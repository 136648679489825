import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Myth extends Component {

  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <div className="content-nav-scroller">
            <h4 className="content-nav-caption">Table of Contents</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-1')}>Do I need to stay 6 ft away from others when I wear a mask?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-10')}>Will spraying and introducing bleach or another disinfectant into your body kill the coronavirus?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-2')}>Do I need to buy surgical masks or N95 respirator?</h4>
            {/* <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-3')}>Can coronavirus spread far distances through the air?</h4> */}
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-4')}>Will wearing gloves 100% protect me from the coronavirus?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-5')}>Can coronavirus survive on surfaces for days?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-6')}>Do I have to wash hands often or disinfect surfaces even if I do not leave the house?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-7')}>Does Ibuprofen worsen disease for people with COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-8')}>Will self-medicating with hydroxychloroquine & azithromycin prevent COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-9')}>Are antibiotics effective in preventing and treating COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-11')}>If I am young and healthy, am I safe from the new coronavirus?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-12')}>Do I have to be hospitalized if I have COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-13')}>Can I check for COVID-19 by holding my breath for 10 seconds or longer without coughing or feeling discomfort?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-14')}>Are hand dryers and UV disinfection lamps effective in killing the coronavirus?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-15')}>Will regularly rinsing your nose with saline prevent COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-16')}>Do extremely hot showers or baths prevent COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-17')}>Do cold weather and snow prevent COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-18')}>Does eating garlic prevent COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-19')}>Does drinking alcohol prevent COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-20')}>Do vaccines against pneumonia protect you against COVID-19?</h4>
          </div>
        </div>
        <div className="content-body">
          <Card 
            id="myth-1"
            thumbnail="/assets/img/shots/myth/myth-1.jpg" 
            title="Do I need to stay 6 ft away from others when I wear a mask?"
            content={
              <div>
                <p><span className="card-tag-true">Yes</span>Continue to maintain 6 ft distance from others; wash hands frequently, including before putting and after removing a mask.</p>
                <p>In the US, <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover-faq.html" target="_blank">CDC</a> recommends cloth masks to help prevent one from spreading the virus by containing respiratory droplets while sneezing, coughing, or talking. Depending on the fabric and fit, masks may offer some protection but not against all virus particles. Please see our “Helpful Tips” page.</p>
              </div>}
            extra={
              <div>
                <p>Please follow <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a> recommendations and use safe household material and ensure the mask fits snugly but still allow for comfortable breathing. CDC recommends multiple layers of fabric and does not recommend a specific type of fabric.</p>
                <p><div className="paragraph-header">How effective are cloth masks against coronavirus?</div>There is no scientific evidence to directly answer this question. In a <a href="https://www.ncbi.nlm.nih.gov/pubmed/24229526" target="_blank">laboratory experiment</a> using a different virus, researchers found none of the fabrics (100% cotton shirt, scarf, silk, pillowcase, linen, cotton mix, tea towel) can effectively block 100% of the viral particles due to it’s small size. However, this experiment does not simulate a normal human cough. It also does not take into account how well fitted the masks are. Since most masks do not offer a tight seal, viral particles can still enter through the <a href="https://www.gannett-cdn.com/experiments/usatoday/gifs/tool/919-surgical.png" target="_blank">sides of the mask.</a></p>
                <p><div className="paragraph-header">Key Point</div>Cloth masks may help prevent infection but will not provide 100% protection.</p>
              </div>}
          />
          <Card 
            id="myth-10"
            thumbnail="/assets/img/shots/myth/myth-10.jpg" 
            title="Can you prevent COVID-19 by putting bleach or other disinfectant into your body?"
            content={
              <div>
                <p><span className="card-tag-false">No</span><strong>DO NOT</strong> <a href="https://www.rb.com/media/news/2020/april/improper-use-of-disinfectants" target="_blank">inject</a>, inhale, drink or spray disinfectant alcohol or chlorine (bleach) all over your body because that is <strong className="red">dangerous.</strong></p>
                <p>According to <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>,  “these substances can be poisonous if ingested and cause irritation and damage to your skin and eyes.” Bleach and disinfectant should only be used carefully to disinfect surfaces under <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cleaning-disinfection.html" target="_blank">appropriate recommendations.</a> Remember to keep disinfectants out of children's reach.</p>
              </div>}
          />
          <Card 
            id="myth-2"
            thumbnail="/assets/img/shots/myth/myth-2.jpg" 
            title="Do I need to buy surgical masks or N95 respirator?"
            content={
              <div>
                <p><span className="card-tag-false">No</span><a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a> recommends <strong>cloth face masks</strong> for the general public in the US.</p>
                <p>You can wear whatever masks you already have at home but you do not need to purposely buy surgical masks or N95. Due to the shortage in supply in the US, medical grade masks should be reserved for health care workers who are trained to use them correctly and are directly in close contacts with ill patients. Consider donating extra N95 masks since N95 are needed to prevent airborne transmission, but COVID-19 is mainly transmitted through respiratory droplets.</p>
              </div>}
            extra={
              <div>
                <p>The <a href="https://www.fda.gov/medical-devices/personal-protective-equipment-infection-control/n95-respirators-and-surgical-masks-face-masks" target="_blank">N95 respirator</a> has a specialized filter that can block at least 95 percent of very small (0.3 micron) test particles <strong>if</strong> there is an air-tight seal. Aside from training, health care workers complete yearly <a href="https://www.safetyalliance.org/wp-content/uploads/2019/06/fit-test-2-000110_retouched-672x372.jpg" target="_blank">fit-testing</a> to ensure appropriate size that will provide air-tight seals. When worn correctly, they can be uncomfortable to breathe in. Even the smallest detail such as facial hair may cause an inadequate seal. For the <a href="https://blogs.cdc.gov/niosh-science-blog/2018/01/04/respirators-public-use/" target="_blank">general public</a>, lack of training and lack of fit-testing will unlikely provide the level of protection the N95 were intended for.</p>
              </div>}
          />
          {/*
          <Card 
            id="myth-3"
            thumbnail="/assets/img/shots/myth/myth-3.jpg" 
            title="Can coronavirus spread far distances through the air?"
            content={
              <div>
                <p><span className="card-tag-maybe">Maybe</span>Airborne transmission is unlikely for the general public.</p>
                <p>According to <a href="https://www.who.int/news-room/q-a-detail/q-a-coronaviruses" target="_blank">WHO</a>, “COVID-19 is mainly transmitted through droplets generated when an infected person coughs, sneezes, or speaks. These droplets are too heavy to hang in the air. They quickly fall on floors or surfaces.” No airborne transmission was reported among an analysis of over 75,000 COVID-19 cases in China. However, health care workers may be at risk. WHO reports airborne transmission may be possible during <a href="https://www.who.int/news-room/commentaries/detail/modes-of-transmission-of-virus-causing-covid-19-implications-for-ipc-precaution-recommendations" target="_blank">hospital procedures that generate aerosols</a> such as intubation, where a breathing tube is inserted into the patient’s airway to help them breathe.</p>
                </div>}
            extra={
              <div>
                <p><div className="paragraph-header">What does airborne transmission mean?</div>In contrast to respiratory droplets, airborne particles are smaller and remain in the air for long periods of time where they can spread farther than 6 feet.</p>
                <p><div className="paragraph-header">Why does the news say coronavirus is airborne?</div>A NEJM <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973" target="_blank">study</a> artificially created aerosols of the coronavirus in a rotating cylinder, where it remained viable for 3 hours. However, the high-powered machine does not represent a normal human cough. In a non peer-reviewed <a href="https://www.medrxiv.org/content/10.1101/2020.03.23.20039446v2" target="_blank">study</a> (has not been evaluated by other experts), researchers sampled for the presence of coronavirus in the air and surfaces of COVID-19 patients’ hospital room. 76.5% of all personal belongings were positive (cell phones, toilets, remotes, bed rails, etc.). For air samples, 63.2% were positive and some samples were collected greater than 6 feet away from the patient. However, the presence of a virus does not automatically mean it can cause disease. For example, the amount of virus may be too low to cause infection. The authors have not proven that the components of the virus that they have detected can cause infections.</p>
              </div>}
          /> */}
          <Card 
            id="myth-4"
            thumbnail="/assets/img/shots/myth/myth-4.jpg" 
            title="Will wearing gloves 100% protect me from the coronavirus?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>Gloves will only help if you maintain precautions. <strong>DO NOT</strong> touch your face with gloved hands. Make sure to wash hands before wearing gloves and <strong>after removing</strong> gloves. Cross-contamination can occur, meaning your gloves can possibly spread germs from one surface to another.</p>
              </div>}
          />
          <Card 
            id="myth-5"
            thumbnail="/assets/img/shots/myth/myth-5.jpg" 
            title="Can coronavirus survive on surfaces for days?"
            content={
              <div>
                <p><span className="card-tag-true">Yes</span>A NEJM <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973?query=featured_home" target="_blank">study</a> shows that the coronavirus can be detected on cardboard for up to 24 hours, and on plastic and steel for up to 72 hours in a laboratory experiment.</p>
                <p>It is important to note that the amount of virus remaining is less than 0.1% of the starting virus material. The presence of the virus does not automatically mean that it can be infectious. Since the amount of virus decreases rapidly over time on each surface, the risk of infection is low.</p>
              </div>}
          />
          <Card 
            id="myth-6"
            thumbnail="/assets/img/shots/myth/myth-6.jpg" 
            title="Should I wash hands often or disinfect surfaces even if I do not leave the house?"
            content={
              <div>
                <p><span className="card-tag-true">Yes</span><a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/prevention.html" target="_blank">CDC</a> recommends to clean and disinfect frequently touched surfaces daily such as doorknobs, light switches, tables, countertops, phones, keyboards, toilets, faucets, and sinks.</p>
                <p>You or a family member may unknowingly be infected. Although you are more likely to get infected by close contact with a sick person than by contaminated surfaces, practice precautions if you can.</p>
              </div>}
          />
          <Card 
            id="myth-7"
            thumbnail="/assets/img/shots/myth/myth-7.jpg" 
            title="Does Ibuprofen worsen disease for people with COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>There is currently not enough scientific evidence to prove ibuprofen (brand names Advil, Motrin) make COVID-19 symptoms worse.</p>
                <p><a href="https://twitter.com/WHO/status/1240409217997189128" target="_blank">WHO</a> does not recommend against the use of ibuprofen (March 18, 2020). Acetaminophen (Tylenol) is an alternative fever and pain relieving medication. <a href="https://www.fda.gov/drugs/drug-safety-and-availability/fda-advises-patients-use-non-steroidal-anti-inflammatory-drugs-nsaids-covid-19" target="_blank">FDA</a> suggests speaking to your health care provider if you are concerned about taking these non-steroidal anti-inflammatory drugs (NSAIDs) and rely on these medications to treat chronic diseases.</p>
                </div>}
            extra={
            <div>
              <p>Ibuprofen falls under a class of medication called <a href="https://www.goodrx.com/blog/are-advil-motrin-tylenol-bad-for-coronavirus-symptoms/" target="_blank">NSAIDS</a> that includes aspirin, naproxen (Aleve), diclofenac (Cambia), and celecoxib (Celebrex), used for treating fever, pain, and inflammation. Every medication has its risks and benefits. For example, NSAIDs can cause kidney and gastrointestinal side effects. The most serious risk of acetaminophen is liver failure caused by overdose, often accidental. Acetaminophen is found in various common over-the-counter cold and flu medicines. Please carefully read all labels of the medications to make sure you are not exceeding the maximum daily dose.</p>
              <p><div className="paragraph-header">How the Ibuprofen debate began:</div><a href="https://jvi.asm.org/content/94/7/e00127-20" target="_blank">Research</a> suggests ACE2, an enzyme in the human body, provides a place on cell surfaces for the coronavirus to attach and infect cells.</p>
              <p>On March 11, 2020, researchers published a <a href="https://www.thelancet.com/journals/lanres/article/PIIS2213-2600(20)30116-8/fulltext" target="_blank">letter</a> in the Lancet Respiratory Medicine with a theory that elevated ACE2 may be associated with severe cases of COVID-19. How does ibuprofen relate? The author suggests Ibuprofen can cause ACE2 to rise. Thus, a connection between Ibuprofen and COVID-19 arose.</p>
              <p>This is all speculative and no scientific evidence has shown Ibuprofen directly worsening outcomes in COVID-19 patients. However, on March 14, the French Ministry of Health posted a warning against the use of ibuprofen for COVID-19 fever and French minister of health <a href="https://twitter.com/olivierveran/status/1238776545398923264" target="_blank">tweeted</a> advice to avoid ibuprofen and other NSAIDS because they could be an “aggravating factor.” His advice spread widely across the news and stories began to misattribute Ibuprofen as the primary causes of COVID-19 deaths. On March 16, the authors of the original hypothesis that sparked the debate clarified their theory <a href="https://www.unibas.ch/en/News-Events/News/Uni-Research/Ibuprofen-and-COVID-19-Setting-the-record-straight.html" target="_blank">“does not constitute a recommendation to use certain drugs or not.”</a></p>
              <p>For people suffering COVID-19 symptoms, WHO initially advised against the use of ibuprofen. <strong>On March 18, the <a href="https://www.ema.europa.eu/en/news/ema-gives-advice-use-non-steroidal-anti-inflammatories-covid-19" target="_blank">EMA</a>, <a href="https://www.fda.gov/drugs/drug-safety-and-availability/fda-advises-patients-use-non-steroidal-anti-inflammatory-drugs-nsaids-covid-19" target="_blank">FDA</a>, <a href="https://twitter.com/WHO/status/1240409217997189128" target="_blank">WHO</a> advised not to avoid NSAIDS when needed.</strong></p>
            </div>}
          />
          <Card 
            id="myth-8"
            thumbnail="/assets/img/shots/myth/myth-8.jpg" 
            title="Will self-medicating with hydroxychloroquine & azithromycin prevent COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>Please <strong>DO NOT</strong> self medicate! Only take prescription medications as advised by a physician due to harmful drug interactions and dangerous side effects.</p>
                <p><a href="https://www.cdc.gov/coronavirus/2019-ncov/hcp/therapeutic-options.html" target="_blank">Hydroxychloroquine and chloroquine</a> are orally prescribed medications for malaria and other inflammatory diseases (lupus, rheumatoid arthritis, etc). Azithromycin is a common antibiotic used to treat infections caused by certain bacteria. Both hydroxychloroquine and azithromycin are associated with cardiac side effects and could lead to life-threatening cardiac arrhythmia, where your heart beats dangerously fast.</p>
              </div>}
          />
          <Card 
            id="myth-9"
            thumbnail="/assets/img/shots/myth/myth-9.jpg" 
            title="Are antibiotics effective in preventing and treating COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>According to <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, antibiotics only treat infection caused by bacteria, not viruses. Antibiotics should not be used for prevention or treatment of viruses. However, if you are hospitalized for COVID-19, you may receive antibiotics because bacterial co-infection is possible.</p>
              </div>}
          />
          
          <Card 
            id="myth-11"
            thumbnail="/assets/img/shots/myth/myth-11.jpg" 
            title="If I am young and healthy, am I safe from the new coronavirus?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>According to <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, “people of all ages can be infected by the new coronavirus.</p>
                <p>Older people, and people with pre-existing medical conditions (such as asthma, diabetes, heart disease) appear to be more vulnerable to becoming severely ill with the virus. WHO recommends people of all ages to take steps to protect themselves from the virus.”</p>
              </div>}
            extra={
              <div>
                <p>Among the first 4,226 cases (Feb 12 - March 16) in the USA, <a href="https://www.cdc.gov/mmwr/volumes/69/wr/mm6912e2.htm#F2_down" target="_blank">CDC</a> reports one in five who were hospitalized were aged 20 to 44. As of May 17, more than half of the confirmed <a href="https://www.cdph.ca.gov/Programs/CID/DCDC/Pages/Immunization/ncov2019.aspx" target="_blank">COVID-19 cases in California</a> occur in people younger than 50. Although case-fatality rates are lower among younger patients, many young adults without pre-existing health problems have died. Everyone should take precautions to protect themselves and others.</p>
              </div>}
          />
          <Card 
            id="myth-12"
            thumbnail="/assets/img/shots/myth/myth-12.jpg" 
            title="Do I have to be hospitalized if I have COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>According to <a href="https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/testing.html" target="_blank">CDC</a>, most people have mild illness and are able to recover at home. Do not leave your home, except to get medical care. Seek medical attention if you are having trouble breathing but call the healthcare provider or facility first. Please see our “Learn about COVID-19”  page.</p>
              </div>}
          />
          <Card 
            id="myth-13"
            thumbnail="/assets/img/shots/myth/myth-13.jpg" 
            title="Can I check for COVID-19 by holding my breath for 10 seconds or longer without coughing or feeling discomfort?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>According to the CDC, you can have the coronavirus and have no symptoms and can also transmit the virus before showing any symptoms. As per <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, “the most common symptoms of COVID-19 are dry cough, tiredness and fever. Some people may develop more severe forms of the disease, such as pneumonia. You cannot confirm it with this breathing exercise.”</p>
              </div>}
          />
          <Card 
            id="myth-14"
            thumbnail="/assets/img/shots/myth/myth-14.jpg" 
            title="Are hand dryers and UV disinfection lamps effective in killing the coronavirus?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>According to <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, “hand dryers are not effective in killing the coronavirus. UV lamps should not be used to sterilize hands or other areas of skin as UV radiation can cause skin irritation. To protect yourself against the new coronavirus, you should frequently clean your hands with soap and water or an alcohol-based hand rub.”</p>
              </div>}
          />
          <Card 
            id="myth-15"
            thumbnail="/assets/img/shots/myth/myth-15.jpg" 
            title="Will regularly rinsing your nose with saline prevent COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>According to <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, “there is no evidence that regularly rinsing the nose with saline has protected people from infection with the new coronavirus. There is some limited evidence that regularly rinsing your nose with saline can help people recover more quickly from the common cold. However, regularly rinsing the nose has not been shown to prevent respiratory infections.”</p>
              </div>}
          />
          <Card 
            id="myth-16"
            thumbnail="/assets/img/shots/myth/myth-16.jpg" 
            title="Do extremely hot showers or baths prevent COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>You should bathe or shower, but please do not use extremely hot water on purpose. According to <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, “your normal body temperature remains around 36.5°C to 37°C, regardless of the temperature of your bath or shower. Actually, taking a hot bath with extremely hot water can be harmful, as it can burn you.”</p>
              </div>}
          />
          <Card 
            id="myth-17"
            thumbnail="/assets/img/shots/myth/myth-17.jpg" 
            title="Do cold weather and snow prevent COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>According to <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, “cold weather and snow cannot kill the new coronavirus. The normal human body temperature remains around 36.5°C to 37°C, regardless of the external temperature or weather.”</p>
              </div>}
          />
          <Card 
            id="myth-18"
            thumbnail="/assets/img/shots/myth/myth-18.jpg" 
            title="Does eating garlic prevent COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>According to <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, “garlic is a healthy food that may have some antimicrobial properties. However, there is no evidence from the current outbreak that eating garlic has protected people from the new coronavirus.”</p>
              </div>}
          />
          <Card 
            id="myth-19"
            thumbnail="/assets/img/shots/myth/myth-19.jpg" 
            title="Does drinking alcohol prevent COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>According to <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, “drinking alcohol does not protect you against COVID-19 and can be dangerous. Frequent or excessive alcohol consumption can increase your risk of health problems.”</p>
              </div>}
          />
          <Card 
            id="myth-20"
            thumbnail="/assets/img/shots/myth/myth-20.jpg" 
            title="Do vaccines against pneumonia protect you against COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>According to <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, “vaccines against pneumonia, such as pneumococcal vaccine and Haemophilus influenzae type B (Hib) vaccine, do not provide protection against the new coronavirus. The virus is so new and different that it needs its own vaccine. Although these vaccines are not effective against the new coronavirus, vaccination against respiratory illnesses is highly recommended to protect your health.”</p>
              </div>}
          />
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Myth;
