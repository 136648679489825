import React, { Component } from 'react'
import classnames from 'classnames'

class ClipboardButton extends Component {

  state = {
    clipboardSuccess: false
  }

  showClipboardCaption = () => {
    this.setState({
      clipboardSuccess: true
    })
    setTimeout(() => this.hideClipboardCaption(), 2000);
  }

  hideClipboardCaption = () => {
    this.setState({
      clipboardSuccess: false
    })
  }

  copyToClipboard = (value) => {
    var input = document.createElement("input");
    input.value = value;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);

    if (this.state.clipboardSuccess) {
      return false;
    }
    this.showClipboardCaption();
  };

  render() {

    const { value, success } = this.props

    return (
      <div className={classnames('clipboard-btn', this.props.className)} onClick={() => this.copyToClipboard(value)}>
        {this.props.children}
        <h5 className={classnames('clipboard-success', {'clipboard-success-active': this.state.clipboardSuccess})}>{success}</h5>
      </div>
    )
  }
}

export default ClipboardButton