import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Tips extends Component {
  
  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <h4 className="content-nav-caption">Mục Lục</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-1')}>Rửa Tay</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-2')}>Khẩu Trang</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-3')}>Bao Tay</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-4')}>Những Cách Giữ Vệ Sinh</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-5')}>Đi Chợ</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-6')}>Cách Xử Lý Thức Ăn Giao Tận Nhà</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-7')}>Cách Xử Lý Gói Đồ và Thư</h4>
        </div>
        <div className="content-body">
          <Card 
            id="tips-1"
            thumbnail="/assets/img/shots/tips/tips-1.jpg" 
            title="Rửa Tay"
            content={
              <div>
                <p>Rửa tay thường xuyên rất quan trọng để phòng chống dịch COVID-19. Tránh chạm vào mắt, mũi hoặc miệng trước khi rửa tay.</p>
                <hr />
                <p>
                  <div className="paragraph-header">Cách Rửa Tay</div>
                  <ol className="content-list">
                    <li><div className="ol-index">1</div>Làm ướt lòng bàn tay dưới vòi nước, tắt vòi</li>
                    <li><div className="ol-index">2</div>Lấy xà phòng vào lòng bàn tay và xoa đều, ít nhất 20 giây</li>
                    <li><div className="ol-index">3</div>Chà 2 bàn tay vào nhau, mặt trong, mặt ngoài, bao gồm các ngón tay, giữa các ngón tay, và dưới móng tay</li>
                    <li><div className="ol-index">4</div>Tráng sạch tay dưới vòi nước</li>
                    <li><div className="ol-index">5</div>Lau khô tay bằng khăn hoặc để khô tự nhiên</li>
                  </ol>
                  <h5 className="content-source"><a href="https://www.cdc.gov/handwashing/when-how-handwashing.html" target="_blank">Nguồn: CDC</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/lWDvYbNlQC0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p>
                  <div className="paragraph-header">Khi nào bạn nên rửa tay?</div>
                  <ul className="content-list">
                    <li>Càng thường xuyên càng tốt</li>
                    <li>Sau khi ra những nơi công cộng</li>
                    <li>
                      <div>Sau khi tiếp xúc với các bề mặt thường chạm</div>
                      <ul>
                        <li>Ví dụ: tay nắm cửa, bàn, vòi bơm xăng, xe đẩy mua sắm, máy tính tiền, nút thang máy, v.v.</li>
                      </ul>
                    </li>
                    <li>Trước, trong và sau khi chuẩn bị thức ăn</li>
                    <li>Trước khi ăn</li>
                    <li>Sau khi ho, hắt hơi hoặc hỉ mũi</li>
                    <li>Trước khi đeo và sau khi tháo mặt nạ và bao tay</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">Nếu không có nước, sử dụng dung dịch rửa tay khô (sanitizer)</div>
                  <ul className="content-list">
                    <li>Sanitizer không tiêu diệt được tất cả các loại vi trùng</li>
                    <li>Sanitizer cần chứa ít nhất 60% cồn</li>
                    <li>Xoa đều toàn bàn tay, giữa các ngón tay và đầu ngón tay</li>
                    <li>Chà hai tay vào nhau cho đến khi khô</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-2"
            thumbnail="/assets/img/shots/tips/tips-2-vn.jpg" 
            title="Khẩu Trang"
            content={
              <div>
                <p>
                  <div className="paragraph-header">Những Điều Nên Thực Hành Khi Đeo Khẩu Trang</div>
                  <ul className="content-list">
                    <div className="content-list-label-positive">Nên làm</div>
                    <li>Xác nhận bạn có thể thở khi đeo khẩu trang</li>
                    <li>Khẩu trang nên che miệng và mũi của bạn (từ sống mũi xuống dưới cằm)</li>
                    <li>Dùng sợi dây bên hông để tháo khẩu trang</li>
                    <li>Rửa và sấy khô khẩu trang mỗi ngày</li>
                    <li>Rửa tay sau khi tháo khẩu trang</li>
                    <li>Giữ khẩu trang trong một bao hoặc thùng nếu chưa rửa sạch</li>
                  </ul>
                  <ul className="content-list">
                    <div className="content-list-label-negative">Tránh</div>
                    <li>Chạm vào mặt trước của khẩu trang, chỗ dơ nhất</li>
                    <li>Kéo khẩu trang xuống cầm hay quấn quanh cổ</li>
                    <li>Đeo khẩu trang quá rộng ở hai bên hông</li>
                    <li>Tháo khẩu trang bằng bao tay</li>
                    <li>Dùng lại những khẩu trang y tế</li>
                  </ul>
                  <h5 className="content-source">Nguồn: <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a>; <a href="https://www.nytimes.com/2020/04/08/well/live/coronavirus-face-mask-mistakes.html#click=https://t.co/GkBNJAuw1V" target="_blank">The New York Times</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/m4O-3wQQjpA?start=118" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p>
                  <div className="paragraph-header">Khẩu trang <span className="red">không</span> thay thế cho các biện pháp khác để phòng ngừa COVID-19</div>
                  <div>Nếu bạn quyết định đeo khẩu trang và / hoặc bao tay, hãy tiếp tục:</div>
                  <ul className="content-list">
                    <li>Rửa tay thường xuyên</li>
                    <li>Cách xa người khác ít nhất 6 feet hoặc 2 mét</li>
                    <li>Ở Yên Tại Nhà (Shelter-in-Place)</li>
                  </ul>
                  <div>Thiết bị bảo vệ như khẩu trang, bao tay, v.v. có thể giúp, nhưng nó cũng có thể gây cảm giác sai lầm về sự an toàn. Khẩu trang và bao tay có thể bị ô nhiễm. Khi gỡ bỏ khẩu trang chú ý tránh tay bị nhiễm bẩn.</div>
                </p>
              </div>}
            extra={
              <div>
                <p>
                  <div className="paragraph-header">Tại sao CDC khuyến nghị mọi người đeo khẩu trang vải ở nơi công cộng?</div>
                  <div>Theo <a href="https://vietnamese.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover.html" target="_blank">CDC</a> mọi người nên đeo khẩu trang vải để bảo vệ những người xung quanh. Bạn có thể lây truyền coronavirus ngay cả khi không có triệu chứng. Bạn có thể giúp ngăn chặn sự lây lan của COVID-19 bằng cách đeo khẩu trang, cách xa người khác ít nhất 6 feet hoặc 2 mét, và rửa tay thường xuyên.</div>
                  <ul className="content-list">
                    <div>Những người KHÔNG nên đeo khẩu trang</div>
                    <li>Trẻ em dưới 2 tuổi</li>
                    <li>Bất cứ ai khó thở</li>
                    <li>Bất cứ ai bất tỉnh</li>
                    <li>Bất cứ ai không thể tự gỡ bỏ khẩu trang </li>
                  </ul>
                  <div>Tại Hoa Kỳ, CDC khuyến nghị sử dụng những vật liệu an toàn từ nhà để làm khẩu trang. Xin xem <a href="https://vietnamese.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">hướng dẫn từng bước</a> của CDC để làm khẩu trang từ vải như áo thun và khăn tay. <strong>Bạn không cần khẩu trang y tế hoặc mặt nạ N95.</strong></div>
                </p>
              </div>
            }
          />
          <Card 
            id="tips-3"
            thumbnail="/assets/img/shots/tips/tips-3.jpg" 
            title="Bao Tay"
            content={
              <div>
                <p>Bạn không cần thiết phải đeo bao tay khi đi ra chỗ công cộng. Nhưng nếu bạn quyết định đeo bao tay, đây là những điều bạn nên biết. Bất cứ điều gì bao tay chạm vào, bao tay có thể truyền nhiễm lên bề mặt khác.</p>
                <p>
                  <ul className="content-list">
                    <div className="content-list-label-positive">Nên làm</div>
                    <li>Rửa tay trước và ngay sau khi tháo bao tay</li>
                    <li>Hãy vứt bỏ bao tay, đừng dùng lại những bao tay cao su</li>
                    <li>Nếu bạn có bao tay vải giặt sạch bằng xà phòng và nước trước khi dùng lại</li>
                  </ul>
                  <ul className="content-list">
                    <div className="content-list-label-negative">Tránh</div>
                    <li>Đụng vào mặt mũi miệng và mắt </li>
                    <li>Bỏ tay vào túi</li>
                    <li>Dùng điện thoại</li>
                  </ul>
                  <h5 className="content-source">Nguồn: <a href="https://www.kqed.org/science/1961243/coronavirus-heres-how-to-use-gloves-properly-to-help-prevent-infection" target="_blank">KQED</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/TnLCYqk20YU?start=116" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>}
          />
          <Card 
            id="tips-4"
            thumbnail="/assets/img/shots/tips/tips-4.jpg" 
            title="Những Cách Giữ Vệ Sinh"
            content={
              <div>
                <p>Dựa vào nghiên cứu hiện tại, COVID-19 chủ yếu lan truyền từ người sang người. Bạn sẽ không bị nhiễm nếu tay chạm vào những vật bị ô nhiễm. Nhưng bạn có thể nhiễm bệnh nếu bạn có vi-rút trên tay rồi đụng vào mắt, mũi hoặc miệng. Để giảm cơ hiểm nhiễm bệnh, bạn nên khử trùng các bề mặt đồ vật thường chạm vào và rửa tay thường xuyên.</p>
                <hr />
                <p>
                  <div className="paragraph-header">Vi-rút Sống Được Bao Lâu Trên Các Bề Mặt ?</div>
                  <div className="content-table">
                    <div className="content-table-row">
                      <div className="content-table-column">
                        <h3 className="content-table-heading">4 Tiếng</h3>
                        <h4 className="content-table-caption">Đồng</h4>
                      </div>
                      <div className="content-table-column">
                        <h3 className="content-table-heading">24 Tiếng</h3>
                        <h4 className="content-table-caption">Các Tông</h4>
                      </div>
                    </div>
                    <div className="content-table-row">
                      <div className="content-table-column">
                        <h3 className="content-table-heading">2-3 Ngày</h3>
                        <h4 className="content-table-caption">Nhựa</h4>
                      </div>
                      <div className="content-table-column">
                        <h3 className="content-table-heading">2-3 Ngày</h3>
                        <h4 className="content-table-caption">Thép</h4>
                      </div>
                    </div>
                  </div>
                  <div>Mặc dù coronavirus được phát hiện trên các bề mặt sau vài tiếng hoặc vài ngày, nguy cơ nhiễm trùng sẽ thấp hơn khi thời gian trôi qua vì lượng vi-rút giảm rất nhanh trên các bề mặt. (Dựa vào một nghiên cứu được công bố trên <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973" target="_blank">Tạp Chí Y Học New England</a> (3/17/2020)</div>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">Dùng gì để khử trùng các bề mặt?</div>
                  <ul className="content-list">
                    <div>Xin xem trang web của CDC để biết chi tiết về cách <a href="https://vietnamese.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cleaning-disinfection.html" target="_blank">khử trùng các loại bề mặt khác nhau.</a> CDC khuyến nghị sử dụng chất khử trùng sau:</div>
                    <li>Chất khử trùng có đăng ký EPA <a href="https://www.cnn.com/2020/03/05/health/epa-disinfectants-coronavirus-trnd/index.html" target="_blank">(Nhấn vào đây để xem danh sách)</a></li>
                    <li>
                      <div>Để pha loãng dung dịch tẩy rửa</div>
                      <ul>
                        <li>5 thìa canh (1/3 cốc) chất tẩy rửa cho mỗi ga-lông nước (3,78 lít)</li>
                        <li><strong>HOẶC</strong> 4 thìa cà phê chất tẩy rửa cho mỗi 1/4 ga-lông nước (946ml)</li>
                        <li>Nhớ phải pha loãng thuốc tẩy (bleach) trước khi sử dụng</li>
                        <li>Chỉ sử dụng thuốc tẩy (bleach) chưa hết hạn</li>
                        <li><strong>ĐỪNG trộn thuốc tẩy</strong> (bleach) với amoniac (ammonia) hoặc hóa chất nào khác</li>
                      </ul>
                    </li>
                    <li>Dung dịch cồn có ít nhất 70% cồn</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-5"
            thumbnail="/assets/img/shots/tips/tips-5.jpg" 
            title="Đi Chợ"
            content={
              <div>
                <p><strong>Cơ hội bạn bị nhiễm bệnh từ người khác cao hơn cơ hội bị nhiễm bệnh từ hàng tạp hóa.</strong> Theo <a href="https://vietnamese.cdc.gov/coronavirus/2019-ncov/daily-life-coping/essential-goods-services.html" target="_blank">CDC</a>, “không có bằng chứng cho thấy thực phẩm hoặc bao bì thực phẩm có vai trò quan trọng trong việc lây lan coronavirus ở Hoa Kỳ.”</p>
                <p>
                  <div className="paragraph-header">Cách Đề Nghị Đi Chợ An Toàn</div>
                  <ul className="content-list">
                    <li>Đừng đi mua sắm nếu bạn có bất kỳ triệu chứng hô hấp hoặc đã tiếp xúc với người bệnh </li>
                    <li>Nếu có thể, đi chợ mỗi tuần một lần hoặc mỗi 2 tuần một lần</li>
                    <li>Nếu có thể, chỉ gửi một người trong gia đình đi chợ</li>
                    <li>Nếu có thể, hãy để trẻ em ở nhà với người lớn khác</li>
                    <li>Lập danh sách mua sắm để giảm thời gian mua sắm</li>
                    <li>Đeo khẩu trang và duy trì khoảng cách với người khác</li>
                    <li>Lau sạch tay cầm của xe đẩy mua sắm</li>
                    <li>Nếu có thể, thực hiện thanh toán không tiếp xúc (Apple pay, thẻ tín dụng, vv)</li>
                    <li>Sử dụng dung dịch sát trùng tay (sanitizer) sau khi rời khỏi cửa hàng</li>
                    <li>Khi về tới nhà, hãy rửa tay bằng xà phòng và nước trong ít nhất 20 giây</li>
                    <li>Mua đồ dùm cho gia đình, bạn bè hoặc hàng xóm nếu họ không thể tự đi chợ</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">Bạn không cần phải khử trùng hàng tạp hóa</div>
                  <div>Điều quan trọng nhất là bạn nên rửa tay và khử trùng các bề mặt sau khi xử lý hàng tạp hóa. Đừng sử dụng xà phòng khi rửa trái cây và rau quả.</div>
                  <ul className="content-list">
                    <div>Tuy nhiên, nếu bạn muốn <a href="https://www.npr.org/sections/health-shots/2020/04/12/832269202/no-you-dont-need-to-disinfect-your-groceries-but-here-s-to-shop-safely" target="_blank">khử trùng hàng tạp hóa</a>:</div>
                    <li>Vứt bỏ các bao bì thực phẩm không cần thiết</li>
                    <li>Chỉ trực tiếp sử dụng thuốc xịt hoặc khăn lau khử trùng trên bề mặt cứng như lọ thủy tinh</li>
                    <li>Sử dụng nước và xà phòng để rửa bao bì nhựa hoặc giấy cạc tông để tránh chất khử trùng thấm vào thực phẩm</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-6"
            thumbnail="/assets/img/shots/tips/tips-6.jpg" 
            title="Cách Xử Lý Thức Ăn Giao Tận Nhà"
            content={
              <div>
                <p>Theo <a href="https://www.fda.gov/food/food-safety-during-emergencies/shopping-food-during-covid-19-pandemic-information-consumers" target="_blank">FDA</a>, COVID-19 lây lan từ người sang người qua các giọt bắn từ đường hô hấp, không giống như vi-rút đường tiêu hóa và ngộ độc thực phẩm. Theo CDC, hiện tại không có bằng chứng nào hỗ trợ cho việc lây truyền COVID-19 liên quan đến thực phẩm.</p>
                <p>
                  <ul className="content-list">
                    <li>Thức ăn giao tận nhà: nhờ người giao thức ăn để thức ăn trước cửa</li>
                    <li>Chuyển thức ăn từ những đồ đựng của nhà hàng sang qua những đồ đựng của nhà bạn</li>
                    <li>Nếu dùng muỗng nĩa từ nhà hàng, bạn nên rửa bằng xà phòng và nước trước</li>
                    <li>Bạn hãy rửa tay trước khi đụng vào thức ăn</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-7"
            thumbnail="/assets/img/shots/tips/tips-7.jpg" 
            title="Cách Xử Lý Gói Đồ và Thư"
            content={
              <div>
                <p><a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973?query=featured_home" target="_blank">Nghiên cứu</a> của NEJM trong phòng thí nghiệm cho thấy một số lượng coronavirus nhỏ được phát hiện trên giấy các tông cho đến 24 tiếng. Tuy nhiên, sự hiện diện của vi-rút không có nghĩa là vi-rút có thể lây nhiễm.  Theo <a href="https://vietnamese.cdc.gov/coronavirus/2019-ncov/faq.html" target="_blank">CDC</a>, cơ hội lây lan qua thư, gói đồ, hoặc  sản phẩm rất nhỏ.</p>
                <p>
                  <ul className="content-list">
                    <li>Rửa tay sau khi mở gói đồ và thư</li>
                    <li>Rửa kéo và các bề mặt</li>
                  </ul>
                </p>
              </div>}
          />
          <h4 className="content-tips-disclaimer">Chúng tôi hiểu rằng mọi người sẽ không thể áp dụng tất cả các lời khuyên ở trên. Chúng tôi hy vọng các ví dụ trên đây hữu dụng khi áp dụng. Hãy chia sẻ với chúng tôi cách bạn thực hiện những lời khuyên này!</h4>
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Tips;
