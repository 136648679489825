import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Tips extends Component {
  
  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <h4 className="content-nav-caption">目录</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-1')}>正确洗手步骤</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-2')}>面罩指南</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-3')}>使用手套的提示</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-4')}>清洁东西</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-5')}>如何处理购买回来的物品</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-6')}>如何处理外卖食物</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-7')}>如何处理邮包</h4>
        </div>
        <div className="content-body">
          <Card 
            id="tips-1"
            thumbnail="/assets/img/shots/tips/tips-1.jpg" 
            title="正确洗手步骤"
            content={
              <div>
                <p>防止传染冠状病毒的关键步骤是常常洗手。避免在洗手前触摸你的眼睛、鼻子、和嘴巴 。</p>
                <hr />
                <p>
                  <div className="paragraph-header">如何正确的洗手：</div>
                  <ol className="content-list">
                    <li><div className="ol-index">1</div>用水冲洗双手</li>
                    <li><div className="ol-index">2</div>加肥皂或者洗手液在手中。搓擦手的各个部位、包括手背、手指之间以及指甲下的缝隙。</li>
                    <li><div className="ol-index">3</div>每次洗手应揉搓20秒以上 （唱 ”生日快乐“ 歌两次）</li>
                    <li><div className="ol-index">4</div>用清水冲掉手中的肥皂</li>
                    <li><div className="ol-index">5</div>用干净的毛巾或者纸巾擦手、或者让手风干</li>
                  </ol>
                  <h5 className="content-source"><a href="https://www.cdc.gov/handwashing/when-how-handwashing.html" target="_blank">来源：美国疾病预防控制中心</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/9eWT94x9Orw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/rgaModQN7RE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p>
                  <div className="paragraph-header">应该何时洗手？</div>
                  <ul className="content-list">
                    <li>尽可能经常的洗手</li>
                    <li>外出到公共场所后</li>
                    <li>
                      <div>触摸常被别人碰的物件后</div>
                      <ul>
                        <li>比如：门把手、桌子、加油站、购物车、收银台、刷卡器、电梯等等</li>
                      </ul>
                    </li>
                    <li>做饭时以及做饭前后</li>
                    <li>吃饭前</li>
                    <li>擤鼻涕、打喷嚏后</li>
                    <li>在戴口罩和手套前；摘口罩和手套后</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">如果没有干净的水和肥皂、不方便洗手时、可以使用含有酒精成分的消毒洗手液来清洁双手</div>
                  <ul className="content-list">
                    <li>消毒洗手液不能杀灭所有的细菌</li>
                    <li>洗手液应该含有最少六成的酒精成分</li>
                    <li>将消毒洗手液搓在手的各个部位上、包括手指之间以及指尖上</li>
                    <li>連续搓手、直到手上的洗手液<strong>干了</strong>为止</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-2"
            thumbnail="/assets/img/shots/tips/tips-2-cn.jpg" 
            title="面罩指南"
            content={
              <div>
                <p>
                  <div className="paragraph-header">使用面罩的良好用法</div>
                  <ul className="content-list">
                    <div className="content-list-label-positive">要做</div>
                    <li>确保可以呼吸</li>
                    <li>确保它遮挡了您的鼻子和嘴巴（从鼻梁到下巴下方）</li>
                    <li>用侧边的绳取下面罩</li>
                    <li>每天清洗和擦干布口罩</li>
                    <li>取下面罩后要洗手</li>
                    <li>将未清洗干净的口罩放在指定的袋子或箱中</li>
                  </ul>
                  <ul className="content-list">
                    <div className="content-list-label-negative">避免</div>
                    <li>触摸面罩的正面、这是最肮脏的位置</li>
                    <li>将面罩滑到下巴下或带在脖子上</li>
                    <li>侧面有间隙</li>
                    <li>用手套取下脸罩</li>
                    <li>重复使用手术口罩</li>
                  </ul>
                  <h5 className="content-source">来源: <a href="https://chinese.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">美国疾病预防控制中心</a>; <a href="https://www.nytimes.com/2020/04/08/well/live/coronavirus-face-mask-mistakes.html#click=https://t.co/GkBNJAuw1V" target="_blank">纽约时报</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/m4O-3wQQjpA?start=118" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p>
                  <div className="paragraph-header">带面罩<span className="red">不能</span>代替其他预防措施</div>
                  <div>若您戴上脸罩和/或手套，请继续保持：</div>
                  <ul className="content-list">
                    <li>正确的洗手</li>
                    <li>与其他人保持6英尺的距离</li>
                    <li>在家隔离</li>
                  </ul>
                  <div>虽然防护装备（口罩、手套等）可以帮助限制病毒传染、但也会产生错误的安全感。保护装备有可能与以被污染的表面接触。如果使用不当、可能会导致交叉感染并被病毒有机可乘。</div>
                </p>
              </div>}
            extra={
              <div>
                <p>
                  <div className="paragraph-header">为什么美国疾病预防控制中心 (CDC) 建议在公众场地使用面罩？</div>
                  <div>根据<a href="https://chinese.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover.html" target="_blank">CDC</a>、无症状的人也可以传播冠状病毒（也称为 “隐形患者”）。通过戴口罩和与他人保持6英尺的距离、可以帮助阻止冠状病毒在社区中的传播。</div>
                  <ul className="content-list">
                    <div>有些人不应该使用面罩！</div>
                    <li>2岁以下的儿童</li>
                    <li>有呼吸困难的人</li>
                    <li>失去知觉或不能独立地取下脸罩（比如行动不便）的人</li>
                  </ul>
                </p>
                <p>在美国、CDC建议广大市民使用安全的家用材料，例如旧恤衫或者是头巾、来做布料面罩。 CDC的网页有谈到使用旧恤衫和头巾来<a href="https://chinese.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">自制布面罩</a>的步骤。</p>
                <p><strong>普通市民并不需要用医疗口罩(外科口罩) 或者是用N95口罩。</strong>反而、因为现时美国医疗口罩的供应短缺、CDC 建议不用特别的去买医疗口罩或N95口罩。</p>
              </div>
            }
          />
          <Card 
            id="tips-3"
            thumbnail="/assets/img/shots/tips/tips-3.jpg" 
            title="使用手套的提示"
            content={
              <div>
                <p>在公众场所用手套作为防护是不必的。如果您选择使用手套、请注意以下几点。</p>
                <p>
                  <ul className="content-list">
                    <div className="content-list-label-positive">要做</div>
                    <li>用手套前和脱下后都要立刻洗手</li>
                    <li>脱了塑料手套后要丢掉、不要重用</li>
                    <li>布制手套、在重新使用前用肥皂和水清洗</li>
                  </ul>
                  <ul className="content-list">
                    <div className="content-list-label-negative">避免</div>
                    <li>触摸脸、嘴、鼻子和眼睛</li>
                    <li>将手放在口袋里</li>
                    <li>使用手机</li>
                  </ul>
                  <h5 className="content-source">来源: <a href="https://www.kqed.org/science/1961243/coronavirus-heres-how-to-use-gloves-properly-to-help-prevent-infection" target="_blank">KQED</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/TnLCYqk20YU?start=116" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>}
          />
          <Card 
            id="tips-4"
            thumbnail="/assets/img/shots/tips/tips-4.jpg" 
            title="清洁东西"
            content={
              <div>
                <p>根据目前的数据、接触污染的物件不是主要的感染冠状病毒方式。如果你只接触了污染的物件、你不会感染到病毒的。但是如果你用占有活的病毒的手来碰你眼睛、鼻子、或者嘴、你的感染风险会比较高。觸摸表面后再清洁表面和洗手、可降低病毒的传播风险。</p>
                <hr />
                <p>
                  <div className="paragraph-header">冠状病毒究竟能在不同物件表面上能稳定地保持多长时间？</div>
                  <div className="content-table">
                    <div className="content-table-row">
                      <div className="content-table-column">
                        <h3 className="content-table-heading">4 小时</h3>
                        <h4 className="content-table-caption">铜</h4>
                      </div>
                      <div className="content-table-column">
                        <h3 className="content-table-heading">24 小时</h3>
                        <h4 className="content-table-caption">纸板</h4>
                      </div>
                    </div>
                    <div className="content-table-row">
                      <div className="content-table-column">
                        <h3 className="content-table-heading">2-3 天</h3>
                        <h4 className="content-table-caption">塑料</h4>
                      </div>
                      <div className="content-table-column">
                        <h3 className="content-table-heading">2-3 天</h3>
                        <h4 className="content-table-caption">不锈钢</h4>
                      </div>
                    </div>
                  </div>
                  <div>虽然过了几个小时到几天还能监测到冠状病毒，表面上的病毒总数会根據時間的推移会变得越来越少，所以感染病毒的风险也会降低得很快。(按照<a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973" target="_blank">新英格兰医学雜誌NEJM</a>的一项研究【二零二零年三月十七日】）</div>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">应该用什么清洁物件表面呢？</div>
                  <ul className="content-list">
                    <div>请浏览美国疾病预防控制中心网站以获取关于<a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cleaning-disinfection.html" target="_blank">如何清洁各种表面的建议。</a>美国疾病预防控制中心的推荐</div>
                    <li>环境保护署批准的消毒剂 <a href="https://www.cnn.com/2020/03/05/health/epa-disinfectants-coronavirus-trnd/index.html" target="_blank">(在这里看名单)</a></li>
                    <li>
                      <div>稀释漂白液</div>
                      <ul>
                        <li>每加仑水配5汤匙（⅓杯）漂白剂</li>
                        <li><strong>或者</strong>，每夸脱的水配4茶匙漂白剂</li>
                        <li>请勿直接从瓶中用沒有稀释的漂白剂清洁</li>
                        <li>确保漂白剂没有过过期</li>
                        <li>请勿将漂白剂与氨水或其他清洁剂混合</li>
                      </ul>
                    </li>
                    <li>酒精溶液(至少70%酒精）</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-5"
            thumbnail="/assets/img/shots/tips/tips-5.jpg" 
            title="如何处理购买回来的物品"
            content={
              <div>
                <p><strong>在超市，相对您购买的物品而言，您更有可能被在超市的人传染。</strong><a href="https://www.cdc.gov/coronavirus/2019-ncov/daily-life-coping/essential-goods-services.html" target="_blank">美国疾病预防控制中心报道</a>， “在美国，我们并没有数据指向食物，或者食物包装，为传播病毒的主要渠道。”</p>
                <p>
                  <div className="paragraph-header">往超市购物时的建议</div>
                  <ul className="content-list">
                    <li>如果你有任何呼吸道或着新冠疾病的症状、请不要出门购买物品</li>
                    <li>尽量每次购买足够一到两个星期的份量</li>
                    <li>尽量每个家庭只派一个人往超市</li>
                    <li>在通常情况下、我们很难留意小孩子接触过什么东西。所以如果可以、让小孩子跟一个可以信任的监护人留在家里。</li>
                    <li>出发前、写下一张购物的清单有助减小逗留在超市的时间</li>
                    <li>要戴面罩。在超市里要跟别人保持6英尺的距离。</li>
                    <li>用消毒湿巾清擦购物车把手的部位</li>
                    <li>尽量选择无接触式付款</li>
                    <li>离开超市后，用免洗洗手液</li>
                    <li>等回家后，用水和洗手液洗手至少20秒</li>
                    <li>如果是在你的能力内、请帮助一些无法自己去超市的家人、朋友或邻居购买食物和日用品</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">您并不需要给您购买的物品消毒。</div>
                  <div>最重要的是在购物后洗手，还有擦干净触碰到物品的桌面。避免用肥皂洗新鲜蔬菜水果。</div>
                  <ul className="content-list">
                    <div>如果<a href="https://www.npr.org/sections/health-shots/2020/04/12/832269202/no-you-dont-need-to-disinfect-your-groceries-but-here-s-to-shop-safely" target="_blank">您坚持清洗您购买的食物或物品</a></div>
                    <li>考虑扔掉所有多余的外包装</li>
                    <li>用肥皂/洗碗剂和水清洗塑料或者纸板包装。这可避免有害的消毒液渗入您的食物</li>
                    <li>可以直接喷射消毒液或用含有消毒液的纸巾抹硬的表面: 罐头、玻璃瓶等等。</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-6"
            thumbnail="/assets/img/shots/tips/tips-6.jpg" 
            title="如何处理外卖食物"
            content={
              <div>
                <p><a href="https://www.fda.gov/food/food-safety-during-emergencies/shopping-food-during-covid-19-pandemic-information-consumers" target="_blank">根据目前资料</a>，新冠病毒 (COVID-19) 主要是透过人与人之间传播，并不是透过食物传染的。</p>
                <p>
                  <ul className="content-list">
                    <li>要求送餐服务员在门口放下外卖</li>
                    <li>把食物从外卖包装盒里直接转放到家里的盘里然后扔掉外卖盒。</li>
                    <li>如果必须使用餐馆提供的餐具， 请先用清水和洗洁剂清洗餐具。</li>
                    <li>在用餐前，记得要用清水跟肥皂清洗双手</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-7"
            thumbnail="/assets/img/shots/tips/tips-7.jpg" 
            title="如何处理邮包"
            content={
              <div>
                <p>根据<a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973?query=featured_home" target="_blank">NEJM的研究</a>、新冠病毒能在板纸包装和纸皮上存活到二十四小时。但是虽然病毒能在包装短暂的存活着，这并不代表病毒一定会通过此途径传染。根据<a href="https://www.cdc.gov/coronavirus/2019-ncov/faq.html" target="_blank">CDC</a>的报道，新冠病毒不太可能通过邮件，产品和包装传染。</p>
                <p>
                  <ul className="content-list">
                    <li>打开邮包或信件以后，用洗手液洗手。</li>
                    <li>将剪刀还有邮件接触到的表面清理干净</li>
                  </ul>
                </p>
              </div>}
          />
          <h4 className="content-tips-disclaimer">我们认识到以上的各种建议可能并不适合所有的读者。每个人还有每一个家庭的情况以及资源并不一样。我们希望这些建议可以帮助大家做好准备，为您自己的家庭想出一些有益的主意。我们邀请您跟我们分享您是如何采取以及实践这些建议的！</h4>
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Tips;
