import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Tips extends Component {
  
  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <h4 className="content-nav-caption">ማውጫ</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-1')}>የእጅ መታጠብ ምክሮች</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-2')}>የፊት ሽፋን ምክሮች</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-3')}>ስለጓንት ጠቃሚ ምክሮች</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-4')}>ለንፅህና ጠቃሚ ምክሮች</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-5')}>የምግብ ግሮሰሪ ለመግዛት ስትወጡ</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-6')}>ወደቤት ተዘጋጅቶ የሚመጣ ምግብ አያያዝ</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-7')}>ጥቅሎች እና ደብዳቤ አያያዝ</h4>
        </div>
        <div className="content-body">
          <Card 
            id="tips-1"
            thumbnail="/assets/img/shots/tips/tips-1.jpg" 
            title="የእጅ መታጠብ ምክሮች"
            content={
              <div>
                <p>COVID-19 እንዳይሰራጭ ለመከላከል  እጅዎን አዘውትረው መታጠብ አስፈላጊ ነው ፡፡ እጅዎን ከማጠብዎ በፊት አይኖችዎን ፣ አፍንጫዎን ወይም አፍዎን ከመንካት ይቆጠቡ ፡፡</p>
                <hr />
                <p>
                  <div className="paragraph-header">ትክክለኛ የእጅ መታጠብ ምክሮች።</div>
                  <ol className="content-list">
                    <li><div className="ol-index">1</div>እጆችዎን በሚፈስ ውሃ አርሱት፡፡</li>
                    <li><div className="ol-index">2</div>ሳሙና ይጨምሩ እና እጆችዎን፣ ከፊትና ከኋላ፣ በጣቶችዎ መካከል እና ጥፍሮችዎን በደምብ ማሸት፡፡</li>
                    <li><div className="ol-index">3</div>እጆችዎን ማሸት ለ 20 ሰከንዶች (“መልካም ልደት” ሁለት ጊዜ መዘመር ጊዜውን በሚገባ ለመጠቀም ይረዳል) )</li>
                    <li><div className="ol-index">4</div>እጆችዎን በንጹህ ውሃ ማለቅለቅ</li>
                    <li><div className="ol-index">5</div>እጆችዎን በፎጣ ያድርቁ ወይም በአየር እንዲደርቅ ያድርጓቸው</li>
                  </ol>
                  <h5 className="content-source"><a href="https://www.cdc.gov/handwashing/when-how-handwashing.html" target="_blank">የዚሀ ሀሳብ ምንጮች: CDC</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/CSJO52qZLbs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p>
                  <div className="paragraph-header">እጅዎን መቼ መታጠብ አለብዎት?</div>
                  <ul className="content-list">
                    <li>በተቻለ መጠን በየጊዜው መታጠብ</li>
                    <li>ውጪ እና ሰው ያለበት ቦታዎች ከወጡ በኋላ</li>
                    <li>
                      <div>ብዙ ጊዘ የሚነኩ ወለሎችን ወይም አቃዎችን ከነካችሁ ቡሃላ</div>
                      <ul>
                        <li>ለምሳሌ - የበር መያዣዎች ፣ ጠረቤዛ ፣ የነዳጅ መቅጅያ ፣ የሱቅ ጋሪዎች ፣ የገንዘብ መከፈያ ቦታ ፣ የሊፍት መውረጃ አና መውጭያ እና ወዘተ፡፡</li>
                      </ul>
                    </li>
                    <li>ምግብን ከማዘጋጀት በፊት ፣ እና ካዘጋጀን በኋላ</li>
                    <li>ከመብላትዎ በፊት</li>
                    <li>ከሳሉ ፣ ካስነጠሱ አና አፍንጫዎን ከጠረጉ በኋላ</li>
                    <li>ጭምብሎችን እና ጓንቶችን ከመልበስዎ በፊት እና ካወለቁ በኋላ</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">ውሃ እና ሳሙና ከሌለ በአልኮል የተዘጋጀ የእጅ ማጽጃ ይጠቀሙ</div>
                  <ul className="content-list">
                    <li>በአልኮሆል የተዘጋጘ የእጅ ማፅጃ ወይም ሃንድ ሳንታይዘር ሁሉንም አይነት ጀርሞችን ሊገድል አይችልም</li>
                    <li>ቢያንስ 60% አልኮሆል መያዝ አለበት</li>
                    <li>ፈሳሹን አልኮሆል ወይም ሳኒታይዘሩን እጅዎ ላይ፣ ጣቶቻችሁ መሃል አና ጥፍሮቻችሁን ውስጥ በደምብ ማዳረስ</li>
                    <li>እጃችሁን እሹት እስከሚደርቅ ድረስ</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-2"
            thumbnail="/assets/img/shots/tips/tips-2-am.jpg" 
            title="የፊት ሽፋን ምክሮች"
            content={
              <div>
                <p>
                  <div className="paragraph-header">የፊት ሽፋኖችን ለመጠቀም ጥሩ ልምዶች</div>
                  <ul className="content-list">
                    <div className="content-list-label-positive">የሚከተለውን አድርጉ</div>
                    <li>የፈት ሽፋነ ወይም ማስክ ስታረጉ ፣ መተንፈስ መቻልዎን ያረጋግጡ</li>
                    <li>አፍንጫዎን እና አፍዎን መሸፈኑን ያረጋግጡ (ከአፍንጫ ድልድይ እስከ አገጮ በታች)</li>
                    <li>የፊት ሽፋኑን ሲያወልቁ የጎን ማሰሪያዎቹን ይጠቀሙ</li>
                    <li>በየቀኑ የጨርቅ ጭምብሎችን ማጠብ እና ማድረቅ</li>
                    <li>የፊት ሽፋኑን ካወለቁ በኋላ እጅዎን ይታጠቡ</li>
                    <li>የጨርቅ ጭምብሎችን እስከምታጥቡ ድረስ ፣ በላስቲክ ውስጥ ለይታቹ አስቀምጡ</li>
                  </ul>
                  <ul className="content-list">
                    <div className="content-list-label-negative">የሚከተለውን አታድርጉ</div>
                    <li>የፊት ሽፋኑን በቀላሉ ቫይረሱ የሚያርፍበት ቦታ ስለሆነ፣ ፊለፊቱን በጃችሁ አትንኩ</li>
                    <li>ጭምብሉን አውርዳችሁ አገጭዎ ወይም አንገትዎ ድርስ አታድርጉ</li>
                    <li>በጎኑ ላይ ልቅ ክፍተቶች አይኑሩ</li>
                    <li>የፊት ሽፋኑን በጓንት ማውለቅ</li>
                    <li>የቀዶ ጥገና ጭንብል ወይም ሀኪም ቤት የሚጠቀሙበት ጭንበል ደግሞ አለማድረግ</li>
                  </ul>
                  <h5 className="content-source">Sources: <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a>; <a href="https://www.nytimes.com/2020/04/08/well/live/coronavirus-face-mask-mistakes.html#click=https://t.co/GkBNJAuw1V" target="_blank">The New York Times</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/m4O-3wQQjpA?start=118" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p>
                  <div className="paragraph-header">የፊት ሽፋኖች ለሌሎች የመከላከያ እርምጃዎች ምትክ አይደሉም</div>
                  <div>የፊት ሽፋኖችን እና / ወይም ጓንቶችን ለመልበስ ከወሰኑ የሚቀተሉትን ልምምዶችን ማድረጉን አትርሱ፡፡</div>
                  <ul className="content-list">
                    <li>ትክክለኛ እጅን መታጠብ</li>
                    <li>ከሌሎች 6 ጫማ ርቀት ርቆ መቆም</li>
                    <li>ቤት ውስጥ መቆየትe</li>
                  </ul>
                  <div>ምንም እንኳን የመከላከያ መሣሪያዎች (የፊት ጭንብል ፣ ጓንት ፣ ወዘተ) የቫይረሱን ስርጭትን ለመገደብ ቢረዱም ፣ የተሳሳተ የደህንነት ስሜት ሊፈጥር ይችላል ፡፡ የመከላከያ መሣሪያዎች ሊበከሉ ከሚችሉት ወለል ጋር በቀላሉ ይገናኛሉ፡፡ በተሳሳተ መንገድ ጥቅም ላይ ከዋለ ፣ ለቫይረሱ ሊያጋልጥ ይችላል።</div>
                </p>
              </div>}
            extra={
              <div>
                <p>
                  <div className="paragraph-header">ሲዲሲ ውጪ ስንወጣ ወይም ሰው ያለበት ቦታዎች ስንሄድ መሸፈኛዎችን መጠቀምን የሚመከረው ለምንድነው?</div>
                  <div><a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover.html" target="_blank">CDC</a> እንዳለው፣ የበሽታው ምልክቶች ላንዳንድ ሰው አይታዩም። ነገር ግን እነዚህ ሰዎች ቫይረሱን ማስተላለፍ ይችላሉ።  የፊት ሽፋኖችን በመልበስ እና ከሌሎች ሰዎች 6 እግር በመራቅ በማህበረሰቡ ውስጥ የኮሮና ቫይረስ ስርጭትን ለማቆም ሊረዱ ይችላሉ።</div>
                  <ul className="content-list">
                    <div>አንዳንድ ግለሰቦች የፊት መሸፈኛዎችን መጠቀም የለባቸውም!</div>
                    <li>ዕድሜያቸው ከ 2 ዓመት በታች የሆኑ ልጆች</li>
                    <li>የመተንፈስ ችግር ያላቸው ግለሰቦች</li>
                    <li>በፅና የታመመ ሰው</li>
                    <li>የፊት መሸፈኛውን ያለእርዳታ ማውለቅ የማይችሉ ግለሰቦች</li>
                  </ul>
                </p>
                <p>ሲዲሲ እንደሚመክረው በቤቶች የሚገንጙ ኬኔተራ ወይም ጨርቅ ሻሽ እንደጭምበል መጠቀም ትችላላችሁ። ጭምበሉን እንዴት እንደሚሰራ ለማወቅ ከፈለጋችሁ፣ <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">የሲዲሲን መመሪያ ተጠቀሙ።</a></p>
                <p><strong>ሐኪም ቤት የሚጠቀሙበት ጭንብል ወይም ኤን-95 ጭንብል አያስፈልጉዎትም ፡፡</strong> በግለሰብ ደረጃ፣ የሆስፒታል ጭንብል ወይም ኤን-95 ጭንብል እጥረት ስላለ፣ ሲዲሲ የሚመክረው ከጨርቅ የሚሰሩ ጭንብል እንድነጠቀም ነው።</p>
              </div>
            }
          />
          <Card 
            id="tips-3"
            thumbnail="/assets/img/shots/tips/tips-3.jpg" 
            title="ስለጓንት ጠቃሚ ምክሮች"
            content={
              <div>
                <p>ከቤት ስንወጣ፣ የእጅ ጓንት ማረግ አስፈላጊ አይደለም። ግን፣ ጓንት ለማረግ ከፈለጋችሁ፣ ከዚህ በታች የሚከተሉትን ምክሮች ተግባራዊ አድርጉ።</p>
                <p>
                  <ul className="content-list">
                    <div className="content-list-label-positive">የሚከተለውን አድርጉ</div>
                    <li>ጓንት ከማድረጋችሁ በፊት አና ካወለቃጭሁ በኋላ <strong>ወዲያውኑ</strong> እጅዎን ይታጠቡ</li>
                    <li>ያወለቁትን የፕላስቲክ ጓንት እንደገና አይጠቀሙ</li>
                    <li>በጨርቅ የተሰሩ ጓንቶችን እንደገና ከመጠቀምዎ በፊት በሳሙና እና በውሃ እጠቧቸው</li>
                  </ul>
                  <ul className="content-list">
                    <div className="content-list-label-negative">የሚከተለውን አታድርጉ</div>
                    <li>ፊትዎን ፣ አፍዎን ፣ አፍንጫዎን እና አይኖችዎን መንካት</li>
                    <li>እጆችዎን በኪስዎ ውስጥ መክተት</li>
                    <li>ስልክዎን ብዙ መጠቀም</li>
                  </ul>
                  <h5 className="content-source">የዚህ ሀሳብ ምንጮች: <a href="https://www.kqed.org/science/1961243/coronavirus-heres-how-to-use-gloves-properly-to-help-prevent-infection" target="_blank">KQED</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/TnLCYqk20YU?start=116" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>}
          />
          <Card 
            id="tips-4"
            thumbnail="/assets/img/shots/tips/tips-4.jpg" 
            title="ለንፅህና ጠቃሚ ምክሮች"
            content={
              <div>
                <p>እስከአሁን በተደረሰበት መረጃ፣ በቫይረሱ የተበከሉ እቃዎችን በመንካት ብቻ የዚህ ቫይረስ ዋናው ማስተላለፊያ መንገድ አይደለም። ነገር ግን፣ ቫይረሱን በነካው እጃችን አፋችንን፣ ፊታችንን፣ አይናችንን ስንነካ ነው ቫይረሱ የሚተላለፈው። ለዚህ ነው፣ ቫይረሱ የምያርፉባቸውን ወለሎችን በማፅዳት አና በደምብ አጃችንን በመታጠብ፣ የቫይረሱን መተላለፊያ መንገዶችን የምንቀንሰው።</p>
                <hr />
                <p>
                  <div className="paragraph-header">በተለያዩ ወለሎች ላይ ኮሮና ቫይረስ ምን ያህል ጊዜ ይቆያል?</div>
                  <div className="content-table">
                    <div className="content-table-row">
                      <div className="content-table-column">
                        <h3 className="content-table-heading">4 ሰዓታት</h3>
                        <h4 className="content-table-caption">መዳብ</h4>
                      </div>
                      <div className="content-table-column">
                        <h3 className="content-table-heading">24 ሰዓታት</h3>
                        <h4 className="content-table-caption">ካርቶን</h4>
                      </div>
                    </div>
                    <div className="content-table-row">
                      <div className="content-table-column">
                        <h3 className="content-table-heading">2-3 ቀናት</h3>
                        <h4 className="content-table-caption">ፕላስቲክ</h4>
                      </div>
                      <div className="content-table-column">
                        <h3 className="content-table-heading">2-3 ቀናት</h3>
                        <h4 className="content-table-caption">የማይዝግ ብረት</h4>
                      </div>
                    </div>
                  </div>
                  <div>ኮሮና ቫይረስ እስከ ሰአታት ወይም ቀናት የሚቆይ ቢሆንም በእነዚህ ወለሎች ላይ ያለው የቫይረስ መጠን ከጊዜ ወደ ጊዜ በፍጥነት ይቀንሳል። ስለዚህ፣ ከጊዜ ወደ ጊዜ በበሽታው የመያዝ እድሉ እየቀነሰ ይሄዳል ። (<a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973" target="_blank">በኒው ኢንግላንድ ጆርናል ኦፍ ሜዲሰን</a> 3/17/2020  በታተመው ጥናት ላይ የተመሠረተ)</div>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">ወለልዎችን ለማፅዳት ምን መጠቀም እችላለው?</div>
                  <ul className="content-list">
                    <div><a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cleaning-disinfection.html" target="_blank">የተለያዩ ወለሎችን</a> እንዴት እንደሚፀዳ የበለጠ መረጃ ከፈለጋችሁ የሲዲሲ ድህረገፅ ይጎብኙ። ሲዲሲ የሚከተሉትን የማፅጃ ዘዴዎች እንድንጠቀም ይመክረናል፥</div>
                    <li>ኢፒኤ (የአሜሪካ የአካባቢ ጥበቃ ኤጀንሲ) የፈቀዳቸውን የማፆጃ ኬሚካል <a href="https://www.cnn.com/2020/03/05/health/epa-disinfectants-coronavirus-trnd/index.html" target="_blank">(ዝርዝሮች ይመልከቱ)</a></li>
                    <li>
                      <div>ለማፆዳት የምንገለገልበትን የበረኪና ኬሚካል ወይም ብሊች አዘገጃጀት</div>
                      <ul>
                        <li>ለ 3.8 ሊተር (1 ጋለን) ውሃ፣ 5 የሾርባ ማንክያ (5 ቴብል ስፑን) በረኪና (ብሊች)</li>
                        <li>ወይም 4 የሻይ ምንኪያ በረኪና (ብሊች) ለ 0.95 ሊተር (1 ኳርት) ውሃ</li>
                        <li>በበረኪናው ወይም በብሊቹ ብቻ ከውሃ ጋር ሳይደባለቅ በፊት ለማፅዳት አትሞክሩ</li>
                        <li>በረኪናውን ከመጠቀማችሁ በፊት የመጠቅሚያው ጊዜ ያላለፈበት መሆኑን ያረጋግጡ</li>
                        <li>በረኪናውን  ወይም ብሊቹን ከሌላ ቤት ውስጥ የሚገኙ መርዛማ  የማጽጃ ኬሚካል ጋር አይቀላቅሉ</li>
                      </ul>
                    </li>
                    <li>ከሰባ ፐርሰንት በላይ የአልኮሆል ድብልቅ ኬሚካል እንድትጠቀሙ ይመከራል</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-5"
            thumbnail="/assets/img/shots/tips/tips-5.jpg" 
            title="የምግብ ግሮሰሪ ለመግዛት ስትወጡ"
            content={
              <div>
                <p><strong>በመደብሮች ውስጥ የሚገኙ በሸቀጣሸቀጥ ሳይሆን ፣ ሱቅ የሚገጥሞዎት ሰዎች ቫይረሱን የበለጠ ሊያስተላልፉ ይችላሉ፡፡</strong> በ <a href="https://www.cdc.gov/coronavirus/2019-ncov/daily-life-coping/essential-goods-services.html" target="_blank">CDC</a> ዘገባ መሠረት “ምግብ ወይም የምግብ ማሸጊያው በአሜሪካ ውስጥ ቫይረሱን በማሰራጨት ረገድ ትልቅ ሚና እንደሚጫወት የሚያሳይ ምንም ማስረጃ የለም፡፡”</p>
                <p>
                  <div className="paragraph-header">የምግብ ግሮሰሪ ግብይት መመሪያዎች</div>
                  <ul className="content-list">
                    <li>ማንኛውም የጉንፋን አይነት ምልክቶች ከታየባችሁ ወይም በቫይረሱ ከተጋለጠ ሰው ጋር ግንኙነት ካላችሁ ፤ የምግብ ግሮሰሪ መሄድ አይመከርም</li>
                    <li>የሸቀጣሸቀጥ ሱቆችን በሳምንት አንድ ጊዜ ወይም በየሁለት ሳምንቱ አንድ ጊዜ ለመሄድ ሞክሩ</li>
                    <li>ከቤተሰብዎ ውስጥ አንድ ሰው ብቻ የምግብ ሸቀጦችን ለመግዛት እንዲችል ይሞክሩ</li>
                    <li>የሚቻል ከሆነ ልጆች ከሌላ አዋቂ ሰው ጋር ቤታቸው እንዲቆዩ ያድርጉ። በመደብሮች ውስጥ ምን ዓይነት ዕቃ እንደሚነኩ ለመከታተል አስቸጋሪ ሊሆን ይችላል ፡፡</li>
                    <li>የገቢያ ጊዜዎን ለመቀነስ የሸቀጣሸቀጦችን ዝርዝር ይፃፉ</li>
                    <li>የግሮሰሪ ጋሪውን እጀታዎች በማፅጃ ኬሚካል ለማፅዳት ሞክሩ</li>
                    <li>በተቻለ መጠን የምትገዙትን እቃ ብቻ ይንኩ</li>
                    <li>በራሳቸው ሸቀጦችን ለመግዛት የማይችሉትን የቤተሰብ አባል ፣ ጓደኛ ወይም ጎረቤት ለመርዳት ይሞክሩ</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">የገዛቹትን ሸቀጣሸቀጥ በኬሚካል ማጽጃ ማጽዳት አያስፈልግዎትም</div>
                  <div>የሸቀጣሸቀጥ እቃዎችን ከያዙ በኋላ እጅዎን እና ወለልኦችን ማጽዳት በጣም አስፈላጊ ነው ፡፡ ፍራፍሬዎችን እና አትክልቶችን ሲያጥቡ ሳሙና አይጠቀሙ፡፡</div>
                  <ul className="content-list">
                    <div>ሆኖም ግን ፣ <a href="https://www.npr.org/sections/health-shots/2020/04/12/832269202/no-you-dont-need-to-disinfect-your-groceries-but-here-s-to-shop-safely" target="_blank">ሸቀጦችዎን ለማፅዳት በእርግጥ ከፈለጉ-</a></div>
                    <li>ሁሉንም አላስፈላጊ የውጭ ፓኬጆችን ወይም ካርቶኖችን ያስወግዱ</li>
                    <li>ለፕላስቲክ ወይም ለካርቶን ማሸጊያ ሳሙና እና ውሃን ይጠቀሙ፡፡ ይህ ፀረ-ተባዮች ኬሚካሎች ወደ ምግብዎ እንዳይገቡ ይከላከላል</li>
                    <li>እንደ መስታወት ጠርሙሶች ወይም ጣሳዎች ባሉ ወለሎች ላይ ኬሚካላዊ ማጽጃዎችን ብቻ ይጠቀሙ</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-6"
            thumbnail="/assets/img/shots/tips/tips-6.jpg" 
            title="ወደቤት ተዘጋጅቶ የሚመጣ ምግብ አያያዝ"
            content={
              <div>
                <p><a href="https://www.fda.gov/food/food-safety-during-emergencies/shopping-food-during-covid-19-pandemic-information-consumers" target="_blank">ኤፍዲኤ</a> እንደዘገበው “ኮቪድ-19 ከሰው ወደ ሰው ይተላለፋል ፣ ብዙውን ጊዜ በተበከለ ምግብ አይተላለፍም ፡፡”</p>
                <p>
                  <ul className="content-list">
                    <li>በበርዎ ላይ እቃዎችን እንዲተው ይጠይቁ</li>
                    <li>ምግቡን ከምግብ ቤት ኮንቴይነሮች ወደ ቤት ኮንቴይነሮች ያስተላልፉ</li>
                    <li>ዕቃዎቻቸውን (ማንኪያ እና ሹካዎች) ለመጠቀም ካቀዱ ፣ ከመጠቀምዎ በፊት በውሃ እና በሳሙና ይጠቧቸው</li>
                    <li>ምግቡን ከመንካትዎ በፊት እጅዎን መታጠብዎን ያረጋግጡ</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-7"
            thumbnail="/assets/img/shots/tips/tips-7.jpg" 
            title="ጥቅሎች እና ደብዳቤ አያያዝ"
            content={
              <div>
                <p>በNEJM <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973?query=featured_home" target="_blank">የምርምር ጥናት መስረት</a> ኮሮና ቫይረስ በካርቶን ላይ እስከ 24 ሰዓታት ድረስ ሊቆይ ይችላል፡፡ ሆኖም የቫይረሱ መኖር በእርግጥ ተላላፊ ሊሆን ይችላል ማለት አይደለም ፡፡ <a href="https://www.cdc.gov/coronavirus/2019-ncov/faq.html" target="_blank">በሲዲሲ</a> ዘገባ መሠረት ቫይረሱ በደብዳቤ ፣ በማሸግ ወይም በማሰራጨት አይተላለፍም ፡፡</p>
                <p>
                  <ul className="content-list">
                    <li>ደብዳቤውን ከከፈቱ በኋላ እጅዎን ይታጠቡ</li>
                    <li>መቀሶችን እና ሌሎች የተበከሉ ቦታዎችን ያፅዱ</li>
                  </ul>
                </p>
              </div>}
          />
          <h4 className="content-tips-disclaimer">የሚከተሉት ምክሮች ለሁሉም ሰው በተመሳሳይ መንገድ ላይሠሩ እንደማይችሉ እናውቃለን ፡፡ የእያንዳንዱ ግለሰብ እና እያንዳንዱ የቤተሰብ ሁኔታ የተለያዩ ናቸው ፡፡ ነገር ግን ፣ የሚከተሉት ምሳሌዎች አንዳንድ ጠቃሚ ሀሳቦችን ሊሰጡዎት ይችላሉ ብለን ተስፋ እናደርጋለን ፡፡ እባክዎን እነዚህ ምክሮች እንዴት ለእርስዎ እንደረዶት ሃሳባዎትን ያካፍሉን ፡፡ </h4>
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Tips;
