import React, { Component } from 'react'
import classnames from 'classnames'

import Button from '../Components/Button'

class About extends Component {

  render() {

    return (
      <div className="about">
        <div className="about-header grid">
          <h1 className="about-title">用知识对抗新冠</h1>
          <p className="about-description">我们是加州大学三藩市分校医学院(UCSF)的医学生、透过和网页设计师的合作、为大家提供一些关于新冠病的可靠资料。</p>
          <p className="about-description">不实的资讯能引起恐慌、甚至可能对你害。由医生和科学家鉴证的资料有时候可能只用英语或比较难明白的科学理论解释。</p>
          <p className="about-description">我们希望能透过这个网页、提供一些可靠和容易理解的资料。有了更多更好的资料和资讯、我们可以更好地对抗新冠病毒。</p>
          <div className="about-disclaimer">
            <h3 className="about-disclaimer-caption">免责声明</h3>
            <p className="about-disclaimer-content">虽然我们不是研究新冠病毒（COVID-19）的专家、但作为医学院的学生、我们拥有足够的医学知识及专业训练。我们更从可靠的研究和参考资料中挑选了一些信息与更多的市民分享。</p>
            <p className="about-disclaimer-content">本网站提供的讯息只属我们个人立场、并不代表UCSF或其它工作机构的立场。特别感谢为本网站内容提供医学顾问及审核的李医生 (Dr. MeLee Lee) 与朴医生 (Dr. Changsoo Park)。</p>
          </div>
          <div className="about-action desktop">
            <a href="mailto:contact@covid19factcheck.com" target="_blank"><div className="btn btn-primary about-action-btn">联络我们</div></a>
            <h4 className="about-action-caption">电邮地址 contact@covid19factcheck.com</h4>
          </div>
        </div>
        <div className="about-team">
          <h1 className="about-team-title">我们的团队</h1>
          <div className="about-team-row grid">
            <div className="about-team-section">
              <h3 className="about-team-section-header">内容策划</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Hallen Pham</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Fiona Ng</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Anuvir Singh</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">网页设计</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Shawn Park</div>
                <h4 className="about-team-member-title">Staff Product Designer, Discord</h4>
                <h4 className="about-team-member-link">
                  <a href="https://designbyroka.com/" target="_blank">Website</a>
                  <a href="https://www.linkedin.com/in/shawn-park-9033a251/" target="_blank">Linkedin</a>
                </h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">阿姆哈拉语翻译</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Beza Dagne</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Yetinayet Dagne</div>
                <h4 className="about-team-member-title">RN BSN, 2nd year Family Nurse Practitioner Student, SJSU</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">阿拉伯语翻译</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Tessnim Ahmad, MD</div>
                <h4 className="about-team-member-title">UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Sarah Semaan</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Amer Alsoudi</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
          </div>
          <div className="about-team-row grid">
            <div className="about-team-section">
              <h3 className="about-team-section-header">中文翻译</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Billy Zeng</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Fiona Ng</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Janette Tang</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Jackie Lin</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Alex Ge</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">韩文翻译</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Eric Kim</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">James Lim</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Eliana Kim</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Sally Oh</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">旁遮普语翻译</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Anuvir Singh</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Prabhjot Kaur</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">俄语翻译</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Liza Leykina</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Katerina Protsenko</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Fima Zaltsman, PhD</div>
                <h4 className="about-team-member-title">Postdoctoral Scholar, UCSF</h4>
              </div>
            </div>
          </div>
          <div className="about-team-row grid">
            <div className="about-team-section">
              <h3 className="about-team-section-header">西班牙语翻译</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Alexis Velazquez</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Jessica Valdez</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Nicole Rodriguez</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Andres Calvillo</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Dani Montejano</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Sandra Perez</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Ivan Iniguez</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">他加禄语翻译</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Joel Jose Quitlong Nario</div>
                <h4 className="about-team-member-title">3rd year Medical Student, Weill Cornell Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Francine Castillo</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">同甘语翻譯</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Ha’ano Fonua</div>
                <h4 className="about-team-member-title">5th year Medical Student, University of Auckland, NZ</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">托克皮辛语翻譯</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Barbara Angoro</div>
                <h4 className="about-team-member-title">PhD Student, School of Pharmacy, University of Auckland</h4>
              </div>
            </div>
          </div>
          <div className="about-team-row grid">
            <div className="about-team-section">
              <h3 className="about-team-section-header">越南语翻译</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Hallen Pham</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Kevin Pham</div>
                <h4 className="about-team-member-title">Molecular, Cell, and Developmental Biology, UCLA</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Kristine Au</div>
                <h4 className="about-team-member-title">De Anza College</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">艺术顾问</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Tammy Nguyen</div>
                <h4 className="about-team-member-link">
                  <a href="http://tamsanity.com/" target="_blank">Portfolio</a>
                  <a href="https://www.instagram.com/tamsanity/?hl=en" target="_blank">Instagram</a>
                  <a href="https://twitter.com/tamsanity_" target="_blank">Twitter</a>
                </h4>
              </div>
            </div>
          </div>
          <h4 className="about-team-thanks">感谢UCSF Asian Pacific American Medical Students Association (APAMSA)、UCSF COVID Student Aid、以及Paul Park (医学生)协助我们招募翻译人员。</h4>
        </div>
        <div className="about-action mobile">
          <a href="mailto:contact@covid19factcheck.com" target="_blank"><div className="btn btn-primary about-action-btn">联络我们</div></a>
          <h4 className="about-action-caption">电邮地址 contact@covid19factcheck.com</h4>
        </div>
      </div>
    )
  }
}

export default About