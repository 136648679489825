import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Learn extends Component {

  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <h4 className="content-nav-caption">Talaan ng Nilalaman</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-1')}>Sa Kabuuan</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-2')}>Paano kumakalat ang COVID-19?</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-3')}>Mga Sintomas</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-4')}>Ano ang dapat kong gawin kung ako ay may sakit?</h4>
        </div>
        <div className="content-body">
          <Card 
            id="learn-1"
            icon="/assets/img/icons/info.svg" 
            title="Sa Kabuuan"
            content={
              <div>
                <p>Ang Coronavirus ay isang pamilya ng mga virus na sanhi ng ilang karaniwang sakit sa paghinga, gaya ng sipon.</p>
                <p>Isang bagong coronavirus ang natukoy noong 2019. “SARS-CoV-2” ang tawag sa virus mismo, samantalang “coronavirus disease 2019” (COVID-19) naman ang tawag sa sakit na dulot nito.</p>
              </div>}
          />
          <Card 
            id="learn-2"
            icon="/assets/img/icons/spread.svg" 
            title="Paano kumakalat ang COVID-19?"
            content={
              <div>
                <p><div className="paragraph-header">Ng tao sa tao</div>Karaniwang kumakalat ang COVID-19 sa mga maliliit na patak ng laway o uhog na tumatalsik kapag ang isang tao ay umuubo, bumabahin, o nagsasalita. Kung nasa loob ng 6 na talampakan (o halos dalawang metro) mula sa isang taong nakakahawa, ang mga patak na ito ay maaring dumapo sa bibig, ilong, o mata, o kaya ay malanghap at makapasok sa baga.</p>
                <p><div className="paragraph-header">Mga gamit na kontaminado</div>Maaari mahawaan kapag hinawakan ang bibig, ilong, o mata matapos humawak ng mga kontaminadong gamit. Mga halimbawa ng gamit na madalas hawakan ng tao ay: hawakan ng pintuan, bukasan ng ilaw, telepono, keyboard, inidoro, gripo, at lababo.</p>
              </div>}
          />
          <Card 
            id="learn-3"
            icon="/assets/img/icons/symptoms.svg" 
            title="Mga Sintomas"
            content={
              <div>
                <div className="content-symptom-container">
                  <ul className="content-symptom-category">
                    <div className="content-symptom-title">Karaniwan</div>
                    <li className="content-symptom-item">Lagnat (100.4°F, 38°C)</li>
                    <li className="content-symptom-item">Tuyong ubo</li>
                    <li className="content-symptom-item">Hirap o kapos na paghinga</li>
                  </ul>
                  <ul className="content-symptom-category">
                    <div className="content-symptom-title">Mga iba pa</div>
                    <li className="content-symptom-item">Panginginig</li>
                    <li className="content-symptom-item">Pananakit ng kalamnan</li>
                    <li className="content-symptom-item">Pananakit ng lalamunan</li>
                    <li className="content-symptom-item">Pagkawala ng pang-amoy at panlasa</li>
                    <li className="content-symptom-item">Pagkahapo o matinding pagkapagod</li>
                    <li className="content-symptom-item">Sipon</li>
                    <li className="content-symptom-item">Pagduwal o pagsusuka</li>
                    <li>Pagtatae</li>
                  </ul>
                </div>
                <div className="content-symptom-caption">Tandaan na marami sa mga nagkaka-virus ay hindi nakakaranas ng anumang sintomas—sila ay tinatawag na “asymptomatic”.</div>
              </div>}
          />
          <Card 
            id="learn-4"
            icon="/assets/img/icons/sick.svg" 
            title="Ano ang dapat kong gawin kung ako ay may sakit?"
            content={
              <div>
                <p>Ayon sa CDC, banayad lang ang sintomas ng nakararami, at maaaring magpagaling nang nasa bahay lamang. Huwag umalis ng tahanan, maliban kung nangangailangan ng agarang tulong medikal. Kung hirap sa paghinga, tumawag sa pangunahing doktor o klinika at humingi ng tulong medikal.</p>
                <hr />
                <p>
                  <ul className="content-list">
                    <div>Tumawag sa 911 kung nangangailangan ng agarang saklolo o tulong; halimbawa:</div>
                    <li>Kung hirap sa paghinga</li>
                    <li>Kung may pananakit sa dibdib</li>
                    <li>Kung may pagkalito o pagkawala sa sarili, o kung mahirap gisingin / pagkawala ng ulirat</li>
                    <li>Kung nangingitim ang kulay ng mga labi o mukha</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <ul className="content-list">
                    <div>Para sa karagdagang impormasyon tungkol sa mapagkukunan ng tulong medikal at iba pang pangangailangang handog ng inyong komunidad, <strong>tumawag sa Sangay ng Kalusugang Pampubliko ng inyong lokal na county.</strong> Pumunta <a href="https://covid19.ca.gov/state-local-resources/#top" target="_blank">rito</a> para sa listahan ng mga sangay-pangkalusugan sa bawat county ng California.</div>
                    <li><a href="https://www.sccgov.org/sites/phd/DiseaseInformation/novel-coronavirus/Pages/home.aspx" target="_blank">Santa Clara County:</a> Tumawag sa 211 para alamin ang tungkol sa COVID-19 at sa iba’t-ibang pangangailangang handog ng inyong komunidad, tulad ng pagkain, masisilungan, gabay o payo, tulong pantrabaho, pangangalaga para sa mga bata, serbisyo para sa matatanda, at marami pang iba (maaaring tumawag anumang araw o oras)</li>
                    <li><a href="https://sf.gov/topics/coronavirus-covid-19" target="_blank">San Francisco:</a> Tumawag sa 311 (kung nasa loob ng SF) o 415-701-2311 para sa mga katanungan, o para makakuha ng gabay sa paghahanap ng tulong-pangkalusugan o iba pang pangangailangang handog ng inyong komunidad (maaaring tumawag anumang araw o oras)</li>
                    <li><a href="http://publichealth.lacounty.gov/media/Coronavirus/" target="_blank">Los Angeles:</a> 211 (maaaring tumawag anumang araw o oras)</li>
                    <li><a href="https://covid19info.ocgov.com/" target="_blank">Orange County:</a> 211 (maaaring tumawag anumang araw o oras)</li>
                    <li><a href="https://www.sandiegocounty.gov/coronavirus.html" target="_blank">San Diego:</a> 211 (maaaring tumawag anumang araw o oras)</li>
                    <li><a href="https://www.co.fresno.ca.us/departments/public-health/covid-19" target="_blank">Fresno:</a> (559) 600-4636 (8a-5p)</li>
                    <li><a href="http://www.acphd.org/2019-ncov.aspx" target="_blank">Alameda:</a> (510) 268-2101</li>
                    <li><a href="https://www.coronavirus.cchealth.org/" target="_blank">Contra Costa:</a> (844) 729-8410 (8a-5p)</li>
                  </ul>
                </p>
                <hr />
                <p>Mangyari pong bisitahin ang <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/disinfecting-your-home.html" target="_blank">website ng CDC</a> para sa mga mahahalagang patnubay upang mabawasan ang kontaminasyon kung ang isang tao ay may sakit.</p>
              </div>}
          />
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Learn;
