import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import classnames from 'classnames'
import {
  BrowserRouter as Router,
  Link,
  Route,
  useLocation,
  useRouteMatch,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom'
import MetaTags from 'react-meta-tags'

import Myth from './Myth'
import Learn from './Learn'
import Tips from './Tips'

import About from './About'
import Header from './Header'
import LanguageModal from '../Components/LanguageModal'
import ShareModal from './ShareModal'

class Tongan extends React.PureComponent {
  state = {
    path: '',
    language: 'to',
    languageModalActive: false,
    shareModalActive: false
  }

  componentDidMount() {
    const {location} = this.props
    this.setState({
      path: location.pathname.slice(4)
    })
  }

  componentDidUpdate(prevProps) {
    const {location} = this.props
    this.setState({
      path: location.pathname.slice(4)
    })
    console.log(this.state.path)
  }

  toggleLanguageModalActive = () => {
    if (!this.state.languageModalActive) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    } else {
      document.getElementsByTagName('body')[0].style.overflow = 'visible'
    }
    this.setState({
      languageModalActive: !this.state.languageModalActive
    })
  }

  toggleShareModalActive = () => {
    if (!this.state.shareModalActive) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    } else {
      document.getElementsByTagName('body')[0].style.overflow = 'visible'
    }
    this.setState({
      shareModalActive: !this.state.shareModalActive
    })
  }

  render() {
    const { path, language, languageModalActive, shareModalActive } = this.state;

    return (
      <div className={classnames('container tongan', {'about-active': path === 'about'})}>
        <MetaTags>
          <title>COVID-19 Fact Check</title>
          <meta name="description=" content="Ako ki he mo’oni´ mo e loi´ ka ke mateuteu lelei mo vahevahe ho ‘ilo´ mo e kakai kehe´" />
          <meta property="og:title" content="COVID-19 Fact Check" />
          <meta property="og:url" content="http://www.covid19factcheck.com/to/" />
          <meta property="og:description" content="Ako ki he mo’oni´ mo e loi´ ka ke mateuteu lelei mo vahevahe ho ‘ilo´ mo e kakai kehe´" />
          <meta property="og:site_name" content="COVID-19 Fact Check" />
          <meta property="og:image" content="http://www.covid19factcheck.com/assets/img/thumbnails/social_cover.png" />
          <meta name="twitter:title" content="COVID-19 Fact Check" />
          <meta name="twitter:description" content="Ako ki he mo’oni´ mo e loi´ ka ke mateuteu lelei mo vahevahe ho ‘ilo´ mo e kakai kehe´" />
          <meta name="twitter:image" content="http://www.covid19factcheck.com/assets/img/thumbnails/social_cover.png" />
        </MetaTags>

        <div className="topbar">
          <div className="topbar-logo"><a href="/to/"><img className="fit-parent" src="/assets/img/icons/logo.svg" /></a></div>
          <div className="topbar-nav">
            <h4 className="topbar-nav-item mobile"><a href="/to/about">Ko hai kimautolu</a></h4>
            <h4 className="topbar-nav-item mobile"><a href="mailto:contact@covid19factcheck.com" target="_blank">Fetu’utaki mai</a></h4>
          </div>
        </div>
        <Header language='to' toggleLanguageModalActive={this.toggleLanguageModalActive} toggleShareModalActive={this.toggleShareModalActive} path={path} />
        <div className="content">
          <Switch>
            <Route path="/to/learn" render={(props) => <Learn path={path} />} />
            <Route path="/to/tips" render={(props) => <Tips path={path} />} />
            <Route path="/to/about" render={(props) => <About />} />
            <Route render={(props) => <Myth path={path} />} />
          </Switch>
        </div>
        <LanguageModal active={languageModalActive} path={path} toggleLanguageModalActive={this.toggleLanguageModalActive} />
        <ShareModal active={shareModalActive} toggleShareModalActive={this.toggleShareModalActive} />
    	</div>
    );
  }
}

export default withRouter(Tongan)
