import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Tips extends Component {
  
  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <h4 className="content-nav-caption">Olgeta sekesn we i stap insaed</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-1')}>Infomesen long saed blong wasem han</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-2')}>Infomesen blong olsem wanem blong yusum Mask</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-3')}>Olgeta infomesen long olsem wanem blong yusum hanglav</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-4')}>Olgeta infomesen blong Klining</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-5')}>Mekem Shoping</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-6')}>Olsem wanem blong tritim olgeta kakae we oli delivarem</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-7')}>Olsem wanem blong tritim olgeta Pajek & Mel
</h4>
        </div>
        <div className="content-body">
          <Card 
            id="tips-1"
            thumbnail="/assets/img/shots/tips/tips-1.jpg" 
            title="Infomesen long saed blong wasem han"
            content={
              <div>
                <p>Mein samting blong stopem COVID-19 i spred hemi blong wasem hand blong yu evritaem. No tajem ae, nus, o maot blong yu bifo yu wasem han blong yu.</p>
                <hr />
                <p>
                  <div className="paragraph-header">Infomesen long olsem wanem blong Wasem Gud Han</div>
                  <ol className="content-list">
                    <li><div className="ol-index">1</div>Mekem han blong yu i wetwet long wan wota we i stap ron</li>
                    <li><div className="ol-index">2</div>Putum sop long han mo rabem han tugeta, mekem tu long olgeta spes bitwin olgeta finga blong yu, long bihaen blong han mo anda long olgeta finganel blong yu.</li>
                    <li><div className="ol-index">3</div>Wasem han blong yu blong 20 seken (singsing “Hapi Betdei” tu taem)</li>
                    <li><div className="ol-index">4</div>Rinsim han blong yu</li>
                    <li><div className="ol-index">5</div>Waepem han blong yu long tawel o letem i drae nomo olsem</li>
                  </ol>
                  <h5 className="content-source"><a href="https://www.cdc.gov/handwashing/when-how-handwashing.html" target="_blank">Source: CDC</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/CSJO52qZLbs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p>
                  <div className="paragraph-header">Wetaem nao yu shud wasem han blong yu?</div>
                  <ul className="content-list">
                    <li>Plante taem</li>
                    <li>Afta we yu stap long wan pablik ples</li>
                    <li>
                      <div>Afta we yu tajem olgeta seves we plante man i stap tajem</div>
                      <ul>
                        <li>Eksampol: handel blong doa, tebol, pam blong gas, basket blong shoping, kash rejista, olgeta baten blong entarem pin kod, olgeta baten blong eleveta, mo olgeta narafala moa samting olsem.</li>
                      </ul>
                    </li>
                    <li>Bifo, long taem blong , mo afta priperem kakae</li>
                    <li>Bifo yu kakae</li>
                    <li>Afta we yu kof, sniz, mo blowem nus blong yu</li>
                    <li>Bifo yu putum mo afta yu tekamaot mask o hanglov blong yu</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">Sipos i nogat sop mo wota, yusum wan han sanitaeza we i gat alkohol long hem</div>
                  <ul className="content-list">
                    <li>Olgeta han sanitaeza oli no kilim evri kaen bebet</li>
                    <li>I shud gat samples 60% blong alkohol olsem long hem</li>
                    <li>Rabem gel long ful seves blong han blong yu, bitwin olgeta finga, mo long en blong finganel blong yu</li>
                    <li>Rabem han blong yu tugeta kasem taem oli drae</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-2"
            thumbnail="/assets/img/shots/tips/tips-2.jpg" 
            title="Infomesen blong olsem wanem blong yusum Mask"
            content={
              <div>
                <p>
                  <div className="paragraph-header">Olgeta gudfala wei blong Yusum Mask</div>
                  <ul className="content-list">
                    <div className="content-list-label-positive">Wanem blong mekem</div>
                    <li>Mekem sua se yu save brit tru long hem</li>
                    <li>Mekem sua i kavremap nus mo maot blong yu (long nus kasem andanit long jin blong yu)</li>
                    <li>Yusum olgeta strap we i stap long saed taem yu wantem karemaot mask blong yu</li>
                    <li>Wasem mo draemap olgeta mask we oli mekem long kaliko nomo</li>
                    <li>Wasem han blong yu afta we yu karemaot mask long fes blong yu</li>
                    <li>Putum olgeta i stap long wan bag we hemi blong kipim olgeta mask long hem kasem taem oli klinim olgeta</li>
                  </ul>
                  <ul className="content-list">
                    <div className="content-list-label-negative">Wanem blong no mekem</div>
                    <li>Tajem foret blong mask blong yu we ples ia nao hemi toti moa</li>
                    <li>Pulum daon mask i kam daon long jin blong yu o mekem i stap raon long nek</li>
                    <li>Mekem se i gat spes long saedsaed taem yu putum mask</li>
                    <li>Karemaot mask taem yu werem hanglav</li>
                    <li>Yusum bakegen olgeta mask blong dokta</li>
                  </ul>
                  <h5 className="content-source">Infomesen i kam long: <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a>; <a href="https://www.nytimes.com/2020/04/08/well/live/coronavirus-face-mask-mistakes.html#click=https://t.co/GkBNJAuw1V" target="_blank">The New York Times</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/m4O-3wQQjpA?start=118" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p>
                  <div className="paragraph-header">Fasin blong putum mask blong kavremap fes  hemi NO wan narafala step blong stopem sik ia</div>
                  <div>Sipos yu tingting blong werem mask mo/o hanglav, mekem sua yu gohed blong mekem olsem:</div>
                  <ul className="content-list">
                    <li>Wasem gud han</li>
                    <li>Stap 2 mita long wei long narafala man</li>
                    <li>Stap insaed nomo long haos</li>
                  </ul>
                  <div>Nomata we olgeta samting blong protektem yumi olsem mask, hanglav mo olgeta narafala moa samting olsem, oli save help blong katem daon spred blong sik ia, hemi save givim tu wan tingting se olgeta samting ia nao bae oli protektem yumi. Olgeta samting blong protektem yumi oli kam long daerek kontak wetem olgeta seves we ating i gat vaeres ia long hem. Sipos yumi no yusum gud bambae i save spred i go moa bakegen mo yu save kasem vaeres ia.</div>
                </p>
              </div>}
            extra={
              <div>
                <p>
                  <div className="paragraph-header">From wanem nao CDC i rekomendem blong man i kavremap fes blong hem long pablik?</div>
                  <div>Folem wanem <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover.html" target="_blank">CDC</a>, i talem, yu save spredem koronavaeres nomata sapos yu nogat olgeta saen blong sik ia long yu (hemia oli kolem “asymptomatic”). Taem yu werem mask mo kipim distens blong yu blong 2 mita longwe long narafala man yu save help blong stopem koronavaeres i spred insaed long komuniti.</div>
                  <ul className="content-list">
                    <div>Sam long olgeta man oli NO shud putum mask!</div>
                    <li>Olgeta pikinini anda long 2 yia</li>
                    <li>Olgeta we oli stap fesem had taem blong brit</li>
                    <li>Olgeta we oli ankonses</li>
                    <li>Olgeta we oli no save karemaot mask olgeta nomo sapos i nogat man blong helpm olgeta</li>
                  </ul>
                </p>
                <p>Long US, CDC i rekomendem man blong yusum olgeta materiol long haos we oli sef olsem olgeta olfala t-shet o bandanas. Plis luk <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">step-by-step guide</a> long olsem wanem nao blong mekem wan mask long t-shet o bandanas.</p>
                <p><strong>Yu no nid blong werem olgeta kaen mask ia we olgeta dokta oli stap werem o N-95 respireta.</strong> Hemi tru tu from se CDC i no rekomendem blong man i pem olgeta kaen mask ia mo N-95 respireta from se.</p>
              </div>
            }
          />
          <Card 
            id="tips-3"
            thumbnail="/assets/img/shots/tips/tips-3.jpg" 
            title="Olgeta infomesen long olsem wanem blong yusum hanglav"
            content={
              <div>
                <p>Hanglav i NO protektem man long pablik. Be, sapos yu wantem werem hanglav, hemia hemi sam long olgeta infomesen.</p>
                <p>
                  <ul className="content-list">
                    <div className="content-list-label-positive">Wanem Blong Mekem</div>
                    <li>Wasem han bifo mo stret afta we yu karemaot hanglav</li>
                    <li>Sakem mo no yusum bakegen olgeta plastik hanglav</li>
                    <li>Blong olgeta hanglav we oli mekem long kaliko, wasem olgeta wetem sop mo wota bifo yu yusum bakegen</li>
                  </ul>
                  <ul className="content-list">
                    <div className="content-list-label-negative">Wanem Blong NoMekem</div>
                    <li>Tajem fes, maot, nus mo ae</li>
                    <li>Putum han blong yu long poket</li>
                    <li>Yusum mobael fon blong yu</li>
                  </ul>
                  <h5 className="content-source">Infomesen i kam long: <a href="https://www.kqed.org/science/1961243/coronavirus-heres-how-to-use-gloves-properly-to-help-prevent-infection" target="_blank">KQED</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/TnLCYqk20YU?start=116" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>}
          />
          <Card 
            id="tips-4"
            thumbnail="/assets/img/shots/tips/tips-4.jpg" 
            title="Olgeta infomesen blong Klining"
            content={
              <div>
                <p>Folem olgeta data blong tede, taem man i tajem seves we i gat vaeres long hem hemia hemi no mein wei we COVID-19 i spred. Bambae yu no save kasem vaeres sapos yu tajem nomo olgeta samting we vaeres i stap long hem. Be, yu save kasem vaeres ia sipso i gat vaeres long han blong yu, afta yu tajem ae, nus, o maot blong yu. Fasin blong klinim seves mo wasem han afta we yu tajem seves ia i save help blong katem daon risk blong vaeres ia i spred.</p>
                <hr />
                <p>
                  <div className="paragraph-header">I tekem hamas haoa nao blong Koronavaeres i stap nomo long olgeta difren kaen seves</div>
                  <div className="content-table">
                    <div className="content-table-row">
                      <div className="content-table-column">
                        <h3 className="content-table-heading">4 haoa</h3>
                        <h4 className="content-table-caption">Kopa</h4>
                      </div>
                      <div className="content-table-column">
                        <h3 className="content-table-heading">24 haoa</h3>
                        <h4 className="content-table-caption">Katen</h4>
                      </div>
                    </div>
                    <div className="content-table-row">
                      <div className="content-table-column">
                        <h3 className="content-table-heading">2-3 dei</h3>
                        <h4 className="content-table-caption">Plastik</h4>
                      </div>
                      <div className="content-table-column">
                        <h3 className="content-table-heading">2-3 dei</h3>
                        <h4 className="content-table-caption">Ayan</h4>
                      </div>
                    </div>
                  </div>
                  <div>Nomata koronavaeres i stap blong plante haoa o dei, amaon blong vaeres long olgeta seves ia i stap go daon taem taem i stap go pas mo i mekem se janis blong kasem vaeres ia i no hae. (Infomesen ia i folem nomo stadi we oli pablisim insaed long <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973" target="_blank">The New England Journal of Medicine</a> long 3/17/2020)</div>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">Wanem nao bae mi yusum blong klinim olgeta seves?</div>
                  <ul className="content-list">
                    <div>Plis visitim websaet blong save moa rekomendesen long olsem wanem blong <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cleaning-disinfection.html" target="_blank">klinim olgeta difren kaen seves.</a> CDC i rekomendem blong yusum olgeta ia:</div>
                    <li>EPA-Apruv Disinfeksen <a href="https://www.cnn.com/2020/03/05/health/epa-disinfectants-coronavirus-trnd/index.html" target="_blank">(luk list blong ples ia)</a></li>
                    <li>
                      <div>Blij</div>
                      <ul>
                        <li>5 tebol spun blong evri galon wota we tekem</li>
                        <li><strong>O</strong> 4 tispun blij blong evri kwota blong wota yu tekem</li>
                        <li>No kapsaedem blij i go daerek long seves</li>
                        <li>Mekem sua se blij i no ekspae</li>
                        <li>NO miksim blij wetem ammonia o wetem olgeta narafala kemikol olsem</li>
                      </ul>
                    </li>
                    <li>Samting we i gat 70% blong alkohol long hem</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-5"
            thumbnail="/assets/img/shots/tips/tips-5.jpg" 
            title="Mekem Shoping"
            content={
              <div>
                <p>Bambae yu save kasem sik ia nom long olgeta pipol insaed long stoa be i no long olgeta samting insaed long stoa ia. Folem wanem CDC i talem, “i nogat pruv we i soemaot se olgeta kakae insaed long stoa nao olgeta nao oli spredem bigwan vaeres ia long Unaeted Stet blong Amerika”</p>
                <p>
                  <div className="paragraph-header">Olgeta tingting blong mekem shoping blong yu i sef</div>
                  <ul className="content-list">
                    <li>Yu no mas go mekem shoping sipos yu stap gat problem taem yu brit o yu kasem sik ia</li>
                    <li>Traem go shoping wan taem nomo long wan wik o wan taem nomo long tu wik olsem</li>
                    <li>Traem blong gat blong gat wan man nomo long famili blong go mekem shoping</li>
                    <li>Sipos hemi oraet, traem mekem olgeta pikinini oli stap nom olong haos wetem wan man</li>
                    <li>Raetem lis blong shoping blong yu blong mekem se yu no tekem tumas taem blong shoping</li>
                    <li>Werem mask blong yu mo kipim distens oltaem long narafala man</li>
                    <li>Waepem olgeta handel blong troli bifo yu pusum blong mekem shoping blong yu</li>
                    <li>Sipos hemi oraet, jsusum wan peimen we bae yu no save tajem han blong stoa kipa</li>
                    <li>Afta we yu livim stoa, wasem han blong yu wetem han sanitaeza</li>
                    <li>Taem yu kasem haos, wasem han blong yu wetem sop mo wota blong 20 seksen olsem</li>
                    <li>Helpem wan memba blong famili, fren, o neba we oli no save go mekem shoping blong olgeta </li>
                  </ul>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">Yu no nid blong klinim olgeta samting yu pem</div>
                  <div>Hemi impoten tumas blong yu wasem han blong yu mo seves afta we lukluk long olgeta samting we pem. No tajem sop taem yu stap rinsim olgeta frut o vegetebol.</div>
                  <ul className="content-list">
                    <div>Be, <a href="https://www.npr.org/sections/health-shots/2020/04/12/832269202/no-you-dont-need-to-disinfect-your-groceries-but-here-s-to-shop-safely" target="_blank">sipos yu wantem tumas blong klinim olgeta samting yu pem</a>:</div>
                    <li>Sakem olgeta ekstra pakejing we yu no nidim</li>
                    <li>Yusum nomo olgeta sanitaeza spre mo waep blong klinim glas o tin</li>
                    <li>Yusum sop mo wota long olgeta plastik o katen pakejing. hemia hemi blong stopem kemikol blong lik i go long kakae blong yu</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-6"
            thumbnail="/assets/img/shots/tips/tips-6.jpg" 
            title="Olsem wanem blong tritim olgeta kakae we oli delivarem"
            content={
              <div>
                <p>Folem wanem <a href="https://www.fda.gov/food/food-safety-during-emergencies/shopping-food-during-covid-19-pandemic-information-consumers" target="_blank">FDA</a>, i talem, “COVID-19 i spred tru long wan man i go long narafala man, i no olsem foodborne gastrointestinal o GI vaeres we samtaem i stap mekem man i sik long olgeta kakae we oli nogud”</p>
                <p>
                  <ul className="content-list">
                    <li>Delivari: Hemi askem blong livim kake long doa nomo</li>
                    <li>Transferem olgeta kake long olgeta kontena blong restorong i go long olgeta kontena blong haos</li>
                    <li>Sapos yu tingtin blong yusum olgeta tul blong olgeta nomo, wasem olgeta wetem wota mo sop bifo yu yusum</li>
                    <li>Mekem sua yu wasem han blong yu bifo yu tajem kakae</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-7"
            thumbnail="/assets/img/shots/tips/tips-7.jpg" 
            title="Olsem wanem blong tritim olgeta Pajek & Mel"
            content={
              <div>
                <p>Long wan NEJM <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973?query=featured_home" target="_blank">risej stadi</a>, wan smol amaon blong koronavaeres i save stap long katen blong 24 haoa. Be, Hemia i no minim se taem i gat vaeres long hem bae hemi otomatik nomo blong yu kasem. Folem wanem <a href="https://www.cdc.gov/coronavirus/2019-ncov/faq.html" target="_blank">CDC</a> i talem, hemi no posibol tumas blong vares i spred tru long mel, olgeta prodak o pakej.</p>
                <p>
                  <ul className="content-list">
                    <li>Wasem han blong yu afta we yu openem pakej o mel blong yu</li>
                    <li>Klinim sisis mo olgeta narafala kaen seves</li>
                  </ul>
                </p>
              </div>}
          />
          <h4 className="content-tips-disclaimer">Mifala i andastanem se olgeta infomesen ia bambae i no aplae long semak wei olsem long evriman. Situesen mo risos blong wanwan man mo famili i difren. Mifala i hop se olgeta eksampol ia bambae i save helpem yu blong gat sam tingting we bae i save help. Plis letem mifala i save olsem wanem nao olgeta infomesen ia i wok long yu!</h4>
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Tips;
