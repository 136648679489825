import React, { Component } from 'react'
import classnames from 'classnames'
import {
  BrowserRouter as Router,
  Link,
  Route,
  useLocation,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom'

import Button from '../Components/Button'

class Header extends React.PureComponent {

  render() {

    const { toggleLanguageModalActive, toggleShareModalActive, path } = this.props;

    return (
      <div className="header-container">
        <div className="header grid">
          <h1 className="header-title">COVID-19<br />Fact Check</h1>
          <div className="header-description">Lanem olgeta tru mo kiaman storian blong i mekem yu save stap rere mo letem olgeta narafala man i save long hem.</div>
          <div className="header-action mobile">
            <Button className="btn-secondary" label="Bislama" imgURL="/assets/img/icons/arrow_down.svg" onClick={toggleLanguageModalActive} />
            <Button className="btn-primary" label="Serem" imgURL="/assets/img/icons/share.svg" onClick={toggleShareModalActive} />
          </div>
          <div className="header-caption mobile">
            <h5 className="header-caption-item">Plante infomesen i kam long <a href="https://www.who.int/" target="_blank">WHO</a> and <a href="https://www.cdc.gov/" target="_blank">CDC.</a> </h5>
            <h5 className="header-caption-item">Infomesen we i jes kamaot nomo 10/26/20.</h5>
          </div>
        </div>
        <div className="nav">
          <a href="/bi/" className={classnames('nav-item', {'nav-item-active': path === ''})}>Infomesen we i no Tru</a>
          <a href="/bi/tips" className={classnames('nav-item', {'nav-item-active': path === 'tips'})}>Infomesen blong helpem yu</a>
          <a href="/bi/learn" className={classnames('nav-item', {'nav-item-active': path === 'learn'})}>Lanem wanem nao hemi COVID-19</a>
          <a href="/bi/about" className={classnames('nav-item desktop', {'nav-item-active': path === 'about'})}>Infomsen long saed blong mifala</a>
          <a href="mailto:contact@covid19factcheck.com" target="_blank" className="nav-item desktop">Kontaktem mifala</a>
        </div>
      </div>
    )
  }
}

export default withRouter(Header)