import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Myth extends Component {

  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <div className="content-nav-scroller">
            <h4 className="content-nav-caption">Talaan ng Nilalaman</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-1')}>Dapat bang 6 na talampakan (o isang dipa) ang agwat ko sa iba kahit na may takip ako sa mukha?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-10')}>Maiiwasan ba ang COVID-19 kung maglalagay ako sa katawan o iinom ng bleach o iba pang pangdisimpekta?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-2')}>Kailangan ko bang bumili ng maskarang pang-ospital (surgical masks) o N95?</h4>
            {/* <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-3')}>Can coronavirus spread far distances through the air?</h4> */}
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-4')}>Nakakatiyak ba ang aking kaligtasan sa coronavirus kung magsusuot ako ng guwantes?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-5')}>Nabubuhay ba ang coronavirus ng mga ilang araw sa iba’t-ibang uri ng materyales?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-6')}>Kailangan ko bang madalas maghugas ng kamay o magdisimpekta ng kagamitan kahit na hindi ako umaalis ng bahay?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-7')}>Nakakapagpalala ba ng sakit ang ibuprofen sa mga taong may COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-8')}>Kaya ko bang maiwasan ang COVID-19 kung ako ay gagamit ng hydroxychloroquine at azithromycin?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-9')}>Mabisa ba ang antibiotic na pamuksa o panlaban sa COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-11')}>Kung ako ay bata-bata pa at malusog naman, ligtas ba ako sa bagong coronavirus?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-12')}>Kailangan ko bang maospital kung ako ay may COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-13')}>Kaya bang suriin ang COVID-19 sa pagpigil ng paghinga sa 10 segundo o higit nang walang pag-ubo o iba pang abnormal na nararamdaman?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-14')}>Napapatay ba ng pantuyo ng kamay (hand dryer) at ng lamparang ultraviolet (UV) ang coronavirus?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-15')}>Kaya bang pigilan ang COVID-19 ng madalas na pagbanlaw ng ilong gamit ang tubig na may asin (saline)?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-16')}>Napipigilan ba ng mainit na pampaligo ang COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-17')}>Napipigilan ba ng malamig na panahon at ng pagnyebe ang COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-18')}>Napipigilan ba ng pagkain ng bawang ang COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-19')}>Napipigilan ba ng pag-inom ng alak ang COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-20')}>Protektado ka ba ng mga bakuna sa pulmonya laban sa COVID-19?</h4>
          </div>
        </div>
        <div className="content-body">
          <Card 
            id="myth-1"
            thumbnail="/assets/img/shots/myth/myth-1.jpg" 
            title="Dapat bang 6 na talampakan (o isang dipa) ang agwat ko sa iba kahit na may takip ako sa mukha?"
            content={
              <div>
                <p><span className="card-tag-true">Oo</span>Panatilihin pa rin ang agwat na 6 na talampakan (isang dipa, o halos dalawang metro) mula sa iba. Maghugas ng kamay ng mabuti at madalas gamit ang sabon at tubig. Maghugas din ng kamay bago magsuot at pagkatapos magtanggal ng takip sa mukha.</p>
                <p>Sa Estados Unidos, payo ng <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover-faq.html" target="_blank">CDC</a> ang gumamit ng maskarang gawa sa tela upang mapigilan ang pagkalat ng virus. Ang virus ay maaaring nasa patak ng laway o uhog na tumatalsik habang bumabahin, umuubo, o nagsasalita ang isang tao. Gayunpaman, ang proteksyon ay depende sa sukat at materyal ng tela na ipinantatakip sa mukha, at maaari pa ring hindi lubusang protektado ang isang tao sa lahat ng patak na may virus. Mangyaring basahin ang aming “Mga Gabay at Payo”.</p>
              </div>}
            extra={
              <div>
                <p>Mangyaring sundin ang mga patnubay mula sa <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a>, at gumamit ng ligtas na materyal para sa pagbuo ng ipantatakip sa mukha. Tiyaking maayos ang kapit ng takip sa mukha, ngunit alalahanin din na ito ay hindi masyadong hapit na mahihirapang huminga ang magsusuot. Payo ng CDC ang gumamit ng ilang patong na tela. Walang partikular na klase ng tela ang isinusulong ng CDC.</p>
                <p><div className="paragraph-header">Gaano kagaling ang mga maskarang gawa sa tela laban sa coronavirus?</div>Hindi pa masagot ng siyensya ang tanong na ito. Sa isang <a href="https://www.ncbi.nlm.nih.gov/pubmed/24229526" target="_blank">pag-aaral sa laboratoryo</a> gamit ang ibang uri ng virus (hindi SARS-CoV-2), napag-alaman na wala ni isa sa mga klase ng tela (100% bulak, kamiseta, iskarp o balabal, seda, punda, lino, pinaghalong bulak, tuwalyang pang-tsaa) ang kayang makapigil ng 100% ng virus dahil sa napakaliit na sukat ng virus. Gayunpaman, may ilang limitasyon ang pag-aaral na ito. Ang eksperimentong ito ay hindi kahalintulad ng karaniwang ubo ng tao. Ang pag-aaral na ito ay ginamitan ng isang aparato. Hindi rin tiningnan dito kung gaano kaayos ang kapit ng mga pantakip. Dahil madalas na hindi maayos ang kapit ng mga ipinantatakip natin sa mukha, kailangan nating tandaan na <a href="https://www.gannett-cdn.com/experiments/usatoday/gifs/tool/919-surgical.png" target="_blank">kaya pa ring pumasok ng virus sa mga gilid nito.</a></p>
                <p><div className="paragraph-header">Pangunahing Puntos na Dapat Tandaan</div>Ang telang maskara ay makakatulong sa pagpigil ng pagkalat ng virus, ngunit kailangang tandaan na hindi ito nagbibigay ng ganap na proteksyon.</p>
              </div>}
          />
          <Card 
            id="myth-10"
            thumbnail="/assets/img/shots/myth/myth-10.jpg" 
            title="Maiiwasan ba ang COVID-19 kung maglalagay ako sa katawan o iinom ng bleach o iba pang pangdisimpekta?"
            content={
              <div>
                <p><span className="card-tag-false">Hindi</span><strong>HUWAG na HUWAG</strong> <a href="https://www.rb.com/media/news/2020/april/improper-use-of-disinfectants" target="_blank">iturok</a>, langhapin, inumin, o i-spray sa katawan ang anumang disimpektante, alkohol, o klorox (bleach) dahil ito ay <strong className="red">mapanganib sa katawan ng tao.</strong></p>
                <p>Ayon sa <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>,  “Ang mga kemikal na ito ay lason kapag ininom at nakasisira sa balat at mata.” Ang bleach at iba pang panlinis ay dapat lamang ginagamit sa pagdidisimpekta ng mga ibabaw, nang naaayon lamang sa <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cleaning-disinfection.html" target="_blank">mga tamang rekomendasyon.</a> Palaging tandaan na ilayo ang mga kemikal na ito sa mga bata sa hindi nila maaabot.</p>
              </div>}
          />
          <Card 
            id="myth-2"
            thumbnail="/assets/img/shots/myth/myth-2.jpg" 
            title="Kailangan ko bang bumili ng maskarang pang-ospital (surgical masks) o N95?"
            content={
              <div>
                <p><span className="card-tag-false">Hindi</span>Payo ng <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a> ang gumamit ng mga <strong>maskarang gawa sa tela</strong> para sa publiko sa Estados Unidos.</p>
                <p>Maaaring suotin ang anumang maskarang mayroon na sa bahay, at hindi na kailangan pang bumili ng maskarang pang-ospital (surgical mask) o ng N95. Dahil sa kakulangan ng mga ito sa Estados Unidos, ang mga maskarang pang-mediko ay dapat lang ilaan para sa mga manggagawang pangkalusugan o manggagawa sa ospital. Ito ay dahil sanay sila sa tamang paggamit ng mga maskarang ito, at dahil madalas nilang kinakailangang makipagsalamuha sa maysakit. Pag-isipang iabuloy na lamang ang mga sobrang N95 na mayroon, dahil kinakailangan ang N95 upang malimitahan ang paghawa ng anumang virus na maaaring nakalutang sa hangin. (Gayunpaman, ang pinakakaraniwang pagkalat pa rin ng COVID-19 ay sa pamamagitan ng mga patak na tumatalsik sa pag-ubo, pagbahin, at pagsasalita. Bukod pa rito, ang mga patak na ito ay masyadong mabigat para manatili sa hangin. Basahin sa ibaba ang mga tama o mali tungkol sa pagkalat ng virus na maaaring nakalutang sa hangin.)</p>
              </div>}
            extra={
              <div>
                <p>Ang <a href="https://www.fda.gov/medical-devices/personal-protective-equipment-infection-control/n95-respirators-and-surgical-masks-face-masks" target="_blank">maskarang N95</a> ay may natatanging pangsala ng mga partikulo sa ere. Kayang salain ng N95 ang hindi bababa sa 95% ng mga napakaliit na partikulo (0.3 micron ang laki) kung maayos ang kapit nito sa mukha at kung walang nakakalabas o nakakapasok na hangin dito. Bukod sa pagtuturo at pagsasanay sa pagsusuot ng N95, bawat taon ginagawa din ang <a href="https://www.safetyalliance.org/wp-content/uploads/2019/06/fit-test-2-000110_retouched-672x372.jpg" target="_blank">pagtetest ng kapit</a> ng N95 sa mukha, para masiguro na tama ang sukat nito upang makapagbigay ito ng pinakamaayos na proteksyon at kapit sa mukha. Kapag sinuot ng tama ang N95, maaaring mahirapan ang isang tao sa paghinga dahil sa kinakailangang mahigpit na kapit nito. Kahit na pinakamaliit na bagay, tulad ng buhok sa mukha, ay sapat na para sirain ang maayos na kapit. Dahil ang <a href="https://blogs.cdc.gov/niosh-science-blog/2018/01/04/respirators-public-use/" target="_blank">publiko</a> ay walang pagsasanay sa tamang pagsuot ng N95, at dahil hindi rin nakakatanggap ang publiko ng pagtetest ng kapit nito sa mukha, maaaring hindi kasing-bisa ang paggamit ng N95 para sa publiko.</p>
              </div>}
          />
          {/*
          <Card 
            id="myth-3"
            thumbnail="/assets/img/shots/myth/myth-3.jpg" 
            title="Can coronavirus spread far distances through the air?"
            content={
              <div>
                <p><span className="card-tag-maybe">Maaari</span>Airborne transmission is unlikely for the general public.</p>
                <p>According to <a href="https://www.who.int/news-room/q-a-detail/q-a-coronaviruses" target="_blank">WHO</a>, “COVID-19 is mainly transmitted through droplets generated when an infected person coughs, sneezes, or speaks. These droplets are too heavy to hang in the air. They quickly fall on floors or surfaces.” Hindi airborne transmission was reported among an analysis of over 75,000 COVID-19 cases in China. However, health care workers may be at risk. WHO reports airborne transmission may be possible during <a href="https://www.who.int/news-room/commentaries/detail/modes-of-transmission-of-virus-causing-covid-19-implications-for-ipc-precaution-recommendations" target="_blank">hospital procedures that generate aerosols</a> such as intubation, where a breathing tube is inserted into the patient’s airway to help them breathe.</p>
                </div>}
            extra={
              <div>
                <p><div className="paragraph-header">What does airborne transmission mean?</div>In contrast to respiratory droplets, airborne particles are smaller and remain in the air for long periods of time where they can spread farther than 6 feet.</p>
                <p><div className="paragraph-header">Why does the news say coronavirus is airborne?</div>A NEJM <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973" target="_blank">study</a> artificially created aerosols of the coronavirus in a rotating cylinder, where it remained viable for 3 hours. However, the high-powered machine does not represent a normal human cough. In a non peer-reviewed <a href="https://www.medrxiv.org/content/10.1101/2020.03.23.20039446v2" target="_blank">study</a> (has not been evaluated by other experts), researchers sampled for the presence of coronavirus in the air and surfaces of COVID-19 patients’ hospital room. 76.5% of all personal belongings were positive (cell phones, toilets, remotes, bed rails, etc.). For air samples, 63.2% were positive and some samples were collected greater than 6 feet away from the patient. However, the presence of a virus does not automatically mean it can cause disease. For example, the amount of virus may be too low to cause infection. The authors have not proven that the components of the virus that they have detected can cause infections.</p>
              </div>}
          /> */}
          <Card 
            id="myth-4"
            thumbnail="/assets/img/shots/myth/myth-4.jpg" 
            title="Nakakatiyak ba ang aking kaligtasan sa coronavirus kung magsusuot ako ng guwantes?"
            content={
              <div>
                <p><span className="card-tag-false">Hindi</span>Ang guwantes ay may silbi lamang kung susundin pa rin ang mga tamang pag-iingat. HUWAG hawakan ang mukha habang nakaguwantes. Tiyaking maghugas ng kamay bago suotin at pagkatapos alisin ang guwantes. Alalahanin din na maaaring kumalat ang anumang kontaminasyong mayroon ang guwantes ninyo kung dadapo o ipapatong ito sa iba’t-ibang mga bagay.</p>
              </div>}
          />
          <Card 
            id="myth-5"
            thumbnail="/assets/img/shots/myth/myth-5-tl.jpg" 
            title="Nabubuhay ba ang coronavirus ng mga ilang araw sa iba’t-ibang uri ng materyales?"
            content={
              <div>
                <p><span className="card-tag-true">Oo</span>Ipinakita sa <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973?query=featured_home" target="_blank">New England Journal of Medicine</a> na sa isang eksperimento sa laboratoryo, makikita pa rin ang coronavirus sa karton hanggang 24 oras, at maging sa plastik at bakal ng hanggang 72 oras.</p>
                <p>Mahalagang alalahanin na pawang 0.1% lang ng orihinal na bilang ng virus ang natitira sa mga materyales pagkatapos ng 24 o 72 oras. Ang presensya ng virus ay hindi nangangahulugang makakahawa pa ang mga ito. Dahil mabilis na lumiliit ang bilang ng virus sa materyales sa paglipas ng oras, ang tsansa na ikaw ay makontamina ay maliit lamang.</p>
              </div>}
          />
          <Card 
            id="myth-6"
            thumbnail="/assets/img/shots/myth/myth-6.jpg" 
            title="Kailangan ko bang madalas maghugas ng kamay o magdisimpekta ng kagamitan kahit na hindi ako umaalis ng bahay?"
            content={
              <div>
                <p><span className="card-tag-true">Oo</span>Payo ng <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/prevention.html" target="_blank">CDC</a> ang maglinis at magdisimpekta ng mga kagamitang madalas na mahawakan, tulad ng hawakan ng pintuan, bukasan ng ilaw, mesa, mga ibabaw sa kusina, telepono, inidoro, gripo, at lababo.</p>
                <p>Ikaw o ang isang kasambahay ay maaaring magdala ng virus nang hindi nalalaman. Bagama’t may posibilidad na mahawaan kapag nakisalamuha ang isang tao sa maysakit o kapag humawak ang tao ng kontaminadong gamit, sikapin pa ring gawin ang ibayong pag-iingat hangga’t kaya.</p>
              </div>}
          />
          <Card 
            id="myth-7"
            thumbnail="/assets/img/shots/myth/myth-7.jpg" 
            title="Nakakapagpalala ba ng sakit ang ibuprofen sa mga taong may COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">Hindi</span>Sa ngayon, wala pang sapat na patunay mula sa siyensya na nakakapagpalala nga ng COVID-19 ang ibuprofen (na kilala din bilang Advil o Motrin).</p>
                <p>Hindi naman pinapayo ng <a href="https://twitter.com/WHO/status/1240409217997189128" target="_blank">WHO</a> na huwag gamitin ang ibuprofen (Marso 18, 2020). Ang acetaminophen (o paracetamol sa Pilipinas; kilala din bilang Tylenol) ay isang uri ng gamot na maaari ring gamitin para sa lagnat at pananakit ng katawan. Mungkahi ng <a href="https://www.fda.gov/drugs/drug-safety-and-availability/fda-advises-patients-use-non-steroidal-anti-inflammatory-drugs-nsaids-covid-19" target="_blank">FDA</a> na magtanong sa tagapangalaga ng kalusugan kung may pag-aalala tungkol sa pag-inom ng ibuprofen, o ng iba pang tinatawag na non-steroidal anti-inflammatory drugs (NSAIDs; kung saan kabilang ang ibuprofen), at kung umaasa sa mahaba at patuloy na pag-inom nito para sa ibang karamdaman.</p>
                </div>}
            extra={
            <div>
              <p>Kabilang ang ibuprofen sa isang uri ng mga gamot na tinatawag na <a href="https://www.goodrx.com/blog/are-advil-motrin-tylenol-bad-for-coronavirus-symptoms/" target="_blank">NSAID</a> Kabilang rin dito ang aspirin, naproxen (Aleve), diclofenac (Cambia), at celecoxib (Celebrex). Ginagamit ang mga ito para sa lagnat, pananakit ng katawan, at pamamaga. Ang bawat gamot ay may kanya-kanyang panganib at pakinabang. Halimbawa, ang mga NSAID ay maaaring magdulot ng delikadong epekto sa bato, o sa daanan ng pagkain (lalamunan, tiyan, bituka, at iba pa). Ang pinakamalalang epekto naman ng acetaminophen (o paracetamol) ay ang pagkapinsala ng atay, dahil sa nasosobrahang pag-inom nito, na madalas namang hindi sinasadya. Ang acetaminophen ay madalas na sangkap sa iba’t-ibang nabibiling gamot para sa sipon at trangkaso. Basahing mabuti ang mga patungkol sa gamot, upang makatiyak na ang pag-inom ay hindi lalagpas sa nararapat na konsumo sa loob ng isang araw.</p>
              <p><div className="paragraph-header">Paano nagsimula ang usapin tungkol sa ibuprofen:</div>Nakita sa <a href="https://jvi.asm.org/content/94/7/e00127-20" target="_blank">mga pag-aaral</a> na maaaring ginagamit ng coronavirus ang ACE2 (isang enzyme o uri ng protina sa katawan) para kumapit at makapasok sa mga cells ng katawan.</p>
              <p>Noong ika-11 ng Marso 2020, gumawa ng <a href="https://www.thelancet.com/journals/lanres/article/PIIS2213-2600(20)30116-8/fulltext" target="_blank">sulat</a> sa pahayagang Lancet Respiratory Medicine ang ilang siyentipiko. Ipinagpalagay nila na may kaugnayan sa mga mas malalang kaso ng COVID-19 ang pagkakaroon ng mataas na ACE2 sa katawan. Paano naugnay dito ang ibuprofen? Ipinagpalagay din ng may akda na maaaring magpataas ng ACE2 sa katawan ang ibuprofen. Dahil dito, naugnay ang ibuprofen sa COVID-19 sa sumunod na pagkukuro-kuro.</p>
              <p>Ang lahat ng ito ay pawang haka-haka lamang, at walang patunay mula sa siyensya na nakakapagpalala nga ng COVID-19 ang ibuprofen. Gayunpaman, noong ika-14 ng Marso, nagbabala ang Ministeryo ng Kalusugan ng Pransya laban sa paggamit ng ibuprofen para sa lagnat na dulot ng COVID-19. Bukod dito, <a href="https://twitter.com/olivierveran/status/1238776545398923264" target="_blank">inilagay sa Twitter</a> ng tagapamuno ng Ministeryo na iwasan ang ibuprofen at iba pang NSAID, dahil ang mga ito ay “makakapagpalala.” Kumalat ng mabilis ang payo na ito sa balita, at maling ipinahayag ng balita na ang ibuprofen ang pangunahing sanhi ng pagkamatay ng mga pasyenteng may COVID-19. Noong ika-16 ng Marso, nilinaw ng mga may akda ng patungkol sa ibuprofen at ACE2 (na siyang nag-ugat nitong pagtatalo) na ang kanilang inilathala <a href="https://www.unibas.ch/en/News-Events/News/Uni-Research/Ibuprofen-and-COVID-19-Setting-the-record-straight.html" target="_blank">“ay hindi isang pagpapayo para sa paggamit o hindi paggamit ng gamot.”</a></p>
              <p>Para sa mga may sintomas ng COVID-19, unang nagpayo ang WHO laban sa paggamit ng ibuprofen. <strong>Noong ika-18 ng Marso, nagpayo ang <a href="https://www.ema.europa.eu/en/news/ema-gives-advice-use-non-steroidal-anti-inflammatories-covid-19" target="_blank">EMA</a>, <a href="https://www.fda.gov/drugs/drug-safety-and-availability/fda-advises-patients-use-non-steroidal-anti-inflammatory-drugs-nsaids-covid-19" target="_blank">FDA</a>, at <a href="https://twitter.com/WHO/status/1240409217997189128" target="_blank">WHO</a> na huwag iwasan ang mga NSAID kung kinakailangang gamitin.</strong></p>
            </div>}
          />
          <Card 
            id="myth-8"
            thumbnail="/assets/img/shots/myth/myth-8.jpg" 
            title="Kaya ko bang maiwasan ang COVID-19 kung ako ay gagamit ng hydroxychloroquine at azithromycin?"
            content={
              <div>
                <p><span className="card-tag-false">Hindi</span><strong>HUWAG na HUWAG</strong> kusang maggamot ng sarili! Gamitin ang mga iniresetang gamot ng naaayon lamang sa kautusan ng doktor. Maaaring may dulot na panganib ang mga gamot na ito dahil sa mga delikadong epekto (side effects), at dahil sa delikadong paghalo sa iba pang gamot na maaaring iniinom ninyo.</p>
                <p>Karaniwang inirereseta ang <a href="https://www.cdc.gov/coronavirus/2019-ncov/hcp/therapeutic-options.html" target="_blank">hydroxychloroquine at chloroquine</a> para sa malaria at sa ibang sakit tulad ng lupus o rayuma. Ang azithromycin naman ay isang antibiotic na karaniwang ginagamit para labanan ang impeksyong gawa ng ilang uri ng bakterya. Ang hydroxychloroquine at azithromycin ay parehong nauugnay sa mga delikadong epekto sa puso. Halimbawa ng epektong ito ay ang hindi tamang pagpintig ng puso na maaaring makamatay. Sa ngayon, may patuloy na pag-aaral para tingnan kung mabisa nga ba ang mga gamot na ito laban sa COVID-19. Gayunpaman, gamitin ang mga iniresetang gamot ng naaayon lamang sa utos ng doktor.</p>
              </div>}
          />
          <Card 
            id="myth-9"
            thumbnail="/assets/img/shots/myth/myth-9.jpg" 
            title="Mabisa ba ang antibiotic na pamuksa o panlaban sa COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">Hindi</span>Ayon sa <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, ang antibiotic ay panlunas para sa impeksyon na dulot ng bakterya at hindi virus. Hindi dapat ginagamit ang antibiotic bilang pamuksa o panlunas sa virus. Gayunpaman, kung sakaling maospital dulot ng COVID-19, maaaring mabigyan ng antibiotic, dahil ang sabay na impeksyon ng virus at bacteria ay posible sa ilang kaso.</p>
              </div>}
          />
          
          <Card 
            id="myth-11"
            thumbnail="/assets/img/shots/myth/myth-11.jpg" 
            title="Kung ako ay bata-bata pa at malusog naman, ligtas ba ako sa bagong coronavirus?"
            content={
              <div>
                <p><span className="card-tag-false">Hindi</span>Ayon sa <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, “Kaya ng bagong coronavirus humawa sa lahat ng tao, anuman ang edad.”</p>
                <p>Bukod pa rito, may patunay na mas nanganganib magkasakit ng malubha ang mga matatanda, pati ang mga taong may iba nang karamdaman (tulad ng hika, diyabetis, sakit sa puso). Payo ng WHO sa lahat ng tao, anuman ang edad, na magkaroon ng ibayong pagsisikap para labanan ang virus na ito.”</p>
              </div>}
            extra={
              <div>
                <p>Ulat ng <a href="https://www.cdc.gov/mmwr/volumes/69/wr/mm6912e2.htm#F2_down" target="_blank">CDC</a> na sa unang 4,226 na kaso (Pebrero 12 - Marso 16) sa Estados Unidos, isa sa bawat limang pasyenteng naospital ay nasa 20 hanggang 40 taong gulang. Noong ika-12 ng Abril, halos kalahati ng lahat ng kumpirmadong kaso ng COVID-19 sa California ay mas bata pa 50 taong gulang. Bagama’t mas mababa ang bilang ng namamatay sa mga mas batang pasyente, marami pa rin sa mga ito ang namatay kahit na malusog at walang ibang naging problema sa kalusugan. Kaya’t dapat sikapin nating lahat na gawin ang nararapat para ingatan ang sarili at ang ating kapwa laban sa sakit na ito.</p>
              </div>}
          />
          <Card 
            id="myth-12"
            thumbnail="/assets/img/shots/myth/myth-12.jpg" 
            title="Kailangan ko bang maospital kung ako ay may COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">Hindi</span>Ayon sa <a href="https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/testing.html" target="_blank">CDC</a>, para sa karamihan, banayad lang ang nagiging sakit sa COVID-19, at maaari silang magpagaling sa bahay lamang. Huwag umalis ng tahanan, maliban kung nangangailangan ng tulong medikal. Humingi ng tulong medikal kung hirap sa paghinga, ngunit tumawag muna sa pangunahing doktor o klinika. Mangyaring tingnan ang aming pahinang “Kaalaman sa COVID-19”.</p>
              </div>}
          />
          <Card 
            id="myth-13"
            thumbnail="/assets/img/shots/myth/myth-13.jpg" 
            title="Kaya bang suriin ang COVID-19 sa pagpigil ng paghinga sa 10 segundo o higit nang walang pag-ubo o iba pang abnormal na nararamdaman?"
            content={
              <div>
                <p><span className="card-tag-false">Hindi</span>Ayon sa CDC, maaari pa ring may coronavirus kahit na wala kang nararamdamang sintomas, at maaari pa rin maipasa ang virus sa iba bago pa man magkaroon ng anumang sintomas. Ayon naman sa <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, “Ang mga pangunahing sintomas ng COVID-19 ay ang tuyong ubo, pagkapagod o pagkahapo, at lagnat. Para sa ilang tao, mas malala ang nagiging anyo ng sakit, tulad ng pulmonya. Hindi makakatukoy sa COVID-19 ang pagpigil sa paghinga ng 10 segundo o higit.”</p>
              </div>}
          />
          <Card 
            id="myth-14"
            thumbnail="/assets/img/shots/myth/myth-14.jpg" 
            title="Napapatay ba ng pantuyo ng kamay (hand dryer) at ng lamparang ultraviolet (UV) ang coronavirus?"
            content={
              <div>
                <p><span className="card-tag-false">Hindi</span>Ayon sa <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, “Hindi mabisa ang mga hand dryer sa pagpatay sa coronavirus. Hindi dapat ginagamit ang mga lamparang may ultraviolet (UV) light para i-sterilize ang kamay o ibang bahagi ng katawan, dahil may masamang epekto sa balat ang radiation na dulot ng UV. Upang mas maging ligtas sa bagong coronavirus, dalasan ang paghugas ng kamay gamit ang sabon at tubig, o gumamit ng hand sanitizer na gawa sa alkohol.”</p>
              </div>}
          />
          <Card 
            id="myth-15"
            thumbnail="/assets/img/shots/myth/myth-15.jpg" 
            title="Kaya bang pigilan ang COVID-19 ng madalas na pagbanlaw ng ilong gamit ang tubig na may asin (saline)?"
            content={
              <div>
                <p><span className="card-tag-false">Hindi</span>Ayon sa <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, “Walang patunay na nakakatulong makaiwas sa bagong coronavirus ang madalas na pagbanlaw ng ilong gamit ang tubig na may asin, o saline. Hindi klaro ang ebidensya na nakakatulong sa pagpapadali ng paggaling ng sipon ang pagbabanlaw na ito. Gayunpaman, walang sadyang patunay na kayang pigilan ng pagbabanlaw ng ilong ang anumang sakit o pagkahawa sa paghinga.”</p>
              </div>}
          />
          <Card 
            id="myth-16"
            thumbnail="/assets/img/shots/myth/myth-16.jpg" 
            title="Napipigilan ba ng mainit na pampaligo ang COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">Hindi</span>Dapat kang maligo, ngunit huwag sadyaing gumamit ng napakainit na tubig. Ayon sa <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, “Laging nasa halos 36.5ºC hanggang 37ºC ang normal na temperatura ng katawan, mainit man o malamig ang ipinanliligong tubig. Sa katunayan, masama ang maligo gamit ang napakainit na tubig dahil nakasusunog ito ng balat.”</p>
              </div>}
          />
          <Card 
            id="myth-17"
            thumbnail="/assets/img/shots/myth/myth-17.jpg" 
            title="Napipigilan ba ng malamig na panahon at ng pagnyebe ang COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">Hindi</span>Ayon sa <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, “Hindi kayang patayin ng malamig na panahon o ng pagnyebe ang bagong coronavirus. Ang normal na temperatura ng tao ay mga 36.5ºC hanggang 37ºC, mainit man o malamig ang panahon.”</p>
              </div>}
          />
          <Card 
            id="myth-18"
            thumbnail="/assets/img/shots/myth/myth-18.jpg" 
            title="Napipigilan ba ng pagkain ng bawang ang COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">Hindi</span>Ayon sa <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, “Ang bawang ay isang masustansyang pagkain na maaaring makatulong laban sa mikrobyo. Gayunpaman, walang ebidensya mula sa pandemyang ito na naging mabisang proteksyon para kaninuman ang pagkain ng bawang para labanan ang bagong coronavirus.”</p>
              </div>}
          />
          <Card 
            id="myth-19"
            thumbnail="/assets/img/shots/myth/myth-19.jpg" 
            title="Napipigilan ba ng pag-inom ng alak ang COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">Hindi</span>Ayon sa <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, “Ang pag-inom ng alak ay hindi nagsisilbing proteksyon laban sa COVID-19, at maaari pa itong makasama. Nakakadagdag-problema sa kalusugan ang madalas at labis na pag-inom ng alak.”</p>
              </div>}
          />
          <Card 
            id="myth-20"
            thumbnail="/assets/img/shots/myth/myth-20.jpg" 
            title="Protektado ka ba ng mga bakuna sa pulmonya laban sa COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">Hindi</span>Ayon sa <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, “Ang mga bakunang pang-pulmonya, tulad ng bakunang pneumococcal at bakunang Haemophilus influenzae type B (Hib), ay hindi nakapagbibigay ng proteksyon laban sa bagong coronavirus. Kinakailangang gumawa ng panibagong bakuna para lamang sa kakaibang virus na ito. Bagama’t hindi mabisa ang mga bakuna sa pulmonya laban sa bagong coronavirus, ang mga bakunang ito ay lubusang inirerekumenda pa rin para sa ikabubuti ng kalusugan.”</p>
              </div>}
          />
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Myth;
