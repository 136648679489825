import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Myth extends Component {

  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <div className="content-nav-scroller">
            <h4 className="content-nav-caption">Ngaahi Kaveinga Lalahi´</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-1')}>‘Oku fiema’u nai ke u kei fakamama’o mei he kakai kehe´ ‘aki e fute ‘e 6 (mita ‘e 2) ‘o kapau ‘oku ou lolotonga tui ha me’a malu’i?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-10')}>‘E lava nai ke te hao mei he COVID-19 ‘o kapau ‘e huhu kia kita ha seniola pe me’a tāmate siemu?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-2')}>‘Oku fiema’u nai ke u fakatau ha me’a mānava fakalokitafa (surgical mask) pe me’a mānava N95 (N95 respirator)?</h4>
            {/* <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-3')}>Can coronavirus spread far distances through the air?</h4> */}
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-4')}>‘Oku mahino 100% nai te u hao he kolonavailasi´ he’eku tui kofunima´?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-5')}>‘Oku malava koā ke tolonga e kolonavailasi´ ‘i ha funga me’a ‘o lau ‘aho?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-6')}>‘Oku fiema’u nai ke u kei fanofano mo fufulu e ngaahi funga me’a kehe´ neongo ‘oku ‘ikai te u mavahe mei ‘api?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-7')}>‘Oku kovi ange koā ‘ete folo Ibuprofen ‘o kapau ‘oku´ te puke ‘i he COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-8')}>‘E tokoni nai ‘eku folo pe huhu ‘aki e hydroxycholoquine mo e azithromycine ke u hao ai mei he COVID-19´?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-9')}>‘Oku ‘aonga nai e ‘enitipaiōtiki´ (antibiotics) ki hono ta’ofi mo faito’o e COVID-19´?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-11')}>Te u hao nai mei he kolonavailasi´ ‘o kapau ‘oku ou kei si’i mo mo’ui lelei?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-12')}>Kuo pau koā ke u tokoto falemahaki ‘o kapau kuo ma’u au ‘i he COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-13')}>‘E lava koā ke u ‘ilo pe ‘oku ou ma’u e COVID-19´ ‘o kapau te u lava ‘o puke hoku mānava´ ‘i ha sekoni ‘e 10 pe lahi ange pea ‘ikai te u ongo’i ai ‘oku ou faingata’a’ia pe tale?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-14')}>‘Oku ‘aonga koā e me’a fakamōmoa nima´ mo e ngaahi maama tāmate siemu UV (UV disinfection lamps) ki hono tamate’i e kolonavailasi´?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-15')}>‘E tokoni nai e tafitafi vai māsima hoto ihuu´ ki hono malu’i kita mei he COVID-19´?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-16')}>‘Oku tokoni nai e kaukau vai vevela´ ki hono malu’i kita mei he COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-17')}>‘Oku tokoni koā e nofo feitu’u ‘ea momoko´ mo e sinou´ ke te hao ai mei he COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-18')}>‘Oku tokoni nai e kai kāliki´ ki hono malu’i kita mei he COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-19')}>‘Oku tokoni koā e inu ‘olokaholo´ ke malu’i ai kita mei he COVID-19´?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-20')}>‘E tokoni nai e huhu niumōnia´ ke te hao ai mei he COVID-19?</h4>
          </div>
        </div>
        <div className="content-body">
          <Card 
            id="myth-1"
            thumbnail="/assets/img/shots/myth/myth-1.jpg" 
            title="‘Oku fiema’u nai ke u kei fakamama’o mei he kakai kehe´ ‘aki e fute ‘e 6 (mita ‘e 2) ‘o kapau ‘oku ou lolotonga tui ha me’a malu’i?"
            content={
              <div>
                <p><span className="card-tag-true">‘Io</span>Hoko atu pē ho’o fakamama’o fute 6 (mita ‘e ua) mei he kakai kehe´ pea toutou fanofano´ kimu’a ha’o tui pea hili hano vete ho me’a malu’i´</p>
                <p>Ko e fale’i ‘a e <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover-faq.html" target="_blank">CDC</a>´ ‘i ‘Amelika´ ke tui e me’a malu’i´ ke ta’ofi ai e mafola ‘a e vailasi´ pea fakahaofi e kakai´ mei he mao ‘o e halanga mānava´ ‘i ha’ate lea, tale pe mafatua. He ‘ikai fakahaofi kita he me’a malu’i´ mei he kalasi vailasi kotoa pē pea ‘oku tu’unga eni ‘i he konga tupenu mo e founga ne ngaohi ‘aki ho me’a malu’i´. Kātaki ‘o vakai ki he peesi “Ngaahi Fale’i Tokoni´” ki ha toe fakaikiiki.</p>
              </div>}
            extra={
              <div>
                <p>Kātaki ‘o vakai ki he ngaahi fale’i ‘a e <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a>´ pea ngāue’aki e ngaahi naunau malu ke mahino ‘oku hao lelei e me’a malu’i´ kae kei ‘atā pē ke ke mānava ai. Ko e fale’i ‘a e CDC´ ke lōlahi e tupenu ‘oku tuitui’aki ho me’a malu’i´. ‘Oku ‘ikai tu’upau e fale’i´ ki ha kalasi konga tupenu pē ‘e taha.</p>
                <p><div className="paragraph-header">Ko e hā e lahi e ‘aonga ‘o e tui me’a malu’i´ ki hono fakahaofi kita mei he kolonavailasi´?</div>‘Oku ‘ikai ma’u ha fakamo’oni fakasaienisi pau ke tali ‘aki e fehu’i´ ni. Ne fakahoko ha <a href="https://www.ncbi.nlm.nih.gov/pubmed/24229526" target="_blank">fakatotolo</a> ‘aki ha ngaahi vailasi kehekehe pea ‘ilo ai ‘oku ‘ikai lava ‘e ha fa’ahinga konga tupenu (falani vavae 100%, sikaafi, silika, tangai pilo, kofu līneni, kofu vavae fio poliesitaa´, tauveli holo ipu´) ‘o mātu’aki ta’ofi peseti ‘e 100 ha vailasi koe’uhī ko ‘ene fu’u iiki´. Ko e nunu’a´ pē he ‘oku ‘ikai fu’u hanga ‘e he fakatotolo´ ni ‘o fakafa’ifa’itaki’i e sīpinga tale anga-maheni ‘o e tangata´. ‘Oku ‘ikai ke fai ha tokanga ia ki he hao lelei ‘o e me’a malu’i´. ‘Ikai ngata ai´, ka ‘oku lava pē foki ke hū vailasi´ he <a href="https://www.gannett-cdn.com/experiments/usatoday/gifs/tool/919-surgical.png" target="_blank">ongo tafa’aki ‘o e me’a malu’i´</a> koe’uhī ko e ‘ikai fa’a sila’i takai e ngaahi me’a malu’i lahi ´.</p>
                <p><div className="paragraph-header">Ngaahi Fakakaukau Tefito´</div>‘Oku ‘i ai e tokoni e ngaahi me’a malu’i tupenu´ ka he ‘ikai 100% ‘ete malu ‘i hono ngāue’aki´.</p>
              </div>}
          />
          <Card 
            id="myth-10"
            thumbnail="/assets/img/shots/myth/myth-10.jpg" 
            title="‘E lava nai ke te hao mei he COVID-19 ‘o kapau ‘e huhu kia kita ha seniola pe me’a tāmate siemu?"
            content={
              <div>
                <p><span className="card-tag-false">‘Ikai</span>Kataki <strong>‘OUA</strong> na’a´ ke <a href="https://www.rb.com/media/news/2020/april/improper-use-of-disinfectants" target="_blank">huhu</a>, mānava, inu pe fana ha me’a tāmate siemu faka’olokaholo pe kololaine (seniola) ‘i ho sino´ he ‘oku fu’u <strong>fakatu’utāmaki!</strong></p>
                <p>Fakatatau ki he lau ‘a e <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, ‘oku kona e ngaahi me’a´ ni ‘o kapau kuo´ te folo pea ‘oku malava ke ne fakatupu ha maumau ki ho kili´ mo ho mata´. Ngāue’aki pē seniola´ mo e me’a tāmate siemu´ ‘o fakatatau ki he ngaahi <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cleaning-disinfection.html" target="_blank">fakahinohino totonu´.</a> Fakapapau’i ‘oku tuku mavahe e ngaahi hina´ ni ke ‘oua na’a lava ha fānau iiki ‘o a’u ki ai.</p>
              </div>}
          />
          <Card 
            id="myth-2"
            thumbnail="/assets/img/shots/myth/myth-2.jpg" 
            title="‘Oku fiema’u nai ke u fakatau ha me’a mānava fakalokitafa (surgical mask) pe me’a mānava N95 (N95 respirator)?"
            content={
              <div>
                <p><span className="card-tag-false">‘Ikai</span>‘I he lau ‘a e <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a>´, ‘oku fe’unga pē ‘ete ngāue’aki e ngaahi me’a malu’i mata tupenu´.</p>
                <p>‘Oku fe’unga pē ho’o tui e me’a malu’i ‘oku ‘osi ma’u ‘i ho ‘api´. ‘Oku ‘ikai fu’u fiema’u ia ke te fakatau e ngaahi me’a malu’i fakalokitafa´ (surgical masks) pe me’a malu’i N95. ‘Oku lolotonga honge ‘a ‘Amelika´ ni ‘i he ongo kalasi me’a malu’i ko ia´. ‘Oku tu’unga ai ‘emau kole ke tau tuku mu’a e ngaahi me’a malu’i ko ia´ ma’a´ e kau ngāue fakafalemahaki´ ‘a ē kuo ako’i kinautolu ki hono faka’aonga’i tonu e me’a´ ni pea ‘aonga koe’uhī ko ‘enau fe’ilongaki tonu mo kinautolu ‘oku lolotonga fokoutua ‘i he mahaki´.</p>
              </div>}
            extra={
              <div>
                <p>Ko e <a href="https://www.fda.gov/medical-devices/personal-protective-equipment-infection-control/n95-respirators-and-surgical-masks-face-masks" target="_blank">me’a mānava N95´</a> (N95 respirator) ‘oku ngaohi’aki ha me’asivi ‘oku´ ne lava ‘o ta’ofi e peseti ‘e 95 ‘o e ‘uli iiki kotoa (maikoloni ‘e 0.3) ‘o kapau pē kuo sila’i takai ke malu pea ‘oua ‘e mama he ‘ea ki tu’a. ‘Oku fakahoko foki ha polokalama ako fakata’u ma’a´ e kau ngāue fakafaito’o´ pehē ki hono <a href="https://www.safetyalliance.org/wp-content/uploads/2019/06/fit-test-2-000110_retouched-672x372.jpg" target="_blank">sivi ke mahino ‘oku hao lelei mo malu</a> e ngaahi me’a malu’i ‘oku nau tui´. ‘Oku malava pē ke te ongo’i ‘oku faingata’a’ia ‘ete mānava´ ‘i he’ete tui fakalelei e me’a malu’i´. ‘Oku toe malava pē foki ke hanga ‘e he fanga ki’i me’a iiki hangē ko e kava he mata´ ‘o uesia e malu ‘o e me’a malu’i´. ‘E hanga ‘e he si’isi’i hono faiako’i e kakai´ mo e si’isi’i hono sivi e hao lelei ‘o e me’a malu’i´ ‘o fakata’e’aonga’i ‘ene <a href="https://blogs.cdc.gov/niosh-science-blog/2018/01/04/respirators-public-use/" target="_blank">‘aonga ma’a´ e kakai´.</a></p>
              </div>}
          />
          {/*
          <Card 
            id="myth-3"
            thumbnail="/assets/img/shots/myth/myth-3.jpg" 
            title="Can coronavirus spread far distances through the air?"
            content={
              <div>
                <p><span className="card-tag-maybe">Tu’u veiveiua</span>Airborne transmission is unlikely for the general public.</p>
                <p>According to <a href="https://www.who.int/news-room/q-a-detail/q-a-coronaviruses" target="_blank">WHO</a>, “COVID-19 is mainly transmitted through droplets generated when an infected person coughs, sneezes, or speaks. These droplets are too heavy to hang in the air. They quickly fall on floors or surfaces.” No airborne transmission was reported among an analysis of over 75,000 COVID-19 cases in China. However, health care workers may be at risk. WHO reports airborne transmission may be possible during <a href="https://www.who.int/news-room/commentaries/detail/modes-of-transmission-of-virus-causing-covid-19-implications-for-ipc-precaution-recommendations" target="_blank">hospital procedures that generate aerosols</a> such as intubation, where a breathing tube is inserted into the patient’s airway to help them breathe.</p>
                </div>}
            extra={
              <div>
                <p><div className="paragraph-header">What does airborne transmission mean?</div>In contrast to respiratory droplets, airborne particles are smaller and remain in the air for long periods of time where they can spread farther than 6 feet.</p>
                <p><div className="paragraph-header">Why does the news say coronavirus is airborne?</div>A NEJM <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973" target="_blank">study</a> artificially created aerosols of the coronavirus in a rotating cylinder, where it remained viable for 3 hours. However, the high-powered machine does not represent a normal human cough. In a non peer-reviewed <a href="https://www.medrxiv.org/content/10.1101/2020.03.23.20039446v2" target="_blank">study</a> (has not been evaluated by other experts), researchers sampled for the presence of coronavirus in the air and surfaces of COVID-19 patients’ hospital room. 76.5% of all personal belongings were positive (cell phones, toilets, remotes, bed rails, etc.). For air samples, 63.2% were positive and some samples were collected greater than 6 feet away from the patient. However, the presence of a virus does not automatically mean it can cause disease. For example, the amount of virus may be too low to cause infection. The authors have not proven that the components of the virus that they have detected can cause infections.</p>
              </div>}
          /> */}
          <Card 
            id="myth-4"
            thumbnail="/assets/img/shots/myth/myth-4.jpg" 
            title="‘Oku mahino 100% nai te u hao he kolonavailasi´ he’eku tui kofunima´?"
            content={
              <div>
                <p><span className="card-tag-false">‘Ikai</span>‘Oku tokoni pē tui kofunima´ ‘o kapau ‘e hoko atu ho’o muimui ki he ngaahi fakatokanga kehe´. <strong>‘OUA</strong> ‘e ala ki ho mata´ ‘o kapau kuo´ ke tui kofunima. Fakapapau’i ‘oku´ ke fanofano kimu’a pea ke tui ho kofunima´ pea <strong>hili hono vete´.</strong> ‘Oku kei malava pē foki ke te fakapipihi holo e siemu´ mei he funga me’a ‘e taha ki ha funga me’a kehe ‘i he’ete tui kofunima´.</p>
              </div>}
          />
          <Card 
            id="myth-5"
            thumbnail="/assets/img/shots/myth/myth-5.jpg" 
            title="‘Oku malava koā ke tolonga e kolonavailasi´ ‘i ha funga me’a ‘o lau ‘aho?"
            content={
              <div>
                <p><span className="card-tag-true">‘Io</span>Ne hā mei ha <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973?query=featured_home" target="_blank">fakatotolo</a> ‘a e NEJM, ‘oku lava pē ke kei mo’ui e kolonavailasi´ ‘i ha la’i puha ‘o a’u ki ha houa ‘e 24, pea houa leva ‘e 72 ‘i ha pelesitiki pe ukamea.</p>
                <p>‘Oku mahu’inga pē ke te fakatokanga’i ko e lahi e vailasi ‘oku toe´ ‘oku si’i hifo ‘i he peseti ‘e 0.1 ‘i he lahi ‘o e vailasi ne kamata ‘aki e fakatotolo´. ‘Oku ‘ikai ‘uhinga e kei toe ‘a e vailasi´ pea mahino ‘oku lava ke te puke mei ai. Koe’uhī ko e vave e holo ‘a e lahi ‘o e vailasi ‘oku mo’ui ‘i he ngaahi funga me’a kehekehe, ‘oku si’isi’i e mafai ke mafola mei ai e mahaki´.</p>
              </div>}
          />
          <Card 
            id="myth-6"
            thumbnail="/assets/img/shots/myth/myth-6.jpg" 
            title="‘Oku fiema’u nai ke u kei fanofano mo fufulu e ngaahi funga me’a kehe´ neongo ‘oku ‘ikai te u mavahe mei ‘api?"
            content={
              <div>
                <p><span className="card-tag-true">‘Io</span>Ko e fale’i ia ‘a e <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/prevention.html" target="_blank">CDC</a>´ ke fufulu mo fakama’a faka’aho e ngaahi funga me’a kotoa pē ‘o hangē ko e kau’i matapaa´, kamosi ‘uhila, tēpile, telefoni, kīpooti, falemālōloo´, paipa vai mo e ngaahi topu fanofano´.</p>
                <p>‘Oku lava pē ke ma’u kita mo ho fāmili´ e mahaki´ kae ‘ikai ke te fakatokanga’i. Neongo ‘oku malava ange ‘ete pihia´ ‘i he’ete fetaulaki ofi mo ha taha ‘oku puke ‘i he mahaki´, ‘oku mahu’inga ke kei hoko atu ai pē ho’o tauhi e ngaahi fakatokanga´, ‘o kapau ‘oku malava.</p>
              </div>}
          />
          <Card 
            id="myth-7"
            thumbnail="/assets/img/shots/myth/myth-7.jpg" 
            title="‘Oku kovi ange koā ‘ete folo Ibuprofen ‘o kapau ‘oku´ te puke ‘i he COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">‘Ikai</span>‘Oku ‘ikai ha fakamo’oni fakasaienisi pau ke mahino ‘e toe kovi ange kia kita ha’ate folo Ibuprofen ‘o kapau ‘oku´ te puke ‘i he COVID-19´.</p>
                <p>‘Oku ‘ikai ha fale’i ‘a e <a href="https://twitter.com/WHO/status/1240409217997189128" target="_blank">WHO</a> ke ta’ofi ‘ete folo Ibuprofen (18 ‘o Ma’asi, 2020). ‘Oku tokoni foki e panatolo´ (acetaminophen [Tylenol] / paracetamol) ki he mofi´ mo e fakaongonoa´. Ko e fale’i ‘a e <a href="https://www.fda.gov/drugs/drug-safety-and-availability/fda-advises-patients-use-non-steroidal-anti-inflammatory-drugs-nsaids-covid-19" target="_blank">FDA</a>´ ke ke talanoa mo ho’o toketaa´ pe tokotaha faito’o tokoni´ ‘o kapau ‘oku´ ke veiveiua ki hono folo e kalasi fo’i ‘akau ko ia ‘oku kau ki ai e Ibuprofen telia ha’o fiema’u ke kei folo hokohoko tu’unga ‘i ha’o mahaki tauhi (‘oku ui ko e NSAIDs pe ko e non-steroidal antiflammatory drugs).</p>
                </div>}
            extra={
            <div>
              <p>Ko e Ibuprofen ‘oku kau ‘i ke kalasi fo’i ‘akau ‘oku ui ko e <a href="https://www.goodrx.com/blog/are-advil-motrin-tylenol-bad-for-coronavirus-symptoms/" target="_blank">NSAIDS</a> ‘a ia ‘oku kau ki ai e ‘esipilini´, naproxen (Aleve), diclofenac (Cambia) mo e celecoxib (Celebrex). ‘Oku ngāue’aki e fa’ahinga fo’i ‘akau´ ni ki he mofi´, felāngaaki pe mamahi, mo e kulokula´. Ko e fo’i ‘akau kotoa pē ‘oku ‘i ai hono lelei pea toe ‘i ai foki mo e ngaahi fakatu’utāmaki. Fakatātā ‘aki ‘eni, ko e NSAID ‘oku malava ke ‘i ai ‘ene uesia ki hoto kofuua´ pe halanga kai´. Ko e fakatu’utāmaki lahi taha ‘e ala lava ‘o hoko ‘i he’ete folo panatolo´, ko e mātu’aki maumau hoto ‘ate´, ‘o tu’unga ‘i ha’ate folo lahi’i e fo’i ‘akau´, pea ‘oku fa’a fakafokifā ‘i he taimi lahi. ‘Oku ma’u foki e panatolo´ ‘i he ngaahi fo’i ‘akau mofi mo fofonu lahi ‘i he ngaahi fo’i ‘akau ko ia ‘oku fakatau atu pē mei he falekoloa´. Kātaki ‘o lau fakalelei e ngaahi fakahinohino kotoa ‘i he hina pe puha ‘o e fo’i ‘akau´ ke fakapapau’i ‘oku ‘ikai ke ‘ova hono folo´.</p>
              <p><div className="paragraph-header">Ko e tupu’anga hono tālanga’i e ibuprofen:</div>Ne hā ‘i ha <a href="https://jvi.asm.org/content/94/7/e00127-20" target="_blank">fakatotolo</a> ko e ACE2 (ko e ‘enisaimi pe kemikale tokoni ki he ngāue ‘a e sino´), ‘oku faingofua e pipiki ki ai ‘a e kolonavailasi´ pea lava ke pipihi ai ki he ngaahi selo mo’ui (cells) kehe he sino´.</p>
              <p>‘I he ‘aho 11 ‘o Ma’asi´, 2020´ ne pulusi ‘e ha kau fakatotolo ha <a href="https://www.thelancet.com/journals/lanres/article/PIIS2213-2600(20)30116-8/fulltext" target="_blank">tohi</a> ‘i he Lancet Respiratory Medicine ‘o pehē ‘oku ‘i ai e fekau’aki ‘a e lahi ‘a e ACE2 mo ‘ete puke lahi ange ‘i he COVID-19´. Ko e hā leva ai e kaunga ‘a e Ibuprofen? ‘Oku pehē he kau fakatotolo´ ‘oku lava he hiki e lahi ‘o e ACE2 ‘i he sino´ tu’unga ‘i he’ete ngāue’aki e Ibuprofen pea ne tu’unga leva mei ai e fakamahamahalo ki he fekau’aki ‘o e COVID-19 mo e Ibuprofen.</p>
              <p>Ko e ngaahi fakamahamahalo kotoa pē ‘eni pea ‘oku te’eki ma’u ha fakamo’oni fakasaienisi pau ke pehē ai ‘oku kovi ange hono fakafolo Ibuprofen e ni’ihi ‘oku puke ‘i he COVID-19´. Ka neongo ia, ne tuku atu ha fakatokanga mei he potungāue mo’ui ‘o Falanisee´ ke ta’ofi e folo Ibuprofen ki he mofi COVID-19´ pea ‘ohake he minisitā mo’ui ‘o Falanisee´ ha’ane <a href="https://twitter.com/olivierveran/status/1238776545398923264" target="_blank">fakamatala ‘i he Twitter</a> ko e poupou ai pē ki hono ta’ofi e folo Ibuprofen ‘o lau tokua ko ha “me’a fakalahi kovi”. Ne mafola ‘ene ngaahi fale’i´ he ngaahi ongoongo´ pea ‘ohake hala ai ha ngaahi talanoa ‘o pehē ko e ibuprofen ko e tupunga tefito ia ‘o e ngaahi mate mei he COVID-19. ‘I he ‘aho 16 ‘o Ma’asi´, ne tuku mai ha toe fakamaama mei he kau mu’aki fakatotolo´ ‘o pehē: <a href="https://www.unibas.ch/en/News-Events/News/Uni-Research/Ibuprofen-and-COVID-19-Setting-the-record-straight.html" target="_blank">‘oku ‘ikai tu’unga mei he fakatotolo´ ha fale’i ke faka’aonga’i ha fa’ahinga fo’i ‘akau pe ‘ikai.</a></p>
              <p>Ko e mu’aki fale’i ‘a e WHO ke ‘oua na’a folo ibuprofen ha ni’ihi ‘oku puke ‘i he ngaahi faka’ilonga ‘o e COVID-19. <strong>‘I he ‘aho 18 ‘o Ma’asi´, ne fakatokanga e <a href="https://www.ema.europa.eu/en/news/ema-gives-advice-use-non-steroidal-anti-inflammatories-covid-19" target="_blank">EMA</a>, <a href="https://www.fda.gov/drugs/drug-safety-and-availability/fda-advises-patients-use-non-steroidal-anti-inflammatory-drugs-nsaids-covid-19" target="_blank">FDA</a>, mo e <a href="https://twitter.com/WHO/status/1240409217997189128" target="_blank">WHO</a> ke ‘oua ‘e fakamama’o mei he folo NSAIDs ‘o kapau ‘oku fiema’u.</strong></p>
            </div>}
          />
          <Card 
            id="myth-8"
            thumbnail="/assets/img/shots/myth/myth-8.jpg" 
            title="‘E tokoni nai ‘eku folo pe huhu ‘aki e hydroxycholoquine mo e azithromycine ke u hao ai mei he COVID-19´?"
            content={
              <div>
                <p><span className="card-tag-false">‘Ikai</span>Kātaki fakamolemole, <strong>‘OUA</strong> ‘e folo pe huhu noa’ia ha fo’i ‘akau pe faito’o ki ho sino´. Toki faka’aonga’i pē ‘o kapau ko ha fale’i mei he toketaa´ koe’uhī he ‘oku lahi e ngaahi fakatu’utāmaki ‘e malava ke tupu mei he ngaahi fo’i ‘akau´ ni.</p>
                <p>Ko e <a href="https://www.cdc.gov/coronavirus/2019-ncov/hcp/therapeutic-options.html" target="_blank">hydroxychloroquine mo e chloroquine</a> ko ha ongo’i faito’o tohi mei he toketaa´ ke faito’o’aki e malēlia´ (malaria) mo e ngaahi mahaki fakakulokula kehe´ ‘o hangē ko e lupus mo e fufula ‘a e hokatanga hui´ (rheumatoid arthritis). Ko e azithromycin ko ha fo’i ‘akau ‘enitipaiōtiki ‘oku´ ne faito’o e ngaahi mahaki pipihi tupu mei ha ngaahi pekilitīlia ‘e ni’ihi. ‘Oku ‘i ai foki e kaunga ‘a e hydroxychloroquine mo e azithromycin ki he uesia ‘a e mafu´ pea malava ke tupu mei ai ha fetō’aki fakatu’utāmaki he tā ‘o e mafu´ (‘o tā vave ka ‘i ha tu’unga fakatu’utāmaki). ‘Oku kei hokohoko atu e ngaahi fakatotolo´ ke vakai pe ‘oku ‘aonga e ngaahi fo’i ‘akau´ ni ki hono faito’o e COVID-19´. Kātaki fakamolemole, toki folo pē fo’i ‘akau tohi´ ‘o fakatatau ki he fale’i ‘a e toketaa´.</p>
              </div>}
          />
          <Card 
            id="myth-9"
            thumbnail="/assets/img/shots/myth/myth-9.jpg" 
            title="‘Oku ‘aonga nai e ‘enitipaiōtiki´ (antibiotics) ki hono ta’ofi mo faito’o e COVID-19´?"
            content={
              <div>
                <p><span className="card-tag-false">‘Ikai</span>Fakatatau ki he lau ‘a e <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, ‘oku ‘aonga pē ‘enitipaiōtiki´ ki hono faito’o ha mahaki pipihi tupu mei ha pekitīlia ‘o ‘ikai ko ha vailasi. ‘Oku ‘ikai totonu ke ke ngāue’aki e ‘enitipaiōtiki´ ki hono ta’ofi mo faito’o e ngaahi mahaki tupu mei he vailasi´. Tukukehe pē ‘o kapau kuo ke tokoto falemahaki koe’uhī ko ha’o puke ‘i he COVID-19´, ‘e toki malava ke ‘oatu ai ha ‘enitipaiōtiki koe’uhī ‘ko e malava ke ‘i ai e kaunga ‘a e pekitīlia´ ki ho puke´.</p>
              </div>}
          />
          
          <Card 
            id="myth-11"
            thumbnail="/assets/img/shots/myth/myth-11.jpg" 
            title="Te u hao nai mei he kolonavailasi´ ‘o kapau ‘oku ou kei si’i mo mo’ui lelei?"
            content={
              <div>
                <p><span className="card-tag-false">‘Ikai</span>Fakatatau ki he lau ‘a e <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, ‘oku malava ke puke e tokotaha kotoa (‘i ha fa’ahinga ta’u motu’a pē) ‘i he kolonavailasi´.</p>
                <p>‘Oku tu’u laveangofua ange e kau toulekeleka´ mo e ni’ihi ‘oku lolotonga ‘i ai honau mahaki tauhi (‘o hangē ko e mahaki hela´, suka´ mo e mahaki mafu´) pea malava ke nau puke lahi ‘aupito ‘o kapau kuo ma’u kinautolu ‘i he vailasi´. Ko e fale’i ‘a e WHO´ ke tokanga e tokotaha kotoa (‘i he ta’u motu’a kotoa) ke hao mei he vailasi´.</p>
              </div>}
            extra={
              <div>
                <p>Mei he toko 4,226 ne ‘uluaki puke ‘i he mahaki´, ne lipooti e <a href="https://www.cdc.gov/mmwr/volumes/69/wr/mm6912e2.htm#F2_down" target="_blank">CDC</a>´ ko e peseti ‘e 20 ‘o e ni’ihi ko ia´ na’e ‘i he ta’u motu’a ko e ta’u 20 ki he 44. Mei he ‘aho 17 ‘o Mee´, ko e ‘ova he vaeua ‘o e <a href="https://www.cdph.ca.gov/Programs/CID/DCDC/Pages/Immunization/ncov2019.aspx" target="_blank">kau puke ‘i Kalefōnia</a>´ na’e si’i hifo he ta’u 50. Neongo e tokosi’i ‘a e kakai ta’u si’i ‘oku iku ‘o mate ‘i he mahaki´, ‘oku kei tokolahi pē mate´ he ni’ihi ‘oku ‘i ai honau mahaki tauhi. Kuo pau ke tokanga e tokotaha kotoa ke malu’i kinautolu mo e kakai kehe´.</p>
              </div>}
          />
          <Card 
            id="myth-12"
            thumbnail="/assets/img/shots/myth/myth-12.jpg" 
            title="Kuo pau koā ke u tokoto falemahaki ‘o kapau kuo ma’u au ‘i he COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">‘Ikai</span>‘Oku lau e <a href="https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/testing.html" target="_blank">CDC</a>´ ‘o pehē ko e tokolahi taha´ te nau ki’i puke si’isi’i pē pea lava ke nau fakaakeake ‘i honau ‘api´. ‘Oua na’a´ ke mavahe mei ho ‘api´, tukukehe kapau ko e kumi ki ha tokoni fakafaito’o. Fekumi ki ha fale’i fakatoketā ‘o kapau ‘oku faingata’a’ia ho mānava kae fakapapau’i kuo ke ‘uluaki tā ki he falemahaki´ pe kilīniki´ kimu’a pea´ ke ‘alu ki ai´.  Kātaki ‘o vakai ki he’emau peesi ko e: “Ako fekau’aki mo e COVID-19” ki ha toe fakamatala tānaki.</p>
              </div>}
          />
          <Card 
            id="myth-13"
            thumbnail="/assets/img/shots/myth/myth-13.jpg" 
            title="‘E lava koā ke u ‘ilo pe ‘oku ou ma’u e COVID-19´ ‘o kapau te u lava ‘o puke hoku mānava´ ‘i ha sekoni ‘e 10 pe lahi ange pea ‘ikai te u ongo’i ai ‘oku ou faingata’a’ia pe tale?"
            content={
              <div>
                <p><span className="card-tag-false">‘Ikai</span>‘I he lau ‘a e CDC´, ‘e malava pē ke ke ma’u e kolonavailasi´ kae ‘ikai ‘asi mai hano faka’ilonga. ‘Oku toe malava foki ke te fakapipihi holo e vailasi´ kimu’a pea kamata hā mai hono ngaahi faka’ilonga´. Ne hiki ‘e he <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a> ha fakamatala ‘o pehē: “Ko e ngaahi faka’ilonga tefito ‘o e COVID-19´ ko e tale pakupaku, tāvaivaia mo e mofi. ‘Oku malava foki ke ma’u ‘e ha ni’ihi e ngaahi faka’ilonga mamafa taha ‘o e mahaki´ ni ‘a ē ‘oku kai ai e niumōnia´.  He ‘ikai lava ke fakamo’oni’i ‘ete ma’u e mahaki´ ni ‘i ha’ate puke mānava ‘o hangē ko ia ‘oku hā ‘i he fehu’i´.</p>
              </div>}
          />
          <Card 
            id="myth-14"
            thumbnail="/assets/img/shots/myth/myth-14.jpg" 
            title="‘Oku ‘aonga koā e me’a fakamōmoa nima´ mo e ngaahi maama tāmate siemu UV (UV disinfection lamps) ki hono tamate’i e kolonavailasi´?"
            content={
              <div>
                <p><span className="card-tag-false">‘Ikai</span>Fakatatau ki he lau ‘a e <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, “’oku ‘ikai ke fu’u ‘aonga e ngaahi me’a fakamōmoa nima´ ki hono tamate’i e kolonavailasi´. ‘Oku ‘ikai totonu ke ke ngāue’aki ha maama UV ke fakama’a’aki ho nima´ he ‘oku lava ‘e he maama UV´ ‘o fakakalakala’i e kili´. Ki hono malu’i koe mei he kolonavailasi´, fakapapau’i ‘oku´ ke toutou fanofano ‘aki e koa mo e vai pe ha me’a fanofano ngaahi ‘aki e ‘olokaholo´”.</p>
              </div>}
          />
          <Card 
            id="myth-15"
            thumbnail="/assets/img/shots/myth/myth-15.jpg" 
            title="‘E tokoni nai e tafitafi vai māsima hoto ihuu´ ki hono malu’i kita mei he COVID-19´?"
            content={
              <div>
                <p><span className="card-tag-false">‘Ikai</span>Fakatatau ki he lau ‘a e <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, “’oku ‘ikai ha fakamo’oni ‘e tokoni ‘ete fa’a tafitafi vai māsima e ihuu´ ki hono malu’i kita mei he pipihi holo ‘a e kolonavailasi´. ‘Oku ‘i ai pē ha ki’i fakamo’oni fakangatangata felave’i mo e tokoni ‘a e tafitafi hoto ihuu´ ‘aki ha vai māsima ki hono fakaakeake kita mei he puke fofonu´. Ka ‘oku te’eki ma’u heni ha fakamo’oni pau ke mahino ‘e lava ke ne malu’i kita mei ha ngaahi mahaki pipihi ‘o e halanga mānava´.</p>
              </div>}
          />
          <Card 
            id="myth-16"
            thumbnail="/assets/img/shots/myth/myth-16.jpg" 
            title="‘Oku tokoni nai e kaukau vai vevela´ ki hono malu’i kita mei he COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">‘Ikai</span>‘Oku lelei pē kaukau´, kae ‘oua ‘e ngāue’aki ha vai ‘oku fu’u vela. ‘I he lau ‘a e <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>´, “’oku tu’u ma’u pē māfana hoto sino´ ‘i he tikilī selisiasi ‘e 36.5 ki he 37, ‘o tatau ai pē pe ko e hā e māfana e vai ‘oku´ te kaukau’aki´. ‘Oku toe fakatu’utāmaki foki ‘ete kaukau vai vela´ koe’uhī ko e lava ke tutu ai ho kili´ pea ke lavea.”</p>
              </div>}
          />
          <Card 
            id="myth-17"
            thumbnail="/assets/img/shots/myth/myth-17.jpg" 
            title="‘Oku tokoni koā e nofo feitu’u ‘ea momoko´ mo e sinou´ ke te hao ai mei he COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">‘Ikai</span>Fakatatau ki he lau ‘a e <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, “’oku ‘ikai lava ‘e he ‘ea momoko´ mo e sinou´ ‘o tāmate’i e kolonavailasi´. Ko e māfana angamaheni e sino ‘a e tangata´ ‘oku ‘i he tikilī selisiasi ‘e 36.5 ki he 37, ‘o tatau ai pē pe ko e hā e māfana ‘o e ‘ea ‘i tu’a´”</p>
              </div>}
          />
          <Card 
            id="myth-18"
            thumbnail="/assets/img/shots/myth/myth-18.jpg" 
            title="‘Oku tokoni nai e kai kāliki´ ki hono malu’i kita mei he COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">‘Ikai</span>Fakatatau ki he lau ‘a e <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, “ko e kāliki´ ko e me’akai mo’ui lelei pea lava ke ‘i ai ‘ene tokoni ki hono tamate’i e siemu hoto sino´. Ka neongo ia, ‘oku ‘ikai ma’u ha fakamo’oni fe’unga ke pehē ‘e lava he kai kāliki´ ‘o malu’i kita mei he kolonavailasi´.</p>
              </div>}
          />
          <Card 
            id="myth-19"
            thumbnail="/assets/img/shots/myth/myth-19.jpg" 
            title="‘Oku tokoni koā e inu ‘olokaholo´ ke malu’i ai kita mei he COVID-19´?"
            content={
              <div>
                <p><span className="card-tag-false">‘Ikai</span>Fakatatau ki he lau ‘a e <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, ‘oku ‘ikai hanga ‘e he’ete inu ‘olokaholo´ ‘o malu’i kita mei he COVID-19 pea ‘oku toe malava foki ke fakatu’utāmaki kiate koe. ‘E lava ke uesia ho mo’ui lelei´ pea hiki hake e mafai ke ke fokoutua ‘i ha ngaahi mahaki kehe tupu mei he inu lahi´ mo e inu tu’o lahi´.</p>
              </div>}
          />
          <Card 
            id="myth-20"
            thumbnail="/assets/img/shots/myth/myth-20.jpg" 
            title="‘E tokoni nai e huhu niumōnia´ ke te hao ai mei he COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">‘Ikai</span>Fakatatau ki he lau ‘a e <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, “’oku ‘ikai malu’i kita he huhu niumōnia´ (‘o hangē ko e huhu pneumococcal mo e huhu Haemophilus influenzae kalasi B [Hib]) mei he kolonavailasi´. Ko e kolonavailasi´ ko e vailasi fo’ou pea ‘oku fiema’u pē hano huhu makehe. Neongo ‘oku ‘ikai kau tonu e ngaahi huhu niumonia´ ke te hao ai mei he kolonavailasi´, ‘oku tokoni ia ke te hao mei he ngaahi mahaki kehe ‘o e halanga mānava´ pea malu’i ai mo ho mo’ui lelei fakalūkufua´.</p>
              </div>}
          />
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Myth;
