import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Tips extends Component {
  
  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <h4 className="content-nav-caption">Tabla de Contenido</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-1')}>Consejos para lavarse las manos</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-2')}>Consejos para cubrirse la cara</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-3')}>Recomendaciones para el uso de guantes</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-4')}>Consejos de limpieza</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-5')}>Compra de alimentos</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-6')}>Manejo de la comida entregada a domicilio</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-7')}>Manejando Paquetes y Cartas</h4>
        </div>
        <div className="content-body">
          <Card 
            id="tips-1"
            thumbnail="/assets/img/shots/tips/tips-1.jpg" 
            title="Consejos para lavarse las manos"
            content={
              <div>
                <p>Lavarse las manos con frecuencia es esencial para prevenir la transmisión del COVID-19. Evite tocarse los ojos, la nariz, o la boca sin antes haberse lavado las manos.</p>
                <hr />
                <p>
                  <div className="paragraph-header">Consejos Para Lavarse Las Manos Adecuadamente</div>
                  <ol className="content-list">
                    <li><div className="ol-index">1</div>Mójese las manos bajo un chorro de agua corriente</li>
                    <li><div className="ol-index">2</div>Añada jabon y frótese las manos, incluyendo los espacios entre los dedos, la parte dorsal de las manos, y debajo de las uñas</li>
                    <li><div className="ol-index">3</div>Frote sus manos por 20 segundos (cantando la canción de “Feliz Cumpleaños” dos veces)</li>
                    <li><div className="ol-index">4</div>Enjuáguese las manos</li>
                    <li><div className="ol-index">5</div>Séquese las manos con una toalla o deje que se sequen al aire libre</li>
                  </ol>
                  <h5 className="content-source"><a href="https://www.cdc.gov/handwashing/esp/when-how-handwashing.html" target="_blank">Fuente: CDC</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/8dZIQ1jWQGY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p>
                  <div className="paragraph-header">¿Cuándo debería lavarse las manos?</div>
                  <ul className="content-list">
                    <li>Lo más frecuentemente que pueda</li>
                    <li>Después de estar en lugares públicos</li>
                    <li>
                      <div>Después de entrar en contacto con superficies comúnmente tocadas</div>
                      <ul>
                        <li>Ejemplos: perillas de puertas, mesas, bombas de gas, carritos de compras, cajas registradoras, teclados numéricos, botones de elevadores, etc.</li>
                      </ul>
                    </li>
                    <li>Antes, durante, y después de preparar la comida</li>
                    <li>Antes de comer</li>
                    <li>Después de sonarse la nariz</li>
                    <li>Antes de ponerse y después de quitarse las mascarillas y los guantes</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">Si no tiene agua o jabón disponible, use un desinfectante a base de alcohol.</div>
                  <ul className="content-list">
                    <li>Los desinfectantes para manos no matan todo tipo de gérmenes.</li>
                    <li>Deben contener al menos 60% de alcohol.</li>
                    <li>Frote el gel sobre todas las superficies de sus manos, entre sus dedos, y sobre las yemas de sus dedos.</li>
                    <li>Frote sus manos, una contra la otra, hasta que estén <strong>secas</strong></li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-2"
            thumbnail="/assets/img/shots/tips/tips-2-es.jpg" 
            title="Consejos para cubrirse la cara"
            content={
              <div>
                <p>
                  <div className="paragraph-header">Buenas Prácticas para el Uso Adecuado de las Coberturas Faciales de Tela</div>
                  <ul className="content-list">
                    <div className="content-list-label-positive">Si</div>
                    <li>Asegúrese de que su cobertura facial le permita respirar</li>
                    <li>Asegúrese de que su cobertura facial le cubra la nariz y la boca (desde el puente de su nariz hasta debajo de su barbilla)</li>
                    <li>Remueva la cobertura facial tirando solamente de la tirilla sujetadora</li>
                    <li>Lave y seque su cobertura facial diariamente</li>
                    <li>Lave sus manos después de quitarse la cobertura facial</li>
                    <li>Si su cobertura facial está sucia, manténgala en una bolsa o caja designada antes de limpiarla</li>
                  </ul>
                  <ul className="content-list">
                    <div className="content-list-label-negative">Evite</div>
                    <li>Tocar la parte exterior de su cobertura facial, que estará más sucia</li>
                    <li>Exponer la nariz y boca, dejando caer la cobertura facial a su barbilla o cuello o colgada de una oreja</li>
                    <li>Dejar espacios grandes entre los lados de la cobertura facial y su cara</li>
                    <li>Remover la cobertura facial con guantes puestos</li>
                    <li>Reutilizar las mascarillas quirúrgicas</li>
                  </ul>
                  <h5 className="content-source">Fuente: <a href="https://espanol.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a>; <a href="https://www.nytimes.com/2020/04/08/well/live/coronavirus-face-mask-mistakes.html#click=https://t.co/GkBNJAuw1V" target="_blank">The New York Times</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/9kQ-uJFqAsU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p>
                  <div className="paragraph-header">Coberturas faciales <span className="red">NO</span> pueden sustituir otras medidas preventivas</div>
                  <div>Si decide usar coberturas faciales y/o guantes, asegúrese de continuar practicando:</div>
                  <ul className="content-list">
                    <li>El lavado de manos adecuado</li>
                    <li>Distanciamiento físico manteniéndose a 6 pies de los demás</li>
                    <li>Refugiarse en su hogar</li>
                  </ul>
                  <div>Aunque equipos de protección personal (mascarillas, guantes, etc.) pueden ayudar a limitar la transmisión, también puede crear un falso sentido de seguridad. El equipo de protección entra en contacto directo con superficies potencialmente contaminadas. Si estos artículos se usan incorrectamente, pueden causar contaminación cruzada y exponerlo al virus.</div>
                </p>
              </div>}
            extra={
              <div>
                <p>
                  <div className="paragraph-header">¿Por qué el CDC recomienda el uso de coberturas faciales en público?</div>
                  <div>Según las recomendaciones del <a href="https://espanol.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover.html" target="_blank">CDC</a>, usted puede transmitir el coronavirus sin tener síntomas (también conocido como el periodo "asintomático"). Al usar coberturas faciales y al mantener los 6 pies de distancia entre usted y otras personas, puede ayudar a detener la propagación del coronavirus en la comunidad.</div>
                  <ul className="content-list">
                    <div>¡Algunas personas NO deben usar coberturas faciales!</div>
                    <li>Niños menores de 2 años</li>
                    <li>Personas con dificultades respiratorias</li>
                    <li>Personas inconscientes o incapaces de removerse la cobertura facial sin asistencia</li>
                  </ul>
                </p>
                <p>El CDC recomienda el uso de materiales caseros como camisetas, pañuelos o bufandas. Por favor vea la <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">guía</a> del CDC que describe paso a paso cómo crear coberturas faciales de tela.</p>
                <p><strong>Usted no necesita utilizar mascarilla quirúrgica ni mascarilla N95.</strong> De hecho, el CDC no recomienda comprar mascarillas quirúrgicas ni mascarillas N95 adicionales debido a la grave escasez en los hospitales en Estados Unidos. </p>
              </div>
            }
          />
          <Card 
            id="tips-3"
            thumbnail="/assets/img/shots/tips/tips-3.jpg" 
            title="Recomendaciones para el uso de guantes"
            content={
              <div>
                <p>El uso de guantes NO es necesario para su protección en lugares públicos. Sin embargo, si decide usar guantes, siga los siguientes consejos.</p>
                <p>
                  <ul className="content-list">
                    <div className="content-list-label-positive">Si</div>
                    <li>Lávese las manos antes e <strong>inmediatamente</strong> después de quitarse los guantes</li>
                    <li>Deseche los guantes después de usarlos y no los reutilice</li>
                    <li>En cuanto a los guantes de tela, lávelos con agua y jabón antes de reutilizarlos</li>
                  </ul>
                  <ul className="content-list">
                    <div className="content-list-label-negative">Evite</div>
                    <li>Tocarse la cara, la boca, la nariz y los ojos</li>
                    <li>Poner sus manos en sus bolsillos</li>
                    <li>Usar su teléfono</li>
                  </ul>
                  <h5 className="content-source">Fuente citada: <a href="https://www.kqed.org/science/1961243/coronavirus-heres-how-to-use-gloves-properly-to-help-prevent-infection" target="_blank">KQED</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/TnLCYqk20YU?start=116" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/_HTCg3Fm0_o" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>}
          />
          <Card 
            id="tips-4"
            thumbnail="/assets/img/shots/tips/tips-4.jpg" 
            title="Consejos de limpieza"
            content={
              <div>
                <p>Basado en datos actuales, el contacto con superficies contaminadas no es la manera principal de contraer la enfermedad del COVID-19. No se va a infectar simplemente por tocar superficies contaminadas con el virus, a menos que lleve sus manos contaminadas a la cara (ojos, nariz, boca). Limpiar superficies y lavarse las manos después de tocarlas puede reducir el riesgo de transmisión del COVID-19.</p>
                <hr />
                <p>
                  <div className="paragraph-header">¿Cuánto tiempo dura vivo el Coronavirus en diferentes superficies?</div>
                  <div className="content-table">
                    <div className="content-table-row">
                      <div className="content-table-column">
                        <h3 className="content-table-heading">4 horas</h3>
                        <h4 className="content-table-caption">Cobre</h4>
                      </div>
                      <div className="content-table-column">
                        <h3 className="content-table-heading">24 horas</h3>
                        <h4 className="content-table-caption">Cartón</h4>
                      </div>
                    </div>
                    <div className="content-table-row">
                      <div className="content-table-column">
                        <h3 className="content-table-heading">2-3 días</h3>
                        <h4 className="content-table-caption">Plástico</h4>
                      </div>
                      <div className="content-table-column">
                        <h3 className="content-table-heading">2-3 días</h3>
                        <h4 className="content-table-caption">Acero Inoxidable</h4>
                      </div>
                    </div>
                  </div>
                  <div><a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973" target="_blank">Un estudio</a> publicado recientemente (3/17/20) describió la cantidad de tiempo que dura el virus en diferentes superficies. Aunque el coronavirus se detectó horas a días después, la cantidad de virus en estas superficies disminuyó rápidamente al pasar el tiempo. Igualmente, el riesgo de infección también disminuyó.</div>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">¿Que puedo usar para limpiar superficies?</div>
                  <ul className="content-list">
                    <div>Por favor visite la página web del CDC para leer recomendaciones de <a href="https://espanol.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cleaning-disinfection.html" target="_blank">como limpiar superficies específicas.</a> El CDC recomienda lo siguiente:</div>
                    <li>Desinfectantes aprobados por la EPA <a href="https://www.cnn.com/2020/03/05/health/epa-disinfectants-coronavirus-trnd/index.html" target="_blank">(vea lista aquí)</a></li>
                    <li>
                      <div>Use una solución de blanqueador diluido</div>
                      <ul>
                        <li>5 cucharadas (o ⅓ taza) de blanqueador por cada galón de agua</li>
                        <li><strong>Alternativamente,</strong> 4 cucharaditas de blanqueador por cada cuarto de galón de agua</li>
                        <li>No limpie con blanqueador directamente de la botella sin diluirlo</li>
                        <li>Asegúrese de que el blanqueador NO se haya expirado</li>
                        <li>NO mezcle blanqueador con amoníaco u otro limpiador.</li>
                      </ul>
                    </li>
                    <li>Soluciones de alcohol, al 70% de concentración o más.</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-5"
            thumbnail="/assets/img/shots/tips/tips-5.jpg" 
            title="El mandado al mercado"
            content={
              <div>
                <p><strong>Es más probable que se enferme por las personas en la tienda de comestibles y no los comestibles en sí.</strong> Según los <a href="https://espanol.cdc.gov/coronavirus/2019-ncov/daily-life-coping/essential-goods-services.html" target="_blank">CDC</a>, "no hay evidencia de que la comida o los envases de la comida tomen parte significante en la propagación del virus en los Estados Unidos".</p>
                <p>
                  <div className="paragraph-header">Consejos para la compra segura del mercado</div>
                  <ul className="content-list">
                    <li>No vaya de compras si tiene síntomas respiratorios o si ha sido expuesto al virus</li>
                    <li>Intente limitar visitas al mercado a una vez a la semana, o cada dos semanas</li>
                    <li>Sólo una persona por familia debería ir al mercado.</li>
                    <li>Si es posible, mantenga a sus hijos en casa con un adulto.</li>
                    <li>Escriba una lista de compras para minimizar su tiempo en el mercado.</li>
                    <li>Use una cubierta facial y mantenga la distancia de los demás.</li>
                    <li>Limpie el manubrio del carrito de compras.</li>
                    <li>Si es posible, elija el pago sin contacto.</li>
                    <li>Después de salir de la tienda, use desinfectante para manos.</li>
                    <li>Cuando llegue a casa, lávese las manos con agua y jabón por al menos 20 segundos.</li>
                    <li>Ofrezca ayudar a un familiar, amigo, o vecino que tal vez no pueda ir de compras al mercado por sí mismo.</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">No necesita desinfectar su compra.</div>
                  <div>Lo más importante es que se lave las manos y las superficies después de manipular los alimentos. Evite el jabón al enjuagar frutas y verduras frescas.</div>
                  <ul className="content-list">
                    <div>Sin embargo, <a href="https://www.elconfidencial.com/alma-corazon-vida/2020-04-17/desinfectar-compra-coronavirus-evitar-contagio-supermercado_2527144/" target="_blank">si realmente quiere limpiar sus alimentos</a>:</div>
                    <li>Considere eliminar todos los empaques innecesarios.</li>
                    <li>Use agua y jabón para envases de plástico o cartón. Esto evita que los productos químicos desinfectantes se metan en los alimentos.</li>
                    <li>Solo use aerosoles y toallitas desinfectantes en superficies duras como frascos de vidrio o latas.</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-6"
            thumbnail="/assets/img/shots/tips/tips-6.jpg" 
            title="Manipulando los alimentos entregados"
            content={
              <div>
                <p>Según la <a href="https://www.fda.gov/food/food-safety-during-emergencies/compra-de-comestibles-durante-la-pandemia-del-covid-19-informacion-para-los-consumidores" target="_blank">FDA</a>, "el COVID-19 se propaga de persona a persona, a diferencia de los virus gastrointestinales transmitidos por alimentos".</p>
                <p>
                  <ul className="content-list">
                    <li>Entrega: solicite que le dejen su compra en su puerta.</li>
                    <li>Transfiera los alimentos de los contenedores de los restaurantes a los contenedores de casa.</li>
                    <li>Si planea usar los utensilios del restaurante, lávelos con agua y jabón antes de usar.</li>
                    <li>Asegúrese de lavarse las manos antes de tocar la comida.</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-7"
            thumbnail="/assets/img/shots/tips/tips-7.jpg" 
            title="Manipulando paquetes y el correo"
            content={
              <div>
                <p>En <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973?query=featured_home" target="_blank">un estudio de investigación</a> de NEJM, se detectó una pequeña cantidad de coronavirus en el cartón por hasta 24 horas. Sin embargo, la presencia del virus no significa automáticamente que pueda ser infeccioso. Según los <a href="https://espanol.cdc.gov/coronavirus/2019-ncov/faq.html" target="_blank">CDC</a>, es poco probable que se propague a través del correo, los productos, o el embalaje.</p>
                <p>
                  <ul className="content-list">
                    <li>Después de abrir su paquete y correo, lávese las manos.</li>
                    <li>Limpie las tijeras y otras superficies contaminadas.</li>
                  </ul>
                </p>
              </div>}
          />
          <h4 className="content-tips-disclaimer">Reconocemos la posibilidad de que los siguientes consejos no apliquen a todos por igual. La situación de cada individuo y familia varía. Esperamos que estos consejos le puedan proveer algunas ideas útiles. Por favor, ¡díganos cómo pudo incorporar estos consejos en su vida diaria!</h4>
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Tips;
