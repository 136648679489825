import React, { Component } from 'react'
import classnames from 'classnames'
import {
  BrowserRouter as Router,
  Link,
  Route,
  useLocation,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom'

import Button from '../Components/Button'

class Header extends React.PureComponent {

  render() {

    const { toggleLanguageModalActive, toggleShareModalActive, path } = this.props;

    return (
      <div className="header-container">
        <div className="header grid">
          <h1 className="header-title">COVID-19<br />Fact Check</h1>
          <div className="header-description">学会分辩事实与谣言，助你做好预防措施以及跟别人分享</div>
          <div className="header-action mobile">
            <Button className="btn-secondary" label="中文-简体" imgURL="/assets/img/icons/arrow_down.svg" onClick={toggleLanguageModalActive} />
            <Button className="btn-primary" label="分享网址" imgURL="/assets/img/icons/share.svg" onClick={toggleShareModalActive} />
          </div>
          <div className="header-caption mobile">
            <h5 className="header-caption-item">资料主要是来自美国疾病预防控制中心 <a href="https://www.cdc.gov/" target="_blank">(CDC)</a> 以及世界卫生组织 <a href="https://www.who.int/" target="_blank">(WHO)</a>的网站</h5>
            <h5 className="header-caption-item">网页最后更新日期 05/18/20</h5>
          </div>
        </div>
        <div className="nav">
          <a href="/cn/" className={classnames('nav-item', {'nav-item-active': path === ''})}>分辨事实与谣言</a>
          <a href="/cn/tips" className={classnames('nav-item', {'nav-item-active': path === 'tips'})}>一些预防措施</a>
          <a href="/cn/learn" className={classnames('nav-item', {'nav-item-active': path === 'learn'})}>关于新冠状病 (COVID-19)</a>
          <a href="/cn/about" className={classnames('nav-item desktop', {'nav-item-active': path === 'about'})}>关于我们</a>
          <a href="mailto:contact@covid19factcheck.com" target="_blank" className="nav-item desktop">联络我们</a>
        </div>
      </div>
    )
  }
}

export default withRouter(Header)