import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Learn extends Component {

  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <h4 className="content-nav-caption">目錄</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-1')}>概述</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-2')}>新冠病毒（COVID-19）是如何傳播的？</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-3')}>症狀</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-4')}>如果我不舒服，應該做什麼呢？</h4>
        </div>
        <div className="content-body">
          <Card 
            id="learn-1"
            icon="/assets/img/icons/info.svg" 
            title="概述"
            content={
              <div>
                <p>冠狀病毒是一種可以引起常見的呼吸道疾病（例如感冒）的傳染病病毒。</p>
                <p>在2019年，發現了一種新的冠狀病毒。 SARS-CoV-2是該病毒的科學名稱。它引起的疾病被稱為 2019年新型冠状病毒病（COVID-19)。</p>
              </div>}
          />
          <Card 
            id="learn-2"
            icon="/assets/img/icons/spread.svg" 
            title="新冠病毒（COVID-19）是如何傳播的？"
            content={
              <div>
                <p><div className="paragraph-header">人與人傳染</div>新冠病毒主要由呼吸飛沫傳播的。如果您位於感染者的6英尺範圍內，他們的咳嗽，噴嚏或說話所產生的呼吸飛沫會進入您的嘴，鼻子，眼睛。</p>
                <p><div className="paragraph-header">觸摸被污染的表面</div>觸摸完有病毒的表面然後摸嘴、鼻子、眼睛是有可能將病毒傳染給自己。經常觸摸的表面包括門把手、電燈開關、手機、桌面、鍵盤、廁所沖水的按鈕、洗手盤、水龍頭和水槽。</p>
              </div>}
          />
          <Card 
            id="learn-3"
            icon="/assets/img/icons/symptoms.svg" 
            title="症狀"
            content={
              <div>
                <div className="content-symptom-container">
                  <ul className="content-symptom-category">
                    <div className="content-symptom-title">常見</div>
                    <li className="content-symptom-item">發燒 (100.4°F，38°C)</li>
                    <li className="content-symptom-item">乾咳</li>
                    <li className="content-symptom-item">呼吸急促</li>
                  </ul>
                  <ul className="content-symptom-category">
                    <div className="content-symptom-title">不常見</div>
                    <li className="content-symptom-item">疲勞</li>
                    <li className="content-symptom-item">肌肉疼痛</li>
                    <li className="content-symptom-item">喉嚨痛</li>
                    <li className="content-symptom-item">流鼻涕</li>
                    <li className="content-symptom-item">反胃</li>
                    <li className="content-symptom-item">腹瀉</li>
                    <li className="content-symptom-item">失去嗅覺和味覺</li>
                  </ul>
                </div>
                <div className="content-symptom-caption">很多感染者都可能沒有症狀</div>
              </div>}
          />
          <Card 
            id="learn-4"
            icon="/assets/img/icons/sick.svg" 
            title="如果我不舒服，應該做什麼呢？"
            content={
              <div>
                <p>根據CDC的報告，大多數患者的症狀都較小，並且能夠留在家中療理。除了去看醫生之外，請避免外出。如果你呼吸有困難請立刻就醫。該醫院或者你的醫生。</p>
                <hr />
                <p>
                  <ul className="content-list">
                    <div>如果您出現以下任何一個緊急症狀請立即致電911：</div>
                    <li>呼吸困難</li>
                    <li>胸口持續疼痛或壓逼感</li>
                    <li>精神恍惚或意識不清</li>
                    <li>嘴唇或臉色發紫</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <ul className="content-list">
                    <div>如想閱讀更多信息或有需要協助你查詢醫療或社區服務，請致電給您當地公共衛生局請。請按<a href="https://covid19.ca.gov/state-local-resources/#top" target="_blank">此網址</a>來查詢加州各縣公共衛生處的聯絡資料</div>
                    <li><a href="https://www.sccgov.org/sites/phd/DiseaseInformation/novel-coronavirus/Pages/home.aspx" target="_blank">聖塔克拉拉縣 (Santa Clara County):</a> 有關新冠病毒和有關本地社區資源（例如食物，住所，諮詢，就業援助，托兒服務，長者護理服務等等）的資料請致電211 (每天24小時服務）</li>
                    <li><a href="https://sf.gov/topics/coronavirus-covid-19" target="_blank">三藩市 (San Francisco):</a> 如要查詢，需要尋找護理服務或其他社區資源請致電 311 (在市內）或415-701-2311（在市外）(每天24小時服務）</li>
                    <li><a href="http://publichealth.lacounty.gov/media/Coronavirus/" target="_blank">洛杉磯 (Los Angeles):</a> 如要查詢，需要尋找護理服務或其他社區資源請致電 211(每天24小時服務）</li>
                    <li><a href="https://covid19info.ocgov.com/" target="_blank">橙縣 (Orange County):</a> 如要查詢，需要尋找護理服務或其他社區資源請致電 211 (每天24小時服務）</li>
                    <li><a href="https://www.sandiegocounty.gov/coronavirus.html" target="_blank">聖地牙哥 (San Diego):</a> 如要查詢，需要尋找護理服務或其他社區資源或者你現時沒有醫療保險請致電 211 (每天24小時服務）</li>
                    <li><a href="https://www.co.fresno.ca.us/departments/public-health/covid-19" target="_blank">福雷斯諾 (Fresno):</a>  請致電 (559) 600-4636 (8a-5p)</li>
                    <li><a href="http://www.acphd.org/2019-ncov.aspx" target="_blank">亞拉米達縣 (Alameda County):</a> 請致電 (510) 268-2101</li>
                    <li><a href="https://www.coronavirus.cchealth.org/" target="_blank">康特拉科斯塔縣 (Contra Costa County):</a> 請致電 844-729-8410 (早上8時至下午5時服務）</li>
                  </ul>
                </p>
                <hr />
                <p>如想知道家中有人有人患上新冠狀病毒，<a href="https://chinese.cdc.gov/coronavirus/2019-ncov/if-you-are-sick/steps-when-sick.html" target="_blank">家人如何能夠減少低感染病毒的風險</a>，請往CDC網站閱讀詳細資料。</p>
              </div>}
          />
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Learn;
