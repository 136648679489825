import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Learn extends Component {

  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <h4 className="content-nav-caption">ማውጫ</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-1')}>አጠቃላይ እይታ</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-2')}>ኮቪድ-19 እንዴት ይሰራጫል?</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-3')}>የበሽታው ምልክቶች</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-4')}>ህመም ቢሰማኝ ምን ማድረግ አለብኝ?</h4>
        </div>
        <div className="content-body">
          <Card 
            id="learn-1"
            icon="/assets/img/icons/info.svg" 
            title="አጠቃላይ እይታ"
            content={
              <div>
                <p>ኮሮና ቫይረስ እንደ ጉንፋን ያሉ የተለመዱ የመተንፈሻ አካላት በሽታዎችን ሊያስከትሉ የሚችሉ ቫይረሶች ቤተሰብ ነው።</p>
                <p>በ 2019 አዲስ ኮሮና ቫይረስ ተገኝቷል፡፡ SARS-CoV-2 የቫይረሱ ሳይንሳዊ ስም ሲሆን ፣ ይህ በሽታ ኮሮና ቫይረስ 2019 (ኮቪድ-19) ይባላል።</p>
              </div>}
          />
          <Card 
            id="learn-2"
            icon="/assets/img/icons/spread.svg" 
            title="ኮቪድ-19 እንዴት ይሰራጫል?"
            content={
              <div>
                <p><div className="paragraph-header">ከሰው ወደ ሰው</div>የመተንፈሻ ነጠብጣቦች ኮቪድ-19 የሚተላለፉበት ዋናው መንገዶች ናቸው። በበሽታው ከተያዘ ሰው በ 6 ጫማ ርቀት የሚያንስ ከቆማቹ ፣ የዛ ሰው በመሳል  ፣ በማስነጠስ ፣ ወይም በማውራት አየርን የሚበክሉ ጠብታዎች በአፍዎ ፣ በአፍንጫዎ ወይም በአይንዎ ውስጥ ሊገባ ይችላል ፡፡</p>
                <p><div className="paragraph-header">የተበከሉ ወለሎች</div>በቫይረሱ የተበከለ ወለል በነካ እጅዎ አፍዎን ፣ አፍንጫዎን ወይም አይንዎን በመንካት ሊበከሉ ይችላሉ፡፡ ተደጋግመው የሚነኩ ወለሎች እነዚን የመስላሉ፥ የበር እጀታዎችን ፣ የብርሃን መቀያየሪያዎችን ፣ የሞባይል ስልኮችን ፣ ጠረጴዛዎችን ፣ መጸዳጃ ቤቶች ፣ የውሃ መክፈቻና መዝጊያ እና መታጠቢያ ቤቶችን ያጠቃልላል ፡</p>
              </div>}
          />
          <Card 
            id="learn-3"
            icon="/assets/img/icons/symptoms.svg" 
            title="የበሽታው ምልክቶች"
            content={
              <div>
                <div className="content-symptom-container">
                  <ul className="content-symptom-category">
                    <div className="content-symptom-title">የተለመደው</div>
                    <li className="content-symptom-item">ትኩሳት (100.4F, 38C)</li>
                    <li className="content-symptom-item">ደረቅ ሳል</li>
                    <li className="content-symptom-item">የትንፋሽ እጥረት</li>
                  </ul>
                  <ul className="content-symptom-category">
                    <div className="content-symptom-title">ብዙም ያልተለመደ ምልክቶች</div>
                    <li className="content-symptom-item">ድካም</li>
                    <li className="content-symptom-item">የጡንቻ ህመም</li>
                    <li className="content-symptom-item">የጉሮሮ ህመም</li>
                    <li className="content-symptom-item">በአፍንጫ ፈሳሽ መዉጣት</li>
                    <li className="content-symptom-item">ማቅለሽለሽ</li>
                    <li className="content-symptom-item">ተቅማጥ</li>
                    <li className="content-symptom-item">ማሽተት እና ጣዕም ማጣት</li>
                  </ul>
                </div>
                <div className="content-symptom-caption">በቫይረሱ የተያዙ ብዙ ሰዎች ምንም ምልክቶች ላይታዩባቸዉ ይችላሉ።</div>
              </div>}
          />
          <Card 
            id="learn-4"
            icon="/assets/img/icons/sick.svg" 
            title="ህመም ቢሰማኝ ምን ማድረግ አለብኝ?"
            content={
              <div>
                <p>በሲ.ዲ.ሲ. መሠረት ፣ አብዛኛው ሰው ቀለል ያለ ህመም ስላለው በቤት ውስጥ ማገገም ይችላል ፡፡ የሕክምና እንክብካቤ ለማግኘት ካልሆነ በስተቀር ከቤትዎ አይሂዱ። የመተንፈስ ችግር ካለብዎ የህክምና እርዳታ ይፈልጉ ፣ ነገር ግን በመጀመሪያ ወደ ጤና ሐኪም ወይም ተቋም ይደውሉ ፡፡</p>
                <hr />
                <p>
                  <ul className="content-list">
                    <div>እነዚህ የድንገተኛ የጤና ችግር ካለብዎ 911 ወይም ለአምቡላንስ ይደውሉ</div>
                    <li>የመተንፈስ ችግር</li>
                    <li>የደረት ህመም</li>
                    <li>ግራ መጋባት ወይም መንቀሳቀስ አለመቻል</li>
                    <li>ሰማያዊ ከንፈር ወይም ፊት</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <ul className="content-list">
                    <div>ለበለጠ መረጃ ወይም የሕክምና እንክብካቤ ወይም የማህበረሰብ ሀብቶችን ለማግኘት እርዳታ ከፈለጉ በአከባቢዎ የሚገኘውን የካውንቲ የህዝብ ጤና ክፍል ይደውሉ ፡፡ በካሊፎርኒያ ውስጥ የህዝብ ጤና ዲፓርትመንቶች ለህዝብ ጤና መምሪያዎች <a href="https://covid19.ca.gov/state-local-resources/#top" target="_blank">እዚህ</a> ይመልከቱ ፡፡</div>
                    <li><a href="https://www.sccgov.org/sites/phd/DiseaseInformation/novel-coronavirus/Pages/home.aspx" target="_blank">Santa Clara County:</a> ስለኮቪድ-19 ፣ ስለምግብ እርዳታ ፣ መጠለያ ፣ የምክር አገልግሎት ፣ የስራ ቅጥር ድጋፍ ፣ ጥራት ያለው የሕፃናት እንክብካቤ ፣ ከፍተኛ አገልግሎት እና ተጨማሪ መረጃ ለማግኘት ለ 211 ይደዉሉ ፡፡</li>
                    <li><a href="https://sf.gov/topics/coronavirus-covid-19" target="_blank">San Francisco:</a> 311 ይደውሉ (ከ SF ውስጥ) ወይም 415-701-2311 ለጥያቄዎች ፣ እንክብካቤን እና የማህበረሰብ እገዛ ለማግኘት (24/7 ይገኛል)</li>
                    <li><a href="http://publichealth.lacounty.gov/media/Coronavirus/" target="_blank">Los Angeles:</a> 211 ይደውሉ: እንክብካቤን እና የማህበረሰብ እገዛ ለማግኘት (24/7 ይገኛል)</li>
                    <li><a href="https://covid19info.ocgov.com/" target="_blank">Orange County:</a> 211 ይደውሉ (24/7 ይገኛል)</li>
                    <li><a href="https://www.sandiegocounty.gov/coronavirus.html" target="_blank">San Diego:</a> 211 ይደውሉ: እንክብካቤን እና የማህበረሰብ እገዛ ለማግኘት (24/7 ይገኛል)</li>
                    <li><a href="https://www.co.fresno.ca.us/departments/public-health/covid-19" target="_blank">Fresno:</a> (559) 600-4636 (8a-5p)</li>
                    <li><a href="http://www.acphd.org/2019-ncov.aspx" target="_blank">Alameda:</a> (510) 268-2101</li>
                    <li><a href="https://www.coronavirus.cchealth.org/" target="_blank">Contra Costa:</a> (844) 729-8410 (8a-5p)</li>
                  </ul>
                </p>
                <hr />
                <p>አንድ ሰው በሚታመምበት ጊዜ ስርጭቶችን እንዴት መቀነስ እንደሚቻል ጠቃሚ ምክሮችን ለማግኘት እባክዎ <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/disinfecting-your-home.html" target="_blank">CDC</a> ድህረገፅ ይጎብኙ  ፡፡</p>
              </div>}
          />
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Learn;
