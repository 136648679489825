import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Myth extends Component {

  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <div className="content-nav-scroller">
            <h4 className="content-nav-caption">Table of Contents</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-1')}>Sapos mi wearim mask, mi still nid lo stap 6 ft longwei lo ol narapela lain or nogat?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-10')}>Yu inap stopim COVID-19 taim yu putin bleach or ol narapla marasin blo cleanim ples insait lo body blo yu?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-2')}>Bai mi buyim surgical mask or N95 respirator?</h4>
            {/* <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-3')}>Can coronavirus spread far distances through the air?</h4> */}
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-4')}>Wearim glove bai banisim mi 100% lo coronavirus?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-5')}>Coronavirus ken stap laif antap lo ol samthin inap sampla dei?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-6')}>Mi sapos lo wasim han olgeta taim, or klinim antap blo olgeta sumtin even sapos mi no lusim haus?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-7')}>Ibuprofen save mekim sik blo ol manmeri wantaim COVID-19 go bikpla moa or?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-8')}>Sapos miyet kisim hydroxychloroquine na azithromycin, em bai stoppim COVID-19 or nogat?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-9')}>Antibiotics em gutpla marasin blo stoppim COVID-19 or nogat?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-11')}>Sapos mi yanpela na stron, niupla coronavirus inap lo aburusim mi or nogat?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-12')}>Bai mi stap lo hausik or nogat sapos mi gat COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-13')}>Mi inap lo save sapos mi gat COVID-19 or nogat sapos mi stop lo pulim win lo 10pla seconds or moa na mi no pilim kus or pilim nogut?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-14')}>Ol han dryer na UV disinfection lamps gat stron blo kilim coronavirus or nogat?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-15')}>Sapos yu wasim nus olgeta taim wantaim saline, bai yu banisim yu yet lo COVID-19 or nogat?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-16')}>Sapos mi waswas wantaim hot wara bai mi banisim miyet lo COVID-19 or nogat?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-17')}>Ples kol na snow ie save banisim manmeri lo COVID-19 or nogat?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-18')}>Garlic bai banisim COVID-19 or nogat?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-19')}>Dring alcohol bai banisim COVID-19 or nogat?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-20')}>Marasin blo banisim pneumonia ken banisim yu lo COVID-19 or nogat?</h4>
          </div>
        </div>
        <div className="content-body">
          <Card 
            id="myth-1"
            thumbnail="/assets/img/shots/myth/myth-1.jpg" 
            title="Sapos mi wearim mask, mi still nid lo stap 6 ft longwei lo ol narapela lain or nogat?"
            content={
              <div>
                <p><span className="card-tag-true">Yes</span>Stap 6 ft longwei lo ol narapela; wasim han olgeta taim, na bifo lo wearim mask na behain lo rausim.</p>
                <p>Lo US, <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover-faq.html" target="_blank">CDC</a> ie toktok stron lo usim mask ol mekim wantaim kolos lo helpim lo daunim binatang taim yu kus or toktok. Mask ken givim sampla banis lo binatang but ie no lo olgeta lo wanem, em ie depend lo wanem kain kolos ol usim lo mekim mask, na sapos mask ie fittim yu gut or nogat.</p>
              </div>}
            extra={
              <div>
                <p>Plis behainim <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a> toksave lo save wanem em seif na stretpla material lo usim, na hau lo save lo mekim mask bai fit gut na helpim yu pulim win gut.</p>
                <p><div className="paragraph-header">Mask ol mekim wantaim kolos em gutpla lo banisim coronavirus or nogat?</div>Ie nogat scientific painim aut lo givim stretpla ansa lo dispela. Insait lo laboratory, ol wokim wok painim aut wantaim planti narapla binatang na painim olsem nogat wanpla kolos (100% cotton shirt, scarf, silk, pillowcase, linen, cotton mix, tea towel) inap lo pasim olgeta binatang 100% blo wanem binatang ie liklik tumas. Tasol, displa wok painim aut ie no mekim manmeri lo kus. Moa long em, wok painim aut ie no lukluk lo sapos mask ie bin fit gut.  Planti ol mask ie no pas gut na karamapim maus olsem na binantang ken kam insait lo sait blo mask.</p>
                <p><div className="paragraph-header">Bikpla toksave</div>Mask ol mekim wantaim kolos ken helpim lo stoppim sampla sik tasol em ie no inap lo stoppim olgeta sik.</p>
              </div>}
          />
          <Card 
            id="myth-10"
            thumbnail="/assets/img/shots/myth/myth-10.jpg" 
            title="Yu inap stopim COVID-19 taim yu putin bleach or ol narapla marasin blo cleanim ples insait lo body blo yu?"
            content={
              <div>
                <p><span className="card-tag-false">Nogat</span>Plis <strong>NOKEN</strong> <a href="https://www.rb.com/media/news/2020/april/improper-use-of-disinfectants" target="_blank">injectim</a>, simelim, dringim or sprayim marasin blo klinim ples or chlorine (bleach) antap lo skin blo yu lo wanem em ie no seif.</p>
                <p><a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a> ie tok olsem “displa ol marasin blo klinim ples em poison  so sapos yu dringim, em ken bagarapim ai na skin blo yu”. Usim bleach na marasin blo klinim ples tasol lo wei blo usim. Tingim lo putim ol marasin blo klinim ples longwei lo ol pikinini so ol noken kisim.</p>
              </div>}
          />
          <Card 
            id="myth-2"
            thumbnail="/assets/img/shots/myth/myth-2.jpg" 
            title="Bai mi buyim surgical mask or N95 respirator?"
            content={
              <div>
                <p><span className="card-tag-false">Nogat</span><a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a> givim toksave lo ol publik lo US lo usim facemask ol mekim wantaim kolos.</p>
                <p>Yu ken wearim wanem kain mask yu gat pinis lo haus, tasol yu no nid lo buyim surgical mask or N95. Ie nogat planti supply blo medical mask lo US olsem na ol mas kipim blo ol health care wokmanmeri tasol long wanem ol save gut lo usim na ol wok wantaim ol sik lain.</p>
              </div>}
            extra={
              <div>
                <p>Displa N95 respirator gat wanpla specialized filter weh ie ken blockim 95 percent blo ol liklik (0.3 micron) test particle sapos ie gat air-tight seal. Antap lo ol kisim skul, ol health care wokmanmeri ie save mekim fit-testing lo olgeta yia lo mek sure olsem em ie stretpla size weh bai givim air-tight seal. Ol publik ie no kisim skul na ol ie no mekim fit-testing so bai nonap long banisim ol gut taim ol usim N95.</p>
              </div>}
          />
          {/*
          <Card 
            id="myth-3"
            thumbnail="/assets/img/shots/myth/myth-3.jpg" 
            title="Can coronavirus spread far distances through the air?"
            content={
              <div>
                <p><span className="card-tag-maybe">Ie mas</span>Airborne transmission is unlikely for the general public.</p>
                <p>According to <a href="https://www.who.int/news-room/q-a-detail/q-a-coronaviruses" target="_blank">WHO</a>, “COVID-19 is mainly transmitted through droplets generated when an infected person coughs, sneezes, or speaks. These droplets are too heavy to hang in the air. They quickly fall on floors or surfaces.” No airborne transmission was reported among an analysis of over 75,000 COVID-19 cases in China. However, health care workers may be at risk. WHO reports airborne transmission may be possible during <a href="https://www.who.int/news-room/commentaries/detail/modes-of-transmission-of-virus-causing-covid-19-implications-for-ipc-precaution-recommendations" target="_blank">hospital procedures that generate aerosols</a> such as intubation, where a breathing tube is inserted into the patient’s airway to help them breathe.</p>
                </div>}
            extra={
              <div>
                <p><div className="paragraph-header">What does airborne transmission mean?</div>In contrast to respiratory droplets, airborne particles are smaller and remain in the air for long periods of time where they can spread farther than 6 feet.</p>
                <p><div className="paragraph-header">Why does the news say coronavirus is airborne?</div>A NEJM <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973" target="_blank">study</a> artificially created aerosols of the coronavirus in a rotating cylinder, where it remained viable for 3 hours. However, the high-powered machine does not represent a normal human cough. In a non peer-reviewed <a href="https://www.medrxiv.org/content/10.1101/2020.03.23.20039446v2" target="_blank">study</a> (has not been evaluated by other experts), researchers sampled for the presence of coronavirus in the air and surfaces of COVID-19 patients’ hospital room. 76.5% of all personal belongings were positive (cell phones, toilets, remotes, bed rails, etc.). For air samples, 63.2% were positive and some samples were collected greater than 6 feet away from the patient. However, the presence of a virus does not automatically mean it can cause disease. For example, the amount of virus may be too low to cause infection. The authors have not proven that the components of the virus that they have detected can cause infections.</p>
              </div>}
          /> */}
          <Card 
            id="myth-4"
            thumbnail="/assets/img/shots/myth/myth-4.jpg" 
            title="Wearim glove bai banisim mi 100% lo coronavirus?"
            content={
              <div>
                <p><span className="card-tag-false">Nogat</span>Gloves bai helpim yu sapos yu usim gut. NOKEN touchim pes blo yu wantaim han insait lo glove. Wasim han bifo lo wearim glove na <strong>behain lo yu rausim</strong>glove. Binatang lo glove ken kalap go lo ol narapla hap.</p>
              </div>}
          />
          <Card 
            id="myth-5"
            thumbnail="/assets/img/shots/myth/myth-5.jpg" 
            title="Coronavirus ken stap laif antap lo ol samthin inap sampla dei?"
            content={
              <div>
                <p><span className="card-tag-true">Yes</span>NEJM wok painim aut ie showim olsem ol ken painim coronavirus antap lo cardboard lo mak olsem 24pla hour, na antap lo plastic na steel lo mak olsem 72pla hour insait lo laboratory wok painim aut.</p>
                <p>Bikpla sumtin lo save em olsem mak blo binatang stap antap lo ol samthin em liklik tumas, mak olsem 0.1% blo displa bin stap pastaim. Binatang ol painim ie no mean olsem em gat stron blo mekim manmeri sik. Bikos namba blo binatang antap lo ol samthin save go daun hariap, stron blo em lo givim sik em liklik.</p>
              </div>}
          />
          <Card 
            id="myth-6"
            thumbnail="/assets/img/shots/myth/myth-6.jpg" 
            title="Mi sapos lo wasim han olgeta taim, or klinim antap blo olgeta sumtin even sapos mi no lusim haus?"
            content={
              <div>
                <p><span className="card-tag-true">Yes</span><a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/prevention.html" target="_blank">CDC</a> toksave lo klinim na putim marasin blo klinim ples antap lo ol samthin weh yu save holim planti taim, kain samthin olsem doorknob, switch blo light, tables, countertops, phones, keyboards, toilets, faucets na sink.</p>
                <p>Yu or femli blo yu ken kisim sik na stil no save olsem yupla gat sik. Em ie easi turu lo kisim sik lo ol lain husait gat sik na kam klostu lo yu, na ie no lo binatang stap antap lo ol sumtinn,olsem na yu mas stil lukautim yu yet.</p>
              </div>}
          />
          <Card 
            id="myth-7"
            thumbnail="/assets/img/shots/myth/myth-7.jpg" 
            title="Ibuprofen save mekim sik blo ol manmeri wantaim COVID-19 go bikpla moa or?"
            content={
              <div>
                <p><span className="card-tag-false">Nogat</span>Ie nogat inap wok painim aut lo showim olsem ibuprofen (brand name Advil, Motrin) save mekim sik COVID-19 go bikpla.</p>
                <p><a href="https://twitter.com/WHO/status/1240409217997189128" target="_blank">WHO</a> ie no givim tok orait lo noken usim ibuprofen (March 18, 2020). Acetaminophen (Tylenol) em narapla marasin blo daunim skin hot na skin pen. <a href="https://www.fda.gov/drugs/drug-safety-and-availability/fda-advises-patients-use-non-steroidal-anti-inflammatory-drugs-nsaids-covid-19" target="_blank">FDA</a> toksave lo toktok wantaim ol wokmanmeri blo hausik sapos yu gat askim behainim displa ol marasin non-steroidal anti-inflammatory drug (NSAIDs), na yu save kisim displa marasin blo ol narapla sik.</p>
                </div>}
            extra={
            <div>
              <p>Ibuprofen em stap ananit lo ol marasin ol kolim <a href="https://www.goodrx.com/blog/are-advil-motrin-tylenol-bad-for-coronavirus-symptoms/" target="_blank">NSAIDS</a> na ie gat aspirin, naproxen (Aleve), diclofenac (Cambia), na celecoxib blo daunim skin hot na skin pen. Olgeta marasin gat gutpla na nogut blo ol. Tok piksa olsem, NSAID ken givim hevi lo kidney na bel. Nogut blo acetaminophen em olsem, sapos ol manmeri aburus na dring planti, em bai bagarapim liver. Acetaminophen ie stap insait lo planti narapela marasin blo kus. Plis readim gut ol label blo marasin lo save sapos yu kisim lo mak blo wanpla dei.</p>
              <p><div className="paragraph-header">Stori blo hau toktok blo ibuprofen em start:</div>Wok painim aut ie showtime olsem ACE2, wanpla enzyme insait lo bodi blo manmeri ie gat ples blo coronavirus lo pas na kamapim sik.</p>
              <p>Lo March 11, 2020, ol lain mekim wok painim aut ie raitim wanpla pas insait lo Lancet Respiratory Medicine wantaim tingting olsem taim namba blo ACE2 save antap moa, ie save mekim manmeri gat bikpla sik COVID-19. Hau na ibuprofen relate lo displa? Ol lain husait raitim pepa ie tok olsem ibuprofen ken mekim namba blo ACE2 go planti. Lo displa tingting na ol tok olsem ie gat connection namel lo ibuprofen na COVID-19.</p>
              <p>Displa em toktok tasol na ie nogat bikpla wok painim aut ie showim klia olsem ibuprofen save mekim sik blo manmeri wantaim COVID-19 go bikpla. Tasol, lo March 14, Ministry of Health blo French ie putim wanpla tok lukaut lo noken usim ibuprofen lo COVID-19 skin hot, na minister blo health blo French is tweetim toksave lo noken usim ibuprofen na narapla ol NSAIDs long wanem ol ken “kamapim hevi”. Toksave blo em ie stap lo olgeta news na stori, na planti manmeri ie kisim olsem ibuprofen em nambawan sumthin ie kamapim die blo ol manmeri wantaim COVID-19. Lo March 16, dispela ol lain husait ie bin mekim wok painim aut pastem, kam klia na tokaut olsem wok painim aut blo ol “ie nogat stron lo mekim dispela toktok blo usim or noken usim sampla marasin.”</p>
              <p>WHO pastaim ie givim toksave long ol manmeri husait gat COVID-19 olsem ol noken usim ibuprofen. <strong>Lo March 18, <a href="https://www.ema.europa.eu/en/news/ema-gives-advice-use-non-steroidal-anti-inflammatories-covid-19" target="_blank">EMA</a>, <a href="https://www.fda.gov/drugs/drug-safety-and-availability/fda-advises-patients-use-non-steroidal-anti-inflammatory-drugs-nsaids-covid-19" target="_blank">FDA</a>, <a href="https://twitter.com/WHO/status/1240409217997189128" target="_blank">WHO</a> givim toksave olsem ol noken aburusim NSAIDs sapos ol ie nidim.</strong></p>
            </div>}
          />
          <Card 
            id="myth-8"
            thumbnail="/assets/img/shots/myth/myth-8.jpg" 
            title="Sapos miyet kisim hydroxychloroquine na azithromycin, em bai stoppim COVID-19 or nogat?"
            content={
              <div>
                <p><span className="card-tag-false">Nogat</span>Plis <strong>NOKEN</strong> kisim marasin lo laik blo yu! Kisim tasol marasin weh physician ie givim tok orait lo em bikos marasin ken bagarapim yu na ken kamapim bikpla hevi.</p>
                <p><a href="https://www.cdc.gov/coronavirus/2019-ncov/hcp/therapeutic-options.html" target="_blank">Hydroxychloroquine na chloroquine</a> em tupla marasin weh yu ken dring lo stopim sik malaria na ol narapla sik olsem lupus na rheumatoid arthritis. Azithromycin em wanpla bikpla antibiotic ol save usim lo kilim sampla binatang save kamapim sik. Hydroxychloroquine na azithromycin em tupla marasin whe ie ken kamapim hevi lo lewa na mekim lewa bai pumim bulut hariap tumas. Planti wok painim aut woklo go het yet lo save sapos ol dispela marasin ie gutpla lo stopim COVID-19, tasol plis kisim sapos physician ie givim tok orait lo kisim.</p>
              </div>}
          />
          <Card 
            id="myth-9"
            thumbnail="/assets/img/shots/myth/myth-9.jpg" 
            title="Antibiotics em gutpla marasin blo stoppim COVID-19 or nogat?"
            content={
              <div>
                <p><span className="card-tag-false">Nogat</span><a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a> ie tok olsem antibiotics em blo stopim sik weh bacteria ie kamapim na ie no virus. Antibiotik ie no blo usim lo stopim sik weh virus ie kamapim. Tasol, sapos yu stap lo hausik wantaim COVID-19, ol wokmanmeri lo hausik ken givim yu antibiotik lo wanem bacteria ken kamapim sik lo wankain taim.</p>
              </div>}
          />
          
          <Card 
            id="myth-11"
            thumbnail="/assets/img/shots/myth/myth-11.jpg" 
            title="Sapos mi yanpela na stron, niupla coronavirus inap lo aburusim mi or nogat?"
            content={
              <div>
                <p><span className="card-tag-false">Nogat</span><a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a> ie tok olsem “ol manmeri lo olgeta krismas ken kisim sik weh dispela niupla coronavirus ie kamapim.”</p>
                <p>Ol lain weh krismas blo ol ie antap, na o lain weh ol gat narapla sik pinis (kain olsem sik sotwin, sik suga na sik blo lewa) ol stap lo mak blo kisim bikpla moa sik sapos binatang blo COVID-19 ie kisim ol. WHO toksave olsem olgeta manmeri lo olgeta krismas mas lukautim ol yet gut so ol noken kisim binatang.</p>
              </div>}
            extra={
              <div>
                <p>Namel lo firstpla 4,226 lain (Feb 12- March 16) lo USA, <a href="https://www.cdc.gov/mmwr/volumes/69/wr/mm6912e2.htm#F2_down" target="_blank">CDC</a> ie repot olsem wanpla lo olgeta fivepla manmeri husait ol stap lo hausik em krismas blo ol namel lo 20 na 44. Stat lo May 17, moa lo half blo ol confirmed cases lo California em ol manmeri husait krismas blo ol tamblo lo 50pla krisma. Namba blo ol yangpela manmeri husait ie die lo sik em tamblo, tasol stil ie gat ol yangpela manmeri wantaim nogat narapela sik wok lo die lo COVID-19. Olsem na olgeta lain mas putim was na lukautim ol yet na ol narapela.</p>
              </div>}
          />
          <Card 
            id="myth-12"
            thumbnail="/assets/img/shots/myth/myth-12.jpg" 
            title="Bai mi stap lo hausik or nogat sapos mi gat COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">Nogat</span><a href="https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/testing.html" target="_blank">CDC</a> ie tok olsem planti lain pilim liklik sik so ol inap lo stap lo haus tasol inap ol orait. Noken lusim haus blo yu; lusim haus tasol sapos yu laik kisim halpim lo ol wokmanmeri lo hausik. Yu mas kisim halpim sapos yu woklo painim hat lo pulim win, tasol yu mas ringim pastaim lo ol woklain lo hausik. Pls lukim toksave lo “Save moa lo COVID-19” page.</p>
              </div>}
          />
          <Card 
            id="myth-13"
            thumbnail="/assets/img/shots/myth/myth-13.jpg" 
            title="Mi inap lo save sapos mi gat COVID-19 or nogat sapos mi stop lo pulim win lo 10pla seconds or moa na mi no pilim kus or pilim nogut?"
            content={
              <div>
                <p><span className="card-tag-false">Nogat</span>CDC ie tok olsem yu ken gat coronavirus na yu nonap lo pilim sik tasol bai yu inap lo givim binatang go lo ol narapla bifo lo yu stat pilim sik. <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a> ie tok “bikpla sign blo sik COVID-19 em dry kus, skin die na skin hot. Yu nonap lo save sapos yu gat COVID-19 or nogat wantaim exercise blo pulim win.”</p>
              </div>}
          />
          <Card 
            id="myth-14"
            thumbnail="/assets/img/shots/myth/myth-14.jpg" 
            title="Ol han dryer na UV disinfection lamps gat stron blo kilim coronavirus or nogat?"
            content={
              <div>
                <p><span className="card-tag-false">Nogat</span><a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a> ie tok “han dryer ie nogat stron lo kilim coronavirus. Noken usim UV lamp lo sterilizim han or narapla hap blo skin bikos UV radiation ken bagarapim skin. Lo banisim yu lo coronavirus, yu mas wasim han olgeta taim wantaim soap na wara or han rub gat alcohol lo em.</p>
              </div>}
          />
          <Card 
            id="myth-15"
            thumbnail="/assets/img/shots/myth/myth-15.jpg" 
            title="Sapos yu wasim nus olgeta taim wantaim saline, bai yu banisim yu yet lo COVID-19 or nogat?"
            content={
              <div>
                <p><span className="card-tag-false">Nogat</span><a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a> ie toksave olsem “ie nogat wok painim aut ie showim  olsem wasim nus olgeta taim wantaim saline bai banisim ol manmeri lo kisim niupla coronavirus. Ie gat sampla wok painim aut ie tok olsem wasim nus olgeta taim wantaim saline ie helpim ol manmeri lo pinisim sik kus quiktaim. Tasol, wasim nus olgeta taim ie no save banisim manmeri lo kisim sik blo pulim win.</p>
              </div>}
          />
          <Card 
            id="myth-16"
            thumbnail="/assets/img/shots/myth/myth-16.jpg" 
            title="Sapos mi waswas wantaim hot wara bai mi banisim miyet lo COVID-19 or nogat?"
            content={
              <div>
                <p><span className="card-tag-false">Nogat</span>Yu ken waswas tasol plis noken usim hotpla wara lo waswas. <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a> toksave olsem “hot blo body blo yu em stap namel lo  36.5°C na 37°C, na ie no save senis behanim hot or kol blo wara yu waswas long em. Sapos yu waswas wantaim hotpla wara stret, em bai kukim yu na bagarapim yu.</p>
              </div>}
          />
          <Card 
            id="myth-17"
            thumbnail="/assets/img/shots/myth/myth-17.jpg" 
            title="Ples kol na snow ie save banisim manmeri lo COVID-19 or nogat?"
            content={
              <div>
                <p><span className="card-tag-false">Nogat</span><a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a> ie tok “ples kol na snow ie no save kilim niupla coronavirus. Hot blo body blo olgeta manmeri em stap namel lo  36.5°C na 37°C, na ie no save senis behanim hot or kol blo ples.”</p>
              </div>}
          />
          <Card 
            id="myth-18"
            thumbnail="/assets/img/shots/myth/myth-18.jpg" 
            title="Garlic bai banisim COVID-19 or nogat?"
            content={
              <div>
                <p><span className="card-tag-false">Nogat</span><a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a> ie tok “garlic em wanpla gutpla kaikai weh ie gat sampla marasin lo em. Tasol, ie nogat wok painim aut ie showim olsem garlic banisim manmeri lo disla bikpla sik niupla coronavirus ie kamapim.</p>
              </div>}
          />
          <Card 
            id="myth-19"
            thumbnail="/assets/img/shots/myth/myth-19.jpg" 
            title="Dring alcohol bai banisim COVID-19 or nogat?"
            content={
              <div>
                <p><span className="card-tag-false">Nogat</span><a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a> ie tok “sapos yu dring alcohol, em nonap banisim yu lo noken kisim COVID-19 na em ino seif. Dring planti alcohol lo olgeta taim ken mekim yu kisim planti narapla hevi lo health blo yu.”</p>
              </div>}
          />
          <Card 
            id="myth-20"
            thumbnail="/assets/img/shots/myth/myth-20.jpg" 
            title="Marasin blo banisim pneumonia ken banisim yu lo COVID-19 or nogat?"
            content={
              <div>
                <p><span className="card-tag-false">Nogat</span><a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a> ie tok “marasin blo banisim pneumonia, kain olsem pneumococcal marasin na Hemophilus influenzae type B marasin, ie no save banisim manmeri lo niupla coronavirus. Dispela binatang em niupla na narapela kain stret weh bai nidim niupla marasin blo em yet. Displa ol narapla marasin weh save givim banis lo ol narapla sik, ie no save givim banis lo niupla coronavirus; tasol em ie moa gutpla lo kisim bikos em bai banisim yu lo  ol narapla sik.</p>
              </div>}
          />
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Myth;
