import React, { Component } from 'react'
import classnames from 'classnames'
import {
  BrowserRouter as Router,
  Link,
  Route,
  useLocation,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom'

import Button from '../Components/Button'

class Header extends React.PureComponent {

  render() {

    const { toggleLanguageModalActive, toggleShareModalActive, path } = this.props;

    return (
      <div className="header-container">
        <div className="header grid">
          <h1 className="header-title">COVID-19<br />Fact Check</h1>
          <div className="header-description">올바른 정보가 예방의 시작입니다.</div>
          <div className="header-action mobile">
            <Button className="btn-secondary" label="한국어" imgURL="/assets/img/icons/arrow_down.svg" onClick={toggleLanguageModalActive} />
            <Button className="btn-primary" label="공유하기" imgURL="/assets/img/icons/share.svg" onClick={toggleShareModalActive} />
          </div>
          <div className="header-caption mobile">
            <h5 className="header-caption-item">콘텐츠 출처: <a href="https://www.who.int/" target="_blank">세계보건기구(WHO)</a> & <a href="https://www.cdc.gov/" target="_blank">미국 질병관리본부(CDC)</a></h5>
            <h5 className="header-caption-item">마지막 업데이트 2020년 5월 18일</h5>
          </div>
        </div>
        <div className="nav">
          <a href="/ko/" className={classnames('nav-item', {'nav-item-active': path === ''})}>팩트체크</a>
          <a href="/ko/tips" className={classnames('nav-item', {'nav-item-active': path === 'tips'})}>예방 조치 안내</a>
          <a href="/ko/learn" className={classnames('nav-item', {'nav-item-active': path === 'learn'})}>COVID-19 란?</a>
          <a href="/ko/about" className={classnames('nav-item desktop', {'nav-item-active': path === 'about'})}>소개</a>
          <a href="mailto:contact@covid19factcheck.com" target="_blank" className="nav-item desktop">문의하기</a>
        </div>
      </div>
    )
  }
}

export default withRouter(Header)