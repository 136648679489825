import React, { Component } from 'react'
import classnames from 'classnames'

import Button from '../Components/Button'
import ClipboardButton from '../Components/ClipboardButton'

class ShareModal extends Component {

  componentDidMount() {
    window.Kakao.init('8c22c0e3b6141547b03badeeb6f137cd');
  }

  sendKakao = (e) => {
    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: 'COVID-19 Fact Check',
        description: 'تحقق من "COVID-19 Fact Check" لتعلم الأسطورة والحقائق حول الفيروس التاجي.',
        imageUrl:
          'http://www.covid19factcheck.com/assets/img/thumbnails/social_square.png',
        link: {
          mobileWebUrl: 'http://www.covid19factcheck.com/ar',
          androidExecParams: 'test',
        },
      },
      success: function(response) {
        console.log(response);
      },
      fail: function(error) {
        console.log(error);
      }
    });
  }

  render() {
    const { active, toggleShareModalActive } = this.props;

    return (
      <div className={classnames('modal', 'share-modal', {'modal-active': active})}>
        <div className="modal-close" onClick={toggleShareModalActive}><img className="fit-parent" src="/assets/img/icons/close.svg" /></div>
        <div className="modal-content">
          <h1 className="modal-title">نشر الكلمة</h1>
          <p className="modal-caption">شارك " COVID-19 Fact Check" مع أصدقائك وعائلتك حتى نعلم جميعًا أفضل طريقة لمنع انتشار الفيروس التاجي.</p>
          <div className="share-modal-action-container">
            <div className="share-modal-action">
              <a href="https://twitter.com/intent/tweet?text=%D8%AA%D8%AD%D9%82%D9%82%20%D9%85%D9%86%20%22COVID-19%20Fact%20Check%22%20%D9%84%D8%AA%D8%B9%D9%84%D9%85%20%D8%A7%D9%84%D8%A3%D8%B3%D8%B7%D9%88%D8%B1%D8%A9%20%D9%88%D8%A7%D9%84%D8%AD%D9%82%D8%A7%D8%A6%D9%82%20%D8%AD%D9%88%D9%84%20%D8%A7%D9%84%D9%81%D9%8A%D8%B1%D9%88%D8%B3%20%D8%A7%D9%84%D8%AA%D8%A7%D8%AC%D9%8A.&tw_p=tweetbutton&url=http%3A%2F%2Fcovid19factcheck.com%2Far" target="_blank">
                <img className="fit-parent" src="/assets/img/icons/share/twitter.png" />
              </a>
            </div>
            <div className="share-modal-action">
              <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=http://covid19factcheck.com/ar" target="_blank">
                <img className="fit-parent" src="/assets/img/icons/share/facebook.png" />
              </a>
            </div>
            <div className="share-modal-action">
              <a href="https://wa.me/?text=%D8%AA%D8%AD%D9%82%D9%82%20%D9%85%D9%86%20%22COVID-19%20Fact%20Check%22%20%D9%84%D8%AA%D8%B9%D9%84%D9%85%20%D8%A7%D9%84%D8%A3%D8%B3%D8%B7%D9%88%D8%B1%D8%A9%20%D9%88%D8%A7%D9%84%D8%AD%D9%82%D8%A7%D8%A6%D9%82%20%D8%AD%D9%88%D9%84%20%D8%A7%D9%84%D9%81%D9%8A%D8%B1%D9%88%D8%B3%20%D8%A7%D9%84%D8%AA%D8%A7%D8%AC%D9%8A.%20http%3A%2F%2Fcovid19factcheck.com%2Far" data-action="share/whatsapp/share" target="_blank">
                <img className="fit-parent" src="/assets/img/icons/share/whatsapp.png" />
              </a>
            </div>
            <div className="share-modal-action">
              <a onClick={this.sendKakao}>
                <img className="fit-parent" src="/assets/img/icons/share/kakaotalk.png" />
              </a>
            </div>
            <div className="share-modal-action">
                <a href="https://social-plugins.line.me/lineit/share?url=http%3A%2F%2Fcovid19factcheck.com%2Far" target="_blank">
                  <img className="fit-parent" src="/assets/img/icons/share/line.png" />
                </a>
            </div>
            <div className="share-modal-action">
              <a href="mailto:?body=%D8%AA%D8%AD%D9%82%D9%82%20%D9%85%D9%86%20%22COVID-19%20Fact%20Check%22%20%D9%84%D8%AA%D8%B9%D9%84%D9%85%20%D8%A7%D9%84%D8%A3%D8%B3%D8%B7%D9%88%D8%B1%D8%A9%20%D9%88%D8%A7%D9%84%D8%AD%D9%82%D8%A7%D8%A6%D9%82%20%D8%AD%D9%88%D9%84%20%D8%A7%D9%84%D9%81%D9%8A%D8%B1%D9%88%D8%B3%20%D8%A7%D9%84%D8%AA%D8%A7%D8%AC%D9%8A.%20http%3A%2F%2Fcovid19factcheck.com%2Far">
                <img className="fit-parent" src="/assets/img/icons/share/mail.png" />
              </a>
            </div>
          </div>
          <ClipboardButton value="http://covid19factcheck.com/ar" success="تم نسخ الرابط! 🎉">
            <Button className="btn-share" label="انسخ رابط المشاركة" imgURL="/assets/img/icons/link.svg" />
          </ClipboardButton>
        </div>
      </div>
    )
  }
}

export default ShareModal