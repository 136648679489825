import React, { Component } from 'react'
import classnames from 'classnames'
import {
  BrowserRouter as Router,
  Link,
  Route,
  useLocation,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom'

import Button from '../Components/Button'

class Header extends React.PureComponent {

  render() {

    const { toggleLanguageModalActive, toggleShareModalActive, path } = this.props;

    return (
      <div className="header-container">
        <div className="header grid">
          <h1 className="header-title">COVID-19<br />Fact Check</h1>
          <div className="header-description">Alamin ang mga tama at mali upang mas makapaghanda, at mas makapagbigay-alam din sa iba.</div>
          <div className="header-action mobile">
            <Button className="btn-secondary" label="Tagalog" imgURL="/assets/img/icons/arrow_down.svg" onClick={toggleLanguageModalActive} />
            <Button className="btn-primary" label="Ibahagi" imgURL="/assets/img/icons/share.svg" onClick={toggleShareModalActive} />
          </div>
          <div className="header-caption mobile">
            <h5 className="header-caption-item">Ang karamihan ng impormasyon dito ay mula sa <a href="https://www.who.int/" target="_blank">WHO</a> at <a href="https://www.cdc.gov/" target="_blank">CDC.</a> </h5>
            <h5 className="header-caption-item">Huling rebisyon 06/06/20.</h5>
          </div>
        </div>
        <div className="nav">
          <a href="/tl/" className={classnames('nav-item', {'nav-item-active': path === ''})}>Mga Tama at Mali</a>
          <a href="/tl/tips" className={classnames('nav-item', {'nav-item-active': path === 'tips'})}>Mga Gabay at Payo</a>
          <a href="/tl/learn" className={classnames('nav-item', {'nav-item-active': path === 'learn'})}>Kaalaman tungkol sa COVID-19</a>
          <a href="/tl/about" className={classnames('nav-item desktop', {'nav-item-active': path === 'about'})}>Tungkol sa Amin</a>
          <a href="mailto:contact@covid19factcheck.com" target="_blank" className="nav-item desktop">Makipag-ugnayan sa Amin</a>
        </div>
      </div>
    )
  }
}

export default withRouter(Header)