import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Learn extends Component {

  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <h4 className="content-nav-caption">ਵਿਸ਼ਾ- ਸੂਚੀ</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-1')}>ਸੰਖੇਪ ਜਾਣਕਾਰੀ</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-2')}>ਕੋਵਿਡ-19 ਕਿਸ ਤਰ੍ਹਾਂ ਫੈਲਦਾ ਹੈ?</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-3')}>ਲੱਛਣ</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-4')}>ਜੇਕਰ ਮੈਂ ਬਿਮਾਰ ਹੋ ਤਾਂ ਮੈਨੂੰ ਕੀ ਕਰਨਾ ਚਾਹੀਦਾ ਹੈ?</h4>
        </div>
        <div className="content-body">
          <Card 
            id="learn-1"
            icon="/assets/img/icons/info.svg" 
            title="ਸੰਖੇਪ ਜਾਣਕਾਰੀ"
            content={
              <div>
                <p>ਕੋਰੋਨਾਵਾਇਰਸ ਵਾਇਰਸਾਂ ਦਾ ਉਹ ਪਰਿਵਾਰ ਹੈ ਜੋ ਸਾਹ ਦੀਆਂ ਬਿਮਾਰੀਆਂ, ਜਿਵੇਂ ਕਿ ਜ਼ੁਕਾਮ, ਦਾ ਕਾਰਨ ਬਣਦੇ ਹਨI</p>
                <p>2019 ਵਿੱਚ ਇੱਕ ਨਵੇਂ ਕੋਰੋਨਾਵਾਇਰਸ ਦੀ ਪਛਾਣ ਕੀਤੀ ਗਈI ਇਸ ਵਾਇਰਸ ਦਾ ਵਿਗਿਆਨਕ ਨਾਮ ਸਾਰਸ-ਕੋਵ-2 (SARS-CoV-2) ਰੱਖਿਆ ਗਿਆ ਅਤੇ ਇਸ ਨਾਲ ਹੋਣ ਵਾਲੀ ਬਿਮਾਰੀ ਨੂੰ ਕੋਵਿਡ-19  ਦਾ ਨਾਮ ਦਿੱਤਾ ਗਿਆ।</p>
              </div>}
          />
          <Card 
            id="learn-2"
            icon="/assets/img/icons/spread.svg" 
            title="ਕੋਵਿਡ-19 ਕਿਸ ਤਰ੍ਹਾਂ ਫੈਲਦਾ ਹੈ?"
            content={
              <div>
                <p><div className="paragraph-header">ਇੱਕ ਦੂਜੇ ਦੇ ਸੰਪਰਕ ਵਿੱਚ ਆਉਣ ਨਾਲ</div>ਕੋਵਿਡ-19 ਮੁੱਖ ਤੌਰ ਤੇ ਸਾਹ ਦੀਆਂ ਬੂੰਦਾਂ ਨਾਲ ਫੈਲਦਾ ਹੈI ਜੇ ਤੁਸੀਂ ਕਿਸੇ ਕੋਰੋਨਾਵਾਇਰਸ ਨਾਲ ਸੰਕਰਮਿਤ ਵਿਅਕਤੀ ਦੇ 6 ਫੁੱਟ ਦੇ ਘੇਰੇ ਵਿੱਚ ਆਉਂਦੇ ਹੋਂ, ਤਾਂ ਉਨ੍ਹਾਂ ਦੀ ਖੰਘ, ਛਿੱਕ, ਜਾਂ ਗੱਲਾਂ ਕਰਨ ਨਾਲ ਉਹਨਾਂ ਦੀਆਂ ਸਾਹ ਦੀਆਂ ਬੂੰਦਾਂ ਤੁਹਾਡੇ ਮੂੰਹ, ਨੱਕ, ਅੱਖਾਂ ਵਿੱਚ ਜਾ ਸਕਦੀਆਂ ਹਨI</p>
                <p><div className="paragraph-header">ਦੂਸ਼ਿਤ ਸਤਹਾਂ</div>ਤੁਸੀਂ ਵਾਇਰਸ ਨਾਲ ਦੂਸ਼ਿਤ ਸਤਹਾਂ ਨੂੰ ਛੂਹਣ ਅਤੇ ਫਿਰ ਆਪਣੇ ਮੂੰਹ, ਨੱਕ ਜਾਂ ਅੱਖਾਂ ਨੂੰ ਛੂਹਣ ਦੁਆਰਾ ਇਸ ਵਾਇਰਸ ਨਾਲ ਸੰਕਰਮਿਤ ਹੋ ਸਕਦੇ ਹੋI ਅਕਸਰ ਛੂਹਣ ਵਾਲੀਆਂ ਸਤਹਾਂ ਵਿੱਚ ਦਰਵਾਜ਼ੇ ਦੇ ਹੈਂਡਲ, ਲਾਈਟ ਦੀਆਂ ਸੁੱਚਾਂ, ਸੈਲਫੋਨ, ਮੇਜ਼, ਕੀਬੋਰਡ, ਟੋਇਲੇਟ, ਟੂਟੀਆਂ ਅਤੇ ਸਿੰਕ ਸ਼ਾਮਲ ਹੁੰਦੇ ਹਨI</p>
              </div>}
          />
          <Card 
            id="learn-3"
            icon="/assets/img/icons/symptoms.svg" 
            title="ਲੱਛਣ"
            content={
              <div>
                <div className="content-symptom-container">
                  <ul className="content-symptom-category">
                    <div className="content-symptom-title">ਆਮ ਲੱਛਣ</div>
                    <li className="content-symptom-item">ਬੁਖਰ (100.4°F, 38°C)</li>
                    <li className="content-symptom-item">ਸੁੱਕੀ ਖੰਘ</li>
                    <li className="content-symptom-item">ਸਾਹ ਚੜ੍ਹਨਾ</li>
                  </ul>
                  <ul className="content-symptom-category">
                    <div className="content-symptom-title">ਘੱਟ ਆਮ ਲੱਛਣ</div>
                    <li className="content-symptom-item">ਥਕਾਵਟ</li>
                    <li className="content-symptom-item">ਮਾਸਪੇਸ਼ੀ ਵਿਚ ਦਰਦ</li>
                    <li className="content-symptom-item">ਗਲੇ ਵਿੱਚ ਖਰਾਸ਼</li>
                    <li className="content-symptom-item">ਨੱਕ ਵਗਣਾ</li>
                    <li className="content-symptom-item">ਉਲਟੀਆਂ</li>
                    <li className="content-symptom-item">ਟੱਟੀਆ ਜਾਂ ਦਸਤ</li>
                    <li className="content-symptom-item">ਸੁਆਦ ਜਾਂ ਸੁੰਘਣ ਸ਼ਕਤੀ ਦਾ ਚਲੇ ਜਾਣਾ</li>
                  </ul>
                </div>
                <div className="content-symptom-caption">ਬਹੁਤ ਸਾਰੇ ਪ੍ਰਭਾਵਿਤ ਵਿਅਕਤੀਆਂ ਵਿੱਚ ਕੋਰੋਨਾਵਾਇਰਸ ਦੇ ਲੱਛਣ ਦਿਖਾਈ ਨਹੀਂ ਦਿੰਦੇI</div>
              </div>}
          />
          <Card 
            id="learn-4"
            icon="/assets/img/icons/sick.svg" 
            title="ਜੇਕਰ ਮੈਂ ਬਿਮਾਰ ਹੋ ਤਾਂ ਮੈਨੂੰ ਕੀ ਕਰਨਾ ਚਾਹੀਦਾ ਹੈ?"
            content={
              <div>
                <p>ਸੀਡੀਸੀ ਦੇ ਅਨੁਸਾਰ, ਬਹੁਤ ਸਾਰੇ ਲੋਕਾਂ ਨੂੰ ਹਲਕੀ ਬਿਮਾਰੀ ਹੁੰਦੀ ਹੈ ਅਤੇ ਉਹ ਘਰ ਵਿੱਚ ਹੀ ਠੀਕ ਹੋ ਸਕਦੇ ਹਨI ਡਾਕਟਰੀ ਦੇਖਭਾਲ ਲੈਣ ਤੋਂ ਇਲਾਵਾ, ਆਪਣੇ ਘਰੋਂ ਬਾਹਰ ਨਾ ਜਾਓI ਜੇ ਤੁਹਾਨੂੰ ਸਾਹ ਲੈਣ ਵਿੱਚ ਮੁਸ਼ਕਲ ਆ ਰਹੀ ਹੈ ਤਾਂ ਡਾਕਟਰੀ ਸਹਾਇਤਾ ਲਓ ਪਰ ਪਹਿਲਾਂ ਸਿਹਤ ਸੰਭਾਲ ਪ੍ਰਦਾਤਾ ਜਾਂ ਹਸਪਤਾਲ ਨੂੰ ਕਾਲ ਕਰੋI</p>
                <hr />
                <p>
                  <ul className="content-list">
                    <div>ਐਮਰਜਜੈਸੀ ਮੈਡੀਕਲ ਸਹਾਿੲਤਾ ਲਈ 911 ਤੇ ਫੋਨ ਕਰੋ ਜੇਕਰ ਤੁਹਾਨੁੰ:</div>
                    <li>ਸਾਹ ਲੈਣ ਵਿੱਚ ਪਰੇਸ਼ਾਨੀ ਹੈ</li>
                    <li>ਛਾਤੀ ਵਿੱਚ ਦਰਦ ਹੋ ਰਿਹਾ ਹੈ</li>
                    <li>ਤੁਹਾਨੂੰ ਸੋਚਣ ਵਿੱਚ ਉਲਝਣ ਹੈ ਜਾਂ ਤੁਸੀਂ ਬੌਂਦਲੇ ਹੋਏ ਹੋ</li>
                    <li>ਨੀਲੇ ਬੁੱਲ੍ਹ ਜਾਂ ਚਿਹਰਾ</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <ul className="content-list">
                    <div>ਹੋਰ ਜਾਣਕਾਰੀ ਲਈ ਜਾਂ ਮੱਦਦ ਲੈਣ ਲਈ ਸਥਾਨਕ ਕਾਊਂਟੀ ਪਬਲਿਕ ਹੈਲਥ ਮੰਤਰਾਲੇ ਨੂੰ ਫੋਨ ਕਰੋ. ਤੁਸੀ ਕੈਲੀਫੋਰਨੀਆ ਪਬਲਿਕ ਹੈਲਥ ਮੰਤਰਾਲਿਆਂ ਲਈ <a href="https://covid19.ca.gov/state-local-resources/#top" target="_blank">ਇੱਥੇ</a> ਕਲਿਕ ਕਰੋ.</div>
                    <li><a href="https://www.sccgov.org/sites/phd/DiseaseInformation/novel-coronavirus/Pages/home.aspx" target="_blank">ਸੈਂਟਾ ਕਲੈਰਾ ਕਾਊਂਟੀ:</a> 211 ਤੇ ਫੋਨ ਕਰੋ. ਕੋਵਿਡ-19 ਅਤੇ ਸਥਾਨਕ ਕਮਿਊਨਟੀ ਸਰੋਤਾਂ ਜਿਵੇਂ ਭੋਜਨ, ਪਨਾਹ, ਸਲਾਹ, ਰੁਜ਼ਗਾਰ ਸਹਾਇਤਾ, ਚਾਈਲਡ ਕੇਅਰ, ਸੀਨੀਅਰ ਸੇਵਾਵਾਂ, ਅਤੇ ਹੋਰ ਜਾਣਕਾਰੀ ਬਾਰੇ  (ਇਹ 24 ਘੰਟੇ ਅਤੇ 7 ਦਿਨ ਉਪਲਬਧ ਹਨ).</li>
                    <li><a href="https://sf.gov/topics/coronavirus-covid-19" target="_blank">ਸੇਨ ਫ੍ਰਾਂਸਿਸਕੋ:</a> ਕਮਿਊਨਟੀ ਸੁਵਿਧਾਵਾ ਅਤੇ ਦੇਖਭਾਲ ਲਈ ਸੇਨ ਫ੍ਰਾਂਸਿਸਕੋ ਵਿੱਚੋਂ 311  ਜਾਂ 415-701-2311 ਨੂੰ ਫੋਨ ਕਰੋ (ਇਹ 24 ਘੰਟੇ ਅਤੇ 7 ਦਿਨ ਉਪਲਬਧ ਹਨ)</li>
                    <li><a href="http://publichealth.lacounty.gov/media/Coronavirus/" target="_blank">ਲੌਸ ਐਂਜਲਸ:</a> ਕਮਿਊਨਟੀ ਸੁਵਿਧਾਵਾ ਅਤੇ ਦੇਖਭਾਲ ਲਈ 211 ਨੂੰ ਫੋਨ ਕਰੋ (ਇਹ 24 ਘੰਟੇ ਅਤੇ 7 ਦਿਨ ਉਪਲਬਧ ਹਨ)</li>
                    <li><a href="https://covid19info.ocgov.com/" target="_blank">ਆਰੰਜ ਕਾਊਂਟੀ:</a> ਕਮਿਊਨਟੀ ਸੁਵਿਧਾਵਾ ਅਤੇ ਦੇਖਭਾਲ ਲਈ 211 ਨੂੰ ਫੋਨ ਕਰੋ (ਇਹ 24 ਘੰਟੇ ਅਤੇ 7 ਦਿਨ ਉਪਲਬਧ ਹਨ)</li>
                    <li><a href="https://www.sandiegocounty.gov/coronavirus.html" target="_blank">ਸਨ ਡਿਏਗੋ:</a> ਕਮਿਊਨਟੀ ਸੁਵਿਧਾਵਾ ਅਤੇ ਦੇਖਭਾਲ ਲਈ ਜਾਂ ਤੁਹਾਡੇ ਕੋਲ ਇੰਸ਼ੋਰੈਂਸ ਨਹੀਂ ਹੈ ਤਾਂ 211 ਨੂੰ ਫੋਨ ਕਰੋ (ਇਹ 24 ਘੰਟੇ ਅਤੇ 7 ਦਿਨ ਉਪਲਬਧ ਹਨ)</li>
                    <li><a href="https://www.co.fresno.ca.us/departments/public-health/covid-19" target="_blank">ਫਰਿਜ਼ਨੋ:</a> (559) 600-4636 (8a-5p)</li>
                    <li><a href="http://www.acphd.org/2019-ncov.aspx" target="_blank">ਅਲਾਮੇਡਾ:</a> (510) 268-2101</li>
                    <li><a href="https://www.coronavirus.cchealth.org/" target="_blank">ਕੌਂਟਰਾ ਕੋਸਟਾ:</a> (844) 729-8410 (8a-5p)</li>
                  </ul>
                </p>
                <hr />
                <p><a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/disinfecting-your-home.html" target="_blank">ਜੇਕਰ ਘਰ ਵਿੱਚ ਕੋਈ ਬਿਮਾਰ ਹੋਵੇ</a> ਤਾਂ ਆਪਣੇ ਬਚਾ ਲਈ ਸੀ.ਡੀ.ਸੀ. (CDC) ਦੀ ਵੈਬਸਾਈਡ ਤੋਂ ਜਾਣਕਾਰੀ ਲਉI </p>
              </div>}
          />
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Learn;
