import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import classnames from 'classnames'
import {
  BrowserRouter as Router,
  Link,
  Route,
  useLocation,
  useRouteMatch,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom'
import MetaTags from 'react-meta-tags'

import Myth from './Myth'
import Learn from './Learn'
import Tips from './Tips'

import About from './About'
import Header from './Header'
import LanguageModal from '../Components/LanguageModal'
import ShareModal from './ShareModal'

class English extends React.PureComponent {
  state = {
    path: '',
    language: 'en',
    languageModalActive: false,
    shareModalActive: false
  }

  componentDidMount() {
    const {location} = this.props
    this.setState({
      path: location.pathname.slice(4)
    })
  }

  componentDidUpdate(prevProps) {
    const {location} = this.props
    this.setState({
      path: location.pathname.slice(4)
    })
  }

  toggleLanguageModalActive = () => {
    if (!this.state.languageModalActive) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    } else {
      document.getElementsByTagName('body')[0].style.overflow = 'visible'
    }
    this.setState({
      languageModalActive: !this.state.languageModalActive
    })
  }

  toggleShareModalActive = () => {
    if (!this.state.shareModalActive) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    } else {
      document.getElementsByTagName('body')[0].style.overflow = 'visible'
    }
    this.setState({
      shareModalActive: !this.state.shareModalActive
    })
  }

  render() {
    const { path, language, languageModalActive, shareModalActive } = this.state;

    return (
      <div className={classnames('container korean', {'about-active': path === 'about'})}>
        <MetaTags>
          <title>COVID-19 Fact Check</title>
          <meta name="description=" content="올바른 정보가 예방의 시작입니다." />
          <meta property="og:title" content="COVID-19 Fact Check" />
          <meta property="og:url" content="http://www.covid19factcheck.com/ko" />
          <meta property="og:description" content="올바른 정보가 예방의 시작입니다." />
          <meta property="og:site_name" content="COVID-19 Fact Check" />
          <meta property="og:image" content="http://www.covid19factcheck.com/assets/img/thumbnails/social_cover.png" />
          <meta name="twitter:title" content="COVID-19 Fact Check" />
          <meta name="twitter:description" content="올바른 정보가 예방의 시작입니다." />
          <meta name="twitter:image" content="http://www.covid19factcheck.com/assets/img/thumbnails/social_cover.png" />
        </MetaTags>
        
        <div className="topbar">
          <div className="topbar-logo"><a href={'/ko'}><img className="fit-parent" src="/assets/img/icons/logo.svg" /></a></div>
          <div className="topbar-nav">
            <h4 className="topbar-nav-item mobile"><a href={'/ko/about'}>소개</a></h4>
            <h4 className="topbar-nav-item mobile"><a href="mailto:contact@covid19factcheck.com" target="_blank">문의하기</a></h4>
          </div>
        </div>
        <Header language='ko' toggleLanguageModalActive={this.toggleLanguageModalActive} toggleShareModalActive={this.toggleShareModalActive} path={path} />
        <div className="content">
          <Switch>
            <Route path="/ko/learn" render={(props) => <Learn path={path} />} />
            <Route path="/ko/tips" render={(props) => <Tips path={path} />} />
            <Route path="/ko/about" render={(props) => <About />} />
            <Route render={(props) => <Myth path={path} />} />
          </Switch>
        </div>
        <LanguageModal active={languageModalActive} path={path} toggleLanguageModalActive={this.toggleLanguageModalActive} />
        <ShareModal active={shareModalActive} toggleShareModalActive={this.toggleShareModalActive} />
    	</div>
    );
  }
}

export default withRouter(English)
