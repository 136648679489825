import React, { Component } from 'react'
import classnames from 'classnames'

import Button from '../Components/Button'
import ClipboardButton from '../Components/ClipboardButton'

class ShareModal extends Component {

  componentDidMount() {
    window.Kakao.init('8c22c0e3b6141547b03badeeb6f137cd');
  }

  sendKakao = (e) => {
    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: 'COVID-19 Fact Check',
        description: 'Mou me’a mai ‘o vakai ki he peesi “COVID-19 Fact Check” ka mou ki he mo’oni mo e loi´ fekau’aki mo e ngaahi ongoongo ‘o e kolonavailasi´.',
        imageUrl:
          'http://www.covid19factcheck.com/assets/img/thumbnails/social_square.png',
        link: {
          mobileWebUrl: 'http://www.covid19factcheck.com/to',
          androidExecParams: 'test',
        },
      },
      success: function(response) {
        console.log(response);
      },
      fail: function(error) {
        console.log(error);
      }
    });
  }

  render() {
    const { active, toggleShareModalActive } = this.props;

    return (
      <div className={classnames('modal', 'share-modal', {'modal-active': active})}>
        <div className="modal-close" onClick={toggleShareModalActive}><img className="fit-parent" src="/assets/img/icons/close.svg" /></div>
        <div className="modal-content">
          <h1 className="modal-title">Fakamafola atu e Ongoongo´.</h1>
          <p className="modal-caption">Vahevahe e peesi “COVID-19 Fact Check” mo ha ngaahi kaungāme’a´ pea pehē ki ho fāmili´ koe’uhī ka tau ‘ilo kotoa e founga lelei taha ‘etau ta’ofi e pipihi ‘a e kolonavailasi´.</p>
          <div className="share-modal-action-container">
            <div className="share-modal-action">
              <a href="https://twitter.com/intent/tweet?text=Mou%20me%E2%80%99a%20mai%20%E2%80%98o%20vakai%20ki%20he%20peesi%20%E2%80%9CCOVID-19%20Fact%20Check%E2%80%9D%20ka%20mou%20ki%20he%20mo%E2%80%99oni%20mo%20e%20loi%C2%B4%20fekau%E2%80%99aki%20mo%20e%20ngaahi%20ongoongo%20%E2%80%98o%20e%20kolonavailasi%C2%B4.&tw_p=tweetbutton&url=http%3A%2F%2Fcovid19factcheck.com%2Fto" target="_blank">
                <img className="fit-parent" src="/assets/img/icons/share/twitter.png" />
              </a>
            </div>
            <div className="share-modal-action">
              <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=http://covid19factcheck.com/to/" target="_blank">
                <img className="fit-parent" src="/assets/img/icons/share/facebook.png" />
              </a>
            </div>
            <div className="share-modal-action">
              <a href="https://wa.me/?text=Mou%20me%E2%80%99a%20mai%20%E2%80%98o%20vakai%20ki%20he%20peesi%20%E2%80%9CCOVID-19%20Fact%20Check%E2%80%9D%20ka%20mou%20ki%20he%20mo%E2%80%99oni%20mo%20e%20loi%C2%B4%20fekau%E2%80%99aki%20mo%20e%20ngaahi%20ongoongo%20%E2%80%98o%20e%20kolonavailasi%C2%B4.%20http%3A%2F%2Fcovid19factcheck.com%2Fto" data-action="share/whatsapp/share" target="_blank">
                <img className="fit-parent" src="/assets/img/icons/share/whatsapp.png" />
              </a>
            </div>
            <div className="share-modal-action">
              <a onClick={this.sendKakao}>
                <img className="fit-parent" src="/assets/img/icons/share/kakaotalk.png" />
              </a>
            </div>
            <div className="share-modal-action">
                <a href="https://social-plugins.line.me/lineit/share?url=http%3A%2F%2Fcovid19factcheck.com%2Fto" target="_blank">
                  <img className="fit-parent" src="/assets/img/icons/share/line.png" />
                </a>
            </div>
            <div className="share-modal-action">
              <a href="mailto:?body=Mou%20me%E2%80%99a%20mai%20%E2%80%98o%20vakai%20ki%20he%20peesi%20%E2%80%9CCOVID-19%20Fact%20Check%E2%80%9D%20ka%20mou%20ki%20he%20mo%E2%80%99oni%20mo%20e%20loi%C2%B4%20fekau%E2%80%99aki%20mo%20e%20ngaahi%20ongoongo%20%E2%80%98o%20e%20kolonavailasi%C2%B4.%20http%3A%2F%2Fcovid19factcheck.com%2Fto">
                <img className="fit-parent" src="/assets/img/icons/share/mail.png" />
              </a>
            </div>
          </div>
          <ClipboardButton value="http://covid19factcheck.com/to" success="Ko e tu’asila´ ni kuo hiki tatau! 🎉">
            <Button className="btn-share" label="Copy Share Link" imgURL="/assets/img/icons/link.svg" />
          </ClipboardButton>
        </div>
      </div>
    )
  }
}

export default ShareModal