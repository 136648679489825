import React, { Component } from 'react'
import classnames from 'classnames'

import Button from '../Components/Button'
import ClipboardButton from '../Components/ClipboardButton'

class ShareModal extends Component {

  componentDidMount() {
    window.Kakao.init('8c22c0e3b6141547b03badeeb6f137cd');
  }

  sendKakao = (e) => {
    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: 'COVID-19 Fact Check',
        description: 'Jekem Stret Infomesen blong COVID-19 blong yu faenemaot olgeta infomesen we oli no tru mo olgeta we i tru long saed blong koronavaeres.',
        imageUrl:
          'http://www.covid19factcheck.com/assets/img/thumbnails/social_square.png',
        link: {
          mobileWebUrl: 'http://www.covid19factcheck.com/bi',
          androidExecParams: 'test',
        },
      },
      success: function(response) {
        console.log(response);
      },
      fail: function(error) {
        console.log(error);
      }
    });
  }

  render() {
    const { active, toggleShareModalActive } = this.props;

    return (
      <div className={classnames('modal', 'share-modal', {'modal-active': active})}>
        <div className="modal-close" onClick={toggleShareModalActive}><img className="fit-parent" src="/assets/img/icons/close.svg" /></div>
        <div className="modal-content">
          <h1 className="modal-title">Pasem toktok i go raon.</h1>
          <p className="modal-caption">Serem olgeta Stret Infomesen blong COVID-19 wetem olgeta fren mo famili blong yumi save namba wan wei blong stopem koronavaesres i spred.</p>
          <div className="share-modal-action-container">
            <div className="share-modal-action">
              <a href="https://twitter.com/intent/tweet?text=Jekem%20Stret%20Infomesen%20blong%20COVID-19%20blong%20yu%20faenemaot%20olgeta%20infomesen%20we%20oli%20no%20tru%20mo%20olgeta%20we%20i%20tru%20long%20saed%20blong%20koronavaeres.&tw_p=tweetbutton&url=http%3A%2F%2Fcovid19factcheck.com%2Fbi" target="_blank">
                <img className="fit-parent" src="/assets/img/icons/share/twitter.png" />
              </a>
            </div>
            <div className="share-modal-action">
              <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=http://covid19factcheck.com/bi/" target="_blank">
                <img className="fit-parent" src="/assets/img/icons/share/facebook.png" />
              </a>
            </div>
            <div className="share-modal-action">
              <a href="https://wa.me/?text=Jekem%20Stret%20Infomesen%20blong%20COVID-19%20blong%20yu%20faenemaot%20olgeta%20infomesen%20we%20oli%20no%20tru%20mo%20olgeta%20we%20i%20tru%20long%20saed%20blong%20koronavaeres.%20http%3A%2F%2Fcovid19factcheck.com%2Fbi" data-action="share/whatsapp/share" target="_blank">
                <img className="fit-parent" src="/assets/img/icons/share/whatsapp.png" />
              </a>
            </div>
            <div className="share-modal-action">
              <a onClick={this.sendKakao}>
                <img className="fit-parent" src="/assets/img/icons/share/kakaotalk.png" />
              </a>
            </div>
            <div className="share-modal-action">
                <a href="https://social-plugins.line.me/lineit/share?url=http%3A%2F%2Fcovid19factcheck.com%2Fbi" target="_blank">
                  <img className="fit-parent" src="/assets/img/icons/share/line.png" />
                </a>
            </div>
            <div className="share-modal-action">
              <a href="mailto:?body=Jekem%20Stret%20Infomesen%20blong%20COVID-19%20blong%20yu%20faenemaot%20olgeta%20infomesen%20we%20oli%20no%20tru%20mo%20olgeta%20we%20i%20tru%20long%20saed%20blong%20koronavaeres.%20http%3A%2F%2Fcovid19factcheck.com%2Fbi">
                <img className="fit-parent" src="/assets/img/icons/share/mail.png" />
              </a>
            </div>
          </div>
          <ClipboardButton value="http://covid19factcheck.com" success="Link ia oli kopi finis! 🎉">
            <Button className="btn-share" label="Kopi Link we oli Serem" imgURL="/assets/img/icons/link.svg" />
          </ClipboardButton>
        </div>
      </div>
    )
  }
}

export default ShareModal