import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Learn extends Component {

  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <h4 className="content-nav-caption">Оглавление</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-1')}>Обзор</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-2')}>Как распространяется COVID-19?</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-3')}>Симптомы</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-4')}>Что мне делать, в случае подозрения на заболевание?</h4>
        </div>
        <div className="content-body">
          <Card 
            id="learn-1"
            icon="/assets/img/icons/info.svg" 
            title="Обзор"
            content={
              <div>
                <p>Коронавирус представляет собой семейство вирусов, которые могут привести к общим респираторным заболеваниям, такое как простуда.</p>
                <p>В 2019 году был обнаружен новый коронавирус. SARS-CoV-2 - это научное название вируса, а вызываемое им заболевание было названо коронавирусной болезнью 2019 года (COVID-19).</p>
              </div>}
          />
          <Card 
            id="learn-2"
            icon="/assets/img/icons/spread.svg" 
            title="Как распространяется COVID-19?"
            content={
              <div>
                <p><div className="paragraph-header">От человека к человеку</div>Дыхательные капли являются основным способом распространения COVID-19. Если вы находитесь в пределах 2 метров от зараженного человека, дыхательные капли от его кашля, чихания или разговора могут попасть в ваш рот, нос или глаза.</p>
                <p><div className="paragraph-header">Через зараженные поверхности</div>Вы можете заразиться прикоснувшись к поверхности содержащей вирус, а затем коснувшись рта, носа или глаз. Наиболее подвержены загрязнению вирусом: дверные ручки, выключатели, мобильные телефоны, поверхности столов, клавиатура, краны и т.д.</p>
              </div>}
          />
          <Card 
            id="learn-3"
            icon="/assets/img/icons/symptoms.svg" 
            title="Симптомы"
            content={
              <div>
                <div className="content-symptom-container">
                  <ul className="content-symptom-category">
                    <div className="content-symptom-title">Общие симптомы</div>
                    <li className="content-symptom-item">Повышенная температура (38°C)</li>
                    <li className="content-symptom-item">Сухой кашель</li>
                    <li className="content-symptom-item">Одышка</li>
                  </ul>
                  <ul className="content-symptom-category">
                    <div className="content-symptom-title">Сопутствующие симптомы</div>
                    <li className="content-symptom-item">Усталость</li>
                    <li className="content-symptom-item">Боль в мышцах</li>
                    <li className="content-symptom-item">Боль в горле</li>
                    <li className="content-symptom-item">Насморк</li>
                    <li className="content-symptom-item">Тошнота</li>
                    <li className="content-symptom-item">Диарея</li>
                    <li className="content-symptom-item">Потеря обоняния и вкуса</li>
                  </ul>
                </div>
                <div className="content-symptom-caption">Во многих случаях заболевание может протекать бессимптомно.</div>
              </div>}
          />
          <Card 
            id="learn-4"
            icon="/assets/img/icons/sick.svg" 
            title="Что мне делать, в случае подозрения на заболевание?"
            content={
              <div>
                <p>Согласно CDC у большинства заболевших заболевание протекает в легкой форме и они могут выздороветь оставаясь дома. Выходите из дома только для получения медицинской помощи. Если вы испытываете проблемы с дыханием, Обратитесь к врачу, но сначала позвоните врачу или в медицинское учреждение.</p>
                <hr />
                <p>
                  <ul className="content-list">
                    <div>Позвоните в скорую (911 в США), если у вас чрезвычайное медицинское положение, включая:</div>
                    <li>Затруднение дыхания</li>
                    <li>Боль в груди</li>
                    <li>Помутнение мыслей или неспособность разбудить</li>
                    <li>Посиневшее лицо или губы</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <ul className="content-list">
                    <div>За дополнительной информацией обращайтесь в ваш местный отдел здравоохранения. Перейдите по <a href="https://covid19.ca.gov/state-local-resources/#top" target="_blank">этой</a> ссылке для получения списка отделов здравоохранения в штате Калифорния.</div>
                    <li><a href="https://www.sccgov.org/sites/phd/DiseaseInformation/novel-coronavirus/Pages/home.aspx" target="_blank">Santa Clara County:</a> Позвоните 211 для получения информации о COVID-19 и оказания помощи с едой, жильем, уходом за детьми и пожилыми людьми, трудоустройством и т.д. (доступно 24/7).</li>
                    <li><a href="https://sf.gov/topics/coronavirus-covid-19" target="_blank">San Francisco:</a> 311 (в пределах Сан Франциско) или 415-701-2311 (доступно 24/7)</li>
                    <li><a href="http://publichealth.lacounty.gov/media/Coronavirus/" target="_blank">Los Angeles:</a> 211 (доступно 24/7)</li>
                    <li><a href="https://covid19info.ocgov.com/" target="_blank">Orange County:</a> 211 (доступно 24/7)</li>
                    <li><a href="https://www.sandiegocounty.gov/coronavirus.html" target="_blank">San Diego:</a> 211 (доступно 24/7)</li>
                    <li><a href="https://www.co.fresno.ca.us/departments/public-health/covid-19" target="_blank">Fresno:</a> (559) 600-4636 (доступно 0800-1700)</li>
                    <li><a href="http://www.acphd.org/2019-ncov.aspx" target="_blank">Alameda:</a> (510) 268-2101</li>
                    <li><a href="https://www.coronavirus.cchealth.org/" target="_blank">Contra Costa:</a> (844) 729-8410 (доступно 0800-1700)</li>
                  </ul>
                </p>
                <hr />
                <p>Посетите <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/disinfecting-your-home.html" target="_blank">сайт CDC</a> для полезных советов о том, как минимизировать заражение когда кто-то болен.</p>
              </div>}
          />
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Learn;
