import React, { Component } from 'react'
import classnames from 'classnames'

import Button from '../Components/Button'
import ClipboardButton from '../Components/ClipboardButton'

class ShareModal extends Component {

  componentDidMount() {
    window.Kakao.init('8c22c0e3b6141547b03badeeb6f137cd');
  }

  sendKakao = (e) => {
    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: 'COVID-19 Fact Check',
        description: 'Chekim COVID-19 Fact Check lo lainim wanem em giaman toktok na wanem em trupla toktok behaninim coronavirus.',
        imageUrl:
          'http://www.covid19factcheck.com/assets/img/thumbnails/social_square.png',
        link: {
          mobileWebUrl: 'http://www.covid19factcheck.com/tp',
          androidExecParams: 'test',
        },
      },
      success: function(response) {
        console.log(response);
      },
      fail: function(error) {
        console.log(error);
      }
    });
  }

  render() {
    const { active, toggleShareModalActive } = this.props;

    return (
      <div className={classnames('modal', 'share-modal', {'modal-active': active})}>
        <div className="modal-close" onClick={toggleShareModalActive}><img className="fit-parent" src="/assets/img/icons/close.svg" /></div>
        <div className="modal-content">
          <h1 className="modal-title">Surukim toksave.</h1>
          <p className="modal-caption">Sharim COVID-19 Fact Check wantaim ol poroman na femli blo yu so yumi olgeta ken save gut lo stretpla wei blo stopim coronavirus.</p>
          <div className="share-modal-action-container">
            <div className="share-modal-action">
              <a href="https://twitter.com/intent/tweet?text=Chekim%20COVID-19%20Fact%20Check%20lo%20lainim%20wanem%20em%20giaman%20toktok%20na%20wanem%20em%20trupla%20toktok%20behaninim%20coronavirus.&tw_p=tweetbutton&url=http%3A%2F%2Fcovid19factcheck.com%2Ftp" target="_blank">
                <img className="fit-parent" src="/assets/img/icons/share/twitter.png" />
              </a>
            </div>
            <div className="share-modal-action">
              <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=http://covid19factcheck.com/tp" target="_blank">
                <img className="fit-parent" src="/assets/img/icons/share/facebook.png" />
              </a>
            </div>
            <div className="share-modal-action">
              <a href="https://wa.me/?text=Chekim%20COVID-19%20Fact%20Check%20lo%20lainim%20wanem%20em%20giaman%20toktok%20na%20wanem%20em%20trupla%20toktok%20behaninim%20coronavirus.%20http%3A%2F%2Fcovid19factcheck.com%2Ftp" data-action="share/whatsapp/share" target="_blank">
                <img className="fit-parent" src="/assets/img/icons/share/whatsapp.png" />
              </a>
            </div>
            <div className="share-modal-action">
              <a onClick={this.sendKakao}>
                <img className="fit-parent" src="/assets/img/icons/share/kakaotalk.png" />
              </a>
            </div>
            <div className="share-modal-action">
                <a href="https://social-plugins.line.me/lineit/share?url=http%3A%2F%2Fcovid19factcheck.com%2Ftp" target="_blank">
                  <img className="fit-parent" src="/assets/img/icons/share/line.png" />
                </a>
            </div>
            <div className="share-modal-action">
              <a href="mailto:?body=Chekim%20COVID-19%20Fact%20Check%20lo%20lainim%20wanem%20em%20giaman%20toktok%20na%20wanem%20em%20trupla%20toktok%20behaninim%20coronavirus.%20http%3A%2F%2Fcovid19factcheck.com%2Ftp">
                <img className="fit-parent" src="/assets/img/icons/share/mail.png" />
              </a>
            </div>
          </div>
          <ClipboardButton value="http://covid19factcheck.com/tp" success="Displa link em copy pinis! 🎉">
            <Button className="btn-share" label="Copy na Sharim Link" imgURL="/assets/img/icons/link.svg" />
          </ClipboardButton>
        </div>
      </div>
    )
  }
}

export default ShareModal