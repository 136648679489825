import React, { Component } from 'react'
import classnames from 'classnames'
import { Link, withRouter } from 'react-router-dom'

import Button from '../Components/Button'
import ClipboardButton from '../Components/ClipboardButton'

class Aside extends React.PureComponent {

  render() {

    return (
      <div className="content-aside desktop">
        <div className="content-share">
          <h4 className="content-share-caption">Ibahagi sa inyong mga kaibigan at kamag-anak</h4>
          <div className="content-share-options">
            <div className="content-share-item content-share-twitter">
              <img className="fit-height" src="/assets/img/icons/twitter.png" />
              <a className="twitter-share-button" href="https://twitter.com/intent/tweet?text=Tingnan%20ang%20COVID-19%20Fact%20Check%20para%20matutunan%20kung%20ano%20ang%20tama%20at%20mali%20tungkol%20sa%20coronavirus.&tw_p=tweetbutton&url=http%3A%2F%2Fcovid19factcheck.com%2Ftl%2F" target="_blank">Tweet</a>
            </div>
            <div className="content-share-item content-share-facebook">
              <img className="fit-height" src="/assets/img/icons/facebook.png" />
              <a className="fb-share-button" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=http://covid19factcheck.com/tl/"></a>
            </div>
            <div className="content-share-item content-share-email">
              <img className="fit-height" src="/assets/img/icons/email.svg" />
              <a href="mailto:?body=Tingnan%20ang%20COVID-19%20Fact%20Check%20para%20matutunan%20kung%20ano%20ang%20tama%20at%20mali%20tungkol%20sa%20coronavirus.%20http%3A%2F%2Fcovid19factcheck.com%2Ftl%2F"></a>
            </div>
            <ClipboardButton className="content-share-item content-share-link" value="http://covid19factcheck.com/tl/" success="Nakopya na ang link! 🎉"><img className="fit-height" src="/assets/img/icons/link.svg" /></ClipboardButton>
          </div>
        </div>
        <h5 className="content-footnote">Ang karamihan ng impormasyon dito ay mula sa <a href="https://www.who.int/" target="_blank">WHO</a> at <a href="https://www.cdc.gov/" target="_blank">CDC</a>.<br /> Huling rebisyon 06/06/20.</h5>
      </div>
    )
  }
}

export default withRouter(Aside)