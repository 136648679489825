import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Myth extends Component {

  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <div className="content-nav-scroller">
            <h4 className="content-nav-caption">Mục Lục</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-1')}>Tôi có cần duy trì khoảng cách 6 feet với người khác khi đeo khẩu trang không?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-10')}>Chích hoặc uống thuốc khử trùng như cồn, thuốc tẩy (bleach) có thể tiêu diệt được coronavirus không?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-2')}>Tôi có cần mua thêm khẩu trang y tế và mặt nạ N95 không?</h4>
            {/* <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-3')}>Coronavirus có khả năng lây lan trong không khí qua khoảng cách xa không?</h4> */}
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-4')}>Đeo bao tay có chống lại coronavirus 100% không?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-5')}>Coronavirus có thể sống trên các bề mặt vài ngày không?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-6')}>Tôi có nên rửa tay thường xuyên và khử trùng bề mặt đồ vật, ngay cả khi không ra khỏi nhà?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-7')}>Ibuprofen có thể làm bệnh COVID-19 nghiêm trọng hơn không?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-8')}>Tự chữa bệnh bằng thuốc hydroxychloroquine & azithromycin có thể ngăn ngừa COVID-19 không?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-9')}>Thuốc kháng sinh có hiệu quả ngăn ngừa và điều trị COVID-19 không?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-11')}>Người trẻ và khỏe mạnh có thể bị nhiễm COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-12')}>Nếu bị nhiễm COVID-19, tôi có cần phải nhập viện không?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-13')}>Nếu tôi có thể nín thở hơn 10 giây mà không cảm giác khó chịu hoặc ho thì tôi không bị bệnh COVID-19, đúng không?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-14')}>Máy sấy khô tay và đèn khử trùng UV có thể tiêu diệt được coronavirus không?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-15')}>Phương pháp rửa mũi bằng nước muối thường xuyên có thể ngăn ngừa COVID-19 không?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-16')}>Tắm nước nóng có thể ngăn ngừa COVID-19 không?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-17')}>Thời tiết lạnh và tuyết có thể ngăn ngừa COVID-19 không?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-18')}>Ăn tỏi có thể ngăn ngừa COVID-19 không?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-19')}>Uống rượu có thể ngăn ngừa COVID-19 không?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-20')}>Vắc-xin chống viêm phổi bảo có thể chống lại COVID-19 không?</h4>
          </div>
        </div>
        <div className="content-body">
          <Card 
            id="myth-1"
            thumbnail="/assets/img/shots/myth/myth-1.jpg" 
            title="Tôi có cần duy trì khoảng cách 6 feet với người khác khi đeo khẩu trang không?"
            content={
              <div>
                <p><span className="card-tag-true">Đúng</span>Bạn nên duy trì khoảng cách 6 feet hoặc 2 mét với người khác; rửa tay thường xuyên, kể cả trước khi đeo và sau khi cởi khẩu trang. </p>
                <p>Tại Hoa Kỳ, <a href="https://vietnamese.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover-faq.html" target="_blank">CDC</a> khuyến nghị mọi người nên đeo khẩu trang vải để bảo vệ những người xung quanh. Khẩu trang giúp ngăn chặn bạn lây vi-rút bằng cách chứa các giọt văng ra khi bạn ho, hắt hơi hoặc nói chuyện. Tùy theo loại vải và mức độ vừa khít, khẩu trang có thể giúp bảo vệ người đeo nhưng nó sẽ không ngăn chặn được tất cả hạt vi-rút. Xin xem trang “Cách Phòng Chống.”</p>
              </div>}
            extra={
              <div>
                <p>Xin theo khuyến nghị của <a href="https://vietnamese.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a> và sử dụng những vật liệu an toàn từ nhà để làm khẩu trang. Khẩu trang nên vừa khít mặt vừa cho bạn thở được thoải mái. CDC khuyến nghị nhiều lớp vải loại nào cũng được.</p>
                <p><div className="paragraph-header">Khẩu trang vải có hiệu quả chống lại coronavirus không?</div>Hiện tại không có bằng chứng khoa học để trả lời câu hỏi này. Khi <a href="https://www.ncbi.nlm.nih.gov/pubmed/24229526" target="_blank">thí nghiệm</a> với một con vi-rút khác, nhà nghiên cứu phát hiện không có loại vải nào (vải cotton, lụa, vải bao gối, vải lanh, khăn quàng cổ, khăn lau bát đĩa) có thể ngăn chặn được vi-rút 100%. Vi-rút có thể xuyên qua vải vì rất nhỏ. Nhưng thí nghiệm này không có miêu tả được cách ho bình thường của con người. Hơn nữa, thí nghiệm này không suy xét khẩu trang vừa kín khuôn mặt hay không. Đa số khẩu trang không vừa khít sát mặt nên vi-rút vẫn có thể <a href="https://www.gannett-cdn.com/experiments/usatoday/gifs/tool/919-surgical.png" target="_blank">vào hai bên góc của khẩu trang</a></p>
                <p><div className="paragraph-header">Điểm chính</div>Khẩu trang vải có thể giúp ngăn ngừa nhiễm trùng nhưng không đảm bảo 100%.</p>
              </div>}
          />
          <Card 
            id="myth-10"
            thumbnail="/assets/img/shots/myth/myth-10.jpg" 
            title="Chích hoặc uống thuốc khử trùng như cồn, thuốc tẩy (bleach) có thể tiêu diệt được coronavirus không?"
            content={
              <div>
                <p><span className="card-tag-false">Không</span>Đừng <a href="https://www.rb.com/media/news/2020/april/improper-use-of-disinfectants" target="_blank">chích</a>, uống hoặc xịt những thuốc khử trùng như cồn, thuốc tẩy (bleach) lên khắp cơ thể bởi vì những phương pháp đó <strong className="red">rất nguy hiểm.</strong></p>
                <p>Theo <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, thuốc khử trùng có thể gây tổn hại nghiêm trọng đối với sức khỏe. Hơn nữa, những phương pháp này không diệt được những vi-rút đã xâm nhập vào cơ thể bạn. Chất cồn và thuốc tẩy hữu ích để khử trùng bề mặt theo các <a href="https://vietnamese.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cleaning-disinfection.html" target="_blank">khuyến nghị thích hợp.</a></p>
              </div>}
          />
          <Card 
            id="myth-2"
            thumbnail="/assets/img/shots/myth/myth-2.jpg" 
            title="Tôi có cần mua thêm khẩu trang y tế và mặt nạ N95 không?"
            content={
              <div>
                <p><span className="card-tag-false">Không</span>Tại Hoa Kỳ, <a href="https://vietnamese.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a> khuyến nghị người dân đeo khẩu trang vải ở những nơi công cộng.</p>
                <p>Bạn có thể đeo bất cứ khẩu trang nào bạn đã có sẵn ở nhà. Tại Hoa Kỳ, khẩu trang y tế và mặt nạ N95 đang bị thiếu, nếu có thể để dành cho nhân viên y tế vì họ cần tiếp xúc với bệnh nhân. Họ được huấn luyện để sử dụng mặt nạ theo đúng cách thức để có hiệu quả. Mặt nạ N95 phòng ngừa những bệnh lây qua không khí nhưng COVID-19 không có lây qua không khí.</p>
              </div>}
            extra={
              <div>
                <p>Mặt nạ <a href="https://www.fda.gov/medical-devices/personal-protective-equipment-infection-control/n95-respirators-and-surgical-masks-face-masks" target="_blank">N95</a> chuyên vừa khít với khuôn mặt. Khi đeo đúng cách, mặt nạ N95 có thể lọc 95% các phân tử hạt trong không khí. Ngoài sự huấn luyện, hàng năm nhân viên y tế còn phải hoàn thành <a href="https://www.safetyalliance.org/wp-content/uploads/2019/06/fit-test-2-000110_retouched-672x372.jpg" target="_blank">kiểm tra</a> để đảm bảo kích thước của mặt nạ N95 chính xác. Những chi tiết nhỏ như râu trên khuôn mặt cũng có thể làm mặt nạ không vừa khít. Khi đeo đúng cách, mặt nạ N95 có thể khiến hô hấp không thoải mái. Khi thiếu huấn luyện và kiểm tra, mặt nạ <a href="https://blogs.cdc.gov/niosh-science-blog/2018/01/04/respirators-public-use/" target="_blank">N95 có thể không khít</a> và sẽ mang đến sự bảo vệ kém hơn.</p>
              </div>}
          />
          {/*
          <Card 
            id="myth-3"
            thumbnail="/assets/img/shots/myth/myth-3.jpg" 
            title="Coronavirus có khả năng lây lan trong không khí qua khoảng cách xa không?"
            content={
              <div>
                <p><span className="card-tag-maybe">Có thể</span>Đa số ngoài ngành y tế, coronavirus sẽ không có khả năng lây lan trong không khí qua khoảng cách xa.</p>
                <p>Theo <a href="https://www.who.int/news-room/q-a-detail/q-a-coronaviruses" target="_blank">WHO</a>, bệnh COVID-19 chủ yếu lan truyền qua các giọt nước được tạo khi người nhiễm bệnh ho, hắt hơi hoặc nói chuyện; và các giọt nước vướng mắt mũi miệng của những người ở gần. Những giọt này nhanh chóng rơi xuống sàn hoặc bề mặt, không lơ lửng trong không khí vì quá nặng. Theo nghiên cứu hơn 75.000 bệnh nhân COVID-19 ở Trung Quốc, không ai bị lây truyền từ không khí. Theo WHO, COVID-19 có thể lây lan qua không khí trong <a href="https://www.who.int/news-room/commentaries/detail/modes-of-transmission-of-virus-causing-covid-19-implications-for-ipc-precaution-recommendations" target="_blank">quá trình chữa bệnh trong bệnh viện</a> như khi bác sĩ luồn ống vào khí quản để giúp bệnh nhân thở.</p>
                </div>}
            extra={
              <div>
                <p><div className="paragraph-header">Bệnh lây truyền qua không khí nghĩa là gì?</div>So sánh với các bệnh lây truyền từ giọt hô hấp, hạt vi-rút của các bệnh lây truyền qua không khí nhỏ hơn, ở trong không khí lâu hơn, và có thể lan xa hơn 6 feet.</p>
                <p><div className="paragraph-header">Tại sao tin tức nói coronavirus lan truyền qua không khí?</div>Nhà khoa học <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973" target="_blank">thí nghiệm</a> với máy công suất cao để chế tạo coronavirus trong một bình chứa. Trong bình chứa, vi-rút treo trong không khí đến 3 tiếng. Tuy nhiên, máy công suất cao không miêu tả được cách ho bình thường của con người. Trong một <a href="https://www.medrxiv.org/content/10.1101/2020.03.23.20039446v2" target="_blank">nghiên cứu</a> khác mà chưa được các chuyên gia khác đánh giá, nhà khoa học phát hiện coronavirus trong không khí và bề mặt của phòng bệnh viện của những bệnh nhân COVID-19. 76,5% đồ cá nhân (điện thoại di động, nhà vệ sinh, đường ray giường, vv) có coronavirus. 63,2% mẫu không khí thí nghiệm có coronavirus và một số mẫu được thu thập cách bệnh nhân xa hơn 6 feet. Tuy nhiên, vi-rút trong không khí không nghĩa là vi-rút có thể gây bệnh vì có thể số lượng quá thấp. Nhà khoa học chưa thể chứng minh thành phần của vi-rút mà họ phát hiện có thể gây bệnh.</p>
              </div>}
          />
        */}
          <Card 
            id="myth-4"
            thumbnail="/assets/img/shots/myth/myth-4.jpg" 
            title="Đeo bao tay có chống lại coronavirus 100% không?"
            content={
              <div>
                <p><span className="card-tag-false">Không</span>Bao tay chỉ giúp ích nếu bạn duy trì các biện pháp phòng ngừa như không đụng vào mắt, mũi, miệng và rửa tay sau khi tháo bao tay. Bất cứ điều gì bao tay chạm vào, bao tay có thể truyền nhiễm lên bề mặt khác.</p>
              </div>}
          />
          <Card 
            id="myth-5"
            thumbnail="/assets/img/shots/myth/myth-5-vn.jpg" 
            title="Coronavirus có thể sống trên các bề mặt vài ngày không?"
            content={
              <div>
                <p><span className="card-tag-true">Đúng</span><a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973?query=featured_home" target="_blank">Nghiên cứu</a> của NEJM trong phòng thí nghiệm cho thấy coronavirus có thể tồn tại trên giấy cạc tông cho đến 24 tiếng, và trên nhựa đến 72 tiếng.</p>
                <p>Điều quan trọng cần lưu ý là lượng vi-rút  còn lại ít hơn 0,1% so với nguyên lượng vi-rút ban đầu. Sự hiện diện của vi-rút không có nghĩa là vi-rút có thể lây nhiễm. Vì số lượng vi-rút trên bề mặt đồ vật giảm nhanh chóng theo thời gian, nguy cơ nhiễm trùng khi đụng vào chúng cũng sẽ giảm theo thời gian.</p>
              </div>}
          />
          <Card 
            id="myth-6"
            thumbnail="/assets/img/shots/myth/myth-6.jpg" 
            title="Tôi có nên rửa tay thường xuyên và khử trùng bề mặt đồ vật, ngay cả khi không ra khỏi nhà?"
            content={
              <div>
                <p><span className="card-tag-true">Đúng</span><a href="https://vietnamese.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/prevention.html" target="_blank">CDC</a> khuyến nghị giữ vệ sinh và khử trùng hàng ngày các bề mặt thường xuyên chạm vào như tay nắm cửa, công tắc đèn, mặt bàn, điện thoại, bàn chữ, cầu tiêu, vòi và bồn rửa.</p>
                <p>Bạn hoặc một thành viên trong gia đình có thể bị nhiễm bệnh mà không có triệu chứng. Mặc dù cơ hội bị nhiễm bệnh do tiếp xúc gần gũi với người bệnh cao hơn là nhiễm bệnh từ bề mặt bị ô nhiễm, hãy thực hành các biện pháp phòng ngừa nếu bạn có thể.</p>
              </div>}
          />
          <Card 
            id="myth-7"
            thumbnail="/assets/img/shots/myth/myth-7.jpg" 
            title="Ibuprofen có thể làm bệnh COVID-19 nghiêm trọng hơn không?"
            content={
              <div>
                <p><span className="card-tag-false">Không</span>Hiện tại không có đủ bằng chứng khoa học để chứng minh ibuprofen (tên hiệu Advil, Motrin) làm cho các triệu chứng COVID-19 trở nên nặng hơn.</p>
                <p>Nếu cần thiết <a href="https://twitter.com/WHO/status/1240409217997189128" target="_blank">WHO</a> nói có thể sử dụng ibuprofen (18/03/2020). Hoặc có thể thay thế với thuốc Acetaminophen (Tylenol) để hạ sốt và giảm đau. <a href="https://www.fda.gov/drugs/drug-safety-and-availability/fda-advises-patients-use-non-steroidal-anti-inflammatory-drugs-nsaids-covid-19" target="_blank">FDA</a> đề nghị nói chuyện với bác sĩ của bạn nếu bạn lo lắng về việc dùng các thuốc chống viêm không steroid (NSAID) để điều trị các bệnh mãn tính.</p>
                </div>}
            extra={
            <div>
              <p>Ibuprofen thuộc vào nhóm thuốc chống viêm không steroid <a href="https://www.goodrx.com/blog/are-advil-motrin-tylenol-bad-for-coronavirus-symptoms/" target="_blank">NSAIDS</a> và bao gồm aspirin, naproxen (Aleve), diclofenac (Cambia) và celecoxib (Celebrex), để hạ sốt và giảm đau. Thuốc nào cũng có lợi ích và rủi ro có thể xảy ra khi sử dụng. Ví dụ, NSAID có thể tác dụng phụ đến đường tiêu hóa và thận. Còn acetaminophen có thể suy gan nếu lỡ uống quá liều. Acetaminophen thường là một thành phần trong thuốc cảm lạnh và cúm. Xin đọc kỹ nhãn thuốc, đặc biệt là các thành phần của thuốc.</p>
              <p><div className="paragraph-header">Cuộc tranh luận về ibuprofen bắt đầu như thế nào</div><a href="https://jvi.asm.org/content/94/7/e00127-20" target="_blank">Nghiên cứu</a> cho thấy ACE2, một loại enzim trong cơ thể, là vị trí cho coronavirus bám vào và xâm nhập vào tế bào.</p>
              <p>Ngày 11 tháng 3 năm 2020: Trong Tạp Chí Y Học Lancet Respiratory Medicine, nhà <a href="https://www.thelancet.com/journals/lanres/article/PIIS2213-2600(20)30116-8/fulltext" target="_blank">nghiên cứu</a> đề nghị giả thuyết: số lượng ACE2 cao có thể làm cho các triệu chứng COVID-19 trở nên nặng hơn. Tại sao ibuprofen liên quan? Tác giả cho rằng ibuprofen có thể khiến số lượng ACE2 tăng. Do đó, một sự quan hệ giữa ibuprofen và COVID-19 nảy sinh.</p>
              <p>Đây chỉ là suy đoán và không có bằng chứng khoa học nào cho thấy ibuprofen trực tiếp làm triệu chứng COVID-19 trở nên nặng hơn. Tuy nhiên, vào ngày 14 tháng 3, Bộ Y Tế Pháp đăng một cảnh báo về việc sử dụng ibuprofen khi sốt COVID-19. Bộ Trưởng Bộ Y Tế Pháp <a href="https://twitter.com/olivierveran/status/1238776545398923264" target="_blank">tweeted</a> lời khuyên tránh ibuprofen và NSAIDS vì có thể làm triệu chứng COVID-19 tệ hơn. Lời khuyên này lan truyền trên tin tức và tin đồn lan truyền nói ibuprofen gây bệnh nghiêm trọng đến tử vong. Vào ngày 16 tháng 3, các tác giả của giả thuyết nói rõ họ <a href="https://www.unibas.ch/en/News-Events/News/Uni-Research/Ibuprofen-and-COVID-19-Setting-the-record-straight.html" target="_blank">không có ý định tạo khuyến nghị về việc sử dụng ibuprofen hay không.</a></p>
              <p>WHO ban đầu khuyên không nên sử dụng ibuprofen cho COVID-19. Vào ngày 18 tháng 3, <a href="https://www.ema.europa.eu/en/news/ema-gives-advice-use-non-steroidal-anti-inflammatories-covid-19" target="_blank">EMA</a>, <a href="https://www.fda.gov/drugs/drug-safety-and-availability/fda-advises-patients-use-non-steroidal-anti-inflammatory-drugs-nsaids-covid-19" target="_blank">FDA</a>, <a href="https://twitter.com/WHO/status/1240409217997189128" target="_blank">WHO</a> <strong>khuyên không nên tránh ibuprofen và NSAIDS nếu cần thiết.</strong></p>
            </div>}
          />
          <Card 
            id="myth-8"
            thumbnail="/assets/img/shots/myth/myth-8.jpg" 
            title="Tự chữa bệnh bằng thuốc hydroxychloroquine & azithromycin có thể ngăn ngừa COVID-19 không?"
            content={
              <div>
                <p><span className="card-tag-false">Không</span><strong>Xin đừng</strong> tự uống thuốc! Bạn nên uống những thuốc có toa theo lời khuyên của bác sĩ để tránh những tác động phụ nguy hiểm.</p>
                <p><a href="https://www.cdc.gov/coronavirus/2019-ncov/hcp/therapeutic-options.html" target="_blank">Hydroxychloroquine và chloroquine</a> là thuốc chữa bệnh sốt rét, viêm khớp dạng thấp, lupus, vv. Azithromycin là một loại thuốc kháng sinh để chữa vi khuẩn. Hydroxychloroquine và azithromycin đều có tác dụng phụ về tim và có thể dẫn đến rối loạn nhịp tim nguy hiểm. Nhà khoa học đang nghiên cứu để xác định loại thuốc này có hiệu quả điều trị COVID-19 hay không.</p>
              </div>}
          />
          <Card 
            id="myth-9"
            thumbnail="/assets/img/shots/myth/myth-9.jpg" 
            title="Thuốc kháng sinh có hiệu quả ngăn ngừa và điều trị COVID-19 không?"
            content={
              <div>
                <p><span className="card-tag-false">Không</span>Theo <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, thuốc kháng sinh diệt vi khuẩn chứ không diệt vi-rút. Bạn không nên sử dụng thuốc kháng sinh để phòng ngừa hoặc điều trị vi-rút. Tuy nhiên, nếu bạn bị nhập viện vì COVID-19, bác sĩ có thể sẽ dùng thuốc kháng sinh vì bạn có thể đồng nhiễm vi khuẩn.</p>
              </div>}
          />
          <Card 
            id="myth-11"
            thumbnail="/assets/img/shots/myth/myth-11.jpg" 
            title="Người trẻ và khỏe mạnh có thể bị nhiễm COVID-19?"
            content={
              <div>
                <p><span className="card-tag-true">Đúng</span>Theo <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, mọi lứa tuổi nào cũng có thể bị nhiễm COVID-19.</p>
                <p>Người già và những người bị bệnh mãn tính như suyễn, tiểu đường, bệnh tim dễ bị bệnh nặng hơn. WHO khuyến cáo mọi người nên thực hiện cách thức để phòng ngừa vi-rút.</p>
              </div>}
            extra={
              <div>
                <p>Trong số 4.226 bệnh nhân đầu tiên (12 tháng 2 - 16 tháng 3) tại Hoa Kỳ, <a href="https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/testing.html" target="_blank">CDC</a> báo cáo một trong năm người bị nhập viện ở độ tuổi từ 20 đến 44. Khoảng một nửa bệnh nhân <a href="https://www.cdph.ca.gov/Programs/CID/DCDC/Pages/Immunization/ncov2019.aspx" target="_blank">COVID-19 ở California</a> đều dưới 50 tuổi. Mặc dù cơ hội tử vong của bệnh nhân trẻ thấp hơn, vẫn có nhiều người trẻ không có vấn đề sức khỏe đã tử vong. Mọi người nên cẩn thận để bảo vệ bản thân và những người khác.</p>
              </div>}
          />
          <Card 
            id="myth-12"
            thumbnail="/assets/img/shots/myth/myth-12.jpg" 
            title="Nếu bị nhiễm COVID-19, tôi có cần phải nhập viện không?"
            content={
              <div>
                <p><span className="card-tag-false">Không</span>Theo <a href="https://vietnamese.cdc.gov/coronavirus/2019-ncov/if-you-are-sick/steps-when-sick.html" target="_blank">CDC</a>, hầu hết mọi người đều bị bệnh nhẹ và có thể hồi phục tại nhà. Nếu bệnh, xin đừng rời khỏi nhà, ngoại trừ khi cần sự chăm sóc y tế. Tìm sự chăm sóc y tế nếu bạn khó thở. Hãy gọi điện trước khi đến khám bệnh. Xin xem trang “Tìm hiểu về COVID-19.”</p>
              </div>}
          />
          <Card 
            id="myth-13"
            thumbnail="/assets/img/shots/myth/myth-13.jpg" 
            title="Nếu tôi có thể nín thở hơn 10 giây mà không cảm giác khó chịu hoặc ho thì tôi không bị bệnh COVID-19, đúng không?"
            content={
              <div>
                <p><span className="card-tag-false">Không</span>Theo CDC, bạn có thể bị nhiễm COVID-19 và không có triệu chứng và vẫn có thể lây vi-rút trước khi triệu chứng xuất hiện. Theo <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, các triệu chứng phổ biến nhất của COVID-19 là ho, mệt mỏi và sốt. Một số người có thể bị bệnh nghiêm trọng hơn, chẳng hạn như viêm phổi. Bạn không thể xác nhận nhiễm COVID-19 hay không với cách thử này.</p>
              </div>}
          />
          <Card 
            id="myth-14"
            thumbnail="/assets/img/shots/myth/myth-14.jpg" 
            title="Máy sấy khô tay và đèn khử trùng UV có thể tiêu diệt được coronavirus không?"
            content={
              <div>
                <p><span className="card-tag-false">Không</span>Theo <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, máy sấy khô tay không có hiệu quả để tiêu diệt coronavirus. Không nên sử dụng đèn UV để khử trùng tay hoặc da vì bức xạ UV có thể gây kích ứng da. Để bảo vệ bản thân, bạn nên thường xuyên rửa tay bằng xà phòng và nước hoặc dung dịch sát khuẩn tay nhanh (sanitizer).</p>
              </div>}
          />
          <Card 
            id="myth-15"
            thumbnail="/assets/img/shots/myth/myth-15.jpg" 
            title="Phương pháp rửa mũi bằng nước muối thường xuyên có thể ngăn ngừa COVID-19 không?"
            content={
              <div>
                <p><span className="card-tag-false">Không</span>Theo <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, không có bằng chứng khoa học để chứng minh rằng rửa mũi bằng nước muối ngăn ngừa coronavirus. Có một ít bằng chứng rằng thường xuyên rửa mũi bằng nước muối có thể giúp phục hồi nhanh hơn khi bị cảm lạnh thông thường. Tuy nhiên, thường xuyên rửa mũi không được chứng minh có thể ngăn ngừa nhiễm trùng đường hô hấp.</p>
              </div>}
          />
          <Card 
            id="myth-16"
            thumbnail="/assets/img/shots/myth/myth-16.jpg" 
            title="Tắm nước nóng có thể ngăn ngừa COVID-19 không?"
            content={
              <div>
                <p><span className="card-tag-false">Không</span>Bạn nên tắm, nhưng đừng cố ý sử dụng nước quá nóng. Theo <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, nhiệt độ cơ thể bình thường của bạn vẫn duy trì khoảng 36,5°C đến 37°C, bất kể nhiệt độ của nước. Tắm với nước quá nóng có thể gây hại, vì nó có thể làm bạn bỏng rát.</p>
              </div>}
          />
          <Card 
            id="myth-17"
            thumbnail="/assets/img/shots/myth/myth-17.jpg" 
            title="Thời tiết lạnh và tuyết có thể ngăn ngừa COVID-19 không?"
            content={
              <div>
                <p><span className="card-tag-false">Không</span>Theo <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, thời tiết lạnh và tuyết không thể tiêu diệt coronavirus. Nhiệt độ cơ thể người bình thường duy trì khoảng 36,5°C đến 37°C, bất kể nhiệt độ bên ngoài hay thời tiết.</p>
              </div>}
          />
          <Card 
            id="myth-18"
            thumbnail="/assets/img/shots/myth/myth-18.jpg" 
            title="Ăn tỏi có thể ngăn ngừa COVID-19 không?"
            content={
              <div>
                <p><span className="card-tag-false">Không</span>Theo <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, tỏi là một loại thực phẩm lành mạnh có thể có một số đặc tính kháng khuẩn. Nhưng không có bằng chứng khoa học để chứng minh rằng ăn tỏi có thể ngăn ngừa COVID-19.</p>
              </div>}
          />
          <Card 
            id="myth-19"
            thumbnail="/assets/img/shots/myth/myth-19.jpg" 
            title="Uống rượu có thể ngăn ngừa COVID-19 không?"
            content={
              <div>
                <p><span className="card-tag-false">Không</span>Theo <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, rượu không bảo vệ bạn khỏi COVID-19 và có thể gây nguy hiểm. Uống rượu thường xuyên hoặc quá mức có thể gây tổn hại nghiêm trọng đối với sức khỏe.</p>
              </div>}
          />
          <Card 
            id="myth-20"
            thumbnail="/assets/img/shots/myth/myth-20.jpg" 
            title="Vắc-xin chống viêm phổi bảo có thể chống lại COVID-19 không?"
            content={
              <div>
                <p><span className="card-tag-false">Không</span>Theo <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, những vắc-xin phòng chống viêm phổi, như vắc-xin phế cầu khuẩn (pneumococcal) và vắc-xin Haemophilus influenzae tuýp b (Hib), không có thể chống lại coronavirus. Vi-rút này rất mới và cần vắc-xin riêng. Mặc dù các loại vắc-xin này không hiệu quả chống lại coronavirus, WHO khuyến khích bạn chích ngừa để bảo vệ sức khỏe.</p>
              </div>}
          />
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Myth;
