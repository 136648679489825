import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Tips extends Component {
  
  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <h4 className="content-nav-caption">Talaan ng Nilalaman</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-1')}>Paghuhugas ng Kamay</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-2')}>Pagtatakip sa Mukha</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-3')}>Pagsusuot ng Guwantes</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-4')}>Paglilinis ng mga Kagamitan</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-5')}>Pamamalengke</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-6')}>Paghawak ng Pagkaing Inihahatid Sa Bahay</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-7')}>Paghawak ng mga Liham at Padala</h4>
        </div>
        <div className="content-body">
          <Card 
            id="tips-1"
            thumbnail="/assets/img/shots/tips/tips-1.jpg" 
            title="Paghuhugas ng Kamay"
            content={
              <div>
                <p>Mahalaga sa pagpigil ng pagkalat ng COVID-19 ang madalas na paghuhugas ng kamay. Iwasang hawakan ang mata, ilong, o bibig bago makapaghugas ng kamay.</p>
                <hr />
                <p>
                  <div className="paragraph-header">Tamang paghuhugas ng kamay</div>
                  <ol className="content-list">
                    <li><div className="ol-index">1</div>Basain ang mga kamay sa ilalim ng tuluy-tuloy na tubig.</li>
                    <li><div className="ol-index">2</div>Magsabon at kiskisin ang mga kamay. Sabunin pati ang pagitan ng mga daliri, ang mga likod ng kamay, at sa ilalim ng mga kuko.</li>
                    <li><div className="ol-index">3</div>Kiskisin ang mga kamay ng 20 segundo (katumbas ng pagkanta ng “Maligayang Bati” ng dalawang beses).</li>
                    <li><div className="ol-index">4</div>Banlawan ang mga kamay.</li>
                    <li><div className="ol-index">5</div>Tuyuin ang mga kamay gamit ang malinis na tuwalya, o hayaang matuyo sa hangin.</li>
                  </ol>
                  <h5 className="content-source"><a href="https://www.cdc.gov/handwashing/when-how-handwashing.html" target="_blank">Mula sa: CDC</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/CSJO52qZLbs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p>
                  <div className="paragraph-header">Kailan ka dapat maghugas ng kamay?</div>
                  <ul className="content-list">
                    <li>Madalas hangga’t maaari</li>
                    <li>Pagkabalik galing sa pampublikong lugar</li>
                    <li>
                      <div>Pagkahawak ng mga gamit na malimit hawakan ng ibang tao</div>
                      <ul>
                        <li>Mga halimbawa: hawakan ng pinto, mesa, pump sa gasolinahan, mga shopping cart, kaha ng pera, pindutan ng elevator, pindutan ng PIN number, etc.</li>
                      </ul>
                    </li>
                    <li>Bago, habang, at pagkatapos maghanda ng pagkain</li>
                    <li>Bago kumain</li>
                    <li>Pagkatapos umubo, bumahin, at pagsinga</li>
                    <li>Bago maglagay at pagkatapos magtanggal ng pantakip ng mukha at ng guwantes</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">Kung walang sabon at tubig, gumamit ng hand sanitizer na gawa sa alkohol.</div>
                  <ul className="content-list">
                    <li>Hindi napapatay ng hand sanitizer ang lahat ng uri ng mikrobyo</li>
                    <li>Dapat hindi bababa sa 60% ang alkohol na sangkap nito</li>
                    <li>Ikiskis ang sanitizer sa mga kamay, sa pagitan ng mga daliri, at sa dulo ng mga daliri</li>
                    <li>Kiskisin ang mga kamay hanggang sa <strong>matuyo</strong> ang sanitizer</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-2"
            thumbnail="/assets/img/shots/tips/tips-2-tl.jpg" 
            title="Pagtatakip sa Mukha"
            content={
              <div>
                <p>
                  <div className="paragraph-header">Mga Mabubuting Kasanayan sa Pagtatakip ng Mukha</div>
                  <ul className="content-list">
                    <div className="content-list-label-positive">Gawin ito</div>
                    <li>Tiyaking kaya mong huminga habang suot ang takip sa mukha</li>
                    <li>Tiyaking natatakpan nito ang ilong at bibig (mula balingusan hanggang sa ilalim ng babà)</li>
                    <li>Gamitin ang mga tali sa gilid ng ulo para alisin ang takip sa mukha (huwag ang harap)</li>
                    <li>Araw-araw hugasan at patuyuin ang mga maskarang gawa sa tela</li>
                    <li>Hugasan ang kamay pagkatapos tanggalin ang takip sa mukha</li>
                    <li>Itago ang ipinantakip sa mukha sa isang lalagyang nakatalaga para lamang dito habang hindi pa ito nalilinis</li>
                  </ul>
                  <ul className="content-list">
                    <div className="content-list-label-negative">Iwasan ang</div>
                    <li>Paghawak sa harap ng pantakip ng mukha, dahil ang bahaging ito ang pinakamarumi</li>
                    <li>Paghila ng takip pailalim ng babà, o ang pagsuot nito sa leeg</li>
                    <li>Pagkakaroon ng maluwang na espasyo sa tabi</li>
                    <li>Pagtanggal ng takip habang may suot pang guwantes</li>
                    <li>Paulit-ulit na paggamit ng maskarang pang-ospital (surgical mask)</li>
                  </ul>
                  <h5 className="content-source">Mula sa: <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a>; <a href="https://www.nytimes.com/2020/04/08/well/live/coronavirus-face-mask-mistakes.html#click=https://t.co/GkBNJAuw1V" target="_blank">The New York Times</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/m4O-3wQQjpA?start=118" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p>
                  <div className="paragraph-header">HINDI pamalit sa ibayong pag-iingat ang pagsusuot ng takip sa mukha</div>
                  <div>Kung magsusuot ng takip sa mukha at guwantes, siguraduhing gawin pa rin ang ibang pag-iingat tulad ng:</div>
                  <ul className="content-list">
                    <li>Tamang paghuhugas ng kamay</li>
                    <li>Pagkakaroon ng 6 na talampakang agwat (isang dipa) mula sa iba</li>
                    <li>Pananatili sa bahay</li>
                  </ul>
                  <div>Bagama’t nakakatulong sa pagpigil ng pagkalat ng virus ang paggamit ng mga kagamitang pang-proteksyon (tulad ng pantakip ng mukha at guwantes), maaari pa ring magdulot ang mga kagamitang ito ng maling pag-aakala na ligtas na ang lahat. Ang mga ito ay dumadapo pa rin sa mga bagay na maaaring kontaminado. Kapag mali ang paggamit ng mga ito, maaari lalong kumalat ang kontaminasyon at manganib na mahawaan ka ng virus.</div>
                </p>
              </div>}
            extra={
              <div>
                <p>
                  <div className="paragraph-header">Bakit isinusulong ng CDC ang pagtatakip ng mukha habang nasa labas?</div>
                  <div>Ayon sa <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover.html" target="_blank">CDC</a>, maaari kang makapagkalat ng coronavirus at makahawa sa iba kahit na wala kang nararanasang sintomas. Pagiging “asymptomatic” ang tawag dito. Mapipigilan ang pagkalat ng coronavirus sa inyong komunidad sa pamamagitan ng pagsusuot ng takip sa mukha, at sa pagkakaroon ng agwat na 6 na talampakan, o isang dipa, sa ibang tao.</div>
                  <ul className="content-list">
                    <div>May mga ilang tao na HINDI DAPAT magsuot ng takip sa mukha! </div>
                    <li>Mga batang 2 taong gulang at pababa</li>
                    <li>Mga taong may kahirapan sa paghinga</li>
                    <li>Mga taong walang ulirat</li>
                    <li>Mga taong hindi kayang magtanggal ng pangtakip nang walang tulong</li>
                  </ul>
                </p>
                <p>Sa Estados Unidos, payo ng CDC na gumamit ng mga ligtas na materyales sa bahay para gumawa ng takip sa mukha, tulad ng mga lumang t-shirt o ng bandana. Tingnan ang <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">gabay ng CDC</a> para matutunan kung paano gumawa ng pantakip sa mukha gamit ang t-shirt o bandana.</p>
                <p><strong>Hindi kinakailangan ang maskarang pang-ospital (surgical mask) o N-95.</strong> Sa katunayan, hindi pinapayo ng CDC ang pagbili ng karagdagang maskarang pang-ospital o N-95 dahil sa malaking kakulangan ng mga ito sa Estados Unidos.</p>
              </div>
            }
          />
          <Card 
            id="tips-3"
            thumbnail="/assets/img/shots/tips/tips-3.jpg" 
            title="Pagsusuot ng Guwantes"
            content={
              <div>
                <p>HINDI kinakailangang magsuot ng guwantes para maging ligtas habang nasa labas. Gayunpaman, ito ang ilang mga payo at patnubay kung pipiliing magsuot ng guwantes.</p>
                <p>
                  <ul className="content-list">
                    <div className="content-list-label-positive">Gawin Ito</div>
                    <li>Maghugas ng kamay bago <strong>at agad pagkatapos</strong> magtanggal ng guwantes</li>
                    <li>Itapon at huwag na ulit gamitin ang plastik na guwantes</li>
                    <li>Para sa mga guwantes na de tela, hugasan ito gamit ang sabon at tubig bago muling gamitin.</li>
                  </ul>
                  <ul className="content-list">
                    <div className="content-list-label-negative">Iwasan Ang</div>
                    <li>Paghawak sa mukha, bibig, ilong, at mata</li>
                    <li>Paglalagay ng kamay sa bulsa</li>
                    <li>Paggamit ng telepono</li>
                  </ul>
                  <h5 className="content-source">Mula sa: <a href="https://www.kqed.org/science/1961243/coronavirus-heres-how-to-use-gloves-properly-to-help-prevent-infection" target="_blank">KQED</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/TnLCYqk20YU?start=116" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>}
          />
          <Card 
            id="tips-4"
            thumbnail="/assets/img/shots/tips/tips-4.jpg" 
            title="Paglilinis ng mga Kagamitan"
            content={
              <div>
                <p>Ayon sa pinakahuling kaalaman, hindi karaniwang kumakalat ang COVID-19 sa paghawak ng mga kontaminadong gamit. Hindi ka basta-basta mahahawaan kung sakaling makahawak ka ng kagamitang may virus. Gayunpaman, mas mataas ang posibilidad na tuluyang mahawaan kung hahawakan ang mata, ilong, o bibig habang may mga nabubuhay pang virus sa kamay. Kaya nating pigilan ang paghawa na ito sa pamamagitan ng paglilinis ng mga gamit at ng paghuhugas ng ating mga kamay.</p>
                <hr />
                <p>
                  <div className="paragraph-header">Gaano Katagal na Nabubuhay ang Coronavirus sa Iba’t-Ibang Materyal?</div>
                  <div className="content-table">
                    <div className="content-table-row">
                      <div className="content-table-column">
                        <h3 className="content-table-heading">4 na oras</h3>
                        <h4 className="content-table-caption">Tanso</h4>
                      </div>
                      <div className="content-table-column">
                        <h3 className="content-table-heading">24 na oras</h3>
                        <h4 className="content-table-caption">Karton</h4>
                      </div>
                    </div>
                    <div className="content-table-row">
                      <div className="content-table-column">
                        <h3 className="content-table-heading">2-3 na araw</h3>
                        <h4 className="content-table-caption">Plastik</h4>
                      </div>
                      <div className="content-table-column">
                        <h3 className="content-table-heading">2-3 na araw</h3>
                        <h4 className="content-table-caption">Bakal (stainless)</h4>
                      </div>
                    </div>
                  </div>
                  <div>Bagama’t nakikita pa rin ang coronavirus sa mga materyal na ito makalipas ang ilang oras hanggang ilang araw, ang bilang ng virus ay mabilis na bumababa sa pagtagal ng oras. Dahil dito, bumababa rin sa paglipas ng oras ang posibilidad ng pagkahawa mula sa mga materyal na ito.</div>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">Ano ang Maaari Kong Gamiting Panlinis ng Mga Kagamitan?</div>
                  <ul className="content-list">
                    <div>Mangyaring bisitahin ang website ng CDC para sa karagdagang payo kung paano maglinis ng <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cleaning-disinfection.html" target="_blank">iba’t-ibang klase ng mga ibabaw at kagamitan.</a> Isinusulong ng CDC ang mga sumusunod:</div>
                    <li>Mga panlinis na aprubado ng EPA <a href="https://www.cnn.com/2020/03/05/health/epa-disinfectants-coronavirus-trnd/index.html" target="_blank">(tingnan ang listahan dito)</a></li>
                    <li>
                      <div>Solusyong gawa sa bleach o klorox</div>
                      <ul>
                        <li>5 kutsara (⅓ tasa) ng bleach sa bawat galon ng tubig</li>
                        <li><strong>O di kaya ay</strong> 4 na kutsarita ng bleach kada 4 na baso ng tubig</li>
                        <li>Huwag ipanlinis ang bleach nang hindi hinahaluan ng tubig</li>
                        <li>Tiyaking HINDI pa nag-eexpire ang bleach</li>
                        <li>HUWAG paghaluin ang bleach at ammonia, o iba pang panlinis sa bahay</li>
                      </ul>
                    </li>
                    <li>Mga solusyong gawa sa alkohol, na hindi bababa sa 70% alkohol.</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-5"
            thumbnail="/assets/img/shots/tips/tips-5.jpg" 
            title="Pamamalengke"
            content={
              <div>
                <p><strong>Mas maaari kang magkasakit dahil sa ibang tao sa pamilihan, at hindi dahil ng mga bagay na binibili.</strong> Ayon sa <a href="https://www.cdc.gov/coronavirus/2019-ncov/daily-life-coping/essential-goods-services.html" target="_blank">CDC</a>, “Walang patunay na nakakahawa sa tao ang binibiling pagkain o pambalot nito. Hindi sanhi ng pagkalat ng virus sa Estados Unidos ang mga pinamili.”</p>
                <p>
                  <div className="paragraph-header">Mga payo para sa ligtas na pamimili</div>
                  <ul className="content-list">
                    <li>Huwag mamili kung mayroon kang sintomas sa paghinga, o kung nakipagsalamuha sa taong kilalang nahawaan na.</li>
                    <li>Sikaping mamili ng isang beses lang sa bawat linggo, o bawat 2 linggo</li>
                    <li>Sikaping isang tao lamang sa bahay ang lumalabas para mamili</li>
                    <li>Hangga’t maaari, iwanan na lamang sa bahay ang mga bata na may paggabay ng matanda</li>
                    <li>Gumawa ng listahan ng bibilhin para hindi magtagal sa pamilihan</li>
                    <li>Magsuot ng takip sa mukha at panatilihin ang sapat na agwat sa iba</li>
                    <li>Punasan ang mga hawakan ng shopping cart</li>
                    <li>Kung maaari, piliin ang opsyon na magbayad nang “no-touch” (o nang hindi kinakailangang humawak ng gamit o pumindot sa keypad).</li>
                    <li>Pagkaalis ng pamilihan, gumamit ng hand sanitizer.</li>
                    <li>Pagkauwi sa bahay, maghugas agad ng kamay gamit ang sabon at tubig nang mahigit 20 segundo.</li>
                    <li>Tumulong sa isang kamag-anak, kaibigan, o kapitbahay na maaaring nahihirapan sa pamamalengke ng para sa kanila.</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">Hindi kinakailangang disimpektahan ang mga pinamili</div>
                  <div>Pinakamahalaga pa rin ang maghugas ng kamay at maglinis ng mga ibabaw pagkatapos humawak ng mga bagong pinamili. Huwag gumamit ng sabon sa paghuhugas ng mga pinamiling prutas at gulay.</div>
                  <ul className="content-list">
                    <div>Gayunpaman, <a href="https://www.npr.org/sections/health-shots/2020/04/12/832269202/no-you-dont-need-to-disinfect-your-groceries-but-here-s-to-shop-safely" target="_blank">kung talagang nais hugasan ang mga pinamili</a>:</div>
                    <li>Pag-isipang itapon ang lahat ng karagdagang balot ng pinamili na hindi naman kinakailangan</li>
                    <li>Gumamit lamang ng sabon at tubig para linisin ang balot na gawa sa plastik o karton. Maiiwasan nito ang pagtagas ng kemikal sa inyong pagkain</li>
                    <li>Gamitin lamang ang mga pangdisimpektang spray at pampunas para sa mga gamit na may matigas na kaanyuan, tulad ng garapon o lata</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-6"
            thumbnail="/assets/img/shots/tips/tips-6.jpg" 
            title="Paghawak ng Pagkaing Inihahatid Sa Bahay"
            content={
              <div>
                <p>Ayon sa <a href="https://www.fda.gov/food/food-safety-during-emergencies/shopping-food-during-covid-19-pandemic-information-consumers" target="_blank">FDA</a>, “Ang COVID-19 ay naipapasa ng tao sa tao. Hindi ito tulad ng mga virus na nagdudulot ng sakit sa tiyan, na karaniwang nakukuha sa pamamagitan ng kontaminadong pagkain.”</p>
                <p>
                  <ul className="content-list">
                    <li>Paghahatid ng Pagkain: sabihin sa maghahatid na iwanan ang pagkain sa may pinto</li>
                    <li>Alisin ang pagkain mula sa lalagyang galing sa restawran at ilipat sa sariling lalagyan sa bahay</li>
                    <li>Kung gagamit ng mga kubyertos na bigay nila, hugasan muna ang mga ito sa tubig at sabon bago gamitin</li>
                    <li>Tiyaking maghugas ng kamay bago hawakan ang pagkain</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-7"
            thumbnail="/assets/img/shots/tips/tips-7.jpg" 
            title="Paghawak ng mga Liham at Padala"
            content={
              <div>
                <p>Sa isang <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973?query=featured_home" target="_blank">pag-aaral sa NEJM</a>, may nakikitang kaunting coronavirus sa karton na tumagal ng hanggang 24 na oras. Gayunpaman, hindi nito agad ibig-sabihin na nakakahawa ito. Ayon sa <a href="https://www.cdc.gov/coronavirus/2019-ncov/faq.html" target="_blank">CDC</a>, malamang na hindi kumakalat ang virus sa mga liham, produkto, o padala.</p>
                <p>
                  <ul className="content-list">
                    <li>Pagkabukas ng inyong mga liham at padala, maghugas ng kamay</li>
                    <li>Linisin ang ginamit na gunting at ang mga kontaminadong pinagpatungan ng gamit</li>
                  </ul>
                </p>
              </div>}
          />
          <h4 className="content-tips-disclaimer">Nauunawaan namin na hindi angkop ang mga sumusunod na payo para sa lahat. Iba-iba ang sitwasyon at kakayanan ng bawat pamilya at ng bawat isa. Umaasa kaming makapagbibigay ng magandang ideya ang mga sumusunod naming halimbawa. Pakibahagi sa amin kung paano ninyo naiangkop ang mga gabay/payo na ito sa inyo!</h4>
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Tips;
