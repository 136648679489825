import React, { Component } from 'react'
import classnames from 'classnames'
import {
  BrowserRouter as Router,
  Link,
  Route,
  useLocation,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom'

import Button from '../Components/Button'

class Header extends React.PureComponent {

  render() {

    const { toggleLanguageModalActive, toggleShareModalActive, path } = this.props;

    return (
      <div className="header-container">
        <div className="header grid">
          <h1 className="header-title">COVID-19<br />Fact Check</h1>
          <div className="header-description"> تعلم الحقائق والأساطير حتى تتمكن من الاستعداد بشكل أفضل وإبقاء الآخرين على اطلاع.</div>
          <div className="header-action mobile">
            <Button className="btn-secondary" label="العربية" imgURL="/assets/img/icons/arrow_down.svg" onClick={toggleLanguageModalActive} />
            <Button className="btn-primary" label="شارك" imgURL="/assets/img/icons/share.svg" onClick={toggleShareModalActive} />
          </div>
          <div className="header-caption mobile">
            <h5 className="header-caption-item">معظم المعلومات مصدرها <a href="https://www.who.int/" target="_blank">WHO</a> & <a href="https://www.cdc.gov/" target="_blank">CDC.</a> </h5>
            <h5 className="header-caption-item">آخر تحديث ٥/١٨/٢٠٢٠</h5>
          </div>
        </div>
        <div className="nav">
          <a href="/ar/" className={classnames('nav-item', {'nav-item-active': path === ''})}> تغير المعلومات الخاطئه</a>
          <a href="/ar/tips" className={classnames('nav-item', {'nav-item-active': path === 'tips'})}> نصائح مفيدة</a>
          <a href="/ar/learn" className={classnames('nav-item', {'nav-item-active': path === 'learn'})}>تعرف على كوفد-١٩</a>
          <a href="/ar/about" className={classnames('nav-item desktop', {'nav-item-active': path === 'about'})}>عنا</a>
          <a href="mailto:contact@covid19factcheck.com" target="_blank" className="nav-item desktop">اتصل بنا</a>
        </div>
      </div>
    )
  }
}

export default withRouter(Header)