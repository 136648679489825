import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Learn extends Component {

  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <h4 className="content-nav-caption">Tabla de Contenido</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-1')}>Información General</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-2')}>¿Cómo se propaga el COVID-19?</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-3')}>Síntomas</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-4')}>¿Que debo hacer si me siento enfermo?</h4>
        </div>
        <div className="content-body">
          <Card 
            id="learn-1"
            icon="/assets/img/icons/info.svg" 
            title="Información General"
            content={
              <div>
                <p>El nuevo coronavirus es un virus dentro de una familia de viruses que causan enfermedades respiratorias como el catarro y la gripe.</p>
                <p>En el 2019, un coronavirus nuevo fue identificado. El SARS-CoV-2 es el nombre scientifico del virus que causa la enfermedad llamada COVID-19 o “Coronavirus Disease 2019”</p>
              </div>}
          />
          <Card 
            id="learn-2"
            icon="/assets/img/icons/spread.svg" 
            title="¿Cómo se propaga el COVID-19?"
            content={
              <div>
                <p><div className="paragraph-header">De Persona a Persona</div>Las partículas microscópicas son la manera principal por la cual el virus se propaga. Si está a menos de seis pies (o 1.8 metros) de una persona infectada que está tosiendo, estornudando, o hablando, estas partículas pueden caer dentro de su boca, nariz u ojos.</p>
                <p><div className="paragraph-header">Superficies Contaminadas</div>Podría infectarse al tocar una superficie contaminada con el virus y luego tocar su boca, nariz u ojos. Superficies que se tocan frecuentemente incluyen las perillas de puertas, interruptores de luz eléctrica, celulares, mesas, teclados, inodoros, llaves de lavamanos.</p>
              </div>}
          />
          <Card 
            id="learn-3"
            icon="/assets/img/icons/symptoms.svg" 
            title="Síntomas"
            content={
              <div>
                <div className="content-symptom-container">
                  <ul className="content-symptom-category">
                    <div className="content-symptom-title">Comunes</div>
                    <li className="content-symptom-item">Fiebre (100.4°F, 38°C)</li>
                    <li className="content-symptom-item">Tos seca</li>
                    <li className="content-symptom-item">Falta de aire al respirar</li>
                  </ul>
                  <ul className="content-symptom-category">
                    <div className="content-symptom-title">Menos común</div>
                    <li className="content-symptom-item">Cansancio</li>
                    <li className="content-symptom-item">Dolor muscular</li>
                    <li className="content-symptom-item">Dolor de garganta</li>
                    <li className="content-symptom-item">Goteo nasal</li>
                    <li className="content-symptom-item">Náusea</li>
                    <li className="content-symptom-item">Diarrea</li>
                    <li className="content-symptom-item">La pérdida de gusto u olfato</li>
                  </ul>
                </div>
                <div className="content-symptom-caption">Muchas personas pueden estar infectadas sin tener síntoma alguno.</div>
              </div>}
          />
          <Card 
            id="learn-4"
            icon="/assets/img/icons/sick.svg" 
            title="¿Que debo hacer si me siento enfermo?"
            content={
              <div>
                <p>Según el CDC, la mayoría de las personas tienen síntomas leves y pueden recuperarse en su hogar. No salga de su casa excepto para recibir atención médica. Busque atención médica si tiene dificultad respirando, pero primero llame a su médico primario o a su clínica.</p>
                <hr />
                <p>
                  <ul className="content-list">
                    <div>Llame al 9-1-1 si tiene una emergencia médica incluyendo:</div>
                    <li>Dificultad respirando</li>
                    <li>Dolor de pecho</li>
                    <li>Pérdida de conocimiento o dificultad despertándose</li>
                    <li>Labios o cara color morado</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <ul className="content-list">
                    <div>Para obtener más información o para conseguir ayuda médica o recursos comunitarios, <strong>llame al departamento de salud pública de su condado.</strong> Haga clic aquí para ver los departamentos de salud pública de los <a href="https://covid19.ca.gov/state-local-resources/#top" target="_blank">condados de California</a></div>
                    <li><a href="https://www.sccgov.org/sites/phd/DiseaseInformation/novel-coronavirus/Pages/home.aspx" target="_blank">Condado de Santa Clara:</a> Marque 211 para obtener información sobre el COVID-19 y aprender sobre recursos comunitarios locales como alimento, vivienda, asesoramiento, asistencia laboral, cuidado infantil, servicios para personas mayores y más (disponible las 24 horas, 7 días a la semana)</li>
                    <li><a href="https://sf.gov/topics/coronavirus-covid-19" target="_blank">San Francisco:</a> Marque 311 (dentro de San Francisco) o 415-701-2311 para preguntas, ayuda para conseguir atención médica y recursos comunitarios</li>
                    <li><a href="http://publichealth.lacounty.gov/media/Coronavirus/" target="_blank">Los Angeles:</a> Marque 211 para preguntas, o para conseguir atención médica y recursos comunitarios (disponible las 24 horas, 7 días de la semana)</li>
                    <li><a href="https://covid19info.ocgov.com/" target="_blank">Orange County:</a> Marque 211 para preguntas, o para conseguir ayuda médica y recursos comunitarios (disponible las 24 horas, 7 días de la semana)</li>
                    <li><a href="https://www.sandiegocounty.gov/coronavirus.html" target="_blank">San Diego:</a> Marque 211 para preguntas, información sobre recursos comunitarios, o si usted no tiene seguro médico (disponible las 24 horas, 7 días de la semana)</li>
                    <li><a href="https://www.co.fresno.ca.us/departments/public-health/covid-19" target="_blank">Fresno:</a> Marque 559-4636 (8am-5pm)</li>
                    <li><a href="http://www.acphd.org/2019-ncov.aspx" target="_blank">Alameda:</a> Marque 510-268-2101</li>
                    <li><a href="https://www.coronavirus.cchealth.org/" target="_blank">Contra Costa:</a> Marque 844-729-8410 (8am a 5pm)</li>
                  </ul>
                </p>
                <hr />
                <p>Para obtener consejos sobre cómo minimizar la contaminación en su hogar <a href="https://espanol.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/disinfecting-your-home.html" target="_blank">cuando alguien está enfermo</a> visite esta página de web del CDC.</p>
              </div>}
          />
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Learn;
