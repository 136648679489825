import React, { Component } from 'react'
import classnames from 'classnames'
import {
  BrowserRouter as Router,
  Link,
  Route,
  useLocation,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom'

import Button from '../Components/Button'

class Header extends React.PureComponent {

  render() {

    const { toggleLanguageModalActive, toggleShareModalActive, path } = this.props;

    return (
      <div className="header-container">
        <div className="header grid">
          <h1 className="header-title">COVID-19<br />Fact Check</h1>
          <div className="header-description">Изучите факты и мифы, чтобы вы могли лучше подготовиться и информировать других.</div>
          <div className="header-action mobile">
            <Button className="btn-secondary" label="Русский" imgURL="/assets/img/icons/arrow_down.svg" onClick={toggleLanguageModalActive} />
            <Button className="btn-primary" label="Поделиться" imgURL="/assets/img/icons/share.svg" onClick={toggleShareModalActive} />
          </div>
          <div className="header-caption mobile">
            <h5 className="header-caption-item">Большая часть информации поступает из <a href="https://www.who.int/" target="_blank">ВОЗ</a> и <a href="https://www.cdc.gov/" target="_blank">CDC.</a> </h5>
            <h5 className="header-caption-item">Последнее обновление 5/24/20.</h5>
          </div>
        </div>
        <div className="nav">
          <a href="/ru/" className={classnames('nav-item', {'nav-item-active': path === ''})}>Мифы и факты</a>
          <a href="/ru/tips" className={classnames('nav-item', {'nav-item-active': path === 'tips'})}>Полезные советы</a>
          <a href="/ru/learn" className={classnames('nav-item', {'nav-item-active': path === 'learn'})}>Узнайте о COVID-19</a>
          <a href="/ru/about" className={classnames('nav-item desktop', {'nav-item-active': path === 'about'})}>О нас</a>
          <a href="mailto:contact@covid19factcheck.com" target="_blank" className="nav-item desktop">Связаться с нами</a>
        </div>
      </div>
    )
  }
}

export default withRouter(Header)