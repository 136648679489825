import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Tips extends Component {
  
  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <h4 className="content-nav-caption">Table of Contents</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-1')}>Hand Washing</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-2')}>Face Covering</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-3')}>Wearing Gloves</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-4')}>Cleaning Surfaces</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-5')}>Doing Groceries</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-6')}>Handling Delivered Food</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-7')}>Handling Packages & Mail</h4>
        </div>
        <div className="content-body">
          <Card 
            id="tips-1"
            thumbnail="/assets/img/shots/tips/tips-1.jpg" 
            title="Hand Washing"
            content={
              <div>
                <p>Washing your hands frequently is key to preventing the spread of COVID-19. Avoid touching your eyes, nose, or mouth before washing your hands.</p>
                <hr />
                <p>
                  <div className="paragraph-header">Proper Hand Washing Tips</div>
                  <ol className="content-list">
                    <li><div className="ol-index">1</div>Wet your hands with running water</li>
                    <li><div className="ol-index">2</div>Add soap and rub your hands together, including spaces in between your fingers, on the back of your hands, and under your nails</li>
                    <li><div className="ol-index">3</div>Scrub your hands for 20 seconds (singing “Happy Birthday” twice)</li>
                    <li><div className="ol-index">4</div>Rinse your hands</li>
                    <li><div className="ol-index">5</div>Dry your hands with a towel or let them air dry</li>
                  </ol>
                  <h5 className="content-source"><a href="https://www.cdc.gov/handwashing/when-how-handwashing.html" target="_blank">Source: CDC</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/CSJO52qZLbs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p>
                  <div className="paragraph-header">When should you wash your hands?</div>
                  <ul className="content-list">
                    <li>As often as possible</li>
                    <li>After being out in public places</li>
                    <li>
                      <div>After coming in contact with commonly touched surfaces</div>
                      <ul>
                        <li>Examples: door handles, tables, gas pumps, shopping carts, cash register, pin pads, elevator buttons, etc.</li>
                      </ul>
                    </li>
                    <li>Before, during, and after preparing food</li>
                    <li>Before you eat</li>
                    <li>After coughing, sneezing, and blowing your nose</li>
                    <li>Before putting on and after taking off masks and gloves</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">If soap and water are not available, use an alcohol-based hand sanitizer</div>
                  <ul className="content-list">
                    <li>Hand sanitizers do not kill all types of germs</li>
                    <li>It should contain at least 60% alcohol</li>
                    <li>Rub the gel along all surfaces of your hands, between your fingers, and your fingertips</li>
                    <li>Rub your hands together until they are <strong>dry</strong></li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-2"
            thumbnail="/assets/img/shots/tips/tips-2.jpg" 
            title="Face Covering"
            content={
              <div>
                <p>
                  <div className="paragraph-header">Good Practices for Using Face Coverings</div>
                  <ul className="content-list">
                    <div className="content-list-label-positive">Do</div>
                    <li>Make sure you can breath through it</li>
                    <li>Make sure it covers your nose and mouth (from nose bridge to below the chin)</li>
                    <li>Use side straps when removing the face covering</li>
                    <li>Wash and dry cloth masks daily</li>
                    <li>Wash your hands after you remove the face covering</li>
                    <li>Keep them in a designated bag or bin until they are cleaned</li>
                  </ul>
                  <ul className="content-list">
                    <div className="content-list-label-negative">Avoid</div>
                    <li>Touching the front of your face covering, where it is the dirtiest</li>
                    <li>Slipping the mask under your chin or wrapping it around your neck</li>
                    <li>Having loose gaps on the sides</li>
                    <li>Removing face covering with gloves on</li>
                    <li>Reusing surgical masks</li>
                  </ul>
                  <h5 className="content-source">Sources: <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a>; <a href="https://www.nytimes.com/2020/04/08/well/live/coronavirus-face-mask-mistakes.html#click=https://t.co/GkBNJAuw1V" target="_blank">The New York Times</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/m4O-3wQQjpA?start=118" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p>
                  <div className="paragraph-header">Face coverings are <span className="red">NOT</span> a substitute for other preventative measures</div>
                  <div>If you decide to wear face coverings and/or gloves, make sure you continue to practice:</div>
                  <ul className="content-list">
                    <li>Proper handwashing</li>
                    <li>Staying 6 feet away from others</li>
                    <li>Shelter-in-Place</li>
                  </ul>
                  <div>Although protective gears (face mask, gloves, etc) can help limit transmission, it can also create a false sense of security. Protective gears come in direct contact with potentially contaminated surfaces. If used incorrectly, can cause cross-contamination and expose you to the virus.</div>
                </p>
              </div>}
            extra={
              <div>
                <p>
                  <div className="paragraph-header">Why is the CDC recommending the use of face coverings in the public?</div>
                  <div>According to the <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover.html" target="_blank">CDC</a>, you can spread coronavirus without having symptoms (also known as being “asymptomatic”). By wearing face coverings and by maintaining 6ft from other people, you can help stop the spread of coronavirus in the community.</div>
                  <ul className="content-list">
                    <div>Some individuals should NOT use face coverings!</div>
                    <li>Children under age of 2-years</li>
                    <li>Individuals with difficulties breathing</li>
                    <li>Individuals who are unconscious</li>
                    <li>Individuals who are unable to remove the face covering without assistance</li>
                  </ul>
                </p>
                <p>In the US, CDC recommends using safe household material such as old t-shirts or bandanas. Please see the CDC <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">step-by-step guide</a> on how to create face covering from t-shirts and bandanas.</p>
                <p><strong>You do not need a surgical mask or N-95 respirator.</strong> In fact, CDC does not recommend purchasing additional surgical masks and N-95 respirator due to the severe shortage in the US.</p>
              </div>
            }
          />
          <Card 
            id="tips-3"
            thumbnail="/assets/img/shots/tips/tips-3.jpg" 
            title="Wearing Gloves"
            content={
              <div>
                <p>Gloves are NOT necessary for protection out in the public. However, if you do choose to wear gloves, here are some tips.</p>
                <p>
                  <ul className="content-list">
                    <div className="content-list-label-positive">Do</div>
                    <li>Wash your hands before <strong>and immediately</strong> after removing the gloves</li>
                    <li>Dispose and do not reuse plastic gloves</li>
                    <li>For fabric gloves, wash them with soap and water before reusing them</li>
                  </ul>
                  <ul className="content-list">
                    <div className="content-list-label-negative">Avoid</div>
                    <li>Touching your face, mouth, nose, and eyes</li>
                    <li>Placing your hands in your pockets</li>
                    <li>Using your phone</li>
                  </ul>
                  <h5 className="content-source">Sources: <a href="https://www.kqed.org/science/1961243/coronavirus-heres-how-to-use-gloves-properly-to-help-prevent-infection" target="_blank">KQED</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/TnLCYqk20YU?start=116" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>}
          />
          <Card 
            id="tips-4"
            thumbnail="/assets/img/shots/tips/tips-4.jpg" 
            title="Cleaning Surfaces"
            content={
              <div>
                <p>Based on current data, touching contaminated surfaces is not the main way COVID-19 spreads. You cannot get infected by simply touching items contaminated with the virus. However, your risk of infection is increased if there are viable viruses on your hands, and then you touch your eyes, nose, or mouth. Cleaning surfaces and washing your hands after touching surfaces can help reduce that risk of transmission. </p>
                <hr />
                <p>
                  <div className="paragraph-header">How Long Does the Coronavirus Remain Stable on Different Surfaces?</div>
                  <div className="content-table">
                    <div className="content-table-row">
                      <div className="content-table-column">
                        <h3 className="content-table-heading">4 hours</h3>
                        <h4 className="content-table-caption">Copper</h4>
                      </div>
                      <div className="content-table-column">
                        <h3 className="content-table-heading">24 hours</h3>
                        <h4 className="content-table-caption">Cardboard</h4>
                      </div>
                    </div>
                    <div className="content-table-row">
                      <div className="content-table-column">
                        <h3 className="content-table-heading">2-3 days</h3>
                        <h4 className="content-table-caption">Plastic</h4>
                      </div>
                      <div className="content-table-column">
                        <h3 className="content-table-heading">2-3 days</h3>
                        <h4 className="content-table-caption">Stainless Steel</h4>
                      </div>
                    </div>
                  </div>
                  <div>Although coronavirus was detected up to hours or days, the amount of virus on these surfaces rapidly decreases over time and thus the risk of infection is less likely as time passes. (Based on a study published in <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973" target="_blank">The New England Journal of Medicine</a> (3/17/2020)</div>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">What Can I Use to Clean Surfaces?</div>
                  <ul className="content-list">
                    <div>Please visit CDC website for more recommendations on how to <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cleaning-disinfection.html" target="_blank">clean specific types of surfaces.</a> The CDC recommends using the following:</div>
                    <li>EPA-Approved Disinfectants <a href="https://www.cnn.com/2020/03/05/health/epa-disinfectants-coronavirus-trnd/index.html" target="_blank">(see list here)</a></li>
                    <li>
                      <div>Diluted bleach solution</div>
                      <ul>
                        <li>5 tablespoons (⅓ cup) bleach for every gallon of water</li>
                        <li><strong>OR</strong> 4 teaspoons bleach per quart of water</li>
                        <li>Do not clean with bleach directly from the bottle without diluting</li>
                        <li>Make sure the bleach has NOT expired</li>
                        <li>Do NOT mix bleach with ammonia or other cleanser at home</li>
                      </ul>
                    </li>
                    <li>Alcohol solutions containing at least 70% alcohol</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-5"
            thumbnail="/assets/img/shots/tips/tips-5.jpg" 
            title="Doing Groceries"
            content={
              <div>
                <p><strong>You are more likely to get sick from the people at the grocery store and not the grocery items.</strong> According to the <a href="https://www.cdc.gov/coronavirus/2019-ncov/daily-life-coping/essential-goods-services.html" target="_blank">CDC</a>, “there is no evidence that food or food packaging play a significant role in spreading the virus in the United States.”</p>
                <p>
                  <div className="paragraph-header">Suggestions for Grocery Shopping</div>
                  <ul className="content-list">
                    <li>Do not shop if you are having any respiratory symptoms or have been exposed</li>
                    <li>Try to grocery shop only once a week or once every 2 weeks</li>
                    <li>Try to have only one person from the family go purchase groceries</li>
                    <li>If possible, have children stay at home with another adult.</li>
                    <li>Write down a grocery list to help reduce your shopping time</li>
                    <li>Wear a face covering and maintain distance from others</li>
                    <li>Wipe down the handles of the shopping cart</li>
                    <li>If possible, choose no-touch payment</li>
                    <li>After leaving the store, use hand sanitizer.</li>
                    <li>When you get home, wash your hands with soap and water for at least 20 seconds</li>
                    <li>Offer to help a family member, friend, or neighbor who may not be able to purchase grocery on their own</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">You do not need to disinfect your groceries</div>
                  <div>It is most important that you wash your hands and surfaces after handling grocery. Avoid soap when rinsing fresh fruits and vegetables.</div>
                  <ul className="content-list">
                    <div>However, <a href="https://www.npr.org/sections/health-shots/2020/04/12/832269202/no-you-dont-need-to-disinfect-your-groceries-but-here-s-to-shop-safely" target="_blank">if you really want to clean your groceries</a>:</div>
                    <li>Consider disposing all unnecessary external packagings</li>
                    <li>Use soap and water for plastic or cardboard packaging. This prevents disinfectant chemicals from leaking into your food</li>
                    <li>Only use disinfecting sprays and wipes on hard surfaces like glass jars or cans</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-6"
            thumbnail="/assets/img/shots/tips/tips-6.jpg" 
            title="Handling Delivered Food"
            content={
              <div>
                <p>According to <a href="https://www.fda.gov/food/food-safety-during-emergencies/shopping-food-during-covid-19-pandemic-information-consumers" target="_blank">FDA</a>, “COVID-19 is spread from person-to-person, unlike foodborne gastrointestinal or GI viruses that often make people ill through contaminated food.”</p>
                <p>
                  <ul className="content-list">
                    <li>Delivery: ask to have items left at your door</li>
                    <li>Transfer food items from restaurant containers to home containers/utensils</li>
                    <li>If you plan on using their utensils, wash them with water and soap before using them.</li>
                    <li>Make sure you wash your hands before touching the food</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-7"
            thumbnail="/assets/img/shots/tips/tips-7.jpg" 
            title="Handling Packages & Mail"
            content={
              <div>
                <p>In a NEJM <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973?query=featured_home" target="_blank">research study</a>, a small amount of coronavirus was detectable on cardboard for up to 24 hours. However, the presence of the virus does not automatically mean that it can be infectious. According to the <a href="https://www.cdc.gov/coronavirus/2019-ncov/faq.html" target="_blank">CDC</a>, it is unlikely to spread through mail, products or packaging.</p>
                <p>
                  <ul className="content-list">
                    <li>After opening your package and mail, wash your hands</li>
                    <li>Clean scissors and other contaminated surfaces</li>
                  </ul>
                </p>
              </div>}
          />
          <h4 className="content-tips-disclaimer">We recognize that the following tips may not be applicable in the same ways for everyone. Each individual’s and each family’s situation and resources differ. We hope the following examples can give you some helpful ideas. Please share with us how you made these tips work for you!</h4>
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Tips;
