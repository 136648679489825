import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Tips extends Component {
  
  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <h4 className="content-nav-caption">جدول المحتويات</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-1')}>نصائح لغسل اليدين</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-2')}>نصائح لتغطية الوجه</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-3')}>نصائح القفازات</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-4')}>نصائح التنظيف</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-5')}>القيام بقالة</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-6')}>التعامل مع الطعام المقدم</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-7')}>التعامل مع علبة والبريد</h4>
        </div>
        <div className="content-body">
          <Card 
            id="tips-1"
            thumbnail="/assets/img/shots/tips/tips-1.jpg" 
            title="نصائح لغسل اليدين"
            content={
              <div>
                <p>غسل اليدين بشكل متكرر هو المفتاح لمنع انتشار كوفد-١٩. تجنب لمس عينيك أو أنفك أو فمك قبل غسل يديك</p>
                <hr />
                <p>
                  <div className="paragraph-header">نصائح غسل اليدين المناسبة</div>
                  <ol className="content-list">
                    <li><div className="ol-index">١</div>بلل يديك بالماء الجاري</li>
                    <li><div className="ol-index">٢</div>أضيفي الصابون وافركي يديكِ معًا ، بما في ذلك المسافات بين أصابعك ، وعلى ظهر يديك وتحت أظافرك</li>
                    <li><div className="ol-index">٣</div>افركي يديك لمدة ٢٠ ثانية (الغناء "عيد ميلاد سعيد " مرتين)</li>
                    <li><div className="ol-index">٤</div>اشطف يديك</li>
                    <li><div className="ol-index">٥</div>جفف يديك بمنشفة أو اتركها تجف في الهواء</li>
                  </ol>
                  <h5 className="content-source"><a href="https://translate.google.com/translate?hl=en&prev=_t&sl=en&tl=ar&u=https://www.cdc.gov/handwashing/when-how-handwashing.html" target="_blank">المصدر: مركز السيطرة على الأمراض</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/XOsGJIcWV5o" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p>
                  <div className="paragraph-header">متى يجب أن تغسل يديك؟</div>
                  <ul className="content-list">
                    <li>كلما أمكن ذلك</li>
                    <li>بعد التواجد في الأماكن العامة</li>
                    <li>
                      <div>بعد ملامسة الأسطح التي يلمسها عادة</div>
                      <ul>
                        <li>أمثلة: مقابض الأبواب ، والطاولات ، ومضخات الغاز ، وعربات التسوق ، وتسجيل النقد ، قلم الكترونيك ، وأزرار المصاعد ، إلخ.</li>
                      </ul>
                    </li>
                    <li>قبل وأثناء وبعد إعداد الطعام</li>
                    <li>قبل الأكل</li>
                    <li>بعد السعال والعطس تنضيف الأنف</li>
                    <li>قبل ارتداء الأقنعة والقفازات وبعد خلعها </li>
                  </ul>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header"> إذا لم يتوفر الماء والصابون ، استخدم معقم اليدين الكحولي</div>
                  <ul className="content-list">
                    <li>مطهرات اليد لا تقتل جميع أنواع الجراثيم</li>
                    <li>يجب أن يحتوي على ٦٠% كحول على الأقل</li>
                    <li>افركي الجل على جميع أسطح يديك ، بين أصابعك ، وأطراف أصابعك </li>
                    <li>افركي يديك معًا حتى تجف</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-2"
            thumbnail="/assets/img/shots/tips/tips-2-ar.jpg" 
            title="نصائح لتغطية الوجه"
            content={
              <div>
                <p>
                  <div className="paragraph-header">الممارسات الجيدة لاستخدام أغطية الوجه</div>
                  <ul className="content-list">
                    <div className="content-list-label-positive">فعل</div>
                    <li> تأكد من أنك تستطيع التنفس من خلاله</li>
                    <li>تأكد من أنه يغطي أنفك وفمك (من جسر الأنف إلى أسفل الذقن)</li>
                    <li> استخدم أحزمة جانبية عند إزالة غطاء الوجه </li>
                    <li>غسل وتجفيف أقنعة القماش يوميا</li>
                    <li> اغسل يديك بعد إزالة غطاء الوجه</li>
                    <li>احتفظ بها في كيس أو صندوق مخصص حتى يتم تنظيفها </li>
                  </ul>
                  <ul className="content-list">
                    <div className="content-list-label-negative">تجنب</div>
                    <li>لمس الجزء الأمامي من القناع وجهك ، حيث يكون أقذر</li>
                    <li>ينزلق القناع تحت ذقنك أو يلفه حول رقبتك</li>
                    <li> وجود فجوات فضفاضة على الجانبين </li>
                    <li> إزالة غطاء الوجه بالقفازات</li>
                    <li>إعادة استخدام الأقنعة الجراحية</li>
                  </ul>
                  <h5 className="content-source">المصادر: <a href="https://translate.google.com/translate?hl=en&prev=_t&sl=en&tl=ar&u=https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">مركز السيطرة على الأمراض</a>. <a href="https://translate.google.com/translate?hl=en&prev=_t&sl=en&tl=ar&u=https://www.nytimes.com/2020/04/08/well/live/coronavirus-face-mask-mistakes.html%2523click%3Dhttps://t.co/GkBNJAuw1V" target="_blank"> اوقات نيويورك</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/m4O-3wQQjpA?start=118" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p>
                  <div className="paragraph-header">أغطية الوجه ليست بديلاً عن التدابير الوقائية الأخرى</div>
                  <div>إذا قررت ارتداء أغطية الوجه و / أو القفازات ، فتأكد من الاستمرار في الممارسة:</div>
                  <ul className="content-list">
                    <li>غسل اليدين بالشكل الصحيح</li>
                    <li> الابتعاد ٦ أقدام عن الآخرين</li>
                    <li>البقاء في المكان</li>
                  </ul>
                  <div>على الرغم من أن القناع الواقية (قناع الوجه والقفازات وما إلى ذلك) يمكن أن تساعد في الحد من انتقال العدوى ، إلا أنها يمكن أن تخلق شعورًا زائفًا بالأمان. تتلامس القناع الواقية بشكل مباشر مع الأسطح الملوثة. إذا تم استخدامه بشكل غير صحيح ، يمكن أن يسبب التلوث المتبادل ويعرضك للفيروس.</div>
                </p>
              </div>}
            extra={
              <div>
                <p>
                  <div className="paragraph-header">لماذا يوصي مركز السيطرة على الأمراض باستخدام أغطية الوجه في الأماكن العامة؟</div>
                  <div>وفقًا لمراكز مكافحة الأمراض والوقاية منها <a href="https://translate.google.com/translate?hl=en&prev=_t&sl=en&tl=ar&u=https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">(مركز السيطرة على الأمراض)</a>، يمكنك نشر فيروسات تاجية بدون ظهور أعراض (تُعرف أيضًا باسم "بدون أعراض " ). من خلال ارتداء أغطية الوجه والحفاظ على 6 أقدام من الأشخاص الآخرين ، يمكنك المساعدة في وقف انتشار فيروس التاجي في المجتمع.</div>
                  <ul className="content-list">
                    <div>يجب على بعض الأفراد عدم استخدام أغطية الوجه!</div>
                    <li>الأطفال دون سن عامين</li>
                    <li>الأفراد الذين يعانون من صعوبات في التنفس</li>
                    <li>الأفراد الذين فقدوا وعيهم</li>
                    <li>الأفراد غير القادرين على إزالة غطاء الوجه دون مساعدة</li>
                  </ul>
                </p>
                <p>في الولايات المتحدة ، يوصي مركز السيطرة على الأمراض باستخدام مواد منزلية آمنة مثل القمصان القديمة أو عصابات. يرجى الاطلاع على دليل مركز السيطرة على الأمراض <a href="https://translate.google.com/translate?hl=en&prev=_t&sl=en&tl=ar&u=https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">خطوة بخطوة</a> حول كيفية إنشاء غطاء الوجه من القمصان والعصابات.</p>
                <p>لا تحتاج إلى قناع جراحي أو جهاز تنفس ن-٩٥. في الواقع ، لا توصي مراكز السيطرة على الأمراض مركز السيطرة على الأمراض بشراء أقنعة جراحية إضافية وجهاز تنفس ن-٩٥ بسبب النقص الحاد في الولايات المتحدة.</p>
              </div>
            }
          />
          <Card 
            id="tips-3"
            thumbnail="/assets/img/shots/tips/tips-3.jpg" 
            title="نصائح القفازات"
            content={
              <div>
                <p>القفازات ليست ضرورية للحماية في الأماكن العامة. ومع ذلك ، إذا اخترت ارتداء القفازات ، فإليك بعض النصائح.</p>
                <p>
                  <ul className="content-list">
                    <div className="content-list-label-positive">فعل</div>
                    <li> اغسل يديك قبل وعلى الفور بعد إزالة القفازات</li>
                    <li>تخلص من القفازات البلاستيكية ولا تعيد استخدامها </li>
                    <li>بالنسبة للقفازات المصنوعة من القماش ، اغسلها بالماء والصابون قبل إعادة استخدامها </li>
                  </ul>
                  <ul className="content-list">
                    <div className="content-list-label-negative">تجنب</div>
                    <li> لمس وجهك وفمك وأنفك وعينيك</li>
                    <li>ضع يديك في جيوبك </li>
                    <li> استخدام هاتفك</li>
                  </ul>
                  <h5 className="content-source">المصدر: <a href="https://translate.google.com/translate?hl=en&prev=_t&sl=en&tl=ar&u=https://www.kqed.org/science/1961243/coronavirus-heres-how-to-use-gloves-properly-to-help-prevent-infection" target="_blank">KQED</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/TnLCYqk20YU?start=116" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>}
          />
          <Card 
            id="tips-4"
            thumbnail="/assets/img/shots/tips/tips-4.jpg" 
            title="نصائح التنظيف"
            content={
              <div>
                <p>بناءً على البيانات الحالية ، فإن لمس الأسطح الملوثة ليس الطريقة الرئيسية لانتشار كوفد-١٩. لا يمكنك الإصابة بالعدوى بمجرد لمس العناصر الملوثة بالفيروس. ومع ذلك ، يزداد خطر الإصابة بالعدوى إذا كانت هناك فيروسات قابلة للحياة على يديك ، ثم تلمس عينيك أو أنفك أو فمك. يمكن أن يساعد تنظيف الأسطح وغسل يديك بعد لمس الأسطح على تقليل خطر انتقال العدوى</p>
                <hr />
                <p>
                  <div className="paragraph-header">إلى متى يبقى الفيروس التاجي مستقرًا على الأسطح المختلفة؟</div>
                  <div className="content-table">
                    <div className="content-table-row">
                      <div className="content-table-column">
                        <h3 className="content-table-heading">٤ ساعات</h3>
                        <h4 className="content-table-caption">نحاس</h4>
                      </div>
                      <div className="content-table-column">
                        <h3 className="content-table-heading">٢٤ ساعة = يوم واحد</h3>
                        <h4 className="content-table-caption">ورق مقوى</h4>
                      </div>
                    </div>
                    <div className="content-table-row">
                      <div className="content-table-column">
                        <h3 className="content-table-heading">٢-٣ أيام</h3>
                        <h4 className="content-table-caption">بلاستيك</h4>
                      </div>
                      <div className="content-table-column">
                        <h3 className="content-table-heading">٢-٣ أيام</h3>
                        <h4 className="content-table-caption">ستانلس ستيل</h4>
                      </div>
                    </div>
                  </div>
                  <div>على الرغم من اكتشاف الفيروس التاجي لمدة تصل إلى ساعات أو أيام ، إلا أن كمية الفيروس على هذه الأسطح تتناقص بسرعة بمرور الوقت ، وبالتالي تقل احتمالية الإصابة بالعدوى مع مرور الوقت. <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973" target="_blank">( بناءً على دراسة نشرت في مجلة نيو إنجلاند الطبية ٣/١٧/٢٠٢٠ )</a></div>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">ا الذي يمكنني استخدامه لتنظيف الأسطح؟ </div>
                  <ul className="content-list">
                    <div>يرجى زيارة موقع CDC للحصول على مزيد من التوصيات حول كيفية <a href="https://translate.google.com/translate?hl=en&prev=_t&sl=en&tl=ar&u=https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cleaning-disinfection.html" target="_blank">تنظيف أنواع معينة من الأسطح.</a> و توصي CDC باستخدام ما يلي:</div>
                    <li> المطهرات المعتمدة من وكالة حماية البيئة <a href="https://translate.google.com/translate?hl=en&prev=_t&sl=en&tl=ar&u=https://www.cnn.com/2020/03/05/health/epa-disinfectants-coronavirus-trnd/index.html" target="_blank">(انظر القائمة هنا)</a></li>
                    <li>
                      <div>محلول مبيض مخفف</div>
                      <ul>
                        <li> ملاعق كبيرة (١ /٣ كوب) مبيض لكل جالون من الماء </li>
                        <li> أو ٤ ملاعق صغيرة مبيض لكل لتر ماء</li>
                        <li>لا تنظف بالتبييض مباشرة من الزجاجة دون تمييع</li>
                        <li> تأكد من عدم انتهاء صلاحية المبيض</li>
                        <li> لا تخلط التبييض مع الأمونيا أو غيرها من المطهر في المنزل</li>
                      </ul>
                    </li>
                    <li> محاليل كحولية تحتوي على ٧٠٪ كحول على الأقل</li>
                  </ul>
                </p>
              </div>}
          />
          <div className="content-additional-disclaimer">
            <h2 className="content-additional-disclaimer-title">حتياطات إضافية</h2>
            <h4 className="content-additional-disclaimer-caption">على الرغم من أن النصائح التالية ليست توصيات مباشرة من مركز السيطرة على الأمراض ، يمكنك التفكير في ممارستها للحصول على احتياطات إضافية. يمكن أن تساعد في الحد من انتقال الفيروس.</h4>
          </div>
          <Card 
            id="tips-5"
            thumbnail="/assets/img/shots/tips/tips-5.jpg" 
            title="القيام بقالة"
            content={
              <div>
                <p>
                  <div className="paragraph-header">اقتراحات لتسوق البقالة</div>
                  <ul className="content-list">
                    <li>لا تتسوق إذا كان لديك أي أعراض تنفسية أو تعرضت</li>
                    <li>حاول شراء البقالة مرة واحدة فقط في الأسبوع أو مرة كل أسبوعين</li>
                    <li>حاول أن يكون لديك شخص واحد فقط من العائلة يذهب لشراء البقالة</li>
                    <li>إذا أمكن ، اجعل الأطفال يبقون في المنزل مع شخص بالغ آخر. قد يكون من الصعب تتبع العناصر التي تلامسها في المتاجر.</li>
                    <li>اكتب قائمة بقالة للمساعدة في تقليل وقت التسوق</li>
                    <li>امسح مقابض عربة التسوق</li>
                    <li>المس عنصر فقط إذا كنت متأكدًا من شرائه</li>
                    <li>اعرض مساعدة أحد أفراد الأسرة أو صديق أو جار قد لا يتمكن من شراء البقالة بمفرده</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">محو البقالة الخاصة بك</div>
                  <ul className="content-list">
                    <li>كوفد-١٩ ليس مرضًا ينتقل عن طريق الطعام ، مما يعني أنه لا يمكنك الإصابة بالعدوى من تناول الطعام الذي قد يكون تعرض للفيروس.</li>
                    <li>تجنب استخدام أكياس القماش (أو اغسلها بعد كل استخدام ) </li>
                    <li>تخلص من الأكياس البلاستيكية أو الورقية من محلات البقالة</li>
                    <li>
                      <div>عيّن سطحًا في المنزل كمحطة للتنظيف</div>
                      <ul>
                        <li>اجعل أحد الجانبين الجانب "النظيف" والآخر "الملوث"</li>
                        <li>بينما تنظف البقالة ، انقل الأشياء من الجانب "الملوث" إلى الجانب "النظيف"</li>
                      </ul>
                    </li>
                    <li>يمكن رش أو مسح العلب مباشرة ، أوعية زجاجية ، أو حاويات صلبة</li>
                    <li>
                      <div><strong>تخلص من جميع العبوات الخارجية غير الضرورية (مثل علب الحبوب)</strong></div>
                      <ul>
                        <li>العناصر المجمدة: إزالة العنصر من حاوية الكرتون</li>
                        <li>اللحم : ينقل إلى حاويات المنزل ويرمي حاوية المخزن</li>
                      </ul>
                    </li>
                    <li>الفواكه والخضروات: اغسل كل قطعة لمدة ٢٠ ثانية بالماء (لا تحتاج إلى استخدام الصابون)</li>
                    <li> تأكد من غسل اليدين والسطح بعد تنظيف مواد البقالة </li>
                  </ul>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/zmoBI5m2_uw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p>
                  <div>أنشأ دكتور جيفري فانوينجن ، طبيب طب الأسرة ، فيديو حول كيفية التعامل مع البقالة عند عودتنا إلى المنزل. ( آخر تحديث للفيديو ٤/١/٢٠٢٠ )</div>
                </p>
              </div>}
          />
          <Card 
            id="tips-6"
            thumbnail="/assets/img/shots/tips/tips-6.jpg" 
            title="التعامل مع الطعام المقدم"
            content={
              <div>
                <p>كوفد-١٩ليس مرضًا ينتقل عن طريق الطعام ، مما يعني أنه لا يمكنك الإصابة بالعدوى من تناول الطعام الذي قد يكون تعرض للفيروس. </p>
                <p>
                  <ul className="content-list">
                    <li>نقل المواد الغذائية من حاويات المطاعم إلى حاويات / أواني منزلية</li>
                    <li>نقل المواد الغذائية من حاويات المطاعم إلى حاويات / أواني منزلية</li>
                    <li>يشمل ذلك الصلصات: الكاتشب ، صلصة الصويا ، الصلصة الحارة ، المربى ، إلخ .</li>
                    <li>تجنب استخدام الأواني البلاستيكية التي يوفرها المطعم إن أمكن</li>
                    <li>إذا كنت تخطط لاستخدام أوانيهم ، اغسلهم بالماء والصابون قبل الاستخدام </li>
                    <li>تأكد من غسل يديك قبل لمس الطعام </li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-7"
            thumbnail="/assets/img/shots/tips/tips-7.jpg" 
            title="التعامل مع علبة والبريد"
            content={
              <div>
                <p>في <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973?query=featured_home" target="_blank">دراسة بحثية لـ</a> NEJM ، تم اكتشاف الفيروس التاجي على الورق المقوى لمدة تصل إلى ٢٤ ساعة. ومع ذلك ، فإن وجود الفيروس لا يعني تلقائيًا أنه يمكن أن يكون معديًا.</p>
                <div className="youtube-embed"><iframe width="560" height="315" src="https://www.youtube.com/embed/Sryj2VsuKns" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
                <p>
                  <div className="paragraph-header"> الطرود المناولة</div>
                  <div>شرح الدكتور جيفري فانوينجن أيضًا كيفية التعامل مع الطرود والرسائل المرسلة إذا كنت تريد أن تكون أكثر حذرًا ( تم تحديث الفيديو آخر مرة في ٤/٥/٢٠٢٠).</div>
                  <ul className="content-list">
                    <li>ستحتاج إلى مقص وسطح نظيف (مثل كرسي) </li>
                    <li>ضعها بالقرب من مدخل منزلك </li>
                    <li>عند إحضارالطرود الخاصة بك إلى منزلك ، افتح الصندوق</li>
                    <li>ألق المحتوى على السطح النظيف</li>
                    <li>تخلص من الصندوق الموجود في سلة المهملات أو سلة المهملات</li>
                    <li>تنظيف المقص وغسل يديك قبل التعامل مع المحتوى</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">معالجة الرسائل</div>
                  <ul className="content-list">
                    <li>النظر في تركه في صندوق البريد حتى صباح اليوم التالي </li>
                    <li>إذا أحضرت البريد إلى منزلك في نفس اليوم ، ففكر في وضعه جانباً لوقت لاحق</li>
                    <li>اغسل يديك بعد التعامل مع بريدك</li>
                  </ul>
                </p>
              </div>}
          />
          <h4 className="content-tips-disclaimer">نحن ندرك أن النصائح التالية قد لا تكون قابلة للتطبيق بالطرق نفسها للجميع. تختلف الموارد والموارد لكل فرد وكل أسرة. نأمل أن تعطيك الأمثلة التالية بعض الأفكار المفيدة. يرجى مشاركتنا كيف جعلت هذه النصائح مناسبة لك!</h4>
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Tips;
