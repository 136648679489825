import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Link,
  Route,
  IndexRoute,
  Redirect,
  Switch
} from 'react-router-dom'
import getUserLocale from 'get-user-locale';
import English from './English/Index'
import Spanish from './Spanish/Index'
import Korean from './Korean/Index'
import Vietnamese from './Vietnamese/Index'
import ChineseSimplified from './ChineseSimplified/Index'
import ChineseTraditional from './ChineseTraditional/Index'
import Punjabi from './Punjabi/Index'
import Arabic from './Arabic/Index'
import Russian from './Russian/Index'
import Amharic from './Amharic/Index'
import Tagalog from './Tagalog/Index'
import Tongan from './Tongan/Index'
import TokPisin from './TokPisin/Index'
import Bislama from './Bislama/Index'
import ReactGA from 'react-ga';

function HandleLocale(props) {

  const userLocale = getUserLocale();

  if (userLocale === 'vn') {
    return <Vietnamese />
  } else if (userLocale === 'es') {
    return <Spanish />
  } else if (userLocale === 'ko') {
    return <Korean />
  } else if (userLocale === 'zh_CN') {
    return <ChineseSimplified />
  } else if (userLocale === 'zh_TW') {
    return <ChineseTraditional />
  } else if (userLocale === 'pa-IN') {
    return <Punjabi />
  } else if (userLocale === 'ar') {
    return <Arabic />
  } else if (userLocale === 'ru') {
    return <Russian />
  } else if (userLocale === 'am') {
    return <Amharic />
  } else if (userLocale === 'tl') {
    return <Tagalog />
  } else if (userLocale === 'to') {
    return <Tongan />
  } else if (userLocale === 'tpi') {
    return <TokPisin />
  } else if (userLocale === 'bi') {
    return <Bislama />
  }

  return <English />

}

ReactGA.initialize('UA-164251314-1');
ReactGA.pageview(window.location.pathname);

ReactDOM.render(
  <Router>
    <div className="language-bar desktop">
      <div className="language-bar-content">
        <a href={'/en/'} className="language-bar-item">English</a>
        <a href={'/es/'} className="language-bar-item">Español</a>
        <a href={'/vn/'} className="language-bar-item">Tiếng Việt</a>
        <a href={'/tl/'} className="language-bar-item">Tagalog</a>
        <a href={'/ru/'} className="language-bar-item">Русский</a>
        <a href={'/to/'} className="language-bar-item">Tongan</a> 
        <a href={'/tp/'} className="language-bar-item">Tok Pisin</a>   
        <a href={'/bi/'} className="language-bar-item">Bislama</a>
        <a href={'/ko/'} className="language-bar-item">한국어</a>
        <a href={'/cn/'} className="language-bar-item">中文-简体</a>
        <a href={'/tw/'} className="language-bar-item">中文-繁體</a>
        <a href={'/pa/'} className="language-bar-item">ਪੰਜਾਬੀ</a>
        <a href={'/ar/'} className="language-bar-item">العربية</a>
        <a href={'/am/'} className="language-bar-item">አማርኛ</a>
      </div>
    </div>
    <Switch>
      <Route path="/en" render={(props) => <English />} />
      <Route path="/es" render={(props) => <Spanish />} />
      <Route path="/ko" render={(props) => <Korean />} />
      <Route path="/vn" render={(props) => <Vietnamese />} />
      <Route path="/cn" render={(props) => <ChineseSimplified />} />
      <Route path="/tw" render={(props) => <ChineseTraditional />} />
      <Route path="/pa" render={(props) => <Punjabi />} />
      <Route path="/ar" render={(props) => <Arabic />} />
      <Route path="/ru" render={(props) => <Russian />} />
      <Route path="/am" render={(props) => <Amharic />} />
      <Route path="/tl" render={(props) => <Tagalog />} />
      <Route path="/to" render={(props) => <Tongan />} />
      <Route path="/tp" render={(props) => <TokPisin />} />
      <Route path="/bi" render={(props) => <Bislama />} />
      <Route render={(props) => <HandleLocale />} />
    </Switch>
  </Router>, 
  document.getElementById('root')
);



