import React, { Component } from 'react'
import classnames from 'classnames'

import Button from '../Components/Button'
import ClipboardButton from '../Components/ClipboardButton'

class ShareModal extends Component {

  componentDidMount() {
    window.Kakao.init('8c22c0e3b6141547b03badeeb6f137cd');
  }

  sendKakao = (e) => {
    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: 'COVID-19 Fact Check',
        description: '查询 COVID-19 Fact Check 来分辨有关冠状病毒的事实和谣言。',
        imageUrl:
          'http://www.covid19factcheck.com/assets/img/thumbnails/social_square.png',
        link: {
          mobileWebUrl: 'http://www.covid19factcheck.com/cn/',
          androidExecParams: 'test',
        },
      },
      success: function(response) {
        console.log(response);
      },
      fail: function(error) {
        console.log(error);
      }
    });
  }

  render() {
    const { active, toggleShareModalActive } = this.props;

    return (
      <div className={classnames('modal', 'share-modal', {'modal-active': active})}>
        <div className="modal-close" onClick={toggleShareModalActive}><img className="fit-parent" src="/assets/img/icons/close.svg" /></div>
        <div className="modal-content">
          <h1 className="modal-title">转发信息</h1>
          <p className="modal-caption">转发该网站 COVID-19 Fact Check 给朋友和家人，让更多人能知道怎样有效地预防传染新冠病毒。</p>
          <div className="share-modal-action-container">
            <div className="share-modal-action">
              <a href="https://twitter.com/intent/tweet?text=%E6%9F%A5%E8%AF%A2%20COVID-19%20Fact%20Check%20%E6%9D%A5%E5%88%86%E8%BE%A8%E6%9C%89%E5%85%B3%E5%86%A0%E7%8A%B6%E7%97%85%E6%AF%92%E7%9A%84%E4%BA%8B%E5%AE%9E%E5%92%8C%E8%B0%A3%E8%A8%80%E3%80%82&tw_p=tweetbutton&url=http%3A%2F%2Fwww.covid19factcheck.com%2Fcn%2F" target="_blank">
                <img className="fit-parent" src="/assets/img/icons/share/twitter.png" />
              </a>
            </div>
            <div className="share-modal-action">
              <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=http://www.covid19factcheck.com/cn/" target="_blank">
                <img className="fit-parent" src="/assets/img/icons/share/facebook.png" />
              </a>
            </div>
            <div className="share-modal-action">
              <a href="https://wa.me/?text=%E6%9F%A5%E8%AF%A2%20COVID-19%20Fact%20Check%20%E6%9D%A5%E5%88%86%E8%BE%A8%E6%9C%89%E5%85%B3%E5%86%A0%E7%8A%B6%E7%97%85%E6%AF%92%E7%9A%84%E4%BA%8B%E5%AE%9E%E5%92%8C%E8%B0%A3%E8%A8%80%E3%80%82%20http%3A%2F%2Fwww.covid19factcheck.com%2Fcn%2F" data-action="share/whatsapp/share" target="_blank">
                <img className="fit-parent" src="/assets/img/icons/share/whatsapp.png" />
              </a>
            </div>
            <div className="share-modal-action">
              <a onClick={this.sendKakao}>
                <img className="fit-parent" src="/assets/img/icons/share/kakaotalk.png" />
              </a>
            </div>
            <div className="share-modal-action">
                <a href="https://social-plugins.line.me/lineit/share?url=http%3A%2F%2Fwww.covid19factcheck.com%2Fcn%2F" target="_blank">
                  <img className="fit-parent" src="/assets/img/icons/share/line.png" />
                </a>
            </div>
            <div className="share-modal-action">
              <a href="mailto:?body=%E6%9F%A5%E8%AF%A2%20COVID-19%20Fact%20Check%20%E6%9D%A5%E5%88%86%E8%BE%A8%E6%9C%89%E5%85%B3%E5%86%A0%E7%8A%B6%E7%97%85%E6%AF%92%E7%9A%84%E4%BA%8B%E5%AE%9E%E5%92%8C%E8%B0%A3%E8%A8%80%E3%80%82%20http%3A%2F%2Fwww.covid19factcheck.com%2Fcn%2F">
                <img className="fit-parent" src="/assets/img/icons/share/mail.png" />
              </a>
            </div>
          </div>
          <ClipboardButton value="http://www.covid19factcheck.com/cn/" success="已复制网址！ 🎉">
            <Button className="btn-share" label="复制与分享网址" imgURL="/assets/img/icons/link.svg" />
          </ClipboardButton>
        </div>
      </div>
    )
  }
}

export default ShareModal