import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Myth extends Component {

  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <div className="content-nav-scroller">
            <h4 className="content-nav-caption">ማውጫ</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-1')}>ጭምብል በለበስኩ ጊዜ 6 ጫማ  ሪቄ መቆየት አያስፈልገኝም?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-10')}>አልኮሆል ወይም ክሎሪን መላ ሰውነትዎ ላይ ቢረጩ ኮሮናቫይረስን ይገድላል?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-2')}>የቀዶ ጥገና ጭምብል ወይስ የ N95 የመተንፈሻ መሣሪያ መግዛት አለብኝ ?</h4>
            {/* <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-3')}>Can coronavirus spread far distances through the air?</h4> */}
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-4')}>ጓንት መሸፈኛ 100% ከኮሮሪ ቫይረስ ይጠብቀኛል?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-5')}>ኮሮና ቫይረስ ለበርካታ ቀናት መሬት ላይ መኖር ይችላል?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-6')}>ከቤት ካልወጣሁ ብዙውን ጊዜ እጅን መታጠብ ወይም ጽዳት ማድረግ የለብኝም ?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-7')}>ኢቡፕሮፌን ኮቪድ-19 ላላቸው ሰዎች በሽታን ያባብሳል?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-8')}>ከሃይድሮክሎክሎሮኪን(የወባ መድኃኒት) እና አዚትሮሚሚሲን(አንቲባዮቲክ) ጋር ራስን በራስ የመድኃኒት ሕክምና ኮቪድ-19 ይከላከላል ?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-9')}>አንቲባዮቲኮች ኮቪድ-19 ን በመከላከል እና በማከም ረገድ ውጤታማ ናቸው ?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-11')}>እኔ ወጣት እና ጤናማ ነኝ ስለሆነም በአዲሱ ኮሮኔቫቫይረስ ኣልያዝም?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-12')}>ኮቪድ-19 ካለኝ ሆስፒታል መተኛት አለብኝ ?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-13')}>ሳል ወይም ምቾት ሳይሰማኝ እስትንፋሴን ለ 10 ሰከንዶች ወይም ከዚያ በላይ ለመያዝ ከቻልኩ ኮቪድ-19 የለኝም?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-14')}>የእጅ ማድረቂያ እና የዩ.ቪ መከላከያ መብራቶች ኮሮናቫይረስን ለመግደል ውጤታማ ናቸው ?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-15')}>አፍንጫዎን አዘውትሮ በጨው ውሃ መታጠብ ኮቪድ-19ን ይከላከላል ?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-16')}>በሙቅ ውሃ መታጠብ ኮቪድ-19 ይከላከላል ?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-17')}>ቀዝቃዛ የአየር ጠባይ እና በረዶ ከ ኮቪድ-19 ይከላከላል?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-18')}>ነጭ ሽንኩርት መብላት ኮቪድ-19 ን ይከላከላል?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-19')}>አልኮል መጠጣት ኮቪድ-19 ን ይከላከላል?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-20')}>የሳንባ ምች በሽታ መከላከያ ክትባት ከ ኮቪድ-19 ይጠብቁዎታል ?</h4>
          </div>
        </div>
        <div className="content-body">
          <Card 
            id="myth-1"
            thumbnail="/assets/img/shots/myth/myth-1.jpg" 
            title="ጭምብል በለበስኩ ጊዜ 6 ጫማ  ሪቄ መቆየት አያስፈልገኝም?"
            content={
              <div>
                <p><span className="card-tag-false">አይደለም</span>ከሌሎች 6 ጫማ / ርቀት መጓዝዎን ይቀጥሉ ፣ እጆችዎን ደጋግመው እና በተገቢው መንገድ ጭምብል ከማስወገድዎ በፊት እና በኋላ ይታጠቡ::</p>
                <p>በአሜሪካ ውስጥ <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover-faq.html" target="_blank">CDC</a> የሚመክረዉ አንድ ሰው ቫይረሱ እንዳይሰራጭ ለመከላከል የሚያሲችላችው ጊዜ በማስነጠስ ፣ ወይም በመናገር ጊዜ ቫይረሱ እንዳይሰራጭ ለመከላከል የጨርቅ ጭንብሎችን ይመክራል ፡፡ ጭምብሉ የተወሰነ መከላከያ ቢሰጥም ከሁሉም የቫይረስ ትህዋሳት ሙሉ በሙሉ አይከላከልም ፡፡</p>
              </div>}
            extra={
              <div>
                <p>እባክዎን የ <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a> ምክሮችን ይከተሉ:: ደህንነቱ የተጠበቀ የቤት ቁሳቁስ ይጠቀሙ:: እንዲሁም ጭምብሉ በልካችሁ መሆኑን ያረጋግጡ:: ጭምብሉ ምቹ የሆነ ኣተነፋፈስ  እንዲኖረው ይመከራል::</p>
                <p><div className="paragraph-header">የጨርቅ ጭምብል ኮሮና ቫይረስን በመከላከል ምን ያህል ውጤታማ ነው?</div>ይህንን ጥያቄ በቀጥታ ለመመለስ ሳይንሳዊ ማስረጃ የለም ፡፡ የተለየ ቫይረስ በመጠቀም <a href="https://www.ncbi.nlm.nih.gov/pubmed/24229526" target="_blank">በላቦራቶሪ ሙከራ</a> ተመራማሪዎች የተለያዩ ጨርቆች መቶ በመቶ ቫይረሱን እንደማይከለክሉ አረጋግጠዋል። አብዛኛዎቹ ጭምብሎች <a href="https://www.gannett-cdn.com/experiments/usatoday/gifs/tool/919-surgical.png" target="_blank">በፊታችን ልክ ስላልሆነ፣</a> ቫይረሱ አሁንም ጭምብሉ ውስጥ ሊገባ ይችላል ፡፡</p>
                <p><div className="paragraph-header">ዋና ዋና ነጥቦች</div>የጨርቅ ጭምብል  ኢንፌክሽኑን ለመከላከል ሊረዳ ይችላል ፣ ነገር ግን 100% ኣይከላከልም ፡፡</p>
              </div>}
          />
          <Card 
            id="myth-10"
            thumbnail="/assets/img/shots/myth/myth-10.jpg" 
            title="አልኮሆል ወይም ክሎሪን መላ ሰውነትዎ ላይ ቢረጩ ኮሮናቫይረስን ይገድላል?"
            content={
              <div>
                <p><span className="card-tag-false">አይደለም</span>እባክዎን ፀረ-ተባይ ፈሳሽ አልኮሆል ወይም ክሎሪን በመላው ሰውነትዎ ላይ ወይም በመርፌ እንዳይጠቀሙ ፡፡</p>
                <p><a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">የዓለም ጤና ድርጅት</a> እንደገለጸው “ቀድሞውኑ ወደ ሰውነትዎ የገቡ ቫይረሶችን አይገድልም ፡፡ በልብስ እና በቆዳ ላይ እንዲህ ያሉትን ንጥረ ነገሮች መርጨት አደገኛ ሊሆን ይችላል ፡፡ አልኮሆል እና ክሎሪን <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cleaning-disinfection.html" target="_blank">መሬቶችን ለማፅዳት ጠቃሚ</a> ሊሆኑ ይችላሉ ፡፡</p>
              </div>}
          />
          <Card 
            id="myth-2"
            thumbnail="/assets/img/shots/myth/myth-2.jpg" 
            title="የቀዶ ጥገና ጭምብል ወይስ የ N95 የመተንፈሻ መሣሪያ መግዛት አለብኝ ?"
            content={
              <div>
                <p><span className="card-tag-false">አይደለም</span><a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a> በአሜሪካ ውስጥ ለጠቅላላው ህዝብ የጨርቅ የፊት ጭምብል እንዲያደርጉ ይመክራል ፡፡</p>
                <p>በቤትዎ ውስጥ ማንኛውንም ጭምብል ማድረግ ይችላሉ ፡፡ የቀዶ ጥገና ጭምብል ወይም N95 መግዛት አያስፈልግዎትም ፡፡ በአሜሪካ አቅርቦት እጥረት ምክንያት የቀዶ ጥገና ጭምብሎች በትክክል እንዲጠቀሙባቸው ለሰለጠኑ እና በቀጥታ ከታመሙ በሽተኞች ጋር ለሚገናኙ የጤና እንክብካቤ ሠራተኞች መቀመጥ አለባቸው ፡፡ በሆስፒታሎች ውስጥ የአየር ዝውውርን ለመከላከል N95 ሰለሚያስፈልግ  ተጨማሪ የ N95 ጭምብሎችን ለሆሰፒታልሎች ቢለግሱ ይመከራል ፣ ግን ኮቪድ-19 በዋነኝነት የሚተላለፈው በእስትንፋስ በኩል ነው ፡፡</p>
              </div>}
            extra={
              <div>
                <p><a href="https://www.fda.gov/medical-devices/personal-protective-equipment-infection-control/n95-respirators-and-surgical-masks-face-masks" target="_blank">የN95 ጭምብል</a> በትክክል ከተደረገ ቢያንስ 95 በመቶ ትንሽ ቅንጣቶችን ሊያግድ ይችላል።  በተጨማሪ የጤና ጥበቃ ሠራተኞች በአየር ላይ የሚያቀርበውን ተገቢ መጠን ለማረጋገጥ በየዓመቱ <a href="https://www.safetyalliance.org/wp-content/uploads/2019/06/fit-test-2-000110_retouched-672x372.jpg" target="_blank">ተስማሚ ምርመራን</a> ያጠናቅቃሉ ፡፡ <a href="https://blogs.cdc.gov/niosh-science-blog/2018/01/04/respirators-public-use/" target="_blank">ለመላው ህዝብ</a> የሥልጠና እጥረት እና የአካል ብቃት ምርመራ አለመኖር N95 የታሰበውን የጥበቃ ደረጃ ላይሰጥ ይችላል ፡፡</p>
              </div>}
          />
          {/*
          <Card 
            id="myth-3"
            thumbnail="/assets/img/shots/myth/myth-3.jpg" 
            title="Can coronavirus spread far distances through the air?"
            content={
              <div>
                <p><span className="card-tag-maybe">ምን አልባት</span>Airborne transmission is unlikely for the general public.</p>
                <p>According to <a href="https://www.who.int/news-room/q-a-detail/q-a-coronaviruses" target="_blank">WHO</a>, “COVID-19 is mainly transmitted through droplets generated when an infected person coughs, sneezes, or speaks. These droplets are too heavy to hang in the air. They quickly fall on floors or surfaces.” አይ airborne transmission was reported among an analysis of over 75,000 COVID-19 cases in China. However, health care workers may be at risk. WHO reports airborne transmission may be possible during <a href="https://www.who.int/news-room/commentaries/detail/modes-of-transmission-of-virus-causing-covid-19-implications-for-ipc-precaution-recommendations" target="_blank">hospital procedures that generate aerosols</a> such as intubation, where a breathing tube is inserted into the patient’s airway to help them breathe.</p>
                </div>}
            extra={
              <div>
                <p><div className="paragraph-header">What does airborne transmission mean?</div>In contrast to respiratory droplets, airborne particles are smaller and remain in the air for long periods of time where they can spread farther than 6 feet.</p>
                <p><div className="paragraph-header">Why does the news say coronavirus is airborne?</div>A NEJM <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973" target="_blank">study</a> artificially created aerosols of the coronavirus in a rotating cylinder, where it remained viable for 3 hours. However, the high-powered machine does not represent a normal human cough. In a non peer-reviewed <a href="https://www.medrxiv.org/content/10.1101/2020.03.23.20039446v2" target="_blank">study</a> (has not been evaluated by other experts), researchers sampled for the presence of coronavirus in the air and surfaces of COVID-19 patients’ hospital room. 76.5% of all personal belongings were positive (cell phones, toilets, remotes, bed rails, etc.). For air samples, 63.2% were positive and some samples were collected greater than 6 feet away from the patient. However, the presence of a virus does not automatically mean it can cause disease. For example, the amount of virus may be too low to cause infection. The authors have not proven that the components of the virus that they have detected can cause infections.</p>
              </div>}
          /> */}
          <Card 
            id="myth-4"
            thumbnail="/assets/img/shots/myth/myth-4.jpg" 
            title="ጓንት መሸፈኛ 100% ከኮሮሪ ቫይረስ ይጠብቀኛል?"
            content={
              <div>
                <p><span className="card-tag-false">አይደለም</span>ጓንቶች የጥንቃቄ እርምጃዎችን ከጠበቁ ብቻ ይረዱዎታል:: ጓንትዎን ካስወገዱ በኋላ ፊትዎን ፣ ዐይንዎን እና አፍንጫዎን ከመንካትዎ በፊት እጅዎን መታጠብዎን ያረጋግጡ::የቆሸሸ ጓንቶችዎ ቢነካውም ወደ እነዚያ ገጽታዎች ሊሰራጭ ይችላል፡፡</p>
              </div>}
          />
          <Card 
            id="myth-5"
            thumbnail="/assets/img/shots/myth/myth-5-am.jpg" 
            title="ኮሮና ቫይረስ ለበርካታ ቀናት መሬት ላይ መኖር ይችላል?"
            content={
              <div>
                <p><span className="card-tag-true">አዎ</span>አንድ የ NEJM <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973?query=featured_home" target="_blank">ጥናት</a> እንደሚያሳየው ኮሮና ቫይረስ በካርቶን ላይ እስከ 24 ሰአታት ድረስ ፣  በላሰቲኪ እና በብረት ውስጥ ደግሞ እስከ 72 ሰዓታት  ሊገኝ ይችላል፡፡</p>
                <p>የሚቀረው የቫይረስ መጠን ከመጀመሪያው የቫይረስ ቁስ ከ 0.1% በታች መሆኑን ልብ ሊባል ይገባል። ቫይረሱ በመኖር ብቻ ተላላፊ ሊሆን ይችላል ማለት አይደለም ፡፡በእያንዳንዱ ወለል ላይ የቫይረሱ መጠን ከጊዜ ወደ ጊዜ በፍጥነት ስለቀነሰ እነሱን ከመንካት የመያዝ እድሉ ከጊዜ ወደ ጊዜ ይቀንሳል ፡፡</p>
              </div>}
          />
          <Card 
            id="myth-6"
            thumbnail="/assets/img/shots/myth/myth-6.jpg" 
            title="ከቤት ካልወጣሁ ብዙውን ጊዜ እጅን መታጠብ ወይም ጽዳት ማድረግ የለብኝም ?"
            content={
              <div>
                <p><span className="card-tag-false">አይደለም</span><a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/prevention.html" target="_blank">CDC</a> እንደ የ በር እጀታዎች ፣ የመብራት ማብሪያና ማጥፊያ ፣ ጠረቤዛ  ፣ መደርደሪያዎች ፣ ስልኮች ፣ የቁልፍ ሰሌዳዎች ፣ መጸዳጃ ቤቶች ፣ የወሃ መክፈቻና መዝጊያ  በየቀኑ በተደጋጋሚ የሚነካኩትን ነገሮች ማፅዳት ይመክራል ፡፡</p>
                <p>እርስዎ ወይም አንድ የቤተሰብ አባል  ሳያውቁት በበሽታው ሊጠቁ ይችላሉ፡፡ ምንም እንኳን በበሽታው ከተያዘው ሰው ጋር ቅርብ በሆነ ግንኙነት በበሽታው የመያዝ እድሉ ከፍተኛ ቢሆንም ጥንቃቄዎችን ማድረግ ይጠበቃል ፡፡</p>
              </div>}
          />
          <Card 
            id="myth-7"
            thumbnail="/assets/img/shots/myth/myth-7.jpg" 
            title="ኢቡፕሮፌን ኮቪድ-19 ላላቸው ሰዎች በሽታን ያባብሳል?"
            content={
              <div>
                <p><span className="card-tag-false">አይደለም</span>የ ‹ኮቪድ-19› ምልክቶች እንዲባባሱ ለማድረግ ibuprofen (ወይም Advil ፣ Motrin) በአሁኑ ጊዜ በቂ ሳይንሳዊ ማስረጃ የለም::</p>
                <p><a href="https://twitter.com/WHO/status/1240409217997189128" target="_blank">የዓለም ጤና ድርጅት</a> ibuprofen  መጠቀምን አይመክርም (ማርች 18 ፣ 2020)።  Acetaminophen (Tylenol) ተለዋጭ ለትኩሳት እና የህመም ማስታገሻ መድሃኒት ነው ፡፡ እነዚህን ስቴሮይድ ያልሆኑ መድሐኒቶች (NSAIDs) የመውሰድ ፍላጎት ካለብዎ እና ሥር የሰደደ በሽታዎችን ለማከም የሚረዳዎት ከሆነ <a href="https://www.fda.gov/drugs/drug-safety-and-availability/fda-advises-patients-use-non-steroidal-anti-inflammatory-drugs-nsaids-covid-19" target="_blank">FDA</a> ለጤና ክብካቤ አቅራቢዎ እንዲነጋገሩ ሀሳብ ያቀርባል ፡፡</p>
                </div>}
            extra={
            <div>
              <p>Ibuprofen በመባል የሚታወቅ የመድኃኒት <a href="https://www.goodrx.com/blog/are-advil-motrin-tylenol-bad-for-coronavirus-symptoms/" target="_blank">ቡድን</a> ስር አስፕሪን ፣ ናፖክሲን (አሌቭ) ፣ ዲክሎፍኖክ (ካምቢያ) እና ሴልኮክቢን (ሴሌብክስ) የተባሉ ትኩሳትን ፣ ህመምን እና እብጠትን ለማከም ያገለግላሉ ፡፡እያንዳንዱ መድሃኒት የራሱ አደጋዎች እና ጥቅሞች አሉት።በጣም አስጊ የሆነው Acetaminophen (Tylenol) ከመጠን በላይ ከተጠቀሙ የጉበት ጉዳት ያመጣል።Tylenol በብዙ የጉንፋን እና ፍሉ መድኃኒቶች ውስጥ ይገኛል ፡፡ከፍተኛውን የዕለታዊ መጠን እንዳያሳልፉ እባክዎን ሁሉንም መድኃኒቶች በጥንቃቄ ያንብቡ። </p>
              <p><div className="paragraph-header">የ ibuprofen ክርክር እንዴት ተጀመረ?</div>በሰውነት ውስጥ የሚገኝ <a href="https://jvi.asm.org/content/94/7/e00127-20" target="_blank">ACE2</a> የተሰኘ ኤንዛይም ኮሮና ቫይረስ ከሰውነት ውስጥ እንዲጣበቅ ያደርጋል</p>
              <p>ማርች 11 ፣ 2020 ተመራማሪዎች በ Lancet የመተንፈሻ ህክምና ተቋም ፡፡Ibuprofen እንዴት ከACE2 ጋር ይዛመዳል? የዚህ ጥናት ደራሲ ibuprofen ACE2 እንዲበዛ ሊያደርግ እንደሚችል ይጠቁማል ፡፡ስለሆነም በ ibuprofen እና ኮቪድ-19 መካከል ግንኙነት ሊኖር ይችላል፡፡</p>
              <p>ይህ በ ኮቪድ-19 በሽተኞች ላይ ኢቡፕሮፌን በቀጥታ በሽታዉን እያባባሰዉ ይመጣል የሚል ሳይንሳዊ ማስረጃ የለም ፡፡ ሆኖም እ.ኤ.አ. ማርች 14 ፣ የፈረንሣይ የጤና ጥበቃ ሚኒስቴር ለ ኮቪድ-19 ትኩሳት ኢብፕሮፌንን መጠቀምን አስመልክቶ ማስጠንቀቂያ ኣስተላልፈዋል እና የፈረንሣይ የጤና ሚኒስትሩ ibuprofen እና ሌሎች NSAIDS ን ለማስቀረት <a href="https://twitter.com/olivierveran/status/1238776545398923264" target="_blank">በትዊተር</a> ላይ ምክሮችን አስተላልፈዋል፡፡የእሱ ምክር በዜና ዘገባዎች ላይ በስፋት ተሰራጭቶ ነበር እና የ ኮቪድ-19 ሞት ዋነኞቹ መንስኤዎች ኢቡፕሮፌን አንደሆነ ሪፖርት ማድረግ ጀመሩ ፡፡በመጋቢት 16 ላይ ክርክሩን ያነሳሱት ፀሐፊዎች የእነሱን ፅንሰ-ሀሳብ ሲያብራሩ የሚከተለውን ብለዋል <a href="https://www.unibas.ch/en/News-Events/News/Uni-Research/Ibuprofen-and-COVID-19-Setting-the-record-straight.html" target="_blank">“የተወሰኑ መድኃኒቶችን እንዲጠቀሙ ወይም አይጠቀሙም የሚል ምክር አይሰጥም ፡፡”</a></p>
              <p>በ ኮቪድ-19 ምልክቶች ለሚሰቃዩ ሰዎች ፣ WHO በመጀመሪያ ibuprofen ን ላለመጠቀም መክረዉ ነበር ::ማርች 18 እ.ኤ.አ. <a href="https://www.ema.europa.eu/en/news/ema-gives-advice-use-non-steroidal-anti-inflammatories-covid-19" target="_blank">EMA</a>, <a href="https://www.fda.gov/drugs/drug-safety-and-availability/fda-advises-patients-use-non-steroidal-anti-inflammatory-drugs-nsaids-covid-19" target="_blank">FDA</a>, <a href="https://twitter.com/WHO/status/1240409217997189128" target="_blank">WHO</a> አስፈላጊ ሆኖ ሲገኝ NSAIDS እንዲወስዱ ይመክራል።</p>
            </div>}
          />
          <Card 
            id="myth-8"
            thumbnail="/assets/img/shots/myth/myth-8.jpg" 
            title="ከሃይድሮክሎክሎሮኪን(የወባ መድኃኒት) እና አዚትሮሚሚሲን(አንቲባዮቲክ) ጋር ራስን በራስ የመድኃኒት ሕክምና ኮቪድ-19 ይከላከላል ?"
            content={
              <div>
                <p><span className="card-tag-false">አይደለም</span>እባክዎ ያለ ዶክተር ትእዛዝ <strong>መድሃኒት አይወስዱ</strong>! በአደገኛ የመድኃኒት ግንኙነቶች እና በአደገኛ የጎንዮሽ ጉዳቶች እንዳይጠቁ  በሐኪም የታዘዘልዎትን መድኃኒቶች ብቻ ይውሰዱ ፡፡</p>
                <p><a href="https://www.cdc.gov/coronavirus/2019-ncov/hcp/therapeutic-options.html" target="_blank">ሃይድሮክሎሮክኩይን እና ክሎሮኩዊን</a> የወባ በሽታ,  እንደ ሉፐስ እና ሩማቶይድ አርትራይተስ ላሉ በሽታዎች የሚታዘዙ መድሃኒቶች ናቸው።Azithromycin በተወሰኑ ባክቴሪያዎች ምክንያት የሚመጡ ኢንፌክሽኖችን ለማከም የሚያገለግል የተለመደ አንቲባዮቲክ ነው፡፡ ሁለቱም hydroxychloroquine እና azithromycin ከልብ ጉዳቶች ጋር የተዛመዱ ናቸው እናሞ ለሕይወት አስጊ የሆነ የልብ ችግር ያስከትላል ፡፡እነዚህ መድሃኒቶች ኮቪድ-19ን በማከም ረገድ ውጤታማ ሊሆኑ ይችሉ እንደሆነ ለማየት ቀጣይ የሆነ ምርምር አለ ፣ ግን እባክዎን በሐኪሞች ምክር መሠረት የታዘዘ መድሃኒት ብቻ ይውሰዱ ፡፡</p>
              </div>}
          />
          <Card 
            id="myth-9"
            thumbnail="/assets/img/shots/myth/myth-9.jpg" 
            title="አንቲባዮቲኮች ኮቪድ-19 ን በመከላከል እና በማከም ረገድ ውጤታማ ናቸው ?"
            content={
              <div>
                <p><span className="card-tag-false">አይደለም</span><a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">የዓለም የጤና ድርጅት</a> እንደገለፀው አንቲባዮቲኮች የሚሰሩት በቫይረሶች ሳይሆን በባክቴሪያ ምክንያት የሚመጡ ኢንፌክሽኖችን ለማስወገድ ብቻ ነው ፡፡አንቲባዮቲኮች ቫይረሶችን ለመከላከል ወይም ለማከም ጥቅም ላይ መዋል የለባቸውም ፡፡ሆኖም ለ ኮቪድ-19 ሆስፒታል ከገቡ ፣ የባክቴሪያ አብሮ-ኢንፌክሽን ስለሚኖር በአንቲባዮቲክ ሊታከሙ ይችላሉ ፡፡</p>
              </div>}
          />
          
          <Card 
            id="myth-11"
            thumbnail="/assets/img/shots/myth/myth-11.jpg" 
            title="እኔ ወጣት እና ጤናማ ነኝ ስለሆነም በአዲሱ ኮሮኔቫቫይረስ ኣልያዝም?"
            content={
              <div>
                <p><span className="card-tag-false">አይደለም</span><a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">የዓለም የጤና ድርጅት</a> እንደገለጸው “በየትኛውም የዕድሜ ክልል ውስጥ ያሉ ሰዎች በአዲሱ የኮሮኔቫቫይረስ ሊጠቁ ይችላሉ።አዛውንት ሰዎች እና የጤና ችግር ያለባቸው ለምሳሌ (አስም ፣ የስኳር ህመም ፣ የልብ ህመም) ያላቸው ሰዎች በቫይረሱ በጣም ተጋላጭ ሆነው ይታያሉ ፡፡በየትኛውም ዕድሜ ያሉ ሰዎች ራሳቸውን ከቫይረሱ ለመከላከል እርምጃዎችን እንዲወስዱ ይመከራል። ”</p>
              </div>}
            extra={
              <div>
                <p>በአሜሪካ ውስጥ ከነበሩት የመጀመሪያዎቹ 4,226 ሰዎች (እ.ኤ.አ. ፌብሩዋሪ 12 - ማርች 16) በአሜሪካ ውስጥ <a href="https://www.cdc.gov/mmwr/volumes/69/wr/mm6912e2.htm#F2_down" target="_blank">CDC</a> ዘገባ እንደሚያመለክተው አምስቱ ሕመምተኞች ከ 20 እስከ 44 ዕድሜ ያላቸው ናቸው ፡፡እ.ኤ.አ ኤፕሪል 12 <a href="https://www.cdph.ca.gov/Programs/CID/DCDC/Pages/Immunization/ncov2019.aspx" target="_blank">በካሊፎርኒያ  የተረጋገጠው የ ኮቪድ-19</a> ከተያዙት ግማሽ የሚሆኑት ከ 50 አመት በታች ናቸው ፡፡ምንም እንኳን በቫይረሱ የሞት መጠን በወጣት ህመምተኞች ዘንድ ዝቅተኛ ቢሆንም ፣ ብዙ ወጣቶች ያለ ቅድመ የጤና ችግር ሕይወታቸው ኣልፏል ፡፡በአጠቃላይ ሁሉም ሰው እራሱን እና ሌሎችን ለመጠበቅ ጥንቃቄዎችን ማድረግ አለበት።</p>
              </div>}
          />
          <Card 
            id="myth-12"
            thumbnail="/assets/img/shots/myth/myth-12.jpg" 
            title="ኮቪድ-19 ካለኝ ሆስፒታል መተኛት አለብኝ ?"
            content={
              <div>
                <p><span className="card-tag-false">አይደለም</span>በ <a href="https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/testing.html" target="_blank">CDC</a> መሰረት መሠረት አብዛኛው ሰው ቀለል ያለ ህመም ስላለው በቤት ውስጥ ማገገም ይችላል ፡፡የሕክምና እንክብካቤ ለማግኘት ካልሆነ በስተቀር ከቤትዎ አይውጡ። የመተንፈስ ችግር ካለብዎ የህክምና እርዳታ ይፈልጉ ፣ ነገር ግን በመጀመሪያ ወደ ጤና ጣብያ ተቋም ይደውሉ ፡፡</p>
              </div>}
          />
          <Card 
            id="myth-13"
            thumbnail="/assets/img/shots/myth/myth-13.jpg" 
            title="ሳል ወይም ምቾት ሳይሰማኝ እስትንፋሴን ለ 10 ሰከንዶች ወይም ከዚያ በላይ ለመያዝ ከቻልኩ ኮቪድ-19 የለኝም?"
            content={
              <div>
                <p><span className="card-tag-false">አይደለም</span>በ CDC መሰረት ኮሮናቫይረስ ሊኖራችሁ የሚችል እና ምንም ዓይነት የበሽታ ምልክት የሌላችሁ እንዲሁም ማንኛውንም ምልክት ከማሳየትዎ በፊት ቫይረሱን ሊያስተላልፉ ይችላሉ ፡፡ <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">በአለም የጤና ድርጅት መሰረት</a> የ “ ኮቪድ-19” በጣም የተለመዱ ምልክቶች ደረቅ ሳል ፣ ድካም እና ትኩሳት ናቸው ፡፡አንዳንድ ሰዎች እንደ የሳንባ ምች ያሉ ከባድ የከፋ የበሽታ ዓይነቶች ሊከሰቱ ይችላሉ።በዚህ የአተነፋፈስ ልምምድ ማረጋገጥ አይቻልም፡፡”</p>
              </div>}
          />
          <Card 
            id="myth-14"
            thumbnail="/assets/img/shots/myth/myth-14.jpg" 
            title="የእጅ ማድረቂያ እና የዩ.ቪ መከላከያ መብራቶች ኮሮናቫይረስን ለመግደል ውጤታማ ናቸው ?"
            content={
              <div>
                <p><span className="card-tag-false">አይደለም</span><a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">የዓለም ጤና ድርጅት</a> እንደገለጸው “የእጅ ማድረቂያ ኮሮናቫይረስን ለመግደል ውጤታማ አይደለም ፡፡የአልትራቫዮሌት ጨረር የቆዳ ችግር ስለሚያስከትል የአልትራቫዮሌት መብራቶች እጅን ወይም ሌሎች የቆዳ አካባቢዎችን ለማከም ጥቅም ላይ መዋል የለባቸውም።ከአዲሱ የኮሮኔቫቫይረስ በሽታ ለመከላከል እጅዎን በሳሙና እና በውሃ ወይም በአልኮል እጅ በመታጠብ አዘውትረው ማጽዳት አለብዎት።”</p>
              </div>}
          />
          <Card 
            id="myth-15"
            thumbnail="/assets/img/shots/myth/myth-15.jpg" 
            title="አፍንጫዎን አዘውትሮ በጨው ውሃ መታጠብ ኮቪድ-19ን ይከላከላል ?"
            content={
              <div>
                <p><span className="card-tag-false">አይደለም</span><a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">የዓለም ጤና ድርጅት</a> እንደገለጸው “አፍንጫውን በጨው አዘውትሮ ማጠቡ ሰዎችን በአዲሱ የኮሮኔቫቫይረስ ኢንፌክሽኑ አንዳይያዙ የሚያረጋግጥ ማስረጃ የለም።አፍንጫዎን በጨው አዘውትረው ማጠብ ሰዎች ከተለመደው ጉንፋን በፍጥነት እንዲያገግሙ እንደሚረዳ የተወሰነ ማስረጃ አለ።ይሁን እንጂ አዘውትሮ አፍንጫዎን በጨው ውሃ መታጠብ የመተንፈሻ አካልን በሽታ ለመከላከል እንደሚረዳ መረጃ የለም።”</p>
              </div>}
          />
          <Card 
            id="myth-16"
            thumbnail="/assets/img/shots/myth/myth-16.jpg" 
            title="በሙቅ ውሃ መታጠብ ኮቪድ-19 ይከላከላል ?"
            content={
              <div>
                <p><span className="card-tag-false">አይደለም</span>ገላዎን መታጠብ ይችላሉ ፣ ግን እባክዎን በጣም ሙቅ ውሃ ሆን ብለው አይጠቀሙ ፡፡ <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">የዓለም ጤና ድርጅት እንደገለጸው</a> “የገላዎ ሙቀት ምንም ይሁን ምን መደበኛ ከ 36.5 ዲግሪ ሴንቲግሬድ እስከ 37 ድግሪ ሴ.ሜ ድረስ ይሆናል ፡፡ እጅግ በጣም በሞቀ ውሃ መታጠቡ ቆዳን ሊጎዳ ይችላል ፡፡”</p>
              </div>}
          />
          <Card 
            id="myth-17"
            thumbnail="/assets/img/shots/myth/myth-17.jpg" 
            title="ቀዝቃዛ የአየር ጠባይ እና በረዶ ከ ኮቪድ-19 ይከላከላል?"
            content={
              <div>
                <p><span className="card-tag-false">አይደለም</span><a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">የዓለም የጤና ድርጅት</a> እንደዘገበው “ቅዝቃዛው የአየር ጠባይ እና በረዶ አዲሱን ኮሮናቫይረስ ሊገድል አይችልም። የውጭው ሙቀትም ሆነ የአየር ሁኔታ ምንም ይሁን ምን መደበኛ የሰው አካል የሙቀት መጠን ከ 36.5 ዲግሪ ሴንቲግሬድ እስከ 37 ዲግሪ ሴንቲ ግሬድ ድረስ ይቆያል ፡፡”</p>
              </div>}
          />
          <Card 
            id="myth-18"
            thumbnail="/assets/img/shots/myth/myth-18.jpg" 
            title="ነጭ ሽንኩርት መብላት ኮቪድ-19 ን ይከላከላል?"
            content={
              <div>
                <p><span className="card-tag-false">አይደለም</span><a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">የዓለም ጤና ድርጅት</a> እንደገለጸው “ነጭ ሽንኩርት አንዳንድ ፀረ-ተሕዋስያን ሊኖረው የሚችል ጤናማ ምግብ ነው ፡፡ ይሁን እንጂ ነጭ ሽንኩርት መብላቱ ሰዎችን ከአዲሱ የኮሮኔቫቫይረስ ለመጠበቅ እንደሚረዳ በአሁኑ ጊዜ መረጃ የለም።”</p>
              </div>}
          />
          <Card 
            id="myth-19"
            thumbnail="/assets/img/shots/myth/myth-19.jpg" 
            title="አልኮል መጠጣት ኮቪድ-19 ን ይከላከላል?"
            content={
              <div>
                <p><span className="card-tag-false">አይደለም</span><a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">የዓለም ጤና ድርጅት</a>እንደገለጸው “አልኮሆል መጠጣት ከ COVID-19 (ከቪቪዲድ -19) ጥበቃ አያደርግም እናም አደገኛ ነው ፡፡ አዘውትሮ ወይም ከመጠን በላይ አልኮሆል መጠጣት ለጤና ችግሮች የመጋለጥ እድልን ሊጨምር ይችላል።”</p>
              </div>}
          />
          <Card 
            id="myth-20"
            thumbnail="/assets/img/shots/myth/myth-20.jpg" 
            title="የሳንባ ምች በሽታ መከላከያ ክትባት ከ ኮቪድ-19 ይጠብቁዎታል ?"
            content={
              <div>
                <p><span className="card-tag-false">አይደለም</span>የዓለም ጤና ድርጅት <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a> እንደገለጸው “እንደ የሳንባ ምች ክትባት እና ሄሞፊለስ ኢንፍሉዌንዛ አይነት ቢ (ሂብ) ክትባት ያሉ የሳንባ ምች በሽታዎችን የመከላከል ክትባት ለአዲሱ የኮሮኔቫይረስ በሽታ መከላከያ አይሆንም፡፡ቫይረሱ በጣም አዲስ እና የተለየ ስለሆነ የራሱን ክትባት ይፈልጋል።ምንም እንኳን እነዚህ ክትባቶች በአዲሱ የኮሮኔቫቫይረስ ላይ ውጤታማ ባይሆኑም የመተንፈሻ አካላት በሽታዎችን ለመከላከል ጤናዎን ለመጠበቅ በከፍተኛ ሁኔታ ይመከራል ፡፡”</p>
              </div>}
          />
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Myth;
