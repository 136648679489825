import React, { Component } from 'react'
import classnames from 'classnames'
import { Link, withRouter } from 'react-router-dom'

import Button from '../Components/Button'
import ClipboardButton from '../Components/ClipboardButton'

class Aside extends React.PureComponent {

  render() {

    return (
      <div className="content-aside desktop">
        <div className="content-share">
          <h4 className="content-share-caption">Comparta con amistades y familiares</h4>
          <div className="content-share-options">
            <div className="content-share-item content-share-twitter">
              <img className="fit-height" src="/assets/img/icons/twitter.png" />
              <a className="twitter-share-button" href="https://twitter.com/intent/tweet?text=Coteje%20la%20p%C3%A1gina%20de%20COVID-19%20Fact%20Check%20para%20aprender%20sobre%20los%20mitos%20y%20hechos%20del%20coronavirus.&tw_p=tweetbutton&url=http%3A%2F%2Fwww.covid19factcheck.com%2Fes%2F" target="_blank">Tweet</a>
            </div>
            <div className="content-share-item content-share-facebook">
              <img className="fit-height" src="/assets/img/icons/facebook.png" />
              <a className="fb-share-button" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=http://www.covid19factcheck.com/es/"></a>
            </div>
            <div className="content-share-item content-share-email">
              <img className="fit-height" src="/assets/img/icons/email.svg" />
              <a href="mailto:?body=Coteje%20la%20p%C3%A1gina%20de%20COVID-19%20Fact%20Check%20para%20aprender%20sobre%20los%20mitos%20y%20hechos%20del%20coronavirus.%20http%3A%2F%2Fwww.covid19factcheck.com%2Fes%2F"></a>
            </div>
            <ClipboardButton className="content-share-item content-share-link" value="http://www.covid19factcheck.com/es/" success="¡El enlace ha sido copiado! 🎉"><img className="fit-height" src="/assets/img/icons/link.svg" /></ClipboardButton>
          </div>
        </div>
        <h5 className="content-footnote">La mayoría de la información proviene de la <a href="https://www.who.int/" target="_blank">OMS</a> y el <a href="https://www.cdc.gov/" target="_blank">CDC</a>. Última actualización realizada 05/18/20.</h5>
      </div>
    )
  }
}

export default withRouter(Aside)