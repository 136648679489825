import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Myth extends Component {

  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <div className="content-nav-scroller">
            <h4 className="content-nav-caption">목차</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-1')}>마스크를 쓰면 6피트 거리두기를 시행하지 않아도 되나요?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-10')}>알코올 소독약이나 염소 소독제를 몸에 뿌리면 바이러스를 죽일수있나요?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-2')}>수술용 마스크나 N95 특수 마스크를 써야 하나요?</h4>
            {/* <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-3')}>COVID-19는 공기중 감염이 되나요?</h4> */}
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-4')}>장갑 착용으로 바이러스를 100 퍼센트 예방할 수 있나요?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-5')}>코로나 바이러스는 며칠 후에도 다양한 물건 표면에 남아있나요?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-6')}>집안에만 머물 경우 손 세척이나 표면소독을 할 필요는 없나요?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-7')}>확진자가 이부프로펜(애드빌, 모트린)을 복용하면 병이 더 악화될 수 있나요?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-8')}>하이드록시클로르퀸과 아지스로마이신 자가치료로 코로나바이러스를 예방할 수 있나요?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-9')}>항생제가 COVID-19 예방과 치료에 효과적인가요?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-11')}>젊고 건강한 개인은 신종 코로나 바이러스로부터 안전한가요?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-12')}>COVID-19 확진을 받으면 무조건 입원을 해야 하나요?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-13')}>10초 이상 기침이나 불편 없이 호흡을 참을 수 있으면 COVID-19 감염인지 확인할수있나요?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-14')}>손 건조기나 자외선 소독기로 코로나 바이러스를 죽일 수 있나요?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-15')}>규칙적인 비강(코안)의 생리식염수 세척으로 신종 코로나바이러스를 예방할 수 있나요?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-16')}>뜨거운 물에서의 샤워나 목욕으로 COVID-19를 예방할 수 있나요?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-17')}>추운 날씨와 눈이 바이러스를 죽일 수 있나요?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-18')}>마늘의 섭취로 신종 코로나바이러스를 예방할 수 있나요?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-19')}>알코올 섭취로 신종 코로나바이러스를 예방할 수 있나요?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-20')}>폐렴백신으로 신종 코로나바이러스를 예방할 수 있나요?</h4>
          </div>
        </div>
        <div className="content-body">
          <Card 
            id="myth-1"
            thumbnail="/assets/img/shots/myth/myth-1.jpg" 
            title="마스크를 쓰면 6피트 (2미터) 거리두기를 시행하지 않아도 되나요?"
            content={
              <div>
                <p><span className="card-tag-false">아니요</span>마스크를 쓰기 전, 쓰고 있는 동안, 벗은 후 등 언제든지 6피트 거리두기, 손 씻기는 계속해서 시행하세요.</p>
                <p><a href="https://korean.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover-faq.html" target="_blank">미국 질병관리본부(CDC)</a>는 미국 시민에게 천 마스크 사용을 권장하고 있습니다. 마스크는 기침, 재채기, 대화중에 나오는 비말을 막을 것으로 예상되나 마스크의 종류와 재질에 따라 효과가 일정하지 않을 수 있습니다. “예방 조치 안내” 페이지를 참고하세요.</p>
              </div>}
            extra={
              <div>
                <p>마스크를 착용할 시 틈새가 없으면서 숨쉬기 편안하게 잘 착용하는 것이 중요하며 마스크 착용 후에도 <a href="https://korean.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">미국 질병관리본부(CDC)</a>의 권고안인 거리두기와 손씻기를 계속 시행하여야 합니다. 여러 겹의 천이 좋으나 CDC에서 특별한 천의 종류는 추천하지 않았습니다.</p>
                <p><div className="paragraph-header">천 마스크는 코로나 바이러스 예방에 얼마나 효과적인가요?</div>현재 이에 대한 직접적인 과학적 데이터는 없습니다. 한 <a href="https://www.ncbi.nlm.nih.gov/pubmed/24229526" target="_blank">연구결과</a>에 따르면 집안에서 찾을 수 있는 다양한 천을 사용하여 만든 마스크를 사용할 경우 50-83퍼센트에서만 바이러스를 막는다고 하나 이 시뮬레이션은 사람의 기침과 다르며 마스크를 제대로 사용하는 지의 변수가 고려되지 않은 결과입니다. 또한 대부분의 마스크는 얼굴 표면과 <a href="https://www.gannett-cdn.com/experiments/usatoday/gifs/tool/919-surgical.png" target="_blank">마스크 사이</a>를 견고하게 밀접시켜주는 효과가 부족하여 그 사이로 바이러스가 침투할 수 있는 위험성이 있습니다.</p>
                <p><div className="paragraph-header">요점</div>천 마스크는 코로나바이러스 감염 예방에 어느 정도 도움이 되나 100%의 예방 효과는 없습니다.</p>
              </div>}
          />
          <Card 
            id="myth-10"
            thumbnail="/assets/img/shots/myth/myth-10.jpg" 
            title="알코올 소독약이나 염소 소독제를 몸에 뿌리면 바이러스를 죽일수있나요?"
            content={
              <div>
                <p><span className="card-tag-false">아니요</span>알코올 소독약이나 염소소독제 (클로린, 락스)는 몸에 유해하므로 <strong>절대로</strong> 주사하거나, 마시거나, 몸에 뿌리지 마십시오.</p>
                <p>세계보건기구(WHO)에 따르면, 이미 체내에 들어간 바이러스는 소독제로 죽일 수 없습니다. 게다가 이러한 물질들은 인체에 유해할 수 있습니다 (특히 눈, 코). 알코올, 염소는 여러가지 표면 소독에 효과적일 수 있으나, 쓰실 경우 꼭 <a href="https://korean.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cleaning-disinfection.html" target="_blank">적절한 권고</a>를 참고해 주십시오.</p>
              </div>}
          />
          <Card 
            id="myth-2"
            thumbnail="/assets/img/shots/myth/myth-2.jpg" 
            title="수술용 마스크나 N95 특수 마스크를 써야 하나요?"
            content={
              <div>
                <p><span className="card-tag-false">아니요</span><a href="https://korean.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover-faq.html" target="_blank">미국 질병관리본부(CDC)</a>는 미국 시민에게 <strong>천 마스크</strong> 사용을 권장하고 있습니다.</p>
                <p>기존에 가지고 있던 마스크는 사용하되 따로 수술용 마스크나 N95 특수 마스크를 구매하지 마세요. 상기 마스크들은 의료용 마스크로써 의료 종사자들을 위해 남겨두어야 합니다. 의료인들은 직접 환자들과 접촉하기 때문에 마스크등의 보호장비가 매우 중요하며 특수 마스크의 올바른 착용에 대한 교육을 받습니다. 현재 국가적으로 보호장비가 부족한 상태이니 가능하면 근처 병원에 수술용 마스크와 N95 마스크를 기부하는것을 추천합니다. N95 마스크는 공기중 감염(airborne transmission)인 경우에 필요하나 코로나 바이러스의 주 전파경로는 비말(respiratory droplets)로 보고되고 있으니 일반인의 경우 굳이 특수 마스크를 착용할 필요는 없습니다.</p>
              </div>}
            extra={
              <div>
                <p><a href="https://www.fda.gov/medical-devices/personal-protective-equipment-infection-control/n95-respirators-and-surgical-masks-face-masks" target="_blank">N95 특수 마스크</a>는 매우 작은(0.3마이크론) 입자를 95% 막을 수 있으나 착용시 마스크와 얼굴 표면 사이에 공기가 들어가지 않도록 밀접하게 주의해서 착용해야 합니다. 의료종사자들은 매년 착용관련 교육을 받고 있으며 얼굴에 맞는 크기의 마스크를 검사받고 있습니다. 제대로 착용할 경우 호흡이 불편할 수 있습니다. <a href="https://blogs.cdc.gov/niosh-science-blog/2018/01/04/respirators-public-use/" target="_blank">일반인</a>의 경우 이런 교육과 <a href="https://www.safetyalliance.org/wp-content/uploads/2019/06/fit-test-2-000110_retouched-672x372.jpg" target="_blank">fit-testing</a> 검사를 할 수 없기 때문에 제대로 된 보호기능이 없을 수 있습니다.</p>
              </div>}
          />
          {/* <Card 
            id="myth-3"
            thumbnail="/assets/img/shots/myth/myth-3.jpg" 
            title="COVID-19는 공기중 감염이 되나요?"
            content={
              <div>
                <p><span className="card-tag-maybe">가능성이 있습니다</span>하지만 일반적인 환경에서 공기중 전파의 가능성은 매우 낮습니다.</p>
                <p><a href="https://www.who.int/news-room/q-a-detail/q-a-coronaviruses" target="_blank">세계보건기구(WHO)</a>에 의하면 주로 감염자의 기침, 재채기, 대화중 생성되는 비말에 의한 개인간의 감염으로 보고 있습니다. 비말은 공기중에 부유하기에는 무겁기 때문에 땅, 바닥, 여러가지 물건의 표면으로 빠르게 떨어지게 됩니다. 그래서 6 피트 이상의 거리두기와 이런 표면과의 접촉후 손을 비누로 씻는 것이 매우 중요합니다. 중국의 75,465명의 환자들에 대한 연구결과에 의하면 공기 전파감염은 없었다고 합니다. 하지만 병원에서 기도삽관 등이 이루어지는 <a href="https://www.who.int/news-room/commentaries/detail/modes-of-transmission-of-virus-causing-covid-19-implications-for-ipc-precaution-recommendations" target="_blank">특수한 상황</a>에서는 공기 전염의 가능성이 있습니다.</p>
                </div>}
            extra={
              <div>
                <p><div className="paragraph-header">공기중 감염이란?</div>비말과 달리 공기중 입자는 더 작고 공기에 더 오랫동안 부유하고 있기 때문에 6피트 이상으로도 전파됩니다.</p>
                <p><div className="paragraph-header">뉴스에서는 왜 코로나바이러스가 공기중 감염이 가능하다고 합니까?</div>뉴잉글랜드 의학저널에 발표된 한 <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973" target="_blank">연구</a>에 따르면 특수 실험장치로 코로나바이러스를 에어로졸화한 결과 바이러스가 3시간 동안 공기중에 생존해 있었습니다. 하지만 이 고성능의 특수기계가 사람의 재채기와는 다릅니다. 아직 입증되지 않은 (다른 전문가의 심사를 거치지 않은) 한 <a href="https://www.medrxiv.org/content/10.1101/2020.03.23.20039446v2" target="_blank">연구</a>에서는 COVID-19 환자가 입원한 13개 병실내에 있는 물건들 중 76.5%에서 바이러스가 탐지되었습니다 (핸드폰, 변기, 리모콘, 침대프레임 등). 또한 샘플로 채취한 공기중에서 바이러스가 63.2% 탐지되었는데, 그중 몇몇 샘플은 환자에게서 6피트 이상 떨어진 곳의 공기도 채취한 것이었습니다. 하지만 바이러스가 탐지되었다는 것이 반드시 질병을 유발시킨다는 것을 의미하는 것은 아닙니다. 저자에 따르면 이 샘플들에서 발견된 바이러스는 양이 적음으로 감염을 시킬 정도는 아닙니다.</p>
              </div>}
          /> */}
          <Card 
            id="myth-4"
            thumbnail="/assets/img/shots/myth/myth-4.jpg" 
            title="장갑 착용으로 바이러스를 100 퍼센트 예방할 수 있나요?"
            content={
              <div>
                <p><span className="card-tag-false">아니요</span>장갑을 착용해도 여전히 눈, 코, 입을 만지지 않도록 조심해야 합니다. 장갑을 벗을 때도 주의해서 제대로 벗어야 하며 벗은 후 반드시 손을 세척하십시오. 오염된 장갑으로 만지는 표면에 바이러스를  묻힐 수 있다는 것을 염두에 두셔야 합니다.</p>
              </div>}
          />
          <Card 
            id="myth-5"
            thumbnail="/assets/img/shots/myth/myth-5-ko.jpg" 
            title="코로나 바이러스는 며칠 후에도 다양한 물건 표면에 남아있나요?"
            content={
              <div>
                <p><span className="card-tag-true">예</span>뉴잉글랜드 의학저널의 한 <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973?query=featured_home" target="_blank">연구</a>에 의하면 종이박스에서는 바이러스가 최장 24시간까지, 비닐, 플라스틱, 금속 표면에서는 최장 3일까지 발견이 된다고 합니다.</p>
                <p>발견된 바이러스의 양은 최초 양의 0.1 퍼센트보다 적은데 이는 바이러스가 표면에서 급속히 사멸하기 때문이며 이론적으로는 시간이 지남에 따라 감염의 가능성은 점점 없어진다고 볼 수 있습니다.</p>
              </div>}
          />
          <Card 
            id="myth-6"
            thumbnail="/assets/img/shots/myth/myth-6.jpg" 
            title="집안에만 머물 경우 손 세척이나 표면소독을 할 필요는 없나요?"
            content={
              <div>
                <p><span className="card-tag-false">아니요</span><a href="https://korean.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/prevention.html" target="_blank">미국 질병관리본부(CDC)</a>에 의하면 실내에서도 수시로 접촉하는 표면(탁자, 문 손잡이, 전등 스위치, 싱크대, 핸드폰, 키보드, 수도꼭지)을 깨끗하게 유지하고 소독 하기를 권장하고 있습니다.</p>
                <p>본인이나 가족중에 감염된 사람이 있다면 바이러스를 배출하고 있을 가능성이 있습니다. 코로나 바이러스에 의한 감염은 직접적인 환자와의 접촉으로 일어날 가능성이 더 높지만 물체의 표면에서 바이러스가 몇시간에서 약 3일까지 생존할 수 있기때문에 표면소독과 손 세척은 꾸준히 하셔야합니다.</p>
              </div>}
          />
          <Card 
            id="myth-7"
            thumbnail="/assets/img/shots/myth/myth-7.jpg" 
            title="확진자가 이부프로펜(애드빌, 모트린)을 복용하면 병이 더 악화될 수 있나요?"
            content={
              <div>
                <p><span className="card-tag-false">아니요</span>현재 이부프로펜이 COVID-19 환자들의 증상을 악화시킨다는 충분한 과학적 근거는 없기 때문에 <a href="https://twitter.com/WHO/status/1240409217997189128" target="_blank">세계보건기구(WHO)</a>의 이부프로펜 사용금지 권고안은 3월 18일자로 더 이상 유효하지 않습니다</p>
                <p>해열제와 진통제로 아세트아미노펜(타이레놀)을 복용할 수 있습니다. <a href="https://www.fda.gov/drugs/drug-safety-and-availability/fda-advises-patients-use-non-steroidal-anti-inflammatory-drugs-nsaids-covid-19" target="_blank">미국 식품의학청(FDA)</a>에 따르면 현재 가지고 있는 만성질환 때문에 NSAIDs(아스피린, 이부프로펜, 나프록센 등)를 복용하고 있다면 담당 주치의와 상의하시길 권고하고 있습니다.</p>
                </div>}
            extra={
            <div>
              <p>아스피린, 이부프로펜, 나프록센(알리브), 디클로페낙(캄비아), 셀레코시브(셀레브렉스) 등을 비스테로이드성 소염진통제 <a href="https://www.goodrx.com/blog/are-advil-motrin-tylenol-bad-for-coronavirus-symptoms/" target="_blank">(NSAIDS)</a>라 하며 주로 해열, 진통, 염증완화의 효과가 있는 약물입니다. NSAIDs의 부작용으로는 신부전, 위장 출혈등이 있습니다. 아세트아미노펜은 과다복용시 간부전을 일으킬 수 있습니다. 처방전 없이 살 수 있는 OTC 약에 자주 포함되는 성분이니 약물 라벨을 꼭 읽어보시고 과다복용하지 않도록 주의하십시오.</p>
              <p><div className="paragraph-header">이부프로펜 논란이 시작된 계기:</div>한 <a href="https://jvi.asm.org/content/94/7/e00127-20" target="_blank">연구</a> 에 따르면 인체의 효소인 안지오텐신 전환요소2(ACE2)가 코로나 바이러스의 세포진입을 도울수있다는 결론을 지었습니다.</p>
              <p>연구자들은 이부프로펜이 ACE2의 발현을 촉진시켜 COVID-19 감염 증상을 악화시킬 수 있다는 가설을 3월 11일 <a href="https://www.thelancet.com/journals/lanres/article/PIIS2213-2600(20)30116-8/fulltext" target="_blank">란셋 호흡기 의학저널</a>에 소개한 바 있습니다.</p>
              <p>하지만 이부프로펜이 COVID-19 감염증 예후를 악화시킨다는 가설은 과학적으로 입증되지 않았습니다. 그럼에도 불구하고 프랑스 보건부 장관은 이부프로펜이나 다른 NSAIDs가 COVID-19감염을 악화시킬수 있다며 3월 14일날 <a href="https://twitter.com/olivierveran/status/1238776545398923264" target="_blank">트위터</a>를 통해 경고했습니다. 이러한 정보는 곳곳 뉴스를 통해 보도가 되면서 이부프로펜이 COVID-19 관련 사망 주 원인이다라는 이야기까지 나오게 됐습니다. 처음 가설을 세운 연구자들은 자신들의 연구가 <a href="https://www.unibas.ch/en/News-Events/News/Uni-Research/Ibuprofen-and-COVID-19-Setting-the-record-straight.html" target="_blank">특정 약물 사용을 추천하는 것이 아니라고</a> 3월 16일에 밝혔습니다.</p>
              <p>세계보건기구(WHO)는 COVID-19의 증상을 보이는 환자들에게 이부프로펜이나 NSAIDs를 처방하지 말 것을 처음 권고하였으나, 3월 18일 <a href="https://www.ema.europa.eu/en/news/ema-gives-advice-use-non-steroidal-anti-inflammatories-covid-19" target="_blank">유럽의학품청(EMA)</a>, <a href="https://www.fda.gov/drugs/drug-safety-and-availability/fda-advises-patients-use-non-steroidal-anti-inflammatory-drugs-nsaids-covid-19" target="_blank">미국 식품의학청(FDA)</a>, <a href="https://twitter.com/WHO/status/1240409217997189128" target="_blank">세계보건기구(WHO)</a>는 필요에 따라 NSAIDs를 처방할 것을 권했습니다.</p>
            </div>}
          />
          <Card 
            id="myth-8"
            thumbnail="/assets/img/shots/myth/myth-8.jpg" 
            title="하이드록시클로르퀸과 아지스로마이신 자가치료로 코로나바이러스를 예방할 수 있나요?"
            content={
              <div>
                <p><span className="card-tag-false">아니요</span><strong>절대</strong> 자가 복용하지 마십시오. 해로운 약물간 상호작용이나 약물 자체의 심각한 부작용이 있을 수 있으니, 반드시 담당의사와 상담후 필요할 경우 처방을 받아서 복용해야 합니다.</p>
                <p><a href="https://www.cdc.gov/coronavirus/2019-ncov/hcp/therapeutic-options.html" target="_blank">하이드록시클로르퀸과 클로르퀸</a>은 경구 말라리아 치료제 또는 자가면역 질환 치료제입니다. 아지스로마이신은 항생제로 다양한 박테리아 감염 치료제입니다. 하이드록시클로르퀸과 아지스로마이신은 심전도상 QT 간격을 연장시킴으로써 생명을 위협하는 심부정맥을 일으킬 수 있습니다. 효능에 대한 연구가 현재 진행되고 있으니 처방을 받은 약물만 복용하십시오.</p>
              </div>}
          />
          <Card 
            id="myth-9"
            thumbnail="/assets/img/shots/myth/myth-9.jpg" 
            title="항생제가 COVID-19 예방과 치료에 효과적인가요?"
            content={
              <div>
                <p><span className="card-tag-false">아니요</span><a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">세계보건기구(WHO)</a>에 의하면, 항생제는 박테리아 치료제이며 바이러스를 죽이지 못합니다. COVID-19는 바이러스에 의한 질환이므로 항생제는 이 질환의 예방, 치료의 목적으로는 사용되지 않습니다. 다만 이 질환과 함께 박테리아에 의한 감염이 병행되는 경우 의료진의 판단하에 투여되기도 합니다.</p>
              </div>}
          />
          <Card 
            id="myth-11"
            thumbnail="/assets/img/shots/myth/myth-11.jpg" 
            title="젊고 건강한 개인은 신종 코로나 바이러스로부터 안전한가요?"
            content={
              <div>
                <p><span className="card-tag-false">아니요</span><a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">세계보건기구(WHO)</a>에 의하면 모든 연령층에서 감염이 일어날 수 있다고 합니다. 노년층 아니면 천식, 당뇨, 심장 질환같은 기저 질환이 있는 환자의 경우, 감염되었을 때 증상이 더 심하게 나타날 확률이 높습니다. 연령층과 상관 없이 모두에게 거리두기와 손 세척 등의 방지책을 권고합니다.</p>
              </div>}
            extra={
              <div>
                <p>2월12일부터 3월16일까지 미국에서 발생한 4,226명의 환자를 <a href="https://www.cdc.gov/mmwr/volumes/69/wr/mm6912e2.htm#F2_down" target="_blank">분석</a>한 결과, 입원을 한 환자 5명중 1명은 20~44세 사이 였다고 합니다. 현재 4월12일까지의 통계에 의하면 <a href="https://www.cdph.ca.gov/Programs/CID/DCDC/Pages/Immunization/ncov2019.aspx" target="_blank">캘리포니아</a>에서 발생한 코로나바이러스 환자의 반 정도가 50세 미만으로 밝혀졌습니다.  비록 젊은 환자군에서 확진자별 사망률은 낮은 것으로 보고되고 있지만, 기저질환이 없는데도 사망하는 경우가 많기 때문에 모든 연령층의 주의가 필요합니다.</p>
              </div>}
          />
          <Card 
            id="myth-12"
            thumbnail="/assets/img/shots/myth/myth-12.jpg" 
            title="COVID-19 확진을 받으면 무조건 입원을 해야 하나요?"
            content={
              <div>
                <p><span className="card-tag-false">아니요</span><a href="https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/testing.html" target="_blank">미국 질병관리본부(CDC)</a>에 따르면 감염된 대부분의 사람은 증상이 경미하고 병원 치료 없이 집에서 회복할 수 있다고 합니다. 병원 방문 외에는 외출을 삼가 하십시오. 호흡하는데에 어려움이 있으면 병원에 직접 문의하되 꼭 전화로 먼저 연락하십시오. “COVID-19 란?” 페이지에서 더 자세한 정보를 얻어가세요.</p>
              </div>}
          />
          <Card 
            id="myth-13"
            thumbnail="/assets/img/shots/myth/myth-13.jpg" 
            title="10초 이상 기침이나 불편 없이 호흡을 참을 수 있으면 COVID-19 감염인지 확인할수있나요?"
            content={
              <div>
                <p><span className="card-tag-false">아니요</span><a href="https://korean.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover.html" target="_blank">미국 질병관리본부(CDC)</a>에 따르면 감염이 되어도 증상이 없을 수 있으며, 증상이 나타나기 전에도 다른 이에게 바이러스 전파를 할 수 있습니다. 또한 WHO에 의하면 “가장 흔한 증상은 마른 기침, 피로감, 그리고 발열이며, 일부의 감염자는 폐렴 같은 더 심한 형태의 질환이 나타날 수 있습니다.” 자가 호흡 테스트로는 절대로 감염 상태를 알 수 없습니다.</p>
              </div>}
          />
          <Card 
            id="myth-14"
            thumbnail="/assets/img/shots/myth/myth-14.jpg" 
            title="손 건조기나 자외선 소독기로 코로나 바이러스를 죽일 수 있나요?"
            content={
              <div>
                <p><span className="card-tag-false">아니요</span><a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">세계보건기구(WHO)</a>에 의하면 손 건조기는 바이러스에 전혀 효과가 없고, 자외선 소독기는 피부 손상을 일으킬 수 있기에 손, 피부를 소독하기 위해 쓰이면 안됩니다. 대신 비누와 물로 손을 씻거나 알코올 소독제(hand sanitizer)를 수시로 써서 손을 깨끗이 유지하는 것이 매우 중요합니다.</p>
              </div>}
          />
          <Card 
            id="myth-15"
            thumbnail="/assets/img/shots/myth/myth-15.jpg" 
            title="규칙적인 비강(코안)의 생리식염수 세척으로 신종 코로나바이러스를 예방할 수 있나요?"
            content={
              <div>
                <p><span className="card-tag-false">아니요</span>일반적인 감기인 경우엔 조금 더 빨리 회복하도록 도움을 준다는 한정된 연구 결과는 있으나, 비강 세척은 코로나 바이러스 예방이나 회복에 도움이 된다는 근거가 없습니다. <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">세계보건기구(WHO)</a>에 의하면 다른 호흡기 질환에도 도움을 주지 않는것으로 보입니다.</p>
              </div>}
          />
          <Card 
            id="myth-16"
            thumbnail="/assets/img/shots/myth/myth-16.jpg" 
            title="뜨거운 물에서의 샤워나 목욕으로 COVID-19를 예방할 수 있나요?"
            content={
              <div>
                <p><span className="card-tag-false">아니요</span>샤워나 목욕은 평상시처럼 하시되 일부러 매우 뜨거운 물을 쓰지 마십시오. <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">세계보건기구(WHO)</a>에 의하면 뜨거운 물에서의 목욕이나 샤워와 상관없이 인체의 체온은 36.5도에서 37도로 유지됩니다. 사실상 너무 뜨거운 온도의 물을 사용하는 것은 화상의 위험이 있으므로 조심해야 합니다.</p>
              </div>}
          />
          <Card 
            id="myth-17"
            thumbnail="/assets/img/shots/myth/myth-17.jpg" 
            title="추운 날씨와 눈이 바이러스를 죽일 수 있나요?"
            content={
              <div>
                <p><span className="card-tag-false">아니요</span><a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">세계보건기구(WHO)</a>에 의하면 추운 날씨와 눈이 바이러스를 죽일 수 없습니다. 외부 온도와 상관없이 인체의 체온은 36.5도에서 37도로 유지됩니다.</p>
              </div>}
          />
          <Card 
            id="myth-18"
            thumbnail="/assets/img/shots/myth/myth-18.jpg" 
            title="마늘의 섭취로 신종 코로나바이러스를 예방할 수 있나요?"
            content={
              <div>
                <p><span className="card-tag-false">아니요</span><a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">세계보건기구(WHO)</a>에 의하면 마늘이 어느 정도의 항균의 효과가 있는 건강에 좋은 음식이긴 하나 신종 코로나바이러스를 예방할 수 있다는 과학적 근거는 없습니다.</p>
              </div>}
          />
          <Card 
            id="myth-19"
            thumbnail="/assets/img/shots/myth/myth-19.jpg" 
            title="알코올 섭취로 신종 코로나바이러스를 예방할 수 있나요?"
            content={
              <div>
                <p><span className="card-tag-false">아니요</span><a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">세계보건기구(WHO)</a>에 의하면 알코올 섭취는 신종 코로나바이러스를 예방할 수 없으며 위험할 수 있습니다. 과한 알코올 섭취는 건강에 여러가지 해를 줄 수 있으니 주의하십시오.</p>
              </div>}
          />
          <Card 
            id="myth-20"
            thumbnail="/assets/img/shots/myth/myth-20.jpg" 
            title="폐렴백신으로 신종 코로나바이러스를 예방할 수 있나요?"
            content={
              <div>
                <p><span className="card-tag-false">아니요</span><a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">세계보건기구(WHO)</a>,에 의하면 폐렴구군 백신이나 헤모필러스 인플루엔자 타입 B 백신과 같은 폐렴백신으로 신종 코로나바이러스를 예방할 수 없습니다. 신종 코로나바이러스는 새로운 바이러스이며 자체 바이러스에 대한 백신은 아직 없으며 현재 여러 연구 단체에서 개발에 박차를 가하고 있습니다. 하지만 여러 호흡기 질환을 예방하기 위해 이러한 폐렴 백신의 접종이 여전히 권고되고 있습니다.</p>
              </div>}
          />
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Myth;
