import React, { Component } from 'react'
import classnames from 'classnames'
import { withRouter } from 'react-router-dom'

import Button from './Button'

class Card extends React.PureComponent {

  state = {
    showExtra: false
  }

  toggleExtra = () => {
    this.setState({
      showExtra: !this.state.showExtra,
    });
  }

  render() {
    const {showExtra} = this.state
    const {id, icon, thumbnail, title, content, extra, location} = this.props
    const language = location.pathname.slice(1, 3)

    let learnMore = 'Learn More'
    let collapse = 'Collapse'

    if (language === 'vn') {
      learnMore = 'Tìm hiểu thêm'
    } else if (language === 'es') {
      learnMore = 'Aprenda más'
    } else if (language === 'ko') {
      learnMore = '자세히 보기'
    } else if (language === 'cn') {
      learnMore = '了解更多'
    } else if (language === 'tw') {
      learnMore = '了解更多'
    } else if (language === 'pa') {
      learnMore = 'ਹੋਰ ਜਾਣਕਾਰੀ ਲਈ'
    } else if (language === 'ar') {
      learnMore = 'أعرف أكثر'
    } else if (language === 'ru') {
      learnMore = 'Узнать больше'
    } else if (language === 'am') {
      learnMore = 'በተጨማሪ'
    } else if (language === 'tl') {
      learnMore = 'Karagdagang kaalaman'
    } else if (language === 'to') {
      learnMore = 'Fakamatala tānaki'
    } else if (language === 'tp') {
      learnMore = 'Lainim moa'
    } else if (language === 'bi') {
      learnMore = 'Save moa'
    }

    if (language === 'vn') {
      collapse = 'Giấu'
    } else if (language === 'es') {
      collapse = 'Minimizar'
    } else if (language === 'ko') {
      collapse = '숨기기'
    } else if (language === 'cn') {
      collapse = '关闭分页'
    } else if (language === 'tw') {
      collapse = '關閉分頁'
    } else if (language === 'pa') {
      collapse = 'ਜਾਣਕਾਰੀ ਨੂੰ ਲੁਕਾਓ'
    } else if (language === 'ar') {
      collapse = 'إخفاء'
    } else if (language === 'ru') {
      collapse = 'Спрятать'
    } else if (language === 'am') {
      collapse = 'መደበቅ'
    } else if (language === 'tl') {
      collapse = 'Itago'
    } else if (language === 'to') {
      collapse = 'Fakasi’isi’i'
    } else if (language === 'tp') {
      collapse = 'Haitim'
    } else if (language === 'bi') {
      collapse = 'Haedem'
    }

    return (
      <div id={id} className={classnames('card', {'card-active': showExtra})}> 
      {icon ? <div className="card-icon"><img className="fit-parent" src={icon} /></div> : null}
      {thumbnail ? <div className="card-thumbnail"><img className="fit-width" src={thumbnail} /></div> : null}
        <h3 className="card-title">{title}</h3>
        <div className="card-body">
          <div className="card-content">{content}</div>
          {showExtra ? <div className="card-extra">{extra}</div> : null}
        </div>
        {extra ? 
        <Button className="btn-tertiary card-toggle" label={showExtra ? collapse : learnMore} imgURL="/assets/img/icons/arrow_right.svg" onClick={this.toggleExtra} />
        : null}
      </div>
    )
  }
}

export default withRouter(Card)