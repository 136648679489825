import React, { Component } from 'react'
import classnames from 'classnames'

import Button from '../Components/Button'
import ClipboardButton from '../Components/ClipboardButton'

class ShareModal extends Component {

  componentDidMount() {
    window.Kakao.init('8c22c0e3b6141547b03badeeb6f137cd');
  }

  sendKakao = (e) => {
    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: 'COVID-19 Fact Check',
        description: '올바른 정보가 예방의 시작입니다.',
        imageUrl:
          'http://www.covid19factcheck.com/assets/img/thumbnails/social_square.png',
        link: {
          mobileWebUrl: 'http://www.covid19factcheck.com/ko/',
          androidExecParams: 'test',
        },
      },
      success: function(response) {
        console.log(response);
      },
      fail: function(error) {
        console.log(error);
      }
    });
  }

  render() {
    const { active, toggleShareModalActive } = this.props;

    return (
      <div className={classnames('modal', 'share-modal', {'modal-active': active})}>
        <div className="modal-close" onClick={toggleShareModalActive}><img className="fit-parent" src="/assets/img/icons/close.svg" /></div>
        <div className="modal-content">
          <h1 className="modal-title">코로나바이러스 확산을 예방하는데 동참하세요!</h1>
          <p className="modal-caption">COVID-19 Fact Check를 주변 사람들에게 공유하세요. 더 많은 사람들이 정확한 정보를 알수록 코로나바이러스에 더 잘 대응할 수 있습니다.</p>
          <div className="share-modal-action-container">
            <div className="share-modal-action">
              <a href="https://twitter.com/intent/tweet?text=%EC%BD%94%EB%A1%9C%EB%82%98%20%EB%B0%94%EC%9D%B4%EB%9F%AC%EC%8A%A4%EC%97%90%20%EB%8C%80%ED%95%9C%20%ED%99%95%EC%8B%A4%ED%95%9C%20%EC%A0%95%EB%B3%B4%2C%20COVID-19%20Fact%20Check%EB%A5%BC%20%ED%86%B5%ED%95%B4%EC%84%9C%20%EC%95%8C%EC%95%84%EB%B3%B4%EC%84%B8%EC%9A%94%21&tw_p=tweetbutton&url=http%3A%2F%2Fwww.covid19factcheck.com%2Fko%2F" target="_blank">
                <img className="fit-parent" src="/assets/img/icons/share/twitter.png" />
              </a>
            </div>
            <div className="share-modal-action">
              <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=http://www.covid19factcheck.com/ko/" target="_blank">
                <img className="fit-parent" src="/assets/img/icons/share/facebook.png" />
              </a>
            </div>
            <div className="share-modal-action">
              <a href="https://wa.me/?text=%EC%BD%94%EB%A1%9C%EB%82%98%20%EB%B0%94%EC%9D%B4%EB%9F%AC%EC%8A%A4%EC%97%90%20%EB%8C%80%ED%95%9C%20%ED%99%95%EC%8B%A4%ED%95%9C%20%EC%A0%95%EB%B3%B4%2C%20COVID-19%20Fact%20Check%EB%A5%BC%20%ED%86%B5%ED%95%B4%EC%84%9C%20%EC%95%8C%EC%95%84%EB%B3%B4%EC%84%B8%EC%9A%94%21%20http%3A%2F%2Fwww.covid19factcheck.com%2Fko%2F" data-action="share/whatsapp/share" target="_blank">
                <img className="fit-parent" src="/assets/img/icons/share/whatsapp.png" />
              </a>
            </div>
            <div className="share-modal-action">
              <a onClick={this.sendKakao}>
                <img className="fit-parent" src="/assets/img/icons/share/kakaotalk.png" />
              </a>
            </div>
            <div className="share-modal-action">
                <a href="https://social-plugins.line.me/lineit/share?url=http%3A%2F%2Fwww.covid19factcheck.com%2Fko%2F" target="_blank">
                  <img className="fit-parent" src="/assets/img/icons/share/line.png" />
                </a>
            </div>
            <div className="share-modal-action">
              <a href="mailto:?body=%EC%BD%94%EB%A1%9C%EB%82%98%20%EB%B0%94%EC%9D%B4%EB%9F%AC%EC%8A%A4%EC%97%90%20%EB%8C%80%ED%95%9C%20%ED%99%95%EC%8B%A4%ED%95%9C%20%EC%A0%95%EB%B3%B4%2C%20COVID-19%20Fact%20Check%EB%A5%BC%20%ED%86%B5%ED%95%B4%EC%84%9C%20%EC%95%8C%EC%95%84%EB%B3%B4%EC%84%B8%EC%9A%94%21%20http%3A%2F%2Fwww.covid19factcheck.com%2Fko%2F">
                <img className="fit-parent" src="/assets/img/icons/share/mail.png" />
              </a>
            </div>
          </div>
          <ClipboardButton value="http://www.covid19factcheck.com/ko/" success="링크가 복사되었습니다! 🎉">
            <Button className="btn-share" label="링크 복사하기" imgURL="/assets/img/icons/link.svg" />
          </ClipboardButton>
        </div>
      </div>
    )
  }
}

export default ShareModal