import React, { Component } from 'react'
import classnames from 'classnames'

import Button from '../Components/Button'
import ClipboardButton from '../Components/ClipboardButton'

class ShareModal extends Component {

  componentDidMount() {
    window.Kakao.init('8c22c0e3b6141547b03badeeb6f137cd');
  }

  sendKakao = (e) => {
    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: 'COVID-19 Fact Check',
        description: 'Xem trang COVID-19 Fact Check để phân biệt tin thật và tin giả về coronavirus.',
        imageUrl:
          'http://www.covid19factcheck.com/assets/img/thumbnails/social_square_vn.png',
        link: {
          mobileWebUrl: 'http://www.covid19factcheck.com/vn/',
          androidExecParams: 'test',
        },
      },
      success: function(response) {
        console.log(response);
      },
      fail: function(error) {
        console.log(error);
      }
    });
  }

  render() {
    const { active, toggleShareModalActive } = this.props;

    return (
      <div className={classnames('modal', 'share-modal', {'modal-active': active})}>
        <div className="modal-close" onClick={toggleShareModalActive}><img className="fit-parent" src="/assets/img/icons/close.svg" /></div>
        <div className="modal-content">
          <h1 className="modal-title">Gởi Thông Tin.</h1>
          <p className="modal-caption">Chia sẻ COVID-19 Fact Check với gia đình bạn bè để chặn sự lây lan của coronavirus.</p>
          <div className="share-modal-action-container">
            <div className="share-modal-action">
              <a href="https://twitter.com/intent/tweet?text=Xem%20trang%20COVID-19%20Fact%20Check%20%C4%91%E1%BB%83%20ph%C3%A2n%20bi%E1%BB%87t%20tin%20th%E1%BA%ADt%20v%C3%A0%20tin%20gi%E1%BA%A3%20v%E1%BB%81%20coronavirus.&tw_p=tweetbutton&url=http%3A%2F%2Fwww.covid19factcheck.com%vn%2F" target="_blank">
                <img className="fit-parent" src="/assets/img/icons/share/twitter.png" />
              </a>
            </div>
            <div className="share-modal-action">
              <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=http://www.covid19factcheck.com/vn/" target="_blank">
                <img className="fit-parent" src="/assets/img/icons/share/facebook.png" />
              </a>
            </div>
            <div className="share-modal-action">
              <a href="https://wa.me/?text=Xem%20trang%20COVID-19%20Fact%20Check%20%C4%91%E1%BB%83%20ph%C3%A2n%20bi%E1%BB%87t%20tin%20th%E1%BA%ADt%20v%C3%A0%20tin%20gi%E1%BA%A3%20v%E1%BB%81%20coronavirus.%20http%3A%2F%2Fwww.covid19factcheck.com%vn%2F" data-action="share/whatsapp/share" target="_blank">
                <img className="fit-parent" src="/assets/img/icons/share/whatsapp.png" />
              </a>
            </div>
            <div className="share-modal-action">
              <a onClick={this.sendKakao}>
                <img className="fit-parent" src="/assets/img/icons/share/kakaotalk.png" />
              </a>
            </div>
            <div className="share-modal-action">
                <a href="https://social-plugins.line.me/lineit/share?url=http%3A%2F%2Fwww.covid19factcheck.com%2Fvn%2F" target="_blank">
                  <img className="fit-parent" src="/assets/img/icons/share/line.png" />
                </a>
            </div>
            <div className="share-modal-action">
              <a href="mailto:?body=Xem%20trang%20COVID-19%20Fact%20Check%20%C4%91%E1%BB%83%20ph%C3%A2n%20bi%E1%BB%87t%20tin%20th%E1%BA%ADt%20v%C3%A0%20tin%20gi%E1%BA%A3%20v%E1%BB%81%20coronavirus.%20http%3A%2F%2Fwww.covid19factcheck.com%2Fvn%2F">
                <img className="fit-parent" src="/assets/img/icons/share/mail.png" />
              </a>
            </div>
          </div>
          <ClipboardButton value="http://www.covid19factcheck.com/vn/" success="Liên kết đã được sao chép! 🎉">
            <Button className="btn-share" label="Sao chép liên kết để chia sẻ" imgURL="/assets/img/icons/link.svg" />
          </ClipboardButton>
        </div>
      </div>
    )
  }
}

export default ShareModal