import React, { Component } from 'react'
import classnames from 'classnames'

import Button from '../Components/Button'

class About extends Component {

  render() {

    return (
      <div className="about">
        <div className="about-header grid">
          <h1 className="about-title">올바른 정보가 예방의 시작입니다.</h1>
          <p className="about-description">저희는 코로나바이러스에 대한 신뢰할 수 있는 정보를 공유하기위해 웹 엔지니어와 함께 이 웹사이트를 만든 UCSF 의대생 입니다.</p>
          <p className="about-description">잘못된 정보는 불안을 조성할 수 있으며 우리에게 오히려 피해를 입힐 수 있습니다. 더불어 인터넷 검색으로 찾아 볼 수 있는 특정 정보는 이해하기 어렵거나 일부 언어로만 제공됩니다. </p>
          <p className="about-description">따라서 저희는 이 웹사이트를 통해 정확하고 이해하기 쉬운 정보를 여러가지 언어로 제공함으로써 코로나바이러스를 예방하고 대응하고자 합니다.</p>
          <div className="about-disclaimer">
            <h3 className="about-disclaimer-caption">공지 사항</h3>
            <p className="about-disclaimer-content">저희는 코로나바이러스19 전문가는 아니지만 의대생으로써 배운 의학적 지식과 연구 해석 경험을 바탕으로 신뢰성이 높은 출처만 선정해 정확도가 높은 정보를 제공하기위해 노력했습니다. </p>
            <p className="about-disclaimer-content">웹사이트에 담긴 콘텐츠는 UCSF와 Discord의 공식 의견이 아님을 알려드립니다. 저희의 콘텐츠에 기여하고 검토 해주신 의사분들에게 감사드립니다.</p>
          </div>
          <div className="about-action desktop">
            <a href="mailto:contact@covid19factcheck.com" target="_blank"><div className="btn btn-primary about-action-btn">문의하기</div></a>
            <h4 className="about-action-caption">이메일: contact@covid19factcheck.com</h4>
          </div>
        </div>
        <div className="about-team">
          <h1 className="about-team-title">팀</h1>
          <div className="about-team-row grid">
            <div className="about-team-section">
              <h3 className="about-team-section-header">콘텐츠 제작</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Hallen Pham</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Fiona Ng</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Anuvir Singh</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">웹 디자인 & 개발</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Shawn Park</div>
                <h4 className="about-team-member-title">Staff Product Designer, Discord</h4>
                <h4 className="about-team-member-link">
                  <a href="https://designbyroka.com/" target="_blank">Website</a>
                  <a href="https://www.linkedin.com/in/shawn-park-9033a251/" target="_blank">Linkedin</a>
                </h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">암하라어 통역</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Beza Dagne</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Yetinayet Dagne</div>
                <h4 className="about-team-member-title">RN BSN, 2nd year Family Nurse Practitioner Student, SJSU</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">아랍어 통역</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Tessnim Ahmad, MD</div>
                <h4 className="about-team-member-title">UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Sarah Semaan</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Amer Alsoudi</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
          </div>
          <div className="about-team-row grid">
            <div className="about-team-section">
              <h3 className="about-team-section-header">중국어 통역</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Billy Zeng</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Fiona Ng</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Janette Tang</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Jackie Lin</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Alex Ge</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">한국어 통역</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Eric Kim</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">James Lim</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Eliana Kim</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Sally Oh</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">펀자브어 통역</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Anuvir Singh</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Prabhjot Kaur</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">러시아어 통역</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Liza Leykina</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Katerina Protsenko</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Fima Zaltsman, PhD</div>
                <h4 className="about-team-member-title">Postdoctoral Scholar, UCSF</h4>
              </div>
            </div>
          </div>
          <div className="about-team-row grid">
            <div className="about-team-section">
              <h3 className="about-team-section-header">스페인어 통역</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Alexis Velazquez</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Jessica Valdez</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Nicole Rodriguez</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Andres Calvillo</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Dani Montejano</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Sandra Perez</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Ivan Iniguez</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">타갈로그어 통역</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Joel Jose Quitlong Nario</div>
                <h4 className="about-team-member-title">3rd year Medical Student, Weill Cornell Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Francine Castillo</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">통가어 통역</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Ha’ano Fonua</div>
                <h4 className="about-team-member-title">5th year Medical Student, University of Auckland, NZ</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">톡 피신 통역</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Barbara Angoro</div>
                <h4 className="about-team-member-title">PhD Student, School of Pharmacy, University of Auckland</h4>
              </div>
            </div>
          </div>
          <div className="about-team-row grid">
            <div className="about-team-section">
              <h3 className="about-team-section-header">베트남어 통역</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Hallen Pham</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Kevin Pham</div>
                <h4 className="about-team-member-title">Molecular, Cell, and Developmental Biology, UCLA</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Kristine Au</div>
                <h4 className="about-team-member-title">De Anza College</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">그림</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Tammy Nguyen</div>
                <h4 className="about-team-member-link">
                  <a href="http://tamsanity.com/" target="_blank">Portfolio</a>
                  <a href="https://www.instagram.com/tamsanity/?hl=en" target="_blank">Instagram</a>
                  <a href="https://twitter.com/tamsanity_" target="_blank">Twitter</a>
                </h4>
              </div>
            </div>
          </div>
          <h4 className="about-team-thanks">통역 팀 멤버를 모으는데 큰 도움을 준 UCSF Asian Pacific American Medical Students Association (APAMSA), UCSF COVID Student Aid, Paul Park (MS4) 에게 감사를 표합니다.</h4>
        </div>
        <div className="about-action mobile">
          <a href="mailto:contact@covid19factcheck.com" target="_blank"><div className="btn btn-primary about-action-btn">문의하기</div></a>
          <h4 className="about-action-caption">이메일: contact@covid19factcheck.com</h4>
        </div>
      </div>
    )
  }
}

export default About