import React, { Component } from 'react'
import classnames from 'classnames'
import {
  BrowserRouter as Router,
  Link,
  Route,
  useLocation,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom'

import Button from '../Components/Button'

class Header extends React.PureComponent {

  render() {

    const { toggleLanguageModalActive, toggleShareModalActive, path } = this.props;

    return (
      <div className="header-container">
        <div className="header grid">
          <h1 className="header-title">COVID-19<br />Fact Check</h1>
          <div className="header-description">學會分辯事實與謠言，助你做好預防措施以及跟別人分享。</div>
          <div className="header-action mobile">
            <Button className="btn-secondary" label="中文-繁體" imgURL="/assets/img/icons/arrow_down.svg" onClick={toggleLanguageModalActive} />
            <Button className="btn-primary" label="分享網址" imgURL="/assets/img/icons/share.svg" onClick={toggleShareModalActive} />
          </div>
          <div className="header-caption mobile">
            <h5 className="header-caption-item">資料主要是來自美國疾病預防控制中心 <a href="https://www.cdc.gov/" target="_blank">(CDC)</a> 以及世界衛生組織 <a href="https://www.who.int/" target="_blank">(WHO)</a>的網站</h5>
            <h5 className="header-caption-item">網頁最後更新日期 05/18/20</h5>
          </div>
        </div>
        <div className="nav">
          <a href="/tw/" className={classnames('nav-item', {'nav-item-active': path === ''})}>分辨事實與謠言</a>
          <a href="/tw/tips" className={classnames('nav-item', {'nav-item-active': path === 'tips'})}>一些預防措施</a>
          <a href="/tw/learn" className={classnames('nav-item', {'nav-item-active': path === 'learn'})}>關於新冠狀病 (COVID-19)</a>
          <a href="/tw/about" className={classnames('nav-item desktop', {'nav-item-active': path === 'about'})}>關於我們</a>
          <a href="mailto:contact@covid19factcheck.com" target="_blank" className="nav-item desktop">聯絡我們</a>
        </div>
      </div>
    )
  }
}

export default withRouter(Header)