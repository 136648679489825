import React, { Component } from 'react'
import classnames from 'classnames'

import Button from '../Components/Button'

class About extends Component {

  render() {

    return (
      <div className="about">
        <div className="about-header grid">
          <h1 className="about-title">Yumi paitim dis bikpla sik wantaim gutpla save.</h1>
          <p className="about-description">Mipla wanpla grup ol sumatin lon UCSF husait ie wokbung wantaim wanpela designer/enginer lo putin olgeta stretpla skul toktok behainim sik coronavirus.</p>
          <p className="about-description">Ol giaman toktok ie ken wokim mahn meri na pikinini poret na bagarapim sindaun bilong kominiti. Planti ol toksave ie stap lo tokples blo ol narapla.</p>
          <p className="about-description">Tingting blo mipla em lo bungim olgeta stretpla toktok blo sik coronavirus na tanim ie go lo narapla tokples lo halivim yumi olgeta pait wantaim lo aburusim displa sik. </p>
          <div className="about-disclaimer">
            <h3 className="about-disclaimer-caption">Toksave</h3>
            <p className="about-disclaimer-content">Mipla no ol bikpla save lain lo COVID-19. Tasol, mipla olsem medical sumatin gat save na skul lo ol medical samthin na gat save blo wokim wok painim aut. Mipla wok hat lo kisim ol stretpla na trupla toktok lo ol lain husait save wokim wok painim aut, na mipla laik sharim disla stretpla toktok lo olgeta manmeri.</p>
            <p className="about-disclaimer-content">Tingting blo mipla em blo mipla yet na ie no makim maus blo ol skul na wokples blo mipla. Mipla laik tok tenkiu tumas lo ol dokta or physician husait ol ie givim halpim kam lo sait blo sekim olgeta skul toktok mipla putim wantaim: Dr. MeLee Lee, Dr Changsoo Park.</p>
          </div>
          <div className="about-action desktop">
            <a href="mailto:contact@covid19factcheck.com" target="_blank"><div className="btn btn-primary about-action-btn">Contactim mipla</div></a>
            <h4 className="about-action-caption">Salem email kam lo mipla lo contact@covid19factcheck.com</h4>
          </div>
        </div>
        <div className="about-team">
          <h1 className="about-team-title">Team</h1>
          <div className="about-team-row grid">
            <div className="about-team-section">
              <h3 className="about-team-section-header">Ol lain husait putim toksave wantaim</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Hallen Pham</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Fiona Ng</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Anuvir Singh</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Web Design na Engineer</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Shawn Park</div>
                <h4 className="about-team-member-title">Staff Product Designer, Discord</h4>
                <h4 className="about-team-member-link">
                  <a href="https://designbyroka.com/" target="_blank">Website</a>
                  <a href="https://www.linkedin.com/in/shawn-park-9033a251/" target="_blank">Linkedin</a>
                </h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Tanim toktok lo Amharic tokples</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Beza Dagne</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Yetinayet Dagne</div>
                <h4 className="about-team-member-title">RN BSN, 2nd year Family Nurse Practitioner Student, SJSU</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Tanim toktok lo Arabic tokples</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Tessnim Ahmad, MD</div>
                <h4 className="about-team-member-title">UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Sarah Semaan</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Amer Alsoudi</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
          </div>
          <div className="about-team-row grid">
            <div className="about-team-section">
              <h3 className="about-team-section-header">Tanim toktok lo Chinese tokples</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Billy Zeng</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Fiona Ng</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Janette Tang</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Jackie Lin</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Alex Ge</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Tanim toktok lo Korean tokples</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Eric Kim</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">James Lim</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Eliana Kim</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Sally Oh</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Tanim toktok lo Punjabi tokples</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Anuvir Singh</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Prabhjot Kaur</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Tanim toktok lo Russian tokples</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Liza Leykina</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Katerina Protsenko</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Fima Zaltsman, PhD</div>
                <h4 className="about-team-member-title">Postdoctoral Scholar, UCSF</h4>
              </div>
            </div>
          </div>
          <div className="about-team-row grid">
            <div className="about-team-section">
              <h3 className="about-team-section-header">Tanim toktok lo Spanish tokples</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Alexis Velazquez</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Jessica Valdez</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Nicole Rodriguez</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Andres Calvillo</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Dani Montejano</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Sandra Perez</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Ivan Iniguez</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Tanim toktok lo Tagalog tokples</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Joel Jose Quitlong Nario</div>
                <h4 className="about-team-member-title">3rd year Medical Student, Weill Cornell Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Francine Castillo</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Tanim toktok lo Tongan</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Ha’ano Fonua</div>
                <h4 className="about-team-member-title">5th year Medical Student, University of Auckland, NZ</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Tanim toktok lo Tokpisin</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Barbara Angoro</div>
                <h4 className="about-team-member-title">PhD Student, School of Pharmacy, University of Auckland</h4>
              </div>
            </div>
          </div>
          <div className="about-team-row grid">
            <div className="about-team-section">
              <h3 className="about-team-section-header">Tanim toktok lo Vietamese tokples</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Hallen Pham</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Kevin Pham</div>
                <h4 className="about-team-member-title">Molecular, Cell, and Developmental Biology, UCLA</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Kristine Au</div>
                <h4 className="about-team-member-title">De Anza College</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Art</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Tammy Nguyen</div>
                <h4 className="about-team-member-link">
                  <a href="http://tamsanity.com/" target="_blank">Portfolio</a>
                  <a href="https://www.instagram.com/tamsanity/?hl=en" target="_blank">Instagram</a>
                  <a href="https://twitter.com/tamsanity_" target="_blank">Twitter</a>
                </h4>
              </div>
            </div>
          </div>
          <h4 className="about-team-thanks">Mipla laik tok tenkiu lo Dr. Elaine Khoong na Dr. Christopher Peabody lo helpim mipla lo sharim website blo mipla ie go lo planti manmeri. Mipla tok tenkiu lo Asian Foundation, UCSF Asian Pacific American Medical Students Association (APAMSA), UCSF COVID Student Aid, na Paul Park (4th year medical sumatin, UCSF) lo helpim mipla kisim ol lain lo tanim tokples. Moa long em, mipla tok tenkiu lo ol femli blo mipla lo helpim mipla putim wantaim displa ol skul tok, na wok hart lo mekim disla skull tok kamap klia lo olgeta lain lo save.</h4>
        </div>
        <div className="about-action mobile">
          <a href="mailto:contact@covid19factcheck.com" target="_blank"><div className="btn btn-primary about-action-btn">Contactim mipla</div></a>
          <h4 className="about-action-caption">Salem email kam lo mipla lo contact@covid19factcheck.com</h4>
        </div>
      </div>
    )
  }
}

export default About