import React, { Component } from 'react'
import classnames from 'classnames'
import { Link, withRouter } from 'react-router-dom'

import Button from '../Components/Button'
import ClipboardButton from '../Components/ClipboardButton'

class Aside extends React.PureComponent {

  render() {

    return (
      <div className="content-aside desktop">
        <div className="content-share">
          <h4 className="content-share-caption">Share with friends and family</h4>
          <div className="content-share-options">
            <div className="content-share-item content-share-twitter">
              <img className="fit-height" src="/assets/img/icons/twitter.png" />
              <a className="twitter-share-button" href="https://twitter.com/intent/tweet?text=Check%20out%20COVID-19%20Fact%20Check%20to%20learn%20the%20myths%20and%20facts%20about%20the%20coronavirus.&tw_p=tweetbutton&url=http%3A%2F%2Fcovid19factcheck.com" target="_blank">Tweet</a>
            </div>
            <div className="content-share-item content-share-facebook">
              <img className="fit-height" src="/assets/img/icons/facebook.png" />
              <a className="fb-share-button" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=http://covid19factcheck.com/"></a>
            </div>
            <div className="content-share-item content-share-email">
              <img className="fit-height" src="/assets/img/icons/email.svg" />
              <a href="mailto:?body=Check%20out%20COVID-19%20Fact%20Check%20to%20learn%20the%20myths%20and%20facts%20about%20the%20coronavirus.%20http%3A%2F%2Fcovid19factcheck.com"></a>
            </div>
            <ClipboardButton className="content-share-item content-share-link" value="http://covid19factcheck.com" success="The link has been copied! 🎉"><img className="fit-height" src="/assets/img/icons/link.svg" /></ClipboardButton>
          </div>
        </div>
        <h5 className="content-footnote">Most information sourced from <a href="https://www.who.int/" target="_blank">WHO</a> & <a href="https://www.cdc.gov/" target="_blank">CDC</a>.<br /> Last updated 05/18/2020.</h5>
      </div>
    )
  }
}

export default withRouter(Aside)