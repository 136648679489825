import React, { Component } from 'react'
import classnames from 'classnames'

import Button from '../Components/Button'

class About extends Component {

  render() {

    return (
      <div className="about">
        <div className="about-header grid">
          <h1 className="about-title">Let’s fight against this pandemic together with knowledge.</h1>
          <p className="about-description">We are a group of medical students from UCSF who partnered with a designer / engineer to create a credible source of information about the coronavirus.</p>
          <p className="about-description">Misinformation can cause fear and may even be potentially harmful. Publicly available information is often difficult to understand or is only available in a select few languages.</p>
          <p className="about-description">Our goal is to create reliable and easy-to-understand information about coronavirus in multiple languages with the intent to better fight against this pandemic together.</p>
          <div className="about-disclaimer">
            <h3 className="about-disclaimer-caption">Disclaimer</h3>
            <p className="about-disclaimer-content">We are not experts on COVID-19. However, as medical students, we have a background in medical knowledge and research interpretation. We have carefully selected information from credible sources and research studies to share with a broader audience.</p>
            <p className="about-disclaimer-content">Our thoughts are our own and do not represent our respective institution. We thank the physicians who have contributed to and reviewed our content: Dr. MeLee Lee and Dr. Changsoo Park.</p>
          </div>
          <div className="about-action desktop">
            <a href="mailto:contact@covid19factcheck.com" target="_blank"><div className="btn btn-primary about-action-btn">Contact Us</div></a>
            <h4 className="about-action-caption">Email us at contact@covid19factcheck.com</h4>
          </div>
        </div>
        <div className="about-team">
          <h1 className="about-team-title">Team</h1>
          <div className="about-team-row grid">
            <div className="about-team-section">
              <h3 className="about-team-section-header">Content Synthesis</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Hallen Pham</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Fiona Ng</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Anuvir Singh</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Design & Engineering</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Shawn Park</div>
                <h4 className="about-team-member-title">Staff Product Designer, Discord</h4>
                <h4 className="about-team-member-link">
                  <a href="https://designbyroka.com/" target="_blank">Website</a>
                  <a href="https://www.linkedin.com/in/shawn-park-9033a251/" target="_blank">Linkedin</a>
                </h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Amharic Translation</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Beza Dagne</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Yetinayet Dagne</div>
                <h4 className="about-team-member-title">RN BSN, 2nd year Family Nurse Practitioner Student, SJSU</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Arabic Translation</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Tessnim Ahmad, MD</div>
                <h4 className="about-team-member-title">UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Sarah Semaan</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Amer Alsoudi</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
          </div>
          <div className="about-team-row grid">
            <div className="about-team-section">
              <h3 className="about-team-section-header">Chinese Translation</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Billy Zeng</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Fiona Ng</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Janette Tang</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Jackie Lin</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Alex Ge</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Korean Translation</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Eric Kim</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">James Lim</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Eliana Kim</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Sally Oh</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Punjabi Translation</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Anuvir Singh</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Prabhjot Kaur</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Russian Translation</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Liza Leykina</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Katerina Protsenko</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Fima Zaltsman, PhD</div>
                <h4 className="about-team-member-title">Postdoctoral Scholar, UCSF</h4>
              </div>
            </div>
          </div>
          <div className="about-team-row grid">
            <div className="about-team-section">
              <h3 className="about-team-section-header">Spanish Translation</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Alexis Velazquez</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Jessica Valdez</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Nicole Rodriguez</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Andres Calvillo</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Dani Montejano</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Sandra Perez</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Ivan Iniguez</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Tagalog Translation</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Joel Jose Quitlong Nario</div>
                <h4 className="about-team-member-title">3rd year Medical Student, Weill Cornell Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Francine Castillo</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Tongan Translation</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Ha’ano Fonua</div>
                <h4 className="about-team-member-title">5th year Medical Student, University of Auckland, NZ</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Tok Pisin Translation</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Barbara Angoro</div>
                <h4 className="about-team-member-title">PhD Student, School of Pharmacy, University of Auckland</h4>
              </div>
            </div>
          </div>
          <div className="about-team-row grid">
            <div className="about-team-section">
              <h3 className="about-team-section-header">Vietnamese Translation</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Hallen Pham</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Kevin Pham</div>
                <h4 className="about-team-member-title">Molecular, Cell, and Developmental Biology, UCLA</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Kristine Au</div>
                <h4 className="about-team-member-title">De Anza College</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Art</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Tammy Nguyen</div>
                <h4 className="about-team-member-link">
                  <a href="http://tamsanity.com/" target="_blank">Portfolio</a>
                  <a href="https://www.instagram.com/tamsanity/?hl=en" target="_blank">Instagram</a>
                  <a href="https://twitter.com/tamsanity_" target="_blank">Twitter</a>
                </h4>
              </div>
            </div>
          </div>
          <h4 className="about-team-thanks">We thank Dr. Elaine Khoong and Dr. Christopher Peabody for helping us share our website to a broader audience. We thank UCSF Asian Pacific American Medical Students Association (APAMSA), UCSF COVID Student Aid, and Paul Park (MS4) for help recruiting our translation team. We also thank our family members and friends for helping to finalize the translation and ensuring that the information is easy-to-understand for everyone.</h4>
        </div>
        <div className="about-action mobile">
          <a href="mailto:contact@covid19factcheck.com" target="_blank"><div className="btn btn-primary about-action-btn">Contact Us</div></a>
          <h4 className="about-action-caption">Email us at contact@covid19factcheck.com</h4>
        </div>
      </div>
    )
  }
}

export default About