import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Learn extends Component {

  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <h4 className="content-nav-caption">Olgeta sekesn we i stap insaed</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-1')}>Shot toktok</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-2')}>Olsem wanem nao COVID i spred?</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-3')}>Olgeta Saen</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-4')}>Wanem nao bae mi mekem sapos mi sik?</h4>
        </div>
        <div className="content-body">
          <Card 
            id="learn-1"
            icon="/assets/img/icons/info.svg" 
            title="Shot toktok"
            content={
              <div>
                <p>Koronavaeres hemi wan famili blong olgeta vaeres we i save mekem man i save kasem sik olsem flu.</p>
                <p>Long 2019 oli bin faenem wan niufala koronavaeres. Folem saens, oli givim nem blong hem SARS-CoV-2 mo siknes we hemi mekem oli singaotem coronavirus disease 2019 (COVID-19).</p>
              </div>}
          />
          <Card 
            id="learn-2"
            icon="/assets/img/icons/spread.svg" 
            title="Olsem wanem nao COVID i spred?"
            content={
              <div>
                <p><div className="paragraph-header">Long wan man i go long narafala man</div>Olgeta respiratori droplets nao oli mein wei we COVID-19 i spred. Sapos yu stap 2 mita kolosap long wan man we i kasem sik ia, olgeta droplets we taem man ia i kof, sniz, o toktok i save lan long maot, nus, ae blong yu o yu save britim i go insaed long olgeta lang blong yu.</p>
                <p><div className="paragraph-header">Olgeta seves we vaeres i stap long hem</div>Yu save kasem vaeres ia taem yu tajem seves we vaeres ia i stap long hem mo afta yu tajem maot, bus o ae blong yu. Olgeta seves we man i stap tajem evritaem hemi handel blong doa, switj blong laet, mobael fon, tebol, kibod, toilet, tap, mo sink.</p>
              </div>}
          />
          <Card 
            id="learn-3"
            icon="/assets/img/icons/symptoms.svg" 
            title="Olgeta Saen"
            content={
              <div>
                <div className="content-symptom-container">
                  <ul className="content-symptom-category">
                    <div className="content-symptom-title">Nomol</div>
                    <li className="content-symptom-item">Fiva (100.4°F, 38°C)</li>
                    <li className="content-symptom-item">Drae kof</li>
                    <li className="content-symptom-item">Sotwin</li>
                  </ul>
                  <ul className="content-symptom-category">
                    <div className="content-symptom-title">Narafala</div>
                    <li className="content-symptom-item">Kolkol</li>
                    <li className="content-symptom-item">Olgeta Masel i so</li>
                    <li className="content-symptom-item">Trot i so</li>
                    <li className="content-symptom-item">No moa save smelem o testem save samting</li>
                    <li className="content-symptom-item">Taet</li>
                    <li className="content-symptom-item">Nus i ron</li>
                    <li className="content-symptom-item">Hed i raon/traot</li>
                    <li className="content-symptom-item">Sisit wota</li>
                  </ul>
                </div>
                <div className="content-symptom-caption">Plante oli kasem vaeres ia be oli no soemaot olgeta saen blong siknes ia</div>
              </div>}
          />
          <Card 
            id="learn-4"
            icon="/assets/img/icons/sick.svg" 
            title="Wanem nao bae mi mekem sapos mi sik?"
            content={
              <div>
                <p>Folem wanem CDC i talem, plante man oli kasem smol siknes nomo mo oli save rikava nomo long haos. Yu no mas lego haos blong yu, be yu save mekem nomo sipos yu go blong pas long dokta. Go pas long dokta sapos yu stap gat problem blong pulum win, be yu mas kolem dokta festaem.</p>
                <hr />
                <p>
                  <ul className="content-list">
                    <div>Kolem 911 sipos yu gat wan emejensi olsem:</div>
                    <li>Faenem i had blong pulum gud win</li>
                    <li>Jes i so</li>
                    <li>Yu konfus o no save wekap gud</li>
                    <li>Lips mo Fes blong yu i blu</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <ul className="content-list">
                    <div>Blong Save moa infomesen o faenem medikol kea o komuniti risos <strong>kolem lokol hospitol blong yu.</strong></div>
                    <li>Vila Sentrol Hospitol:  23503 (24/7)</li>
                    <li>Santo Hospitol Noten Rijen: 36015 (24/7)</li>
                    <li>Malekula Norsup Hospitol: 48416</li>
                    <li>Tanna Lenakel Hospital: 88632</li>
                  </ul>
                </p>
                <hr />
                <p>Plis visitim <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/disinfecting-your-home.html" target="_blank">CDC websaet</a> blong kasem olgeta infomesen we bae i helpem yu blong katem daon risk blong kasem sik ia taem wan man i sik.</p>
              </div>}
          />
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Learn;
