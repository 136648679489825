import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Learn extends Component {

  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <h4 className="content-nav-caption">Table of Contents</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-1')}>Overview</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-2')}>How does it spread?</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-3')}>Symptoms</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-4')}>What to do if I feel sick?</h4>
        </div>
        <div className="content-body">
          <Card 
            id="learn-1"
            icon="/assets/img/icons/info.svg" 
            title="Overview"
            content={
              <div>
                <p>Coronavirus is a family of viruses that can cause common respiratory illnesses such as a cold.</p>
                <p>In 2019, a new coronavirus was identified. SARS-CoV-2 is the scientific name of the virus and the disease it causes has been named coronavirus disease 2019 (COVID-19).</p>
              </div>}
          />
          <Card 
            id="learn-2"
            icon="/assets/img/icons/spread.svg" 
            title="How does it spread?"
            content={
              <div>
                <p><div className="paragraph-header">Person to Person</div>Respiratory droplets are the main way COVID-19 spread. If you are within 6 feet of an infected person, respiratory droplets from their cough, sneeze, or talking can land in your mouth, nose, eyes, or possibly be inhaled into the lungs.</p>
                <p><div className="paragraph-header">Contaminated Surfaces</div>You can become infected by touching the surface that has the virus and then touching your mouth, nose, or eyes. Frequently touched surfaces include door handles, light switches, cellphones, tables, keyboards, toilets, faucets, and sinks.</p>
              </div>}
          />
          <Card 
            id="learn-3"
            icon="/assets/img/icons/symptoms.svg" 
            title="Symptoms"
            content={
              <div>
                <div className="content-symptom-container">
                  <ul className="content-symptom-category">
                    <div className="content-symptom-title">Common</div>
                    <li className="content-symptom-item">Fever (100.4°F, 38°C)</li>
                    <li className="content-symptom-item">Dry cough</li>
                    <li className="content-symptom-item">Shortness of breath</li>
                  </ul>
                  <ul className="content-symptom-category">
                    <div className="content-symptom-title">Uncommon</div>
                    <li className="content-symptom-item">Fatigue</li>
                    <li className="content-symptom-item">Muscle Pain</li>
                    <li className="content-symptom-item">Sore Throat</li>
                    <li className="content-symptom-item">Runny Nose</li>
                    <li className="content-symptom-item">Nausea</li>
                    <li className="content-symptom-item">Diarrhea</li>
                    <li className="content-symptom-item">Loss of smell and taste</li>
                  </ul>
                </div>
                <div className="content-symptom-caption">Many infected by the virus can be asymptomatic and experience no symptoms.</div>
              </div>}
          />
          <Card 
            id="learn-4"
            icon="/assets/img/icons/sick.svg" 
            title="What should I do if I feel sick? "
            content={
              <div>
                <p>According to the CDC, most people have mild illness and are able to recover at home. Do not leave your home, except to get medical care. Seek medical attention if you are having trouble breathing but call the healthcare provider or facility first. </p>
                <hr />
                <p>
                  <ul className="content-list">
                    <div>Call 911 if you have a medical emergency including:</div>
                    <li>Difficulty breathing</li>
                    <li>Chest pain</li>
                    <li>Confusion or inability to arouse</li>
                    <li>Bluish lips or face</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <ul className="content-list">
                    <div>For more information or help finding medical care or community resources <strong>call your local county public health department.</strong> Click <a href="https://covid19.ca.gov/state-local-resources/#top" target="_blank">here</a> for public health departments of counties in California.</div>
                    <li><a href="https://www.sccgov.org/sites/phd/DiseaseInformation/novel-coronavirus/Pages/home.aspx" target="_blank">Santa Clara County:</a> Dial 211 for information about COVID-19 and local community resources such as food, shelter, counseling, employment assistance, quality childcare, senior services, and more (available 24/7)</li>
                    <li><a href="https://sf.gov/topics/coronavirus-covid-19" target="_blank">San Francisco:</a> 311 (From within SF) or 415-701-2311 (available 24/7)</li>
                    <li><a href="http://publichealth.lacounty.gov/media/Coronavirus/" target="_blank">Los Angeles:</a> 211 (available 24/7)</li>
                    <li><a href="https://covid19info.ocgov.com/" target="_blank">Orange County:</a> 211 (available 24/7)</li>
                    <li><a href="https://www.sandiegocounty.gov/coronavirus.html" target="_blank">San Diego:</a> 211 (available 24/7)</li>
                    <li><a href="https://www.co.fresno.ca.us/departments/public-health/covid-19" target="_blank">Fresno:</a> (559) 600-4636 (8a-5p)</li>
                    <li><a href="http://www.acphd.org/2019-ncov.aspx" target="_blank">Alameda:</a> (510) 268-2101</li>
                    <li><a href="https://www.coronavirus.cchealth.org/" target="_blank">Contra Costa:</a> (844) 729-8410 (8a-5p)</li>
                  </ul>
                </p>
                <hr />
                <p>Please visit <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/disinfecting-your-home.html" target="_blank">CDC website</a> for helpful tips on how to minimize contamination when someone is sick.</p>
              </div>}
          />
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Learn;
