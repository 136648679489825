import React, { Component } from 'react'
import classnames from 'classnames'
import { Link, withRouter } from 'react-router-dom'

import Button from '../Components/Button'
import ClipboardButton from '../Components/ClipboardButton'

class Aside extends React.PureComponent {

  render() {

    return (
      <div className="content-aside desktop">
        <div className="content-share">
          <h4 className="content-share-caption">شارك مع الأصدقاء والعائلة</h4>
          <div className="content-share-options">
            <div className="content-share-item content-share-twitter">
              <img className="fit-height" src="/assets/img/icons/twitter.png" />
              <a className="twitter-share-button" href="https://twitter.com/intent/tweet?text=%D8%AA%D8%AD%D9%82%D9%82%20%D9%85%D9%86%20%22COVID-19%20Fact%20Check%22%20%D9%84%D8%AA%D8%B9%D9%84%D9%85%20%D8%A7%D9%84%D8%A3%D8%B3%D8%B7%D9%88%D8%B1%D8%A9%20%D9%88%D8%A7%D9%84%D8%AD%D9%82%D8%A7%D8%A6%D9%82%20%D8%AD%D9%88%D9%84%20%D8%A7%D9%84%D9%81%D9%8A%D8%B1%D9%88%D8%B3%20%D8%A7%D9%84%D8%AA%D8%A7%D8%AC%D9%8A.&tw_p=tweetbutton&url=http%3A%2F%2Fcovid19factcheck.com%2Far" target="_blank">Tweet</a>
            </div>
            <div className="content-share-item content-share-facebook">
              <img className="fit-height" src="/assets/img/icons/facebook.png" />
              <a className="fb-share-button" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=http://covid19factcheck.com/ar"></a>
            </div>
            <div className="content-share-item content-share-email">
              <img className="fit-height" src="/assets/img/icons/email.svg" />
              <a href="mailto:?body=%D8%AA%D8%AD%D9%82%D9%82%20%D9%85%D9%86%20%22COVID-19%20Fact%20Check%22%20%D9%84%D8%AA%D8%B9%D9%84%D9%85%20%D8%A7%D9%84%D8%A3%D8%B3%D8%B7%D9%88%D8%B1%D8%A9%20%D9%88%D8%A7%D9%84%D8%AD%D9%82%D8%A7%D8%A6%D9%82%20%D8%AD%D9%88%D9%84%20%D8%A7%D9%84%D9%81%D9%8A%D8%B1%D9%88%D8%B3%20%D8%A7%D9%84%D8%AA%D8%A7%D8%AC%D9%8A.%20http%3A%2F%2Fcovid19factcheck.com%2Far"></a>
            </div>
            <ClipboardButton className="content-share-item content-share-link" value="http://covid19factcheck.com/ar" success="تم نسخ الرابط! 🎉"><img className="fit-height" src="/assets/img/icons/link.svg" /></ClipboardButton>
          </div>
        </div>
        <h5 className="content-footnote">معظم المعلومات مصدرها <a href="https://www.who.int/" target="_blank">WHO</a> & <a href="https://www.cdc.gov/" target="_blank">CDC.</a> <br /> آخر تحديث ٥/١٨/٢٠٢٠</h5>
      </div>
    )
  }
}

export default withRouter(Aside)