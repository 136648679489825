import React, { Component } from 'react'
import classnames from 'classnames'
import { Link, withRouter } from 'react-router-dom'

import Button from '../Components/Button'
import ClipboardButton from '../Components/ClipboardButton'

class Aside extends React.PureComponent {

  render() {

    return (
      <div className="content-aside desktop">
        <div className="content-share">
          <h4 className="content-share-caption">ለጓደኞች እና ለቤተሰብ ያጋሩ</h4>
          <div className="content-share-options">
            <div className="content-share-item content-share-twitter">
              <img className="fit-height" src="/assets/img/icons/twitter.png" />
              <a className="twitter-share-button" href="https://twitter.com/intent/tweet?text=%E1%88%B5%E1%88%88%20%E1%8A%AE%E1%88%AE%E1%8A%93%20%E1%89%AB%E1%8B%AD%E1%88%A8%E1%88%B5%20%E1%8A%A0%E1%8D%88%20%E1%89%B3%E1%88%AA%E1%8A%AD%20%E1%8A%A5%E1%8A%93%20%E1%88%80%E1%89%86%E1%89%BD%20%E1%88%88%E1%88%9B%E1%8B%88%E1%89%85%20COVID-19%20Fact%20Check%20%E1%8B%AD%E1%8C%8E%E1%89%A5%E1%8A%99%E1%8A%95%E1%8D%A2&tw_p=tweetbutton&url=http%3A%2F%2Fcovid19factcheck.com%2Fam%2F" target="_blank">Tweet</a>
            </div>
            <div className="content-share-item content-share-facebook">
              <img className="fit-height" src="/assets/img/icons/facebook.png" />
              <a className="fb-share-button" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=http://covid19factcheck.com/am/"></a>
            </div>
            <div className="content-share-item content-share-email">
              <img className="fit-height" src="/assets/img/icons/email.svg" />
              <a href="mailto:?body=%E1%88%B5%E1%88%88%20%E1%8A%AE%E1%88%AE%E1%8A%93%20%E1%89%AB%E1%8B%AD%E1%88%A8%E1%88%B5%20%E1%8A%A0%E1%8D%88%20%E1%89%B3%E1%88%AA%E1%8A%AD%20%E1%8A%A5%E1%8A%93%20%E1%88%80%E1%89%86%E1%89%BD%20%E1%88%88%E1%88%9B%E1%8B%88%E1%89%85%20COVID-19%20Fact%20Check%20%E1%8B%AD%E1%8C%8E%E1%89%A5%E1%8A%99%E1%8A%95%E1%8D%A2%20http%3A%2F%2Fcovid19factcheck.com%2Fam%2F"></a>
            </div>
            <ClipboardButton className="content-share-item content-share-link" value="http://covid19factcheck.com/2F" success="አገናኝ ድህረገፅ ተቀድቷል 🎉"><img className="fit-height" src="/assets/img/icons/link.svg" /></ClipboardButton>
          </div>
        </div>
        <h5 className="content-footnote">አብዛኛው መረጃ የተገኘው ከ <a href="https://www.who.int/" target="_blank">WHO</a> እና <a href="https://www.cdc.gov/" target="_blank">ነው።</a> <br /> ለመጨረሻ ጊዜ የዘመነው 5/29/2020።</h5>
      </div>
    )
  }
}

export default withRouter(Aside)