import React, { Component } from 'react'
import classnames from 'classnames'
import {
  BrowserRouter as Router,
  Link,
  Route,
  useLocation,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom'

import Button from '../Components/Button'

class Header extends React.PureComponent {

  render() {

    const { toggleLanguageModalActive, toggleShareModalActive, path } = this.props;

    return (
      <div className="header-container">
        <div className="header grid">
          <h1 className="header-title">COVID-19<br />Fact Check</h1>
          <div className="header-description">Tìm hiểu thêm và phân biệt tin thật và tin giả để phòng chống coronavirus.</div>
          <div className="header-action mobile">
            <Button className="btn-secondary" label="Tiếng Việt" imgURL="/assets/img/icons/arrow_down.svg" onClick={toggleLanguageModalActive} />
            <Button className="btn-primary" label="Chia sẻ" imgURL="/assets/img/icons/share.svg" onClick={toggleShareModalActive} />
          </div>
          <div className="header-caption mobile">
            <h5 className="header-caption-item">Nguồn gốc của đa số thông tin từ <a href="https://www.cdc.gov/" target="_blank">CDC</a> & <a href="https://www.who.int/" target="_blank">WHO</a>. </h5>
            <h5 className="header-caption-item">Lần cuối xem xét trang này 05/18/20.</h5>
          </div>
        </div>
        <div className="nav">
          <a href="/vn" className={classnames('nav-item', {'nav-item-active': path === ''})}>Tin thật hay giả</a>
          <a href="/vn/tips" className={classnames('nav-item', {'nav-item-active': path === 'tips'})}>Cách phòng chống </a>
          <a href="/vn/learn" className={classnames('nav-item', {'nav-item-active': path === 'learn'})}>Tìm hiểu về COVID-19</a>
          <a href="/vn/about" className={classnames('nav-item desktop', {'nav-item-active': path === 'about'})}>Về Chúng Tôi</a>
          <a href="mailto:contact@covid19factcheck.com" target="_blank" className="nav-item desktop">Liên Lạc Chúng Tôi</a>
        </div>
      </div>
    )
  }
}

export default withRouter(Header)