import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Tips extends Component {
  
  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <h4 className="content-nav-caption">目錄</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-1')}>正確洗手步驟</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-2')}>面罩指南</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-3')}>使用手套的提示</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-4')}>清潔東西</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-5')}>如何處理購買回來的物品</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-6')}>如何處理外賣食物</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-7')}>如何處理郵包</h4>
        </div>
        <div className="content-body">
          <Card 
            id="tips-1"
            thumbnail="/assets/img/shots/tips/tips-1.jpg" 
            title="正確洗手步驟"
            content={
              <div>
                <p>防止傳染冠狀病毒的關鍵步驟是常常洗手。避免在洗手前觸摸你的眼睛、鼻子、和嘴巴。</p>
                <hr />
                <p>
                  <div className="paragraph-header">如何正確的洗手</div>
                  <ol className="content-list">
                    <li><div className="ol-index">1</div>用水沖洗雙手</li>
                    <li><div className="ol-index">2</div>加肥皂或者洗手液在手中。搓擦手的各個部位、包括手背、手指之間以及指甲下的縫隙。</li>
                    <li><div className="ol-index">3</div>每次洗手應揉搓20秒以上 （唱 ”生日快樂“ 歌兩次）</li>
                    <li><div className="ol-index">4</div>用清水沖掉手中的肥皂</li>
                    <li><div className="ol-index">5</div>用乾淨的毛巾或者紙巾擦手、或者讓手風乾</li>
                  </ol>
                  <h5 className="content-source"><a href="https://www.cdc.gov/handwashing/when-how-handwashing.html" target="_blank">来源: 美国疾病预防控制中心</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/9eWT94x9Orw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/rgaModQN7RE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p>
                  <div className="paragraph-header">應該何時洗手？</div>
                  <ul className="content-list">
                    <li>盡可能經常的洗手</li>
                    <li>外出到公共場所後</li>
                    <li>
                      <div>觸摸常被別人碰的物件後</div>
                      <ul>
                        <li>比如：門把手、桌子、加油站、購物車、收銀台、刷卡器、電梯等等</li>
                      </ul>
                    </li>
                    <li>做飯時以及做飯前後</li>
                    <li>吃飯前</li>
                    <li>擤鼻涕、打噴嚏後</li>
                    <li>在戴口罩和手套前；摘口罩和手套後</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">如果沒有乾淨的水和肥皂、不方便洗手時、可以使用含有酒精成分的消毒洗手液來清潔雙手</div>
                  <ul className="content-list">
                    <li>消毒洗手液不能殺滅所有的細菌</li>
                    <li>洗手液應該含有最少六成的酒精成分</li>
                    <li>將消毒洗手液搓在手的各個部位上、包括手指之間以及指尖上</li>
                    <li>連續搓手、直到手上的洗手液乾了為止</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-2"
            thumbnail="/assets/img/shots/tips/tips-2-tw.jpg" 
            title="面罩指南"
            content={
              <div>
                <p>
                  <div className="paragraph-header">使用面罩的良好用法</div>
                  <ul className="content-list">
                    <div className="content-list-label-positive">要做</div>
                    <li>確保可以呼吸</li>
                    <li>確保它遮擋了您的鼻子和嘴巴（從鼻樑到下巴下方）</li>
                    <li>用側邊的繩取下面罩</li>
                    <li>每天清洗和擦乾布口罩</li>
                    <li>取下面罩後要洗手</li>
                    <li>將未清洗乾淨的口罩放在指定的袋子或箱中</li>
                  </ul>
                  <ul className="content-list">
                    <div className="content-list-label-negative">避免</div>
                    <li>觸摸面罩的正面、這是最骯髒的位置</li>
                    <li>將面罩滑到下巴下或帶在脖子上</li>
                    <li>側面有間隙</li>
                    <li>用手套取下臉罩</li>
                    <li>重複使用手術口罩</li>
                  </ul>
                  <h5 className="content-source">来源: <a href="https://chinese.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">美国疾病预防控制中心</a>; <a href="https://www.nytimes.com/2020/04/08/well/live/coronavirus-face-mask-mistakes.html#click=https://t.co/GkBNJAuw1V" target="_blank">纽约时报</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/m4O-3wQQjpA?start=118" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p>
                  <div className="paragraph-header">帶面罩<span className="red">不能</span>代替其他預防措施</div>
                  <div>若您戴上臉罩和/或手套，請繼續保持：</div>
                  <ul className="content-list">
                    <li>正確的洗手</li>
                    <li>與其他人保持6英尺的距離</li>
                    <li>在家隔離</li>
                  </ul>
                  <div>雖然防護裝備（口罩、手套等）可以幫助限制病毒傳染、但也會產生錯誤的安全感。保護裝備有可能與以被污染的表面接觸。如果使用不當、可能會導致交叉感染並被病毒有機可乘。</div>
                </p>
              </div>}
            extra={
              <div>
                <p>
                  <div className="paragraph-header">為什麼美國疾病預防控制中心 (CDC) 建議在公眾場地使用面罩？</div>
                  <div>根據<a href="https://chinese.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover.html" target="_blank">CDC</a>、無症狀的人也可以傳播冠狀病毒（也稱為 “隱形患者”）。通過戴口罩和與他人保持6英尺的距離、可以幫助阻止冠狀病毒在社區中的傳播。</div>
                  <ul className="content-list">
                    <div>有些人不應該使用面罩</div>
                    <li>2歲以下的兒童</li>
                    <li>有呼吸困難的人</li>
                    <li>失去知覺或不能獨立地取下臉罩 (比如行動不便) 的人</li>
                  </ul>
                </p>
                <p>在美國、CDC建議廣大市民使用安全的家用材料，例如舊恤衫或者是頭巾、來做布料面罩。 CDC的網頁有談到使用舊恤衫和頭巾來<a href="https://chinese.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">自製布面罩</a>的步驟。</p>
                <p><strong>普通市民並不需要用醫療口罩(外科口罩) 或者是用N95口罩。</strong>反而、因為現時美國醫療口罩的供應短缺、CDC 建議不用特別的去買醫療口罩或N95口罩。</p>
              </div>
            }
          />
          <Card 
            id="tips-3"
            thumbnail="/assets/img/shots/tips/tips-3.jpg" 
            title="使用手套的提示"
            content={
              <div>
                <p>在公眾場所用手套作為防護是不必的。如果您選擇使用手套、請注意以下幾點。</p>
                <p>
                  <ul className="content-list">
                    <div className="content-list-label-positive">要做</div>
                    <li>用手套前和脫下後都要立刻洗手</li>
                    <li>脫了塑料手套後要丟掉、不要重用</li>
                    <li>布製手套、在重新使用前用肥皂和水清洗</li>
                  </ul>
                  <ul className="content-list">
                    <div className="content-list-label-negative">避免</div>
                    <li>觸摸臉、嘴、鼻子和眼睛</li>
                    <li>將手放在口袋裡</li>
                    <li>使用手機</li>
                  </ul>
                  <h5 className="content-source">来源: <a href="https://www.kqed.org/science/1961243/coronavirus-heres-how-to-use-gloves-properly-to-help-prevent-infection" target="_blank">KQED</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/TnLCYqk20YU?start=116" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>}
          />
          <Card 
            id="tips-4"
            thumbnail="/assets/img/shots/tips/tips-4.jpg" 
            title="清潔東西"
            content={
              <div>
                <p>根據目前的數據、接觸污染的物件不是主要的感染冠狀病毒方式。如果你只接觸了污染的物件、你不會感染到病毒的。但是如果你用佔有活的病毒的手來碰你眼睛、鼻子、或者嘴、你的感染風險會比較高。觸摸表面後再清潔表面和洗手、可降低病毒的傳播風險。</p>
                <hr />
                <p>
                  <div className="paragraph-header">冠狀病毒究竟能在不同物件表面上能穩定地保持多長時間？</div>
                  <div className="content-table">
                    <div className="content-table-row">
                      <div className="content-table-column">
                        <h3 className="content-table-heading">4 小時</h3>
                        <h4 className="content-table-caption">銅</h4>
                      </div>
                      <div className="content-table-column">
                        <h3 className="content-table-heading">24 小時</h3>
                        <h4 className="content-table-caption">紙板</h4>
                      </div>
                    </div>
                    <div className="content-table-row">
                      <div className="content-table-column">
                        <h3 className="content-table-heading">2-3 天</h3>
                        <h4 className="content-table-caption">塑料</h4>
                      </div>
                      <div className="content-table-column">
                        <h3 className="content-table-heading">2-3 天</h3>
                        <h4 className="content-table-caption">不銹鋼</h4>
                      </div>
                    </div>
                  </div>
                  <div>雖然過了幾個小時到幾天還能監測到冠狀病毒，表面上的病毒總數會根據時間的推移會變得越來越少，所以感染病毒的風險也會降低得很快。 (按照<a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973" target="_blank">新英格蘭醫學雜誌NEJM</a>的一項研究【二零二零年三月十七日】）</div>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">應該用什麼清潔物件表面呢？</div>
                  <ul className="content-list">
                    <div>請瀏覽美國疾病預防控制中心網站以獲取關於<a href="https://chinese.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cleaning-disinfection.html" target="_blank">如何清潔各種表面的建議。</a> 美國疾病預防控制中心的推薦:</div>
                    <li>環境保護署批准的消毒劑 <a href="https://www.cnn.com/2020/03/05/health/epa-disinfectants-coronavirus-trnd/index.html" target="_blank">(在這裡看名單)</a></li>
                    <li>
                      <div>稀釋漂白液</div>
                      <ul>
                        <li>每加侖水配5湯匙（⅓杯）漂白劑</li>
                        <li>或者，每夸脫的水配4茶匙漂白劑</li>
                        <li>請勿直接從瓶中用沒有稀釋的漂白劑清潔</li>
                        <li>確保漂白劑沒有過過期</li>
                        <li>請勿將漂白劑與氨水或其他清潔劑混合</li>
                      </ul>
                    </li>
                    <li>酒精溶液(至少70%酒精）</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-5"
            thumbnail="/assets/img/shots/tips/tips-5.jpg" 
            title="如何處理購買回來的物品"
            content={
              <div>
                <p><strong>在超市，相對您購買的物品而言，您更有可能被在超市的人傳染。</strong> <a href="https://www.cdc.gov/coronavirus/2019-ncov/daily-life-coping/essential-goods-services.html" target="_blank">美國疾病預防控制中心報導</a>， “在美國，我們並沒有數據指向食物，或者食物包裝，為傳播病毒的主要渠道。</p>
                <p>
                  <div className="paragraph-header">往超市購物時的建議</div>
                  <ul className="content-list">
                    <li>如果你有任何呼吸道或著新冠疾病的症狀、請不要出門購買物品</li>
                    <li>盡量每次購買足夠一到兩個星期的份量</li>
                    <li>盡量每個家庭只派一個人往超市</li>
                    <li>在通常情況下、我們很難留意小孩子接觸過什麼東西。所以如果可以、讓小孩子跟一個可以信任的監護人留在家裡。</li>
                    <li>出發前、寫下一張購物的清單有助減小逗留在超市的時間</li>
                    <li>要戴面罩。在超市裡要跟別人保持6英尺的距離。</li>
                    <li>用消毒濕巾清擦購物車把手的部位</li>
                    <li>盡量選擇無接觸式付款</li>
                    <li>離開超市後，用免洗洗手液</li>
                    <li>等回家後，用水和洗手液洗手至少20秒</li>
                    <li>如果是在你的能力內、請幫助一些無法自己去超市的家人、朋友或鄰居購買食物和日用品</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">您並不需要給您購買的物品消毒。</div>
                  <div>最重要的是在購物後洗手，還有擦乾淨觸碰到物品的桌面。避免用肥皂洗新鮮蔬菜水果。</div>
                  <ul className="content-list">
                    <div>如果<a href="https://www.npr.org/sections/health-shots/2020/04/12/832269202/no-you-dont-need-to-disinfect-your-groceries-but-here-s-to-shop-safely" target="_blank">您堅持清洗您購買的食物或物品</a></div>
                    <li>考慮扔掉所有多餘的外包裝</li>
                    <li>用肥皂/洗碗劑和水清洗塑料或者紙板包裝。這可避免有害的消毒液滲入您的食物</li>
                    <li>可以直接噴射消毒液或用含有消毒液的紙巾抹硬的表面: 罐頭、玻璃瓶等等。</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-6"
            thumbnail="/assets/img/shots/tips/tips-6.jpg" 
            title="如何處理外賣食物"
            content={
              <div>
                <p>根據<a href="https://www.fda.gov/food/food-safety-during-emergencies/shopping-food-during-covid-19-pandemic-information-consumers" target="_blank">目前資料</a>，新冠病毒 (COVID-19) 主要是透過人與人之間傳播，並不是透過食物傳染的。</p>
                <p>
                  <ul className="content-list">
                    <li>要求送餐服務員在門口放下外賣</li>
                    <li>把食物從外賣包裝盒裡直接轉放到家裡的盤裡然後扔掉外賣盒。</li>
                    <li>如果必須使用餐館提供的餐具， 請先用清水和洗潔劑清洗餐具。</li>
                    <li>在用餐前，記得要用清水跟肥皂清洗雙手。</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-7"
            thumbnail="/assets/img/shots/tips/tips-7.jpg" 
            title="如何处理邮包"
            content={
              <div>
                <p>根據NEJM<a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973?query=featured_home" target="_blank">的研究</a>、新冠病毒能在板紙包裝和紙皮上存活到二十四小時。但是雖然病毒能在包裝短暫的存活著，這並不代表病毒一定會通過此途徑傳染。根據<a href="https://www.cdc.gov/coronavirus/2019-ncov/faq.html" target="_blank">CDC</a>的報導，新冠病毒不太可能通過郵件，產品和包裝傳染。</p>
                <p>
                  <ul className="content-list">
                    <li>打開郵包或信件以後，用洗手液洗手</li>
                    <li>將剪刀還有郵件接觸到的表面清理乾淨</li>
                  </ul>
                </p>
              </div>}
          />
          <h4 className="content-tips-disclaimer">我們認識到以上的各種建議可能並不適合所有的讀者。每個人還有每一個家庭的情況以及資源並不一樣。我們希望這些建議可以幫助大家做好準備，為您自己的家庭想出一些有益的主意。我們邀請您跟我們分享您是如何採取以及實踐這些建議的！</h4>
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Tips;
