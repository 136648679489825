import React, { Component } from 'react'
import classnames from 'classnames'

class Button extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <button className={classnames('btn', this.props.className)} onClick={this.props.onClick}>
        <span>{this.props.label}</span>
        {this.props.imgURL ? <img className="fit-parent" src={this.props.imgURL} /> : null}
        {this.props.children}
      </button>
    )
  }
}

export default Button