import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Myth extends Component {

  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <div className="content-nav-scroller">
            <h4 className="content-nav-caption">Tabla de Contenido</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-1')}>¿Tengo que estar a 6 pies (2 metros) de otras personas cuando uso una mascarilla?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-10')}>¿Puede matar el coronavirus rociando el cuerpo con alcohol o con cloro?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-2')}>¿Necesito comprar mascarillas quirúrgicas o mascarilla N95?</h4>
            {/* <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-3')}>¿Se puede transmitir el COVID-19 a través del air?</h4> */}
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-4')}>¿Usando guantes me protegerá al 100% del coronavirus?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-5')}>¿Puede sobrevivir el coronavirus en las superficies por días?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-6')}>¿Debería lavarme las manos con frecuencia o desinfectar las superficies de mi hogar aunque no salga de mi casa?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-7')}>¿Empeora el ibuprofeno la enfermedad en personas con el COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-8')}>¿La automedicación con hidroxicloroquina y azitromicina evitará el COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-9')}>¿Son efectivos los antibióticos en la prevención y tratamiento del COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-11')}>Si soy joven y estoy saludable, ¿estoy a salvo del nuevo coronavirus?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-12')}>¿Tengo que ser hospitalizado si tengo el COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-13')}>¿Puedo verificar si tengo el COVID-19 conteniendo la respiración durante 10 segundos o más sin toser o sentir molestias?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-14')}>¿Son efectivos los secadores de manos y las lámparas de desinfección UV para matar el coronavirus?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-15')}>¿Enjuagándose regularmente la nariz con solución salina evitará el COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-16')}>¿Las duchas o baños extremadamente calientes previenen el COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-17')}>¿El clima frío y la nieve previenen el COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-18')}>¿Comiendo ajo previene el COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-19')}>¿Bebiendo alcohol previene el COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-20')}>¿Las vacunas contra la neumonía lo protegen contra el COVID-19?</h4>
          </div>
        </div>
        <div className="content-body">
          <Card 
            id="myth-1"
            thumbnail="/assets/img/shots/myth/myth-1.jpg" 
            title="¿Tengo que estar a 6 pies (2 metros) de otras personas cuando uso una mascarilla?"
            content={
              <div>
                <p><span className="card-tag-true">Sí</span>Continúe manteniendo una distancia de 6 pies (o 2 metros) de los demás. Lávese las manos con frecuencia, incluso antes de ponerse y después de quitarse una mascarilla.</p>
                <p>En los Estados Unidos (EE. UU.), el <a href="https://espanol.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover-faq.html" target="_blank">CDC</a> recomienda el uso de mascarillas o cobertura facial de tela para ayudar a prevenir la propagación del virus, porque previenen el esparcimiento de las gotas respiratorias infecciosas que producimos cuando estornudamos, tosemos o hablamos. Dependiendo de la tela y su ajuste, las mascarillas pueden ofrecer protección contra algunas pero no todas las partículas del virus. Vea nuestra página sobre “Consejos Útiles.”</p>
              </div>}
            extra={
              <div>
                <p>Por favor, siga las recomendaciones del <a href="https://espanol.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a> y use materiales de tela para crear su mascarilla. Asegúrese de que la mascarilla se ajuste a su cara perfectamente, permitiéndole respirar cómodamente. El CDC recomienda que su mascarilla esté hecha de varias capas de tela, aunque no especifica el tipo de tela que usted deba usar. </p>
                <p><div className="paragraph-header">¿Qué tan efectivas son las mascarillas de tela contra el coronavirus?</div>No hay evidencia científica para contestar esta pregunta apropiadamente. En <a href="https://www.ncbi.nlm.nih.gov/pubmed/24229526" target="_blank">un experimento de laboratorio</a> con un virus diferente, científicos encontraron que ninguna de las telas que estudiaron (camisa de 100% algodón, bufanda, seda, funda de almohada, lino, mezcla de algodón, toalla de té) pudieron bloquear efectivamente el 100% de las partículas virales debido a su pequeño tamaño. Sin embargo, este experimento no simulaba una tos humana normal. El estudio tampoco consideró la medida y el ajuste de las mascarillas. Como la mayoría de las mascarillas no ofrecen un sello hermético, las partículas virales pueden ingresar a través <a href="https://www.gannett-cdn.com/experiments/usatoday/gifs/tool/919-surgical.png" target="_blank">de los lados de la mascarilla.</a></p>
                <p><div className="paragraph-header">Puntos importantes</div>Las mascarillas de tela pueden ayudar a prevenir infecciones, pero no brindan una protección del 100%.</p>
              </div>}
          />
          <Card 
            id="myth-10"
            thumbnail="/assets/img/shots/myth/myth-10.jpg" 
            title="¿Puede matar el coronavirus rociando el cuerpo con alcohol o con cloro?"
            content={
              <div>
                <p><span className="card-tag-false">No</span><strong>NO</strong> tome, inyecte o rocíe su piel con desinfectante hecho de alcohol o cloro.</p>
                <p>Según las recomendaciones de la <a href="https://www.who.int/es/emergencies/diseases/novel-coronavirus-2019/advice-for-public/q-a-coronaviruses" target="_blank">OMS</a>, “Rociar todo el cuerpo con alcohol o cloro no mata el virus si ya está dentro de tu cuerpo. Rociar estas sustancias puede dañar la ropa y es peligroso para las mucosas (los ojos, la boca, etc.). Tanto el alcohol como el cloro sirven para desinfectar superficies, siempre y cuando que se sigan las <a href="https://espanol.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cleaning-disinfection.html" target="_blank">recomendaciones indicadas.</a>"</p>
              </div>}
          />
          <Card 
            id="myth-2"
            thumbnail="/assets/img/shots/myth/myth-2.jpg" 
            title="¿Necesito comprar mascarillas quirúrgicas o mascarilla N95?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>El <a href="https://espanol.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a> recomienda mascarillas de tela para el público general.</p>
                <p>Las mascarillas médicas (quirúrgicas o N95) están en alta demanda y deben reservarse para los trabajadores de salud que han sido entrenados para usarlas correctamente y están en contacto directo con pacientes enfermos. Por esta razón, es preferible que el público general utilice mascarillas hechas en casa para no agotar los suministros de mascarillas médicas. Si tiene mascarillas N95 a su disposición, considere donarlas a los hospitales que tanto las necesitan.</p>
                <p>Las mascarillas N95 son necesarias para prevenir la transmisión de partículas aéreas que se quedan suspendidas brevemente en el ambiente. Mayormente, el COVID-19 se transmite por contacto directo con gotas respiratorias. Por favor, vea la próxima sección sobre el mito acerca de la transmisión aérea.</p>
              </div>}
            extra={
              <div>
                <p>El respirador <a href="https://www.fda.gov/medical-devices/personal-protective-equipment-infection-control/n95-respirators-and-surgical-masks-face-masks" target="_blank">N95</a> tiene un filtro especializado que puede bloquear por lo menos 95% de las partículas pequeñas (0.3 micras) <strong>mientras</strong> haya un sello hermético. Además del entrenamiento, los profesionales de salud realizan <a href="https://www.safetyalliance.org/wp-content/uploads/2019/06/fit-test-2-000110_retouched-672x372.jpg" target="_blank">pruebas de ajuste</a> cada año para garantizar el tamaño adecuado que les proporcione el sello hermético de las mascarillas. Cuando se usan correctamente, pueden ser incómodas. Incluso los detalles más pequeños, como el vello facial, pueden causar un sellado inadecuado. Para <a href="https://www.cdc.gov/spanish/niosh/docs/2013-138_sp/default.html" target="_blank">el público en general</a>, la falta de entrenamiento y la falta de pruebas de ajuste probablemente no proporcionarán el nivel de protección adecuado y necesario para el uso de las mascarillas N95.</p>
              </div>}
          />
          {/*
          <Card 
            id="myth-3"
            thumbnail="/assets/img/shots/myth/myth-3.jpg" 
            title="¿Se puede transmitir el COVID-19 a través del air?"
            content={
              <div>
                <p><span className="card-tag-maybe">Tal Vez.</span>Para el público en general, la probabilidad de que el COVID-19 se transmita a través del aire es mínima.</p>
                <p>Según las recomendaciones de la <a href="https://www.who.int/es/emergencies/diseases/novel-coronavirus-2019/advice-for-public/q-a-coronaviruses" target="_blank">OMS</a>, “COVID-19 se transmite principalmente a través de gotas generadas cuando una persona infectada tose, estornuda o habla. Estas gotas son pesadas y no permanecen en el aire. Caen rápidamente sobre pisos o superficies. No se reportó transmisión aérea en un estudio de más de 75,000 casos de COVID-19 en China. Sin embargo, los profesionales médicos están en mayor riesgo. La OMS informa que la transmisión aérea puede ser posible en circunstancias específicas en las que se realizan <a href="https://www.who.int/es/news-room/commentaries/detail/modes-of-transmission-of-virus-causing-covid-19-implications-for-ipc-precaution-recommendations" target="_blank">procedimientos hospitalarios que generan aerosoles</a>, como durante la intubación de un paciente, donde se inserta un tubo de respiración en las vías respiratorias para ayudarlo a respirar.</p>
                </div>}
            extra={
              <div>
                <p><div className="paragraph-header">¿Qué significa la transmisión aérea?</div>A diferencia de las gotas respiratorias del COVID-19, las partículas aéreas son más pequeñas y pueden permanecer en el aire por largos periodos de tiempo, donde pueden regarse y cubrir un territorio de más de 6 pies.</p>
                <p><div className="paragraph-header">¿Por qué las noticias dicen que el coronavirus está en el aire?</div><a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973" target="_blank">Un estudio</a> creó artificialmente aerosoles del coronavirus en un cilindro giratorio, donde permaneció viable durante 3 horas. Sin embargo, la máquina de alta potencia no representa una tos humana normal o típica. En otro <a href="https://www.medrxiv.org/content/10.1101/2020.03.23.20039446v2" target="_blank">estudio</a> no revisado por pares (lo cual significa que no ha sido evaluado por otros expertos), los investigadores tomaron muestras de la presencia de coronavirus en el aire y las superficies de las habitaciones de hospital de 13 pacientes con COVID-19, y se encontró que el 76.5% de todas las pertenencias personales dieron positivo a COVID-19 (teléfono celular, baño, control remoto, cama, etc.). En cuanto a las muestras de aire, el 63.2% fueron positivas al COVID-19, incluso muestras recolectadas a más de 6 pies de distancia del paciente. Sin embargo, la detección del virus no necesariamente significa que resultase en la enfermedad. En adición, concentraciones bajas del virus complican la detección de COVID-19. El autor afirma: "Las bajas concentraciones de virus recuperadas de estas muestras dificultan la búsqueda del virus infeccioso."
                </p>
              </div>}
          /> */}
          <Card 
            id="myth-4"
            thumbnail="/assets/img/shots/myth/myth-4.jpg" 
            title="¿Usando guantes me protegerá al 100% del coronavirus?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>Los guantes sólo ayudan si continúa manteniendo precauciones como no tocarse los ojos, la nariz o la boca, y lavarse las manos después de quitarse los guantes. Puede ocurrir la contaminación cruzada, lo que significa que cualquier cosa que toquen los guantes sucios puede propagar la contaminación a otras superficies.</p>
              </div>}
          />
          <Card 
            id="myth-5"
            thumbnail="/assets/img/shots/myth/myth-5-es.jpg" 
            title="¿Puede sobrevivir el coronavirus en las superficies por días?"
            content={
              <div>
                <p><span className="card-tag-true">Sí</span><a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973?query=featured_home" target="_blank">Un estudio</a> de laboratorio describió que el coronavirus se puede detectar en cartón por hasta 24 horas, y en plástico y acero hasta 72 horas.</p>
                <p>Es importante notar que la cantidad de virus presente en las superficies es inferior al 0.1% del material del virus inicial, y esta presencia no significa automáticamente que usted contraerá la infección. Dado que la cantidad de virus disminuye rápidamente con el tiempo en cada superficie, el riesgo de infección al tocar estas superficies también disminuye con el tiempo.</p>
              </div>}
          />
          <Card 
            id="myth-6"
            thumbnail="/assets/img/shots/myth/myth-6.jpg" 
            title="¿Debería lavarme las manos con frecuencia o desinfectar las superficies de mi hogar aunque no salga de mi casa?"
            content={
              <div>
                <p><span className="card-tag-true">Sí</span>El <a href="https://espanol.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/prevention.html" target="_blank">CDC</a> recomienda limpiar y desinfectar diariamente las superficies que se tocan con frecuencia, como perillas de puertas, interruptores de luz, mesas, encimeras de los muebles, teléfonos, teclados, inodoros, llaves de agua, y fregaderos.</p>
                <p>Usted o un miembro de su familia puede estar infectado sin saberlo. Sin embargo, la probabilidad de que uno se infecte a través de contacto directo con una persona enferma es más alta que la probabilidad de que uno se infecte tocando superficies contaminadas. Aún así, es importante tomar precauciones.</p>
              </div>}
          />
          <Card 
            id="myth-7"
            thumbnail="/assets/img/shots/myth/myth-7.jpg" 
            title="¿Empeora el ibuprofeno la enfermedad en personas con el COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>Actualmente no hay suficiente evidencia científica para probar que el ibuprofeno (marcas Advil, Motrin) empeora los síntomas del COVID-19.</p>
                <p>A partir del 18 de marzo de 2020, la <a href="https://twitter.com/WHO/status/1240409217997189128" target="_blank">OMS</a> no está en contra del uso de ibuprofeno. Si desea cambiar medicamentos, el acetaminofeno (Tylenol) es una alternativa para aliviar la fiebre y el dolor. La <a href="https://www.fda.gov/drugs/drug-safety-and-availability/fda-advises-patients-use-non-steroidal-anti-inflammatory-drugs-nsaids-covid-19" target="_blank">FDA</a> sugiere hablar con su proveedor de atención médica si le preocupa tomar medicamentos antiinflamatorios no esteroideos (AINE), tal como ibuprofeno, si depende de estos medicamentos para tratar enfermedades crónicas.</p>
                </div>}
            extra={
            <div>
              <p>El ibuprofeno pertenece a una clase de medicamentos llamados <a href="https://www.goodrx.com/blog/are-advil-motrin-tylenol-bad-for-coronavirus-symptoms/" target="_blank">AINE</a> (antiinflamatorios no esteroideos) que incluye aspirina, naproxeno (Aleve), diclofenaco (Cambia) y celecoxib (Celebrex), utilizados para tratar fiebre, dolor e inflamación. Cada medicamento tiene sus riesgos y beneficios. Por ejemplo, los AINE pueden aumentar el riesgo de insuficiencia renal y úlceras gastrointestinales. El riesgo más grave del acetaminofeno es la insuficiencia hepática por sobredosis, que a menudo ocurre de forma accidental. El acetaminofeno se encuentra en varios medicamentos comunes de venta libre para la gripe. Lea atentamente la etiqueta de información de sus medicamentos para asegurarse de no exceder la dosis diaria máxima.</p>
              <p><div className="paragraph-header">¿Cómo comenzó el debate sobre el ibuprofeno?</div><a href="https://jvi.asm.org/content/94/7/e00127-20" target="_blank">Estudios</a> sugieren que ACE2, una enzima en el cuerpo humano, proporciona una superficie sobre las cuales las células del coronavirus pueden adherirse a las células del cuerpo e infectarlas.</p>
              <p>El 11 de marzo de 2020, científicos publicaron <a href="https://www.thelancet.com/journals/lanres/article/PIIS2213-2600(20)30116-8/fulltext" target="_blank">una carta</a> en Lancet Respiratory Medicine con una teoría describiendo que la ACE2 elevada puede estar asociada con casos graves de COVID-19. ¿Cómo se relaciona esto al ibuprofeno? El autor sugiere que el ibuprofeno puede causar el aumento de ACE2, y por lo tanto, surgió esta conexión entre el ibuprofeno y el COVID-19.</p>
              <p>Todo esto es especulativo y no hay evidencia científica que sugiere que el ibuprofeno empeora los resultados en pacientes con COVID-19. Sin embargo, el 14 de marzo, el Ministerio de Salud de Francia publicó una advertencia contra el uso de ibuprofeno para la fiebre causada por el COVID-19 y el ministro de salud francés <a href="https://twitter.com/olivierveran/status/1238776545398923264" target="_blank">tuiteó</a> consejos para evitar el ibuprofeno y otros AINE porque podrían ser un "factor agravante". Su consejo se extendió ampliamente en las redes y las noticias erróneamente atribuyeron al ibuprofeno como el causante principal de muertes por COVID-19. El 16 de marzo, los autores de la hipótesis original, que desencadenaron este debate, aclararon que su teoría <a href="https://www.unibas.ch/en/News-Events/News/Uni-Research/Ibuprofen-and-COVID-19-Setting-the-record-straight.html" target="_blank">"no constituye una recomendación para usar ciertas drogas o no."</a></p>
              <p>Para las personas que sufren síntomas de COVID-19, la OMS inicialmente aconsejó en contra del uso de ibuprofeno, pero el 18 de marzo, la <a href="https://www.ema.europa.eu/en/news/ema-gives-advice-use-non-steroidal-anti-inflammatories-covid-19" target="_blank">EMA</a>, <a href="https://www.fda.gov/drugs/drug-safety-and-availability/fda-advises-patients-use-non-steroidal-anti-inflammatory-drugs-nsaids-covid-19" target="_blank">FDA</a>, <a href="https://twitter.com/WHO/status/1240409217997189128" target="_blank">OMS</a> recomendaron no evitar los AINE cuando el uso médico sea necesario.</p>
            </div>}
          />
          <Card 
            id="myth-8"
            thumbnail="/assets/img/shots/myth/myth-8.jpg" 
            title="¿La automedicación con hidroxicloroquina y azitromicina evitará el COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>¡Por favor <strong>NO</strong> se automedique! Sólo tome medicamentos recetados por un médico debido a la posibilidad de interacciones con otros medicamentos y efectos secundarios que podrían ser peligrosos.</p>
                <p>La <a href="https://www.cdc.gov/coronavirus/2019-ncov/hcp/therapeutic-options.html" target="_blank">hidroxicloroquina y la cloroquina</a> son medicamentos recetados por vía oral para la malaria y otras enfermedades inflamatorias (lupus, artritis reumatoide, etc.). La azitromicina es un antibiótico común que se usa para tratar infecciones causadas por ciertas bacterias. Tanto la hidroxicloroquina como la azitromicina están asociadas con efectos secundarios cardíacos y podrían provocar arritmia cardíaca potencialmente mortal, donde el corazón late rápido peligrosamente. Las investigaciones todavía continúan para determinar si estos medicamentos pueden contribuir al tratamiento de COVID-19. Por lo tanto, sólo tome medicamentos recetados por su médico.</p>
              </div>}
          />
          <Card 
            id="myth-9"
            thumbnail="/assets/img/shots/myth/myth-9.jpg" 
            title="¿Son efectivos los antibióticos en la prevención y tratamiento del COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>Según las recomendaciones de la <a href="https://www.who.int/es/emergencies/diseases/novel-coronavirus-2019/advice-for-public/q-a-coronaviruses" target="_blank">OMS</a>, “Los antibióticos son efectivos contra las bacterias, pero no contra los viruses. COVID-19 es un virus, por lo tanto, no se deben utilizar antibióticos para prevenir o tratar la infección. Sin embargo, si hospitalizan a uno por COVID-19, es posible que le administren antibióticos para evitar una co-infección bacteriana.”</p>
              </div>}
          />
          <Card 
            id="myth-11"
            thumbnail="/assets/img/shots/myth/myth-11.jpg" 
            title="Si soy joven y estoy saludable, ¿estoy a salvo del nuevo coronavirus?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>Según las recomendaciones de la <a href="https://www.who.int/es/emergencies/diseases/novel-coronavirus-2019/advice-for-public/q-a-coronaviruses" target="_blank">OMS</a>, “COVID-19 puede infectar a personas de todas edades.”</p>
                <p>Se ha observado que las personas mayores y las que padecen de algunas enfermedades crónicas (como el asma, la diabetes o las cardiopatías) pueden ser más vulnerables a enfermarse gravemente cuando adquieren la infección. La OMS aconseja a las personas de todas las edades a tomar medidas preventivas para protegerse contra el virus (lavado de manos y mascarillas).</p>
              </div>}
            extra={
              <div>
                <p>El <a href="https://www.cdc.gov/mmwr/volumes/69/wr/mm6912e2.htm#F2_down" target="_blank">CDC</a> informó que entre los primeros 4,226 casos (Feb 12 - Marzo 16) en los Estados Unidos, 20% de personas hospitalizadas por COVID-19 tenían entre 20-44 años de edad. Desde el 12 de Abril, aproximadamente 50% de los casos de <a href="https://www.cdph.ca.gov/Programs/CID/DCDC/Pages/Immunization/ncov2019.aspx" target="_blank">COVID-19 confirmados</a> en California ocurrieron en personas menores de 50 años. Aunque la tasa de fatalidad en pacientes jóvenes es más baja, muchos adultos jóvenes sin problemas de salud preexistentes han muerto. Ante este hecho, todos debemos tomar precauciones para protegernos y proteger a los demás.</p>
              </div>}
          />
          <Card 
            id="myth-12"
            thumbnail="/assets/img/shots/myth/myth-12.jpg" 
            title="¿Tengo que ser hospitalizado si tengo el COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>Según las recomendaciones del <a href="https://espanol.cdc.gov/coronavirus/2019-ncov/symptoms-testing/testing.html" target="_blank">CDC</a>, la gran mayoría de las personas con COVID-19 tiene síntomas leves y puede recuperarse en su hogar. Si tiene problemas respiratorios, llame a su médico o visite su sala de emergencia local. Lea nuestra página “Infórmese sobre el COVID-19.”</p>
              </div>}
          />
          <Card 
            id="myth-13"
            thumbnail="/assets/img/shots/myth/myth-13.jpg" 
            title="¿Puedo verificar si tengo el COVID-19 conteniendo la respiración durante 10 segundos o más sin toser o sentir molestias?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>De acuerdo al <a href="https://espanol.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover.html" target="_blank">CDC</a>, usted puede tener COVID-19 sin tener síntomas y también puede transmitir el virus antes de tener síntomas perceptibles. Según la OMS, “Los síntomas más comunes de COVID-19 son tos seca, fatiga y fiebre. Algunas personas pueden desarrollar formas más graves de la enfermedad, como neumonía. Usted no puede confirmar si está infectado en base a este ejercicio de respiración.</p>
              </div>}
          />
          <Card 
            id="myth-14"
            thumbnail="/assets/img/shots/myth/myth-14.jpg" 
            title="¿Son efectivos los secadores de manos y las lámparas de desinfección UV para matar el coronavirus?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>Según las recomendaciones de la <a href="https://www.who.int/es/emergencies/diseases/novel-coronavirus-2019/advice-for-public/q-a-coronaviruses" target="_blank">OMS</a>, “Los secadores de manos no matan el COVID-19. No se deben utilizar lámparas ultravioletas para esterilizar las manos u otras partes del cuerpo, ya que la radiación ultravioleta puede causar irritación de la piel. Para protegerse contra el COVID-19, lávese las manos frecuentemente con agua y jabón. En ausencia, puede usar un gel hidroalcohólico.”</p>
              </div>}
          />
          <Card 
            id="myth-15"
            thumbnail="/assets/img/shots/myth/myth-15.jpg" 
            title="¿Enjuagándose regularmente la nariz con solución salina evitará el COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>Según las recomendaciones de la <a href="https://www.who.int/es/emergencies/diseases/novel-coronavirus-2019/advice-for-public/q-a-coronaviruses" target="_blank">OMS</a>, “No hay evidencia que indique que esta práctica protege contra la infección del coronavirus. Aunque algunos estudios sugieren que enjuagarse la nariz regularmente con solución salina puede acelerar la recuperación tras un resfriado común, no hay evidencia que esta práctica ayude a prevenir infecciones respiratorias.”</p>
              </div>}
          />
          <Card 
            id="myth-16"
            thumbnail="/assets/img/shots/myth/myth-16.jpg" 
            title="¿Las duchas o baños extremadamente calientes previenen el COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>Bañarse con agua demasiado caliente a propósito no previene el COVID-19. Según las recomendaciones de la <a href="https://www.who.int/es/emergencies/diseases/novel-coronavirus-2019/advice-for-public/q-a-coronaviruses" target="_blank">OMS</a>, “Independientemente de la temperatura del agua de la bañera o la ducha, la temperatura corporal se mantiene entre 36.5 °- 37 °C. Temperaturas muy altas pueden producir quemaduras.</p>
              </div>}
          />
          <Card 
            id="myth-17"
            thumbnail="/assets/img/shots/myth/myth-17.jpg" 
            title="¿El clima frío y la nieve previenen el COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>Según <a href="https://www.who.int/es/emergencies/diseases/novel-coronavirus-2019/advice-for-public/q-a-coronaviruses" target="_blank">OMS</a>, “La temperatura normal del cuerpo humano se mantiene entre 36.5°-37°C, independiente de la temperatura exterior o el clima. Por lo tanto, no es cierto que el frío o la nieve eliminan al COVID-19.</p>
              </div>}
          />
          <Card 
            id="myth-18"
            thumbnail="/assets/img/shots/myth/myth-18.jpg" 
            title="¿Comiendo ajo previene el COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>Según las recomendaciones de la <a href="https://www.who.int/es/emergencies/diseases/novel-coronavirus-2019/advice-for-public/q-a-coronaviruses" target="_blank">OMS</a>, “El ajo es un alimento saludable que puede tener algunas propiedades antimicrobianas. Sin embargo, no hay evidencia que sugiera que comer ajo proteja contra el COVID-19.”</p>
              </div>}
          />
          <Card 
            id="myth-19"
            thumbnail="/assets/img/shots/myth/myth-19.jpg" 
            title="¿Bebiendo alcohol previene el COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>Según las recomendaciones de la <a href="https://www.who.int/es/emergencies/diseases/novel-coronavirus-2019/advice-for-public/q-a-coronaviruses" target="_blank">OMS</a>, “Beber alcohol no protegerá contra el COVID-19 y puede ser peligroso. El consumo frecuente o excesivo de alcohol aumenta el riesgo de desarrollar otras enfermedades.”</p>
              </div>}
          />
          <Card 
            id="myth-20"
            thumbnail="/assets/img/shots/myth/myth-20.jpg" 
            title="¿Las vacunas contra la neumonía lo protegen contra el COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>Según las recomendaciones de la <a href="https://www.who.int/es/emergencies/diseases/novel-coronavirus-2019/advice-for-public/q-a-coronaviruses" target="_blank">OMS</a>, “Las vacunas contra la neumonía, como la neumocócica y la vacuna contra Haemophilus influenzae de tipo B (Hib), no protegen contra el nuevo coronavirus. El COVID-19 es un virus nuevo y diferente, por lo tanto requiere el desarrollo de su propia vacuna. Aunque las vacunas contra la neumonía no son eficaces contra el COVID-19 es recomendado vacunarse contra otras enfermedades respiratorias para mantener su salud.”</p>
              </div>}
          />
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Myth;
