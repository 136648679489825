import React, { Component } from 'react'
import classnames from 'classnames'
import { Link, withRouter } from 'react-router-dom'

import Button from '../Components/Button'
import ClipboardButton from '../Components/ClipboardButton'

class Aside extends React.PureComponent {

  render() {

    return (
      <div className="content-aside desktop">
        <div className="content-share">
          <h4 className="content-share-caption">공유하기</h4>
          <div className="content-share-options">
            <div className="content-share-item content-share-twitter">
              <img className="fit-height" src="/assets/img/icons/twitter.png" />
              <a className="twitter-share-button" href="https://twitter.com/intent/tweet?text=%EC%BD%94%EB%A1%9C%EB%82%98%20%EB%B0%94%EC%9D%B4%EB%9F%AC%EC%8A%A4%EC%97%90%20%EB%8C%80%ED%95%9C%20%ED%99%95%EC%8B%A4%ED%95%9C%20%EC%A0%95%EB%B3%B4%2C%20COVID-19%20Fact%20Check%EB%A5%BC%20%ED%86%B5%ED%95%B4%EC%84%9C%20%EC%95%8C%EC%95%84%EB%B3%B4%EC%84%B8%EC%9A%94%21&tw_p=tweetbutton&url=http%3A%2F%2Fwww.covid19factcheck.com%2Fko%2F" target="_blank">Tweet</a>
            </div>
            <div className="content-share-item content-share-facebook">
              <img className="fit-height" src="/assets/img/icons/facebook.png" />
              <a className="fb-share-button" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=http://www.covid19factcheck.com/ko/"></a>
            </div>
            <div className="content-share-item content-share-email">
              <img className="fit-height" src="/assets/img/icons/email.svg" />
              <a href="mailto:?body=%EC%BD%94%EB%A1%9C%EB%82%98%20%EB%B0%94%EC%9D%B4%EB%9F%AC%EC%8A%A4%EC%97%90%20%EB%8C%80%ED%95%9C%20%ED%99%95%EC%8B%A4%ED%95%9C%20%EC%A0%95%EB%B3%B4%2C%20COVID-19%20Fact%20Check%EB%A5%BC%20%ED%86%B5%ED%95%B4%EC%84%9C%20%EC%95%8C%EC%95%84%EB%B3%B4%EC%84%B8%EC%9A%94%21%20http%3A%2F%2Fwww.covid19factcheck.com%2Fko%2F"></a>
            </div>
            <ClipboardButton className="content-share-item content-share-link" value="http://www.covid19factcheck.com/ko/" success="링크가 복사되었습니다! 🎉"><img className="fit-height" src="/assets/img/icons/link.svg" /></ClipboardButton>
          </div>
        </div>
        <h5 className="content-footnote">콘텐츠 출처: <a href="https://www.who.int/" target="_blank">세계보건기구(WHO)</a> & <a href="https://www.cdc.gov/" target="_blank">미국 질병관리본부(CDC)</a><br /> 마지막 업데이트 2020년 5월 18일</h5>
      </div>
    )
  }
}

export default withRouter(Aside)