import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Myth extends Component {

  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <div className="content-nav-scroller">
            <h4 className="content-nav-caption">Оглавление</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-1')}>Мне обязательно держаться на расстоянии 2 метров от других, когда я ношу маску?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-10')}>Могу ли я предотвратить COVID-19 употребив во внутрь хлорку (отбеливатель) или другое дезинфицирующее средство?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-2')}>Нужно ли мне купить хирургические маски или респиратор N95?</h4>
            {/* <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-3')}>Can coronavirus spread far distances through the air?</h4> */}
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-4')}>Ношение перчаток на 100% защитит меня от коронавируса?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-5')}>Может ли коронавирус выживать на различных поверхностях в течении нескольких дней?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-6')}>Должен ли я часто мыть руки или дезинфицировать поверхности, даже если я не выхожу из дома?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-7')}>Ухудшает ли ибупрофен тяжесть заболевания у людей с COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-8')}>Предотвращает ли прием гидроксихлорохина и азитромицина заболевание Ковидом-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-9')}>Эффективны ли антибиотики в предотвращение и лечении COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-11')}>Если я молодой и здоровый защищен ли я от нового коронавируса?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-12')}>Должен ли я быть госпитализирован если я заражен COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-13')}>Могу ли я проверить есть ли у меня COVID-19, задержав дыхание на 10 секунд или дольше без кашля или ощущения дискомфорта?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-14')}>Эффективно ли использование электросушителей для рук и ультрафиолетовых ламп в уничтожении коронавируса?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-15')}>Регулярное промывание носа солевыми растворами предотвращает COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-16')}>Горячий душ или ванна защищают от COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-17')}>Холодная погода и снег защищают от COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-18')}>Употребление в пищу чеснока защищает от COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-19')}>Употребление алкоголя внутрь защищает от COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-20')}>Защищают ли от коронавируса вакцины от пневмонии?</h4>
          </div>
        </div>
        <div className="content-body">
          <Card 
            id="myth-1"
            thumbnail="/assets/img/shots/myth/myth-1.jpg" 
            title="Мне обязательно держаться на расстоянии 2 метров от других, когда я ношу маску?"
            content={
              <div>
                <p><span className="card-tag-true">Да</span>Продолжайте находится на расстояние 2 метров от других; часто мойте руки, в том числе перед надеванием и после снятия маски.</p>
                <p>В США, <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover-faq.html" target="_blank">CDC</a> рекомендует использовать маски из ткани для предотвращения распространение вируса посредством капель, образующихся в результате чихание, кашля, и при разговоре. В зависимости от ткани и посадки, маски могут обеспечивать некоторую защиту, но не от всех вирусных частиц. Пожалуйста посетите нашу страницу «Полезные советы».</p>
              </div>}
            extra={
              <div>
                <p>Пожалуйста, следуйте рекомендации <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a> и используйте безопасные бытовые материалы и убедитесь что маска плотно прилегает, и не препятствует дыхание. CDC рекомендует несколько слоев ткани и не рекомендует определенный тип ткани.</p>
                <p><div className="paragraph-header">Насколько эффективны тканевые маски против коронавируса?</div>Отсутствуют научные доказательства чтобы ответить на этот вопрос. В <a href="https://www.ncbi.nlm.nih.gov/pubmed/24229526" target="_blank">лабораторном эксперименте</a> в котором использовался другой вирус исследователи обнаружили, что ни одна из тканей (100% хлопковая рубашка, шарф, шелк, наволочка, бельё, хлопковая смесь, кухонное полотенце) не может эффективно блокировать 100% вирусных частиц из-за их небольшого размера. Однако этот эксперимент не симулирует нормальный человеческий кашель. Также не учитывалось насколько плотно установлены маски. Поскольку большинство масок не обеспечивают герметичное уплотнение, вирусные частицы могут проникать через <a href="https://www.gannett-cdn.com/experiments/usatoday/gifs/tool/919-surgical.png" target="_blank">боковые стороны маски.</a></p>
                <p><div className="paragraph-header">Ключевые моменты</div>Тканевые маски могут помочь предотвратить инфекцию, но не обеспечивают 100% защиту.</p>
              </div>}
          />
          <Card 
            id="myth-10"
            thumbnail="/assets/img/shots/myth/myth-10.jpg" 
            title="Могу ли я предотвратить COVID-19 употребив во внутрь хлорку (отбеливатель) или другое дезинфицирующее средство?"
            content={
              <div>
                <p><span className="card-tag-false">Нет</span>Пожалуйста,<strong> НЕ <a href="https://www.rb.com/media/news/2020/april/improper-use-of-disinfectants" target="_blank">вводите</a>, не вдыхайте, не пейте и не распыляйте</strong> дезинфицирующий спирт или хлор (отбеливатель) по всему телу, потому что это <strong className="red">опасно.</strong></p>
                <p>Согласно <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">ВОЗ</a>,  «эти вещества могут быть ядовитыми при попадании в организм и вызывать раздражение и повреждение кожи и глаз». Отбеливатель и дезинфицирующее средство должны использоваться только для дезинфекции поверхностей в соответствии с <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cleaning-disinfection.html" target="_blank">рекомендациями</a>. Не забудьте держать дезинфицирующие средства в недоступном для детей месте.</p>
              </div>}
          />
          <Card 
            id="myth-2"
            thumbnail="/assets/img/shots/myth/myth-2.jpg" 
            title="Нужно ли мне купить хирургические маски или респиратор N95?"
            content={
              <div>
                <p><span className="card-tag-false">Нет</span><a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a> рекомендует тканевые маски для лица для широкой публики в США.</p>
                <p>Вы можете носить любые маски которые у вас уже есть дома, но вам не нужно специально покупать хирургические маски или N95. Из-за нехватки в США, маски медицинского уровня должны быть зарезервированы для медицинских работников, которые обучены их правильному использованию и находятся в тесном контакте с больными пациентами. Подумайте o пожертвование дополнительных масок N95, так как N95 необходимы для предотвращения воздушно-капельной передачи, но COVID-19 в основном передается через дыхательные капли. Пожалуйста смотрите ниже насчет мифа о воздушно-капельной передачи.</p>
              </div>}
            extra={
              <div>
                <p><a href="https://www.fda.gov/medical-devices/personal-protective-equipment-infection-control/n95-respirators-and-surgical-masks-face-masks" target="_blank">Респиратор N95</a> имеет специальный фильтр, который, при правильном ношении с воздухонепроницаемым лицевым уплотнением, может блокировать не менее 95% мелких частиц (0,3 микрона). Все медицинские работники проходят обучение обращению с респиратором. Кроме того проводятся  ежегодные <a href="https://www.safetyalliance.org/wp-content/uploads/2019/06/fit-test-2-000110_retouched-672x372.jpg" target="_blank">проверки</a> на предмет пригодности респиратора, который должен быть правильно подобран по размеру для обеспечения воздухопроницаемости. Следует отметить, что при правильном ношении в респираторе может  быть трудно дышать. Есть много причин, по которым респиратор может не прилегать плотно  к лицу (например борода). <a href="https://blogs.cdc.gov/niosh-science-blog/2018/01/04/respirators-public-use/" target="_blank">В силу сказанного</a>, респиратор N95 не обеспечивает широкой публике тот уровень защиты, для которого он предназначен.</p>
              </div>}
          />
          {/*
          <Card 
            id="myth-3"
            thumbnail="/assets/img/shots/myth/myth-3.jpg" 
            title="Can coronavirus spread far distances through the air?"
            content={
              <div>
                <p><span className="card-tag-maybe">Может быть</span>Airborne transmission is unlikely for the general public.</p>
                <p>According to <a href="https://www.who.int/news-room/q-a-detail/q-a-coronaviruses" target="_blank">ВОЗ</a>, “COVID-19 is mainly transmitted through droplets generated when an infected person coughs, sneezes, or speaks. These droplets are too heavy to hang in the air. They quickly fall on floors or surfaces.” No airborne transmission was reported among an analysis of over 75,000 COVID-19 cases in China. However, health care workers may be at risk. ВОЗ reports airborne transmission may be possible during <a href="https://www.who.int/news-room/commentaries/detail/modes-of-transmission-of-virus-causing-covid-19-implications-for-ipc-precaution-recommendations" target="_blank">hospital procedures that generate aerosols</a> such as intubation, where a breathing tube is inserted into the patient’s airway to help them breathe.</p>
                </div>}
            extra={
              <div>
                <p><div className="paragraph-header">What does airborne transmission mean?</div>In contrast to respiratory droplets, airborne particles are smaller and remain in the air for long periods of time where they can spread farther than 6 feet.</p>
                <p><div className="paragraph-header">Why does the news say coronavirus is airborne?</div>A NEJM <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973" target="_blank">study</a> artificially created aerosols of the coronavirus in a rotating cylinder, where it remained viable for 3 hours. However, the high-powered machine does not represent a normal human cough. In a non peer-reviewed <a href="https://www.medrxiv.org/content/10.1101/2020.03.23.20039446v2" target="_blank">study</a> (has not been evaluated by other experts), researchers sampled for the presence of coronavirus in the air and surfaces of COVID-19 patients’ hospital room. 76.5% of all personal belongings were positive (cell phones, toilets, remotes, bed rails, etc.). For air samples, 63.2% were positive and some samples were collected greater than 6 feet away from the patient. However, the presence of a virus does not automatically mean it can cause disease. For example, the amount of virus may be too low to cause infection. The authors have not proven that the components of the virus that they have detected can cause infections.</p>
              </div>}
          /> */}
          <Card 
            id="myth-4"
            thumbnail="/assets/img/shots/myth/myth-4.jpg" 
            title="Ношение перчаток на 100% защитит меня от коронавируса?"
            content={
              <div>
                <p><span className="card-tag-false">Нет</span>Перчатки помогут только при соблюдении других мер предосторожности: не следует касаться глаз, носа, рта, и обязательно мыть руки после снятия перчаток. Может произойти перекрестное загрязнение, то есть, любое к чему ваши грязные перчатки прикасались, может распространиться на эти поверхности.</p>
              </div>}
          />
          <Card 
            id="myth-5"
            thumbnail="/assets/img/shots/myth/myth-5-ru.jpg" 
            title="Может ли коронавирус выживать на различных поверхностях в течении нескольких дней?"
            content={
              <div>
                <p><span className="card-tag-true">Да</span>Исследования в <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973?query=featured_home" target="_blank">NEJM</a> показывают, что коронавирус может выживать на картоне до 24 часов и на пластике и металле до 72 часов.</p>
                <p>Важно сказать, что количество вируса которого остается составляет менее 0,1% от исходного вирусного материала. Наличие вируса не означает, что он автоматически может быть заразным. Поскольку количество вируса быстро уменьшалось со временем на каждой поверхности, риск контакта с ним также должен уменьшаться со временем.</p>
              </div>}
          />
          <Card 
            id="myth-6"
            thumbnail="/assets/img/shots/myth/myth-6.jpg" 
            title="Должен ли я часто мыть руки или дезинфицировать поверхности, даже если я не выхожу из дома?"
            content={
              <div>
                <p><span className="card-tag-true">Да</span><a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/prevention.html" target="_blank">СДС</a> рекомендует ежедневно протирать и дезинфицировать часто касаемые поверхности, такие как дверные ручки, выключатели, столы, кухонные прилавки, телефоны, клавиатуры, унитазы, краны и раковины.</p>
                <p>Вы или член семьи могут быть заражены не подозревая об этом. Хотя вы имеете больше шансов заразиться при тесном контакте с больным человеком, чем от загрязненных поверхностей, по возможности соблюдайте меры предосторожности.</p>
              </div>}
          />
          <Card 
            id="myth-7"
            thumbnail="/assets/img/shots/myth/myth-7.jpg" 
            title="Ухудшает ли ибупрофен тяжесть заболевания у людей с COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">Нет</span>В настоящее время недостаточно доказательств, что Ибупрофен (другое наименование Advil/Адвил, Motrin/Мотрин) ухудшает симптомы COVID-19.</p>
                <p><a href="https://twitter.com/ВОЗ/status/1240409217997189128" target="_blank">ВОЗ</a> нет рекомендаций по использованию Ибупрофена (18 марта 2020). Парацетамол (другое наименование ацетаминофен, Tylenol/Тайленол) является альтернативным средством от температуры и боли. <a href="https://www.fda.gov/drugs/drug-safety-and-availability/fda-advises-patients-use-non-steroidal-anti-inflammatory-drugs-nsaids-covid-19" target="_blank">FDA</a> рекомендует поговорить с вашим врачом, если вы беспокоитесь о приеме этих нестероидных противовоспалительных препаратов (НПВП) и рассчитываете на эти препараты для лечения хронических заболеваний.</p>
                </div>}
            extra={
            <div>
              <p>Ибупрофен входит в группу препаратов именуемых <a href="https://www.goodrx.com/blog/are-advil-motrin-tylenol-bad-for-coronavirus-symptoms/" target="_blank">НПВП</a>, которые включают Аспирин, Напроксен (Aleve/Алив), Диклофенак (Cambia/Камбия) и Целекоксиб (Celebrex/Селебрекс), используемых для лечения лихорадки, боли и воспаления. Каждое лекарство имеет свои недостатки и преимущества. Например, НПВП могут поражать почки и желудочнокишечный тракт. Наиболее серьезным риском ацетаминофена является печеночная недостаточность, вызванная передозировкой, часто случайной. Парацетамол (Ацетаминофен) содержится в различных безрецептурных лекарствах от простуды и гриппа. Пожалуйста, внимательно прочитайте все этикетки лекарств, чтобы убедиться, что вы не превышаете максимальную суточную дозу.</p>
              <p><div className="paragraph-header">Как начались дебаты по ибупрофену:</div><a href="https://jvi.asm.org/content/94/7/e00127-20" target="_blank">Исследования</a> показывают что АПФ2 (ACE2), фермент в организме человека, обеспечивает место на поверхностях клеток, чтобы коронавирус был способен прикрепляться и инфицировать клетки.</p>
              <p>11 марта 2020 г. исследователи опубликовали в научном журнале Lancet Respiratory Medicine <a href="https://www.thelancet.com/journals/lanres/article/PIIS2213-2600(20)30116-8/fulltext" target="_blank">письмо</a> с теорией, что повышенный уровень АПФ2 может быть связан с тяжелыми случаями COVID-19. Как это соотносится с ибупрофеном? Автор предполагает, что ибупрофен может вызвать повышение уровня АПФ2. Таким образом, возникла связь между ибупрофеном и COVID-19.</p>
              <p>Все это является спекулятивным, и никакие научные доказательства не показали, что ибупрофен непосредственно ухудшал результаты у пациентов с COVID-19. Однако 14 марта министерство здравоохранения Франции <a href="https://twitter.com/olivierveran/status/1238776545398923264" target="_blank">опубликовало</a> предупреждение против использования ибупрофена при заболевании COVID-19, а министр здравоохранения Франции написал в Твиттере совет о том, как избегать ибупрофена и других НПВП, поскольку они могут стать «отягчающим фактором». Его советы широко распространились по всем новостям, и появились статьи в которых ибупрофен назывался основной причиной смерти от COVID-19. 16 марта авторы исходной гипотезы, которая вызвала дебаты, прояснили свою теорию, что она <a href="https://www.unibas.ch/en/News-Events/News/Uni-Research/Ibuprofen-and-COVID-19-Setting-the-record-straight.html" target="_blank">«не является рекомендацией по использованию или не использованию определенных препаратов».</a></p>
              <p>Для людей, страдающих от симптомов COVID-19, ВОЗ изначально рекомендовала не применять ибупрофен. <strong>18 марта <a href="https://www.ema.europa.eu/en/news/ema-gives-advice-use-non-steroidal-anti-inflammatories-covid-19" target="_blank">EMA</a>, <a href="https://www.fda.gov/drugs/drug-safety-and-availability/fda-advises-patients-use-non-steroidal-anti-inflammatory-drugs-nsaids-covid-19" target="_blank">FDA</a>, <a href="https://twitter.com/ВОЗ/status/1240409217997189128" target="_blank">ВОЗ</a> посоветовали не избегать приема НПВП если они необходимы.</strong></p>
            </div>}
          />
          <Card 
            id="myth-8"
            thumbnail="/assets/img/shots/myth/myth-8.jpg" 
            title="Предотвращает ли прием гидроксихлорохина и азитромицина заболевание Ковидом-19?"
            content={
              <div>
                <p><span className="card-tag-false">Нет</span>Пожалуйста не занимайтесь самолечением. Принимайте только лекарства по рецепту выписанному доктором, поскольку лекарства могут иметь опасные взаимодействия с другими лекарствами и серьезные побочные эффекты.</p>
                <p><a href="https://www.cdc.gov/coronavirus/2019-ncov/hcp/therapeutic-options.html" target="_blank">Гидроксихлорохин и хлорохин</a> - это лекарства, принимаемые орально, для лечения малярии и других воспалительных заболеваний, таких как системная красная волчанка и ревматоидный артрит. Азитромицин - это антибиотик, который используется для лечения инфекций, вызываемых определенными бактериями. Оба препарата гидроксихлорохин и азитромицин ассоциируются с побочными эффектами, влияющими на сердце и могут привести к опасной для жизни сердечной аритмии, ассоциирующейся с быстрым сердцебиением. Текущие исследования расследовают если эти препараты могут быть эффективны для лечения COVID-19, но принимать их следует только по назначению врача.</p>
              </div>}
          />
          <Card 
            id="myth-9"
            thumbnail="/assets/img/shots/myth/myth-9.jpg" 
            title="Эффективны ли антибиотики в предотвращение и лечении COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">Нет</span>Согласно <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">ВОЗ</a>, антибиотики используются только для лечения инфекций, вызываемых бактериями, не вирусами. Антибиотики не должны использоваться для предупреждения и лечения вирусов. Тем не менее, если вы госпитализированы с COVID-19, вы можете получать антибиотики, поскольку возможна сопутствующая бактериальная инфекция.</p>
              </div>}
          />
          
          <Card 
            id="myth-11"
            thumbnail="/assets/img/shots/myth/myth-11.jpg" 
            title="Если я молодой и здоровый защищен ли я от нового коронавируса?"
            content={
              <div>
                <p><span className="card-tag-false">Нет</span>Согласно <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">ВОЗ</a>, люди всех возрастов могут быть инфицированы новым коронавирусом.</p>
                <p>Пожилые люди и люди с сопутствующими медицинскими проблемами, такими как астма, диабет, заболевания сердца, более подвержены тяжелым формам заболевания вызванного вирусом. ВОЗ рекомендует людям всех возрастов предпринимать шаги для защиты себя от коронавируса.</p>
              </div>}
            extra={
              <div>
                <p>Агенство <a href="https://www.cdc.gov/mmwr/volumes/69/wr/mm6912e2.htm#F2_down" target="_blank">CDC</a> сообщает, что в числе первых 4226 случаев, с 12 февраля по 16 марта, в США один из пяти госпитализированных был в возрасте от 20 до 44 лет. По состоянию на 12 апреля около половины подтвержденных случаев <a href="https://www.cdph.ca.gov/Programs/CID/DCDC/Pages/Immunization/ncov2019.aspx" target="_blank">COVID-19 в Калифорнии</a> встречаются у людей моложе 50 лет. Хотя смертность ниже среди молодых пациентов, многие молодые люди без сопутствующих медицинских проблем умерли. Каждый человек должен предпринимать меры предосторожности, чтобы защитить себя и других.</p>
              </div>}
          />
          <Card 
            id="myth-12"
            thumbnail="/assets/img/shots/myth/myth-12.jpg" 
            title="Должен ли я быть госпитализирован если я заражен COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">Нет</span>Согласно <a href="https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/testing.html" target="_blank">CDC</a>, большинство людей с заболеванием средней тяжести могут находиться и выздороветь дома. Оставайтесь дома и выходите только если нужна медицинская помощь. Немедленно обратитесь за медицинской помощью если у вас проблемы с дыханием, но предварительно позвоните врачу или скорую помощь. Пожалуйста, посмотрите страницу "Что нужно знать о COVID-19".</p>
              </div>}
          />
          <Card 
            id="myth-13"
            thumbnail="/assets/img/shots/myth/myth-13.jpg" 
            title="Могу ли я проверить есть ли у меня COVID-19, задержав дыхание на 10 секунд или дольше без кашля или ощущения дискомфорта?"
            content={
              <div>
                <p><span className="card-tag-false">Нет</span>Согласно СДС, у вас может быть коронавирус без симптомов и вы можете передавать вирус до проявления симптомов. Согласно <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">ВОЗ</a>: "Наиболее частыми симптомами COVID-19 являются сухой кашель, слабость и температура. У некоторых людей могут развиться более тяжелые формы заболевания, такие как воспаление легких. Это невозможно подтвердить с помощью дыхательных упражнений."</p>
              </div>}
          />
          <Card 
            id="myth-14"
            thumbnail="/assets/img/shots/myth/myth-14.jpg" 
            title="Эффективно ли использование электросушителей для рук и ультрафиолетовых ламп в уничтожении коронавируса?"
            content={
              <div>
                <p><span className="card-tag-false">Нет</span>Согласно <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">ВОЗ</a>, "электросушители для рук не эффективны в уничтожении коронавируса. Ультрафиолетовые лампы не должны использоваться для стерилизации рук и других частей тела, так как ультрафиолетовое излучение может вызвать раздражение кожи. Для защиты себя от нового коронавируса необходимо часто мыть руки с водой и мылом или спиртосодержащими санитайзерами."</p>
              </div>}
          />
          <Card 
            id="myth-15"
            thumbnail="/assets/img/shots/myth/myth-15.jpg" 
            title="Регулярное промывание носа солевыми растворами предотвращает COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">Нет</span>Согласно <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">ВОЗ</a>, "нет доказательств, что регулярное промывание носа солевым раствором защищает людей от инфекции, вызванной новым коронавирусом. Есть некоторые доказательства, что регулярное промывание носа солевым раствором приводит к более быстрому выздоровлению от простуды. Тем не менее, отсутствует доказательство того что регулярное промывание носа защищает от респираторных инфекций."</p>
              </div>}
          />
          <Card 
            id="myth-16"
            thumbnail="/assets/img/shots/myth/myth-16.jpg" 
            title="Горячий душ или ванна защищают от COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">Нет</span>Вы должны принимать душ или ванну, но пожалуйста специально не используйте сильно горячую воду. Согласно <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">ВОЗ</a>: "нормальная температура вашего тела 36.5 - 37C независимо от температуры воды в душе или ванне. В действительности прием сильно горячей ванны или душа вредно, так как может вызвать ожоги.</p>
              </div>}
          />
          <Card 
            id="myth-17"
            thumbnail="/assets/img/shots/myth/myth-17.jpg" 
            title="Холодная погода и снег защищают от COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">Нет</span>Согласно <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">ВОЗ</a>: "холодная погода и снег не убивают новый коронавирус. Нормальная температура тела человека остается постоянной 36.5 - 37C независимо от внешней температуры или погоды."</p>
              </div>}
          />
          <Card 
            id="myth-18"
            thumbnail="/assets/img/shots/myth/myth-18.jpg" 
            title="Употребление в пищу чеснока защищает от COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">Нет</span>Согласно <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">ВОЗ</a>: "чеснок является продуктом здорового питания который может иметь некоторые антимикробные свойства. Но нет доказательств, что при данной вспышке заболевания употребление чеснока в пищу защищает людей от нового коронавируса."</p>
              </div>}
          />
          <Card 
            id="myth-19"
            thumbnail="/assets/img/shots/myth/myth-19.jpg" 
            title="Употребление алкоголя внутрь защищает от COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">Нет</span>Согласно <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">ВОЗ</a>: "употребление алкоголя внутрь не защищает вас от COVID-19 и может быть опасным. Частое и чрезмерное употребление алкоголя повышает риск возникновения проблем со здоровьем"</p>
              </div>}
          />
          <Card 
            id="myth-20"
            thumbnail="/assets/img/shots/myth/myth-20.jpg" 
            title="Защищают ли от коронавируса вакцины от пневмонии?"
            content={
              <div>
                <p><span className="card-tag-false">Нет</span>Согласно <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">ВОЗ</a>: "вакцины против пневмонии, например пневмококковая вакцина или вакцина против гемофильной палочки типа В (Hib-вакцина), не защищают от нового коронавируса. Этот вирус принципиально отличается от других и для него требуется специальная вакцина. Несмотря на то, что эти вакцины неэффективны против нового коронавируса, вакцинация против других респираторных заболеваний рекомендуется для защиты вашего здоровья."</p>
              </div>}
          />
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Myth;
