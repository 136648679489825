import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Learn extends Component {

  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <h4 className="content-nav-caption">Table of Contents</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-1')}>Vakai Fakalūkufua´</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-2')}>‘Oku anga fēfē mafola ‘a e COVID-19?</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-3')}>Ngaahi faka’ilonga´</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-4')}>Fēfē kapau te u ongo’i puke?</h4>
        </div>
        <div className="content-body">
          <Card 
            id="learn-1"
            icon="/assets/img/icons/info.svg" 
            title="Vakai Fakalūkufua´"
            content={
              <div>
                <p>Ko e Kolonavailasi´ ko ha kalasi vailasi ‘oku tupunga mei ai he ngaahi mahaki angamaheni hotau halanga mānava´ ‘o hangē ko e fofonu´.</p>
                <p>Ne ma’u ha kalasi fo’ou ‘o e Kolonavailasi´ he ta’u 2019´. Ko e hingoa fakasaienisi ‘o e vailasi´ ko e SARS-CoV-2 pea ko e mahaki ‘oku tupu mei ai´ ne fakahingoa ko e: coronavirus disease 2019 (COVID-19).</p>
              </div>}
          />
          <Card 
            id="learn-2"
            icon="/assets/img/icons/spread.svg" 
            title="‘Oku anga fēfē mafola ‘a e COVID-19?"
            content={
              <div>
                <p><div className="paragraph-header">Mei he tangata ki he tangata</div>Ko e tefito’i founga e mafola ‘a e kolonavailasi´, ko e mao ko ia ‘i he’ete mānava ki tu’a´. ‘O ka ke tu’u mo ha taha puke ‘o ofi ange ‘i he fute ‘e 6 (pe mita ‘e 2), ‘e lava ke pipihi atu e siemu he’enau tale´, mafatua´ mo e lea´ ‘o tu’u ho ngutu´, ihuu´, mata´ pe ko ha’o mānava ki he loto ma’ama’a´.</p>
                <p><div className="paragraph-header">Ngaahi Funga me’a kuo pā ki ai e vailasi´.</div>‘E malava ke ke puke ‘o ka ke ala ki ha funga me’a ne pipiki ki ai e vailasi´ pea ke ala leva mei ai ki ho ngutu´, mata´ pe ihuu´. ‘Oku kau ai heni e ngaahi kau’i matapaa´, kamosi ‘uhila´, telefoni to’oto’o´, tēpile´, kīpooti´, toileti´, paipa vai´ mo e ngaahi singi´.</p>
              </div>}
          />
          <Card 
            id="learn-3"
            icon="/assets/img/icons/symptoms.svg" 
            title="Ngaahi faka’ilonga´"
            content={
              <div>
                <div className="content-symptom-container">
                  <ul className="content-symptom-category">
                    <div className="content-symptom-title">Faka’ilonga angamaheni</div>
                    <li className="content-symptom-item">Mofi (100.4°F, 38°C)</li>
                    <li className="content-symptom-item">Tale Pakupaku´</li>
                    <li className="content-symptom-item">Nounou ‘o e mānava´</li>
                  </ul>
                  <ul className="content-symptom-category">
                    <div className="content-symptom-title">Ngaahi Faka’ilonga Kehe´</div>
                    <li className="content-symptom-item">Fakamokosia</li>
                    <li className="content-symptom-item">Felāngaaki</li>
                    <li className="content-symptom-item">Mamahi e monga´</li>
                    <li className="content-symptom-item">Mole nanamu´ mo e ‘ahi’ahi ifo´</li>
                    <li className="content-symptom-item">Tāvaivaia</li>
                    <li className="content-symptom-item">Fofonu</li>
                    <li className="content-symptom-item">Tokakovi</li>
                    <li className="content-symptom-item">Fakalele</li>
                  </ul>
                </div>
                <div className="content-symptom-caption">‘Oku tokolahi e ni’ihi ‘e puke ‘i he vailasi´ ka ‘e ‘ikai te nau ma’u hono ngaahi faka’ilonga´.</div>
              </div>}
          />
          <Card 
            id="learn-4"
            icon="/assets/img/icons/sick.svg" 
            title="Fēfē kapau te u ongo’i puke?"
            content={
              <div>
                <p>Fakatatau ki he lau ‘a e CDC´ ko e tokolahi taha´ ‘e puke si’isi’i pē pea lava pē ‘o fakaakeake ‘i ‘api. ‘Oua na’a´ ke mavahe mei ho ‘api´, tukukehe kapau ko e kumi ki ha tokoni fakafaito’o. ‘O kapau ‘oku nounou ho mānava´, kumi ki ha tokoni fakafaito’o kae fakapapau’i kuo ke ‘uluaki telefoni ki he falemahaki´ pe fale ‘oku´ ke kumi faito’o ki ai´.</p>
                <hr />
                <p>
                  <ul className="content-list">
                    <div>Fetu’utaki ki he fika 911 ‘o ka ‘i ai ha’o fiema’u faito’o fakavavevave ‘o kau ai e:</div>
                    <li>Mānavatamaki´ (Faingata’a e mānava´)</li>
                    <li>Langa pe mamahi e fatafata´</li>
                    <li>Hē ‘a e fakakaukau´ mo e ta’elava e fakalanga holi´</li>
                    <li>Lanulanu pulū e mata´ pe loungutu´</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <ul className="content-list">
                    <div>Ki ha toe fakaikiiki fekau’aki mo e kumi ki ha ngaahi tokoni fakafaito’o pe fakakomiunitī´, fetu’utaki ki he potungāue mo’ui ma’a´ e kakai (Public Health Department) ‘o ho kolo´. Lomi <a href="https://covid19.ca.gov/state-local-resources/#top" target="_blank">heni</a> ki he fakaikiiki ‘o e ngaahi potungāue ‘i Kalefōnia´</div>
                    <li><a href="https://www.sccgov.org/sites/phd/DiseaseInformation/novel-coronavirus/Pages/home.aspx" target="_blank">Santa Clara County:</a> 211 ke ma’u atu ha ngaahi fakamatala fekau’aki mo e COVID-19 pehē ki he ngaahi tokoni kehe ‘a e komiunitii´ ‘o hangē ko e me’atokoni, feitu’u nofo’anga, fai fale’i´, tokoni ma’ungāue´, tokanga’i ‘o e fānau iiki´, sēvesi ki he kau toulekeleka mo e ngaahi me’a kehe (ma’u atu he houa ‘e 24, ‘aho ‘e 7 he uike)</li>
                    <li><a href="https://sf.gov/topics/coronavirus-covid-19" target="_blank">San Francisco:</a> 311 (Mei loto SF) or 415-701-2311(ma’u atu he houa ‘e 24, ‘aho ‘e 7 he uike)</li>
                    <li><a href="http://publichealth.lacounty.gov/media/Coronavirus/" target="_blank">Los Angeles:</a> 211 (ma’u atu he houa ‘e 24, ‘aho ‘e 7 he uike)</li>
                    <li><a href="https://covid19info.ocgov.com/" target="_blank">Orange County:</a> 211 (ma’u atu he houa ‘e 24, ‘aho ‘e 7 he uike)</li>
                    <li><a href="https://www.sandiegocounty.gov/coronavirus.html" target="_blank">San Diego:</a> 211 (ma’u atu he houa ‘e 24, ‘aho ‘e 7 he uike)</li>
                    <li><a href="https://www.co.fresno.ca.us/departments/public-health/covid-19" target="_blank">Fresno:</a> (559) 600-4636 (8a-5p)</li>
                    <li><a href="http://www.acphd.org/2019-ncov.aspx" target="_blank">Alameda:</a> (510) 268-2101</li>
                    <li><a href="https://www.coronavirus.cchealth.org/" target="_blank">Contra Costa:</a> (844) 729-8410 (8a-5p)</li>
                  </ul>
                </p>
                <hr />
                <p>Kātaki ‘o vakai ki he <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/disinfecting-your-home.html" target="_blank">uepisaiti ‘o e CDC´</a> ki ha ngaahi fale’i tokoni fekau’aki mo hono fakasi’isi’i e pipihi ‘a e mahaki´ ‘o kapau kuo fokoutua ai ha taha</p>
              </div>}
          />
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Learn;
