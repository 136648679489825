import React, { Component } from 'react'
import classnames from 'classnames'
import {
  BrowserRouter as Router,
  Link,
  Route,
  useLocation,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom'

import Button from '../Components/Button'

class Header extends React.PureComponent {

  render() {

    const { toggleLanguageModalActive, toggleShareModalActive, path } = this.props;

    return (
      <div className="header-container">
        <div className="header grid">
          <h1 className="header-title">COVID-19<br />Fact Check</h1>
          <div className="header-description">በተሻለ ለመዘጋጀት እና ለሌሎች እንዲያውቁ ለማድረግ ትክክለኛውን መረጃ ይወቁ ፡፡</div>
          <div className="header-action mobile">
            <Button className="btn-secondary" label="አማርኛ" imgURL="/assets/img/icons/arrow_down.svg" onClick={toggleLanguageModalActive} />
            <Button className="btn-primary" label="ያጋሩ" imgURL="/assets/img/icons/share.svg" onClick={toggleShareModalActive} />
          </div>
          <div className="header-caption mobile">
            <h5 className="header-caption-item">አብዛኛው መረጃ የተገኘው ከ <a href="https://www.who.int/" target="_blank">WHO</a> እና <a href="https://www.cdc.gov/" target="_blank">ነው።</a> </h5>
            <h5 className="header-caption-item">ለመጨረሻ ጊዜ የዘመነው 5/29/2020።</h5>
          </div>
        </div>
        <div className="nav">
          <a href="/am/" className={classnames('nav-item', {'nav-item-active': path === ''})}>እውነት ወይም ሐሰት</a>
          <a href="/am/tips" className={classnames('nav-item', {'nav-item-active': path === 'tips'})}>ጠቃሚ ምክሮች</a>
          <a href="/am/learn" className={classnames('nav-item', {'nav-item-active': path === 'learn'})}>ስለ ኮቪድ-19 ይወቁ</a>
          <a href="/am/about" className={classnames('nav-item desktop', {'nav-item-active': path === 'about'})}>ስለ እኛ</a>
          <a href="mailto:contact@covid19factcheck.com" target="_blank" className="nav-item desktop">አግኙን</a>
        </div>
      </div>
    )
  }
}

export default withRouter(Header)