import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Tips extends Component {
  
  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <h4 className="content-nav-caption">ਵਿਸ਼ਾ- ਸੂਚੀ</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-1')}>ਹੱਥ ਧੋਣ ਵਾਸਤੇ ਸੁਝਾਅ</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-2')}>ਚਿਹਰੇ ਨੂੰ ਢੱਕਣ ਦੇ ਸੁਝਾਅ</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-3')}>ਦਸਤਾਨਿਆਂ ਬਾਰੇ ਸੁਝਾਅ</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-4')}>ਸਫ਼ਾਈ ਲਈ ਸੁਝਾਅ</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-5')}>ਗਰੋਸਰੀ ਖਰੀਦਣਾ</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-6')}>ਡਿਲਿਵਰੀ ਵਾਲੇ ਖਾਣੇ ਨਾਲ ਕਿਵੇਂ ਨਜਿੱਠਿਆ ਜਾਵੇ</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-7')}>ਚਿੱਠੀਆਂ ਅਤੇ ਮੇਲ ਵਾਲੇ ਪੈਕਜਾਂ ਨਾਲ ਕਿਵੇਂ ਨਜਿੱਠਿਆ ਜਾਵੇ</h4>
        </div>
        <div className="content-body">
          <Card 
            id="tips-1"
            thumbnail="/assets/img/shots/tips/tips-1.jpg" 
            title="ਹੱਥ ਧੋਣ ਵਾਸਤੇ ਸੁਝਾਅ"
            content={
              <div>
                <p>ਕੋਵਿਡ-19 ਦੇ ਫੈਲਣ ਨੂੰ ਰੋਕਣ ਲਈ ਆਪਣੇ ਹੱਥਾਂ ਨੂੰ ਅਕਸਰ ਧੋਣਾ ਮਹੱਤਵਪੂਰਣ ਹੈI ਅਣਧੋਤੇ ਹੱਥਾਂ ਨਾਲ ਆਪਣੀਆਂ ਅੱਖਾਂ, ਨੱਕ ਜਾਂ ਮੂੰਹ ਨੂੰ ਛੂਹਣ ਤੋਂ ਬੱਚੋI</p>
                <hr />
                <p>
                  <div className="paragraph-header">ਹੱਥ ਧੋਣ ਦੇ ਲਈ ਸਹੀ ਸੁਝਾਅ</div>
                  <ol className="content-list">
                    <li><div className="ol-index">1</div>ਵਗਦੇ ਪਾਣੀ ਨਾਲ ਆਪਣੇ ਹੱਥ ਗਿੱਲੇ ਕਰੋ</li>
                    <li><div className="ol-index">2</div>ਸਾਬਣ ਨੂੰ ਆਪਣੇ ਹੱਥਾਂ 'ਤੇ, ਉਂਗਲਾਂ ਦੇ ਵਿਚਕਾਰ, ਹੱਥਾਂ ਦੇ ਬਾਹਰਲੇ ਪਾਸੇ, ਅਤੇ ਨਹੁੰਾਂ ਦੀ ਹੇਠਲੀ ਥਾਂਵਾਂ 'ਤੇ ਮਲੋ</li>
                    <li><div className="ol-index">3</div>ਆਪਣੇ ਹੱਥਾਂ ਨੂੰ 20 ਸਕਿੰਟ ਲਈ ਮਲੋ ਜਾਂ ਸਕਰੱਬ ਕਰੋ (ਇਹ ਕਰਨ ਲੱਗੇ ਤੁਸੀਂ ਦੋ ਵਾਰ "ਹੈਪੀ ਬਰਥਡੇ" ਵਾਲਾ ਗਾਣਾ ਗਾ ਸਕਦੇ ਹੋ)</li>
                    <li><div className="ol-index">4</div>ਅਪਣੇ ਹੱਥ ਪਾਣੀ ਨਾਲ ਧੋਵੋ</li>
                    <li><div className="ol-index">5</div>ਆਪਣੇ ਹੱਥਾਂ ਨੂੰ ਤੌਲੀਏ ਨਾਲ ਸੁਕਾਓ ਜਾਂ ਉਨ੍ਹਾਂ ਨੂੰ ਹਵਾ ਨਾਲ ਹੀ ਸੁੱਕਣ ਦਿਓ</li>
                  </ol>
                  <h5 className="content-source"><a href="https://www.cdc.gov/handwashing/when-how-handwashing.html" target="_blank">ਸਰੋਤ: CDC</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/CSJO52qZLbs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p>
                  <div className="paragraph-header">ਤੁਹਾਨੂੰ ਆਪਣੇ ਹੱਥ ਕਦੋਂ ਧੋਣੇ ਚਾਹੀਦੇ ਹਨ?</div>
                  <ul className="content-list">
                    <li>ਜਿੰਨੀ ਵਾਰ ਸੰਭਵ ਹੋ ਸਕੇ ਓਨੀ ਵਾਰ</li>
                    <li>ਬਾਹਰ ਜਨਤਕ ਥਾਵਾਂ 'ਤੇ ਜਾਣ ਤੋਂ ਬਾਅਦ</li>
                    <li>
                      <div>ਆਮ ਤੌਰ 'ਤੇ ਛੂੰਹਦੀਆਂ ਸਤਹਾਂ ਦੇ ਸੰਪਰਕ ਵਿੱਚ ਆਉਣ ਤੋਂ ਬਾਅਦ</div>
                      <ul>
                        <li>ਉਦਾਹਰਣਾਂ: ਦਰਵਾਜ਼ੇ ਦੇ ਹੈਂਡਲ, ਮੇਜ਼, ਪਟ੍ਰੋਲ ਪੰਪ, ਸ਼ਾਪਿੰਗ ਕਰਨ ਵਾਲੀ ਰੇੜੀ, ਕੈਸ਼ੀਅਰ ਰਜਿਸਟਰ, ਪਿੰਨ ਪੈਡ, ਐਲੀਵੇਟਰ ਬਟਨ, ਆਦਿ</li>
                      </ul>
                    </li>
                    <li>ਭੋਜਨ ਤਿਆਰ ਕਰਨ ਤੋਂ ਪਹਿਲਾਂ, ਤਿਆਰ ਕਰਦੇ ਦੌਰਾਨ ਅਤੇ ਬਣਾਉਣ ਤੋਂ ਬਾਅਦ ਵਿੱਚ</li>
                    <li>ਖਾਣ ਤੋਂ ਪਹਿਲਾਂ</li>
                    <li>ਅਪਣਾ ਨੱਕ ਸਿਨਕਣ ਤੋਂ ਬਾਅਦ</li>
                    <li>ਮਾਸਕ ਅਤੇ ਦਸਤਾਨੇ ਪਹਿਨਣ ਤੋਂ ਪਹਿਲਾਂ ਅਤੇ ਉਤਾਰਨ ਤੋਂ ਬਾਅਦ</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">ਜੇ ਸਾਬਣ ਅਤੇ ਪਾਣੀ ਉਪਲਬਧ ਨਹੀਂ ਹਨ, ਤਾਂ ਅਲਕੋਹਲ-ਅਧਾਰਤ ਹੈਂਡ ਸੈਨੀਟਾਈਜ਼ਰ ਦੀ ਵਰਤੋਂ ਕਰੋ</div>
                  <ul className="content-list">
                    <li>ਹੈਂਡ ਸੈਨੀਟਾਈਜ਼ਰ ਹਰ ਕਿਸਮ ਦੇ ਕੀਟਾਣੂਆਂ ਨੂੰ ਨਹੀਂ ਮਾਰਦੇ</li>
                    <li>ਹੈਂਡ ਸੈਨੀਟਾਈਜ਼ਰ ਵਿੱਚ ਘੱਟੋ-ਘੱਟ 60 ਪ੍ਰਤੀਸ਼ਤ ਅਲਕੋਹਲ ਦੀ ਮਾਤਰਾ ਹੋਣੀ ਚਾਹੀਦੀ ਹੈ</li>
                    <li>ਹੈਂਡ ਸੈਨੀਟਾਈਜ਼ਰ ਨੂੰ ਆਪਣੇ ਹੱਥਾਂ ਦੀਆਂ ਸਾਰੀਆਂ ਸਤਹਾਂ, ਆਪਣੀਆਂ ਉਂਗਲਾਂ ਅਤੇ ਉਂਗਲੀਆਂ ਦੇ ਵਿਚਕਾਰ ਮਲੋ</li>
                    <li>ਆਪਣੇ ਹੱਥਾਂ ਨੂੰ ਉਦੋਂ ਤੱਕ ਮਲੋ ਜਦੋਂ ਤਕ ਉਹ ਸੁੱਕ ਨਾ ਜਾਣ</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-2"
            thumbnail="/assets/img/shots/tips/tips-2-pa.jpg" 
            title="ਚਿਹਰੇ ਨੂੰ ਢੱਕਣ ਦੇ ਸੁਝਾਅ"
            content={
              <div>
                <p>
                  <div className="paragraph-header">ਚਿਹਰੇ ਨੂੰ ਚੰਗੀ ਤਰ੍ਹਾਂ ਢੱਕਣ ਦੀ ਵਰਤੋਂ ਕਰਨ ਲਈ ਚੰਗੇ ਅਭਿਆਸ</div>
                  <ul className="content-list">
                    <div className="content-list-label-positive">ਇਹ ਕਰੋ</div>
                    <li>ਇਹ ਸੁਨਿਸ਼ਚਿਤ ਕਰੋ ਕਿ ਤੁਸੀਂ ਇਸ ਨੂੰ ਪਹਿਨਣ ਨਾਲ ਸਾਹ ਲੈ ਸਕਦੇ ਹੋ</li>
                    <li>ਇਹ ਸੁਨਿਸ਼ਚਿਤ ਕਰੋ ਕਿ ਇਹ ਤੁਹਾਡੇ ਨੱਕ ਅਤੇ ਮੂੰਹ ਨੂੰ ਢਕੇ (ਨੱਕ ਤੋਂ ਠੋਡੀ ਦੇ ਹੇਠਾਂ ਤੱਕ)</li>
                    <li>ਚਿਹਰੇ ਦੇ ਮਾਸਕ ਨੂੰ ਉਤਾਰਦੇ ਸਮੇਂ ਮਾਸਕ ਦੇ ਸਾਈਡ ਜਾਂ ਪਾਸੇ ਦੀਆਂ ਤਣੀਆਂ ਦੀ ਵਰਤੋਂ ਕਰੋ</li>
                    <li>ਕੱਪੜੇ ਦੇ ਮਾਸਕ ਰੋਜ਼ਾਨਾ ਧੋਵੋ ਅਤੇ ਸੁਕਾਓ</li>
                    <li>ਆਪਣੇ ਚਿਹਰੇ ਤੋਂ ਮਾਸਕ ਜਾਂ ਕਵਰ ਨੂੰ ਹਟਾਉਣ ਤੋਂ ਬਾਅਦ ਆਪਣੇ ਹੱਥ ਧੋਵੋ</li>
                    <li>ਉਨ੍ਹਾਂ ਨੂੰ ਇਕ ਨਿਰਧਾਰਤ ਬੈਗ ਜਾਂ ਬਿਨ ਵਿਚ ਰੱਖੋ ਜਦੋਂ ਤਕ ਉਹ ਸਾਫ ਨਹੀਂ ਹੁੰਦੇ</li>
                  </ul>
                  <ul className="content-list">
                    <div className="content-list-label-negative">ਇਹ ਨਾ ਕਰੋ</div>
                    <li>ਆਪਣੇ ਚਿਹਰੇ ਦੇ ਮਾਸਕ ਜਾਂ ਕਵਰ ਦੇ ਮੂਰਲੇ ਹਿੱਸੇ ਨੂੰ ਨਾਂ ਛੁਹੋ ਕਿਓਂਕਿ ਇਹ ਹਿਸਾ ਸਬ ਤੋਂ ਵੱਧ ਗੰਦਾ ਹੁੰਦਾ ਹੈ</li>
                    <li>ਮਾਸਕ ਨੂੰ ਆਪਣੀ ਠੋਡੀ ਦੇ ਹੇਠਾਂ ਰੱਖਣਾ ਜਾਂ ਇਸ ਨੂੰ ਆਪਣੀ ਗਰਦਨ ਦੇ ਦੁਆਲੇ ਲਪੇਟਣਾ</li>
                    <li>Hਮਾਸਕ ਦੇ ਪਾਸਿਆਂ ਜਾਂ ਸਾਇਡਾਂ ਵਿੱਚ ਢਿੱਲੇ ਪਾੜੇ ਹੋਣਾ</li>
                    <li>ਦਸਤਾਨਿਆਂ ਨਾਲ ਚਿਹਰੇ ਵਾਲੇ ਮਾਸਕ ਜਾਂ ਕਵਰ ਉਤਾਰਨਾ</li>
                    <li>ਸਰਜੀਕਲ ਮਾਸਕ ਦੀ ਮੁੜ ਮੁੜ ਵਰਤੋਂ ਕਰਨਾ</li>
                  </ul>
                  <h5 className="content-source">ਸਰੋਤ: <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a>; <a href="https://www.nytimes.com/2020/04/08/well/live/coronavirus-face-mask-mistakes.html#click=https://t.co/GkBNJAuw1V" target="_blank">The New York Times</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/m4O-3wQQjpA?start=118" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p>
                  <div className="paragraph-header">ਚਿਹਰੇ ਨੂੰ ਢੱਕਣ ਵਾਲੇ ਮਾਸਕ ਜਾਂ ਕਵਰ ਹੋਰ ਰੋਕਥਾਮ ਉਪਾਵਾਂ ਦਾ ਬਦਲ ਨਹੀਂ ਹੁੰਦੇ</div>
                  <div>ਚਿਹਰਾ ਢੱਕਣ ਜਾਂ ਦਸਤਾਨੇ ਪਹਿਨਣ ਦੇ ਨਾਲ-ਨਾਲ ਇਹ ਸੁਨਿਸ਼ਚਿਤ ਕਰੋ ਕਿ ਤੁਸੀਂ ਹੇਠ ਲਿਖੇ ਅਭਿਆਸ ਜਾਰੀ ਰੱਖਓਂਗੇ:</div>
                  <ul className="content-list">
                    <li>ਸਹੀ ਤਰੀਕੇ ਨਾਲ ਹੱਥ ਧੋਣਾ</li>
                    <li>ਦੂਜਿਆਂ ਤੋਂ 6 ਫੁੱਟ ਦੂਰ ਰਹਿਣਾ</li>
                    <li>ਸ਼ੈਲਟਰ-ਇਨ-ਪਲੇਸ</li>
                  </ul>
                  <div>ਹਾਲਾਂਕਿ ਸੁਰੱਖਿਆਤਮਕ ਵਸਤੂ (ਫੇਸ ਮਾਸਕ, ਦਸਤਾਨੇ, ਆਦਿ) ਵਾਇਰਸ ਦੇ ਪ੍ਰਸਾਰਣ ਨੂੰ ਸੀਮਤ ਕਰਨ ਵਿੱਚ ਸਹਾਇਤਾ ਕਰ ਸਕਦੇ ਹਨ, ਇਹ ਸੁਰੱਖਿਆ ਦੀ ਇੱਕ ਗਲਤ ਭਾਵਨਾ ਵੀ ਪੈਦਾ ਕਰ ਸਕਦੇ ਹਨI ਸੁਰੱਖਿਆ ਵਾਲੀਆਂ ਵਸਤੂਆਂ ਸੰਭਾਵਿਤ ਦੂਸ਼ਿਤ ਸਤਹਾਂ ਦੇ ਸਿੱਧੇ ਸੰਪਰਕ ਵਿੱਚ ਆਉਂਦੀਆਂ ਹਨI ਜੇ ਗਲਤ ਤਰੀਕੇ ਨਾਲ ਇਸਤੇਮਾਲ ਕੀਤਾ ਜਾਂਦਾ ਹੈ, ਤਾਂ ਇਹ ਵਸਤੂਆਂ ਅੰਤਰ-ਦੂਸ਼ਣ ਪੈਦਾ ਕਰ ਸਕਦੀਆਂ ਹਨ ਅਤੇ ਤੁਹਾਨੂੰ ਵਾਇਰਸ ਦੇ ਸੰਪਰਕ ਵਿੱਚ ਹੋਣ ਦਾ ਖ਼ਤਰਾ ਵਧਾ ਸਕਦੀਆਂ ਹਨI</div>
                </p>
              </div>}
            extra={
              <div>
                <p>
                  <div className="paragraph-header">ਸੀਡੀਸੀ ਜਨਤਾ ਨੂੰ ਚਿਹਰੇ ਤੇ ਮਾਸਕ ਜਾਂ ਕਵਰ ਪੌਣ ਦੀ ਵਰਤੋਂ ਦੀ ਸਿਫਾਰਸ਼ ਕਿਉਂ ਕਰ ਰਹੀ ਹੈ?</div>
                  <div><a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover.html" target="_blank">ਸੀਡੀਸੀ</a> ਦੇ ਅਨੁਸਾਰ, ਤੁਸੀਂ ਬਿਨਾਂ ਲੱਛਣਾਂ ਤੋਂ ਵੀ ਕੋਰਨਾਵਾਇਰਸ ਨੂੰ ਫੈਲਾ ਸਕਦੇ ਹੋ (ਜਿਸ ਨੂੰ "ਏਸਿਮਪਟੋਮੈਟਿਕ" ਵੀ ਕਿਹਾ ਜਾਂਦਾ ਹੈ) I ਚਿਹਰੇ ਤੇ ਮਾਸਕ ਜਾਂ ਕਵਰ ਪਾ ਕੇ ਅਤੇ ਦੂਜੇ ਲੋਕਾਂ ਤੋਂ 6 ਫੁੱਟ ਦੀ ਦੂਰੀ ਬਣਾ ਕੇ, ਤੁਸੀਂ ਆਪਣੀ ਕਮਿਊਨਟੀ ਵਿਚ ਕੋਰੋਨਾਵਾਇਰਸ ਦੇ ਫੈਲਣ ਨੂੰ ਰੋਕਣ ਵਿਚ ਮਦਦ ਕਰ ਸਕਦੇ ਹੋI</div>
                  <ul className="content-list">
                    <div>ਕੁਝ ਵਿਅਕਤੀਆਂ ਨੂੰ ਚਿਹਰੇ ਦੇ ਮਾਸਕ ਜਾਂ ਕਵਰ ਦੀ ਵਰਤੋਂ ਨਹੀਂ ਕਰਨੀ ਚਾਹੀਦੀ!</div>
                    <li>2 ਸਾਲ ਤੋਂ ਘੱਟ ਉਮਰ ਦੇ ਬੱਚੇ</li>
                    <li>ਉਹ ਵਿਅਕਤੀ ਜਿਨ੍ਹਾਂ ਨੂੰ ਸਾਹ ਲੈਣ ਵਿੱਚ ਮੁਸ਼ਕਲ ਆਉਂਦੀ ਹੈ</li>
                    <li>ਉਹ ਵਿਅਕਤੀ ਜੋ ਬੇਹੋਸ਼ ਹਨ</li>
                    <li>ਉਹ ਵਿਅਕਤੀ ਜੋ ਸਹਾਇਤਾ ਤੋਂ ਬਿਨਾਂ ਚਿਹਰੇ ਦੇ ਮਾਸਕ ਜਾਂ ਕਵਰ ਨੂੰ ਉਤਾਰਨ ਵਿੱਚ ਅਸਮਰੱਥ ਹਨ</li>
                  </ul>
                </p>
                <p>ਅਮਰੀਕਾ ਵਿੱਚ ਸੀਡੀਸੀ ਚਿਹਰੇ ਦੇ ਮਾਸਕ ਲਈ ਘਰੇਲੂ ਸਮੱਗਰੀ ਦੀ ਵਰਤੋਂ ਕਰਨ ਦੀ ਸਿਫਾਰਸ਼ ਕਰਦੀ ਹੈ, ਜਿਵੇਂ ਕਿ ਪੁਰਾਣੀ ਟੀ-ਸ਼ਰਟ ਜਾਂ ਰੁਮਾਲI ਟੀ-ਸ਼ਰਟਾਂ ਅਤੇ ਰੁਮਾਲ ਤੋਂ ਚਿਹਰਾ ਦੇ ਮਾਸਕ ਜਾਂ ਕਵਰ ਕਿਵੇਂ ਬਣਾਏ ਜਾਂਦੇ ਨੇ, ਇਸ ਬਾਰੇ ਜਾਣਕਾਰੀ ਲਈ  ਸੀਡੀਸੀ ਦੀ <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">ਕਦਮ-ਦਰ-ਕਦਮ ਗਾਈਡ</a> ਵੇਖੋI</p>
                <p><strong>ਤੁਹਾਨੂੰ ਸਰਜੀਕਲ ਮਾਸਕ ਜਾਂ ਐਨ-95 ਮਾਸਕ ਲੈਣ ਦੀ ਲੋੜ ਨਹੀਂ ਹੈI</strong> ਅਮਰੀਕਾ ਵਿੱਚ ਭਾਰੀ ਘਾਟ ਕਾਰਨ ਸੀਡੀਸੀ ਸਰਜੀਕਲ ਮਾਸਕ ਅਤੇ ਐਨ-95 ਮਾਸਕ ਖਰੀਦਣ ਦੀ ਸਿਫਾਰਸ਼ ਨਹੀਂ ਕਰਦਾ ਹੈI</p>
              </div>
            }
          />
          <Card 
            id="tips-3"
            thumbnail="/assets/img/shots/tips/tips-3.jpg" 
            title="ਦਸਤਾਨਿਆਂ ਬਾਰੇ ਸੁਝਾਅ"
            content={
              <div>
                <p>ਸੁਰੱਖਿਆ ਲਈ ਜਨਤਕ ਸਥਾਨਾਂ ਵਿੱਚ ਦਸਤਾਨੇ ਲਾਜ਼ਮੀ ਨਹੀਂ ਹਨ I ਪਰ ਜੇ ਤੁਸੀਂ ਦਸਤਾਨੇ ਪਹਿਨਣਦੇ ਹੋਂ ਤਾਂ ਇਹ ਹੇਠ ਲਿਖੇ ਸੁਝਾਅ ਪੜ੍ਹੋI</p>
                <p>
                  <ul className="content-list">
                    <div className="content-list-label-positive">ਇਹ ਕਰੋ</div>
                    <li>ਦਸਤਾਨਿਆਂ ਨੂੰ ਉਤਾਰਣ ਤੋਂ ਪਹਿਲਾਂ ਅਤੇ ਉਤਾਰਣ ਤੋਂ ਤੁਰੰਤ ਬਾਅਦ ਆਪਣੇ ਹੱਥ ਧੋਵੋ</li>
                    <li>ਪਲਾਸਟਿਕ ਦੇ ਦਸਤਾਨਿਆਂ ਦੀ ਮੁੜ ਵਰਤੋਂ ਨਾ ਕਰੋ ਅਤੇ ਇਹਨਾਂ ਨੂੰ ਵਰਤੋਂ ਤੋਂ ਬਾਅਦ ਸੁੱਟ ਦਵੋ</li>
                    <li>ਕੱਪੜੇ ਦੇ ਦਸਤਾਨਿਆਂ ਦੀ ਦੋਬਾਰਾ ਵਰਤੋਂ ਕਰਨ ਤੋਂ ਪਹਿਲਾਂ ਉਨ੍ਹਾਂ ਨੂੰ ਸਾਬਣ ਅਤੇ ਪਾਣੀ ਨਾਲ ਧੋ ਲਓ</li>
                  </ul>
                  <ul className="content-list">
                    <div className="content-list-label-negative">ਇਹ ਨਾ ਕਰੋ</div>
                    <li>ਆਪਣੇ ਚਿਹਰੇ, ਮੂੰਹ, ਨੱਕ ਅਤੇ ਅੱਖਾਂ ਨੂੰ ਛੂਹਣਾ</li>
                    <li>ਆਪਣੇ ਜੇਬ ਵਿੱਚ ਆਪਣੇ ਹੱਥ ਪਾਉਣਾ</li>
                    <li>ਆਪਣੇ ਫੋਨ ਦੀ ਵਰਤੋਂ ਕਰਨਾ</li>
                  </ul>
                  <h5 className="content-source">ਸਰੋਤ: <a href="https://www.kqed.org/science/1961243/coronavirus-heres-how-to-use-gloves-properly-to-help-prevent-infection" target="_blank">KQED</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/TnLCYqk20YU?start=116" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>}
          />
          <Card 
            id="tips-4"
            thumbnail="/assets/img/shots/tips/tips-4.jpg" 
            title="ਸਫ਼ਾਈ ਲਈ ਸੁਝਾਅ"
            content={
              <div>
                <p>ਮੌਜੂਦਾ ਅੰਕੜਿਆਂ ਦੇ ਅਧਾਰ ਤੇ, ਦੂਸ਼ਿਤ ਸਤਹਾਂ ਨੂੰ ਛੂਹਣਾ ਕੋਵਿਡ -19 ਦੇ ਫੈਲਣ ਦਾ ਮੁੱਖ ਤਰੀਕਾ ਨਹੀਂ ਹੈI ਤੁਸੀਂ ਵਾਇਰਸ ਨਾਲ ਦੂਸ਼ਿਤ ਚੀਜ਼ਾਂ ਨੂੰ ਸਿਰਫ ਛੂਹਣ ਦੁਆਰਾ ਸੰਕਰਮਿਤ ਨਹੀਂ ਹੋ ਸਕਦੇI ਪਰ ਜੇ ਤੁਹਾਡੇ ਹੱਥਾਂ ਤੇ ਵਿਵਹਾਰਕ ਵਾਇਰਸ ਹਨ, ਅਤੇ ਫਿਰ ਤੁਸੀਂ ਆਪਣੀਆਂ ਅੱਖਾਂ, ਨੱਕ ਜਾਂ ਮੂੰਹ ਨੂੰ ਛੂਹਦੇ ਹੋ, ਇਸ ਨਾਲ ਤੁਹਾਨੂੰ ਲਾਗ ਹੋਣ ਦਾ ਖ਼ਤਰਾ ਵੱਧ ਜਾਂਦਾ ਹੈI ਸਤਹਾਂ ਨੂੰ ਛੂਹਣ ਤੋਂ ਬਾਅਦ ਸਤਹ ਸਾਫ਼ ਕਰਨ ਅਤੇ ਆਪਣੇ ਹੱਥ ਧੋਣ ਨਾਲ ਇਸ ਖ਼ਤਰੇ ਨੂੰ ਘਟਾਉਣ ਵਿੱਚ ਸਹਾਇਤਾ ਹੁੰਦੀ ਹੈI</p>
                <hr />
                <p>
                  <div className="paragraph-header">ਕੋਰੋਨਾਵਾਇਰਸ ਕਿੰਨੇ ਸਮੇਂ ਲਈ ਵੱਖੋ ਵੱਖਰੀਆਂ ਸਤਹਾਂ 'ਤੇ ਸਥਿਰ ਰਹਿੰਦਾ ਹੈ?</div>
                  <div className="content-table">
                    <div className="content-table-row">
                      <div className="content-table-column">
                        <h3 className="content-table-heading">4 ਘੰਟੇ</h3>
                        <h4 className="content-table-caption">ਤਾਂਬਾ</h4>
                      </div>
                      <div className="content-table-column">
                        <h3 className="content-table-heading">24 ਘੰਟੇ</h3>
                        <h4 className="content-table-caption">ਗੱਤਾ</h4>
                      </div>
                    </div>
                    <div className="content-table-row">
                      <div className="content-table-column">
                        <h3 className="content-table-heading">2-3 ਦਿਨ</h3>
                        <h4 className="content-table-caption">ਪਲਾਸਟਿਕ</h4>
                      </div>
                      <div className="content-table-column">
                        <h3 className="content-table-heading">2-3 ਦਿਨ</h3>
                        <h4 className="content-table-caption">ਸਟੇਨਲੇਸ ਸਟੀਲ</h4>
                      </div>
                    </div>
                  </div>
                  <div>ਹਾਲਾਂਕਿ ਕੋਰੋਨਾਵਾਇਰਸ ਕੁਝ ਘੰਟਿਆਂ ਤੋਂ ਲੈ ਕੇ ਕੁਝ ਦਿਨਾਂ ਤੱਕ ਸਤਹਾਂ ਤੇ ਪਾਇਆ ਜਾਂ ਸਕਦਾ ਹੈ, ਪਰ ਸਮੇਂ ਦੇ ਨਾਲ ਇਨ੍ਹਾਂ ਸਤਹਾਂ ਤੇ ਵਾਇਰਸ ਦੀ ਮਾਤਰਾ ਤੇਜ਼ੀ ਨਾਲ ਘਟਦੀ ਜਾਂਦੀ ਹੈ ਅਤੇ ਸਮਾਂ ਲੰਘਣ ਨਾਲ ਲਾਗ ਲੱਗਣ ਦਾ ਖ਼ਤਰਾ ਵੀ ਘੱਟ ਹੁੰਦਾ ਜਾਂਦਾ ਹੈI (<a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973" target="_blank">ਨਿਊ ਇੰਗਲੈਂਡ ਜਰਨਲ ਆਫ਼ ਮੈਡੀਸਨ</a> ਵਿਚ ਪ੍ਰਕਾਸ਼ਤ ਇਕ ਅਧਿਐਨ ਦੇ ਅਨੁਸਾਰ, 3/17/20)</div>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">ਸਤਹ ਸਾਫ ਕਰਨ ਲਈ ਮੈਂ ਕੀ ਇਸਤੇਮਾਲ ਕਰ ਸਕਦਾ ਹਾਂ?</div>
                  <ul className="content-list">
                    <div><a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cleaning-disinfection.html" target="_blank">ਖ਼ਾਸ ਕਿਸਮ ਦੀਆਂ ਸਤਹਾਂ</a> ਨੂੰ ਸਾਫ਼ ਕਰਨ ਬਾਰੇ ਹੋਰ ਜਾਣਕਾਰੀ ਲਈ ਕਿਰਪਾ ਕਰਕੇ ਸੀਡੀਸੀ ਦੀ ਵੈਬਸਾਈਟ ਦੇਖੋI ਸੀਡੀਸੀ ਹੇਠ ਲਿਖੀਆਂ ਚੀਜ਼ਾਂ ਦੀ ਵਰਤੋਂ ਕਰਨ ਦੀ ਸਿਫਾਰਸ਼ ਕਰਦਾ ਹੈ:</div>
                    <li>ਈਪੀਏ ਦੁਆਰਾ ਮਨਜ਼ੂਰ ਕੀਟਾਣੂਨਾਸ਼ਕ <a href="https://www.cnn.com/2020/03/05/health/epa-disinfectants-coronavirus-trnd/index.html" target="_blank">(ਸੂਚੀ ਇੱਥੇ ਵੇਖੋ)</a></li>
                    <li>
                      <div>ਬਲੀਚ ਦਾ ਪਤਲਾ ਸੋਲਿਊਸ਼ਣ</div>
                      <ul>
                        <li>ਪਾਣੀ ਦੀ ਹਰੇਕ ਗੈਲਨ ਲਈ 5 ਵੱਡੇ ਚਮਚ (⅓ ਕੱਪ) ਬਲੀਚ</li>
                        <li>ਜਾਂ ਪਾਣੀ ਦੇ ਹਰੇਕ ਕ੍ਵਾਰਟ ਲਈ 4 ਛੋਟੇ ਚਮਚੇ ਬਲੀਚ</li>
                        <li>ਬਲੀਚ ਨੂੰ ਡਾਇਲੂਟ ਜਾਂ ਪਤਲੇ ਕਰਨ ਤੋਂ ਬਿਨਾਂ ਸਿੱਧੇ ਬੋਤਲ ਵਿਚੋਂ ਕੱਢ ਕੇ ਸਫਾਈ ਨਾ ਕਰੋ</li>
                        <li>ਇਹ ਸੁਨਿਸ਼ਚਿਤ ਕਰੋ ਕਿ ਬਲੀਚ ਦੀ ਮਿਆਦ ਖਤਮ ਨਹੀਂ ਹੋਈ ਹੈ</li>
                        <li>ਘਰ ਵਿਚ ਅਮੋਨੀਆ ਜਾਂ ਹੋਰ ਸਫਾਈ ਵਾਲਿਆਂ ਚੀਜ਼ਾਂ ਨਾਲ ਬਲੀਚ ਨੂੰ ਨਾ ਮਿਲਾਓ</li>
                      </ul>
                    </li>
                    <li>ਅਲਕੋਹਲ ਦੇ ਉਹ ਸੋਲਿਊਸ਼ਣ ਜਿਨ੍ਹਾਂ ਵਿਚ ਘੱਟੋ-ਘੱਟ 70 ਪ੍ਰਤੀਸ਼ਤ ਅਲਕੋਹਲ ਦੀ ਮਾਤਰਾ ਹੈ</li>
                  </ul>
                </p>
              </div>}
          />
          <div className="content-additional-disclaimer">
            <h2 className="content-additional-disclaimer-title">ਹੋਰ ਸਾਵਧਾਨੀਆਂ ਜਿਹੜੀਆਂ ਤੁਸੀਂ ਵਰਤ ਸਕਦੇ ਹੋ</h2>
            <h4 className="content-additional-disclaimer-caption">ਹਾਲਾਂਕਿ ਹੇਠਾਂ ਦਿੱਤੇ ਸੁਝਾਅ ਸੀਡੀਸੀ ਦੀਆਂ ਸਿਫਾਰਸ਼ਾਂ ਨਹੀਂ ਹਨ, ਤੁਸੀਂ ਹੋਰ ਸਾਵਧਾਨੀ ਲਈ ਇਹਨਾਂ ਦਾ ਅਭਿਆਸ ਕਰਨ ਬਾਰੇ ਵਿਚਾਰ ਕਰ ਸਕਦੇ ਹੋI ਇਹ ਵਾਇਰਸ ਦੇ ਸੰਚਾਰ ਨੂੰ ਸੀਮਤ ਕਰਨ ਵਿੱਚ ਸਹਾਇਤਾ ਕਰ ਸਕਦੇ ਹਨI</h4>
          </div>
          <Card 
            id="tips-5"
            thumbnail="/assets/img/shots/tips/tips-5.jpg" 
            title="ਗਰੋਸਰੀ ਖਰੀਦਣਾ"
            content={
              <div>
                <p>
                  <div className="paragraph-header">ਗਰੋਸਰੀ ਦੀ ਖਰੀਦਾਰੀ ਲਈ ਸੁਝਾਅ</div>
                  <ul className="content-list">
                    <li>ਜੇ ਤੁਹਾਨੂੰ ਸਾਹ ਲੈਣ ਵਿੱਚ ਤਕਲੀਫੀ ਹੈ ਜਾਂ ਸਾਹ ਦੇ ਕੋਈ ਹੋਰ ਲੱਛਣ ਹੋ ਰਹੇ ਹਨ ਤਾਂ ਖਰੀਦਦਾਰੀ ਨਾ ਕਰੋ</li>
                    <li>ਗਰੋਸਰੀ ਦੀ ਖਰੀਦ ਹਫਤੇ ਵਿਚ ਇਕ ਵਾਰ ਜਾਂ ਹਰ 2 ਹਫ਼ਤਿਆਂ ਵਿਚ ਇਕ ਵਾਰ ਕਰਨ ਦੀ ਕੋਸ਼ਿਸ਼ ਕਰੋ</li>
                    <li>ਪਰਿਵਾਰ ਦੇ ਸਿਰਫ ਇਕ ਵਿਅਕਤੀ ਨੂੰ ਗਰੋਸਰੀ ਦੀ ਖਰੀਦ ਕਰਨੀ ਚਾਹੀਦੀ ਹੈ</li>
                    <li>ਜੇ ਸੰਭਵ ਹੋਵੇ, ਤਾਂ ਬੱਚਿਆਂ ਨੂੰ ਕਿਸੇ ਹੋਰ ਬਾਲਗ ਨਾਲ ਘਰ ਵਿਚ ਰਹਿਣ ਦਿਓ I ਸਟੋਰਾਂ ਤੇ ਉਹ ਕਿਹੜੀਆਂ ਚੀਜ਼ਾਂ ਦੇ ਸੰਪਰਕ ਵਿੱਚ ਆਉਂਦੇ ਹਨ, ਇਸ ਨੂੰ ਜਾਣਨਾ ਮੁਸ਼ਕਲ ਹੋ ਸਕਦਾ ਹੈI</li>
                    <li>ਆਪਣੇ ਖਰੀਦਾਰੀ ਦੇ ਸਮੇਂ ਨੂੰ ਘਟਾਉਣ ਵਿੱਚ ਸਹਾਇਤਾ ਲਈ ਗਰੋਸਰੀ ਦੀ ਸੂਚੀ ਜਾਂ ਲਿਸਟ ਬਣਾਓ</li>
                    <li>ਖਰੀਦਦਾਰੀ ਵਾਲੀ ਰੇਡੀ ਦੇ ਹੈੰਡਲਾਂ ਨੂੰ ਪੂੰਝੋ ਅਤੇ ਸਾਫ ਕਰੋ</li>
                    <li>ਓਹੀ ਚੀਜ਼ ਨੂੰ ਛੋਹਵੋ ਜਿਹੜੀ ਤੁਸੀਂ ਖਰੀਦਣੀ ਹੈ</li>
                    <li>ਜੇ ਤੁਹਾਡੇ ਕਿਸੇ ਪਰਿਵਾਰ ਦੇ ਮੈਂਬਰ, ਦੋਸਤ ਜਾਂ ਗੁਆਂਡੀ ਨੂੰ ਗਰੋਸਰੀ ਖਰੀਦਣ ਵਿੱਚ ਮਦਦ ਚਾਹੀਦੀ ਹੈ ਤਾਂ ਓਹਨਾ ਦੀ ਸਹਾਇਤਾ ਕਰੋ</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">ਗਰੋਸਰੀ ਨੂੰ ਸਾਫ਼ ਕਰਨਾ</div>
                  <ul className="content-list">
                    <li>ਕੋਵਿਡ-19 ਖਾਣੇ ਤੋਂ ਹੋਣ ਵਾਲੀ ਬਿਮਾਰੀ ਨਹੀਂ ਹੈ, ਜਿਸਦਾ ਮਤਲਬ ਹੈ ਕਿ ਤੁਸੀਂ ਵਾਇਰਸ ਦੇ ਸੰਪਰਕ ਵਿੱਚ ਆਏ ਭੋਜਣ ਨੂੰ ਖਾਣ ਨਾਲ ਇਸ ਬਿਮਾਰੀ ਨਾਲ ਸੰਕਰਮਿਤ ਨਹੀਂ ਹੋ ਸਕਦੇI</li>
                    <li>ਕੱਪੜੇ ਦੇ ਬੈਗ ਵਰਤਣ ਤੋਂ ਪਰਹੇਜ਼ ਕਰੋ (ਜਾਂ ਹਰੇਕ ਵਰਤੋਂ ਤੋਂ ਬਾਅਦ ਉਨ੍ਹਾਂ ਨੂੰ ਧੋ ਲਓ)</li>
                    <li>ਗਰੋਸਰੀ ਦੀਆਂ ਦੁਕਾਨਾਂ ਤੋਂ ਮਿਲੇ ਪਲਾਸਟਿਕ ਜਾਂ ਕਾਗਜ਼ ਦੇ ਬੈਗਾਂ ਨੂੰ ਸੁੱਟ ਦਿਓ</li>
                    <li>
                      <div>ਆਪਣੇ ਘਰ ਵਿੱਚ ਇੱਕ ਸਤਹ ਸਫਾਈ ਸਟੇਸ਼ਨ ਦੇ ਤੌਰ ਤੇ ਨਿਰਧਾਰਤ ਕਰੋ</div>
                      <ul>
                        <li>ਇੱਕ ਪਾਸੇ ਨੂੰ "ਸਾਫ" ਪਾਸਾ ਅਤੇ ਦੂਜੇ ਨੂੰ "ਦੂਸ਼ਿਤ" ਪਾਸਾ ਨਿਰਧਾਰਤ ਕਰੋ</li>
                        <li>ਜਦੋਂ ਤੁਸੀਂ ਆਪਣੀ ਗਰੋਸਰੀ ਨੂੰ ਸਾਫ਼ ਕਰਦੇ ਹੋ, ਵਸਤੂਆਂ ਨੂੰ “ਦੂਸ਼ਿਤ” ਪਾਸਿਓਂ “ਸਾਫ” ਵਾਲੇ ਪਾਸੇ ਟ੍ਰਾਂਸਫਰ ਕਰੋ</li>
                      </ul>
                    </li>
                    <li>ਕੈਨਾਂ, ਕੱਚ ਦੀਆਂ ਬੋਤਲਾਂ ਅਤੇ ਕੰਟੇਨਰ ਸਪਰੇਅ ਮਾਰ ਕੇ ਵੀ ਸਾਫ਼ ਕਰ ਸਕਦੇ ਹੋ</li>
                    <li>
                      <div>ਸਾਰੀਆਂ ਬੇਲੋੜੀ ਦੀਆਂ ਪੈਕਿਗਾਂ ਨੂੰ ਸੁਟੋ (ਜਿਵੇਂ ਕਿ, ਸੀਰੀਅਲ ਦੇ ਡੱਬੇ)</div>
                      <ul>
                        <li>ਜੰਮੀਆਂ ਹੋਈਆਂ ਜਾਂ ਫ਼ਰੋਜ਼ਨ ਚੀਜ਼ਾਂ: ਇਹਨਾਂ ਦੇ ਗੱਤੇ ਦੇ ਡੱਬੇਆਂ ਨੂੰ ਸੁਟੋ</li>
                        <li>ਮੀਟ: ਘਰ ਦੇ ਕੰਟੇਨਰਾਂ ਵਿੱਚ ਪਾਓ ਅਤੇ ਸਟੋਰਾਂ ਦੇ ਡੱਬੇਆਂ ਜਾਂ ਕੰਟੇਨਰਾਂ ਨੂੰ ਸੁੱਟ ਦਿਓ</li>
                      </ul>
                    </li>
                    <li>ਫਲ ਅਤੇ ਸਬਜ਼ੀਆਂ: ਹਰੇਕ ਟੁਕੜੇ ਨੂੰ 20 ਸੈਕਿੰਡ ਲਈ ਪਾਣੀ ਨਾਲ ਧੋ ਲਓ (ਤੁਹਾਨੂੰ ਸਾਬਣ ਨਾਲ ਧੋਣ ਦੀ ਜ਼ਰੂਰਤ ਨਹੀਂ ਹੈ)</li>
                    <li>ਇਹ ਸੁਨਿਸ਼ਚਿਤ ਕਰੋ ਕਿ ਤੁਸੀਂ ਆਪਣੀਆਂ ਗਰੋਸਰੀ ਦੀਆਂ ਚੀਜ਼ਾਂ ਨੂੰ ਸਾਫ਼ ਕਰਨ ਤੋਂ ਬਾਅਦ ਆਪਣੇ ਹੱਥਾਂ ਅਤੇ ਸਤਹ ਨੂੰ ਵੀ ਸਾਫ਼ ਕਰੋ</li>
                  </ul>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/zmoBI5m2_uw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p>
                  <div>ਇੱਕ ਡਾਕਟਰ, ਜੈਫਰੀ ਵੈਨਵਿੰਗੇਨ, ਨੇ ਇੱਕ ਵੀਡੀਓ ਬਣਾਇਆ ਜਿਸ ਵਿੱਚ ਓਹਨਾ ਨੇ ਘਰ ਵਾਪਸ ਆਉਣ ਤੇ ਅਪਣੀ ਗਰੋਸਰੀ ਨੂੰ ਕਿਵੇਂ ਸੰਭਾਲਣਾ ਚਾਹੀਦਾ ਹੈ ਬਾਰੇ ਦੱਸਿਆ. (ਵੀਡੀਓ ਆਖਰੀ ਵਾਰ 4/1/2020 ਨੂੰ ਅਪਡੇਟ ਕੀਤਾ ਗਿਆ ਸੀ)</div>
                </p>
              </div>}
          />
          <Card 
            id="tips-6"
            thumbnail="/assets/img/shots/tips/tips-6.jpg" 
            title="ਡਿਲਿਵਰੀ ਵਾਲੇ ਖਾਣੇ ਨਾਲ ਕਿਵੇਂ ਨਜਿੱਠਿਆ ਜਾਵੇ"
            content={
              <div>
                <p>ਕੋਵਿਡ-19 ਖਾਣੇ ਤੋਂ ਹੋਣ ਵਾਲੀ ਬਿਮਾਰੀ ਨਹੀਂ ਹੈ, ਜਿਸਦਾ ਮਤਲਬ ਹੈ ਕਿ ਤੁਸੀਂ ਵਾਇਰਸ ਦੇ ਸੰਪਰਕ ਵਿੱਚ ਆਏ ਭੋਜਣ ਨੂੰ ਖਾਣ ਨਾਲ ਇਸ ਬਿਮਾਰੀ ਨਾਲ ਸੰਕਰਮਿਤ ਨਹੀਂ ਹੋ ਸਕਦੇI</p>
                <p>
                  <ul className="content-list">
                    <li>ਡਿਲਿਵਰੀ: ਚੀਜ਼ਾਂ ਨੂੰ ਦਰਵਾਜ਼ੇ ਤੇ ਛੱਡਣ ਲਈ ਕਹੋ</li>
                    <li>ਰੈਸਟੋਰੈਂਟ ਦੇ ਕੰਟੇਨਰਾਂ ਤੋਂ ਖਾਣ ਪੀਣ ਵਾਲੀਆਂ ਚੀਜ਼ਾਂ ਨੂੰ ਘਰ ਦੇ ਭਾਂਡੇ / ਬਰਤਨਾਂ ਵਿੱਚ ਕੱਢੋ</li>
                    <li>ਇਸ ਵਿੱਚ ਸਾਸ ਵੀ ਸ਼ਾਮਲ ਹਨ: ਕੈਚੱਪ, ਸੋਇਆ ਸਾਸ, ਹੌਟ ਸਾਸ, ਜੈਮ, ਆਦਿ</li>
                    <li>ਜੇ ਸੰਭਵ ਹੋਵੇ ਤਾਂ ਰੈਸਟੋਰੈਂਟ ਦੁਆਰਾ ਸਪਲਾਈ ਕੀਤੇ ਗਏ ਪਲਾਸਟਿਕ ਦੇ ਬਰਤਨ ਵਰਤਣ ਤੋਂ ਪਰਹੇਜ਼ ਕਰੋ</li>
                    <li>ਜੇ ਤੁਸੀਂ ਰੈਸਟੋਰੈਂਟ ਦੇ ਬਰਤਨ ਵਰਤਣ ਦੀ ਜੁਗਤ ਬਣਾ ਰਹੇ ਹੋ, ਤਾਂ ਵਰਤਣ ਤੋਂ ਪਹਿਲਾਂ ਉਨ੍ਹਾਂ ਨੂੰ ਪਾਣੀ ਅਤੇ ਸਾਬਣ ਨਾਲ ਧੋ ਲਓ</li>
                    <li>ਖਾਣੇ ਨੂੰ ਛੂਹਣ ਤੋਂ ਪਹਿਲਾਂ ਇਹ ਯਕੀਨੀ ਬਣਾਓ ਕਿ ਤੁਹਾਡੇ ਹੱਥ ਧੋਤੇ ਹੋਏ ਨੇ</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-7"
            thumbnail="/assets/img/shots/tips/tips-7.jpg" 
            title="ਚਿੱਠੀਆਂ ਅਤੇ ਮੇਲ ਵਾਲੇ ਪੈਕਜਾਂ ਨਾਲ ਕਿਵੇਂ ਨਜਿੱਠਿਆ ਜਾਵੇ"
            content={
              <div>
                <p>ਇੱਕ ਐਨਈਜੇਐਮ <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973?query=featured_home" target="_blank">ਅਧਿਐਨ</a> ਦਰਸਾਉਂਦਾ ਹੈ ਕਿ ਕੋਰੋਨਵਾਇਰਸ ਗੱਤੇਆਂ ਤੇ 24 ਘੰਟੇ ਤੱਕ ਬਚ ਕੇ ਰਹਿ ਸਕਦਾ ਹੈI ਹਾਲਾਂਕਿ, ਵਾਇਰਸ ਦੀ ਮੌਜੂਦਗੀ ਦਾ ਆਪਣੇ ਆਪ ਇਹ ਮਤਲਬ ਨਹੀਂ ਹੈ ਕਿ ਇਹ ਛੂਤਕਾਰੀ ਹੋ ਸਕਦਾ ਹੈI</p>
                <div className="youtube-embed"><iframe width="560" height="315" src="https://www.youtube.com/embed/Sryj2VsuKns" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
                <p>
                  <div className="paragraph-header">ਪੈਕਜਾਂ ਨਾਲ ਕਿਵੇਂ ਨਜਿੱਠਿਆ ਜਾਵੇ</div>
                  <div>ਡਾਕਟਰ ਜੈਫਰੀ ਵੈਨਵਿੰਗੇਨ ਨੇ ਇਹ ਵੀ ਦੱਸਿਆ ਕਿ ਜੇ ਤੁਸੀਂ ਜ਼ਿਆਦਾ ਸਾਵਧਾਨ ਰਹਿਣਾ ਚਾਹੁੰਦੇ ਹੋ ਤਾਂ ਡਲਿਵਰ ਕੀਤੇ ਪੈਕੇਜ ਅਤੇ ਮੇਲ ਨਾਲ ਕਿਵੇਂ ਨਜਿੱਠਣਾ ਹੈ (ਇਹ ਵੀਡੀਓ ਆਖਰੀ ਵਾਰ 4/5/20 ਨੂੰ ਅਪਡੇਟ ਕੀਤੀ ਗਈ ਸੀ)I</div>
                  <ul className="content-list">
                    <li>ਤੁਹਾਨੂੰ ਕੈਂਚੀ ਅਤੇ ਸਾਫ਼ ਸਤਹ (ਜਿਵੇਂ ਕਿ, ਕੁਰਸੀ) ਦੀ ਜ਼ਰੂਰਤ ਹੋਏਗੀ</li>
                    <li>ਉਨ੍ਹਾਂ ਨੂੰ ਆਪਣੇ ਘਰ ਦੇ ਪ੍ਰਵੇਸ਼ ਦੁਆਰ ਦੇ ਨੇੜੇ ਰੱਖੋ</li>
                    <li>ਆਪਣੇ ਪੈਕਜ ਨੂੰ ਘਰ ਅੰਦਰ ਲਿਆਉਣ ਤੋਂ ਬਾਅਦ ਬਾਕਸ ਖੋਲ੍ਹੋ</li>
                    <li>ਬਾਕਸ 'ਚੋਂ ਨਿਕਲੇ ਸਮਾਨ ਨੂੰ ਸਾਫ਼ ਜਗਾ 'ਤੇ ਢੇਰੀ ਕਰੋ</li>
                    <li>ਬਾਕਸ ਨੂੰ ਆਪਣੀ ਰੀਸਾਈਕਲਿੰਗ ਬਿਨ ਜਾਂ ਰੱਦੀ ਵਿੱਚ ਸੁੱਟ ਦਿਓ</li>
                    <li>ਸਮਾਨ ਨੂੰ ਸੰਭਾਲਣ ਤੋਂ ਪਹਿਲਾਂ ਕੈਂਚੀ ਸਾਫ਼ ਕਰੋ ਅਤੇ ਆਪਣੇ ਹੱਥ ਧੋਵੋ</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">ਚਿੱਠੀਆਂ ਨਾਲ ਕਿਵੇਂ ਨਜਿੱਠਿਆ ਜਾਵੇ</div>
                  <ul className="content-list">
                    <li>ਚਿੱਠੀਆਂ ਨੂੰ ਅਗਲੀ ਸਵੇਰ ਤਕ ਮੇਲ ਬਾਕਸ ਵਿਚ ਛੱਡਣ ਬਾਰੇ ਵਿਚਾਰ ਕਰੋ</li>
                    <li>ਜੇ ਤੁਸੀਂ ਉਸੇ ਦਿਨ ਮੇਲ ਆਪਣੇ ਘਰ ਲਿਆਉਂਦੇ ਹੋ, ਤਾਂ ਉਨ੍ਹਾਂ ਨੂੰ ਇਕ ਪਾਸੇ ਰੱਖਣ ਬਾਰੇ ਵਿਚਾਰ ਕਰੋ ਤਾਂ ਜੋ ਤੁਸੀਂ ਬਾਅਦ ਵਿੱਚ ਇਸ ਨੂੰ ਪੜ੍ਹ ਸਕੋਂ</li>
                    <li>ਆਪਣੀ ਮੇਲ ਨੂੰ ਸੰਭਾਲਣ ਤੋਂ ਬਾਅਦ ਆਪਣੇ ਹੱਥ ਧੋਵੋ</li>
                  </ul>
                </p>
              </div>}
          />
          <h4 className="content-tips-disclaimer">ਅਸੀਂ ਜਾਣਦੇ ਹਾਂ ਕਿ ਹੇਠਾਂ ਦਿੱਤੇ ਸੁਝਾਅ ਹਰੇਕ ਲਈ ਇਕੋ ਤਰੀਕੇ ਨਾਲ ਲਾਗੂ ਨਹੀਂ ਹੋ ਸਕਦੇ ਹਨI ਹਰੇਕ ਵਿਅਕਤੀ ਦੀ ਅਤੇ ਹਰੇਕ ਪਰਿਵਾਰ ਦੀ ਸਥਿਤੀ ਅਤੇ ਸਰੋਤ ਵੱਖਰੇ ਹੁੰਦੇ ਹਨI ਸਾਨੂੰ ਉਮੀਦ ਹੈ ਕਿ ਹੇਠਾਂ ਦਿੱਤੀਆਂ ਉਦਾਹਰਣਾਂ ਤੁਹਾਨੂੰ ਕੁਝ ਮਦਦਗਾਰ ਵਿਚਾਰ ਦੇ ਸਕਦੀਆਂ ਹਨI ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਇਹ ਸਾਂਝਾ ਕਰੋ ਕਿ ਤੁਸੀਂ ਇਹ ਸੁਝਾਅ ਕਿਵੇਂ ਵਰਤੋਂ ਵਿੱਚ ਲਿਆਂਦੇI</h4>
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Tips;
