import React, { Component } from 'react'
import classnames from 'classnames'

import Button from '../Components/Button'

class About extends Component {

  render() {

    return (
      <div className="about">
        <div className="about-header grid">
          <h1 className="about-title">ਆਓ ਗਿਆਨ ਦੇ ਨਾਲ ਮਿਲ ਕੇ ਇਸ ਮਹਾਂਮਾਰੀ ਦਾ ਸਾਹਮਣਾ ਕਰੀਏ</h1>
          <p className="about-description">ਅਸੀਂ ਯੂਸੀਐਸਐਫ (UCSF) ਦੇ ਮੈਡੀਕਲ ਵਿਦਿਆਰਥੀ ਹਾਂ ਅਤੇ ਅਸੀਂ ਕੋਰੋਨਵਾਇਰਸ ਬਾਰੇ ਜਾਣਕਾਰੀ ਦਾ ਇੱਕ ਭਰੋਸੇਯੋਗ ਸਰੋਤ ਬਣਾਉਣ ਲਈ ਇੱਕ ਡਿਜ਼ਾਈਨਰ / ਇੰਜੀਨੀਅਰ ਨਾਲ ਭਾਈਵਾਲੀ ਕੀਤੀ ਅਤੇ ਇਹ ਵੈਬਸਾਈਟ ਤਿਆਰ ਕੀਤੀI</p>
          <p className="about-description">ਗਲਤ ਜਾਣਕਾਰੀ ਡਰ ਦਾ ਕਾਰਨ ਬਣ ਸਕਦੀ ਹੈ ਅਤੇ ਇਹ ਸੰਭਾਵਿਤ ਤੌਰ ਤੇ ਨੁਕਸਾਨਦੇਹ ਵੀ ਹੋ ਸਕਦੀ ਹੈI ਜਨਤਕ ਤੌਰ ਤੇ ਉਪਲਬਧ ਜਾਣਕਾਰੀ ਨੂੰ ਸਮਝਣਾ ਅਕਸਰ ਮੁਸ਼ਕਲ ਹੁੰਦਾ ਹੈ ਜਾਂ ਇਹ ਜਾਣਕਾਰੀ ਸਿਰਫ ਕੁਝ ਚੁਣੀਆਂ ਗਈਆਂ ਭਾਸ਼ਾਵਾਂ ਵਿੱਚ ਉਪਲਬਧ ਹੁੰਦੀ ਹੈI</p>
          <p className="about-description">ਸਾਡਾ ਮਕਸਦ ਇਸ ਮਹਾਂਮਾਰੀ ਦੇ ਵਿਰੁੱਧ ਮਿਲ ਕੇ ਬਿਹਤਰ ਲੜਾਈ ਲੜਨ ਦੇ ਇਰਾਦੇ ਨਾਲ ਕਈ ਭਾਸ਼ਾਵਾਂ ਵਿੱਚ ਕੋਰੋਨਵਾਇਰਸ ਬਾਰੇ ਭਰੋਸੇਮੰਦ ਅਤੇ ਸਮਝਣਯੋਗ ਜਾਣਕਾਰੀ ਪੈਦਾ ਕਰਨਾ ਹੈI</p>
          <div className="about-disclaimer">
            <h3 className="about-disclaimer-caption">ਬੇਦਾਅਵਾ</h3>
            <p className="about-disclaimer-content">ਅਸੀਂ ਕੋਵਿਡ-19 ਦੇ ਮਾਹਰ ਨਹੀਂ ਹਾਂI ਪਰ ਮੈਡੀਕਲ ਵਿਦਿਆਰਥੀ ਹੋਣ ਵਜੋਂ ਸਾਡੇ ਕੋਲ ਮੈਡੀਕਲ ਗਿਆਨ ਅਤੇ ਖੋਜ ਵਿਆਖਿਆ ਵਿੱਚ ਇੱਕ ਪਿਛੋਕੜ ਹੈI ਅਸੀਂ ਲੋਕਾਂ ਨਾਲ ਸਾਂਝਾ ਕਰਨ ਲਈ ਭਰੋਸੇਯੋਗ ਸਰੋਤਾਂ ਅਤੇ ਖੋਜ ਅਧਿਐਨਾਂ ਤੋਂ ਧਿਆਨ ਨਾਲ ਇਸ ਜਾਣਕਾਰੀ ਨੂੰ ਚੁਣਿਆ ਹੈI</p>
            <p className="about-disclaimer-content">ਸਾਡੇ ਵਿਚਾਰ ਸਾਡੇ ਆਪਣੇ ਹਨ ਅਤੇ ਸਾਡੀ ਸੰਬੰਧਿਤ ਸੰਸਥਾ ਦੀ ਨੁਮਾਇੰਦਗੀ ਨਹੀਂ ਕਰਦੇI ਅਸੀਂ ਉਨ੍ਹਾਂ ਡਾਕਟਰਾਂ ਦਾ ਧੰਨਵਾਦ ਕਰਦੇ ਹਾਂ ਜਿਨ੍ਹਾਂ ਨੇ ਸਾਡੀ ਸਮੱਗਰੀ ਲਈ ਯੋਗਦਾਨ ਪਾਇਆ ਅਤੇ ਸਮੀਖਿਆ ਕੀਤੀI ਡਾਕਟਰ ਮੀਲੀ ਲੀ ਅਤੇ ਡਾਕਟਰ ਚਾੰਗਸੂ ਪਾਰਕ ਤੁਹਾਡਾ ਧੰਨਵਾਦ ਹੈI</p>
          </div>
          <div className="about-action desktop">
            <a href="mailto:contact@covid19factcheck.com" target="_blank"><div className="btn btn-primary about-action-btn">ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ</div></a>
            <h4 className="about-action-caption">ਸਾਨੂੰ ਇਸ ਪਤੇ ਤੇ ਈ-ਮੇਲ ਕਰੋ contact@covid19factcheck.com</h4>
          </div>
        </div>
        <div className="about-team">
          <h1 className="about-team-title">ਟੀਮ</h1>
          <div className="about-team-row grid">
            <div className="about-team-section">
              <h3 className="about-team-section-header">ਇਸ ਵੈਬਸਾਈਟ ਦੀ ਸਮੱਗਰੀ ਤੇ ਜਾਣਕਾਰੀ ਦਾ ਸੰਸਲੇਸ਼ਣ</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Hallen Pham</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Fiona Ng</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Anuvir Singh</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">ਵੈੱਬਸਾਈਟ ਡਿਜ਼ਾਈਨਰ</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Shawn Park</div>
                <h4 className="about-team-member-title">Staff Product Designer, Discord</h4>
                <h4 className="about-team-member-link">
                  <a href="https://designbyroka.com/" target="_blank">Website</a>
                  <a href="https://www.linkedin.com/in/shawn-park-9033a251/" target="_blank">Linkedin</a>
                </h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">ਅਮਹੈਰਕ ਅਨੁਵਾਦ</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Beza Dagne</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Yetinayet Dagne</div>
                <h4 className="about-team-member-title">RN BSN, 2nd year Family Nurse Practitioner Student, SJSU</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">ਅਰਬੀ ਅਨੁਵਾਦ</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Tessnim Ahmad, MD</div>
                <h4 className="about-team-member-title">UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Sarah Semaan</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Amer Alsoudi</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
          </div>
          <div className="about-team-row grid">
            <div className="about-team-section">
              <h3 className="about-team-section-header">ਚੀਨੀ ਅਨੁਵਾਦ</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Billy Zeng</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Fiona Ng</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Janette Tang</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Jackie Lin</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Alex Ge</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">ਕੋਰੀਅਨ ਅਨੁਵਾਦ</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Eric Kim</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">James Lim</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Eliana Kim</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Sally Oh</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">ਪੰਜਾਬੀ ਅਨੁਵਾਦ</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Anuvir Singh</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Prabhjot Kaur</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">ਰੂਸੀ ਅਨੁਵਾਦ</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Liza Leykina</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Katerina Protsenko</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Fima Zaltsman, PhD</div>
                <h4 className="about-team-member-title">Postdoctoral Scholar, UCSF</h4>
              </div>
            </div>
          </div>
          <div className="about-team-row grid">
            <div className="about-team-section">
              <h3 className="about-team-section-header">ਸਪੈਨਿਸ਼ ਅਨੁਵਾਦ</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Alexis Velazquez</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Jessica Valdez</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Nicole Rodriguez</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Andres Calvillo</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Dani Montejano</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Sandra Perez</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Ivan Iniguez</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">ਤਾਗਾਲੋਗ ਅਨੁਵਾਦ</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Joel Jose Quitlong Nario</div>
                <h4 className="about-team-member-title">3rd year Medical Student, Weill Cornell Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Francine Castillo</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">ਬੋਲਾਨ ਅਨੁਵਾਦ</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Ha’ano Fonua</div>
                <h4 className="about-team-member-title">5th year Medical Student, University of Auckland, NZ</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">ਟੋਕ ਪਿਸਿਨ ਅਨੁਵਾਦ</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Barbara Angoro</div>
                <h4 className="about-team-member-title">PhD Student, School of Pharmacy, University of Auckland</h4>
              </div>
            </div>
          </div>
          <div className="about-team-row grid">
            <div className="about-team-section">
              <h3 className="about-team-section-header">ਵੀਅਤਨਾਮੀ ਅਨੁਵਾਦ</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Hallen Pham</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Kevin Pham</div>
                <h4 className="about-team-member-title">Molecular, Cell, and Developmental Biology, UCLA</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Kristine Au</div>
                <h4 className="about-team-member-title">De Anza College</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">ਕਲਾ ਦਾ ਕੰਮ</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Tammy Nguyen</div>
                <h4 className="about-team-member-link">
                  <a href="http://tamsanity.com/" target="_blank">Portfolio</a>
                  <a href="https://www.instagram.com/tamsanity/?hl=en" target="_blank">Instagram</a>
                  <a href="https://twitter.com/tamsanity_" target="_blank">Twitter</a>
                </h4>
              </div>
            </div>
          </div>
          <h4 className="about-team-thanks">ਅਸੀਂ ਸਾਡੀ ਅਨੁਵਾਦ ਟੀਮ ਦੀ ਭਰਤੀ ਵਿਚ ਮਦਦ ਲਈ ਯੂਸੀਐਸਐਫ ਏਸ਼ੀਅਨ ਪੈਸੀਫਿਕ ਅਮੇਰਿਕਨ ਮੈਡੀਕਲ ਸਟੂਡੈਂਟਸ ਐਸੋਸੀਏਸ਼ਨ (ਏਪੀਏਐਮਐੱਸਏ), ਯੂਸੀਐਸਐਫ ਕੋਵਿਡ ਸਟੂਡੈਂਟ ਏਡ, ਅਤੇ ਪਾਲ ਪਾਰਕ (ਐਮਐਸ 4) ਦਾ ਧੰਨਵਾਦ ਕਰਦੇ ਹਾਂI</h4>
        </div>
        <div className="about-action mobile">
          <a href="mailto:contact@covid19factcheck.com" target="_blank"><div className="btn btn-primary about-action-btn">ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ</div></a>
          <h4 className="about-action-caption">ਸਾਨੂੰ ਇਸ ਪਤੇ ਤੇ ਈ-ਮੇਲ ਕਰੋ contact@covid19factcheck.com</h4>
        </div>
      </div>
    )
  }
}

export default About