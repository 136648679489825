import React, { Component } from 'react'
import classnames from 'classnames'

import Button from '../Components/Button'

class About extends Component {

  render() {

    return (
      <div className="about">
        <div className="about-header grid">
          <h1 className="about-title">Labanan natin ang pandemyang ito gamit ang ating kaalaman.</h1>
          <p className="about-description">Isa kaming grupo ng mga mag-aaral ng medisina sa UCSF na nakipag-ugnayan sa isang inhinyero/tagapagdisenyo upang makapaglikha ng website na naghahatid ng mapagkakatiwalaang kaalamang nauukol sa coronavirus.</p>
          <p className="about-description">Nakapagdudulot ng takot ang pagkalat ng maling impormasyon. Maaari rin itong maging sanhi ng panganib. Bukod pa rito, madalas ding mahirap maunawaan, o sa iilang wika rin lang mababasa, ang impormasyong karaniwang ipinalalabas sa publiko.</p>
          <p className="about-description">Ang layunin namin ay makapagbigay ng kaalaman sa coronavirus na maaasahan, madaling maintindihan, at mababasa sa iba’t-ibang wika, upang mas magkaisa nating malabanan ang pandemyang ito.</p>
          <div className="about-disclaimer">
            <h3 className="about-disclaimer-caption">Paunawa</h3>
            <p className="about-disclaimer-content">Hindi kami mga eksperto sa COVID-19. Gayunpaman, bilang mga mag-aaral ng medisina, mayroon kaming kaalaman sa impormasyong medikal at sa pagsusuring siyentipiko. Sinuyod namin ng mabuti ang mga impormasyong nakalap namin mula sa mga pinagkakatiwalaang mapagkukunan, kabilang na ang mga siyentipikong pag-aaral, upang maibahagi namin ang mga ito ng maayos sa mas marami pang mga kinauukulan.</p>
            <p className="about-disclaimer-content">Ang mga saloobin sa website na ito ay amin lamang, at hindi opinyon ng aming mga institusyon. Pinasasalamatan namin ang mga manggagamot na nag-ambag at nagsuri sa aming inilathala: sina Dr. MeLee Lee, Dr. Changsoo Park.</p>
          </div>
          <div className="about-action desktop">
            <a href="mailto:contact@covid19factcheck.com" target="_blank"><div className="btn btn-primary about-action-btn">Makipag-ugnayan sa Amin</div></a>
            <h4 className="about-action-caption">Padalhan kami ng email sa contact@covid19factcheck.com</h4>
          </div>
        </div>
        <div className="about-team">
          <h1 className="about-team-title">Mga Kasamahan</h1>
          <div className="about-team-row grid">
            <div className="about-team-section">
              <h3 className="about-team-section-header">Paglilikha ng Nilalaman</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Hallen Pham</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Fiona Ng</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Anuvir Singh</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Inhinyero at Tagapagdisenyo ng Website</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Shawn Park</div>
                <h4 className="about-team-member-title">Staff Product Designer, Discord</h4>
                <h4 className="about-team-member-link">
                  <a href="https://designbyroka.com/" target="_blank">Website</a>
                  <a href="https://www.linkedin.com/in/shawn-park-9033a251/" target="_blank">Linkedin</a>
                </h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Pagsasalin sa Amharic</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Beza Dagne</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Yetinayet Dagne</div>
                <h4 className="about-team-member-title">RN BSN, 2nd year Family Nurse Practitioner Student, SJSU</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Pagsasalin sa Arabe</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Tessnim Ahmad, MD</div>
                <h4 className="about-team-member-title">UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Sarah Semaan</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Amer Alsoudi</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
          </div>
          <div className="about-team-row grid">
            <div className="about-team-section">
              <h3 className="about-team-section-header">Pagsasalin sa Instik</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Billy Zeng</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Fiona Ng</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Janette Tang</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Jackie Lin</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Alex Ge</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Pagsasalin sa Koreyano</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Eric Kim</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">James Lim</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Eliana Kim</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Sally Oh</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Pagsasalin sa Punjabi</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Anuvir Singh</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Prabhjot Kaur</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Pagsasalin sa Ruso</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Liza Leykina</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Katerina Protsenko</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Fima Zaltsman, PhD</div>
                <h4 className="about-team-member-title">Postdoctoral Scholar, UCSF</h4>
              </div>
            </div>
          </div>
          <div className="about-team-row grid">
            <div className="about-team-section">
              <h3 className="about-team-section-header">Pagsasalin sa Kastila</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Alexis Velazquez</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Jessica Valdez</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Nicole Rodriguez</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Andres Calvillo</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Dani Montejano</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Sandra Perez</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Ivan Iniguez</div>
                <h4 className="about-team-member-title">1st year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Pagsasalin sa Tagalog</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Joel Jose Quitlong Nario</div>
                <h4 className="about-team-member-title">3rd year Medical Student, Weill Cornell Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Francine Castillo</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Pagsasalin sa Tongan</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Ha’ano Fonua</div>
                <h4 className="about-team-member-title">PhD Student, 5th year Medical Student, University of Auckland, NZ</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Pagsasalin sa Tok Pisin</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Barbara Angoro</div>
                <h4 className="about-team-member-title">PhD Student, School of Pharmacy, University of Auckland</h4>
              </div>
            </div>
          </div>
          <div className="about-team-row grid">
            <div className="about-team-section">
              <h3 className="about-team-section-header">Pagsasalin sa Vietnamese</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Hallen Pham</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Kevin Pham</div>
                <h4 className="about-team-member-title">Molecular, Cell, and Developmental Biology, UCLA</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Kristine Au</div>
                <h4 className="about-team-member-title">De Anza College</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Pagguguhit</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Tammy Nguyen</div>
                <h4 className="about-team-member-link">
                  <a href="http://tamsanity.com/" target="_blank">Portfolio</a>
                  <a href="https://www.instagram.com/tamsanity/?hl=en" target="_blank">Instagram</a>
                  <a href="https://twitter.com/tamsanity_" target="_blank">Twitter</a>
                </h4>
              </div>
            </div>
          </div>
          <h4 className="about-team-thanks">Pinasasalamatan namin ang Asian Pacific American Medical Students Association (APAMSA) ng UCSF, ang UCSF COVID Student Aid (Tulong Pang-Estudyante), at si Paul Park (MS4) sa tulong nila sa pagbuo ng aming grupong tagapagsalin. Pinasasalamatan namin sina Dr. Elaine Khoong at Dr. Christopher Peabody sa pagtulong sa aming maipaabot itong impormasyon sa mas nakararami pang iba. Pinasasalamatan din namin ang lahat ng aming mga pamilya na tumulong sa pagsasalin ng kaalaman sa iba’t-ibang wika, at sa pagtiyak na ang pagsasaling ito ay angkop at maiintindihan ng lahat.</h4>
        </div>
        <div className="about-action mobile">
          <a href="mailto:contact@covid19factcheck.com" target="_blank"><div className="btn btn-primary about-action-btn">Makipag-ugnayan sa Amin</div></a>
          <h4 className="about-action-caption">Padalhan kami ng email sa contact@covid19factcheck.com</h4>
        </div>
      </div>
    )
  }
}

export default About