import React, { Component } from 'react'
import classnames from 'classnames'
import {
  BrowserRouter as Router,
  Link,
  Route,
  useLocation,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom'

import Button from '../Components/Button'

class Header extends React.PureComponent {

  render() {

    const { toggleLanguageModalActive, toggleShareModalActive, path } = this.props;

    return (
      <div className="header-container">
        <div className="header grid">
          <h1 className="header-title">COVID-19<br />Fact Check</h1>
          <div className="header-description">Learn the facts and myths so you can prepare better and keep others informed.</div>
          <div className="header-action mobile">
            <Button className="btn-secondary" label="English" imgURL="/assets/img/icons/arrow_down.svg" onClick={toggleLanguageModalActive} />
            <Button className="btn-primary" label="Share" imgURL="/assets/img/icons/share.svg" onClick={toggleShareModalActive} />
          </div>
          <div className="header-caption mobile">
            <h5 className="header-caption-item">Most information sourced from <a href="https://www.who.int/" target="_blank">WHO</a> and <a href="https://www.cdc.gov/" target="_blank">CDC.</a> </h5>
            <h5 className="header-caption-item">Last updated 05/18/20.</h5>
          </div>
        </div>
        <div className="nav">
          <a href="/en/" className={classnames('nav-item', {'nav-item-active': path === ''})}>Myth Buster</a>
          <a href="/en/tips" className={classnames('nav-item', {'nav-item-active': path === 'tips'})}>Helpful Tips</a>
          <a href="/en/learn" className={classnames('nav-item', {'nav-item-active': path === 'learn'})}>Learn about COVID-19</a>
          <a href="/en/about" className={classnames('nav-item desktop', {'nav-item-active': path === 'about'})}>About</a>
          <a href="mailto:contact@covid19factcheck.com" target="_blank" className="nav-item desktop">Contact</a>
        </div>
      </div>
    )
  }
}

export default withRouter(Header)