import React, { Component } from 'react'
import classnames from 'classnames'
import {
  BrowserRouter as Router,
  Link,
  Route,
  useLocation,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom'

import Button from '../Components/Button'

class Header extends React.PureComponent {

  render() {

    const { toggleLanguageModalActive, toggleShareModalActive, path } = this.props;

    return (
      <div className="header-container">
        <div className="header grid">
          <h1 className="header-title">COVID-19<br />Fact Check</h1>
          <div className="header-description">Lainim stretpla toktok na giaman toktok so yu ken save gut lo stretpla tok na toksave lo ol narapla lain.</div>
          <div className="header-action mobile">
            <Button className="btn-secondary" label="Tok Pisin" imgURL="/assets/img/icons/arrow_down.svg" onClick={toggleLanguageModalActive} />
            <Button className="btn-primary" label="Sharim" imgURL="/assets/img/icons/share.svg" onClick={toggleShareModalActive} />
          </div>
          <div className="header-caption mobile">
            <h5 className="header-caption-item">Planti toksave ie kam lo <a href="https://www.who.int/" target="_blank">WHO</a> na <a href="https://www.cdc.gov/" target="_blank">CDC.</a> </h5>
            <h5 className="header-caption-item">Laspla taim blo update bin 07/11/20.</h5>
          </div>
        </div>
        <div className="nav">
          <a href="/tp/" className={classnames('nav-item', {'nav-item-active': path === ''})}>Stopim giaman toktok</a>
          <a href="/tp/tips" className={classnames('nav-item', {'nav-item-active': path === 'tips'})}>Gutpla toktok blo halpim</a>
          <a href="/tp/learn" className={classnames('nav-item', {'nav-item-active': path === 'learn'})}>Lainim moa lo COVID-19</a>
          <a href="/tp/about" className={classnames('nav-item desktop', {'nav-item-active': path === 'about'})}>Stori blo mipla</a>
          <a href="mailto:contact@covid19factcheck.com" target="_blank" className="nav-item desktop">Kontactim mipla</a>
        </div>
      </div>
    )
  }
}

export default withRouter(Header)