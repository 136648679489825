import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Tips extends Component {
  
  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <h4 className="content-nav-caption">목차</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-1')}>올바른 손씻기</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-2')}>마스크 쓰는 요령</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-3')}>장갑 끼는 요령</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-4')}>청소와 소독</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-5')}>장보기</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-6')}>배달음식</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-7')}>택배와 우편물</h4>
        </div>
        <div className="content-body">
          <Card 
            id="tips-1"
            thumbnail="/assets/img/shots/tips/tips-1.jpg" 
            title="올바른 손씻기"
            content={
              <div>
                <p>손을 자주 씻는 것은 COVID19 예방에 매우 중요합니다. 손 씻기 전 눈, 코, 입을 만지는 것을 삼가하십시오.</p>
                <hr />
                <p>
                  <div className="paragraph-header">올바른 손씻기 5단계 방법</div>
                  <ol className="content-list">
                    <li><div className="ol-index">1</div>흐르는 물로 양 손을 적셔주세요.</li>
                    <li><div className="ol-index">2</div>비누를 사용해 손바닥, 손가락 사이, 손등, 손톱 밑까지 깨끗이 문지르세요.</li>
                    <li><div className="ol-index">3</div>흐르는 물에 20초 동안 손을 씻으세요. (생일 축하 노래 2번 부르기)</li>
                    <li><div className="ol-index">4</div>흐르는 물에 손을 헹구세요.</li>
                    <li><div className="ol-index">5</div>깨끗한 수건으로 손을 닦거나 자연 건조 하세요.</li>
                  </ol>
                  <h5 className="content-source"><a href="https://www.cdc.gov/handwashing/when-how-handwashing.html" target="_blank">출처: CDC</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/wd6XR9Q1IM8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p>
                  <div className="paragraph-header">언제 손을 씻어야 하나요?</div>
                  <ul className="content-list">
                    <li>외출 후</li>
                    <li>
                      <div>생활 속 물건이나 표면을 만진 후</div>
                      <ul>
                        <li>예: 문 손잡이, 탁자, 주유기, 쇼핑 카트, 계산 카운터, 핀 패드, 엘리베이터 버튼 등</li>
                      </ul>
                    </li>
                    <li>식사 전</li>
                    <li>코를 푼 후</li>
                    <li>마스크랑 장갑 착용 전/후</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">비누랑 물이 없는 경우 알코올 손소독제를 사용하세요</div>
                  <ul className="content-list">
                    <li>손소독제는 모든 세균을 죽이지 않습니다</li>
                    <li>60% 알코올을 함유해야합니다</li>
                    <li>손소독제가 마를때까지 깨끗이 손바닥, 손등, 손가락까지 문지르세요</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-2"
            thumbnail="/assets/img/shots/tips/tips-2-ko.jpg" 
            title="마스크 쓰는 요령"
            content={
              <div>
                <p>
                  <ul className="content-list">
                    <div className="content-list-label-positive">천 마스크 사용시 해야할 것</div>
                    <li>숨쉬는데 지장이 없어야 합니다</li>
                    <li>입과 코(콧대부터 턱까지)를 막을수 있어야 합니다</li>
                    <li>양옆 끈을 이용해 벗어야 합니다</li>
                    <li>매일 세탁해줘야 합니다</li>
                    <li>벗은 후 즉시 손을 씻어야 합니다</li>
                    <li>사용후 세탁하기 전 까지 지정된 봉투나 박스에 보관해야 합니다</li>
                  </ul>
                  <ul className="content-list">
                    <div className="content-list-label-negative">천 마스크 사용시 피해야할 것</div>
                    <li>마스크 끈 외에 다른 부분(특히 입이나 코 앞면)은 만지면 안됩니다</li>
                    <li>마스크를 턱 아래에 걸치거나 목 주위로 감으면 안됩니다</li>
                    <li>마스크 측면에 틈새가 없어야 합니다</li>
                    <li>장갑을 끼고 마스크를 만지면 안됩니다</li>
                    <li>수술용 마스크를 재사용하면 안됩니다 </li>
                  </ul>
                  <h5 className="content-source">출처: <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a>; <a href="https://www.nytimes.com/2020/04/08/well/live/coronavirus-face-mask-mistakes.html#click=https://t.co/GkBNJAuw1V" target="_blank">The New York Times</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/m4O-3wQQjpA?start=118" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p>
                  <div className="paragraph-header">마스크를 써도 기존의 예방수칙을 따라야 합니다.</div>
                  <div>마스크나 장갑을 착용해도, 꼭 기억해야 할 행동수칙</div>
                  <ul className="content-list">
                    <li>꼼꼼한 손씻기</li>
                    <li>6피트 (최소 2미터) 사회적 거리두기</li>
                    <li>외출 자제</li>
                  </ul>
                  <div>마스크, 장갑 등 이 바이러스의 전파나 감염을 막는데 도움이 될 수 있으나, 마스크나 장갑 착용 만으로 바이러스 감염을 다 막을 수 있다고 잘 못 생각 할 수도 있습니다. 보호 장비들은 오염된 표면이나 물건에 노출될 수 있으며 잘못된 사용으로인해 오히려 보호장비들에 묻어 있는 바이러스에 감염(교차 감염) 이 될 수 있으니 주의하셔야 합니다.</div>
                </p>
              </div>}
            extra={
              <div>
                <p>
                  <div className="paragraph-header">미국 질병관리본부(CDC)에서 천 마스크 착용을 권하는 이유는?</div>
                  <div><a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover.html" target="_blank">미국 질병관리본부(CDC)</a>에 의하면, 일부 무증상자도 바이러스를 전파할 수 있습니다. 따라서 CDC는 증상이 없는분들 에게도 천 마스크 착용과 6피트 사회적 거리두기를 권하고 있습니다.</div>
                  <ul className="content-list">
                    <div>다음에 해당되는 분들은 천 마스크 사용을 삼가하세요:</div>
                    <li>2세 미만 어린이</li>
                    <li>호흡에 어려움이 있는 사람</li>
                    <li>무의식, 무력 상태이거나 타인의 도움 없이 마스크를 벗을 수 없는 사람 </li>
                  </ul>
                </p>
                <p>CDC는 쓰지 않는 티셔츠나 반다나를 이용해 천 마스크를 만드는 것을 권하고 있습니다. <a href="https://korean.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC 웹사이트</a>를 통해 만드는 방법을 찾아보세요.</p>
                <p><strong>수술용 마스크나 N-95 호흡보호구는 불필요합니다.</strong> 미국 질병관리본부(CDC)에서는 의료 마스크의 공급이 의료 종사자들에게도 부족함을 고려해 일반 시민들은 수술용 마스크와 N-95 호흡보호구 구매를 삼가할 것을 권고하였습니다.</p>
              </div>
            }
          />
          <Card 
            id="tips-3"
            thumbnail="/assets/img/shots/tips/tips-3.jpg" 
            title="장갑 끼는 요령"
            content={
              <div>
                <p>외출시 장갑 사용이 꼭 필요한 것은 아닙니다. 하지만 사용하실 경우, 다음 주의사항을 따라주세요.</p>
                <p>
                  <ul className="content-list">
                    <div className="content-list-label-positive">장갑 사용시 해야할 것</div>
                    <li>천 장갑은 사용후 비누와 물로 깨끗이 씻어주세요</li>
                    <li>비닐장갑은 재사용 하지 마세요</li>
                    <li>천 장갑은 사용후 비누와 물로 깨끗이 씻어주세요</li>
                  </ul>
                  <ul className="content-list">
                    <div className="content-list-label-negative">장갑 사용시 피해야할 것</div>
                    <li>얼굴(특히 눈, 코, 입) 만지는 것을 삼가하세요</li>
                    <li>주머니에 손을 넣지 마세요</li>
                    <li>핸드폰/스마트폰 사용을 삼가하세요</li>
                  </ul>
                  <h5 className="content-source">출처: <a href="https://www.kqed.org/science/1961243/coronavirus-heres-how-to-use-gloves-properly-to-help-prevent-infection" target="_blank">KQED</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/CepaDz4_t_I" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/TnLCYqk20YU?start=116" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>}
          />
          <Card 
            id="tips-4"
            thumbnail="/assets/img/shots/tips/tips-4.jpg" 
            title="청소와 소독"
            content={
              <div>
                <p>현재 데이터에 따르면 오염된 표면을 접촉하는 것은 COVID-19의 주요 감염 경로가 아닌것으로 알려져있습니다. 이런 표면과 손 바닥등의 피부접촉만으로 바이러스에 감염 되지 않습니다. 하지만 바이러스로 오염된 손으로 눈,코,입을 만지면 감염이 될수 있습니다. 집안에 여러가지 표면을 소독하고 손씻기로 예방할 수 있습니다. </p>
                <hr />
                <p>
                  <div className="paragraph-header">코로나바이러스는 얼마나 오랫동안 사물 표면에 남아있나요?</div>
                  <div className="content-table">
                    <div className="content-table-row">
                      <div className="content-table-column">
                        <h3 className="content-table-heading">4 시간</h3>
                        <h4 className="content-table-caption">구리</h4>
                      </div>
                      <div className="content-table-column">
                        <h3 className="content-table-heading">24 시간</h3>
                        <h4 className="content-table-caption">판지 (카드보드)</h4>
                      </div>
                    </div>
                    <div className="content-table-row">
                      <div className="content-table-column">
                        <h3 className="content-table-heading">2-3 일</h3>
                        <h4 className="content-table-caption">플라스틱</h4>
                      </div>
                      <div className="content-table-column">
                        <h3 className="content-table-heading">2-3 일</h3>
                        <h4 className="content-table-caption">스테인레스</h4>
                      </div>
                    </div>
                  </div>
                  <div>코로나바이러스는 몇시간, 몇일 후에도 탐지됐지만 바이러스의 양은 시간이 지남에 따라 급격히 감소하고 감염 위험도 줄어 듭니다. 출처: <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973" target="_blank">The New England Journal of Medicine</a> (3/17/2020)</div>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">사물 표면을 소독하기 위해 무엇을 사용할 수 있나요?</div>
                  <ul className="content-list">
                    <div>여러가지 표면을 소독 및 멸균하는 방법에 대한 상세한 정보는 미국 질병관리본부(CDC) <a href="https://korean.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cleaning-disinfection.html" target="_blank">웹사이트</a>를 참고하세요. <a href="https://korean.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/disinfecting-your-home.html" target="_blank">CDC</a>는 다음을 권장합니다:</div>
                    <li>EPA가 지정한 소독제 <a href="https://www.cnn.com/2020/03/05/health/epa-disinfectants-coronavirus-trnd/index.html" target="_blank">(여기 목록을 참고하세요)</a></li>
                    <li>
                      <div>표백제 희석용액</div>
                      <ul>
                        <li>물 1gal (3,785ml)당 표백제 5tbsp (74ml) 희석하세요</li>
                        <li><strong>또는</strong> 물 1qt (946ml)당 표백제 4tbsp (59ml) 희석하세요</li>
                        <li>표백제를 이렇게 희석하지 않고 용기에서 바로 사용하지 마세요.</li>
                        <li>표백제의 사용 기한이 만료되지 않았는지 확인하세요.</li>
                        <li>표백제를 암모니아나 다른 소독제와 섞지 마세요.</li>
                      </ul>
                    </li>
                    <li>70% 이상의 알코올이 포함되어 있는 알코올 소독제</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-5"
            thumbnail="/assets/img/shots/tips/tips-5.jpg" 
            title="장보기"
            content={
              <div>
                <p>마트에 있는 물건들보다는 마트에 있는 <strong>사람들로부터</strong> 전염될 확률이 높습니다. CDC에 따르면, <a href="https://korean.cdc.gov/coronavirus/2019-ncov/daily-life-coping/essential-goods-services.html" target="_blank">식품이나 물품 포장이</a> 바이러스를 확산시킨다는 증거가 없습니다.</p>
                <p>
                  <div className="paragraph-header">장보기 팁</div>
                  <ul className="content-list">
                    <li>호흡기 증상이 있거나 코로나바이러스에 노출 된 경우에는 외출(장보기)하시면 안됩니다.</li>
                    <li>1주 또는 2주에 한번씩만 장을 보세요</li>
                    <li>가족 중 한 사람만 장을 보도록 정하세요</li>
                    <li>가능하면 아이들은 어른과 함께 집에 있는게 좋습니다.</li>
                    <li>쇼핑 시간을 줄이기 위해 식료품 목록을 작성하십시오</li>
                    <li>마스크를 꼭 쓰고 사람들로부터 충분히 거리를 두세요.</li>
                    <li>쇼핑 카트의 손잡이를 닦으세요.</li>
                    <li>가능하다면 신용카드나 현금보다는 삼성페이나 애플페이 같은 간편결제 서비스를 사용하세요.</li>
                    <li>장을 보고난 후 손 소독제를 사용하세요.</li>
                    <li>집에 도착하면 비누와 물로 최소 20초 동안 손을 씻으세요.</li>
                    <li>직접 식료품을 구입할 수 없는 가족, 친구, 또는 이웃을 도와주세요</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">장본 물건을 소독할 필요는 없습니다.</div>
                  <div>꼭 기억해야 할 점은 오염된 손과 표면을 씻는 것이지, 물건들을 소독할 필요는 없습니다. 또한, 과일이나 채소를 씻을 때에는 비누를 사용하지 마세요.</div>
                  <ul className="content-list">
                    <div>만약 <a href="https://www.npr.org/sections/health-shots/2020/04/12/832269202/no-you-dont-need-to-disinfect-your-groceries-but-here-s-to-shop-safely" target="_blank">꼭 물건들을 씻어야한다면</a> 다음과 같은 요령을 추천합니다:</div>
                    <li>불필요한 겉포장은 버리세요.</li>
                    <li>플라스틱이나 판지(카드보드)로 포장된 물품들은 소독제 대신 비누와 물로 씻으세요. 소독제는 포장 안에 있는 물건으로 흘러 들어갈 수 있습니다.</li>
                    <li>소독제는 유리 병이나 캔 같은 딱딱한 표면을 닦을 때만 사용하세요.</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-6"
            thumbnail="/assets/img/shots/tips/tips-6.jpg" 
            title="배달음식"
            content={
              <div>
                <p><a href="https://www.fda.gov/food/food-safety-during-emergencies/shopping-food-during-covid-19-pandemic-information-consumers" target="_blank">FDA</a>에 따르면, 코로나바이러스는 식중독이나 위장병처럼 오염된 식품으로부터 걸리는 질병이 아니라, 사람과 사람으로부터 걸리는 질병입니다.</p>
                <p>
                  <ul className="content-list">
                    <li>배달음식을 받을 때 문 밖에 놓아두라 요청하세요</li>
                    <li>음식을 가정 내에서 사용하는 용기로 옮기고 포장 용기는 폐기하세요</li>
                    <li>레스토랑에서 제공하는 수저를 사용할시 물과 비누로 깨끗이 씻으세요</li>
                    <li>음식을 만지기 전 손을 깨끗이 씻으세요</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-7"
            thumbnail="/assets/img/shots/tips/tips-7.jpg" 
            title="택배와 우편물"
            content={
              <div>
                <p>NEJM <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973?query=featured_home" target="_blank">연구</a> 결과에 따르면 코로나바이러스는 카드보드(판지, 박스종이)에 최장 24시간까지 발견이 된다고 합니다. 하지만 바이러스가 있다고해서 감염되는 것은 아닙니다. <a href="https://korean.cdc.gov/coronavirus/2019-ncov/faq.html" target="_blank">CDC</a>에 따르면, 우편물이나 택배로부터 감염될 확률은 낮습니다.</p>
                <p>
                  <ul className="content-list">
                    <li>택배 내용물을 정리한 후에는 손을 씻으세요</li>
                    <li>가위나 다른 오염된 표면을 닦으세요.</li>
                  </ul>
                </p>
              </div>}
          />
          <h4 className="content-tips-disclaimer">위 정보는 모두에게 적용되지 않을 수 있습니다. 각자 환경과 상황에 따라 참고하시고 좋은 정보를 얻어가시길 바랍니다.</h4>
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Tips;
