import React, { Component } from 'react'
import classnames from 'classnames'
import { Link, withRouter } from 'react-router-dom'

import Button from '../Components/Button'
import ClipboardButton from '../Components/ClipboardButton'

class Aside extends React.PureComponent {

  render() {

    return (
      <div className="content-aside desktop">
        <div className="content-share">
          <h4 className="content-share-caption">Поделитесь с друзьями и семьей</h4>
          <div className="content-share-options">
            <div className="content-share-item content-share-twitter">
              <img className="fit-height" src="/assets/img/icons/twitter.png" />
              <a className="twitter-share-button" href="https://twitter.com/intent/tweet?text=%D0%9F%D1%80%D0%BE%D0%B2%D0%B5%D1%80%D1%8C%D1%82%D0%B5%20%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B8%D1%86%D1%83%20COVID-19%20Fact%20Check%2C%20%D1%87%D1%82%D0%BE%D0%B1%D1%8B%20%D1%83%D0%B7%D0%BD%D0%B0%D1%82%D1%8C%20%D0%BC%D0%B8%D1%84%D1%8B%20%D0%B8%20%D1%84%D0%B0%D0%BA%D1%82%D1%8B%20%D0%BE%20%D0%BA%D0%BE%D1%80%D0%BE%D0%BD%D0%B0%D0%B2%D0%B8%D1%80%D1%83%D1%81%D0%B5&tw_p=tweetbutton&url=http%3A%2F%2Fcovid19factcheck.com%2Fru%2F" target="_blank">Tweet</a>
            </div>
            <div className="content-share-item content-share-facebook">
              <img className="fit-height" src="/assets/img/icons/facebook.png" />
              <a className="fb-share-button" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=http://covid19factcheck.com/ru/"></a>
            </div>
            <div className="content-share-item content-share-email">
              <img className="fit-height" src="/assets/img/icons/email.svg" />
              <a href="mailto:?body=%D0%9F%D1%80%D0%BE%D0%B2%D0%B5%D1%80%D1%8C%D1%82%D0%B5%20%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B8%D1%86%D1%83%20COVID-19%20Fact%20Check%2C%20%D1%87%D1%82%D0%BE%D0%B1%D1%8B%20%D1%83%D0%B7%D0%BD%D0%B0%D1%82%D1%8C%20%D0%BC%D0%B8%D1%84%D1%8B%20%D0%B8%20%D1%84%D0%B0%D0%BA%D1%82%D1%8B%20%D0%BE%20%D0%BA%D0%BE%D1%80%D0%BE%D0%BD%D0%B0%D0%B2%D0%B8%D1%80%D1%83%D1%81%D0%B5%20http%3A%2F%2Fcovid19factcheck.com%2Fru%2F"></a>
            </div>
            <ClipboardButton className="content-share-item content-share-link" value="http://covid19factcheck.com/ru/" success="Ссылка была скопирована! 🎉"><img className="fit-height" src="/assets/img/icons/link.svg" /></ClipboardButton>
          </div>
        </div>
        <h5 className="content-footnote">Большая часть информации поступает <a href="https://www.who.int/" target="_blank">ВОЗ</a> & <a href="https://www.cdc.gov/" target="_blank">CDC</a>.<br /> Последнее обновление 05/18/2020.</h5>
      </div>
    )
  }
}

export default withRouter(Aside)