import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Tips extends Component {
  
  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <h4 className="content-nav-caption">Оглавление</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-1')}>Советы по мытью рук</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-2')}>FСоветы по ношению маски</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-3')}>Рекомендации по ношению перчаток</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-4')}>Правила уборки</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-5')}>Во время покупок в продуктовом магазине</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-6')}>Обработка доставленных вам продуктов</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-7')}>Обработка почтовых посылок и писем</h4>
        </div>
        <div className="content-body">
          <Card 
            id="tips-1"
            thumbnail="/assets/img/shots/tips/tips-1.jpg" 
            title="Советы по мытью рук"
            content={
              <div>
                <p>Частое мытье рук  очень важно для предотвращения распространения COVID-19. Избегайте прикосновения глаз, носа или рта до того как помыли руки.</p>
                <hr />
                <p>
                  <div className="paragraph-header">Советы как правильно мыть руки</div>
                  <ol className="content-list">
                    <li><div className="ol-index">1</div>Намочите руки проточной водой</li>
                    <li><div className="ol-index">2</div>Добавьте мыло и потрите руки, включая между пальцами, с обратной стороны ладони и под ногтями</li>
                    <li><div className="ol-index">3</div>Потрите руки в течение 20 секунд (Пропойте "С Днем Рождения" 2 раза.)</li>
                    <li><div className="ol-index">4</div>Ополосните руки водой</li>
                    <li><div className="ol-index">5</div>Высушите руки с помощью полотенца или дайте им высохнуть на воздухе.</li>
                  </ol>
                  <h5 className="content-source"><a href="https://www.cdc.gov/handwashing/when-how-handwashing.html" target="_blank">Источник: CDC</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/CSJO52qZLbs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p>
                  <div className="paragraph-header">Когда нужно мыть руки?</div>
                  <ul className="content-list">
                    <li>Как можно чаще</li>
                    <li>После нахождения в общественном месте</li>
                    <li>
                      <div>После дотрагивания до общепринятых поверхностей</div>
                      <ul>
                        <li>Например: ручки дверей, столы, шланги на заправках, магазинных тележек, кнопок на кассе и в лифтах</li>
                      </ul>
                    </li>
                    <li>До, во время и после приготовления пищи</li>
                    <li>До еды</li>
                    <li>После кашля, чихания и высмаркивания носа</li>
                    <li>До надевания и после снятия маски и перчаток</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">Если нет воды и мыла используйте спиртосодержащее дезинфицирующее средство</div>
                  <ul className="content-list">
                    <li>Спиртосодержащее дезинфицирующее средство не убивает все виды микробов</li>
                    <li>Спиртосодержащее дезинфицирующее средство должно содержать не менее 60% спирта</li>
                    <li>Втирайте гель с обеих сторон рук, между пальцами и кончиками пальцев</li>
                    <li>Трите руки пока они не станут <strong>сухими</strong></li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-2"
            thumbnail="/assets/img/shots/tips/tips-2-ru.jpg" 
            title="Советы по ношению маски"
            content={
              <div>
                <p>
                  <div className="paragraph-header">Правила  прикрывания лица</div>
                  <ul className="content-list">
                    <div className="content-list-label-positive">Делайте следующее</div>
                    <li>Убедитесь  что вы можете дышать через  маску</li>
                    <li>Убедитесь  что маска  закрывает ваш нос и рот (от переносицы до  нижней части подбородка)</li>
                    <li>Используйте боковые ленты когда снимаете маску</li>
                    <li>Стирайте и гладьте тканевую маску ежедневно</li>
                    <li>Мойте руки  после того как сняли маску</li>
                    <li>Держите маску в специальном  пакете или  коробке до тех пор пока маска не почищена</li>
                  </ul>
                  <ul className="content-list">
                    <div className="content-list-label-negative">Избегайте</div>
                    <li>Дотрагивания до передней части маски которое наиболее грязное место</li>
                    <li>Соскальзывания маски под подбородок или оборачивания вокруг шеи</li>
                    <li>Ослабления боковых затяжек</li>
                    <li>Снятия маски руками в перчатках</li>
                    <li>Повторного использования хирургических масок</li>
                  </ul>
                  <h5 className="content-source">Источник: <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a>; <a href="https://www.nytimes.com/2020/04/08/well/live/coronavirus-face-mask-mistakes.html#click=https://t.co/GkBNJAuw1V" target="_blank">The New York Times</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/m4O-3wQQjpA?start=118" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p>
                  <div className="paragraph-header">Закрывание лицо маской не заменяет другие превентивные меры</div>
                  <div>Если вы решили закрывать лицо маской и/или надевать перчатки, продолжайте:</div>
                  <ul className="content-list">
                    <li>Тщательно мыть руки</li>
                    <li>Держать дистанцию 2 метров друг от друга</li>
                    <li>Продолжать самоизоляцию</li>
                  </ul>
                  <div>Хотя защитные меры (маски, перчатки и др.) помогают уменьшить  распространение инфекции, они могут дать чувство ложной защиты. Защитные средства находятся в прямом контакте с потенциально  зараженными поверхностями. Если они используются некорректно, это вызывает перекрестное заражение.</div>
                </p>
              </div>}
            extra={
              <div>
                <p>
                  <div className="paragraph-header">Почему СДС рекомендует использование маски в общественном месте </div>
                  <div>Согласно <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover.html" target="_blank">CDC</a>, вы можете распространять вирус не имея симптомов (Явление известное как "Бессимптомное"). Прикрывая лицо и соблюдая дистанцию 2 метров друг от друга, вы можете остановить распространение Коронавируса в обществе.</div>
                  <ul className="content-list">
                    <div>Некоторые люди могут не использовать маски!</div>
                    <li>Дети до 2-х лет</li>
                    <li>Люди с осложненным дыханием</li>
                    <li>Лица, которые находятся без сознания</li>
                    <li>Лица, которые не могут снять маску без посторонней помощи</li>
                  </ul>
                </p>
                <p>В Соединенных Штатах, CDC рекомендует использование материалов имеющихся дома, таких как старые футболки, платки. Пожалуйста посмотрите <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">Инструкцию</a> CDC как сделать маску из футболки или платка.</p>
                <p><strong>Вам не нужны хирургические маски или респираторы N-95.</strong> В действительности CDC не рекомендует приобретать дополнительные маски и респираторы в связи с ограниченным их количеством в США.</p>
              </div>
            }
          />
          <Card 
            id="tips-3"
            thumbnail="/assets/img/shots/tips/tips-3.jpg" 
            title="Рекомендации по ношению перчаток"
            content={
              <div>
                <p>Перчатки не защищают вас в общественных местах. Однако, если вы решили носить перчатки, соблюдайте следующие правила.</p>
                <p>
                  <ul className="content-list">
                    <div className="content-list-label-positive">Делайте следующее</div>
                    <li>Мойте руки до <strong>и сразу после</strong> снятия перчаток</li>
                    <li>Выбрасывайте и не используйте повторно пластиковые перчатки</li>
                    <li>Тканевые перчатки стирайте с мылом и водой перед тем как одеваете снова.</li>
                  </ul>
                  <ul className="content-list">
                    <div className="content-list-label-negative">Избегайте</div>
                    <li>Прикосновения рта, носа и глаз</li>
                    <li>Складывания рук в карманы</li>
                    <li>Пользования вашего телефона</li>
                  </ul>
                  <h5 className="content-source">Источник: <a href="https://www.kqed.org/science/1961243/coronavirus-heres-how-to-use-gloves-properly-to-help-prevent-infection" target="_blank">KQED</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/TnLCYqk20YU?start=116" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>}
          />
          <Card 
            id="tips-4"
            thumbnail="/assets/img/shots/tips/tips-4.jpg" 
            title="Правила уборки"
            content={
              <div>
                <p>Согласно последним исследованиям  прикосновения к загрязненным поверхностям не основной путь распространения COVID-19. Вы не можете заразиться при дотрагивании  поверхности загрязненной вирусом. Тем не менее, риск инфицирования  увеличивается  если на ваших руках вирус и вы дотрагиваетесь до глаз, носа и рта. Мытье поверхностей  и рук после притрагивания к поверхностям помогает снизить риск распространения вируса.</p>
                <hr />
                <p>
                  <div className="paragraph-header">Как долго коронавирус остается активным на различных поверхностях ?</div>
                  <div className="content-table">
                    <div className="content-table-row">
                      <div className="content-table-column">
                        <h3 className="content-table-heading">4 часа</h3>
                        <h4 className="content-table-caption">Mедь</h4>
                      </div>
                      <div className="content-table-column">
                        <h3 className="content-table-heading">24 часа</h3>
                        <h4 className="content-table-caption">Kартон</h4>
                      </div>
                    </div>
                    <div className="content-table-row">
                      <div className="content-table-column">
                        <h3 className="content-table-heading">2-3 дня</h3>
                        <h4 className="content-table-caption">Пластик</h4>
                      </div>
                      <div className="content-table-column">
                        <h3 className="content-table-heading">2-3 дня</h3>
                        <h4 className="content-table-caption">Нержавеющая сталь</h4>
                      </div>
                    </div>
                  </div>
                  <div>Хотя коронавирус был обнаружен на поверхностях в течении несколько часов или дней, количество вируса уменьшается с течением времени и таким образом риск заражения уменьшается. (Основываясь на исследовании опубликованном  в журнале <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973" target="_blank">The New England Journal of Medicine</a> (3/17/2020)</div>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">Что использовать для  мытья поверхностей?</div>
                  <ul className="content-list">
                    <div>Пожалуйста, посетите страницу CDC для получения дополнительных рекомендаций о том, как <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cleaning-disinfection.html" target="_blank">чистить различные типы поверхностей</a> СДС рекомендует следующие средства:</div>
                    <li>EPA-утвержденные дезинфицирующие средства <a href="https://www.cnn.com/2020/03/05/health/epa-disinfectants-coronavirus-trnd/index.html" target="_blank">(смотрите список)</a></li>
                    <li>
                      <div>Разбавленный раствор отбеливателя</div>
                      <ul>
                        <li>5 столовых ложек (1/3 чашки, 80 мл) отбеливателя на каждый галлон (3,8 Литра) воды</li>
                        <li><strong>ИЛИ</strong> 4 чайные ложки отбеливателя на 1 литр воды</li>
                        <li>Не используйте отбеливатель без предварительного разведения</li>
                        <li>Убедитесь что отбеливатель не просрочен</li>
                        <li>Не смешивайте отбеливатель с аммиаком или другими  очищающими средствами в доме</li>
                      </ul>
                    </li>
                    <li>Спиртовые растворы содержащие минимум 70% спирта</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-5"
            thumbnail="/assets/img/shots/tips/tips-5.jpg" 
            title="Во время покупок в продуктовом магазине"
            content={
              <div>
                <p><strong>Вы имеете больше шансов заразиться от людей в продуктовом магазине, а не от продуктов.</strong> Согласно <a href="https://www.cdc.gov/coronavirus/2019-ncov/daily-life-coping/essential-goods-services.html" target="_blank">CDC</a>, «нет никаких доказательств того, что пища или упаковка пищевых продуктов играют значительную роль в распространении вируса в Соединенных Штатах».</p>
                <p>
                  <div className="paragraph-header">Pекомендации для покупок в продуктовом магазине</div>
                  <ul className="content-list">
                    <li>Не ходите в магазин если у вас респираторные симптомы  или вы были в контакте с больным</li>
                    <li>Старайтесь делать покупки один раз в неделю или один раз в две недели</li>
                    <li>Старайтесь сделать так, чтобы один член семьи ходил в магазин</li>
                    <li>Если возможно, оставьте детей дома с кем-то из взрослых.</li>
                    <li>Напишите список продуктов. Это поможет сократить время в магазине.</li>
                    <li>Носите маску и держитесь на расстоянии от других.</li>
                    <li>Протрите ручку тележки в магазине.</li>
                    <li>Если возможно выбирайте бесконтактные способы оплаты.</li>
                    <li>Выйдя из магазина, используйте спиртосодержащее дезинфицирующее средство.</li>
                    <li>Когда вы вернетесь домой, вымойте руки с мылом и водой не менее 20 секунд.</li>
                    <li>Предложите помощь членам семьи, друзьям и соседям если они не могут пойти в магазин за продуктами.</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">Вам не нужно дезинфицировать ваши продукты</div>
                  <div>Самое главное, чтобы вы вымыли руки и поверхности после работы с продуктами. Избегайте мыла при полоскании свежих фруктов и овощей.</div>
                  <ul className="content-list">
                    <div>Однако, <a href="https://www.npr.org/sections/health-shots/2020/04/12/832269202/no-you-dont-need-to-disinfect-your-groceries-but-here-s-to-shop-safely" target="_blank">если вы действительно хотите обработать продукты</a>:</div>
                    <li>Рассмотрите возможность утилизации всех ненужных внешних упаковок</li>
                    <li>Используйте мыло и воду для пластиковой или картонной упаковки. Это предотвращает попадание дезинфицирующих химикатов в вашу еду</li>
                    <li>Используйте дезинфицирующие спреи и салфетки только на твердых поверхностях, таких как металлические и стеклянные банки</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-6"
            thumbnail="/assets/img/shots/tips/tips-6.jpg" 
            title="Обработка доставленных вам продуктов"
            content={
              <div>
                <p>Согласно <a href="https://www.fda.gov/food/food-safety-during-emergencies/shopping-food-during-covid-19-pandemic-information-consumers" target="_blank">FDA</a>, «COVID-19 распространяется от человека к человеку, в отличие от пищевых желудочно-кишечных вирусов, которые часто делают людей больными через загрязненную пищу».</p>
                <p>
                  <ul className="content-list">
                    <li>Доставка: попросите оставить продукты около двери</li>
                    <li>Переложите продукты из ресторанной упаковки в домашние контейнеры/посуду</li>
                    <li>Если вы предполагаете использование их посуды, мойте ее с водой и мылом перед использованием</li>
                    <li>Обязательно мойте руки перед дотрагиванием до продуктов.</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-7"
            thumbnail="/assets/img/shots/tips/tips-7.jpg" 
            title="Обработка почтовых посылок и писем"
            content={
              <div>
                <p>В <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973?query=featured_home" target="_blank">научном журнале NEJM сообщается</a>, что небольшое количество коронавируса сохраняется на картонной упаковке до 24 часов. Тем не менее, присутствие вируса не означает, что он может быть заразным. Согласно <a href="https://www.cdc.gov/coronavirus/2019-ncov/faq.html" target="_blank">CDC</a>, вирус вряд ли распространится через почту, продукты или упаковку.</p>
                <p>
                  <ul className="content-list">
                    <li>Мойте руки после открытия ваших посылок и почты</li>
                    <li>Вымойте ножницы и другие загрязненным поверхности</li>
                  </ul>
                </p>
              </div>}
          />
          <h4 className="content-tips-disclaimer">Мы понимаем, что представленные cоветы не могут быть неприменимы ко всем. Ситуация для каждого человека и каждой семьи может быть разная. Мы надеемся, что следующие примеры могут дать вам полезные идеи. Поделись пожалуйста с нами, как вы адаптировали эти советы.</h4>
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Tips;
