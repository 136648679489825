import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Learn extends Component {

  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <h4 className="content-nav-caption">Mục Lục</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-1')}>Vi-rút Corona</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-2')}>COVID-19 lây lan như thế nào?</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-3')}>Triệu Chứng</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-4')}>Cần Phải Làm Gì Nếu Bị Bệnh?</h4>
        </div>
        <div className="content-body">
          <Card 
            id="learn-1"
            icon="/assets/img/icons/info.svg" 
            title="Vi-rút Corona"
            content={
              <div>
                <p>Coronavirus là một loại vi-rút có thể gây các bệnh về đường hô hấp nhẹ, giống như cảm lạnh thông thường.</p>
                <p>Vi-rút SARS-CoV-2 là một loại vi-rút corona chưa từng được phát hiện trước 2019, có thể gây bệnh vi-rút corona 2019 (COVID-19). Trong chữ COVID-19, 'CO' viết tắt của từ 'corona,' 'VI' viết tắt của từ 'vi-rút,' và 'D' là bệnh.</p>
              </div>}
          />
          <Card 
            id="learn-2"
            icon="/assets/img/icons/spread.svg" 
            title="COVID-19 lây lan như thế nào?"
            content={
              <div>
                <p><div className="paragraph-header">Người Sang Người</div>COVID-19 chủ yếu lan truyền qua các giọt từ đường hô hấp của người nhiễm bệnh. Nếu bạn ở trong vòng 6 feet của người bị nhiễm bệnh, những giọt hô hấp khi họ ho, hắt hơi hoặc nói chuyện có thể rơi vào mắt, mũi, hoặc miệng của bạn.</p>
                <p><div className="paragraph-header">Tiếp Xúc Với Bề Mặt Bị Dính Vi-Rút</div>Bạn có thể nhiễm COVID-19 nếu bạn chạm vào bề mặt hoặc đồ vật có vi-rút và sau đó chạm vào mắt, mũi, hoặc miệng. Những bề mặt thường xuyên chạm vào bao gồm tay nắm cửa, công tắc đèn, điện thoại di động, mặt bàn, bàn chữ, cầu tiêu, vòi và bồn rửa.</p>
              </div>}
          />
          <Card 
            id="learn-3"
            icon="/assets/img/icons/symptoms.svg" 
            title="Triệu Chứng"
            content={
              <div>
                <div className="content-symptom-container">
                  <ul className="content-symptom-category">
                    <div className="content-symptom-title">Triệu chứng thường</div>
                    <li className="content-symptom-item">Sốt (100.4°F, 38°C)</li>
                    <li className="content-symptom-item">Ho</li>
                    <li className="content-symptom-item">Hụt hơi</li>
                  </ul>
                  <ul className="content-symptom-category">
                    <div className="content-symptom-title">Triệu chứng khác</div>
                    <li className="content-symptom-item">Mệt mỏi</li>
                    <li className="content-symptom-item">Đau nhức cơ thể</li>
                    <li className="content-symptom-item">Đau cổ họng</li>
                    <li className="content-symptom-item">Sổ mũi</li>
                    <li className="content-symptom-item">Chóng mặt muốn ói</li>
                    <li className="content-symptom-item">Tiêu chảy</li>
                    <li className="content-symptom-item">Mất khứu giác và vị giác</li>
                  </ul>
                </div>
                <div className="content-symptom-caption">Nhiều người bị nhiễm COVID-19 không có triệu chứng.</div>
              </div>}
          />
          <Card 
            id="learn-4"
            icon="/assets/img/icons/sick.svg" 
            title="Cần Phải Làm Gì Nếu Bị Bệnh?"
            content={
              <div>
                <p>Theo <a href="https://vietnamese.cdc.gov/coronavirus/2019-ncov/if-you-are-sick/steps-when-sick.html" target="_blank">CDC</a>, hầu hết mọi người đều bị bệnh nhẹ và có thể hồi phục tại nhà. Nếu bệnh, xin đừng rời khỏi nhà, ngoại trừ khi cần sự chăm sóc y tế. Tìm sự chăm sóc y tế nếu bạn khó thở nhưng hãy gọi điện trước khi đến khám bệnh.</p>
                <hr />
                <p>
                  <ul className="content-list">
                    <div>Gọi 911 nếu bạn gặp trường hợp cấp cứu y tế:</div>
                    <li>Khó thở</li>
                    <li>Đau hoặc tức ngực</li>
                    <li>Trở nên lẫn lộn và không thể thức dậy</li>
                    <li>Mặt môi tím tái</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <ul className="content-list">
                    <div>Để kiếm thêm thông tin về COVID-19, hay để tìm chăm sóc y tế hoặc hỗ trợ cộng đồng, <strong>hãy gọi Sở Y Tế Công cộng</strong> của bạn. <a href="https://covid19.ca.gov/state-local-resources/#top" target="_blank">Nhấn vào đây</a> để kiếm trang web của các sở y tế công cộng của các hạt ở California.</div>
                    <li><a href="https://www.sccgov.org/sites/phd/DiseaseInformation/novel-coronavirus/Pages/home.aspx" target="_blank">Santa Clara County:</a> Gọi 211 để hỏi về COVID-19, tìm chăm sóc y tế hoặc trợ giúp về thực phẩm, nơi ở, bảo hiểm y tế, huấn luyện việc làm, chăm sóc cho người cao niên và giữ trẻ, và nhiều dịch vụ khác nữa (24/7)</li>
                    <li><a href="https://sf.gov/topics/coronavirus-covid-19" target="_blank">San Francisco:</a> Gọi 311 hay 415-701-2311 để hỏi về COVID-19, tìm chăm sóc y tế hoặc các dịch vụ xã hội và hỗ trợ cộng đồng (24/7)</li>
                    <li><a href="http://publichealth.lacounty.gov/media/Coronavirus/" target="_blank">Los Angeles:</a> Gọi 211 để hỏi về COVID-19, tìm chăm sóc y tế hoặc các dịch vụ xã hội và hỗ trợ cộng đồng (24/7)</li>
                    <li><a href="https://covid19info.ocgov.com/" target="_blank">Orange County:</a> Gọi 211  để hỏi về COVID-19, tìm chăm sóc y tế hoặc các dịch vụ xã hội và hỗ trợ cộng đồng (24/7)</li>
                    <li><a href="https://www.sandiegocounty.gov/coronavirus.html" target="_blank">San Diego:</a> Gọi 211 để hỏi về COVID-19, tìm chăm sóc y tế hoặc các dịch vụ xã hội và hỗ trợ cộng đồng (24/7)</li>
                    <li><a href="https://www.co.fresno.ca.us/departments/public-health/covid-19" target="_blank">Fresno:</a> (559) 600-4636 (8a-5p)</li>
                    <li><a href="http://www.acphd.org/2019-ncov.aspx" target="_blank">Alameda:</a> (510) 268-2101</li>
                    <li><a href="https://www.coronavirus.cchealth.org/" target="_blank">Contra Costa:</a> (844) 729-8410 (8a-5p)</li>
                  </ul>
                </p>
                <hr />
                <p>Xin xem trang web <a href="https://vietnamese.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/disinfecting-your-home.html" target="_blank">CDC</a> để biết thêm chi tiết về cách vệ sinh và khử trùng khi ai mắc bệnh</p>
              </div>}
          />
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Learn;
