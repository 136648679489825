import React, { Component } from 'react'
import classnames from 'classnames'
import { Link, withRouter } from 'react-router-dom'

import Button from '../Components/Button'
import ClipboardButton from '../Components/ClipboardButton'

class Aside extends React.PureComponent {

  render() {

    return (
      <div className="content-aside desktop">
        <div className="content-share">
          <h4 className="content-share-caption">ਦੋਸਤਾਂ ਅਤੇ ਪਰਿਵਾਰ ਨਾਲ ਸਾਂਝਾ ਕਰੋ</h4>
          <div className="content-share-options">
            <div className="content-share-item content-share-twitter">
              <img className="fit-height" src="/assets/img/icons/twitter.png" />
              <a className="twitter-share-button" href="https://twitter.com/intent/tweet?text=%E0%A8%95%E0%A9%8B%E0%A8%B0%E0%A9%8B%E0%A8%B5%E0%A8%BF%E0%A8%A1%20-19%20%E0%A8%AB%E0%A9%88%E0%A8%95%E0%A8%9F%20%E0%A8%9A%E0%A9%88%E0%A9%B1%E0%A8%95%20%E0%A8%B5%E0%A9%88%E0%A8%AC%E0%A8%B8%E0%A8%BE%E0%A8%88%E0%A8%9F%20%E0%A8%A4%E0%A9%87%20%E0%A8%95%E0%A9%8B%E0%A8%B0%E0%A9%8B%E0%A8%A8%E0%A8%B5%E0%A8%BE%E0%A8%87%E0%A8%B0%E0%A8%B8%20%E0%A8%AC%E0%A8%BE%E0%A8%B0%E0%A9%87%20%E0%A8%AE%E0%A8%BF%E0%A9%B1%E0%A8%A5%20%E0%A8%85%E0%A8%A4%E0%A9%87%20%E0%A8%A4%E0%A9%B1%E0%A8%A5%20%E0%A8%B8%E0%A8%BF%E0%A9%B1%E0%A8%96%E0%A9%8BI&tw_p=tweetbutton&url=http%3A%2F%2Fwww.covid19factcheck.com%2Fpa%2F" target="_blank">Tweet</a>
            </div>
            <div className="content-share-item content-share-facebook">
              <img className="fit-height" src="/assets/img/icons/facebook.png" />
              <a className="fb-share-button" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=http://www.covid19factcheck.com/pa/"></a>
            </div>
            <div className="content-share-item content-share-email">
              <img className="fit-height" src="/assets/img/icons/email.svg" />
              <a href="mailto:?body=%E0%A8%95%E0%A9%8B%E0%A8%B0%E0%A9%8B%E0%A8%B5%E0%A8%BF%E0%A8%A1%20-19%20%E0%A8%AB%E0%A9%88%E0%A8%95%E0%A8%9F%20%E0%A8%9A%E0%A9%88%E0%A9%B1%E0%A8%95%20%E0%A8%B5%E0%A9%88%E0%A8%AC%E0%A8%B8%E0%A8%BE%E0%A8%88%E0%A8%9F%20%E0%A8%A4%E0%A9%87%20%E0%A8%95%E0%A9%8B%E0%A8%B0%E0%A9%8B%E0%A8%A8%E0%A8%B5%E0%A8%BE%E0%A8%87%E0%A8%B0%E0%A8%B8%20%E0%A8%AC%E0%A8%BE%E0%A8%B0%E0%A9%87%20%E0%A8%AE%E0%A8%BF%E0%A9%B1%E0%A8%A5%20%E0%A8%85%E0%A8%A4%E0%A9%87%20%E0%A8%A4%E0%A9%B1%E0%A8%A5%20%E0%A8%B8%E0%A8%BF%E0%A9%B1%E0%A8%96%E0%A9%8BI%20http%3A%2F%2Fwww.covid19factcheck.com%2Fpa%2F"></a>
            </div>
            <ClipboardButton className="content-share-item content-share-link" value="http://www.covid19factcheck.com/pa/" success="ਲਿੰਕ ਕਾਪੀ ਹੋ ਗਿਆ ਹੈ! 🎉"><img className="fit-height" src="/assets/img/icons/link.svg" /></ClipboardButton>
          </div>
        </div>
        <h5 className="content-footnote">ਜ਼ਿਆਦਾਤਰ ਜਾਣਕਾਰੀ ਡਬਲਯੂਐਚਓ <a href="https://www.who.int/" target="_blank">(WHO)</a> ਅਤੇ ਸੀਡੀਸੀ <a href="https://www.cdc.gov/" target="_blank">(CDC)</a> ਤੋਂ ਪ੍ਰਾਪਤ ਕੀਤੀ ਗਈ ਹੈ<br /> ਆਖਰੀ ਵਾਰ ਮਈ 18, 2020 ਨੂੰ ਅਪਡੇਟ ਕੀਤੀ ਗਈ</h5>
      </div>
    )
  }
}

export default withRouter(Aside)