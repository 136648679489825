import React, { Component } from 'react'
import classnames from 'classnames'
import { Link, withRouter } from 'react-router-dom'

import Button from '../Components/Button'
import ClipboardButton from '../Components/ClipboardButton'

class Aside extends React.PureComponent {

  render() {

    return (
      <div className="content-aside desktop">
        <div className="content-share">
          <h4 className="content-share-caption">Chia sẻ thông tin với gia đình bạn bè</h4>
          <div className="content-share-options">
            <div className="content-share-item content-share-twitter">
              <img className="fit-height" src="/assets/img/icons/twitter.png" />
              <a className="twitter-share-button" href="https://twitter.com/intent/tweet?text=Xem%20trang%20COVID-19%20Fact%20Check%20%C4%91%E1%BB%83%20ph%C3%A2n%20bi%E1%BB%87t%20tin%20th%E1%BA%ADt%20v%C3%A0%20tin%20gi%E1%BA%A3%20v%E1%BB%81%20coronavirus&tw_p=tweetbutton&url=http%3A%2F%2Fwww.covid19factcheck.com%2Fvn%2F" target="_blank">Tweet</a>
            </div>
            <div className="content-share-item content-share-facebook">
              <img className="fit-height" src="/assets/img/icons/facebook.png" />
              <a className="fb-share-button" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=http://www.covid19factcheck.com/vn/"></a>
            </div>
            <div className="content-share-item content-share-email">
              <img className="fit-height" src="/assets/img/icons/email.svg" />
              <a href="mailto:?body=Xem%20trang%20COVID-19%20Fact%20Check%20%C4%91%E1%BB%83%20ph%C3%A2n%20bi%E1%BB%87t%20tin%20th%E1%BA%ADt%20v%C3%A0%20tin%20gi%E1%BA%A3%20v%E1%BB%81%20coronavirus.%20http%3A%2F%2Fwww.covid19factcheck.com%2Fvn%2F"></a>
            </div>
            <ClipboardButton className="content-share-item content-share-link" value="http://www.covid19factcheck.com/vn/" success="Liên kết đã được sao chép! 🎉">
              <img className="fit-height" src="/assets/img/icons/link.svg" />
            </ClipboardButton>
          </div>
        </div>
        <h5 className="content-footnote">Nguồn gốc của đa số thông tin từ <a href="https://www.cdc.gov/" target="_blank">CDC</a> & <a href="https://www.who.int/" target="_blank">WHO</a>.<br /> Lần cuối xem xét trang này 05/18/20.</h5>
      </div>
    )
  }
}

export default withRouter(Aside)