import React, { Component } from 'react'
import classnames from 'classnames'

import Button from '../Components/Button'

class About extends Component {

  render() {

    return (
      <div className="about">
        <div className="about-header grid">
          <h1 className="about-title">Luchemos juntos contra la pandemia con el conocimiento.</h1>
          <p className="about-description">Somos un grupo de estudiantes de medicina de la Universidad de California en San Francisco (UCSF) que se asoció con un ingeniero y diseñador de web para crear una fuente de información verídica sobre el coronavirus.</p>
          <p className="about-description">La información incorrecta puede crear miedo, ansiedad y hasta causar daño. La información disponible al público puede ser difícil de comprender debido al nivel de complejidad, además de que no está disponible en múltiples idiomas. </p>
          <p className="about-description">Nuestro objetivo es desarrollar una fuente de información confiable y fácil de entender sobre el coronavirus en varios idiomas con la intención de apoyar nuestra lucha contra la pandemia.</p>
          <div className="about-disclaimer">
            <h3 className="about-disclaimer-caption">Exoneración de Responsabilidad</h3>
            <p className="about-disclaimer-content">No somos expertos sobre el COVID-19. Pero como somos estudiantes de medicina, tenemos experiencia en el conocimiento médico y la interpretación de investigaciones. Hemos seleccionado esta información con cuidado, usando fuentes verídicas que incluyen estudios científicos, para así compartirla con el público general.</p>
            <p className="about-disclaimer-content">Los pensamientos que hemos compartido en esta página web son nuestros y no representan la opinión de nuestra institución. Agradecemos a los doctores que han revisado y contribuido a este material. Gracias a: Dr. MeLee Lee, Dr. Changsoo Park.</p>
          </div>
          <div className="about-action desktop">
            <a href="mailto:contact@covid19factcheck.com" target="_blank"><div className="btn btn-primary about-action-btn">Contacto</div></a>
            <h4 className="about-action-caption">Envíenos un correo electrónico a contact@covid19factcheck.com</h4>
          </div>
        </div>
        <div className="about-team">
          <h1 className="about-team-title">Equipo</h1>
          <div className="about-team-row grid">
            <div className="about-team-section">
              <h3 className="about-team-section-header">Desarrollo de Contenido</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Hallen Pham</div>
                <h4 className="about-team-member-title">Estudiante de Medicina de 4º año (MS4) de la Escuela de Medicina de UCSF</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Fiona Ng</div>
                <h4 className="about-team-member-title">MS4 de la Escuela de Medicina de UCSF</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Anuvir Singh</div>
                <h4 className="about-team-member-title">MS4 de la Escuela de Medicina de UCSF</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Diseñador de Web e Ingeniero</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Shawn Park</div>
                <h4 className="about-team-member-title">Diseñador Senior de Producto en Discord</h4>
                <h4 className="about-team-member-link">
                  <a href="https://designbyroka.com/" target="_blank">Website</a>
                  <a href="https://www.linkedin.com/in/shawn-park-9033a251/" target="_blank">Linkedin</a>
                </h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Traducción Amárico</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Beza Dagne</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Yetinayet Dagne</div>
                <h4 className="about-team-member-title">RN BSN, 2nd year Family Nurse Practitioner Student, SJSU</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Traducciones en Árabe</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Tessnim Ahmad, MD</div>
                <h4 className="about-team-member-title">UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Sarah Semaan</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Amer Alsoudi</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
          </div>
          <div className="about-team-row grid">
            <div className="about-team-section">
              <h3 className="about-team-section-header">Traducciones en Chino</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Billy Zeng</div>
                <h4 className="about-team-member-title">MS4 de la Escuela de Medicina de UCSF</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Fiona Ng</div>
                <h4 className="about-team-member-title">MS4 de la Escuela de Medicina de UCSF</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Janette Tang</div>
                <h4 className="about-team-member-title">MS1 de la Escuela de Medicina de UCSF</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Jackie Lin</div>
                <h4 className="about-team-member-title">MS1 de la Escuela de Medicina de UCSF</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Alex Ge</div>
                <h4 className="about-team-member-title">MS1 de la Escuela de Medicina de UCSF</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Traducciones en Coreano</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Eric Kim</div>
                <h4 className="about-team-member-title">MS3 de la Escuela de Medicina de UCSF</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">James Lim</div>
                <h4 className="about-team-member-title">MS3 de la Escuela de Medicina de UCSF</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Eliana Kim</div>
                <h4 className="about-team-member-title">MS1 de la Escuela de Medicina de UCSF</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Sally Oh</div>
                <h4 className="about-team-member-title">MS1 de la Escuela de Medicina de UCSF</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Traducciones en Punjabi</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Anuvir Singh</div>
                <h4 className="about-team-member-title">MS4 de la Escuela de Medicina de UCSF</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Prabhjot Kaur</div>
                <h4 className="about-team-member-title">MS1 de la Escuela de Medicina de UCSF</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Traducción Rusa</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Liza Leykina</div>
                <h4 className="about-team-member-title">3rd year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Katerina Protsenko</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Fima Zaltsman, PhD</div>
                <h4 className="about-team-member-title">Postdoctoral Scholar, UCSF</h4>
              </div>
            </div>
          </div>
          <div className="about-team-row grid">
            <div className="about-team-section">
              <h3 className="about-team-section-header">Traducciones en Español</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Alexis Velázquez</div>
                <h4 className="about-team-member-title">MS4 de la Escuela de Medicina de UCSF</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Jessica Valdez</div>
                <h4 className="about-team-member-title">MS4 de la Escuela de Medicina de UCSF</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Nicole Rodríguez</div>
                <h4 className="about-team-member-title">MS4 de la Escuela de Medicina de UCSF</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Andrés Calvillo</div>
                <h4 className="about-team-member-title">MS3 de la Escuela de Medicina de UCSF</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Dani Montejano</div>
                <h4 className="about-team-member-title">MS3 de la Escuela de Medicina de UCSF</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Sandra Perez</div>
                <h4 className="about-team-member-title">MS3 de la Escuela de Medicina de UCSF</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Ivan Iñiguez</div>
                <h4 className="about-team-member-title">MS1 de la Escuela de Medicina de UCSF</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Traducción Tagalo</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Joel Jose Quitlong Nario</div>
                <h4 className="about-team-member-title">3rd year Medical Student, Weill Cornell Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Francine Castillo</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Traducciones en Tongan</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Ha’ano Fonua</div>
                <h4 className="about-team-member-title">5th year Medical Student, University of Auckland, NZ</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Traducciones en Tok Pisin</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Barbara Angoro</div>
                <h4 className="about-team-member-title">PhD Student, School of Pharmacy, University of Auckland</h4>
              </div>
            </div>
          </div>
          <div className="about-team-row grid">
            <div className="about-team-section">
              <h3 className="about-team-section-header">Traducciones en Vietnamita</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Hallen Pham</div>
                <h4 className="about-team-member-title">4th year Medical Student, UCSF School of Medicine</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Kevin Pham</div>
                <h4 className="about-team-member-title">Molecular, Cell, and Developmental Biology, UCLA</h4>
              </div>
              <div className="about-team-member">
                <div className="about-team-member-name">Kristine Au</div>
                <h4 className="about-team-member-title">De Anza College</h4>
              </div>
            </div>
            <div className="about-team-section">
              <h3 className="about-team-section-header">Arte</h3>
              <div className="about-team-member">
                <div className="about-team-member-name">Tammy Nguyen</div>
                <h4 className="about-team-member-link">
                  <a href="http://tamsanity.com/" target="_blank">Portfolio</a>
                  <a href="https://www.instagram.com/tamsanity/?hl=en" target="_blank">Instagram</a>
                  <a href="https://twitter.com/tamsanity_" target="_blank">Twitter</a>
                </h4>
              </div>
            </div>
          </div>
          <h4 className="about-team-thanks">Agradecemos a la “Asian Pacific American Medical Students Association” (APAMSA) de UCSF, al Equipo de Ayuda Estudiantil COVID-19 de UCSF y a Paul Park (MS4) por ayudarnos en la reclutación del equipo de traducción.</h4>
        </div>
        <div className="about-action mobile">
          <a href="mailto:contact@covid19factcheck.com" target="_blank"><div className="btn btn-primary about-action-btn">Contacto</div></a>
          <h4 className="about-action-caption">Envíenos un correo electrónico a contact@covid19factcheck.com</h4>
        </div>
      </div>
    )
  }
}

export default About