import React, { Component } from 'react'
import classnames from 'classnames'
import { Link, withRouter } from 'react-router-dom'

import Button from '../Components/Button'
import ClipboardButton from '../Components/ClipboardButton'

class Aside extends React.PureComponent {

  render() {

    return (
      <div className="content-aside desktop">
        <div className="content-share">
          <h4 className="content-share-caption">Vahevahe mo ho kaungāme’a´ pehē ki ho fāmili´</h4>
          <div className="content-share-options">
            <div className="content-share-item content-share-twitter">
              <img className="fit-height" src="/assets/img/icons/twitter.png" />
              <a className="twitter-share-button" href="https://twitter.com/intent/tweet?text=Mou%20me%E2%80%99a%20mai%20%E2%80%98o%20vakai%20ki%20he%20peesi%20%E2%80%9CCOVID-19%20Fact%20Check%E2%80%9D%20ka%20mou%20ki%20he%20mo%E2%80%99oni%20mo%20e%20loi%C2%B4%20fekau%E2%80%99aki%20mo%20e%20ngaahi%20ongoongo%20%E2%80%98o%20e%20kolonavailasi%C2%B4.&tw_p=tweetbutton&url=http%3A%2F%2Fcovid19factcheck.com%2Fto" target="_blank">Tweet</a>
            </div>
            <div className="content-share-item content-share-facebook">
              <img className="fit-height" src="/assets/img/icons/facebook.png" />
              <a className="fb-share-button" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=http://covid19factcheck.com/to/"></a>
            </div>
            <div className="content-share-item content-share-email">
              <img className="fit-height" src="/assets/img/icons/email.svg" />
              <a href="mailto:?body=Mou%20me%E2%80%99a%20mai%20%E2%80%98o%20vakai%20ki%20he%20peesi%20%E2%80%9CCOVID-19%20Fact%20Check%E2%80%9D%20ka%20mou%20ki%20he%20mo%E2%80%99oni%20mo%20e%20loi%C2%B4%20fekau%E2%80%99aki%20mo%20e%20ngaahi%20ongoongo%20%E2%80%98o%20e%20kolonavailasi%C2%B4.%20http%3A%2F%2Fcovid19factcheck.com%2Fto"></a>
            </div>
            <ClipboardButton className="content-share-item content-share-link" value="http://covid19factcheck.com/to/" success="Ko e tu’asila´ ni kuo hiki tatau! 🎉"><img className="fit-height" src="/assets/img/icons/link.svg" /></ClipboardButton>
          </div>
        </div>
        <h5 className="content-footnote">Ko e lahi taha ‘i he ngaahi fakamatala´ ne fakamā’opo’opo mei he <a href="https://www.who.int/" target="_blank">WHO</a> & <a href="https://www.cdc.gov/" target="_blank">CDC</a>.<br /> Ko e fakafo’ou fakamuimui taha´ ‘i he ‘aho 18/07/2020.</h5>
      </div>
    )
  }
}

export default withRouter(Aside)