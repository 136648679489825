import React, { Component } from 'react'
import classnames from 'classnames'

import Button from '../Components/Button'
import ClipboardButton from '../Components/ClipboardButton'

class ShareModal extends Component {

  componentDidMount() {
    window.Kakao.init('8c22c0e3b6141547b03badeeb6f137cd');
  }

  sendKakao = (e) => {
    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: 'COVID-19 Fact Check',
        description: 'Проверьте страницу COVID-19 Fact Check, чтобы узнать мифы и факты о коронавирусе.',
        imageUrl:
          'http://www.covid19factcheck.com/assets/img/thumbnails/social_square.png',
        link: {
          mobileWebUrl: 'http://www.covid19factcheck.com/ru/',
          androidExecParams: 'test',
        },
      },
      success: function(response) {
        console.log(response);
      },
      fail: function(error) {
        console.log(error);
      }
    });
  }

  render() {
    const { active, toggleShareModalActive } = this.props;

    return (
      <div className={classnames('modal', 'share-modal', {'modal-active': active})}>
        <div className="modal-close" onClick={toggleShareModalActive}><img className="fit-parent" src="/assets/img/icons/close.svg" /></div>
        <div className="modal-content">
          <h1 className="modal-title">Распространите информацию.</h1>
          <p className="modal-caption">Поделитесь COVID-19 Fact Check с вашими друзьями и семьей, чтобы все знали как лучше предохраняться от коронавируса</p>
          <div className="share-modal-action-container">
            <div className="share-modal-action">
              <a href="https://twitter.com/intent/tweet?text=%D0%9F%D1%80%D0%BE%D0%B2%D0%B5%D1%80%D1%8C%D1%82%D0%B5%20%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B8%D1%86%D1%83%20COVID-19%20Fact%20Check%2C%20%D1%87%D1%82%D0%BE%D0%B1%D1%8B%20%D1%83%D0%B7%D0%BD%D0%B0%D1%82%D1%8C%20%D0%BC%D0%B8%D1%84%D1%8B%20%D0%B8%20%D1%84%D0%B0%D0%BA%D1%82%D1%8B%20%D0%BE%20%D0%BA%D0%BE%D1%80%D0%BE%D0%BD%D0%B0%D0%B2%D0%B8%D1%80%D1%83%D1%81%D0%B5&tw_p=tweetbutton&url=http%3A%2F%2Fcovid19factcheck.com%2Fru%2F" target="_blank">
                <img className="fit-parent" src="/assets/img/icons/share/twitter.png" />
              </a>
            </div>
            <div className="share-modal-action">
              <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=http://covid19factcheck.com/ru/" target="_blank">
                <img className="fit-parent" src="/assets/img/icons/share/facebook.png" />
              </a>
            </div>
            <div className="share-modal-action">
              <a href="https://wa.me/?text=%D0%9F%D1%80%D0%BE%D0%B2%D0%B5%D1%80%D1%8C%D1%82%D0%B5%20%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B8%D1%86%D1%83%20COVID-19%20Fact%20Check%2C%20%D1%87%D1%82%D0%BE%D0%B1%D1%8B%20%D1%83%D0%B7%D0%BD%D0%B0%D1%82%D1%8C%20%D0%BC%D0%B8%D1%84%D1%8B%20%D0%B8%20%D1%84%D0%B0%D0%BA%D1%82%D1%8B%20%D0%BE%20%D0%BA%D0%BE%D1%80%D0%BE%D0%BD%D0%B0%D0%B2%D0%B8%D1%80%D1%83%D1%81%D0%B5%20http%3A%2F%2Fcovid19factcheck.com%2Fru%2F" data-action="share/whatsapp/share" target="_blank">
                <img className="fit-parent" src="/assets/img/icons/share/whatsapp.png" />
              </a>
            </div>
            <div className="share-modal-action">
              <a onClick={this.sendKakao}>
                <img className="fit-parent" src="/assets/img/icons/share/kakaotalk.png" />
              </a>
            </div>
            <div className="share-modal-action">
                <a href="https://social-plugins.line.me/lineit/share?url=http%3A%2F%2Fcovid19factcheck.com%2Fru%2F" target="_blank">
                  <img className="fit-parent" src="/assets/img/icons/share/line.png" />
                </a>
            </div>
            <div className="share-modal-action">
              <a href="mailto:?body=%D0%9F%D1%80%D0%BE%D0%B2%D0%B5%D1%80%D1%8C%D1%82%D0%B5%20%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B8%D1%86%D1%83%20COVID-19%20Fact%20Check%2C%20%D1%87%D1%82%D0%BE%D0%B1%D1%8B%20%D1%83%D0%B7%D0%BD%D0%B0%D1%82%D1%8C%20%D0%BC%D0%B8%D1%84%D1%8B%20%D0%B8%20%D1%84%D0%B0%D0%BA%D1%82%D1%8B%20%D0%BE%20%D0%BA%D0%BE%D1%80%D0%BE%D0%BD%D0%B0%D0%B2%D0%B8%D1%80%D1%83%D1%81%D0%B5%20http%3A%2F%2Fcovid19factcheck.com%2Fru%2F">
                <img className="fit-parent" src="/assets/img/icons/share/mail.png" />
              </a>
            </div>
          </div>
          <ClipboardButton value="http://covid19factcheck.com" success="Ссылка была скопирована! 🎉">
            <Button className="btn-share" label="Скопировать Поделиться Ссылка" imgURL="/assets/img/icons/link.svg" />
          </ClipboardButton>
        </div>
      </div>
    )
  }
}

export default ShareModal