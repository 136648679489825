import React, { Component } from 'react'
import classnames from 'classnames'
import {
  BrowserRouter as Router,
  Link,
  Route,
  useLocation,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom'

import Button from '../Components/Button'

class Header extends React.PureComponent {

  render() {

    const { toggleLanguageModalActive, toggleShareModalActive, path } = this.props;

    return (
      <div className="header-container">
        <div className="header grid">
          <h1 className="header-title">COVID-19<br />Fact Check</h1>
          <div className="header-description">ਕੋਰੋਨਾਵਾਇਰਸ ਬਾਰੇ ਤੱਥਾਂ ਅਤੇ ਮਿੱਥਾਂ ਨੂੰ ਜਾਣੋ ਤਾਂ ਜੋ ਤੁਸੀਂ ਬਿਹਤਰ ਤਿਆਰੀ ਕਰ ਸਕੋ ਅਤੇ ਦੂਜਿਆਂ ਨੂੰ ਸੂਚਿਤ ਰੱਖ ਸਕੋI</div>
          <div className="header-action mobile">
            <Button className="btn-secondary" label="ਪੰਜਾਬੀ" imgURL="/assets/img/icons/arrow_down.svg" onClick={toggleLanguageModalActive} />
            <Button className="btn-primary" label="ਸ਼ੇਅਰ ਕਰੋ" imgURL="/assets/img/icons/share.svg" onClick={toggleShareModalActive} />
          </div>
          <div className="header-caption mobile">
            <h5 className="header-caption-item">ਜ਼ਿਆਦਾਤਰ ਜਾਣਕਾਰੀ ਡਬਲਯੂਐਚਓ <a href="https://www.who.int/" target="_blank">(WHO)</a> ਅਤੇ ਸੀਡੀਸੀ <a href="https://www.cdc.gov/" target="_blank">(CDC)</a> ਤੋਂ ਪ੍ਰਾਪਤ ਕੀਤੀ ਗਈ ਹੈ</h5>
            <h5 className="header-caption-item">ਆਖਰੀ ਵਾਰ ਮਈ 18, 2020 ਨੂੰ ਅਪਡੇਟ ਕੀਤੀ ਗਈ</h5>
          </div>
        </div>
        <div className="nav">
          <a href="/pa/" className={classnames('nav-item', {'nav-item-active': path === ''})}>ਮਿੱਥ ਬਸਟਰ</a>
          <a href="/pa/tips" className={classnames('nav-item', {'nav-item-active': path === 'tips'})}>ਮਦਦਗਾਰ ਸੁਝਾਅ</a>
          <a href="/pa/learn" className={classnames('nav-item', {'nav-item-active': path === 'learn'})}>ਕੋਵਿਡ-19 ਬਾਰੇ ਜਾਣੋ</a>
          <a href="/pa/about" className={classnames('nav-item desktop', {'nav-item-active': path === 'about'})}>ਸਾਡੇ ਬਾਰੇ</a>
          <a href="mailto:contact@covid19factcheck.com" target="_blank" className="nav-item desktop">ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ</a>
        </div>
      </div>
    )
  }
}

export default withRouter(Header)