import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Myth extends Component {

  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <div className="content-nav-scroller">
            <h4 className="content-nav-caption">Olgeta sekesn we i stap insaed</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-1')}>I nid blong mi stap 2 mita aot long narafala man taem mi werem mask?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-10')}>Sipos yu putum blij mo olgeta narafala kaen kemikol olsem long bodi blong yu, bae yu save stopem COVID-19 ia?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-2')}>I nid blong man i pem mask we olgeta dokta i stap yusum o N95 respireta?</h4>
            {/* <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-3')}>Can coronavirus spread far distances through the air?</h4> */}
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-4')}>Sipos mi werem hanglav bambae hemi save protektem mi 100% long koronavaeres?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-5')}>Koronavaeres i save stap long wan seves blong plante dei?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-6')}>Bambae mi shud wasem han oltaem o waepem olgeta seves nomata sipos mi no livim haos?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-7')}>Ibuprofen i mekem man we i kasem COVID-19 i sik moa wos?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-8')}>Sipos man i stap tekem hem wan olgeta meresin olsem hydroxychloroquine & azithromycin bambae i save stopem COVID-19 ia?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-9')}>Olgeta antibaeotik oli gud blong stopem mo tritim COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-11')}>Sipos mi yang mo helti, bae mi no save kasem koronavaeres?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-12')}>Bambae mi mas go stap long hospitol sipos mi kasem COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-13')}>Mi save holem taet win blong mi blong 10 seken o moa mo mi no kof o filim i nogud, blong jekem se mi kasem COVID-19 o no?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-14')}>Olgeta mashin blong draemap han mo olgeta UV laet oli save kilim koronavaeres?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-15')}>Sipos yu stap rinsim nus blong yu wetem saline bae i stopem COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-16')}>Swim long wota we i hot tumas o bath i save stopem COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-17')}>Kol wota mo sno i stopem COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-18')}>Hemi gud blong kakae galik blong stopem COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-19')}>Hemi gud blong drink alkohol blong stopem COVID-19?</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-20')}>Olgeta stik meresin blong lang problem oli save protektem yu agensem COVID-19?</h4>
          </div>
        </div>
        <div className="content-body">
          <Card 
            id="myth-1"
            thumbnail="/assets/img/shots/myth/myth-1.jpg" 
            title="I nid blong mi stap 2 mita aot long narafala man taem mi werem mask?"
            content={
              <div>
                <p><span className="card-tag-true">Yes</span>Kontinu blong stap 2 mita aot long olgeta narafala man; wasen han oltaem, mekem olsem tu taem yu putum mo karemaot mask.</p>
                <p>Long Amerika, <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover-faq.html" target="_blank">CDC</a> i rekomendem blong man i yusum olgeta mask we oli mekem long kaliko blong help blong stopem spred blong vaeres, from se kaliko i holem taet olgeta droplet taem man i sniz, kof, o toktok. Mask i save givim sam proteksen be i no agensem evri patikol blong vaeres, hemia i dipen nomo long materiol blong kaliko blong mask mo sipos mask i fitim gud yu. Plis luk long “Infomesen blong helpem yu” peij blong mifala.</p>
              </div>}
            extra={
              <div>
                <p>Plis folem olgeta rekomendesen blong <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a> mo yusum olgeta materiol blong haos we oli sef mo mekem sua mask i fitim gud fes we yu save brit tru long hem yet. CDC i rekomendem se mask i mas tik mo hemi no rekomendem wanem kaen materiol blong kaliko nao blong yusum.</p>
                <p><div className="paragraph-header">Olsem wanem nao olgeta mask we oli mekem long kaliko oli wok gud agensem koronavaeres?</div>Long saed blong saens, i nogat wan ansa long kwesten ia. Long wan <a href="https://www.ncbi.nlm.nih.gov/pubmed/24229526" target="_blank">laboratory experiment</a> oli yusum wan difren vaeres, mo oli faenemaot se i nogat wan materiol blong kaliko  (olsem, 100% koton, shet, skaf, silk, pilokes, bedsit, koton miks, ti tawel) oli no save blokem 100% blong patikol blong vaeres from se saes blong hem i smol tumas. Be, eksperimen ia oli no mekem long wan nomol kof blong man. Hemi no lukluk tu long olsem wanem mask i fitim gud man. From se olgeta mask ia oli nogat wan ples blong man i save taetem, olgeta patikol blong vaeres oli save kamaot tru long <a href="https://www.gannett-cdn.com/experiments/usatoday/gifs/tool/919-surgical.png" target="_blank">saedsaed blong mask</a></p>
                <p><div className="paragraph-header">Olgeta mein poen</div>Olgeta mask we oli mekem long kaliko oli save help blong stopem man blong kasem vaeres be bae oli no provaedem 100% proteksen</p>
              </div>}
          />
          <Card 
            id="myth-10"
            thumbnail="/assets/img/shots/myth/myth-10.jpg" 
            title="Sipos yu putum blij mo olgeta narafala kaen kemikol olsem long bodi blong yu, bae yu save stopem COVID-19 ia?"
            content={
              <div>
                <p><span className="card-tag-false">No</span><strong>Plis NO MAS</strong> <a href="https://www.rb.com/media/news/2020/april/improper-use-of-disinfectants" target="_blank">injektem</a>, britim, drink o spre sanitaeza (alkohol) o chlorine (blij) ova long bodi blong yu from se hemi <strong className="red">denjares.</strong></p>
                <p>Folem toktok blong <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>,  “olgeta kemikol ia oli poisen mo oli save kilim yu o damejem skin mo ae blong yu sapos yu drink o putum long bodi blong yu” Yusum blij mo olgeta narafala kemikol olsem nomo long olgeta seves we <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cleaning-disinfection.html" target="_blank">oli rekomendem.</a> Rimemba blong no letem olgeta pikinini i tajem.</p>
              </div>}
          />
          <Card 
            id="myth-2"
            thumbnail="/assets/img/shots/myth/myth-2.jpg" 
            title="I nid blong man i pem mask we olgeta dokta i stap yusum o N95 respireta?"
            content={
              <div>
                <p><span className="card-tag-false">No</span><a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a> i rekomendem nomo <strong>mask we oli mekem long kaliko nomo</strong> blong pablik blong Amerika oli yusum.</p>
                <p>Yu save werem eni mask we yu gat long haos be i no nid blong yu wantem go pem mask we dokta i stap yusum o N95. From se saplae blong olgeta medikol mask ia i shot long Amerika, bambae olgeta helt kea woka nomo oli yusum from olgeta oli tren blong save yusum gud mo from se olgeta oli gat daerek kontak wetem olgeta sikman.</p>
              </div>}
            extra={
              <div>
                <p>I gat wan spesel filta we i stap long <a href="https://www.fda.gov/medical-devices/personal-protective-equipment-infection-control/n95-respirators-and-surgical-masks-face-masks" target="_blank">N95 respirator</a> we i save blokem 95 pesen blong olgeta smolsmol (0.3 micron) patikol <strong>sipos</strong> i gat wan ples we ea i go insaed mo aotsaed long hem. Antap long trening we olgeta helt woka oli stap tekem, olgeta oli stap gat olgeta <a href="https://www.safetyalliance.org/wp-content/uploads/2019/06/fit-test-2-000110_retouched-672x372.jpg" target="_blank">fit-testing</a> evri yia blong mekem sua olgeta mask ia oli stret saes blong save alaoem ea i go insaed mo aotsaed long hem. Taem yu werem gud bambae yu no save filim gud taem yu brit insaed long hem. Sipos i gat mustaj long fes blong yu bae hemia i mekem tu se mask bae i no save kavremap gud fes blong yu. Blong <a href="https://blogs.cdc.gov/niosh-science-blog/2018/01/04/respirators-public-use/" target="_blank">jenerol pablik</a>, from se yumi nogat treining mo fit testing bambae N95 ia  i no save protektem gud yumi long wei we hemi sapos blong mekem.</p>
              </div>}
          />
          {/*
          <Card 
            id="myth-3"
            thumbnail="/assets/img/shots/myth/myth-3.jpg" 
            title="Can coronavirus spread far distances through the air?"
            content={
              <div>
                <p><span className="card-tag-maybe">Ating</span>Airborne transmission is unlikely for the general public.</p>
                <p>According to <a href="https://www.who.int/news-room/q-a-detail/q-a-coronaviruses" target="_blank">WHO</a>, “COVID-19 is mainly transmitted through droplets generated when an infected person coughs, sneezes, or speaks. These droplets are too heavy to hang in the air. They quickly fall on floors or surfaces.” No airborne transmission was reported among an analysis of over 75,000 COVID-19 cases in China. However, health care workers may be at risk. WHO reports airborne transmission may be possible during <a href="https://www.who.int/news-room/commentaries/detail/modes-of-transmission-of-virus-causing-covid-19-implications-for-ipc-precaution-recommendations" target="_blank">hospital procedures that generate aerosols</a> such as intubation, where a breathing tube is inserted into the patient’s airway to help them breathe.</p>
                </div>}
            extra={
              <div>
                <p><div className="paragraph-header">What does airborne transmission mean?</div>In contrast to respiratory droplets, airborne particles are smaller and remain in the air for long periods of time where they can spread farther than 6 feet.</p>
                <p><div className="paragraph-header">Why does the news say coronavirus is airborne?</div>A NEJM <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973" target="_blank">study</a> artificially created aerosols of the coronavirus in a rotating cylinder, where it remained viable for 3 hours. However, the high-powered machine does not represent a normal human cough. In a non peer-reviewed <a href="https://www.medrxiv.org/content/10.1101/2020.03.23.20039446v2" target="_blank">study</a> (has not been evaluated by other experts), researchers sampled for the presence of coronavirus in the air and surfaces of COVID-19 patients’ hospital room. 76.5% of all personal belongings were positive (cell phones, toilets, remotes, bed rails, etc.). For air samples, 63.2% were positive and some samples were collected greater than 6 feet away from the patient. However, the presence of a virus does not automatically mean it can cause disease. For example, the amount of virus may be too low to cause infection. The authors have not proven that the components of the virus that they have detected can cause infections.</p>
              </div>}
          /> */}
          <Card 
            id="myth-4"
            thumbnail="/assets/img/shots/myth/myth-4.jpg" 
            title="Sipos mi werem hanglav bambae hemi save protektem mi 100% long koronavaeres?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>Bambae hanglav i help nomo sipos yu kipim oltaem proteksen we oli talem. <strong>NO MAS</strong> tajem fes blong yu wetem hanglav long han blong yu yet. Mekem sua yu wasem han bifo yu werem hanglav mo <strong>afta we yu karemaot.</strong> Man i save kasem vaeres olsem, we hanglav yu putum i save spredem bebet long wan seves i go long narafala wan.</p>
              </div>}
          />
          <Card 
            id="myth-5"
            thumbnail="/assets/img/shots/myth/myth-5.jpg" 
            title="Koronavaeres i save stap long wan seves blong plante dei?"
            content={
              <div>
                <p><span className="card-tag-true">Yes</span>Wan NEJM <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973?query=featured_home" target="_blank">studi</a> i soemaot se koronavaeres we i stap long wan katen i save stap blong 24 haoa, mo long plastik mo ayan i save stap blong 72 haoa, hemi folem eksperimen we oli mekem long wan laboretri.</p>
                <p>Hemi impoten blong tekem not se amaon blong vaeres we i stap bihaen hemi 0.1% long materiol we vaeres i bin stap long hem festaem. Vaeres we i stap i no minim se otomatik nomo olsem bambae yu kasem.</p>
              </div>}
          />
          <Card 
            id="myth-6"
            thumbnail="/assets/img/shots/myth/myth-6.jpg" 
            title="Bambae mi shud wasem han oltaem o waepem olgeta seves nomata sipos mi no livim haos?"
            content={
              <div>
                <p><span className="card-tag-true">Yes</span><a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/prevention.html" target="_blank">CDC</a> i rekomendem se man i mas klinim mo waepem  olgeta seves we man i stap tajem evri dei olsem handel blong doa, switj blong laet, tebol, telefon, kibod, toilet, tap, mo sink.</p>
                <p>Bambae yu o wan famili memba blong yu i kasem sik ia we bambae yutufala i no save se yutufala i kasem. Nomata we bambae yu save kasem sik ia sapos yu tajem o stap kolosap long wan man we i kasem sik ia, bitim taem yu tajem wan seves we i gat vaeres long hem, oltaem yu mas praktisim proteksen we oli stap talem.</p>
              </div>}
          />
          <Card 
            id="myth-7"
            thumbnail="/assets/img/shots/myth/myth-7.jpg" 
            title="Ibuprofen i mekem man we i kasem COVID-19 i sik moa wos?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>I nogat eni pruf long saed blong saens we i soemaot se ibuprofen (nem blong brand Advil Motrin) i mekem man we i kasem COVID-19 i sik moa wos.</p>
                <p><a href="https://twitter.com/WHO/status/1240409217997189128" target="_blank">WHO</a> i no agensem yus blong ibuprofen (Maj namba 18 2020). Acetaminophen (Tylenol) hemi wan narafala meresin we i rilivim fiva mo taem bodi o pat blong bodi i so. <a href="https://www.fda.gov/drugs/drug-safety-and-availability/fda-advises-patients-use-non-steroidal-anti-inflammatory-drugs-nsaids-covid-19" target="_blank">FDA</a> i givim tingting ia se yu mas toktok wetem dokta blong yu sipos yu stap gat wari blong tekem olgeta non-steroidal anti-inflammatori meresin (olgeta NSAID) olsem mo dipen long olgeta blong tritim olgeta longfala siknes.</p>
                </div>}
            extra={
            <div>
              <p>Ibuprofen i stap anda long wan grup blong meresin we oli kolem <a href="https://www.goodrx.com/blog/are-advil-motrin-tylenol-bad-for-coronavirus-symptoms/" target="_blank">NSAIDS</a> we hemia i inkludim asprin, naproxen (Aleve), diclofenac (Cambia), mo celexcoxib (Celexbrex), we oli yusum blong tritim bodi i so o pat blo bodi i so mo sweling. Evri meresin i gat olgeta risk mo benefit blong hem. Eksampol, olgeta NSAID oli save mekem saed ifek long kidni mo bel. Risk we hemi series tumas long saed blong acetaminophen hemi liva problem we i save hapen  taem man i tekem tumas, samtaem man i mestem i tekem ova tumas. Acetaminophen yu save faenem samtaem long olgeta komon kol mo flu meresin. Plis ridim gud infomesen long olgeta meresin blong mekem sua yu no tekem i ova tumas long wanem we yu sapos blong tekem.</p>
              <p><div className="paragraph-header">Olsem wanem olgeta toktok raon long ibuprofen i stat:</div><a href="https://jvi.asm.org/content/94/7/e00127-20" target="_blank">Research</a> i sajestem se ACE2, wan enzaem insaed long bodi, i mekem wan ples long seves blong sel blong koronavaeres i stak long hem mo spred i go long olgeta narafala sel.</p>
              <p>Long March namba 11 2020, olgeta man we oli mekem risej oli bin pablisim wan <a href="https://www.thelancet.com/journals/lanres/article/PIIS2213-2600(20)30116-8/fulltext" target="_blank">leta</a> long Lancet Respiratory Medicine we oli talem se ACE2 i i givhan blong mekem olgeta keis blong COVID-19 i go wos. Olsem wanem nao ibuprofen i kam insaed long isu ia? Man we i raetem leta ia i talem tingting se ibuprofen i save mekem ACE2 i go antap. Hemia i minim se koneksen bitwin ibuprofen mo COVID-19 i go antap.</p>
              <p>Hemia hemi wan toktok nomo we i stap go raon be i nogat pruf blong hem long saens we i soemaot se ibuprofen i mekem olgeta man we oli kasem COVID-19 i sik moa wos. Be, long namba 14 blong manis Maj, Ministri blong Helt blong France i postem wan woning agensem yus blong ibuprofen long COVID19 fiva, mo French Minista i mekem <a href="https://twitter.com/olivierveran/status/1238776545398923264" target="_blank">tweet</a> mo advaesem man blong no yusum ibuprofen mo olgeta narafala NSAIDS from se i save gat wan “rabis fakta” we i save gihav long sik ia blong go wos. Advaes blong hem i spred raon long olgeta nius mo stori mo oli talem nogud ibuprofen se hemi nao mein samting we i mekem ol pipol oli ded long COVID-19. Long namba 16 blong manis Maj olgeta we oli raetem fesfala theori ia we i statem dipet ia oli kam blong kliarem toktok blong olgeta <a href="https://www.unibas.ch/en/News-Events/News/Uni-Research/Ibuprofen-and-COVID-19-Setting-the-record-straight.html" target="_blank">“i no mekem wan rekomendesen blong yusum spesifik meresin o no”</a></p>
              <p>Blong olgeta we oli stap sava long olgeta saen blong COVID-19, WHO i advaesem feswan blong no tekem ibuprofen. <strong>Long namba 18 blong manis Maj <a href="https://www.ema.europa.eu/en/news/ema-gives-advice-use-non-steroidal-anti-inflammatories-covid-19" target="_blank">EMA</a>, <a href="https://www.fda.gov/drugs/drug-safety-and-availability/fda-advises-patients-use-non-steroidal-anti-inflammatory-drugs-nsaids-covid-19" target="_blank">FDA</a>, <a href="https://twitter.com/WHO/status/1240409217997189128" target="_blank">WHO</a> oli advaesem se man i mas fraet blong yusum NSAIDS sapos hemi nid blong yusum.</strong></p>
            </div>}
          />
          <Card 
            id="myth-8"
            thumbnail="/assets/img/shots/myth/myth-8.jpg" 
            title="Sipos man i stap tekem hem wan olgeta meresin olsem hydroxychloroquine & azithromycin bambae i save stopem COVID-19 ia?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>Plis yu <strong>NO MAS</strong> tritim yu wan wetem olgeta meresin ia! Tekem nomo olgeta meresin we dota i talem from nomo se oli save spolem yu taem yu miksim tugeta mo oli gat olgeta denjares saed ifek blong hem.</p>
                <p><a href="https://www.cdc.gov/coronavirus/2019-ncov/hcp/therapeutic-options.html" target="_blank">Hydroxychloroquine mo chloroquine</a> oli olgeta meresin we yu tekem tru long maot blong yu taem yu kasem malaria mo olgeta narafala siknes olsem lupus mo atraetis. Azithromycin hemi wan komon antibaeotik blong tritim olgeta infeksen we olgeta bakteria oli mekem. Hydroxychloroquine mo azithromycin tugeta i gat saed ifek blong hat atak mo oli save mekem hat i pam kwik taem tumas we i save kilim ded man. Risej i stap gohed yet blong faenemaot sipos olgeta meresin ia oli gud blong save tritim COVID-19 o no, be plis tekem olgeta meresin nomo we dokta i talem blong yu tekem.</p>
              </div>}
          />
          <Card 
            id="myth-9"
            thumbnail="/assets/img/shots/myth/myth-9.jpg" 
            title="Olgeta antibaeotik oli gud blong stopem mo tritim COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>Folem toktok blong <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a>, olgeta antibaeotik oli tritim nomo olgeta infeksen we bakteria i mekem, i no olgeta we vaeres i mekem. Man i no shud yusum antibaeotik blong stopem o tritim vaeres. Be, sipos yu kasem COVID-19 mo yu silip long hospitol, bam oli save givim antibaeotik long yu blong from se bakteria i save infektem hem tu semtaem.</p>
              </div>}
          />
          
          <Card 
            id="myth-11"
            thumbnail="/assets/img/shots/myth/myth-11.jpg" 
            title="Sipos mi yang mo helti, bae mi no save kasem koronavaeres?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>Folem wanem <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a> i talem “pipol long evri eij oli save kasem koronavaeres”</p>
                <p>Olgeta olfala, mo olgeta we medikol kondisen blong olgeta i nogud finis (olsem oli kasem sotwin, sik suga, hat problem) oli gat hae risk blong save sik bigwan wetem vaeres ia. WHO i rekomendem se evriman oli mas tekem step blong protektem olgeta long vaeres ia”</p>
              </div>}
            extra={
              <div>
                <p>Long olgeta 4226 keis (Februari 12 kasem Maj 160 long Amerika, <a href="https://www.cdc.gov/mmwr/volumes/69/wr/mm6912e2.htm#F2_down" target="_blank">CDC</a> i ripotem se wan aot long faev pipol we oli stap long hospitol oli olgeta eij grup blong 20 i go kasem 44. Stat long namba 17 Mei, ova long haf blong olgeta <a href="https://www.cdph.ca.gov/Programs/CID/DCDC/Pages/Immunization/ncov2019.aspx" target="_blank">COVID-19 keis long California</a> we oli konfem, hemi olgeta pipol we eij blong olgeta i no kasem 50 yia. Nomata we namba blong ded blong olgeta yang pipol i low, plante long olgeta yangfala we oli nogat eni medikol helt problem oli ded. Evriman i shud tekem kea mo protektem hem wan mo narafala man.</p>
              </div>}
          />
          <Card 
            id="myth-12"
            thumbnail="/assets/img/shots/myth/myth-12.jpg" 
            title="Bambae mi mas go stap long hospitol sipos mi kasem COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>Folem wanem <a href="https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/testing.html" target="_blank">CDC</a> plante man oli kasem smol sik nomo mo oli rikava long haos nomo. Yu no mas lego haos blong yu, be yu save mekem nomo sipos yu go blong pas long hospital. Go long hospitol sipos yu stap gat problem blong pulum win be yu kolem dokta o hospitol festaem bifo yu go. Plis luk “Lanem wanem nao hemi COVID-19”</p>
              </div>}
          />
          <Card 
            id="myth-13"
            thumbnail="/assets/img/shots/myth/myth-13.jpg" 
            title="Mi save holem taet win blong mi blong 10 seken o moa mo mi no kof o filim i nogud, blong jekem se mi kasem COVID-19 o no?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>Folem wanem CDC i talem, yu save gat koronavaeres mo semtaem nogat eni saen blong hem mo yu save pasem vaeres ia bifo yu soemaot eni saen blong hem. Folem toktok blong <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a> “Nomol saen blong COVID-19 hemi drae kof, man i filim taet mo fiva. Sam pipol bae oli divelopem plante strong sik olsem lang problem. Bambae yu no save konfemem wetem eksesaes ia blong holem taet win blong yu.</p>
              </div>}
          />
          <Card 
            id="myth-14"
            thumbnail="/assets/img/shots/myth/myth-14.jpg" 
            title="Olgeta mashin blong draemap han mo olgeta UV laet oli save kilim koronavaeres?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>Folem wanem <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a> i talem, olgeta mashin blong draemap han oli no save kilim koronavaeres. NO yusum UV laet blong klinim han blong yu o olgeta narafala eria blong skin from se radiesen blong UV i save damejem skin. Blong protektem yu agensem niufala koronavaeres ia yu shud wasem han blong yu oltaem wetem sop mo wota o wan samting we i gat alkohol long hem blong rapem han blong yu wetem”</p>
              </div>}
          />
          <Card 
            id="myth-15"
            thumbnail="/assets/img/shots/myth/myth-15.jpg" 
            title="Sipos yu stap rinsim nus blong yu wetem saline bae i stopem COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>Folem toktok blong <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a> “i nogat pruf blong soemaot se taem yu rinsim nus blong yu wetem saline hemi protektem yu blong kasem niufala koronavaeres ia. I gat smol pruf we i soemaot se taem yu rinsim nus blong yu wetem saline hemi save helpem yu blong rikava kwiktaem long nomol flu. Be, fasin blong rinsim nus evritaem i no soemaot se hemi stopem problem blong pulum win.</p>
              </div>}
          />
          <Card 
            id="myth-16"
            thumbnail="/assets/img/shots/myth/myth-16.jpg" 
            title="Swim long wota we i hot tumas o bath i save stopem COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>Yu shud showa o gat bath blong yu, be plis yu no minim blong swim long wota we i hot tumas. Folem toktok blong <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a> Nomol tempereja blong bodi blong yu i stap long samples 36.5°C kasem 37°C, nomata long tempereja blong bath o showa blong yu. Be olsem yumi save finis se taem yu swim long wan wota we i hot tumas hemi save bonem yu.”</p>
              </div>}
          />
          <Card 
            id="myth-17"
            thumbnail="/assets/img/shots/myth/myth-17.jpg" 
            title="Kol wota mo sno i stopem COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>Folem toktok blong <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a> “kol wota mo sno i no save kilim niufala vaeres ia. Nomol tempereja blong man hemi samples 36.5°C kasem 37°C nomata long tempereja aotsaed”</p>
              </div>}
          />
          <Card 
            id="myth-18"
            thumbnail="/assets/img/shots/myth/myth-18.jpg" 
            title="Hemi gud blong kakae galik blong stopem COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>Folem toktok blong <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a> “galik hemi wan helti kakae we i save gat sam propeti blong antimaekrobial long hem. Be, i nogat pruf we i soemaot long aotbrek we i jes pas ia se galik i bin protektem olgeta pipol long niufala koronavaeres ia.”</p>
              </div>}
          />
          <Card 
            id="myth-19"
            thumbnail="/assets/img/shots/myth/myth-19.jpg" 
            title="Does drinking alcohol prevent COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>Folem toktok blong <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a> “taem yu drink alkohol hemi no protektem yu agensem COVID-19 mo hemi denjares tumas. Sapos yu tekem tumas alkohol bambae yu inkrisim risk blong helt problem blong yu”</p>
              </div>}
          />
          <Card 
            id="myth-20"
            thumbnail="/assets/img/shots/myth/myth-20.jpg" 
            title="Do vaccines against pneumonia protect you against COVID-19?"
            content={
              <div>
                <p><span className="card-tag-false">No</span>Folem toktok blong <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">WHO</a> “olgeta stik meresin blong lang problem, olsem pneumococcal vaksin mo Haemophilus influenzae type B (Hib) vaksin, oli no protektem yu agensem niufala koronavaeres ia. Wanem we oli rekomendem blong protektem helt blong yu hemi stik meresin we yu tekem sapos yu gat problem blong pulum win”</p>
              </div>}
          />
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Myth;
