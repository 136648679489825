import React, { Component } from 'react'
import classnames from 'classnames'
import { Link, withRouter } from 'react-router-dom'

import Button from '../Components/Button'
import ClipboardButton from '../Components/ClipboardButton'

class Aside extends React.PureComponent {

  render() {

    return (
      <div className="content-aside desktop">
        <div className="content-share">
          <h4 className="content-share-caption">Serem wetem olgeta fren mo famili</h4>
          <div className="content-share-options">
            <div className="content-share-item content-share-twitter">
              <img className="fit-height" src="/assets/img/icons/twitter.png" />
              <a className="twitter-share-button" href="https://twitter.com/intent/tweet?text=Jekem%20Stret%20Infomesen%20blong%20COVID-19%20blong%20yu%20faenemaot%20olgeta%20infomesen%20we%20oli%20no%20tru%20mo%20olgeta%20we%20i%20tru%20long%20saed%20blong%20koronavaeres.&tw_p=tweetbutton&url=http%3A%2F%2Fcovid19factcheck.com%2Fbi" target="_blank">Tweet</a>
            </div>
            <div className="content-share-item content-share-facebook">
              <img className="fit-height" src="/assets/img/icons/facebook.png" />
              <a className="fb-share-button" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=http://covid19factcheck.com/bi"></a>
            </div>
            <div className="content-share-item content-share-email">
              <img className="fit-height" src="/assets/img/icons/email.svg" />
              <a href="mailto:?body=Jekem%20Stret%20Infomesen%20blong%20COVID-19%20blong%20yu%20faenemaot%20olgeta%20infomesen%20we%20oli%20no%20tru%20mo%20olgeta%20we%20i%20tru%20long%20saed%20blong%20koronavaeres.%20http%3A%2F%2Fcovid19factcheck.com%2Fbi"></a>
            </div>
            <ClipboardButton className="content-share-item content-share-link" value="http://covid19factcheck.com/bi" success="Link ia oli kopi finis! 🎉"><img className="fit-height" src="/assets/img/icons/link.svg" /></ClipboardButton>
          </div>
        </div>
        <h5 className="content-footnote">Plante infomesen i kam long <a href="https://www.who.int/" target="_blank">WHO</a> & <a href="https://www.cdc.gov/" target="_blank">CDC</a>.<br /> Infomesen we i jes kamaot nomo 10/27/2020.</h5>
      </div>
    )
  }
}

export default withRouter(Aside)