import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Learn extends Component {

  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <h4 className="content-nav-caption">جدول المحتويات</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-1')}>نظرة عامة</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-2')}>How does it spread?</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-3')}>Symptoms</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-4')}>What to do if I feel sick?</h4>
        </div>
        <div className="content-body">
          <Card 
            id="learn-1"
            icon="/assets/img/icons/info.svg" 
            title="نظرة عامة"
            content={
              <div>
                <p>الفيروس التاجي هو عائلة من الفيروسات التي يمكن أن تسبب أمراض الجهاز التنفسي الشائعة مثل البرد.</p>
                <p>في عام 2019 ،تم تحديد فيروس تاجي جديد. SARS-CoV-2 هو الاسم العلمي للفيروس والمرض الذي يسببه تم تسميته بمرض فيروسات التاجية 2019 (COVID-19).</p>
              </div>}
          />
          <Card 
            id="learn-2"
            icon="/assets/img/icons/spread.svg" 
            title="كيف ينتشر COVID-19؟"
            content={
              <div>
                <p><div className="paragraph-header">شخص لشخص:</div>قطرات الجهاز التنفسي هي الطريقة الرئيسية لانتشار COVID-19. إذا كنت على بعد 6 أقدام من شخص مصاب، يمكن أن تنتقل قطرات الجهاز التنفسي من السعال أو العطس أو التحدث الى فمك أو أنفك أو عينيك.</p>
                <p><div className="paragraph-header">السطوح الملوثة</div> يمكن أن تصاب بالعدوى عن طريق لمس الأسطح المصابة بالفيروس ثم لمس فمك أو أنفك أو عينيك. تشمل الأسطح التي يتم لمسها بشكل متكرر مقابض الأبواب ومفاتيح الإضاءة والهواتف المحمولة والطاولات ولوحات المفاتيح والمراحيض والصنابير والأحواض.</p>
              </div>}
          />
          <Card 
            id="learn-3"
            icon="/assets/img/icons/symptoms.svg" 
            title="الأعراض"
            content={
              <div>
                <div className="content-symptom-container">
                  <ul className="content-symptom-category">
                    <div className="content-symptom-title">أعراض أكثر شيوعًا</div>
                    <li className="content-symptom-item">حمى (100.4 درجة فهرنهايت ، 38 درجة مئوية)</li>
                    <li className="content-symptom-item">سعال جاف</li>
                    <li className="content-symptom-item">ضيق التنفس</li>
                  </ul>
                  <ul className="content-symptom-category">
                    <div className="content-symptom-title">أعراض أقل شيوعًا</div>
                    <li className="content-symptom-item">التعب</li>
                    <li className="content-symptom-item">آلام العضلات</li>
                    <li className="content-symptom-item">التهاب الحلق</li>
                    <li className="content-symptom-item">سيلان الأنف</li>
                    <li className="content-symptom-item">الغثيان</li>
                    <li className="content-symptom-item">الإسهال</li>
                    <li className="content-symptom-item">فقدان حاسةالشم أوالذوق</li>
                  </ul>
                </div>
                <div className="content-symptom-caption">يمكن أن لا يظهر المصابين بالفيروس أي أعراض.</div>
              </div>}
          />
          <Card 
            id="learn-4"
            icon="/assets/img/icons/sick.svg" 
            title="ماذا أفعل إذا شعرت بالمرض؟"
            content={
              <div>
                <p>وفقاً لمراكز السيطرة على الأمراض والوقاية (CDC)، يعاني معظم الناس من مرض خفيف ويمكنهم التعافي في المنزل. لا تغادر منزلك إلا للحصول على رعاية طبية. اطلب العناية الطبية إذا كنت تواجه صعوبة في التنفس ولكن اتصل ب المشفى او طبيبك الخاص أولا.</p>
                <hr />
                <p>
                  <ul className="content-list">
                    <div>اتصل برقم 911 إذا كان لديك حالة طبية طارئة بما في ذلك:</div>
                    <li>صعوبة في التنفس</li>
                    <li>ألم في الصدر</li>
                    <li>علامات اختلاط و تشوش</li>
                    <li>ازرقاق في لون الشفتين</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <ul className="content-list">
                    <div>لمزيد من المعلومات أو للمساعدة في العثور على الرعاية الطبية أو موارد المجتمع ، اتصل بقسم الصحة العامة في المقاطعة المحلية. <a href="https://covid19.ca.gov/state-local-resources/#top" target="_blank">انقر هنا</a> للحصول على إدارات الصحة العامة للمقاطعات في كاليفورنيا</div>
                    <li><a href="https://www.sccgov.org/sites/phd/DiseaseInformation/novel-coronavirus/Pages/home.aspx" target="_blank">Santa Clara County:</a> اتصل برقم 211 للحصول على معلومات حول COVID-19 وموارد المجتمع المحلي مثل الطعام والمأوى والاستشارة والمساعدة في التوظيف ورعاية الأطفال الجيدة والخدمات العليا والمزيد (متوفر على مدار الساعة طوال أيام الأسبوع</li>
                    <li><a href="https://sf.gov/topics/coronavirus-covid-19" target="_blank">San Francisco:</a> اتصل بالرقم 311 (من داخل SF) أو 2311- 01 7-415 للأسئلة ، وساعد في العثور على الرعاية وموارد المجتمع (متاح 24/7)</li>
                    <li><a href="http://publichealth.lacounty.gov/media/Coronavirus/" target="_blank">Los Angeles:</a> اتصل برقم 211 للحصول على الأسئلة ، وساعد في العثور على الرعاية وموارد المجتمع (متاح24/7)</li>
                    <li><a href="https://covid19info.ocgov.com/" target="_blank">Orange County:</a> اتصل برقم 211 للحصول على الأسئلة ، وساعد في العثور على الرعاية وموارد المجتمع (متوفرة على مدار الساعة طوال أيام الأسبوع)</li>
                    <li><a href="https://www.sandiegocounty.gov/coronavirus.html" target="_blank">San Diego:</a> اطلب 211 للحصول على أسئلة أو معلومات حول موارد المجتمع ، أو إذا كنت غير مؤمن عليه صحيا (متاح 24/7)</li>
                    <li><a href="https://www.co.fresno.ca.us/departments/public-health/covid-19" target="_blank">Fresno:</a> اتصل برقم 5596004636</li>
                    <li><a href="http://www.acphd.org/2019-ncov.aspx" target="_blank">Alameda:</a> اتصل برقم 5102682101</li>
                    <li><a href="https://www.coronavirus.cchealth.org/" target="_blank">Contra Costa:</a> اتصل برقم 8447298410</li>
                  </ul>
                </p>
                <hr />
                <p>يرجى زيارة موقع <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/disinfecting-your-home.html" target="_blank">CDC</a> على الإنترنت للحصول على نصائح مفيدة حول كيفية تقليل خطر العدوى عند مرض شخص ما</p>
              </div>}
          />
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Learn;
