import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Myth extends Component {

  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <div className="content-nav-scroller">
            <h4 className="content-nav-caption">目录</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-1')}>如果我戴口罩，我不需要与他人保持6英尺的距离。</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-10')}>全身喷酒精或氯能杀死新型冠状病毒。</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-2')}>我需要去买医疗口罩或N95口罩</h4>
            {/* <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-3')}>新冠病毒能通过空气传播</h4> */}
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-4')}>戴手套可以百分之百防御新冠病毒</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-5')}>冠状病毒可以在表面存活数天。</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-6')}>如果我长期留在家中，就不必经常洗手或清洁消毒</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-7')}>消炎药 布洛芬 (Ibuprofen) 可以让新冠患者的病情恶化</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-8')}>自行服用羟氯喹或阿奇霉素可防止感染冠状病毒。</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-9')}>抗生素对冠状病毒有预防和治疗的作用。</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-11')}>我年轻又健康，所以我不会感染冠状病毒。</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-12')}>如果我感染了新冠病毒，我一定要住院。</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-13')}>如果我能够屏住呼吸10秒或更长时间而不咳嗽或感到不适，就意味着我没有患冠状病毒病。</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-14')}>干手机（洗手后用的烘干机）或者紫外线消毒灯可有效的杀灭冠状病毒。</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-15')}>R经常用盐水冲洗鼻子可防止感染冠状病毒。</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-16')}>洗热水澡能预防新型冠状病毒病。</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-17')}>寒冷和下雪的天气可预防感染新冠病毒。</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-18')}>吃大蒜可防止感染新冠病毒。</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-19')}>饮酒可预防感染新冠病毒。</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-20')}>肺炎疫苗能预防新型冠状病毒。</h4>
          </div>
        </div>
        <div className="content-body">
          <Card 
            id="myth-1"
            thumbnail="/assets/img/shots/myth/myth-1.jpg" 
            title="如果我戴口罩，我不需要与他人保持6英尺的距离"
            content={
              <div>
                <p><span className="card-tag-false">不对</span>继续与他人保持6英尺的距离和经常洗手。戴口罩之前和脱下口罩之后都要洗手。</p>
                <p><a href="https://chinese.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover-faq.html" target="_blank">美国疾病預防控制中心 (CDC)</a>建议戴布料口罩以防止打喷嚏，咳嗽或说话时的飞沫传播病毒。口罩的保护性取决于其制造面料和材料。请参阅我们的口罩提示。</p>
              </div>}
            extra={
              <div>
                <p>请遵循<a href="https://chinese.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a>的建议并使用安全的家用材料。请确保口罩紧密贴合但仍可以使呼吸舒适。 CDC建议使用多层织物而没有建议使用某种特定类型的织物。</p>
                <p><div className="paragraph-header">布料口罩对冠状病毒的防护作用多少？</div>目前没有科学证据可以直接回答这个问题。<a href="https://www.ncbi.nlm.nih.gov/pubmed/24229526" target="_blank">在研究另一种病毒的实验</a>中，研究员发现所有试验过的织物（全棉衬衫、围巾、丝绸、枕头套、亚麻、棉混纺、茶巾）都不能百分之百阻止病毒颗粒。但是，此实验没有模拟正常人咳嗽。它也没有考虑面罩是否穿戴紧密合适。由于大多数口罩不能提供紧密的密封，因此病毒颗粒仍然可以通过口罩的缝隙 <a href="https://www.gannett-cdn.com/experiments/usatoday/gifs/tool/919-surgical.png" target="_blank">进入。</a></p>
                <p><div className="paragraph-header">重点</div>布料口罩可能有助于预防感染，但不能百分之百的保证防御成功。</p>
              </div>}
          />
          <Card 
            id="myth-10"
            thumbnail="/assets/img/shots/myth/myth-10.jpg" 
            title="全身喷酒精或氯能杀死新型冠状病毒。"
            content={
              <div>
                <p><span className="card-tag-false">不对</span><strong>请勿</strong>注射,饮用或者喷射酒精或氯在身上。</p>
                <p><a href="https://www.who.int/zh/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">世界卫生组织（WHO）</a>,称：「全身喷洒精或氯不会杀死已经进入体内的病毒。喷洒此类物质可能对衣服或粘膜（即眼睛、口腔）有害。请注意，酒精和氯可能对消毒各种表面有用，但是使用时须遵循<a href="https://chinese.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cleaning-disinfection.html" target="_blank">适当的建议</a>。」 请不要饮用或注射有腐蚀性的清洁用品，这些产品只适合用来清洁物件和表面。</p>
              </div>}
          />
          <Card 
            id="myth-2"
            thumbnail="/assets/img/shots/myth/myth-2.jpg" 
            title="我需要去买医疗口罩或N95口罩"
            content={
              <div>
                <p><span className="card-tag-false">不对</span>美国<a href="https://chinese.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a>建议普通市民使用布料面罩</p>
                <p>您可以使用您家里已有的各种的口罩。不用特别的去买医疗口罩(或称外科口罩)或N95口罩。您也可以考虑捐赠多余的N95口罩给医护人员。因为医疗口罩的供应短缺，应保留给受过培训和直接与病者密切接触的医护人员使用。 N95口罩主要防止被空气传播的病毒，但新冠病毒主要通过呼吸道飞沫传播。请在以下查询更多关于空气传播资讯。</p>
              </div>}
            extra={
              <div>
                <p><a href="https://www.fda.gov/medical-devices/personal-protective-equipment-infection-control/n95-respirators-and-surgical-masks-face-masks" target="_blank">N95口罩</a>有专用的过滤器。正确穿上时有气密密封，可以阻挡至少95％的非常小的（0.3微米）粒子。除了培训之外，每年医护人员还需要进行<a href="https://www.safetyalliance.org/wp-content/uploads/2019/06/fit-test-2-000110_retouched-672x372.jpg" target="_blank">口罩密合度测试</a>，以确保尺寸合适，以确保密封。正确佩戴后，可能会呼吸不舒服。医护人员也需要谨守精微的细节，例如面部毛发也可能导致不能完全密封。对于<a href="https://blogs.cdc.gov/niosh-science-blog/2018/01/04/respirators-public-use/" target="_blank">普通市民</a>来说，缺乏培训如何佩戴和缺乏合适测试, N95不太可能会提供足够的保护</p>
              </div>}
          />
          {/*
          <Card 
            id="myth-3"
            thumbnail="/assets/img/shots/myth/myth-3.jpg" 
            title="新冠病毒能通过空气传播"
            content={
              <div>
                <p><span className="card-tag-maybe">有可能</span>对于普通市民来说，不太可能会受到空气传播。</p>
                <p>根据<a href="https://www.who.int/news-room/q-a-detail/q-a-coronaviruses" target="_blank">世界卫生组织（WHO)</a>「 新冠病毒主要通过感染者的咳嗽、打喷嚏或说话时产生的飞沫传播。 这些飞沫太重而无法留在空气中。 它们很快会掉在地板或地面上。」在中国超过75,000病例的研究中，没有空中传播的报导。但是，医护人员可能位于高度危险性的环境。 WHO报告，在<a href="https://www.who.int/news-room/commentaries/detail/modes-of-transmission-of-virus-causing-covid-19-implications-for-ipc-precaution-recommendations" target="_blank">某些可以产生气雾</a>的医疗程序下（比如在为了帮助患者插入呼吸管），这个过程中病毒可能通过空气传播。</p>
                </div>}
            extra={
              <div>
                <p><div className="paragraph-header">什么是空气传播？</div>与呼吸飞沫相比，空气中的微粒更小，可以在空气中停留一段很长的时间使它们传播的距离超过6英尺。</p>
                <p><div className="paragraph-header">为什么新闻说新冠病毒是空气传播的？</div>发表在新英格兰医学杂志（NEJM）的<a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973" target="_blank">一项研究中</a>，研究人员用一个高功能机器旋转圆柱体制造了冠状病毒的气雾，在该圆柱体中病毒存活了3个小时。不过，机器制造的并不代表正常的人类咳嗽飞沫。在另一项未经同行评审的<a href="https://www.medrxiv.org/content/10.1101/2020.03.23.20039446v2" target="_blank">研究中</a>（未由其他专家进行评估），研究人员从13名新冠病毒患者病房的空气和表面中采集了样本。在所有个人用品中，有76.5％为阳性（手机、厕所、遥控器、床栏等等)。在空气样本中，有63.2％为阳性。并且在这些空气样本中，有些样本跟患者的距离甚至超过6英尺。但是，病毒的存在并不等于着它会引起疾病。比如说，病毒数量可能太低而无法引起疾病。该研究团体指出，由于样本中的病毒浓度较低，现时还未能确定样本中的传染性。</p>
              </div>}
          /> */}
          <Card 
            id="myth-4"
            thumbnail="/assets/img/shots/myth/myth-4.jpg" 
            title="戴手套可以百分之百防御新冠病毒"
            content={
              <div>
                <p><span className="card-tag-false">不对</span>只有在采取预防措施（例如，不要触摸眼睛、鼻子、嘴巴，和摘下手套后一定要洗手）后，手套才有帮助。否则，手套可能会产生交叉污染。被污染的手套接触到什么，病毒都可能扩散到被接触物件的表面。</p>
              </div>}
          />
          <Card 
            id="myth-5"
            thumbnail="/assets/img/shots/myth/myth-5-cn.jpg" 
            title="冠状病毒可以在表面存活数天。"
            content={
              <div>
                <p><span className="card-tag-true">对的</span>发表在NEJM的<a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973?query=featured_home" target="_blank">一项研究中</a>，在实验室控制的状况下，可以在纸皮板上检测冠状病毒长达24小时, 而在塑料、钢铁上长达72小时。</p>
                <p>注意，最终残留的病毒数量为起始病毒的0.1％。病毒的存在并不等于它具有传染性。由于每个表面上的病毒数量都随着时间迅速减少，因此触摸它们的感染风险也应随着时间而降低。</p>
              </div>}
          />
          <Card 
            id="myth-6"
            thumbnail="/assets/img/shots/myth/myth-6.jpg" 
            title="如果我长期留在家中，就不必经常洗手或清洁消毒"
            content={
              <div>
                <p><span className="card-tag-false">不对</span><a href="https://chinese.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/prevention.html" target="_blank">CDC</a>建议每天清洁和消毒经常触摸的表面，例如门把手、电灯开关、桌子、厨房的台面、电话、键盘、厕所、水龙头和水槽。</p>
                <p>您或家人可能在不知不觉中被感染。虽然您与病人密切接触比被污染的表面感染可能性更大，但如果可以，请采取预防措施。</p>
              </div>}
          />
          <Card 
            id="myth-7"
            thumbnail="/assets/img/shots/myth/myth-7.jpg" 
            title="消炎药 布洛芬 (Ibuprofen) 可以让新冠患者的病情恶化"
            content={
              <div>
                <p><span className="card-tag-false">不对</span>布洛芬 (Ibuprofen，商品名称Advil，Motrin) 是一种普遍在药房能够买到的消炎止痛药。当前没有足够的科学证据证明布洛芬会使新冠患者病情恶化。.</p>
                <p>直至2020年3月18日，<a href="https://twitter.com/WHO/status/1240409217997189128" target="_blank">世卫组织</a>不建议停用布洛芬。对乙酰氨基酚（Tylenol）是另外一种去烧和止痛药。如果您现在依靠服用非甾体抗炎药物 (NSAID) 去治疗慢性疾病但您担心服用这些药物，<a href="https://www.fda.gov/drugs/drug-safety-and-availability/fda-advises-patients-use-non-steroidal-anti-inflammatory-drugs-nsaids-covid-19" target="_blank">美国食品药品管理局（FDA）</a>建议与您的家庭医生谈论</p>
                </div>}
            extra={
            <div>
              <p>布洛芬(Ibuprofen) 属于一种称为<a href="https://www.goodrx.com/blog/are-advil-motrin-tylenol-bad-for-coronavirus-symptoms/" target="_blank">非甾体抗炎(NSAID)的药物</a>，还包括阿司匹林(Aspirin)，萘普生（Aleve），双氯芬酸（Cambia）和塞来昔布（Celebrex）都是用于治疗发烧，疼痛和炎症。每种药物都有其风险和益处。例如，非甾体抗炎药会增加肾衰竭和胃肠道溃疡的风险。服用对乙酰氨基酚（acetaminophen, Tylenol）的最大风险是服用过量引起的肝衰竭。对乙酰氨基酚存在于各种常见的非处方感冒和流感药物中。请仔细阅读药物的所有标签以确保不会超出每日服用的最大剂量。</p>
              <p><div className="paragraph-header">布洛芬药效争议是如何开始的？</div><a href="https://jvi.asm.org/content/94/7/e00127-20" target="_blank">研究</a>表明，ACE2是人体内的一种酶，它在人细胞膜上提供了一个位置可以让冠状病毒附着并且感染细胞。</p>
              <p>在2020年3月11日，研究人员在医学杂志 the Lancet Respiratory Medicine上发表了<a href="https://www.thelancet.com/journals/lanres/article/PIIS2213-2600(20)30116-8/fulltext" target="_blank">一封信</a>，其理论认为提高细胞的ACE2量可能与新冠病毒的严重性有关。跟布洛芬有什么关系？作者认为布洛芬可以引起ACE2量数增加。因此，在布洛芬和新冠病毒之间建立了关系。</p>
              <p>这些都是推测性的，现无科学证据表明布洛芬直接恶化新冠病毒患者的状况。但是，3月14日，法国卫生部发布警告，禁止将布洛芬用于治疗因新冠病毒的发热，法国卫生部长在<a href="https://twitter.com/olivierveran/status/1238776545398923264" target="_blank">推特 (Twitter)</a>上发布了避免布洛芬和其他NSAID的建议，因为它们可能“加重病情”。他的建议在新闻中广泛传播，并开始将布洛芬归因于新冠病毒死亡的主要原因。 3月16日，引发辩论的作者澄清了他们的理论<a href="https://www.unibas.ch/en/News-Events/News/Uni-Research/Ibuprofen-and-COVID-19-Setting-the-record-straight.html" target="_blank">“不构成是否使用某些药物的建议”。</a></p>
              <p>对于患有新冠病症状的人，WHO最初建议不要使用布洛芬。在3月18日，<strong><a href="https://www.ema.europa.eu/en/news/ema-gives-advice-use-non-steroidal-anti-inflammatories-covid-19" target="_blank">EMA</a>, <a href="https://www.fda.gov/drugs/drug-safety-and-availability/fda-advises-patients-use-non-steroidal-anti-inflammatory-drugs-nsaids-covid-19" target="_blank">FDA</a>, <a href="https://twitter.com/WHO/status/1240409217997189128" target="_blank">WHO</a></strong>建议在必要时刻可以考虑使用NSAIDS。</p>
            </div>}
          />
          <Card 
            id="myth-8"
            thumbnail="/assets/img/shots/myth/myth-8.jpg" 
            title="自行服用羟氯喹或阿奇霉素可防止感染冠状病毒。"
            content={
              <div>
                <p><span className="card-tag-false">不对</span>请不要在无处方的情况下吃处方药！处方药只应该在医生的指导下服用，以避免危险的副作用或者药物相互作用。</p>
                <p><a href="https://www.cdc.gov/coronavirus/2019-ncov/hcp/therapeutic-options.html" target="_blank">羟氯喹(Hydroxychloroquine)和氯喹 (chloroquine)</a>是治疗疟疾以及炎症性疾病（狼疮，风湿等）的口服处方药。阿奇霉素是一个常用的抗生素。羟氯喹和阿奇霉素都可能有影响心脏的副作用，可导致致命的心律失常（心跳过快）。研究人员正在研究这些药物是否能有效地治疗冠状病毒，不过请您务必遵守用药规则，在医生的指导下吃处方药。</p>
              </div>}
          />
          <Card 
            id="myth-9"
            thumbnail="/assets/img/shots/myth/myth-9.jpg" 
            title="抗生素对冠状病毒有预防和治疗的作用。"
            content={
              <div>
                <p><span className="card-tag-false">不对</span>根据<a href="https://www.who.int/zh/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">世界卫生组织（WHO）</a>，抗生素只能治疗细菌感染，但对病毒所引起的感染不起作用。所以抗生素不应该用于预防或者治疗病毒引起的感染。但是，如果你感染冠状病毒住院，因为病毒感染伴可能随着细菌感染， 医生有可能会给你抗生素。</p>
              </div>}
          />
          <Card 
            id="myth-11"
            thumbnail="/assets/img/shots/myth/myth-11.jpg" 
            title="我年轻又健康，所以我不会感染冠状病毒。"
            content={
              <div>
                <p><span className="card-tag-false">不对</span><a href="https://www.who.int/zh/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">世界卫生组织（WHO）</a>称：「无论年纪，所有人都可能感染新型冠状病毒。」</p>
                <p>年纪大或者有既往病史（别的疾病，比如哮喘，糖尿病和心脏病）的人感染后更可能会有较严重的症状。世界卫生组织建议所有年纪的人注重做好防护措施，以防病毒的传染。”</p>
              </div>}
            extra={
              <div>
                <p><a href="https://chinese.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover.html" target="_blank">美国疾病控制预防中心（CDC）</a>报道说：在美国最初4,226的冠毒病例 （從二月12日到三月16日期中），五分之一的住院病人年纪为20到44岁。直到2020年4月12日，<a href="https://www.cdph.ca.gov/Programs/CID/DCDC/Pages/Immunization/ncov2019.aspx" target="_blank">加州大约一半的冠状病毒确诊病例</a>发生于50岁以下的人。虽然死亡率在年轻人当中比较低，但也有很多无既往病史的青年人因得病而去世了。为了保护自己和他人，所有人都应该做好防护措施。</p>
              </div>}
          />
          <Card 
            id="myth-12"
            thumbnail="/assets/img/shots/myth/myth-12.jpg" 
            title="如果我感染了新冠病毒，我一定要住院。"
            content={
              <div>
                <p><span className="card-tag-false">不对</span>根据<a href="https://chinese.cdc.gov/coronavirus/2019-ncov/symptoms-testing/testing.html" target="_blank">美国疾病预防控制中心 (CDC) </a>大部分感染冠状病毒的人会有轻度的症状，会在家里恢复。除了去找医疗以外，不要离开你的住所。如果你的呼吸有困难，先打电话给你医生或者诊所。请参阅这个网站的“关于新冠状病毒”网页。</p>
              </div>}
          />
          <Card 
            id="myth-13"
            thumbnail="/assets/img/shots/myth/myth-13.jpg" 
            title="如果我能够屏住呼吸10秒或更长时间而不咳嗽或感到不适，就意味着我没有患冠状病毒病。"
            content={
              <div>
                <p><span className="card-tag-false">不对</span><a href="https://chinese.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover.html" target="_blank">美国疾病控制预防中心（CDC）</a>警告：「大部分患有冠状病毒的人都缺乏症状（“无症状”），甚至那些最终出现症状（“症状前”）的个体也会在显现症状前将病毒传播给他人」。 <a href="https://www.who.int/zh/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">世界卫生组织（WHO）</a>称：「新冠病毒最常见症状是干咳、乏力和发烧。有些人可能会患肺炎等比较严重疾病。不能用这种呼吸练习来测试。</p>
              </div>}
          />
          <Card 
            id="myth-14"
            thumbnail="/assets/img/shots/myth/myth-14.jpg" 
            title="干手机（洗手后用的烘干机）或者紫外线消毒灯可有效的杀灭冠状病毒。"
            content={
              <div>
                <p><span className="card-tag-false">不对</span><a href="https://www.who.int/zh/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">世界卫生组织（WHO）</a>称：「干手机并不能有效的消灭冠状病毒。紫外线消毒灯不应用来为手或者任何皮肤消毒，因为紫外线可能刺激皮肤。为了防止冠状病毒的传染，您应该经常用洗手液洗手，或者用带有酒精的消毒液洗手（免洗洗手液）。」</p>
              </div>}
          />
          <Card 
            id="myth-15"
            thumbnail="/assets/img/shots/myth/myth-15.jpg" 
            title="经常用盐水冲洗鼻子可防止感染冠状病毒。"
            content={
              <div>
                <p><span className="card-tag-false">不对</span><a href="https://www.who.int/zh/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">世界卫生组织（WHO）</a>称：「目前没有科学证据证明经常用盐水冲洗鼻子可防止感染新冠状病毒。少量的科研结果表示，患有普通感冒的人用这个方式冲洗鼻子可能会快点恢复。同时，经常冲洗鼻子未显示预防呼吸道感染。”</p>
              </div>}
          />
          <Card 
            id="myth-16"
            thumbnail="/assets/img/shots/myth/myth-16.jpg" 
            title="洗热水澡能预防新型冠状病毒病。"
            content={
              <div>
                <p><span className="card-tag-false">不对</span>您可以洗澡或淋浴，但请不要故意使用极热的水。<a href="https://www.who.int/zh/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">世界卫生组织（WHO）</a>称：“不管您洗澡或淋浴温度如何，您的正常体温仍会保持在36.5℃至37℃之间。事实上，洗热水澡时，如果水温过热是有害的，可能会烫伤您。”</p>
              </div>}
          />
          <Card 
            id="myth-17"
            thumbnail="/assets/img/shots/myth/myth-17.jpg" 
            title="寒冷和下雪的天气可预防感染新冠病毒。"
            content={
              <div>
                <p><span className="card-tag-false">不对</span><a href="https://www.who.int/zh/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">世界卫生组织（WHO）</a>称：”寒冷天气跟雪都不能消灭新冠状病毒。不管外面的温度高低，人的正常体温会维持在36.5到37°C之间。”</p>
              </div>}
          />
          <Card 
            id="myth-18"
            thumbnail="/assets/img/shots/myth/myth-18.jpg" 
            title="吃大蒜可防止感染新冠病毒。"
            content={
              <div>
                <p><span className="card-tag-false">不对</span><a href="https://www.who.int/zh/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">世界卫生组织（WHO）</a>称： “大蒜是一种含有抗菌特性的健康食物。但是，从当前的大流行中没有证据证明大蒜可防止感染新冠状病毒。”</p>
              </div>}
          />
          <Card 
            id="myth-19"
            thumbnail="/assets/img/shots/myth/myth-19.jpg" 
            title="饮酒可预防感染新冠病毒。"
            content={
              <div>
                <p><span className="card-tag-false">不对</span><a href="https://www.who.int/zh/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">世界卫生组织（WHO）</a>称：“饮酒不可预防感染新冠病毒，反而可能会带来危害。频繁或过量饮酒可能会加剧健康问题。</p>
              </div>}
          />
          <Card 
            id="myth-20"
            thumbnail="/assets/img/shots/myth/myth-20.jpg" 
            title="肺炎疫苗能预防新型冠状病毒。"
            content={
              <div>
                <p><span className="card-tag-false">不对</span><a href="https://www.who.int/zh/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">世界卫生组织（WHO）</a>称：“肺炎疫苗，如肺炎球菌（pneumococcal）疫苗和乙型流感嗜血杆菌（Hib）疫苗，不能预防新型冠状病毒。新冠病毒是新的和不同的病毒，需要有其专用的疫苗。虽然这些呼吸道疾病疫苗对预防新型冠状病毒没有效果，但我们强烈建议接种这些疫苗来保障自己的健康。”</p>
              </div>}
          />
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Myth;
