import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Tips extends Component {
  
  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <h4 className="content-nav-caption">Ngaahi Kaveinga Lalahi´</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-1')}>Ko e fanofano´</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-2')}>Ko hono malu’i ho mata´</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-3')}>Ko e tui kofunima´</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-4')}>Ko e fakama’a´</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-5')}>Fai fakatau´</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-6')}>Ko e fetuku mo e tiliva me’akai´</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-7')}>Ko e fetuku meili´</h4>
        </div>
        <div className="content-body">
          <Card 
            id="tips-1"
            thumbnail="/assets/img/shots/tips/tips-1.jpg" 
            title="Ko e fanofano´"
            content={
              <div>
                <p>‘Oku mahu’inga e fanofano´ ki hono ta’ofi e mafola ‘o e COVID-19. Fakaalaala mei he ala ki ho mata´, ihuu´, ngutu´ kimu’a pea ke fanofano.</p>
                <hr />
                <p>
                  <div className="paragraph-header">Founga Fanofano Totonu´</div>
                  <ol className="content-list">
                    <li><div className="ol-index">1</div>Fakalele hifo ha vai ke fakaviviku ‘aki ho nima´</li>
                    <li><div className="ol-index">2</div>Faka’aonga’i ha koa pea mili fakatoloua ho ongo nima´, ‘o kau ai mo e vahavaha’a ‘o ho lohulohu’i nima´, tu’anima´ pea pehē ki ho lalo nge’esi nima´.</li>
                    <li><div className="ol-index">3</div>Fanofano ho nima´ ‘i ha sekoni ‘e 20. (Tatau mo ha’o hiva tu’o ua e hiva “Happy birthday”)</li>
                    <li><div className="ol-index">4</div>Toe fanofano vai ma’a ho nima´.</li>
                    <li><div className="ol-index">5</div>Fakamātu’u’aki ho nima´ ha tauveli pe ko ho’o fakamōmoa pē he ‘ea´.</li>
                  </ol>
                  <h5 className="content-source"><a href="https://www.cdc.gov/handwashing/when-how-handwashing.html" target="_blank">Ma’u’anga Fakamatala´: CDC</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/CSJO52qZLbs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p>
                  <div className="paragraph-header">Ko fē taimi ‘oku tonu ke te fanofano ai´?</div>
                  <ul className="content-list">
                    <li>Taimi kotoa pē te ke ala lava ai´</li>
                    <li>Hili ha’o ‘i tu’a he ngaahi feitu’u kakai´.</li>
                    <li>
                      <div>Hili ha’o ala ki ha ngaahi me’a he feitu’u kakai’ia´</div>
                      <ul>
                        <li>Hangē ko e: kau’i matapaa´, tēpile´, pamu he pausa´, saliote sōpingi´, misini toho pa’anga´, ngaahi me’a lomi’anga fika´, me’a lomi he ‘eleveita´ etc.</li>
                      </ul>
                    </li>
                    <li>Kimu’a, lolotonga pea hili ha’o feime’atokoni</li>
                    <li>Kimu’a pea ke kai</li>
                    <li>Hili ha’o tale, mafatua, pea fangu ihū</li>
                    <li>Kimu’a ha’o tui pea hili ha’o vete ha me’a malu’i mata (mask) pe kofunima.</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">‘O ka ikai ma’u ha koa pe vai, ngāue’aki ha huhu’a tāmate siemu (hand sanitizer) ‘oku ngaohi’aki e ‘olokaholo´.</div>
                  <ul className="content-list">
                    <li>‘Oku ‘ikai lava ‘e he huhu’a tāmate siemu´ (hand sanitizer) ‘o tāmate’i e kalasi siemu hono kotoa.</li>
                    <li>Ko e totonu´ ke pēseti ‘e 60 pe laka hake ai e lahi ‘o e ‘olokaholo ‘oku ngaohi ‘aki e fo’i hina´.</li>
                    <li>Ngāue’aki e huhu’a´ ke mili kakato ‘aki ho nima´, vaha’a ho lohulohu’i nima´ pea pehē ki ho mu’omu’a’i nima.</li>
                    <li>Milimili ho nima´ kae’oua kuo <strong>mātu’u.</strong></li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-2"
            thumbnail="/assets/img/shots/tips/tips-2.jpg" 
            title="Ko hono malu’i ho mata´"
            content={
              <div>
                <p>
                  <div className="paragraph-header">Ngaahi fale’i ki hono ngāue’aki e ngaahi me’a malu’i mata´</div>
                  <ul className="content-list">
                    <div className="content-list-label-positive">Fakapapau’i</div>
                    <li>‘Oku lava ke ke kei mānava hono tui´</li>
                    <li>‘Oku´ ne kofu ho ihuu´ mo ho ngutu´ (mei he funga ihuu´ ki he lalo kumukumu´)</li>
                    <li>‘Oku´ ke ala ‘aki pē ngaahi me’a ha’i he tafa’aki´, ‘o ka ke vete ho me’a malu’i´</li>
                    <li>‘Oku fō mo tauaki faka’aho ho me’a malu’i´, kapau ‘oku ngaahi ‘aki ha konga tupenu.</li>
                    <li>‘Oku fanofano ma’u pē ho nima´ hili hono vete ho me’a malu’i´</li>
                    <li>‘Oku fa’o e me’a malu’i´ ‘i ha kato pe pini pau, kae’oua kuo taimi hono foo´.</li>
                  </ul>
                  <ul className="content-list">
                    <div className="content-list-label-negative">Faka’ehi’ehi</div>
                    <li>Mei he ala ki he konga ki tu’a ho me’a malu’i´, he ko e konga ia ‘oku likoliko taha´.</li>
                    <li>Mei hono tuku ke tautau e me’a malu’i´ ho lalo kumukumu´ pe fakatui takai ho kia´</li>
                    <li>Mei hono tuku ke avaava e ongo tafa’aki´</li>
                    <li>Mei hono vete ho me’a malu’i´ lolotonga ho tui kofunima´</li>
                    <li>Mei he ngāue’aki tu’olahi e ngaahi me’a malu’i fakalokitafa´ (surgical masks)</li>
                  </ul>
                  <h5 className="content-source">Ma’u’anga fakamatala´: <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a>; <a href="https://www.nytimes.com/2020/04/08/well/live/coronavirus-face-mask-mistakes.html#click=https://t.co/GkBNJAuw1V" target="_blank">The New York Times</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/m4O-3wQQjpA?start=118" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p>
                  <div className="paragraph-header">‘Oku ‘ikai ‘uhinga e tui me’a malu’i mata´ ke tuku ai ‘ete tauhi e ngaahi fale’i faka’ehi’ehi kehe´</div>
                  <div>‘O ka ke fili ke tui ha me’a malu’i pe kofunima, fakapapau’i ‘oku´ ke tauhi e:</div>
                  <ul className="content-list">
                    <li>Fanofano lelei´</li>
                    <li>Fakamama’o ‘aki e fute ‘e 6 (mita ‘e ua) mei he kakai kehe´</li>
                    <li>Tauhi ma’u ‘i he ‘api ‘oku´ ke lolotonga nofo ai´</li>
                  </ul>
                  <div>Neongo e tokoni ‘a e ngaahi me’a´ ni (me’a malu’i mata, kofunima etc.) ke fakasi’isi’i e mafola ‘a e mahaki´, ‘oku lava pē ke fehālaaki ai ha’ate ongo’i kuo´ te malu mo hao mei he mahaki´. ‘E kei lava pē foki e ngaahi me’a malu’i´ ni ‘o pā ki ha ngaahi me’a kehe kuo´ pipiki ki ai e vailasi´. Ko ia ai, ka fehālaaki ho’o ngāue’aki e ngaahi me’a´ ni, ‘e faingofua ange ai ho tu’u laveangofua ki hono ma’u e vailasi´.</div>
                </p>
              </div>}
            extra={
              <div>
                <p>
                  <div className="paragraph-header">Ko e hā ‘oku fale’i ai e CDC´ ke ngāue’aki e me’a malu’i mata´ he feitu’u kakai´?</div>
                  <div>Fakatatau ki he lau ‘a e <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover.html" target="_blank">CDC</a>, ‘e lava pē ke mafola meiate kita e mahaki´ neongo ‘oku ‘ikai ke te ongo’i puke. ‘Oku tokoni ho tui me’a malu’i´ mo ho fakamama’o mei he kakai kehe´ (‘aki e fute ‘e 6 pe mita ‘e ua) ki hono fakasi’isi’i e mafola ‘a e kolonavailasi´ ‘i he’etau komiunitii´.</div>
                  <ul className="content-list">
                    <div> ‘Oku ‘i ai e ni’ihi ‘oku ‘IKAI totonu ke nau tui me’a malu’i:</div>
                    <li>Fānau ‘oku si’i hifo he ta’u 2´</li>
                    <li>Kakai ‘oku faingata’a’ia ‘enau mānava´</li>
                    <li>Kakai kuo tō ‘o ‘ikai ‘ilo ha me’a</li>
                    <li>Kakai kuo ‘ikai ke nau lava ‘o vete honau me’a malu’i´</li>
                  </ul>
                </p>
                <p>‘Oku ‘i ai e fale’i ‘a e CDC ‘i ‘Amelika ke ngaohi ho me’a malu’i´ ‘aki ha ngaahi me’a ‘oku malu ‘o kau ai e ngaahi falani motu’a pe konga tupenu ha’i ‘ulu. Vakai ki he fakahinohino ‘a e CDC <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">step-by-step guide</a> fekau’aki mo e ngaahi founga ki hono fa’u ‘o e me’a malu’i´.</p>
                <p><strong>Oku ‘ikai fu’u fiema’u ia ke ke tui ha me’a malu’i fakalokitafa (surgical masks) pe me’a malu’i ‘o hangē ko e N-95 respirator.</strong> ‘Oku ‘ikai poupou’i he CDC´ ho’o fakatau e ngaahi me’a´ ni koe’uhī ko ‘ene honge ‘i ‘Amelika´.</p>
              </div>
            }
          />
          <Card 
            id="tips-3"
            thumbnail="/assets/img/shots/tips/tips-3.jpg" 
            title="Ko e tui kofunima´"
            content={
              <div>
                <p>‘Oku ‘ikai fu’u fiema’u fēfē e tui kofunima´ kapau ‘oku´ ke feinga ke malu’i koe he feitu’u kakai´. Kā, ‘o kapau ‘oku´ ke fili ke tui, ko e ngaahi fale’i mahu’inga leva eni ke ke tokanga ki ai´:</p>
                <p>
                  <ul className="content-list">
                    <div className="content-list-label-positive">FAKAPAPAU’</div>
                    <li>‘Oku´ ke fanofano kimu’a pea ke tui ho kofunima´. Toe fanofano <strong>‘i he toki ‘osi</strong> hono vete ho kofunima´.</li>
                    <li>D‘Oku´ ke laku ki he veve´ hili hono faka’aonga’i´ pea ‘oua ‘e ngāue’aki tu’olahi e kofunima pelesitiki´.</li>
                    <li>‘Oku fō e kofunima tupenu´ ‘aki e vai mo e koa kimu’a pea´ ke toe ngāue’aki´.</li>
                  </ul>
                  <ul className="content-list">
                    <div className="content-list-label-negative">FAKA’EHI’EHI</div>
                    <li>Mei he ala ki ho mata´, ngutu´ mo e ihuu´</li>
                    <li>Mei hono tuku ho nima´ he loto kato ho kofu´</li>
                    <li>Mei he ala ki ho telefoni´</li>
                  </ul>
                  <h5 className="content-source">Ma’u’anga fakamatala: <a href="https://www.kqed.org/science/1961243/coronavirus-heres-how-to-use-gloves-properly-to-help-prevent-infection" target="_blank">KQED</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/TnLCYqk20YU?start=116" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>}
          />
          <Card 
            id="tips-4"
            thumbnail="/assets/img/shots/tips/tips-4.jpg" 
            title="Ko e fakama’a"
            content={
              <div>
                <p>Fakatatau ki he ngaahi fakatotolo fakamuimui taha´, ‘oku ‘ikai ko e founga tefito e pipihi ‘a e mahaki COVID-19´ ‘a ‘ete pā ki ha ngaahi me’a kuo pipiki ai e vailasi´. Ka ‘oku toe malava ange ho’o ma’u e mahaki´ ‘o kapau kuo pipiki ha ngaahi vailasi mo’ui ‘i ho nima´, pea´ ke ala leva ai ki ho mata´, ihuu´ pe ngutu´. ‘E tokoni ho fanofano´ mo ho’o fakama’a e ngaahi funga me’a hono kotoa ki hono fakasi’isi’i e mafola ‘a e mahaki´.</p>
                <hr />
                <p>
                  <div className="paragraph-header">Ko e hā e lōloa ‘a e mo’ui ‘a e Kolonavailasi´ he ngaahi naunau kalasi kehekehe´?</div>
                  <div className="content-table">
                    <div className="content-table-row">
                      <div className="content-table-column">
                        <h3 className="content-table-heading">Houa ‘e 4s</h3>
                        <h4 className="content-table-caption">Kopa</h4>
                      </div>
                      <div className="content-table-column">
                        <h3 className="content-table-heading">Houa ‘e 24</h3>
                        <h4 className="content-table-caption">La’i puha</h4>
                      </div>
                    </div>
                    <div className="content-table-row">
                      <div className="content-table-column">
                        <h3 className="content-table-heading">‘Aho ‘e 2-3</h3>
                        <h4 className="content-table-caption">Pelesitiki</h4>
                      </div>
                      <div className="content-table-column">
                        <h3 className="content-table-heading">‘Aho ‘e 2-3</h3>
                        <h4 className="content-table-caption">Ukamea sitila</h4>
                      </div>
                    </div>
                  </div>
                  <div>Neongo na’e kei mo’ui e vailasi´ he ngaahi funga me’a´ ni hili ha ngaahi houa mo e ‘aho, na’e holo vave ‘aupito e lahi ‘o e ngaahi fo’i vailasi ne mo’ui´ pea holo hifo ai pē mo e mafai ke ma’u kita he mahaki´. (Fakatatau ki he fakatotolo na’e pulusi he <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973" target="_blank">The New England Journal of Medicine</a> (3/17/2020)</div>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">Ko e hā e me’a ‘e lava ke u ngāue’aki ki he fakama’a me’a´?</div>
                  <ul className="content-list">
                    <div>Kātaki ‘o vakai ki he uepisaiti ‘a e CDC ‘a ē ‘oku hā ai e ngaahi fale’i ki he anga hono fakama’a e ngaahi funga me’a kalasi kehekehe´ <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cleaning-disinfection.html" target="_blank">(clean specific types of surfaces.)</a> . ‘Oku poupou e CDC (CDC recommends) Ki hono ngāue’aki e ngaahi me’a´ ni</div>
                    <li>Ngaahi me’a fakama’a kuo faka’atā mei he EPA <a href="https://www.cnn.com/2020/03/05/health/epa-disinfectants-coronavirus-trnd/index.html" target="_blank">(vakai heni ki he lisi - see list here)</a></li>
                    <li>
                      <div>Seniola hu’i</div>
                      <ul>
                        <li>Sēpuni seniola lahi ‘e nima (⅓ e ipu) ki he lita vai ‘e 3.8</li>
                        <li><strong>PE</strong> ko e sēpuni tī ‘e 4 ki he lita vai ‘e taha.</li>
                        <li>‘Oua e ngāue’aki e seniola´ ke fai ‘aki ha’o fufulu me’a kae’oua kuo hu’i.</li>
                        <li>Fakapapau’i kuo te’eki ke ‘osi e taimi ‘o e seniola´.</li>
                        <li>‘Oua na’a ke heu fakataha e seniola´ mo ha ‘amōnia pe ha toe huhu’a fufulu me’a kehe ‘i ‘api.</li>
                      </ul>
                    </li>
                    <li>Ngaahi huhu’a ‘olokaholo laka hake ‘i he peseti ‘e 70.</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-5"
            thumbnail="/assets/img/shots/tips/tips-5.jpg" 
            title="Fai fakatau´"
            content={
              <div>
                <p><strong>‘Oku toe malava ange ha’o puke mei he kakai he ngaahi falekoloa´, ‘o ‘ikai mei he ngaahi koloa ‘oku fakatau´.</strong> Fakatatau ki he <a href="https://www.cdc.gov/coronavirus/2019-ncov/daily-life-coping/essential-goods-services.html" target="_blank">CDC</a>, “‘oku ‘ikai ha fakamo’oni ki he tokoni ‘a e me’akai´ pe ngaahi puha pe kofu ‘oku fa’o ai´ ki hono fakamafola e vailasi´ ‘i ‘Amelika.”</p>
                <p>
                  <div className="paragraph-header">Ngaahi fale’i ke ke malu he fakatau koloa´</div>
                  <ul className="content-list">
                    <li>‘Oua na’a´ ke ‘alu ki falekoloa ‘o kapau ‘oku ke ongo’i puke (tale, mafatua, fofonu etc.) pe ‘o kapau kuo´ ke fetaulaki tonu mo ha taha ‘oku fokoutua ‘i he mahaki´.</li>
                    <li>Feinga ke fakauiketaha pe fakauikeua ho faifakatau´</li>
                    <li>Fakapapau’i ko e tokotaha pē ho’omou fāmili ‘oku fai homou fakatau´</li>
                    <li>‘O kapau ‘oku lava, tuku ke nofo e fānau iiki´ mo ha taha matu’otu’a homou ‘api´.</li>
                    <li>Hiki ha lisi ho fakatau´ ke fakasi’isi’i ho taimi sōpingi´.</li>
                    <li>Tui ha me’a ke malu’i ho mata´ pea fakamama’o mei he kakai kehe´</li>
                    <li>Holo e kau ‘o e saliote sōpingi´ kimu’a pea ke ngāue’aki´</li>
                    <li>‘O kapau ‘oku lava, fili e founga totongi te ke hao ai he ala ki he mīsini´</li>
                    <li>Ko ho mavahe pē mei he falekoloa´ pea faka’aonga’i leva e huhu’a tāmate siemu´.</li>
                    <li>‘I ho’o a’u ki ‘api´, fanofano ho nima´ ‘aki e koa mo e vai ‘o ‘oua na’a si’i hifo he sekoni ‘e 20.</li>
                    <li>Tokoni ki he ni’ihi (fāmili, kaungāme’a pe maheni) ‘oku ‘ikai te nau lava ‘o fakahoko ‘enau fakatau´.</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">‘Oku ‘ikai fiema’u  ke ke fufulu e ngaahi koloa kuo´ ke fakatau´</div>
                  <div>Ko e mahu’inga taha´ ke ke fanofano ho nima´ hili ha’o ala ki he ngaahi koloa toki fakatau´. Faka’ehi’ehi mei hono ngāue’aki e koa´ ki hono fufulu e fua’i ‘akau´ mo e vesitapolo´.</div>
                  <ul className="content-list">
                    <div>Kā, <a href="https://www.npr.org/sections/health-shots/2020/04/12/832269202/no-you-dont-need-to-disinfect-your-groceries-but-here-s-to-shop-safely" target="_blank">‘o kapau ‘oku´ ke loto ke fufulu mo fakama’a ho fakatau´:</a></div>
                    <li>Vakai pe ‘oku lava ke laku e ngaahi kofukofu ‘oku ‘ikai fiema’u´</li>
                    <li>Toki ngāue’aki pē ngaahi me’a tāmate siemu´ (disinfectant) he ngaahi me’a fefeka´ ‘o hangē ko e hina sio’ata´ mo e kapa´</li>
                    <li>Ngāue’aki e koa mo e vai kapau ‘oku kofu pelesitiki pe la’ipuha e koloa´. ‘E hao ai ho koloa´ mei he hū ki ai e koa mo ha ngaahi kemikale kehe.</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-6"
            thumbnail="/assets/img/shots/tips/tips-6.jpg" 
            title="Ko e fetuku mo e tiliva me’akai´"
            content={
              <div>
                <p>Fakatatau ki he lau ‘a e <a href="https://www.fda.gov/food/food-safety-during-emergencies/shopping-food-during-covid-19-pandemic-information-consumers" target="_blank">FDA</a>, ‘oku mafola e COVID-19 mei he tangata ki he tangata, ‘o ‘ikai hangē ko e ngaahi puke ko ē ‘oku tau angamaheni mo ia ‘oku fa’a tupu mei he kai e me’akai mafu´.</p>
                <p>
                  <ul className="content-list">
                    <li>Tiliva me’akai´: Kole ke tuku e me’akai´ ho ve’e matapaa´.</li>
                    <li>To’o e me’akai´ mei he poulu ‘a e falekai´ ki he poulu pe koniteina ‘o’ou´.</li>
                    <li>Kapau ‘oku´ ke ngāue’aki ha huhu, hele pe sēpuni mei he falekai´, ‘uluaki fufulu ‘aki ha vai mo e koa fufulu ipu pea ke toki ngāue’aki.</li>
                    <li>Fakapapau’i kuo ke fanofano ho nima´ pea ke toki ala ki he me’akai´.</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-7"
            thumbnail="/assets/img/shots/tips/tips-7.jpg" 
            title="Ko e fetuku meili´"
            content={
              <div>
                <p>Fakatatau ki he <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973?query=featured_home" target="_blank">fakatotolo</a>‘a e NEJM, ne kei ‘asi pe ha ki’i konga si’i ‘o e kolonavailasi´ ‘i ha ngaahi la’ipuha´ hili e pā ki ai e vailasi´ he ‘osi e houa ‘e 24. Ka ‘oku ‘ikai tu’upau ai hano ma’u kita he vailasi´ ‘o ka te pā ki he la’ipuha´ ni. ‘I he lau ‘a e <a href="https://www.cdc.gov/coronavirus/2019-ncov/faq.html" target="_blank">CDC</a>´, ‘oku si’isi’i ha lava ‘a e mahaki´ ‘o mafola he meili´, koloa´ mo e ngaahi puha pe kofukofu´.</p>
                <p>
                  <ul className="content-list">
                    <li>Hili hono fakaava ho meili´ pea fanofano ho nima´</li>
                    <li>Fufulu e helekosi mo e ngaahi funga me’a kotoa pē kuo pā ki he meili´.</li>
                  </ul>
                </p>
              </div>}
          />
          <h4 className="content-tips-disclaimer">‘Oku mau lave’i pē he ‘ikai malava ke faitatau e tokotaha kotoa ki he ngaahi fale’i´ ni. ‘Oku kehe ‘a e tūkunga mo e tu’unga ma’ume’a ‘a e fāmili takitaha. Neongo ia, ‘oku mau faka’amu pē ne ‘i ai ha ki’i tokoni ‘a e fakamatala´ ni. Pea kātaki pē kāinga, ka mou vahevahe mai mu’a ‘o kapau ne ‘i ai ha ngaahi founga kehe ne mou ngāue’aki ke fakalava ‘aki hono tauhi e ngaahi fale’i´ ni, koe’uhī kae lava ke fakalelei ai ‘emau fakahoko fatongia´! Mālō!</h4>
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Tips;
