import React, { Component } from 'react'
import classnames from 'classnames'
import {
  BrowserRouter as Router,
  Link,
  Route,
  useLocation,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom'

import Button from '../Components/Button'

class Header extends React.PureComponent {

  render() {

    const { toggleLanguageModalActive, toggleShareModalActive, path } = this.props;

    return (
      <div className="header-container">
        <div className="header grid">
          <h1 className="header-title">COVID-19<br />Fact Check</h1>
          <div className="header-description">Ako ki he mo’oni´ mo e loi´ ka ke mateuteu lelei mo vahevahe ho ‘ilo´ mo e kakai kehe´</div>
          <div className="header-action mobile">
            <Button className="btn-secondary" label="Tongan" imgURL="/assets/img/icons/arrow_down.svg" onClick={toggleLanguageModalActive} />
            <Button className="btn-primary" label="Vahevahe" imgURL="/assets/img/icons/share.svg" onClick={toggleShareModalActive} />
          </div>
          <div className="header-caption mobile">
            <h5 className="header-caption-item">Ko e lahi taha ‘i he ngaahi fakamatala´ ne fakamā’opo’opo mei he <a href="https://www.who.int/" target="_blank">WHO</a> pea mo e <a href="https://www.cdc.gov/" target="_blank">CDC.</a> </h5>
            <h5 className="header-caption-item">Ko e fakafo’ou fakamuimui taha´ ‘i he ‘aho 18/07/20.</h5>
          </div>
        </div>
        <div className="nav">
          <a href="/to/" className={classnames('nav-item', {'nav-item-active': path === ''})}>Ko hono fakamakehe’i e mo’oni´ mei he loi´</a>
          <a href="/to/tips" className={classnames('nav-item', {'nav-item-active': path === 'tips'})}>Ngaahi fale’i tokoni´</a>
          <a href="/to/learn" className={classnames('nav-item', {'nav-item-active': path === 'learn'})}>Ako fekau’aki mo e COVID-19</a>
          <a href="/to/about" className={classnames('nav-item desktop', {'nav-item-active': path === 'about'})}>Ko hai kimautolu</a>
          <a href="mailto:contact@covid19factcheck.com" target="_blank" className="nav-item desktop">Fetu’utaki mai</a>
        </div>
      </div>
    )
  }
}

export default withRouter(Header)