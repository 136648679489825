import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Myth extends Component {

  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <div className="content-nav-scroller">
            <h4 className="content-nav-caption">جدول المحتويات</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-1')}>هل أحتاج إلى الابتعاد 6 أقدام عن الآخرين حتى لو كنت أرتدي قناع وجه؟</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-10')}>هل يقتل رش الكحول أو الكلور في جميع أنحاء الجسم الفيروس التاجي الجديد؟</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-2')}>هل أحتاج إلى شراء أقنعة جراحية أو جهاز تنفس N95؟.</h4>
            {/* <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-3')}>هل ينتقل كوفيد-19 عبر الهواء؟</h4> */}
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-4')}>إذا كنت أرتدي قفازات ، فهل أنا محمي من فيروس كورونا؟</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-5')}>هل يمكن للفيروس التاجي أن يعيش على الأسطح لأيام؟</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-6')}>هل يجب علي غسل اليدين بشكل متكرر أو تعقيم الأسطح إذا لم أغادر المنزل؟</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-7')}>هل يزيد الإيبوبروفين من تفاقم المرض لدى الأشخاص المصابين بـ كوفيد-19؟</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-8')}>هل يمنع التطبيب الذاتي بواسطة هيدروكسي كلوروكين وأزيثروميسين كوفيد-19؟</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-9')}>هل المضادات الحيوية فعالة في منع وعلاج كوفيد-19؟</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-11')}>ههل الأشخاص الذين هم في سن الشباب يتمتعون بصحة جيدة من فيروس كورونا الجديد؟</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-12')}>هل سأدخل المستشفى إذا كان لدي كوفيد-19؟</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-13')}>إذا تمكنت من حبس أنفاسي لمدة 10 ثوانٍ أو أكثر من دون السعال أو الشعور بعدم الراحة ، فهل هذا يعني أنني لا أملك كوفيد-19؟</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-14')}>هل مجففات الأيدي ومصابيح التعقيم بالأشعة فوق البنفسجية فعالة في قتل الفيروس التاجي؟</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-15')}>هل غسل أنفك بانتظام بالمحلول الملحي يمنع كوفيد-19؟</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-16')}>هل يمنع الدش أو الحمام الساخن كوفيد-19؟</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-17')}>هل يمنع الطقس البارد والثلوج كوفيد-19؟</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-18')}>هل أكل الثوم يمنع كوفيد-19؟</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-19')}>هل شرب الكحول يمنع كوفيد-19؟</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-20')}>هل اللقاحات ضد الالتهاب الرئوي تقي من كوفيد-19؟</h4>
          </div>
        </div>
        <div className="content-body">
          <Card 
            id="myth-1"
            thumbnail="/assets/img/shots/myth/myth-1.jpg" 
            title="هل أحتاج إلى الابتعاد 6 أقدام عن الآخرين حتى لو كنت أرتدي قناع وجه؟"
            content={
              <div>
                <p><span className="card-tag-true">نعم</span>الاستمرار في الحفاظ على مسافة 6 أقدام من الآخرين ؛ اغسل يديك بشكل متكرر ، بما في ذلك قبل وضع القناع وبعد إزالته.</p>
                <p>في الولايات المتحدة ، يوصي مركز السيطرة على الأمراض <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover-faq.html" target="_blank">(CDC)</a> بأقنعة من القماش للمساعدة على منع الشخص من نشر الفيروس عن طريق احتواء قطرات الجهاز التنفسي أثناء العطس أو السعال أو التحدث. اعتمادًا على القماش والملاءمة ، قد توفر الأقنعة بعض الحماية ولكن ليس ضد جميع جزيئات الفيروس. يرجى الاطلاع على صفحة "نصائح مفيدة".</p>
              </div>}
            extra={
              <div>
                <p>يرجى اتباع توصيات مركز السيطرة على الأمراض <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover-faq.html" target="_blank">(CDC)</a> واستخدام المواد المنزلية الآمنة والتأكد من أن القناع يناسب بشكل مريح ولكنه لا يزال يسمح بالتنفس المريح. يوصي مركز السيطرة على الأمراض (CDC) بطبقات متعددة من القماش ولا ينصح بنوع معين من القماش.</p>
                <p><div className="paragraph-header">ما مدى فعالية أقنعة القماش ضد الفيروس التاجي؟</div>لا يوجد دليل علمي للإجابة مباشرة على هذا السؤال. ف<a href="https://www.ncbi.nlm.nih.gov/pubmed/24229526" target="_blank">ي تجربة مختبرية</a> باستخدام فيروس مختلف ، لم يجد الباحثون أيًا من الأقمشة (قميص قطني 100٪ ، وشاح ، حرير ، غطاء وسادة ، كتان ، مزيج قطن ، منشفة شاي) يمكنها حجب 100٪ من الجزيئات الفيروسية بشكل فعال بسبب صغر حجمها. ومع ذلك ، فإن هذه التجربة لا تحاكي السعال البشري العادي. كما أنها لا تأخذ في الاعتبار مدى ملاءمة الأقنعة. نظرًا لأن معظم الأقنعة لا توفر ختمًا محكمًا ، فلا يزال بإمكان الجسيمات الفيروسية الدخول من خلال جوانب القناع.</p>
                <p><div className="paragraph-header">النقاط الرئيسية</div>قد تساعد أقنعة القماش في منع العدوى لكنها لن توفر حماية بنسبة 100٪.</p>
              </div>}
          />
          <Card 
            id="myth-10"
            thumbnail="/assets/img/shots/myth/myth-10.jpg" 
            title="هل يقتل رش الكحول أو الكلور في جميع أنحاء الجسم الفيروس التاجي الجديد؟"
            content={
              <div>
                <p><span className="card-tag-false">لا</span>الرجاء عدم الحقن أو شرب أو رش الكحول المطهر أو الكلور في جميع أنحاء الجسم.</p>
                <p>وفقًا <a href="https://translate.google.com/translate?hl=en&prev=_t&sl=auto&tl=ar&u=https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">لمنظمة الصحة العالمية</a> ، "لن تقتل الفيروسات التي دخلت جسمك بالفعل. يمكن أن يكون رش هذه المواد على الملابس والجلد خطيرًا. يمكن أن يكون الكحول والكلور مفيدًا في تطهير الأسطح وفقًا <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cleaning-disinfection.html" target="_blank">للتوصيات المناسبة. "</a></p>
              </div>}
          />
          <Card 
            id="myth-2"
            thumbnail="/assets/img/shots/myth/myth-2.jpg" 
            title="هل أحتاج إلى شراء أقنعة جراحية أو جهاز تنفس N95؟"
            content={
              <div>
                <p><span className="card-tag-false">لا</span>يوصي مركز السيطرة على الأمراض <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">(CDC)</a> بأقنعة الوجه القماشية لعامة الناس في الولايات المتحدة.</p>
                <p>يمكنك ارتداء أي أقنعة لديك بالفعل في المنزل ولكنك لست بحاجة إلى شراء أقنعة جراحية أو N95 عن قصد. بسبب النقص في العرض في الولايات المتحدة ، يجب حجز أقنعة الرتب الطبية للعاملين في الرعاية الصحية الذين تم تدريبهم على استخدامها بشكل صحيح والذين هم على اتصال مباشر مع المرضى المرضى. ضع في اعتبارك التبرع بأقنعة N95 الإضافية نظرًا لأن N95 مطلوبة لمنع الانتقال المحمول جواً ، ولكن كوفيد-19 ينتقل بشكل أساسي من خلال قطرات الجهاز التنفسي. يرجى الاطلاع على الأسطورة المحمولة جوا أدناه.</p>
              </div>}
            extra={
              <div>
                <p>يحتوي <a href="https://www.fda.gov/medical-devices/personal-protective-equipment-infection-control/n95-respirators-and-surgical-masks-face-masks" target="_blank">جهاز التنفس N95</a> على مرشح متخصص يمكنه حجب ما لا يقل عن 95 في المائة من جسيمات الاختبار الصغيرة جدًا (0.3 ميكرون) إذا كان هناك مانع لتسرب الهواء. وبصرف النظر عن التدريب ، فإن العاملين في مجال الرعاية الصحية يكملون <a href="https://www.safetyalliance.org/wp-content/uploads/2019/06/fit-test-2-000110_retouched-672x372.jpg" target="_blank">اختبار الملاءمة</a> سنويًا لضمان الحجم المناسب الذي يوفر أختام محكمة الإغلاق. عند ارتدائها بشكل صحيح ، قد تكون غير مريحة للتنفس. حتى أصغر التفاصيل مثل شعر الوجه قد تسبب ختمًا غير مناسب. بالنسبة <a href="https://blogs.cdc.gov/niosh-science-blog/2018/01/04/respirators-public-use/" target="_blank">لعامة الناس</a> ، من غير المرجح أن يوفر نقص التدريب وقلة اختبار الملاءمة مستوى الحماية التي كان N95 مخصصًا لها.</p>
              </div>}
          />
          {/*
          <Card 
            id="myth-3"
            thumbnail="/assets/img/shots/myth/myth-3.jpg" 
            title="هل ينتقل كوفيد-19 عبر الهواء؟"
            content={
              <div>
                <p><span className="card-tag-maybe">ربما</span>من غير المحتمل انتقاله جواً لعامة الناس.</p>
                <p>وفقًا لمنظمة الصحة العالمية  <a href="https://www.who.int/news-room/q-a-detail/q-a-coronaviruses" target="_blank">(WHO)</a>، "ينتقل كوفيد-19 بشكل أساسي من خلال قطرات ولدت عندما يسعل شخص مصاب أو يعطس أو يتحدث. هذه القطرات ثقيلة جدًا بحيث لا يمكن تعليقها في الهواء. يسقطون بسرعة على الأرضيات أو الأسطح ". لم يتم الإبلاغ عن أي انتقال جوي بين تحليل أكثر من 75000 حالة من كوفيد-19 في الصين. ومع ذلك ، قد يكون عمال الرعاية الصحية في خطر. تشير منظمة الصحة العالمية إلى أن الانتقال عبر الهواء قد يكون ممكنًا أثناء إجراءات المستشفى التي تولد الهباء الجوي مثل التنبيب ، حيث يتم إدخال أنبوب التنفس في مجرى الهواء للمريض لمساعدته على التنفس.</p>
                </div>}
            extra={
              <div>
                <p><div className="paragraph-header">ماذا يعني النقل الجوي؟</div>على عكس القطيرات التنفسية ، تكون الجزيئات المحمولة في الهواء أصغر وتبقى في الهواء لفترات زمنية طويلة حيث يمكن أن تنتشر أبعد من 6 أقدام.</p>
                <p><div className="paragraph-header">لماذا تقول الأخبار أن الفيروس التاجي محمول جوا؟</div>قامت دراسة NEJM بإنشاء هباء من الفيروس التاجي بشكل مصطنع في أسطوانة دوارة ، حيث ظلت صالحة لمدة 3 ساعات. ومع ذلك ، لا تمثل الماكينة عالية السعال البشري العادي. في دراسة لم يراجعها الأقران (لم يتم تقييمها من قبل خبراء آخرين) ، أخذ الباحثون عينات من وجود فيروس تاجي في الهواء والأسطح في غرفة مستشفى كوفيد-19 للمرضى. 76.5 ٪ من جميع المتعلقات الشخصية كانت إيجابية (الهواتف المحمولة والمراحيض وأجهزة التحكم عن بعد وقضبان السرير ، وما إلى ذلك). بالنسبة لعينات الهواء ، كانت 63.2٪ إيجابية وتم جمع بعض العينات على مسافة تزيد عن 6 أقدام من المريض. ومع ذلك ، فإن وجود فيروس لا يعني تلقائيًا أنه يمكن أن يسبب المرض. على سبيل المثال ، قد تكون كمية الفيروس منخفضة جدًا بحيث لا تسبب العدوى. لم يثبت المؤلفون أن مكونات الفيروس التي اكتشفوها يمكن أن تسبب العدوى.</p>
              </div>}
          /> */}
          <Card 
            id="myth-4"
            thumbnail="/assets/img/shots/myth/myth-4.jpg" 
            title="إذا كنت أرتدي قفازات ، فهل أنا محمي من فيروس كورونا؟"
            content={
              <div>
                <p><span className="card-tag-false">لا</span>تساعد القفازات فقط إذا حافظت على احتياطات مثل عدم لمس العينين والأنف والفم وغسل اليدين بعد إزالة القفازات. يمكن أن يحدث التلوث المتبادل ، مما يعني أن القفازات المتسخة تلمسها ، ويمكن أن تنتشر إلى تلك الأسطح.</p>
              </div>}
          />
          <Card 
            id="myth-5"
            thumbnail="/assets/img/shots/myth/myth-5-ar.jpg" 
            title="هل يمكن للفيروس التاجي أن يعيش على الأسطح لأيام؟"
            content={
              <div>
                <p><span className="card-tag-true">نعم</span>تظهر <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973?query=featured_home" target="_blank">دراسة NEJM</a> أنه يمكن الكشف عن الفيروس التاجي على الورق المقوى لمدة تصل إلى 24 ساعة ، وعلى البلاستيك والفولاذ لمدة تصل إلى 72 ساعة في تجربة مخبرية.</p>
                <p>من المهم ملاحظة أن كمية الفيروس المتبقية أقل من 0.1٪ من مادة الفيروس الأولية. لا يعني وجود الفيروس تلقائيًا أنه يمكن أن يكون معديًا. نظرًا لأن كمية الفيروس تنخفض بسرعة مع مرور الوقت على كل سطح ، فإن خطر الإصابة بالعدوى من لمسها يجب أن ينخفض بمرور الوقت أيضًا.</p>
              </div>}
          />
          <Card 
            id="myth-6"
            thumbnail="/assets/img/shots/myth/myth-6.jpg" 
            title="هل يجب علي غسل اليدين بشكل متكرر أو تعقيم الأسطح إذا لم أغادر المنزل؟"
            content={
              <div>
                <p><span className="card-tag-true">نعم</span>يوصي مركز السيطرة على الأمراض <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/prevention.html" target="_blank">(CDC) بتنظيف وتطهير</a> الأسطح التي يتم لمسها بشكل متكرر يوميًا مثل مقابض الأبواب ومفاتيح الإضاءة والجداول والكونترتوب والهواتف ولوحات المفاتيح والمراحيض والصنابير والأحواض.</p>
                <p>أنت أو أحد أفراد الأسرة قد تكون مصابًا دون علم. على الرغم من أنك أكثر عرضة للإصابة بالعدوى عن طريق الاتصال الوثيق بشخص مريض أكثر من الأسطح الملوثة ، اتبع الاحتياطات إذا استطعت.</p>
              </div>}
          />
          <Card 
            id="myth-7"
            thumbnail="/assets/img/shots/myth/myth-7.jpg" 
            title="هل يزيد الإيبوبروفين من تفاقم المرض لدى الأشخاص المصابين بـ كوفيد-19؟"
            content={
              <div>
                <p><span className="card-tag-false">لا</span>لا توجد حاليًا أدلة علمية كافية لإثبات أن الأيبوبروفين (الأسماء التجارية Advil ، Motrin) يجعل أعراض كوفيد-19 أسوأ.</p>
                <p>لا توصي منظمة الصحة العالمية بعدم استخدام الإيبوبروفين (18 مارس 2020). أسيتامينوفين (تايلينول) هو حمى بديلة وأدوية مسكنة للألم. تقترح إدارة الأغذية والأدوية <a href="https://www.fda.gov/drugs/drug-safety-and-availability/fda-advises-patients-use-non-steroidal-anti-inflammatory-drugs-nsaids-covid-19" target="_blank">FDA</a> التحدث إلى مقدم الرعاية الصحية الخاص بك إذا كنت قلقًا بشأن تناول هذه الأدوية المضادة للالتهابات غير الستيرويدية (NSAIDs) والاعتماد على هذه الأدوية لعلاج الأمراض المزمنة.</p>
                </div>}
            extra={
            <div>
              <p>يندرج الإيبوبروفين تحت فئة من الأدوية تسمى <a href="https://www.goodrx.com/blog/are-advil-motrin-tylenol-bad-for-coronavirus-symptoms/" target="_blank">مضادات الالتهاب غير الستيروئيدية</a> التي تشمل الأسبرين والنابروكسين (أليف) وديكلوفيناك (كامبيا) وسيليكوكسيب (سيليبريكس) ، وتستخدم لعلاج الحمى والألم والالتهاب. كل دواء له مخاطره وفوائده. على سبيل المثال ، يمكن أن تسبب مضادات الالتهاب غير الستيروئيدية آثارًا جانبية للكلى والجهاز الهضمي. إن أخطر خطر للأسيتامينوفين هو الفشل الكبدي الناجم عن جرعة زائدة ، غالبًا عرضية. تم العثور على أسيتامينوفين في العديد من أدوية البرد والإنفلونزا الشائعة بدون وصفة طبية. يرجى قراءة جميع ملصقات الأدوية بعناية للتأكد من عدم تجاوز الجرعة اليومية القصوى.</p>
              <p><div className="paragraph-header">كيف بدأ نقاش الإيبوبروفين:</div>تشير <a href="https://jvi.asm.org/content/94/7/e00127-20" target="_blank">الأبحاث</a> إلى أن ACE2 ، وهو إنزيم في جسم الإنسان ، يوفر مكانًا على أسطح الخلايا لفيروس التاجي لربط الخلايا وإصابتها.</p>
              <p>في 11 مارس 2020 ، نشر الباحثون <a href="https://www.thelancet.com/journals/lanres/article/PIIS2213-2600(20)30116-8/fulltext" target="_blank">رسالة</a> في لانسيت لأمراض الجهاز التنفسي مع نظرية مفادها أن ارتفاع ACE2 قد يترافق مع الحالات الشديدة من كوفيد-19. في 11 مارس 2020 ، نشر الباحثون رسالة في لانسيت لأمراض الجهاز التنفسي مع نظرية مفادها أن ارتفاع ACE2 قد يترافق مع الحالات الشديدة من كوفيد-19.</p>
              <p>هذا كله تخميني ولم تظهر أي أدلة علمية أن نتائج الإيبوبروفين تزداد سوءًا بشكل مباشر في مرضى كوفيد-19. ومع ذلك ، في 14 مارس ، نشرت وزارة الصحة الفرنسية تحذيرًا من استخدام الإيبوبروفين في حمى كوفيد-19 ونصيحة وزير الصحة الفرنسي <a href="https://twitter.com/olivierveran/status/1238776545398923264" target="_blank">على تويتر</a> لتجنب الإيبوبروفين ومضادات الالتهاب غير الستيرويدية الأخرى لأنها قد تكون "عاملاً مشددًا". انتشرت نصيحته على نطاق واسع عبر الأخبار ، وبدأت القصص في إساءة إسناد الإيبوبروفين كأسباب رئيسية لوفيات كوفيد-19. في 16 مارس ، أوضح مؤلفو الفرضية الأصلية التي أثارت النقاش أن نظريتهم <a href="https://www.unibas.ch/en/News-Events/News/Uni-Research/Ibuprofen-and-COVID-19-Setting-the-record-straight.html" target="_blank">"لا تشكل توصية لاستخدام بعض الأدوية أو لا</a> بالنسبة للأشخاص الذين يعانون من أعراض كوفيد-19 ، نصحت منظمة الصحة العالمية في البداية بعدم استخدام الإيبوبروفين. في 18 مارس<a href="https://www.ema.europa.eu/en/news/ema-gives-advice-use-non-steroidal-anti-inflammatories-covid-19" target="_blank">EMA</a>, <a href="https://www.fda.gov/drugs/drug-safety-and-availability/fda-advises-patients-use-non-steroidal-anti-inflammatory-drugs-nsaids-covid-19" target="_blank">FDA</a>, <a href="https://twitter.com/WHO/status/1240409217997189128" target="_blank">WHO</a>,، منظمة الصحة العالمية نصحت بعدم تجنب مضادات الالتهاب غير الستيرويدية عند الحاجة.</p>
            </div>}
          />
          <Card 
            id="myth-8"
            thumbnail="/assets/img/shots/myth/myth-8.jpg" 
            title="هل يمنع التطبيب الذاتي بواسطة هيدروكسي كلوروكين وأزيثروميسين كوفيد-19؟"
            content={
              <div>
                <p><span className="card-tag-false">لا</span>لرجاء عدم التداوي الذاتي! لا تتناول الأدوية الموصوفة طبيًا إلا بناء على نصيحة الطبيب بسبب التفاعلات الدوائية الضارة والآثار الجانبية الخطيرة.</p>
                <p><a href="https://www.cdc.gov/coronavirus/2019-ncov/hcp/therapeutic-options.html" target="_blank">هيدروكسي كلوروكوين والكلوروكين</a> أدوية موصوفة عن طريق الفم لعلاج الملاريا والأمراض الالتهابية الأخرى مثل الذئبة والتهاب المفاصل الروماتويدي. أزيثروميسين هو مضاد حيوي شائع يستخدم لعلاج الالتهابات التي تسببها بعض البكتيريا. يرتبط كل من هيدروكسي كلوروكين وأزيثروميسين بآثار جانبية قلبية وقد يؤديان إلى اضطراب نظم القلب الذي يهدد الحياة ، حيث ينبض قلبك بسرعة خطيرة. هناك <a href="https://www.cdc.gov/coronavirus/2019-ncov/hcp/therapeutic-options.html" target="_blank">أبحاث جارية</a> لمعرفة ما إذا كانت هذه الأدوية قد تكون فعالة في علاج كوفيد-19 ولكن يُرجى تناول الأدوية الموصوفة طبقًا لنصيحة الأطباء.</p>
              </div>}
          />
          <Card 
            id="myth-9"
            thumbnail="/assets/img/shots/myth/myth-9.jpg" 
            title="هل المضادات الحيوية فعالة في منع وعلاج كوفيد-19؟"
            content={
              <div>
                <p><span className="card-tag-false">لا</span>وفقاً لمنظمة الصحة العالمية  <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">(WHO)</a>، تعالج المضادات الحيوية العدوى التي تسببها البكتيريا فقط ، وليس الفيروسات. لا يجب استخدام المضادات الحيوية للوقاية من الفيروسات أو معالجتها. ومع ذلك ، إذا كنت في المستشفى بسبب كوفيد-19 ، فقد تتلقى مضادات حيوية لأن العدوى المشتركة البكتيرية ممكنة.</p>
              </div>}
          />
          
          <Card 
            id="myth-11"
            thumbnail="/assets/img/shots/myth/myth-11.jpg" 
            title="هل الأشخاص الذين هم في سن الشباب يتمتعون بصحة جيدة من فيروس كورونا الجديد؟"
            content={
              <div>
                <p><span className="card-tag-false">لا</span>وفقا <a href="https://translate.google.com/translate?hl=en&prev=_t&sl=auto&tl=ar&u=https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">لمنظمة الصحة العالمية</a> ، "يمكن أن يصاب الناس من جميع الأعمار بفيروس التاجي الجديد.</p>
                <p>يبدو أن كبار السن والأشخاص الذين يعانون من حالات طبية موجودة مسبقًا (مثل الربو والسكري وأمراض القلب) أكثر عرضة للإصابة بالفيروس. توصي منظمة الصحة العالمية الأشخاص من جميع الأعمار باتخاذ خطوات لحماية أنفسهم من الفيروس. "</p>
              </div>}
            extra={
              <div>
                <p>من بين الحالات الـ 4،226 الأولى (12 فبراير - 16 مارس) في الولايات المتحدة الأمريكية ، أفادت مراكز مكافحة الأمراض والوقاية   <a href="https://translate.google.com/translate?hl=en&prev=_t&sl=auto&tl=ar&u=https://www.cdc.gov/mmwr/volumes/69/wr/mm6912e2.htm%23F2_down#F2_down" target="_blank">(CDC)</a>منها أن واحدًا من كل خمسة الذين تم إدخالهم إلى المستشفى تتراوح أعمارهم بين 20 و 44 عامًا. وحتى 12 أبريل ، تحدث حوالي نصف <a href="https://www.cdph.ca.gov/Programs/CID/DCDC/Pages/Immunization/ncov2019.aspx" target="_blank">حالات كوفيد-19 المؤكدة في كاليفورنيا</a> في الأشخاص الأصغر سنًا. من 50. على الرغم من انخفاض معدلات إماتة الحالات بين المرضى الأصغر سنا ، فقد مات العديد من الشباب الذين ليس لديهم مشاكل صحية موجودة من قبل. يجب على الجميع اتخاذ الاحتياطات اللازمة لحماية أنفسهم والآخرين.</p>
              </div>}
          />
          <Card 
            id="myth-12"
            thumbnail="/assets/img/shots/myth/myth-12.jpg" 
            title="هل سأدخل المستشفى إذا كان لدي كوفيد-19؟"
            content={
              <div>
                <p><span className="card-tag-false">لا</span>وفقاً لمراكز السيطرة على الأمراض والوقاية منها ، يعاني معظم الأشخاص من مرض خفيف ويمكنهم التعافي في المنزل. لا تغادر منزلك إلا للحصول على رعاية طبية. اطلب العناية الطبية إذا كنت تواجه صعوبة في التنفس ولكن اتصل بمقدم الرعاية الصحية أو المرفق أولاً. يرجى الاطلاع على صفحة "تعرف على كوفيد-19".</p>
              </div>}
          />
          <Card 
            id="myth-13"
            thumbnail="/assets/img/shots/myth/myth-13.jpg" 
            title="إذا تمكنت من حبس أنفاسي لمدة 10 ثوانٍ أو أكثر من دون السعال أو الشعور بعدم الراحة ، فهل هذا يعني أنني لا أملك كوفيد-19؟"
            content={
              <div>
                <p><span className="card-tag-false">لا</span>وفقًا لمركز السيطرة على الأمراض (CDC) ، يمكن أن يكون لديك الفيروس التاجي وليس لديك أعراض ويمكن أيضًا نقل الفيروس قبل إظهار أي أعراض. وفقًا <a href="https://translate.google.com/translate?hl=en&prev=_t&sl=auto&tl=ar&u=https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">لمنظمة الصحة العالمية</a> ، فإن "أكثر أعراض كوفيد-19 شيوعًا هي السعال الجاف والتعب والحمى. قد يصاب بعض الأشخاص بأشكال أكثر حدة من المرض ، مثل الالتهاب الرئوي. لا يمكنك تأكيد ذلك من خلال تمرين التنفس هذا ".</p>
              </div>}
          />
          <Card 
            id="myth-14"
            thumbnail="/assets/img/shots/myth/myth-14.jpg" 
            title="هل مجففات الأيدي ومصابيح التعقيم بالأشعة فوق البنفسجية فعالة في قتل الفيروس التاجي؟"
            content={
              <div>
                <p><span className="card-tag-false">لا</span>وفقاً <a href="https://translate.google.com/translate?hl=en&prev=_t&sl=auto&tl=ar&u=https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">لمنظمة الصحة العالمية</a> ، "مجففات الأيدي ليست فعالة في قتل الفيروس التاجي. لا يجب استخدام مصابيح الأشعة فوق البنفسجية لتعقيم اليدين أو مناطق أخرى من الجلد لأن الأشعة فوق البنفسجية يمكن أن تسبب تهيج الجلد. لحماية نفسك من الفيروس التاجي الجديد ، يجب عليك تنظيف يديك كثيرًا بالماء والصابون أو فرك اليد الذي يحتوي على الكحول. "</p>
              </div>}
          />
          <Card 
            id="myth-15"
            thumbnail="/assets/img/shots/myth/myth-15.jpg" 
            title="هل غسل أنفك بانتظام بالمحلول الملحي يمنع كوفيد-19؟"
            content={
              <div>
                <p><span className="card-tag-false">لا</span>وفقاً <a href="https://translate.google.com/translate?hl=en&prev=_t&sl=auto&tl=ar&u=https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">لمنظمة الصحة العالمية</a> ، "لا يوجد دليل على أن شطف الأنف بانتظام بمحلول ملحي يحمي الناس من الإصابة بالفيروس التاجي الجديد. هناك بعض الأدلة المحدودة على أن شطف أنفك بانتظام بالمحلول الملحي يمكن أن يساعد الأشخاص على التعافي بسرعة أكبر من نزلات البرد. ومع ذلك ، لم يثبت أن شطف الأنف بانتظام يمنع التهابات الجهاز التنفسي.</p>
              </div>}
          />
          <Card 
            id="myth-16"
            thumbnail="/assets/img/shots/myth/myth-16.jpg" 
            title="هل يمنع الدش أو الحمام الساخن كوفيد-19؟"
            content={
              <div>
                <p><span className="card-tag-false">لا</span>يجب أن تستحم أو تستحم ، ولكن من فضلك لا تستخدم الماء الساخن للغاية عن قصد. وفقًا <a href="https://translate.google.com/translate?hl=en&prev=_t&sl=auto&tl=ar&u=https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">لمنظمة الصحة العالمية</a> ، "تظل درجة حرارة الجسم الطبيعية حوالي 36.5 درجة مئوية إلى 37 درجة مئوية ، بغض النظر عن درجة حرارة الحمام أو الدش. في الواقع ، إن الاستحمام بماء ساخن للغاية يمكن أن يكون ضارًا ، لأنه يمكن أن يحرقك ".</p>
              </div>}
          />
          <Card 
            id="myth-17"
            thumbnail="/assets/img/shots/myth/myth-17.jpg" 
            title="هل يمنع الطقس البارد والثلوج كوفيد-19؟"
            content={
              <div>
                <p><span className="card-tag-false">لا</span>وفقاً <a href="https://translate.google.com/translate?hl=en&prev=_t&sl=auto&tl=ar&u=https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">لمنظمة الصحة العالمية</a> ، "لا يمكن للطقس البارد والثلج أن يقتل الفيروس التاجي الجديد. تبقى درجة حرارة الجسم الطبيعية حوالي 36.5 درجة مئوية إلى 37 درجة مئوية ، بغض النظر عن درجة الحرارة الخارجية أو الطقس ".</p>
              </div>}
          />
          <Card 
            id="myth-18"
            thumbnail="/assets/img/shots/myth/myth-18.jpg" 
            title="هل أكل الثوم يمنع كوفيد-19؟"
            content={
              <div>
                <p><span className="card-tag-false">لا</span>وفقاً <a href="https://translate.google.com/translate?hl=en&prev=_t&sl=auto&tl=ar&u=https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">لمنظمة الصحة العالمية</a> ، "الثوم غذاء صحي قد يحتوي على بعض الخصائص المضادة للميكروبات. ومع ذلك ، لا يوجد دليل من التفشي الحالي على أن أكل الثوم قد حمى الناس من فيروس التاجي الجديد. "</p>
              </div>}
          />
          <Card 
            id="myth-19"
            thumbnail="/assets/img/shots/myth/myth-19.jpg" 
            title="هل شرب الكحول يمنع كوفيد-19؟"
            content={
              <div>
                <p><span className="card-tag-false">لا</span>وفقاً <a href="https://translate.google.com/translate?hl=en&prev=_t&sl=auto&tl=ar&u=https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">لمنظمة الصحة العالمية</a> ، "إن شرب الكحول لا يحميك من كوفيد-19 ويمكن أن يكون خطيراً. يمكن أن يزيد الاستهلاك المتكرر أو المفرط للكحول من خطر حدوث مشاكل صحية ".</p>
              </div>}
          />
          <Card 
            id="myth-20"
            thumbnail="/assets/img/shots/myth/myth-20.jpg" 
            title="هل اللقاحات ضد الالتهاب الرئوي تقي من كوفيد-19؟"
            content={
              <div>
                <p><span className="card-tag-false">لا</span>وفقًا <a href="https://translate.google.com/translate?hl=en&prev=_t&sl=auto&tl=ar&u=https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">لمنظمة الصحة العالمية</a> ، "إن اللقاحات ضد الالتهاب الرئوي ، مثل لقاح المكورات الرئوية ولقاح المستدمية النزلية من النوع ب (Hib) ، لا توفر الحماية ضد الفيروس التاجي الجديد. الفيروس جديد ومختلف لدرجة أنه يحتاج إلى لقاح خاص به. على الرغم من أن هذه اللقاحات ليست فعالة ضد الفيروس التاجي الجديد ، يوصى بشدة بالتطعيم ضد أمراض الجهاز التنفسي لحماية صحتك. "</p>
              </div>}
          />
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Myth;
