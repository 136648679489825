import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Tips extends Component {
  
  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <h4 className="content-nav-caption">Table of Contents</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-1')}>Hau lo wasim han gut</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-2')}>Gutpla wei blo karamapim pes</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-3')}>Hau lo usim gloves</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-4')}>Ol wei blo cleanim samtin</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-5')}>Buyim kaikai na kargo blo haus</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-6')}>Wei blo lukautim kaikai</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('tips-7')}>Wei blo lukautim Packages na Mail</h4>
        </div>
        <div className="content-body">
          <Card 
            id="tips-1"
            thumbnail="/assets/img/shots/tips/tips-1.jpg" 
            title="Hau lo wasim han gut"
            content={
              <div>
                <p>Pasin blo wasim han klostu klostu em wei blo banisim sik COVID-19. Noken putim han lo ai, nus or maus blo yu bifo lo yu wasim han blo yu.</p>
                <hr />
                <p>
                  <div className="paragraph-header">Stretpla wei blo wasim han</div>
                  <ol className="content-list">
                    <li><div className="ol-index">1</div>Wetim han blo yu wantaim wara.</li>
                    <li><div className="ol-index">2</div>Putim soap lo han na rubim tupla han blo yu wantaim, tingim lo wasim namel lo ol finga blo yu, beksait lo han na ananit lo ol finganail tu.</li>
                    <li><div className="ol-index">3</div>Wasim han inap 20pla seconds (singim singsing ‘Happy Birthday” tupla taim.)</li>
                    <li><div className="ol-index">4</div>Wasim rausim soap lo han.</li>
                    <li><div className="ol-index">5</div>Dryim han wantaim towel or larim em yet ie dry.</li>
                  </ol>
                  <h5 className="content-source"><a href="https://www.cdc.gov/handwashing/when-how-handwashing.html" target="_blank">Toksave ie kam lo CDC</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/CSJO52qZLbs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p>
                  <div className="paragraph-header">Yu inap wasim han blo yu long wanem taim?</div>
                  <ul className="content-list">
                    <li>Wasim han klostu klostu</li>
                    <li>Taim yu kambek lo haus, behain lo yu bin go lo pablik ples.</li>
                    <li>
                      <div>Behain lo yu holim ol samthin weh ol narapla manmeri ie holim tu.</div>
                      <ul>
                        <li>Kain samthin olsem: handle blo dua, table, pump blo putim gas, trolley blo shoppin, hap blo putim moni olsem cash regista na masin blo putim pin na pay, button blo lift na kain olsem.</li>
                      </ul>
                    </li>
                    <li>Bifo, namel na behain lo redim kaikai.</li>
                    <li>Bifo lo yu kaikai.</li>
                    <li>Behain lo yu kus na blowim nus blo yu.</li>
                    <li>Bifo na behain lo yu rausim face mask na gloves.</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">Sapos ie nogat soap na wara, usim alcohol-based han sanitizer.</div>
                  <ul className="content-list">
                    <li>Han sanitizer ie no save kilim olgeta kainkain binatang.</li>
                    <li>Ie shud gat alcohol lo 60% mak.</li>
                    <li>Rubim gel lo olgeta hap blo han blo yu, namel lo ol finga na arere lo ol finga.</li>
                    <li>Rubim tupla han blo yu wantaim inap ol ie <strong>dry</strong></li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-2"
            thumbnail="/assets/img/shots/tips/tips-2.jpg" 
            title="Gutpla wei blo karamapim pes"
            content={
              <div>
                <p>
                  <div className="paragraph-header">Gutpla wei blo usim ol sumtin blo karamapim pes</div>
                  <ul className="content-list">
                    <div className="content-list-label-positive">Ol sumtin blo mekim</div>
                    <li>Yu inap pulim win gut taim yu karamapim pes.</li>
                    <li>Yu mas karamapim nus na maus wantaim (karamapim antap lo nus go daun tamblo lo chin blo yu)</li>
                    <li>Taim yu laik rausim karamap blo pes, yu mas rausim lo sait pastaim.</li>
                    <li>Wasim na dryim karamap blo pes olgeta dei.</li>
                    <li>Wasim han olgeta taim behain lo yu rausim karamap blo pes.</li>
                    <li>Putim ol karamap blo pes insait lo wanpla bag or bin inap yu wasim ol.</li>
                  </ul>
                  <ul className="content-list">
                    <div className="content-list-label-negative">Noken</div>
                    <li>Holim front blo karamap blo pes, bikos em hap weh ie dirty nogut tru.</li>
                    <li>Surukim karamap blo pes ie go ananit lo chin or taitim raunim nek blo yu.</li>
                    <li>Larim karamap blo pes ie slack lo ol sait.</li>
                    <li>Rausim karamap blo pes taim han glove blo yu ie stap yet lo han.</li>
                    <li>Usim surgical mask moa lo tupla taim.</li>
                  </ul>
                  <h5 className="content-source">Sources: <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a>; <a href="https://www.nytimes.com/2020/04/08/well/live/coronavirus-face-mask-mistakes.html#click=https://t.co/GkBNJAuw1V" target="_blank">The New York Times</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/m4O-3wQQjpA?start=118" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p>
                  <div className="paragraph-header">Karamap blo pes ie no kisim ples blo ol narapla wei blo stopim binatang nogut</div>
                  <div>Sapos yu laikm putim karamap blo pes or wearim glove, yu mas stil mekim disla:</div>
                  <ul className="content-list">
                    <li>Wasim han gut</li>
                    <li>Stap longwei lo ol narapla lain, mak olsem 6 feet.</li>
                  </ul>
                  <div>Ol sumtin blo abusim binatang (mask blo pes, han glove, na kain olsem) ie ken halpim lo daunim binatang lo go lo narapla lain, tasol em ken mekim ol manmeri ting olsem ol nonap kisim binatang. Ol samthin blo aburusim binatang iken touchim hap weh binatang ie stap long em. Sapos yu no usim gut ol samthin blo aburusim binatang, yu ken kisim binatang.</div>
                </p>
              </div>}
            extra={
              <div>
                <p>
                  <div className="paragraph-header">Why na CDC ie laikim ol manmeri lo usim samthin blo karamapim pes lo publik?</div>
                  <div>Ol <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover.html" target="_blank">CDC</a> ie tok olsem yu ken spreadim sik coronavirus lo taim yu no pilim sik (ol kolim olsem “asymptomatic”. Taim yu wearim samthin blo karamapim pes na taim yu stap longwei olsem mak blo 6ft long ol narapla lain, yu ken halpim lo stopim sik coronavirus lo spread insait lo kommuniti blo yu.</div>
                  <ul className="content-list">
                    <div>Displa ol lain mas noken usim sumtin blo karamapim pes!</div>
                    <li>Ol pikinini ananit lo 2-pla krismas</li>
                    <li>Ol lain husait ie painim hat lo pulim win</li>
                    <li>Ol lain husait ie hap indai </li>
                    <li>Ol lain husait ino inap lo rausim karamap blo pes ol yet</li>
                  </ul>
                </p>
                <p>Insait lo kantri US, CDC ie tok stron lo usim kain samtin olsem lapun t-shirt or bandana. Plis lukim CDC wei blo mekim sumtin blo karamapim pes wantaim t-shirt na bandana. </p>
                <p><strong>Yu no nidim surgical mask or N-95 respirator.</strong> CDC toktok stron olsem ie nogat nid lo buyim surgical mask na N-95 respirator bikos ol nogat inap na ol ie sot lo US.</p>
              </div>
            }
          />
          <Card 
            id="tips-3"
            thumbnail="/assets/img/shots/tips/tips-3.jpg" 
            title="Hau lo usim gloves"
            content={
              <div>
                <p>Yu no nidim glove lo banisim yu lo binatang taim yu go lo publik hap. Tasol, sapos yu laik wearim glove, displa em sampla skul toktok.</p>
                <p>
                  <ul className="content-list">
                    <div className="content-list-label-positive">Ol samtin blo mekim</div>
                    <li>Wasim han bifo na behain stret lo yu rausim gloves.</li>
                    <li>Toromoi na noken usim gen plastic gloves</li>
                    <li>Ol glove ol mekim wantaim fabric, wasim ol gut wantaim soap na wara bifo lo usim ol gen.</li>
                  </ul>
                  <ul className="content-list">
                    <div className="content-list-label-negative">Noken</div>
                    <li>Holim pes, maus, nus na ai blo yu.</li>
                    <li>Putim han insait lo pocket blo yu.</li>
                    <li>Usim phone blo yu.</li>
                  </ul>
                  <h5 className="content-source">Sources: <a href="https://www.kqed.org/science/1961243/coronavirus-heres-how-to-use-gloves-properly-to-help-prevent-infection" target="_blank">KQED</a></h5>
                </p>
                <div className="youtube-embed">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/TnLCYqk20YU?start=116" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>}
          />
          <Card 
            id="tips-4"
            thumbnail="/assets/img/shots/tips/tips-4.jpg" 
            title="Ol wei blo cleanim samtin"
            content={
              <div>
                <p>Wok painim aut ie soim olsem,holim ol sumtin or ples igat binatang pinis ino wanpla rot tasol blo kisim COVID-19. Yu no nap kisim sik sapos yu holim ol sumtin weh binantang ie pas long ol. Tasol, mak blo yu kisim sik ie antap moa sapos yu gat binatang lo han na yu holim ai, nus na maus blo yu. Klinim antap lo ol sumtin na wasim han behain lo cleanim ples lo daunim mak blo kisim sik.</p>
                <hr />
                <p>
                  <div className="paragraph-header">Binatang blo sik Coronavirus stap antap lo ol samtin lo hamaspla hour or dei?</div>
                  <div className="content-table">
                    <div className="content-table-row">
                      <div className="content-table-column">
                        <h3 className="content-table-heading">4pla hour</h3>
                        <h4 className="content-table-caption">Copper</h4>
                      </div>
                      <div className="content-table-column">
                        <h3 className="content-table-heading">24pla hour</h3>
                        <h4 className="content-table-caption">Cardboard</h4>
                      </div>
                    </div>
                    <div className="content-table-row">
                      <div className="content-table-column">
                        <h3 className="content-table-heading">2-3pla dei</h3>
                        <h4 className="content-table-caption">Plastic</h4>
                      </div>
                      <div className="content-table-column">
                        <h3 className="content-table-heading">2-3pla dei</h3>
                        <h4 className="content-table-caption">Stainless Steel</h4>
                      </div>
                    </div>
                  </div>
                  <div>Wok painim aut ie soim olsem coronavirus ie stap antap lo ol samthin tasol namba blo em ie go daun behain lo sampla taim na ie mekim hard lo givim sik. (Wok painim aut ie stap lo <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973" target="_blank">The New England Journal of Medicine</a> 3/17/2020)</div>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">Mi ken usim wanem lo klinim ples or sumtin weh binatang iken stap?</div>
                  <ul className="content-list">
                    <div>Plis go lo website blo ol CDC lo kisim moa toksave lo hau lo klinim kainkain ples. CDC givim toksave lo displa:</div>
                    <li>Disinfectants ol EPA givim tok orait <a href="https://www.cnn.com/2020/03/05/health/epa-disinfectants-coronavirus-trnd/index.html" target="_blank">(see list here)</a></li>
                    <li>
                      <div>Bleach ol ie mixim lo mekim bleach wara</div>
                      <ul>
                        <li>Putim 5pla teaspoon (⅓ cup) bleach lo olgeta  gallon wara</li>
                        <li><strong>OR</strong> putim 4pla teaspoon lo olgeta quart wara.</li>
                        <li>Noken klinim ples wantaim bleach yu kisim stret lo kontaina; mixim wantaim wara pastaim.</li>
                        <li>Checkim olsem bleach ie no expire yet.</li>
                        <li>Yu yet NOKEN mixim bleach wantaim ammonia or narapla ol marasin blo klinim ples, lo haus.</li>
                      </ul>
                    </li>
                    <li>Alcohol solution mas gat mak olsem 70% alcohol insait.</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-5"
            thumbnail="/assets/img/shots/tips/tips-5.jpg" 
            title="Buyim kaikai na kargo blo haus"
            content={
              <div>
                <p><strong>Yu inap lo kisim sik lo ol narapla manmeri lo stoa na ie no lo ol kaikai or kargo blo haus.</strong> Ol <a href="https://www.cdc.gov/coronavirus/2019-ncov/daily-life-coping/essential-goods-services.html" target="_blank">CDC</a> ie tok “ie nogat wok painim aut ie showim olsem kaikai or ol karamap blo kaikai ie spreadim binatang lo United States”.</p>
                <p>
                  <div className="paragraph-header">Sampla gutpla wei blo buyim kaikai na kargo blo haus</div>
                  <ul className="content-list">
                    <li>Noken go lo stoa sapos yu gat sik kus or yu bin stap klostu lo ol lain husait gat sik kus.</li>
                    <li>Traim lo go lo stoa lo buyim kaikai na kargo blo haus wanpla taim insait lo wanpla wik or wanpla taim olgeta tupla wik.</li>
                    <li>Traim lo makim wanpla man/meri tasol lo femli lo go buyim kaikai na kargo blo haus</li>
                    <li>Sapos ie orait, larim ol pikinini stap lo haus wantaim wanpla bikman/meri.</li>
                    <li>Raitim wanpla list na karim go lo stoa so yu noken stap longtaim insait lo stoa.</li>
                    <li>Wearim wanpla karamap blo pes na stap longwei lo ol narapla.</li>
                    <li>Wipim ol handol blo shopping cart.</li>
                    <li>Sapos yu inap, usim no-touch wei blo pay.</li>
                    <li>Behaim lo yu lusim stoa, usim han sanitizer.</li>
                    <li>Taim yu kamap lo haus, wasim han wantaim soap na wara inap lo 20pla seconds.</li>
                    <li>Helpim ol femli, poroman or neiba husait ie painim hard lo go buyim kaikai na kargo blo haus blo ol yet</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <div className="paragraph-header">Yu no nid lo wasim olgeta kaikai na kargo blo haus blo yu.</div>
                  <div>Bikpla samthin olsem yu mas wasim han blo yu na ol samthin yu holim behain lo buyim kaikai na kargo blo haus. Noken usim soap taim yu wasim ol kaikai olsem fruits na vegetables.</div>
                  <ul className="content-list">
                    <div>Tasol, <a href="https://www.npr.org/sections/health-shots/2020/04/12/832269202/no-you-dont-need-to-disinfect-your-groceries-but-here-s-to-shop-safely" target="_blank">sapos yu laik stret lo klinim olgeta kaikai na kargo blo haus yu buyim</a>:</div>
                    <li>Tingim lo rausim olgeta karamap ie stap arasait lo ol kargo </li>
                    <li>Usim marasin spray na wipes lo klinim ol sumtin olsem glass jar na ol can.</li>
                    <li>Usim soap na wara lo klinim ol plastic na cardboard karamap. Displa bai stopim marasin lo go insait lo kaikai blo yu.</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-6"
            thumbnail="/assets/img/shots/tips/tips-6.jpg" 
            title="Wei blo lukautim kaikai"
            content={
              <div>
                <p><a href="https://www.fda.gov/food/food-safety-during-emergencies/shopping-food-during-covid-19-pandemic-information-consumers" target="_blank">FDA</a> ie tok olsem “COVID-19 binatang ie save kalap go lo narapela ol manmeri na givim sik, na ie no olsem ol narapla sik weh binatang save stap lo kaikai na mekim manmeri sik.</p>
                <p>
                  <ul className="content-list">
                    <li>Sapos narapla lain bringim kaikai yu buyim, askim ol lo lusim lo haus dua blo yu.</li>
                    <li>Rausim kaikai lo kontaina blo restaurant na putim lo kontaina blo yu.</li>
                    <li>Sapos yu laik usim ol kontaina  blo ol, wasim wantaim wara na soap pastem bifo lo yu usim.</li>
                    <li>Wasim han blo yu bifo lo yu holim kaikai.</li>
                  </ul>
                </p>
              </div>}
          />
          <Card 
            id="tips-7"
            thumbnail="/assets/img/shots/tips/tips-7.jpg" 
            title="Wei blo lukautim Packages na Mail"
            content={
              <div>
                <p>Insait lo wanpla wok painim aut blo NEJM, ol bin painim liklik mak blo coronavirus binatang stap antap lo cardboard inap 24pla hour. Tasol mak blo displa binatang ie no mean olsem em ken mekim manmeri sik. Ol lain lo <a href="https://www.cdc.gov/coronavirus/2019-ncov/faq.html" target="_blank">CDC</a> ie tok olsem mail, products or packing nonap givim sik long manmeri.</p>
                <p>
                  <ul className="content-list">
                    <li>Behain lo yu opim package na mail, wasim han blo yu.</li>
                    <li>Klinim scissors na ol narapela hap weh yu holim.</li>
                  </ul>
                </p>
              </div>}
          />
          <h4 className="content-tips-disclaimer">Mipla luksave olsem olgeta manmeri ino inap lo usim skull tok mipla givim lo wankain wei. Olgeta manmeri ino wankain tasol mipla hope olsem ol tok piksa mipla givim yu bai helpim yu. Pls sharim tingting blo yu lo hau disla ol skul tok ie halpim yu.</h4>
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Tips;
