import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Learn extends Component {

  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <h4 className="content-nav-caption">目录</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-1')}>概述</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-2')}>新冠病毒（COVID-19）是如何传播的？</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-3')}>症狀</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-4')}>如果我不舒服，应该做什么呢？</h4>
        </div>
        <div className="content-body">
          <Card 
            id="learn-1"
            icon="/assets/img/icons/info.svg" 
            title="概述"
            content={
              <div>
                <p>冠状病毒是一种可以引起常见的呼吸道疾病（例如感冒）的传染病病毒。</p>
                <p>在2019年，发现了一种新的冠状病毒。 SARS-CoV-2是该病毒的科学名称。它引起的疾病被称为 2019年新型冠状病毒病（COVID-19)。</p>
              </div>}
          />
          <Card 
            id="learn-2"
            icon="/assets/img/icons/spread.svg" 
            title="新冠病毒（COVID-19）是如何传播的？"
            content={
              <div>
                <p><div className="paragraph-header">人与人传染</div>新冠病毒主要由呼吸飞沫传播的。如果您位于感染者的6英尺范围内，他们的咳嗽，喷嚏或说话所产生的呼吸飞沫会进入您的嘴，鼻子，眼睛。</p>
                <p><div className="paragraph-header">触摸被污染的表面</div>触摸完有病毒的表面然后摸嘴、鼻子、眼睛是有可能将病毒传染给自己。经常触摸的表面包括门把手、电灯开关、手机、桌面、键盘、厕所冲水的按钮、洗手盘、水龙头和水槽。</p>
              </div>}
          />
          <Card 
            id="learn-3"
            icon="/assets/img/icons/symptoms.svg" 
            title="症狀"
            content={
              <div>
                <div className="content-symptom-container">
                  <ul className="content-symptom-category">
                    <div className="content-symptom-title">常见</div>
                    <li className="content-symptom-item">发烧（100.4°F，38°C)</li>
                    <li className="content-symptom-item">干咳</li>
                    <li className="content-symptom-item">呼吸急促</li>
                  </ul>
                  <ul className="content-symptom-category">
                    <div className="content-symptom-title">不常见</div>
                    <li className="content-symptom-item">疲劳</li>
                    <li className="content-symptom-item">肌肉疼痛</li>
                    <li className="content-symptom-item">喉咙痛</li>
                    <li className="content-symptom-item">流鼻涕</li>
                    <li className="content-symptom-item">反胃</li>
                    <li className="content-symptom-item">腹泻</li>
                    <li className="content-symptom-item">失去嗅觉和味觉</li>
                  </ul>
                </div>
                <div className="content-symptom-caption">很多感染者都可能没有症状</div>
              </div>}
          />
          <Card 
            id="learn-4"
            icon="/assets/img/icons/sick.svg" 
            title="如果我不舒服，应该做什么呢？"
            content={
              <div>
                <p>根据CDC的报告，大多数患者的症状都较为轻微，并且能够留在家中疗理。除了去看医生以外，请避免外出。如果你呼吸有困难请立刻就医。在你前往医院前，请先通知该医院或者你的医生。</p>
                <hr />
                <p>
                  <ul className="content-list">
                    <div>如果您出现以下任何一个紧急症状请立刻致电 911:</div>
                    <li>呼吸困难</li>
                    <li>胸口持续疼痛或压逼感</li>
                    <li>精神恍惚或意识不清</li>
                    <li>嘴唇或脸色发紫</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <ul className="content-list">
                    <div>如想阅读更多信息或有需要协助你查询医疗或社区服务, <strong>请致电给您当地公共卫生局请。</strong>请按<a href="https://covid19.ca.gov/state-local-resources/#top" target="_blank">网址</a>来查询加州各县公共卫生处的联络资料</div>
                    <li><a href="https://www.sccgov.org/sites/phd/DiseaseInformation/novel-coronavirus/Pages/home.aspx" target="_blank">聖塔克拉拉縣 (Santa Clara County):</a> 有关新冠病毒和有关当地社区资源（例如食物、住所、咨询、就业援助、托儿服务、长者护理服务等等) 的资料请致电211 (每天24小时服务）</li>
                    <li><a href="https://sf.gov/topics/coronavirus-covid-19" target="_blank">三藩市 (San Francisco):</a> 如要查询、需要寻找护理服务或其他社区资源请致电 311 (在市内）或 415-701-2311 (在市外) (每天24小时服务）</li>
                    <li><a href="http://publichealth.lacounty.gov/media/Coronavirus/" target="_blank">洛杉磯 (Los Angeles):</a> 如要查询、需要寻找护理服务或其他社区资源请致电 211 (每天24小时服务）</li>
                    <li><a href="https://covid19info.ocgov.com/" target="_blank">橙縣 (Orange County):</a> 如要查询、需要寻找护理服务或其他社区资源请致电 211 (每天24小时服务）</li>
                    <li><a href="https://www.sandiegocounty.gov/coronavirus.html" target="_blank">聖地牙哥 (San Diego):</a> 如要查询、需要寻找护理服务或其他社区资源或者你现时没有医疗保险 请致电 211 (每天24小时服务)</li>
                    <li><a href="https://www.co.fresno.ca.us/departments/public-health/covid-19" target="_blank">福雷斯諾 (Fresno):</a> 请致电 (559) 600-4636 (8a-5p)</li>
                    <li><a href="http://www.acphd.org/2019-ncov.aspx" target="_blank">亞拉米達縣 （Alameda County):</a> 请致电 (510) 268-2101</li>
                    <li><a href="https://www.coronavirus.cchealth.org/" target="_blank">康特拉科斯塔縣 (Contra Costa County):</a> 请致电 844-729-8410 (早上8时至下午5时服务）</li>
                  </ul>
                </p>
                <hr />
                <p>如想知道家中有人患上新冠病毒，<a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/disinfecting-your-home.html" target="_blank">家人如何能够减低感染病毒的风险</a>，请往CDC网站阅读详细资料。</p>
              </div>}
          />
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Learn;
