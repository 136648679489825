import React, { Component } from 'react'
import classnames from 'classnames'

import Button from '../Components/Button'
import ClipboardButton from '../Components/ClipboardButton'

class ShareModal extends Component {

  componentDidMount() {
    window.Kakao.init('8c22c0e3b6141547b03badeeb6f137cd');
  }

  sendKakao = (e) => {
    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: 'COVID-19 Fact Check',
        description: 'ਕੋਰੋਵਿਡ -19 ਫੈਕਟ ਚੈੱਕ ਵੈਬਸਾਈਟ ਤੇ ਕੋਰੋਨਵਾਇਰਸ ਬਾਰੇ ਮਿੱਥ ਅਤੇ ਤੱਥ ਸਿੱਖੋI',
        imageUrl:
          'http://www.covid19factcheck.com/assets/img/thumbnails/social_square.png',
        link: {
          mobileWebUrl: 'http://www.covid19factcheck.com/pa/',
          androidExecParams: 'test',
        },
      },
      success: function(response) {
        console.log(response);
      },
      fail: function(error) {
        console.log(error);
      }
    });
  }

  render() {
    const { active, toggleShareModalActive } = this.props;

    return (
      <div className={classnames('modal', 'share-modal', {'modal-active': active})}>
        <div className="modal-close" onClick={toggleShareModalActive}><img className="fit-parent" src="/assets/img/icons/close.svg" /></div>
        <div className="modal-content">
          <h1 className="modal-title">ਗਿਆਨ ਫੈਲਾਓ</h1>
          <p className="modal-caption">ਕੋਵਿਡ-19 ਫੈਕਟ ਚੈੱਕ ਨੂੰ ਆਪਣੇ ਦੋਸਤਾਂ ਅਤੇ ਪਰਿਵਾਰ ਨਾਲ ਸਾਂਝਾ ਕਰੋ ਤਾਂ ਜੋ ਅਸੀਂ ਸਾਰੇ ਕੋਰੋਨਾਵਾਇਰਸ ਦੇ ਫੈਲਣ ਨੂੰ ਰੋਕਣ ਦਾ ਸਭ ਤੋਂ ਵਧੀਆ ਤਰੀਕਾ ਜਾਣ ਸਕੀਏI</p>
          <div className="share-modal-action-container">
            <div className="share-modal-action">
              <a href="https://twitter.com/intent/tweet?text=%E0%A8%95%E0%A9%8B%E0%A8%B0%E0%A9%8B%E0%A8%B5%E0%A8%BF%E0%A8%A1%20-19%20%E0%A8%AB%E0%A9%88%E0%A8%95%E0%A8%9F%20%E0%A8%9A%E0%A9%88%E0%A9%B1%E0%A8%95%20%E0%A8%B5%E0%A9%88%E0%A8%AC%E0%A8%B8%E0%A8%BE%E0%A8%88%E0%A8%9F%20%E0%A8%A4%E0%A9%87%20%E0%A8%95%E0%A9%8B%E0%A8%B0%E0%A9%8B%E0%A8%A8%E0%A8%B5%E0%A8%BE%E0%A8%87%E0%A8%B0%E0%A8%B8%20%E0%A8%AC%E0%A8%BE%E0%A8%B0%E0%A9%87%20%E0%A8%AE%E0%A8%BF%E0%A9%B1%E0%A8%A5%20%E0%A8%85%E0%A8%A4%E0%A9%87%20%E0%A8%A4%E0%A9%B1%E0%A8%A5%20%E0%A8%B8%E0%A8%BF%E0%A9%B1%E0%A8%96%E0%A9%8BI&tw_p=tweetbutton&url=http%3A%2F%2Fwww.covid19factcheck.com%2Fpa%2F" target="_blank">
                <img className="fit-parent" src="/assets/img/icons/share/twitter.png" />
              </a>
            </div>
            <div className="share-modal-action">
              <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=http://www.covid19factcheck.com/pa/" target="_blank">
                <img className="fit-parent" src="/assets/img/icons/share/facebook.png" />
              </a>
            </div>
            <div className="share-modal-action">
              <a href="https://wa.me/?text=%E0%A8%95%E0%A9%8B%E0%A8%B0%E0%A9%8B%E0%A8%B5%E0%A8%BF%E0%A8%A1%20-19%20%E0%A8%AB%E0%A9%88%E0%A8%95%E0%A8%9F%20%E0%A8%9A%E0%A9%88%E0%A9%B1%E0%A8%95%20%E0%A8%B5%E0%A9%88%E0%A8%AC%E0%A8%B8%E0%A8%BE%E0%A8%88%E0%A8%9F%20%E0%A8%A4%E0%A9%87%20%E0%A8%95%E0%A9%8B%E0%A8%B0%E0%A9%8B%E0%A8%A8%E0%A8%B5%E0%A8%BE%E0%A8%87%E0%A8%B0%E0%A8%B8%20%E0%A8%AC%E0%A8%BE%E0%A8%B0%E0%A9%87%20%E0%A8%AE%E0%A8%BF%E0%A9%B1%E0%A8%A5%20%E0%A8%85%E0%A8%A4%E0%A9%87%20%E0%A8%A4%E0%A9%B1%E0%A8%A5%20%E0%A8%B8%E0%A8%BF%E0%A9%B1%E0%A8%96%E0%A9%8BI%20http%3A%2F%2Fwww.covid19factcheck.com%2Fpa%2F" data-action="share/whatsapp/share" target="_blank">
                <img className="fit-parent" src="/assets/img/icons/share/whatsapp.png" />
              </a>
            </div>
            <div className="share-modal-action">
              <a onClick={this.sendKakao}>
                <img className="fit-parent" src="/assets/img/icons/share/kakaotalk.png" />
              </a>
            </div>
            <div className="share-modal-action">
                <a href="https://social-plugins.line.me/lineit/share?url=http%3A%2F%2Fwww.covid19factcheck.com%2Fpa%2F" target="_blank">
                  <img className="fit-parent" src="/assets/img/icons/share/line.png" />
                </a>
            </div>
            <div className="share-modal-action">
              <a href="mailto:?body=%E0%A8%95%E0%A9%8B%E0%A8%B0%E0%A9%8B%E0%A8%B5%E0%A8%BF%E0%A8%A1%20-19%20%E0%A8%AB%E0%A9%88%E0%A8%95%E0%A8%9F%20%E0%A8%9A%E0%A9%88%E0%A9%B1%E0%A8%95%20%E0%A8%B5%E0%A9%88%E0%A8%AC%E0%A8%B8%E0%A8%BE%E0%A8%88%E0%A8%9F%20%E0%A8%A4%E0%A9%87%20%E0%A8%95%E0%A9%8B%E0%A8%B0%E0%A9%8B%E0%A8%A8%E0%A8%B5%E0%A8%BE%E0%A8%87%E0%A8%B0%E0%A8%B8%20%E0%A8%AC%E0%A8%BE%E0%A8%B0%E0%A9%87%20%E0%A8%AE%E0%A8%BF%E0%A9%B1%E0%A8%A5%20%E0%A8%85%E0%A8%A4%E0%A9%87%20%E0%A8%A4%E0%A9%B1%E0%A8%A5%20%E0%A8%B8%E0%A8%BF%E0%A9%B1%E0%A8%96%E0%A9%8BI%20http%3A%2F%2Fwww.covid19factcheck.com%2Fpa%2F">
                <img className="fit-parent" src="/assets/img/icons/share/mail.png" />
              </a>
            </div>
          </div>
          <ClipboardButton value="http://www.covid19factcheck.com/pa/" success="ਲਿੰਕ ਕਾਪੀ ਹੋ ਗਿਆ ਹੈ! 🎉">
            <Button className="btn-share" label="ਸ਼ੇਅਰ ਕਰਨ ਵਾਲੇ ਲਿੰਕ ਨੂੰ ਕਾਪੀ ਕਰੋ" imgURL="/assets/img/icons/link.svg" />
          </ClipboardButton>
        </div>
      </div>
    )
  }
}

export default ShareModal