import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Learn extends Component {

  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <h4 className="content-nav-caption">Table of Contents</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-1')}>Toksave</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-2')}>COVID-19 save kalap go lo narapla lain lo wanem rot?</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-3')}>Taim yu sik, bai yu pilim olsem wanem</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-4')}>Bai mi mekim wanem sapos mi pilim sik?</h4>
        </div>
        <div className="content-body">
          <Card 
            id="learn-1"
            icon="/assets/img/icons/info.svg" 
            title="Toksave"
            content={
              <div>
                <p>Coronavirus em wanpla femli blo ol binatang ie save kamapim sik blo pulim win, olsem kus.</p>
                <p>Ol bin painim wanpla niupla coronavirus lo 2019. SARS-CoV-2 em scientific nem blo displa binatang na sik weh em ie kamapim em ol kolim lo coronavirus disease 2019 (COVID-19).</p>
              </div>}
          />
          <Card 
            id="learn-2"
            icon="/assets/img/icons/spread.svg" 
            title="COVID-19 save kalap go lo narapla lain lo wanem rot?"
            content={
              <div>
                <p><div className="paragraph-header">Lo wanpla man/meri go lo narapla.</div>Bikpla rot weh COVID-19 save kalap go lo ol narapla lain em taim ol kus na sampla droplet ie go lo ol narapla. Sapos yu no stap 6 feet longwei lo wanpla husait ie gat sik, taim em kus or toktok, dispela ol droplet ie ken go lo maus, nus, ai na sumpla taim yu ken pulim win wantaim sampla droplet blo disla sik tu.</p>
                <p><div className="paragraph-header">Antap blo ol samthin whe ie gat binatang long ol</div>Yu ken kisim sik sapos yu holim ol samthin whe ie gat binatang lo ol na yu holim maus, nus, or ai blo yu. Ol sumtin weh manmeri save holim klostu klostu olsem handle blo dua, switch blo light, cellphone, table, keyboard, toilet, faucet na sink.</p>
              </div>}
          />
          <Card 
            id="learn-3"
            icon="/assets/img/icons/symptoms.svg" 
            title="Taim yu sik, bai yu pilim olsem wanem"
            content={
              <div>
                <div className="content-symptom-container">
                  <ul className="content-symptom-category">
                    <div className="content-symptom-title">Planti taim</div>
                    <li className="content-symptom-item">Skin hot (100.4°F, 38°C)</li>
                    <li className="content-symptom-item">Dry kus</li>
                    <li className="content-symptom-item">Sotwin</li>
                  </ul>
                  <ul className="content-symptom-category">
                    <div className="content-symptom-title">Narapela</div>
                    <li className="content-symptom-item">Kol</li>
                    <li className="content-symptom-item">Skin pen</li>
                    <li className="content-symptom-item">Nek ie sua</li>
                    <li className="content-symptom-item">Painim hat lo smelim or tastim sumtin</li>
                    <li className="content-symptom-item">Bodi ie no stron</li>
                    <li className="content-symptom-item">Nus kus</li>
                    <li className="content-symptom-item">Pilim taraut</li>
                    <li className="content-symptom-item">Pekpek wara</li>
                  </ul>
                </div>
                <div className="content-symptom-caption">Planti lain husait gat binatang ken stap orait tasol na ol ie nonap lo pilim wanpla mak blo sik.</div>
              </div>}
          />
          <Card 
            id="learn-4"
            icon="/assets/img/icons/sick.svg" 
            title="Bai mi mekim wanem sapos mi pilim sik?"
            content={
              <div>
                <p>CDC ie toksave olsem planti lain husait pilim liklik sik tasol ken stap lo haus blo ol na bai ol ie orait. Stap lo haus, tasol sapos yu nidim, yu ken lusim haus lo go kisim helpim lo hausik. Kisim helpim lo hausik sapos yu pilim sotwin na painim hat lo pulim win, tasol noken lustingting lo ringim hausik pastem.</p>
                <hr />
                <p>
                  <ul className="content-list">
                    <div>Ringim 911 sapos yu gat bikpla emergency olsem:</div>
                    <li>Sotwin</li>
                    <li>Boros pen</li>
                    <li>Yu paol or painim hat lo save wanem samthin woklo kamap lo yu</li>
                    <li>Lips na pes blo yu tanim go blue</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <ul className="content-list">
                    <div>Lo moa toksave or helpim lo painim hausik or community resources, <strong>ringim county publik helt department blo yu.</strong> Click <a href="https://covid19.ca.gov/state-local-resources/#top" target="_blank">lo hia</a> lo kisim moa toksave lo olgeta publik helt department blo counties insait lo California.</div>
                    <li><a href="https://www.sccgov.org/sites/phd/DiseaseInformation/novel-coronavirus/Pages/home.aspx" target="_blank">Santa Clara County:</a> 211 lo toksave behainim COVID-19 na local community resources olsem kaikai, haus, counseling, helpim lo kisim wok, gutpla lukaut blo ol liklik pikinini, services blo ol lapun, na planti moa (ie stap 24/7)</li>
                    <li><a href="https://sf.gov/topics/coronavirus-covid-19" target="_blank">San Francisco:</a> (insait lo SF) or 415-701-2311 (ie stap 24/7)</li>
                    <li><a href="http://publichealth.lacounty.gov/media/Coronavirus/" target="_blank">Los Angeles:</a> 211 (ie stap 24/7)</li>
                    <li><a href="https://covid19info.ocgov.com/" target="_blank">Orange County:</a> 211 (ie stap 24/7)</li>
                    <li><a href="https://www.sandiegocounty.gov/coronavirus.html" target="_blank">San Diego:</a> 211 (ie stap 24/7)</li>
                    <li><a href="https://www.co.fresno.ca.us/departments/public-health/covid-19" target="_blank">Fresno:</a> (559) 600-4636 (8a-5p)</li>
                    <li><a href="http://www.acphd.org/2019-ncov.aspx" target="_blank">Alameda:</a> (510) 268-2101</li>
                    <li><a href="https://www.coronavirus.cchealth.org/" target="_blank">Contra Costa:</a> (844) 729-8410 (8a-5p)</li>
                  </ul>
                </p>
                <hr />
                <p>Plis go lo <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/disinfecting-your-home.html" target="_blank">CDC website</a> lo kisim gutpla moa toksave lo hau lo daunim binatang taim wanpla ie sik.</p>
              </div>}
          />
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Learn;
