import React, { Component } from 'react'
import classnames from 'classnames'
import { Link, withRouter } from 'react-router-dom'

class LanguageModal extends React.PureComponent {

  render() {
    const { active, path, toggleLanguageModalActive } = this.props;

    return (
      <div className={classnames('modal', 'language-modal', {'modal-active': active})}>
        <div className="modal-close" onClick={toggleLanguageModalActive}><img className="fit-parent" src="/assets/img/icons/close.svg" /></div>
        <div className="modal-content">
          <h2 className="language-modal-item"><a href={'/en/' + path}>English</a></h2>
          <h2 className="language-modal-item"><a href={'/es/' + path}>Español</a></h2>
          <h2 className="language-modal-item"><a href={'/tl/' + path}>Tagalog</a></h2>
          <h2 className="language-modal-item"><a href={'/vn/' + path}>Tiếng Việt</a></h2>
          <h2 className="language-modal-item"><a href={'/to/' + path}>Tongan</a></h2>
          <h2 className="language-modal-item"><a href={'/tp/' + path}>Tok Pisin</a></h2>
          <h2 className="language-modal-item"><a href={'/bi/' + path}>Bislama</a></h2>
          <h2 className="language-modal-item"><a href={'/ru/' + path}>Русский</a></h2>
          <h2 className="language-modal-item"><a href={'/ko/' + path}>한국어</a></h2>
          <h2 className="language-modal-item"><a href={'/cn/' + path}>中文-简体</a></h2>
          <h2 className="language-modal-item"><a href={'/tw/' + path}>中文-繁體</a></h2>
          <h2 className="language-modal-item"><a href={'/pa/' + path}>ਪੰਜਾਬੀ</a></h2>
          <h2 className="language-modal-item"><a href={'/ar/' + path}>العربية</a></h2>
          <h2 className="language-modal-item"><a href={'/am/' + path}>አማርኛ</a></h2>
        </div>
      </div>
    )
  }
}

export default withRouter(LanguageModal)