import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Learn extends Component {

  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <h4 className="content-nav-caption">목차</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-1')}>COVID-19 란?</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-2')}>COVID-19의 감염 경로는?</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-3')}>COVID-19의 증상</h4>
          <h4 className="content-nav-item" onClick={() => this.scrollToCard('learn-4')}>증상이 있으면 어떻게 해야 하나요?</h4>
        </div>
        <div className="content-body">
          <Card 
            id="learn-1"
            icon="/assets/img/icons/info.svg" 
            title="COVID-19 란?"
            content={
              <div>
                <p>코로나 바이러스는 감기와 같은 흔한 호흡기 질환을 일으킬수있는 바이러스의 일종입니다.</p>
                <p>현재 유행중인 코로나 바이러스는 2019년에 처음으로 발견된 새로운 유형이며, 과학적인 이름은 SARS-CoV-2이고 이 바이러스 감염에 의한 호흡기 질환을 coronavirus disease 2019 (COVID-19)라고 부릅니다.</p>
              </div>}
          />
          <Card 
            id="learn-2"
            icon="/assets/img/icons/spread.svg" 
            title="COVID-19의 감염 경로는?"
            content={
              <div>
                <p><div className="paragraph-header">사람 간 감염</div>COVID-19는 주로 비말(침방울)을 통해 감염되는것으로 알려져 있습니다. 감염자와 6피트 (약 2미터) 이내인 경우에 감염자의 기침, 재채기, 말을 할 때 나오는 호흡기 비말이 입, 코, 눈을 통하여 감염될 수 있습니다.</p>
                <p><div className="paragraph-header">오염된 표면</div>바이러스에 오염된 표면이나 물건을 만진 뒤 눈, 코, 입을 만지면 감염될 수 있습니다. 흔히 생활 속에서 자주 접촉하는 것들 중에 특히 조심 해야 할 것으로는 문 손잡이, 전등 스위치, 핸드폰/스마트폰, 책상, 키보드, 변기, 수도꼭지, 싱크대 등 이 있습니다.</p>
              </div>}
          />
          <Card 
            id="learn-3"
            icon="/assets/img/icons/symptoms.svg" 
            title="COVID-19의 증상"
            content={
              <div>
                <div className="content-symptom-container">
                  <ul className="content-symptom-category">
                    <div className="content-symptom-title">흔한 증상</div>
                    <li className="content-symptom-item">발열 (38˚C)</li>
                    <li className="content-symptom-item">기침</li>
                    <li className="content-symptom-item">호흡 곤란</li>
                  </ul>
                  <ul className="content-symptom-category">
                    <div className="content-symptom-title">덜 흔한 증상</div>
                    <li className="content-symptom-item">피로</li>
                    <li className="content-symptom-item">근육통</li>
                    <li className="content-symptom-item">인후통</li>
                    <li className="content-symptom-item">콧물</li>
                    <li className="content-symptom-item">구역질/구토</li>
                    <li className="content-symptom-item">설사</li>
                    <li className="content-symptom-item">후각, 미각 상실</li>
                  </ul>
                </div>
                <div className="content-symptom-caption">바이러스에 감염 되어도 증상이 없을 수 있습니다.</div>
              </div>}
          />
          <Card 
            id="learn-4"
            icon="/assets/img/icons/sick.svg" 
            title="증상이 있으면 어떻게 해야 하나요?"
            content={
              <div>
                <p>CDC에 따르면 감염된 대부분의 사람들은 증상이 경미하고 병원 치료 없이 집에서 회복 할 수 있다고 합니다. 병원 방문 외에는 외출을 삼가 하십시오. 호흡하는데에 어려움이 있으면 병원에 직접 문의하되 꼭 전화로 먼저 연락하십시오.</p>
                <hr />
                <p>
                  <ul className="content-list">
                    <div>다음과 같은 COVID-19에 대한 응급 증상이 나타나는 경우 즉시 911을 부르십시오:</div>
                    <li>심한 호흡 곤란</li>
                    <li>가슴의 지속적인 통증 또는 압박</li>
                    <li>혼란</li>
                    <li>시퍼런 입술 또는 얼굴</li>
                  </ul>
                </p>
                <hr />
                <p>
                  <ul className="content-list">
                    <div>도움이 필요하거나 궁금한 점이 있으면 지역 사회 보건 센터에 연락하십시오. 거주하는 지역에 따라 연락처가 다릅니다.</div>
                    <li><a href="https://www.sccgov.org/sites/phd/DiseaseInformation/novel-coronavirus/Pages/home.aspx" target="_blank">Santa Clara County:</a> 2-1-1로 전화연결을 통해 COVID-19정보, 사회지원 (식사/식품제공,, 보호소/쉼터, 상담, 취업지원, 아이돌봄 서비스, 노인돌봄 서비스, 등등) 상담이 24시간 제공됩니다.</li>
                    <li><a href="https://sf.gov/topics/coronavirus-covid-19" target="_blank">San Francisco (샌프란시스코):</a> 311 혹은 415-701-2311 전화연결을 통해 사회지원 및 보건복지상담이 24시간 제공됩니다.</li>
                    <li><a href="http://publichealth.lacounty.gov/media/Coronavirus/" target="_blank">Los Angeles (로스앤젤레스):</a> 211 전화연결을 통해 사회지원 및 보건복지상담이 24시간 제공됩니다.</li>
                    <li><a href="https://covid19info.ocgov.com/" target="_blank">Orange County (오렌지 카운티):</a> 211 전화연결을 통해 사회지원 및 보건복지상담이 24시간 제공됩니다.</li>
                    <li><a href="https://www.sandiegocounty.gov/coronavirus.html" target="_blank">San Diego (샌디에고):</a> 211 전화연결을 통해 사회지원 및 비보험자 보건복지상담이 24시간 제공됩니다.</li>
                    <li><a href="https://www.co.fresno.ca.us/departments/public-health/covid-19" target="_blank">Fresno (프레스노):</a> (559) 600-4636 (8a-5p)</li>
                    <li><a href="http://www.acphd.org/2019-ncov.aspx" target="_blank">Alameda (알라메다):</a> (510) 268-2101</li>
                    <li><a href="https://www.coronavirus.cchealth.org/" target="_blank">Contra Costa (콘트라 코스타):</a> (844) 729-8410 (8a-5p)</li>
                  </ul>
                </p>
                <hr />
                <p>미국 질병관리본부(CDC) <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/disinfecting-your-home.html" target="_blank">웹사이트</a>를 방문하여 주변 사람이 감염 되었을 때의 대처요령을 더 자세히 알아보세요.</p>
              </div>}
          />
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Learn;
