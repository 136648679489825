import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Card from '../Components/Card'
import Aside from './Aside'

class Myth extends Component {

  scrollToCard = ( target ) => {
    document.getElementById(target).scrollIntoView({ behavior: 'smooth'});
  }

  render() {
    return (
      <div className="grid">
        <div className="content-nav desktop">
          <div className="content-nav-scroller">
            <h4 className="content-nav-caption">目錄</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-1')}>如果我戴口罩，我不需要與他人保持6英尺的距離。</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-10')}>全身噴酒精或氯能殺死新型冠狀病毒。</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-2')}>我需要去買醫療口罩或N95口罩</h4>
            {/* <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-3')}>新冠病毒能通過空氣傳播</h4> */}
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-4')}>戴手套可以百分之百防禦新冠病毒</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-5')}>冠狀病毒可以在表面存活數天。</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-6')}>如果我長期留在家中，就不必經常洗手或清潔消毒</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-7')}>消炎藥 布洛芬 (Ibuprofen) 可以讓新冠患者的病情惡化</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-8')}>自行服用羥氯喹或阿奇黴素可防止感染冠狀病毒。</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-9')}>抗生素對冠狀病毒有預防和治療的作用。</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-11')}>我年輕又健康，所以我不會感染冠狀病毒。</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-12')}>如果我感染了新冠病毒，我一定要住院。</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-13')}>如果我能夠屏住呼吸10秒或更長時間而不咳嗽或感到不適，就意味著我沒有患冠狀病毒病。</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-14')}>乾手機（洗手後用的烘乾機）或者紫外線消毒燈可有效的殺滅冠狀病毒。</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-15')}>經常用鹽水沖洗鼻子可防止感染冠狀病毒。</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-16')}>洗熱水澡能預防新型冠狀病毒病。</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-17')}>寒冷和下雪的天氣可預防感染新冠病毒。</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-18')}>吃大蒜可防止感染新冠病毒。</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-19')}>飲酒可預防感染新冠病毒。</h4>
            <h4 className="content-nav-item" onClick={() => this.scrollToCard('myth-20')}>肺炎疫苗能預防新型冠狀病毒。</h4>
          </div>
        </div>
        <div className="content-body">
          <Card 
            id="myth-1"
            thumbnail="/assets/img/shots/myth/myth-1.jpg" 
            title="如果我戴口罩，我不需要與他人保持6英尺的距離。"
            content={
              <div>
                <p><span className="card-tag-false">不對</span>繼續與他人保持6英尺的距離和經常洗手。戴口罩之前和脫下口罩之後都要洗手。</p>
                <p><a href="https://chinese.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover-faq.html" target="_blank">美国疾病預防控制中心 (CDC)</a>建議戴布口罩以防止打噴嚏，咳嗽或說話時的飛沫傳播病毒。口罩的保護性取決於其製造織物和材料。請參見我們的口罩提示。</p>
              </div>}
            extra={
              <div>
                <p>請遵守<a href="https://chinese.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a>請確保口罩緊密貼合但仍可以使呼吸舒適。CDC建議使用多層織物而沒有建議使用某種特定類型的織物。</p>
                <p><div className="paragraph-header">布料口罩對冠狀病毒的防護作用多少？</div>目前沒有科學證據可以直接回答這個問題。在<a href="https://www.ncbi.nlm.nih.gov/pubmed/24229526" target="_blank">研究另一種病毒的實驗</a>中，發現所有試驗過的織物（全棉襯衫，圍巾，絲綢，枕頭套，亞麻，棉混紡，茶巾）都不能百分之百阻止病毒顆粒。但是，此實驗沒有模擬正常人咳嗽。它也沒有考慮面罩是否穿戴緊密合適。由於大多數口罩不能提供緊密的密封，因此病毒顆粒仍然可以通過口罩的縫隙<a href="https://www.gannett-cdn.com/experiments/usatoday/gifs/tool/919-surgical.png" target="_blank">進入。</a></p>
                <p><div className="paragraph-header">重點</div>布料口罩可能有助於預防感染，但不能百分之百的保證防御成功。</p>
              </div>}
          />
          <Card 
            id="myth-10"
            thumbnail="/assets/img/shots/myth/myth-10.jpg" 
            title="全身噴酒精或氯能殺死新型冠狀病毒。"
            content={
              <div>
                <p><span className="card-tag-false">不對</span><strong>請勿</strong>注射,飲用或者噴射酒精或氯在身上。</p>
                <p><a href="https://www.who.int/zh/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">世界衛生組織（WHO）</a>稱：「全身噴灑精或氯不會殺死已經進入體內的病毒。噴灑此類物質可能對衣服或粘膜（即眼睛、口腔）有害。請注意，酒精和氯可能對消毒各種表面有用，但是使用時須遵循適當的建議。」<a href="https://chinese.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cleaning-disinfection.html" target="_blank">請不要</a>飲用或註射有腐蝕性的清潔用品，這些產品只適合用來清潔物件和表面。</p>
              </div>}
          />
          <Card 
            id="myth-2"
            thumbnail="/assets/img/shots/myth/myth-2.jpg" 
            title="我需要去買醫療口罩或N95口罩"
            content={
              <div>
                <p><span className="card-tag-false">不對</span>美國<a href="https://chinese.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">CDC</a>建議普通市民使用布料面罩。</p>
                <p>您可以使用您家裡已有的各種的口罩。不用特別的去買醫療口罩(或稱外科口罩)或N95口罩。您也可以考慮捐贈多餘的N95口罩給醫護人員。因為醫療口罩的供應短缺，應保留給受過培訓和直接與病者密切接觸的醫護人員使用。 N95口罩主要防止被空氣傳播的病毒，但新冠病毒主要通過呼吸道飛沫傳播。請在以下查詢更多關於空氣傳播資訊。</p>
              </div>}
            extra={
              <div>
                <p><a href="https://www.fda.gov/medical-devices/personal-protective-equipment-infection-control/n95-respirators-and-surgical-masks-face-masks" target="_blank">N95口罩</a>有專用的過濾器。正確穿上時有氣密密封，可以阻擋至少95％的非常小的（0.3微米）粒子。除了培訓之外，每年醫護人員還需要進行<a href="https://www.safetyalliance.org/wp-content/uploads/2019/06/fit-test-2-000110_retouched-672x372.jpg" target="_blank">口罩密合度測試</a>，以確保尺寸合適，以確保密封。正確佩戴後，可能會呼吸不舒服。醫護人員也需要謹守精微的細節，例如面部毛髮也可能導致不能完全密封。對於<a href="https://blogs.cdc.gov/niosh-science-blog/2018/01/04/respirators-public-use/" target="_blank">普通市民</a>來說，缺乏培訓如何佩戴和缺乏合適測試, N95不太可能會提供足夠的保護。</p>
              </div>}
          />
          {/*
          <Card 
            id="myth-3"
            thumbnail="/assets/img/shots/myth/myth-3.jpg" 
            title="新冠病毒能通過空氣傳播"
            content={
              <div>
                <p><span className="card-tag-maybe">可能</span>對於普通市民來說，不太可能會受到空氣傳播。</p>
                <p>根據<a href="https://www.who.int/zh/news-room/q-a-detail/q-a-coronaviruses" target="_blank">世界衛生組織(WHO)</a>「 新冠病毒主要通過感染者的咳嗽、打噴嚏或說話時產生的飛沫傳播。 這些飛沫太重而無法留在空氣中。 它們很快會掉在地板或地面上。」在中國超過75,000病例的研究中，沒有空中傳播的報導。但是，醫護人員可能位於高度危險性的環境。 WHO報告，在<a href="https://www.who.int/zh/news-room/commentaries/detail/modes-of-transmission-of-virus-causing-covid-19-implications-for-ipc-precaution-recommendations" target="_blank">某些可以產生氣霧</a>的醫療程序下（比如在為了幫助患者插入呼吸管），這個過程中病毒可能通過空氣傳播。</p>
                </div>}
            extra={
              <div>
                <p><div className="paragraph-header">什麼是空氣傳播？</div>與呼吸飛沫相比，空氣中的微粒更小，可以在空氣中停留一段很長的時間使它們傳播的距離超過6英尺。</p>
                <p><div className="paragraph-header">為什麼新聞說新冠病毒是空氣傳播的？</div>發表在新英格蘭醫學雜誌（NEJM）的<a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973" target="_blank">一項研究中</a>，研究人員用一個高功能機器旋轉圓柱體製造了冠狀病毒的氣霧，在該圓柱體中病毒存活了3個小時。不過，機器製造的並不代表正常的人類咳嗽飛沫。在另一項未經同行評審的<a href="https://www.medrxiv.org/content/10.1101/2020.03.23.20039446v2" target="_blank">研究中</a>（未由其他專家進行評估），研究人員從13名新冠病毒患者病房的空氣和表面中採集了樣本。在所有個人用品中，有76.5％為陽性（手機、廁所、遙控器、床欄等等)。在空氣樣本中，有63.2％為陽性。並且在這些空氣樣本中，有些樣本跟患者的距離甚至超過6英尺。但是，病毒的存在並不等於著它會引起疾病。比如說，病毒數量可能太低而無法引起疾病。該研究團體指出，由於樣本中的病毒濃度較低，現時還未能確定樣本中的傳染性。</p>
              </div>}
          /> */}
          <Card 
            id="myth-4"
            thumbnail="/assets/img/shots/myth/myth-4.jpg" 
            title="戴手套可以百分之百防禦新冠病毒"
            content={
              <div>
                <p><span className="card-tag-false">不對</span>只有在採取預防措施（例如，不要觸摸眼睛、鼻子、嘴巴，和摘下手套後一定要洗手）後，手套才有幫助。否則，手套可能會產生交叉污染。被污染的手套接觸到什麼，病毒都可能擴散到被接觸物件的表面。</p>
              </div>}
          />
          <Card 
            id="myth-5"
            thumbnail="/assets/img/shots/myth/myth-5-tw.jpg" 
            title="冠狀病毒可以在表面存活數天。"
            content={
              <div>
                <p><span className="card-tag-true">對的</span>發表在<a href="https://www.nejm.org/doi/full/10.1056/NEJMc2004973?query=featured_home" target="_blank">NEJM的一項研究</a>中，在實驗室控制的狀況下，可以在紙皮板上檢測冠狀病毒長達24小時, 而在塑料、鋼鐵上長達72小時。</p>
                <p>注意，最終殘留的病毒數量為起始病毒的0.1％。病毒的存在並不等於它具有傳染性。由於每個表面上的病毒數量都隨著時間迅速減少，因此觸摸它們的感染風險也應隨著時間而降低。</p>
              </div>}
          />
          <Card 
            id="myth-6"
            thumbnail="/assets/img/shots/myth/myth-6.jpg" 
            title="如果我長期留在家中，就不必經常洗手或清潔消毒"
            content={
              <div>
                <p><span className="card-tag-false">不對</span><a href="https://chinese.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/prevention.html" target="_blank">CDC</a>建議每天清潔和消毒經常觸摸的表面，例如門把手、電燈開關、桌子、廚房的檯面、電話、鍵盤、廁所、水龍頭和水槽。</p>
                <p>您或家人可能在不知不覺中被感染。雖然您與病人密切接觸比被污染的表面感染可能性更大，但如果可以，請採取預防措施。</p>
              </div>}
          />
          <Card 
            id="myth-7"
            thumbnail="/assets/img/shots/myth/myth-7.jpg" 
            title="消炎藥 布洛芬 (Ibuprofen) 可以讓新冠患者的病情惡化"
            content={
              <div>
                <p><span className="card-tag-false">不對</span>布洛芬 (Ibuprofen，商品名稱Advil，Motrin) 是一種普遍在藥房能夠買到的消炎止痛藥。當前沒有足夠的科學證據證明布洛芬會使新冠患者病情惡化。</p>
                <p>直至2020年3月18日，<a href="https://twitter.com/WHO/status/1240409217997189128" target="_blank">世衛組織</a>不建議停用布洛芬。對乙酰氨基酚（Tylenol）是另外一種去燒和止痛藥。如果您現在依靠服用非甾體抗炎藥物 (NSAID) 去治療慢性疾病但您擔心服用這些藥物，<a href="https://www.fda.gov/drugs/drug-safety-and-availability/fda-advises-patients-use-non-steroidal-anti-inflammatory-drugs-nsaids-covid-19" target="_blank">美國食品藥品管理局（FDA）</a>建議與您的家庭醫生談論。</p>
                </div>}
            extra={
            <div>
              <p>布洛芬(Ibuprofen) 屬於一種稱為<a href="https://www.goodrx.com/blog/are-advil-motrin-tylenol-bad-for-coronavirus-symptoms/" target="_blank">非甾體抗炎(NSAID)的藥物</a>，還包括阿司匹林(Aspirin)，萘普生（Aleve），雙氯芬酸（Cambia）和塞來昔布（Celebrex）都是用於治療發燒，疼痛和炎症。每種藥物都有其風險和益處。例如，非甾體抗炎藥會增加腎衰竭和胃腸道潰瘍的風險。服用對乙酰氨基酚（acetaminophen, Tylenol）的最大風險是服用過量引起的肝衰竭。對乙酰氨基酚存在於各種常見的非處方感冒和流感藥物中。請仔細閱讀藥物的所有標籤以確保不會超出每日服用的最大劑量。</p>
              <p><div className="paragraph-header">布洛芬藥效爭議是如何開始的？</div><a href="https://jvi.asm.org/content/94/7/e00127-20" target="_blank">研究</a>表明，ACE2是人體內的一種酶，它在人細胞膜上提供了一個位置可以讓冠狀病毒附著並且感染細胞。</p>
              <p>在2020年3月11日，研究人員在醫學雜誌 the Lancet Respiratory Medicine上發表了<a href="https://www.thelancet.com/journals/lanres/article/PIIS2213-2600(20)30116-8/fulltext" target="_blank">一封信</a>，其理論認為提高細胞的ACE2量可能與新冠病毒的嚴重性有關。跟布洛芬有什麼關係？作者認為布洛芬可以引起ACE2量數增加。因此，在布洛芬和新冠病毒之間建立了關係。</p>
              <p>這些都是推測性的，現無科學證據表明布洛芬直接惡化新冠病毒患者的狀況。但是，3月14日，法國衛生部發布警告，禁止將布洛芬用於治療因新冠病毒的發熱，法國衛生部長在<a href="https://twitter.com/olivierveran/status/1238776545398923264" target="_blank">推特(Twitter)</a>上發布了避免布洛芬和其他NSAID的建議，因為它們可能“加重病情”。他的建議在新聞中廣泛傳播，並開始將布洛芬歸因於新冠病毒死亡的主要原因。 3月16日，引發辯論的作者澄清了他們的理論<a href="https://www.unibas.ch/en/News-Events/News/Uni-Research/Ibuprofen-and-COVID-19-Setting-the-record-straight.html" target="_blank">“不構成是否使用某些藥物的建議”。</a></p>
              <p>對於患有新冠病症狀的人，WHO最初建議不要使用布洛芬。<strong>在3月18日，<a href="https://www.ema.europa.eu/en/news/ema-gives-advice-use-non-steroidal-anti-inflammatories-covid-19" target="_blank">EMA</a>, <a href="https://www.fda.gov/drugs/drug-safety-and-availability/fda-advises-patients-use-non-steroidal-anti-inflammatory-drugs-nsaids-covid-19" target="_blank">FDA</a>, <a href="https://twitter.com/WHO/status/1240409217997189128" target="_blank">WHO</a>建議在必要時刻可以考慮使用NSAIDS。</strong></p>
            </div>}
          />
          <Card 
            id="myth-8"
            thumbnail="/assets/img/shots/myth/myth-8.jpg" 
            title="自行服用羥氯喹或阿奇黴素可防止感染冠狀病毒。"
            content={
              <div>
                <p><span className="card-tag-false">不對</span>請<strong>不要</strong>在無處方的情況下吃處方藥！處方藥只應該在醫生的指導下服用，以避免危險的副作用或者藥物相互作用。</p>
                <p><a href="https://www.cdc.gov/coronavirus/2019-ncov/hcp/therapeutic-options.html" target="_blank">羥氯喹(Hydroxychloroquine)和氯喹 (chloroquine)</a>是治療瘧疾以及炎症性疾病（狼瘡，風濕等）的口服處方藥。阿奇黴素是一個常用的抗生素。羥氯喹和阿奇黴素都可能有影響心臟的副作用，可導致致命的心律失常（心跳過快）。研究人員正在研究這些藥物是否能有效地治療冠狀病毒，不過請您務必遵守用藥規則，在醫生的指導下吃處方藥。</p>
              </div>}
          />
          <Card 
            id="myth-9"
            thumbnail="/assets/img/shots/myth/myth-9.jpg" 
            title="抗生素對冠狀病毒有預防和治療的作用。"
            content={
              <div>
                <p><span className="card-tag-false">不對</span>根據<a href="https://www.who.int/zh/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">世界衛生組織 （WHO）</a>，抗生素只能治療細菌感染，但對病毒所引起的感染不起作用。所以抗生素不應該用於預防或者治療病毒引起的感染。但是，如果你感染冠狀病毒住院，因為病毒感染伴可能隨著細菌感染， 醫生有可能會給你抗生素。</p>
              </div>}
          />
          <Card 
            id="myth-11"
            thumbnail="/assets/img/shots/myth/myth-11.jpg" 
            title="我年輕又健康，所以我不會感染冠狀病毒。"
            content={
              <div>
                <p><span className="card-tag-false">不對</span><a href="https://www.who.int/zh/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">世界衛生組織（WHO）</a>稱：「無論年紀，所有人都可能感染新型冠狀病毒。」</p>
                <p>年紀大或者有既往病史（別的疾病，比如哮喘，糖尿病和心髒病）的人感染後更可能會有較嚴重的症狀。世界衛生組織建議所有年紀的人注重做好防護措施，以防病毒的傳染。</p>
              </div>}
            extra={
              <div>
                <p><a href="https://chinese.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover.html" target="_blank">美國疾病控制預防中心（CDC）</a>報導說：在美國最初4,226的冠毒病例 （從二月12日到三月16日期中），五分之一的住院病人年紀為20到44歲。直到2020年4月12日，<a href="https://www.cdph.ca.gov/Programs/CID/DCDC/Pages/Immunization/ncov2019.aspx" target="_blank">加州大約一半的冠狀病毒確診病例</a>生於50歲以下的人。雖然死亡率在年輕人當中比較低，但也有很多無既往病史的青年人因得病而去世了。為了保護自己和他人，所有人都應該做好防護措施。</p>
              </div>}
          />
          <Card 
            id="myth-12"
            thumbnail="/assets/img/shots/myth/myth-12.jpg" 
            title="如果我感染了新冠病毒，我一定要住院。"
            content={
              <div>
                <p><span className="card-tag-false">不對</span>根據<a href="https://chinese.cdc.gov/coronavirus/2019-ncov/symptoms-testing/testing.html" target="_blank">美国疾病预防控制中心 (CDC) </a>大部分感染冠狀病毒的人會有輕度的症狀，會在家裡恢復。除了去找醫療以外，不要離開你的住所。如果你的呼吸有困難，先打電話給你醫生或者診所。請參閱這個網站的“關於新冠狀病毒”網頁。</p>
              </div>}
          />
          <Card 
            id="myth-13"
            thumbnail="/assets/img/shots/myth/myth-13.jpg" 
            title="如果我能夠屏住呼吸10秒或更長時間而不咳嗽或感到不適，就意味著我沒有患冠狀病毒病。"
            content={
              <div>
                <p><span className="card-tag-false">不對</span><a href="https://chinese.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover.html" target="_blank">美国疾病控制预防中心（CDC）</a>警告：「大部分患有冠狀病毒的人都缺乏症狀（“無症狀”），甚至那些最終出現症狀（“症狀前”）的個體也會在顯現症狀前將病毒傳播給他人」。<a href="https://www.who.int/zh/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">世界衛生組織（WHO）</a>稱：「新冠病毒最常見症狀是乾咳、乏力和發燒。有些人可能會患肺炎等比較嚴重疾病。不能用這種呼吸練習來測試。」</p>
              </div>}
          />
          <Card 
            id="myth-14"
            thumbnail="/assets/img/shots/myth/myth-14.jpg" 
            title="乾手機（洗手後用的烘乾機）或者紫外線消毒燈可有效的殺滅冠狀病毒。"
            content={
              <div>
                <p><span className="card-tag-false">不對</span><a href="https://www.who.int/zh/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">世界卫生组织（WHO）</a>稱：「乾手機並不能有效的消滅冠狀病毒。紫外線消毒燈不應用來為手或者任何皮膚消毒，因為紫外線可能刺激皮膚。為了防止冠狀病毒的傳染，您應該經常用洗手液洗手，或者用帶有酒精的消毒液洗手（免洗洗手液）。」</p>
              </div>}
          />
          <Card 
            id="myth-15"
            thumbnail="/assets/img/shots/myth/myth-15.jpg" 
            title="經常用鹽水沖洗鼻子可防止感染冠狀病毒。"
            content={
              <div>
                <p><span className="card-tag-false">不對</span><a href="https://www.who.int/zh/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">世界卫生组织（WHO）</a>稱：「目前沒有科學證據證明經常用鹽水沖洗鼻子可防止感染新冠狀病毒。少量的科研結果表示，患有普通感冒的人用這個方式沖洗鼻子可能會快點恢復。同時，經常沖洗鼻子未顯示預防呼吸道感染。”</p>
              </div>}
          />
          <Card 
            id="myth-16"
            thumbnail="/assets/img/shots/myth/myth-16.jpg" 
            title="洗熱水澡能預防新型冠狀病毒病。"
            content={
              <div>
                <p><span className="card-tag-false">不對</span>您可以洗澡或淋浴，但請不要故意使用極熱的水。<a href="https://www.who.int/zh/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">世界卫生组织（WHO）</a>稱：“不管您洗澡或淋浴溫度如何，您的正常體溫仍會保持在36.5℃至37℃之間。事實上，洗熱水澡時，如果水溫過熱是有害的，可能會燙傷您。”</p>
              </div>}
          />
          <Card 
            id="myth-17"
            thumbnail="/assets/img/shots/myth/myth-17.jpg" 
            title="寒冷和下雪的天氣可預防感染新冠病毒。"
            content={
              <div>
                <p><span className="card-tag-false">不對</span><a href="https://www.who.int/zh/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">世界卫生组织（WHO）</a>稱：”寒冷天氣跟雪都不能消滅新冠狀病毒。不管外面的溫度高低，人的正常體溫會維持在36.5到37°C之間。”</p>
              </div>}
          />
          <Card 
            id="myth-18"
            thumbnail="/assets/img/shots/myth/myth-18.jpg" 
            title="吃大蒜可防止感染新冠病毒。"
            content={
              <div>
                <p><span className="card-tag-false">不對</span><a href="https://www.who.int/zh/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">世界卫生组织（WHO）</a>稱： “大蒜是一種含有抗菌特性的健康食物。但是，從當前的大流行中沒有證據證明大蒜可防止感染新冠狀病毒。”</p>
              </div>}
          />
          <Card 
            id="myth-19"
            thumbnail="/assets/img/shots/myth/myth-19.jpg" 
            title="飲酒可預防感染新冠病毒。"
            content={
              <div>
                <p><span className="card-tag-false">不對</span><a href="https://www.who.int/zh/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">世界卫生组织（WHO）</a>稱：“飲酒不可預防感染新冠病毒，反而可能會帶來危害。頻繁或過量飲酒可能會加劇健康問題。”</p>
              </div>}
          />
          <Card 
            id="myth-20"
            thumbnail="/assets/img/shots/myth/myth-20.jpg" 
            title="肺炎疫苗能預防新型冠狀病毒。"
            content={
              <div>
                <p><span className="card-tag-false">不對</span><a href="https://www.who.int/zh/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters" target="_blank">世界卫生组织 (WHO) </a>稱：“肺炎疫苗，如肺炎球菌（pneumococcal）疫苗和乙型流感嗜血桿菌（Hib）疫苗，不能預防新型冠狀病毒。新冠病毒是新的和不同的病毒，需要有其專用的疫苗。雖然這些呼吸道疾病疫苗對預防新型冠狀病毒沒有效果，但我們強烈建議接種這些疫苗來保障自己的健康。”</p>
              </div>}
          />
        </div>
        <Aside path={this.props.path} />
      </div>
    );
  }
}

export default Myth;
