import React, { Component } from 'react'
import classnames from 'classnames'
import { Link, withRouter } from 'react-router-dom'

import Button from '../Components/Button'
import ClipboardButton from '../Components/ClipboardButton'

class Aside extends React.PureComponent {

  render() {

    return (
      <div className="content-aside desktop">
        <div className="content-share">
          <h4 className="content-share-caption">轉發給朋友和家人</h4>
          <div className="content-share-options">
            <div className="content-share-item content-share-twitter">
              <img className="fit-height" src="/assets/img/icons/twitter.png" />
              <a className="twitter-share-button" href="https://twitter.com/intent/tweet?text=%E6%9F%A5%E8%A9%A2%20COVID-19%20Fact%20Check%20%E4%BE%86%E5%88%86%E8%BE%A8%E6%9C%89%E9%97%9C%E5%86%A0%E7%8B%80%E7%97%85%E6%AF%92%E7%9A%84%E4%BA%8B%E5%AF%A6%E5%92%8C%E8%AC%A0%E8%A8%80%E3%80%82&tw_p=tweetbutton&url=http%3A%2F%2Fwww.covid19factcheck.com%2Ftw%2F" target="_blank">Tweet</a>
            </div>
            <div className="content-share-item content-share-facebook">
              <img className="fit-height" src="/assets/img/icons/facebook.png" />
              <a className="fb-share-button" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=http://www.covid19factcheck.com/tw/"></a>
            </div>
            <div className="content-share-item content-share-email">
              <img className="fit-height" src="/assets/img/icons/email.svg" />
              <a href="mailto:?body=%E6%9F%A5%E8%A9%A2%20COVID-19%20Fact%20Check%20%E4%BE%86%E5%88%86%E8%BE%A8%E6%9C%89%E9%97%9C%E5%86%A0%E7%8B%80%E7%97%85%E6%AF%92%E7%9A%84%E4%BA%8B%E5%AF%A6%E5%92%8C%E8%AC%A0%E8%A8%80%E3%80%82%20http%3A%2F%2Fwww.covid19factcheck.com%2Ftw%2F"></a>
            </div>
            <ClipboardButton className="content-share-item content-share-link" value="http://www.covid19factcheck.com/tw/" success="已復製網址！ 🎉"><img className="fit-height" src="/assets/img/icons/link.svg" /></ClipboardButton>
          </div>
        </div>
        <h5 className="content-footnote">資料主要是來自美國疾病預防控制中心 <a href="https://www.cdc.gov/" target="_blank">(CDC)</a> 以及世界衛生組織 <a href="https://www.who.int/" target="_blank">(WHO)</a>的網站<br /> 網頁最後更新日期 05/18/20</h5>
      </div>
    )
  }
}

export default withRouter(Aside)